import { BlockState, BlockType } from "./useBlocks";

const playerBlock = "player";
const staticBlock = "static";

const isBlock = (blockType: BlockType) =>
  blockType === playerBlock || blockType === staticBlock;

export const windowToBlockInfo = (
  blockWindow: BlockState,
  halfX: boolean,
  halfY: boolean
) => {
  let blockType: BlockType | "empty" = undefined;
  let xOffset = 0;
  let yOffset = 0;

  if (isBlock(blockWindow[2][2])) {
    blockType = blockWindow[2][2];
  } else if (isBlock(blockWindow[1][2]) && halfX) {
    blockType = blockWindow[1][2];
    xOffset = -1;
  } else if (isBlock(blockWindow[3][2]) && !halfX) {
    blockType = blockWindow[3][2];
    xOffset = 1;
  } else if (isBlock(blockWindow[2][1]) && halfY) {
    blockType = blockWindow[2][1];
    yOffset = -1;
  } else if (isBlock(blockWindow[2][3]) && !halfY) {
    blockType = blockWindow[2][3];
    yOffset = 1;
  } else if (isBlock(blockWindow[1][1]) && halfX && halfY) {
    blockType = blockWindow[1][1];
    xOffset = -1;
    yOffset = -1;
  } else if (isBlock(blockWindow[1][3]) && halfX && !halfY) {
    blockType = blockWindow[1][3];
    xOffset = -1;
    yOffset = 1;
  } else if (isBlock(blockWindow[3][1]) && !halfX && halfY) {
    blockType = blockWindow[3][1];
    xOffset = 1;
    yOffset = -1;
  } else if (isBlock(blockWindow[3][3]) && !halfX && !halfY) {
    blockType = blockWindow[3][3];
    xOffset = 1;
    yOffset = 1;
  }

  if (
    !blockWindow[1][1] &&
    !blockWindow[1][2] &&
    !blockWindow[1][3] &&
    !blockWindow[2][1] &&
    !blockWindow[2][2] &&
    !blockWindow[2][3] &&
    !blockWindow[3][1] &&
    !blockWindow[3][2] &&
    !blockWindow[3][3]
  ) {
    blockType = "empty";
  }

  return {blockType, xOffset, yOffset}
};
