import { useCallback, useEffect, useState } from "react";
import { Coord } from "../../shared/Coord";
import { Board } from "../../shared/Board";
import { Layout } from "../../shared/Layout";
import { lt } from "../../utils/lt";

const setInState = (
  x: number,
  y: number,
  boardState: boolean[][],
  value: boolean
) => {
  boardState[x][y] = value;
  boardState[x][y + 1] = value;
  boardState[x + 1][y] = value;
  boardState[x + 1][y + 1] = value;
  return boardState;
};

const printBoard = (state: boolean[][], towers: boolean[][]) => {
  for (var i = 0; i < state[0].length; i++) {
    var row = "";
    for (var j = 0; j < state.length; j++) {
      row = row + (state[j][i] ? "OO " : towers[j][i] ? "[] " : ".. ");
    }
    console.log("" + i + (i > 9 ? ":" : " :") + row);
  }
};

const minMax = (value: number, max: number) =>
  Math.max(Math.min(value, max - 1), 0);

export type BoardRepresentation = {
  canSetTower: (coord: Coord) => boolean;
  resetRepresentation: () => void;
  removeTowerInRepresentation: (coord: Coord) => void;
  forceRepresentation: (layout: Layout) => void;
};

export function useBoardRepresentation(
  board: Board,
  startingLayout?: Layout
): BoardRepresentation {
  const [boardState, setBoardState] = useState<boolean[][]>(() => {
    const state = [...Array(board.width)].map(() =>
      [...Array(board.height)].map(() => false)
    );
    board.staticTowers.map((tower) =>
      setInState(tower.coord.x, tower.coord.y, state, true)
    );
    return state;
  });

  useEffect(() => {
    const state = [...Array(board.width)].map(() =>
      [...Array(board.height)].map(() => false)
    );
    board.staticTowers.map((tower) =>
      setInState(tower.coord.x, tower.coord.y, state, true)
    );
    if (startingLayout && startingLayout.towers) {
      startingLayout.towers.forEach((tower) => {
        setInState(tower.coord.x, tower.coord.y, state, true);
      });
    }
    setBoardState(state);
    // eslint-disable-next-line
  }, [board]);

  const canSet = useCallback(
    (x: number, y: number, boardState: boolean[][]) =>
      x >= 0 &&
      y >= 0 &&
      y < board.height &&
      x < board.width &&
      !boardState[x][y] &&
      !boardState[x][y + 1] &&
      !boardState[x + 1][y] &&
      !boardState[x + 1][y + 1],
    [board]
  );

  const canSetTower = useCallback(
    (coord: Coord) => {
      const debug = false;

      if (debug) console.log(coord);
      if (!canSet(coord.x, coord.y, boardState)) {
        if (debug) {
          console.log("cant set");
          printBoard(boardState, boardState);
        }
        return false;
      }
      const boardRep = setInState(coord.x, coord.y, boardState, true);
      const isVisited = [...Array(board.width)].map(() =>
        [...Array(board.height)].map(() => false)
      );

      var current: Coord;

      // immediately return false if endzone/startzone is blocked
      if (
        board.startArea.every(
          ({ x, y }) =>
            boardRep[minMax(x, board.width)][minMax(y, board.height)]
        ) ||
        board.endArea.every(
          ({ x, y }) =>
            boardRep[minMax(x, board.width)][minMax(y, board.height)]
        )
      ) {
        setInState(coord.x, coord.y, boardState, false);
        return false;
      }
      var temp = lt(
        board.startArea.find(
          ({ x, y }) =>
            !boardRep[minMax(x, board.width)][minMax(y, board.height)]
        )!,
        ({ x, y }) => ({
          x: minMax(x, board.width),
          y: minMax(y, board.height),
        })
      );

      var queue = [temp];

      var counter = 0;
      var foundEnd = false;
      var foundWaypoint = !board.waypoint;
      while (true) {
        if (queue.length === 0) {
          setInState(coord.x, coord.y, boardState, false);
          return false;
        } else {
          current = queue.pop()!;
          if (debug) {
            counter++;
            console.log("iteration " + counter);
            printBoard(isVisited, boardRep);
            console.log(current);
            console.log(queue);
            console.log("---------------------------------");
            if (counter > 2000) {
              return false;
            }
          }
          if (
            !foundEnd &&
            board.endArea.some(
              // eslint-disable-next-line
              ({ x, y }) =>
                current.x === minMax(x, board.width) &&
                current.y === minMax(y, board.height)
            )
          ) {
            foundEnd = true;
          }
          if (
            !foundWaypoint &&
            board.waypoint &&
            board.waypoint.x === current.x &&
            board.waypoint.y === current.y
          ) {
            foundWaypoint = true;
          }

          if (foundEnd && foundWaypoint) {
            setBoardState(boardRep);
            return true;
          }

          // Visit next tiles
          if (
            current.x > 0 &&
            !boardRep[current.x - 1][current.y] &&
            !isVisited[current.x - 1][current.y]
          ) {
            isVisited[current.x - 1][current.y] = true;
            queue.push({ x: current.x - 1, y: current.y });
          }
          if (
            current.y > 0 &&
            !boardRep[current.x][current.y - 1] &&
            !isVisited[current.x][current.y - 1]
          ) {
            isVisited[current.x][current.y - 1] = true;
            queue.push({ x: current.x, y: current.y - 1 });
          }
          if (
            current.x < board.width - 1 &&
            !boardRep[current.x + 1][current.y] &&
            !isVisited[current.x + 1][current.y]
          ) {
            isVisited[current.x + 1][current.y] = true;
            queue.push({ x: current.x + 1, y: current.y });
          }
          if (
            current.y < board.height - 1 &&
            !boardRep[current.x][current.y + 1] &&
            !isVisited[current.x][current.y + 1]
          ) {
            isVisited[current.x][current.y + 1] = true;
            queue.push({ x: current.x, y: current.y + 1 });
          }
        }
      }
    },
    [board, boardState, setBoardState, canSet]
  );
  const removeTowerInRepresentation = useCallback(
    (coord: Coord) => {
      setBoardState(setInState(coord.x, coord.y, boardState, false));
    },
    [boardState, setBoardState]
  );

  const resetRepresentation = useCallback(() => {
    const state = [...Array(board.width)].map(() =>
      [...Array(board.height)].map(() => false)
    );
    board.staticTowers.map((tower) =>
      setInState(tower.coord.x, tower.coord.y, state, true)
    );
    setBoardState(state);
  }, [board, setBoardState]);

  const forceRepresentation = useCallback(
    (layout: Layout) => {
      const state = [...Array(board.width)].map(() =>
        [...Array(board.height)].map(() => false)
      );
      board.staticTowers.map((tower) =>
        setInState(tower.coord.x, tower.coord.y, state, true)
      );
      layout.towers.forEach((tower) =>
        setInState(tower.coord.x, tower.coord.y, state, true)
      );
      setBoardState(state);
    },
    [board, setBoardState]
  );

  return {
    canSetTower,
    resetRepresentation,
    removeTowerInRepresentation,
    forceRepresentation,
  };
}
