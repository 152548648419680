export type Arena = {
  maxWins: number;
  maxLosses: number;
  duration: number;
  cost: number;
};
export type Defaults = {
  boardWidth: number;
  boardHeight: number;
  duration: number;
};

export type Challenge = {
  showDeadlineChallengeSolutions: boolean;
};

export type Constants = {
  version: number;
  tileSize: number;
  clapLength: number;
  clapModifier: number;
  clapSize: number;
  saveCost: number;
  speedModifier: number;
  ratio: number;
  hostName: string;
  backendUrl: string;
  webSocketUrl: string;
  tutorialSeed: number;
  countsRefreshRate: number;
  longTapDuration: number;
  longTapTooLong: number;
  defaults: Defaults;
  arena: Arena;
  challenge: Challenge;
  minCachedImageSize: number;
  logErrors: boolean;
  firstStaticTint: number;
  firstStaticInlayTint: number;
  leaderboardPageSize: number;
  secondStaticTint: number;
  secondStaticInlayTint: number;
};

export const isDev = false;

export const cst: Constants = {
  version: 2,
  ratio: 19.5 / 17,
  minCachedImageSize: 8000,
  logErrors: false,
  tileSize: 64,
  clapLength: 15,
  clapModifier: 0.5,
  clapSize: 3.125,
  tutorialSeed: 41,
  speedModifier: 14,
  saveCost: 100,
  countsRefreshRate: 5 * 60 * 1000,
  hostName: isDev ? "http://localhost:8080" : "https://maze.game",
  backendUrl: isDev ? "http://localhost:8080" : "/b",
  webSocketUrl: isDev ? "ws://localhost:8080" : "wss://maze.game/b",
  longTapDuration: 300,
  longTapTooLong: 2000,
  defaults: {
    boardWidth: 16,
    boardHeight: 18,
    duration: 90,
  },
  arena: {
    maxWins: 12,
    maxLosses: 3,
    duration: 90,
    cost: 350,
  },
  challenge: {
    showDeadlineChallengeSolutions: true,
  },
  leaderboardPageSize: 25,
  firstStaticTint: 0xcc6058,
  firstStaticInlayTint: 0xb3544d,
  secondStaticTint: 0xf57167,
  secondStaticInlayTint: 0xdb655c,
};
