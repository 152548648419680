import React, { useContext } from "react";
import { Textures } from "./texture";
import spritesheet from "./spritesheet.png";
import spritesheetJson from "./spritesheet.json";
import blockSheet from "./blockSheet.png";
import blockSheetJson from "./blockSheet.json";
import { loadTextures } from "./loadTextures";
import { Application } from "@pixi/app";

type TextureDaemon = {
  app: Application;
  textures: Textures;
};
const app = new Application();
const textures = loadTextures([
  { imageUrl: spritesheet, frameData: spritesheetJson },
  { imageUrl: blockSheet, frameData: blockSheetJson },
]);

export const TextureDaemonContext = React.createContext<TextureDaemon>({
  app,
  textures,
});

export const useTextures = () => {
  const { textures } = useContext(TextureDaemonContext);
  return textures;
};