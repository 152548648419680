import React, { useState } from "react";
import {
  AcademyRound,
  ResultPlayers,
  isResultPlayersList,
} from "../../shared/Academy";
import {
  Box,
  Button,
  Collapse,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IconLayout } from "./SavedLayout";
import { CosmeticPlayerSolution } from "../../shared/Cosmetics";
import { ErrorMessage } from "../../network/ErrorMessage";

interface AcademyResultProps {
  integerResult: number;
  disabled: boolean;
  accentColour: string;
  getLayouts: () => Promise<ResultPlayers[] | ErrorMessage>;
  setSolution: (solution: CosmeticPlayerSolution) => void;
  academyRound: AcademyRound;
}

export const AcademyResult = (props: AcademyResultProps) => {
  const {
    integerResult,
    accentColour,
    disabled,
    getLayouts,
    setSolution,
    academyRound,
  } = props;

  const [open, setOpen] = useState(false);
  const [resultPlayers, setResultPlayers] = useState<ResultPlayers[]>([]);

  return (
    <Paper
      sx={{
        mb: "2px",
        width: 1,
        borderLeft: "4px solid " + accentColour,
      }}
      key={integerResult}
    >
      <Button
        endIcon={disabled ? "" : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        startIcon={disabled ? "" : <ExpandMoreIcon sx={{ opacity: 0 }} />}
        disabled={disabled}
        fullWidth
        sx={{ color: "white", px: 2 }}
        onClick={() => {
          if (open) {
            setOpen(false);
          } else {
            if (resultPlayers.length === 0) {
              getLayouts().then((response) => {
                if (isResultPlayersList(response)) {
                  setResultPlayers(response);
                  setOpen(true);
                }
              });
            } else {
              setOpen(true);
            }
          }
        }}
      >
        <Typography width={1}>{integerResult}</Typography>
      </Button>
      <Collapse in={open}>
        <MenuList sx={{ px: 0, mx: 0 }}>
          {resultPlayers.map(({ players, solution }, index) => (
            <MenuItem
              sx={{ px: 1, mx: 0 }}
              key={index}
              onClick={() => {
                setSolution(solution);
              }}
            >
              <ListItemIcon>
                <IconLayout
                  board={academyRound.round.board}
                  layout={solution.solution.layout}
                />
              </ListItemIcon>
              <Typography
                minHeight={1}
                alignItems="center"
                display="flex"
                pl={1}
              >
                By&nbsp;<b>{players[0].name}</b>&nbsp;
              </Typography>
              {players.length > 1 && (
                <Tooltip
                  title={
                    <Box
                      maxHeight={300}
                      sx={{
                        overflow: "auto",
                        scrollbarGutter: "stable",
                        pr: 1,
                        mr: -1,
                        my: -0.5,
                      }}
                    >
                      {players.slice(1).map((player, index) => (
                        <Typography key={index}>
                          <b>
                            {player.guest ? <i>{player.name}</i> : player.name}
                          </b>
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Typography
                    display="inline-flex"
                    alignItems="center"
                    minHeight={1}
                  >
                    and&nbsp;<b>{players.length - 1}</b>
                    &nbsp;other
                    {players.length > 2 ? "s" : ""}
                  </Typography>
                </Tooltip>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Collapse>
    </Paper>
  );
};
