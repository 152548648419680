import { Box } from "@mui/system";
import React from "react";
import { Pattern, Rarity } from "../../shared/Cosmetics";
import { InspectablePattern } from "./InspectablePattern";
import { PatternName } from "./PatternName";

interface PatternElementProps {
  pattern: Pattern;
}

export const PatternElement = (props: PatternElementProps) => {
  return (
    <Box>
      <InspectablePattern
        blockPattern={{
          firstColor: {
            color: { name: "", tint: 0x111111 },
            indentTint: 0x111111,
            rarity: Rarity.LEGENDARY,
          },
          secondColor: {
            color: { name: "", tint: 0xffffff },
            indentTint: 0xffffff,
            rarity: Rarity.COMMON,
          },
          pattern: props.pattern,
        }}
        inspectDisabled={false}
        isTool
      />
      <PatternName pattern={props.pattern} />
    </Box>
  );
};
