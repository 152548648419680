import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import Emoji from "./Emoji";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { theme } from "../../utils/theme";
import { Player } from "../../shared/Player";
import { lt } from "../../utils/lt";
import { PlayerProfileLink } from "./PlayerProfileLink";
import { MultiplayerRoundElementProps } from "./MultiplayerRoundElement";
import { useSecondsPassed } from "../../utils/useSecondsPassed";
import { secondsToHHMMSS } from "../../utils/secToHHMMSS";
import PageVisibility from "react-page-visibility";
import { ChallengeSnapshot } from "../../shared/Challenge";
import { RoundResultsListElement } from "./RoundResultsListElement";
import { getChallengeService } from "../../network/ChallengeService";
import { isCosmeticPlayerSolutions } from "../../shared/Cosmetics";
import { TableHead } from "@material-ui/core";
import ScrollContainer from "react-indiana-drag-scroll";


export const rankedBackgroundColor = (place: number, total: number) => {
  if (place === 0) {
    return "#97804a";
  }
  if (place === 1) {
    return "#808080";
  }
  if (place === 2) {
    return "#91674a";
  }
  let ratio = 1 - ((place - 3) / (total - 4));
  return "rgb(79, 109, 128, " + ratio + ")";
};

interface ChallengeSnapshotElementProps {
  player: Player;
  snapshot: ChallengeSnapshot;
  playRound: () => void;
  hasEnded: boolean;
  normalizeScores?: boolean;
  setReplay: (
    multiplayerRoundElementProps: MultiplayerRoundElementProps
  ) => void;
}

const leftCell = { pl: 0.3, pt: 0.3, pb: 0.3, border: 0 };
const rightCell = { pt: 0.3, pb: 0.3, pr: 1.05, border: 0 };

const selectedColor = theme.palette.info.light + "15";

const noBorders = {
  "&:before": {
    display: "none",
  },
  boxShadow: "none",
};

const roundedBottoms = {
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
};
const roundedTops = { borderTopRightRadius: 4, borderTopLeftRadius: 4 };

export const ChallengeSnapshotElement = (
  props: ChallengeSnapshotElementProps
) => {
  const {
    snapshot,
    player,
    playRound,
    hasEnded,
    setReplay,
    normalizeScores = false,
  } = props;

  const maxRounds = useMemo(
    () => snapshot.parameters.settings.totalRounds,
    [snapshot]
  );

  const totals = useMemo(() => {
    let roundResults: { player: Player; score: number }[][] = [];
    snapshot.results.forEach(({ player, results }) => {
      results.forEach((score, round) => {
        if (roundResults[round]) {
          roundResults[round] = [...roundResults[round], { player, score }];
        } else {
          roundResults[round] = [{ player, score }];
        }
      });
    });
    let norm = roundResults
      .map((playerScores) => {
        let scores = playerScores.map((it) => it.score);
        let max = Math.max(...scores);
        return playerScores.map(({ player, score }) => {
          return { player, score: score / max };
        });
      })
      .reduce((acc: { player: Player; scores: number[] }[], playerScores) => {
        let newAcc = [...acc];
        playerScores.forEach(({ player, score }) => {
          let index = newAcc.findIndex((it) => it.player.id === player.id);
          if (index === -1) {
            newAcc = [...newAcc, { player, scores: [score] }];
          } else {
            newAcc[index] = {
              player,
              scores: [
                ...newAcc[index].scores,
                newAcc[index].scores[newAcc[index].scores.length - 1] + score,
              ],
            };
          }
        });
        return newAcc;
      }, []);
    return norm;
  }, [snapshot]);
  const places = useMemo(() => {
    let placeMap = new Map<number, number>();

    totals
      .sort(
        (a, b) =>
          (b.scores[maxRounds - 1] || 0) - (a.scores[maxRounds - 1] || 0)
      )
      .forEach(({ player, scores }, index) => {
        if (scores.length === maxRounds) {
          placeMap.set(player.id, index);
        }
      });
    return placeMap;
  }, [totals, maxRounds]);

  const [orderRound, setOrderRound] = useState(() =>
    lt(
      snapshot.results.sort((a, b) => b.results.length - a.results.length),
      (playerResults) =>
        playerResults.length === 0 ? 0 : playerResults[0].results.length
    )
  );

  const sortedTotals = useMemo(() => {
    let asc = orderRound > 0;

    let actualRound = Math.abs(orderRound);

    return totals.sort((a, b) =>
      asc
        ? (b.scores[actualRound - 1] || 0) - (a.scores[actualRound - 1] || 0)
        : (a.scores[actualRound - 1] || 1000000) -
          (b.scores[actualRound - 1] || 1000000)
    );
  }, [orderRound, totals]);

  const { secondsPassed, resetSecondsPassed } = useSecondsPassed();

  const isCompleted = useMemo(
    () =>
      hasEnded ||
      snapshot.results.some(
        (it) => it.results.length === snapshot.parameters.settings.totalRounds
      ),
    [snapshot, hasEnded]
  );

  const tableRef = useRef<HTMLElement | null>(null);

  const playButtonRef = useRef<any>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = 100000;
    }
  }, []);

  useEffect(() => {
    if (playButtonRef.current) {
      window.scroll(0, playButtonRef.current.offsetTop - 240);
    }
  }, []);

  return (
    <PageVisibility
      onChange={(isVisible) => {
        if (isVisible) resetSecondsPassed();
      }}
    >
      <Box sx={{ p: 1 }}>
        {snapshot.rounds.length > 0 && (
          <RoundResultsListElement
            roundHeader
            playerId={player.id}
            results={snapshot.rounds.map((round, index) => ({
              id: index,
              round,
              results: snapshot.results
                .filter((it) => it.results.length > index)
                .map((it) => ({
                  player: it.player,
                  result: it.results[index],
                }))
                .sort((a, b) => b.result - a.result),
            }))}
            normalizeScores={normalizeScores}
            replay={(round: number) => {
              getChallengeService()
                .getSolutions(snapshot.parameters.id, round)
                .then((playerSolutions) => {
                  if (isCosmeticPlayerSolutions(playerSolutions)) {
                    setReplay({
                      playerSolution:
                        playerSolutions.find(
                          (it) => it.player.id === player.id
                        ) || playerSolutions[0],
                      allSolutions: playerSolutions,
                      round: snapshot.rounds[round],
                    });
                  }
                });
            }}
            getPlayerSolution={(round: number, playerId: number) =>
              getChallengeService().getSolution(
                snapshot.parameters.id,
                round,
                playerId
              )
            }
          />
        )}
        {[...Array(snapshot.parameters.settings.totalRounds)].map((_, i) => (
          <Fragment key={i}>
            {i === snapshot.rounds.length && !hasEnded && (
              <Button
                ref={playButtonRef}
                key={"active" + i}
                fullWidth
                variant="contained"
                color="info"
                sx={{ mt: 1, mb: 1, height: 50 }}
                style={{ textTransform: "none" }}
                onClick={playRound}
              >
                <Box
                  sx={{
                    pt: 1,
                    pb: 1,
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <Typography sx={{ pt: 1, pb: 1 }}>Round {i + 1}</Typography>
                  {snapshot.roundDeadline && (
                    <Typography>
                      {secondsToHHMMSS(
                        Math.floor(snapshot.roundDeadline / 1000) -
                          secondsPassed
                      )}
                    </Typography>
                  )}
                  <PlayCircleOutlineIcon />
                </Box>
              </Button>
            )}
            {i > snapshot.rounds.length && !hasEnded && (
              <Accordion
                disabled
                key={"disabled" + i}
                elevation={i % 2 ? 1 : 0}
                sx={
                  i === snapshot.rounds.length + 1 &&
                  i ===
                    snapshot.parameters.settings.totalRounds -
                      (hasEnded ? 0 : 1)
                    ? { borderRadius: 1, ...noBorders }
                    : i === snapshot.rounds.length + 1 && !hasEnded
                    ? { ...roundedTops, ...noBorders }
                    : i === snapshot.parameters.settings.totalRounds - 1
                    ? { ...roundedBottoms, ...noBorders }
                    : noBorders
                }
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Round {i + 1}</Typography>
                </AccordionSummary>
              </Accordion>
            )}
          </Fragment>
        ))}
        {sortedTotals.length > 0 && (
          <Paper sx={{ pb: 0, mt: 1 }}>
            <Typography sx={{ p: 1, pb: 0 }}>
              <b style={{ fontSize: 20 }}>Totals</b>
              {isCompleted && <br />}
              {isCompleted && <Emoji symbol="🏆" />}
              {isCompleted && (
                <b>
                  {" "}
                  {lt(
                    totals
                      .map((it) => ({
                        player: it.player,
                        score: it.scores[maxRounds - 1] || 0,
                      }))
                      .sort((a, b) => b.score - a.score)[0],
                    ({ player, score }) => (
                      <Fragment>
                        {player.guest ? (
                          <i>{player.name}</i>
                        ) : (
                          <PlayerProfileLink playerName={player.name} />
                        )}
                        {" - " + (score * 100000).toFixed(0) + " "}
                      </Fragment>
                    )
                  )}
                </b>
              )}
              {isCompleted && <Emoji symbol="🏆" />}
            </Typography>
            <AccordionDetails
              sx={{
                m: 0,
                px: 0,
                overflow: "auto",
              }}
            >
              <ScrollContainer activationDistance={5} innerRef={tableRef}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          ...leftCell,
                          position: "sticky",
                          left: 0,
                          maxWidth: "180px",
                          bgcolor: "#424242",
                          border: 0,
                          boxShadow: "1px 0px 0px 0px rgba(100,100,100,1)",
                          zIndex: 10,
                        }}
                      ></TableCell>
                      {snapshot.results
                        .sort((a, b) => b.results.length - a.results.length)[0]
                        .results.map((_, index) => (
                          <TableCell
                            align="center"
                            sx={{
                              ...rightCell,
                            }}
                          >
                            <Button
                              sx={{
                                zIndex: 0,
                                color: "white",
                                py: 0,
                                my: 0,
                              }}
                              fullWidth
                              onClick={() =>
                                setOrderRound((o) =>
                                  o === index + 1 ? -(index + 1) : index + 1
                                )
                              }
                            >
                              <Box
                                width={"5px"}
                                sx={{ p: 0, m: 0, lineHeight: 0 }}
                              />
                              <b>{index + 1}</b>
                              <Box
                                width={"5px"}
                                sx={{ p: 0, m: 0, lineHeight: 0 }}
                              >
                                {orderRound === index + 1 ? (
                                  <ExpandMoreIcon />
                                ) : orderRound === -(index + 1) ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <Fragment />
                                )}
                              </Box>
                            </Button>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedTotals.map((playerSolution, index) => (
                      <TableRow
                        sx={
                          playerSolution.player.id === player.id
                            ? { bgcolor: selectedColor }
                            : {}
                        }
                        key={"row" + playerSolution.player.id}
                      >
                        <TableCell
                          sx={{
                            ...leftCell,
                            position: "sticky",
                            left: 0,
                            maxWidth: { xs: "100px", sm: "180px" },
                            bgcolor: "#424242",
                            border: 0,
                            boxShadow: "1px 0px 0px 0px rgba(100,100,100,1)",
                          }}
                        >
                          <Typography noWrap variant="body2">
                            <Box
                              display="inline-block"
                              minWidth="30px"
                              textAlign="center"
                              fontSize="12px"
                            >
                              <b>
                                {lt(
                                  places.get(playerSolution.player.id),
                                  (place) =>
                                    place === 0 ? (
                                      <Emoji symbol="🥇" />
                                    ) : place === 1 ? (
                                      <Emoji symbol="🥈" />
                                    ) : place === 2 ? (
                                      <Emoji symbol="🥉" />
                                    ) : place === undefined ? (
                                      ""
                                    ) : (
                                      place + 1
                                    )
                                )}
                              </b>
                            </Box>
                            {lt(playerSolution.player, (player) =>
                              player.guest ? (
                                <i>{player.name}</i>
                              ) : (
                                <PlayerProfileLink playerName={player.name} />
                              )
                            )}
                          </Typography>
                        </TableCell>
                        {snapshot.results
                          .find(
                            (it) => it.player.id === playerSolution.player.id
                          )
                          ?.results.map((it, round) => (
                            <TableCell
                              align="right"
                              sx={{
                                ...rightCell,
                                bgcolor: rankedBackgroundColor(
                                  sortedTotals.filter(
                                    (it) =>
                                      it.scores.length >= round &&
                                      it.scores[round] >
                                        playerSolution.scores[round]
                                  ).length,
                                  sortedTotals.filter(
                                    (it) => it.scores.length > round
                                  ).length
                                ),
                              }}
                            >
                              {(playerSolution.scores[round] * 100000).toFixed(
                                0
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollContainer>
            </AccordionDetails>
          </Paper>
        )}
      </Box>
    </PageVisibility>
  );
};
