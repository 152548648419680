import coins4000Image from "../resources/4000points.png";
import coins13500Image from "../resources/13500points.png";
import coins35000Image from "../resources/35000points.png";
import coins100000Image from "../resources/100000points.png";

export type ProductCode =
  | "COINS_4000"
  | "COINS_13500"
  | "COINS_35000"
  | "COINS_100000";

export function isProductCode(value: any): value is ProductCode {
  if (
    value === "COINS_4000" ||
    value === "COINS_13500" ||
    value === "COINS_35000" ||
    value === "COINS_100000"
  ) {
    return true;
  }
  return false;
}

export const productCodeToCoins = (code: ProductCode) =>
  code === "COINS_4000"
    ? 4000
    : code === "COINS_13500"
    ? 13500
    : code === "COINS_35000"
    ? 35000
    : code === "COINS_100000"
    ? 100000
    : 0;

export const productImage = (code: ProductCode) =>
  code === "COINS_4000"
    ? coins4000Image
    : code === "COINS_13500"
    ? coins13500Image
    : code === "COINS_35000"
    ? coins35000Image
    : coins100000Image;

export type Offer = {
  name: string;
  priceUSDCent: number;
  productCode: ProductCode;
};

export function isOffer(value: any): value is Offer {
  if ((value as Offer).name) {
    return true;
  }
  return false;
}

export function isOfferList(values: any): values is Offer[] {
  if (Array.isArray(values) && values.every((value) => isOffer(value))) {
    return true;
  }
  return false;
}
