import {
  Avatar,
  Badge,
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { getPlayerService } from "../network/PlayerService";
import {
  allBalls,
  Inventory,
  isInventory,
  rarityColor,
  rarityText,
  unlockableBallColors,
} from "../shared/Cosmetics";
import { cst } from "../utils/constants";
import { clearPuzzleImageCache } from "../utils/puzzleImageCacheUtils";
import { theme } from "../utils/theme";
import { MazePaper } from "./elements/MazePaper";
import LockIcon from "@mui/icons-material/Lock";
import { lt } from "../utils/lt";
import { isPlayer, Player } from "../shared/Player";
import { useNavigate } from "react-router";
import {
  ballCosmeticImage,
  blockCosmeticImage,
} from "../utils/imageGenerators";
import { useTextures } from "../game/sprites/TextureDaemon";
import { unselectable } from "../utils/unselectable";
import { RarityBadge } from "./elements/RarityBadge";
import { BlockPatternName } from "./elements/BlockPatternName";
import { SingleRoundElement } from "./elements/SingleRoundElement";
import { hueDist } from "./elements/InputPicker";
import { HuePicker } from "react-color";

export interface InventoryPageProps {
  refreshCosmetics: () => void;
  player: Player | undefined | null | "unregistered";
}

export const InventoryPage = (props: InventoryPageProps) => {
  let { refreshCosmetics, player } = props;
  const textures = useTextures();
  const [inventory, setInventory] = useState<Inventory | undefined>();

  useEffect(() => {
    getPlayerService()
      .inventory()
      .then((response) => {
        if (isInventory(response)) {
          setInventory(response);
        }
      });
  }, [setInventory]);

  const blockImage = useMemo(
    () =>
      inventory === undefined
        ? []
        : blockCosmeticImage(
            inventory.blockPatterns.map(
              (uniqueBlockColor) => uniqueBlockColor.blockPattern
            ),
            textures
          ),
    [textures, inventory]
  );

  const ballImages = useMemo(
    () =>
      inventory === undefined ? [] : ballCosmeticImage(allBalls, textures),
    [textures, inventory]
  );

  const [openUnlocks, setOpenUnlocks] = useState<string[]>([]);

  const navigate = useNavigate();
  const [sortColor, setSortColor] = useState<string>();
  const sorted = useMemo(
    () =>
      sortColor === undefined
        ? inventory?.blockPatterns
        : inventory
        ? [...inventory.blockPatterns].sort(
            (a, b) => hueDist(sortColor, a) - hueDist(sortColor, b)
          )
        : undefined,
    [inventory, sortColor]
  );
  return (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 5 }}>
      <MazePaper
        title="Inventory"
        topRight={
          <Button
            sx={{ mt: 1.8, color: "white", borderColor: "white" }}
            onClick={() => navigate("/crafting")}
            variant="outlined"
          >
            Craft
          </Button>
        }
      >
        <Grid container>
          {inventory && (
            <Fragment>
              {inventory?.bestRound && isPlayer(player) && (
                <Grid item xs={12} sx={{ pb: 3 }}>
                  <SingleRoundElement
                    round={inventory.bestRound.round}
                    playerSolution={{
                      player: player,
                      solution: inventory.bestRound.solution,
                      cosmetics: {
                        ball: {
                          color: inventory.ballColors.find(
                            (it) => it.id === inventory.activeBallColorId
                          )!.ballColor,
                        },
                        block: {
                          pattern: inventory.blockPatterns.find(
                            (it) => it.id === inventory.activeBlockPatternId
                          )!.blockPattern,
                        },
                      },
                    }}
                    place={1}
                    width={Math.min(
                      theme.breakpoints.values.sm - 64,
                      window.innerWidth - 32
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Paper sx={{ pt: 1 }}>
                  <Grid container>
                    {allBalls
                      .map((it) => ({
                        unique: inventory.ballColors.find(
                          (uniqueBallColor) =>
                            uniqueBallColor.ballColor.color.name ===
                            it.color.name
                        ),
                        ...it,
                      }))
                      .map((ball, index) => (
                        <Grid item xs={3} sm={3} key={index}>
                          <Tooltip
                            open={openUnlocks.some(
                              (it) => it === ball.color.name
                            )}
                            title={lt(
                              unlockableBallColors.find(
                                (it) =>
                                  it.ballColor.color.name === ball.color.name
                              ),
                              (unlockable) =>
                                unlockable !== undefined &&
                                isPlayer(player) &&
                                unlockable.condition(player, navigate)
                            )}
                            componentsProps={{
                              tooltip: { sx: { bgcolor: "#363636ee" } },
                            }}
                          >
                            <Badge
                              badgeContent={rarityText[ball.rarity]}
                              sx={{
                                "& .MuiBadge-badge": {
                                  background: rarityColor(ball.rarity),
                                  border: `2px solid ${theme.palette.background.paper}`,
                                  bottom: 8,
                                  right: 8,
                                  fontSize: 0,
                                  color:
                                    ball.rarity === "UNCOMMON"
                                      ? "black"
                                      : undefined,
                                  ":hover": {
                                    fontSize: "small",
                                    cursor: "default",
                                  },
                                  ...unselectable,
                                },
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <span>
                                <Button
                                  sx={{
                                    p: 0.5,
                                    borderRadius: "50%",
                                    minWidth: 0,
                                    ":disabled": {
                                      bgcolor:
                                        ball.unique === undefined
                                          ? undefined
                                          : "white",
                                    },
                                    textTransform: "none",
                                  }}
                                  disabled={
                                    ball.unique !== undefined &&
                                    ball.unique.id ===
                                      inventory.activeBallColorId
                                  }
                                  variant={
                                    ball.unique &&
                                    ball.unique.id ===
                                      inventory.activeBallColorId
                                      ? "contained"
                                      : undefined
                                  }
                                  onClick={() => {
                                    if (ball.unique) {
                                      setInventory({
                                        ...inventory,
                                        activeBallColorId: ball.unique!!.id,
                                      });
                                      getPlayerService()
                                        .setBallColor(ball.unique!!.id)
                                        .then(refreshCosmetics);
                                    } else {
                                      setOpenUnlocks((us) =>
                                        lt(
                                          us.find(
                                            (it) => it === ball.color.name
                                          ),
                                          (name) =>
                                            name
                                              ? us.filter((it) => it !== name)
                                              : [ball.color.name, ...us]
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <Avatar
                                    src={ballImages[index]}
                                    sx={{
                                      width: cst.tileSize,
                                      height: cst.tileSize,
                                    }}
                                  />
                                  {ball.unique === undefined && (
                                    <Avatar
                                      sx={{
                                        width: cst.tileSize,
                                        height: cst.tileSize,
                                        position: "absolute",
                                        bgcolor: "#00000000",
                                        color: "white",
                                      }}
                                    >
                                      <LockIcon fontSize="large" />
                                    </Avatar>
                                  )}
                                </Button>
                              </span>
                            </Badge>
                          </Tooltip>
                          <Typography>
                            <b>{ball.color.name}</b>
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ pt: 3 }}></Grid>
              <Grid container direction="row-reverse" justifyContent="flex-end">
                {inventory.blockPatterns.length > 20 && (
                  <Grid item xs={12} pb={2}>
                    <HuePicker
                      width={"100%"}
                      color={sortColor}
                      onChangeComplete={(a) => setSortColor(a.hex)}
                    />
                  </Grid>
                )}
                {sorted &&
                  sorted.map(({ blockPattern, id }, index) => (
                    <Grid item xs={4} sm={3} sx={{ mb: 1 }} key={index}>
                      <Box sx={{ py: 1, mx: 0.5 }} height={1}>
                        <RarityBadge
                          firstRarity={blockPattern.firstColor.rarity}
                          secondRarity={blockPattern.secondColor?.rarity}
                        >
                          <Button
                            sx={{
                              p: 0.5,
                              borderRadius: 2,
                              minWidth: 0,
                              ":disabled": { bgcolor: "white" },
                              textTransform: "none",
                            }}
                            disabled={id === inventory.activeBlockPatternId}
                            variant={
                              id === inventory.activeBlockPatternId
                                ? "contained"
                                : undefined
                            }
                            onClick={() => {
                              setInventory({
                                ...inventory,
                                activeBlockPatternId: id,
                              });
                              getPlayerService()
                                .setBlockPattern(id)
                                .then(refreshCosmetics);
                              clearPuzzleImageCache();
                            }}
                          >
                            <Avatar
                              variant="rounded"
                              src={
                                blockImage[
                                  inventory.blockPatterns.findIndex(
                                    (it) => it.id === id
                                  )
                                ]
                              }
                              sx={{
                                width: cst.tileSize,
                                height: cst.tileSize,
                              }}
                            />
                          </Button>
                        </RarityBadge>
                        <BlockPatternName pattern={blockPattern} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Fragment>
          )}
        </Grid>
      </MazePaper>
    </Box>
  );
};
