import axios from "axios";
import { Leaderboard, SectionOverview } from "../shared/Leaderboard";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface LeaderboardService {
  sections: () => Promise<SectionOverview | ErrorMessage>;
  leaderboard: (
    section: string,
    subSection: string,
    page: number
  ) => Promise<Leaderboard | ErrorMessage>;
  personalLeaderboard: (
    section: string,
    subSection: string
  ) => Promise<Leaderboard | ErrorMessage>;
}

export const getLeaderboardService = (): LeaderboardService => ({
  sections: () =>
    axios
      .get<SectionOverview>(cst.backendUrl + "/leaderboards")
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  leaderboard: (section: string, subSection: string, page: number) =>
    axios
      .get<Leaderboard>(
        cst.backendUrl +
          "/leaderboard/" +
          section.toLowerCase() +
          "/" +
          subSection.toLowerCase() +
          "/" +
          page
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  personalLeaderboard: (section: string, subSection: string) =>
    axios
      .get<Leaderboard>(
        cst.backendUrl +
          "/leaderboard/" +
          section.toLowerCase() +
          "/" +
          subSection.toLowerCase(),
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
