import axios from "axios";
import { BoardSize } from "../shared/Board";
import {
  ChallengeSnapshot,
  PeriodicalChallenge,
  PublicChallenges,
} from "../shared/Challenge";
import { CosmeticPlayerSolution } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { Round } from "../shared/Round";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface ChallengeService {
  start: (
    duration?: number,
    rounds?: number,
    deadline?: number,
    isPublic?: boolean,
    waypoints?: boolean,
    boardSize?: BoardSize
  ) => Promise<number | any>;
  submit: (
    challengeId: number,
    layout: Layout
  ) => Promise<CosmeticPlayerSolution[] | ErrorMessage>;
  tentative: (challengeId: number, layout: Layout) => Promise<void>;
  next: (challengeId: number) => Promise<Round | ErrorMessage>;
  get: (challengeId: number) => Promise<ChallengeSnapshot | ErrorMessage>;
  getSolution: (
    challengeId: number,
    round: number,
    playerId: number
  ) => Promise<CosmeticPlayerSolution | ErrorMessage>;
  getSolutions: (
    challengeId: number,
    round: number
  ) => Promise<CosmeticPlayerSolution[] | ErrorMessage>;
  getPeriodical: (
    periodicalChallengeId: number
  ) => Promise<PeriodicalChallenge | ErrorMessage>;
  currentPeriodical: () => Promise<PeriodicalChallenge | ErrorMessage>;
  periodicals: () => Promise<PeriodicalChallenge[] | ErrorMessage>;
  public: () => Promise<PublicChallenges | ErrorMessage>;
}

export const getChallengeService = (): ChallengeService => ({
  start: (
    duration?: number,
    rounds?: number,
    deadline?: number,
    isPublic?: boolean,
    waypoints?: boolean,
    boardSize?: BoardSize
  ) =>
    axios
      .get<number>(
        cst.backendUrl +
          "/challenge/start" +
          (duration === undefined ? "" : "?duration=" + duration) +
          (rounds === undefined ? "" : "&rounds=" + rounds) +
          (deadline === undefined ? "" : "&deadline=" + deadline) +
          (isPublic === undefined || !isPublic ? "" : "&public") +
          (waypoints === undefined || !waypoints ? "" : "&waypoints") +
          (boardSize === undefined || !boardSize ? "" : "&size=" + boardSize),
        creds
      )
      .then((response) => response.data),
  submit: (challengeId: number, layout: Layout) =>
    axios
      .post<CosmeticPlayerSolution[]>(
        cst.backendUrl + "/challenge/" + challengeId + "/submit",
        layout,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  tentative: (challengeId: number, layout: Layout) =>
    axios
      .post(
        cst.backendUrl + "/challenge/" + challengeId + "/tentative",
        layout,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  next: (challengeId: number) =>
    axios
      .get<Round>(cst.backendUrl + "/challenge/" + challengeId + "/next", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  get: (challengeId: number) =>
    axios
      .get<ChallengeSnapshot>(
        cst.backendUrl + "/challenge/" + challengeId,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSolutions: (challengeId: number, round: number) =>
    axios
      .get<CosmeticPlayerSolution[]>(
        cst.backendUrl + "/challenge/" + challengeId + "/" + round,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSolution: (challengeId: number, round: number, playerId: number) =>
    axios
      .get<CosmeticPlayerSolution>(
        cst.backendUrl +
          "/challenge/" +
          challengeId +
          "/" +
          round +
          "/" +
          playerId,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getPeriodical: (periodicalChallengeId: number) =>
    axios
      .get<PeriodicalChallenge>(
        cst.backendUrl + "/challenge/periodical/" + periodicalChallengeId,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  currentPeriodical: () =>
    axios
      .get<PeriodicalChallenge>(cst.backendUrl + "/challenge/periodical", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  periodicals: () =>
    axios
      .get<PeriodicalChallenge[]>(
        cst.backendUrl + "/challenge/periodicals",
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  public: () =>
    axios
      .get<PublicChallenges>(cst.backendUrl + "/challenge/public", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
