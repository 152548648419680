import React, { useEffect, useState } from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyButtonProps {
  link: string;
  setLinkCopied: () => void;
}

export const CopyButton = (props: CopyButtonProps) => (
  <IconButton
    onClick={() => {
      navigator.clipboard.writeText(props.link);
      props.setLinkCopied();
    }}
  >
    <ContentCopyIcon />
  </IconButton>
);

interface CopyInputProps {
  label: string | JSX.Element;
  link: string;
}

export const CopyInput = (props: CopyInputProps) => {
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (linkCopied) {
      window.setTimeout(() => mounted && setLinkCopied(false), 2000);
    }
    return () => {
      mounted = false;
    };
  }, [linkCopied, setLinkCopied]);

  return (
    <Tooltip arrow open={linkCopied} title="Link Copied">
      <TextField
        margin="normal"
        fullWidth
        onFocus={(event) => {
          event.target.select();
          setLinkCopied(true);
          navigator.clipboard.writeText(props.link);
        }}
        value={props.link}
        label={props.label}
        InputProps={{
          endAdornment: (
            <CopyButton
              link={props.link}
              setLinkCopied={() => setLinkCopied(true)}
            />
          ),
        }}
      />
    </Tooltip>
  );
};
