import { useCallback, useMemo, useRef } from "react";
import { RunnerIndicatorState } from "../../pages/elements/RunnerIndicator";
import { Coord } from "../../shared/Coord";
import { Path } from "../../shared/Path";
import { cst } from "../../utils/constants";

const pi = Math.PI;

export type RunnerState = {
  x: number;
  y: number;
  angle: number;
  isSlowed: boolean;
};

type PositionSection = {
  start: number;
  end: number;
  isSlowed: boolean;
  interceptX: number;
  slopeX: number;
  interceptY: number;
  slopeY: number;
  angle: number;
};

export type SlowInstance = {
  start: number;
  end: number;
  epicenter: Coord;
};

export type RunnerHooks = {
  getRunnerState: ((score: number) => RunnerState) | undefined;
  slowInstances: SlowInstance[] | undefined;
};

function rLerp(a: number, b: number, w: number) {
  let CS = (1 - w) * Math.cos(a) + w * Math.cos(b);
  let SN = (1 - w) * Math.sin(a) + w * Math.sin(b);
  return Math.atan2(SN, CS);
}

function normalizeRadian(r: number) {
  let angle = r;
  while (angle > pi) angle -= 2 * pi;
  while (angle < -pi) angle += 2 * pi;
  return angle;
}
const getSectionIndex = (
  score: number,
  startIndex: number,
  positionSections: PositionSection[]
) => {
  let currentIndex = Math.min(startIndex, positionSections.length - 1);
  let currentSection = positionSections[currentIndex];
  while (currentSection.start > score || currentSection.end < score) {
    if (currentSection.end < score) {
      if (currentIndex < positionSections.length - 1) {
        currentIndex += 1;
        currentSection = positionSections[currentIndex];
      } else {
        return currentIndex;
      }
    } else {
      if (currentIndex === 0) {
        return currentIndex;
      } else {
        currentIndex -= 1;
        currentSection = positionSections[currentIndex];
      }
    }
  }
  return currentIndex;
};

export const useRunnerState: (
  setRunnerIndicatorState: React.Dispatch<
    React.SetStateAction<RunnerIndicatorState>
  >,
  path?: Path
) => RunnerHooks = (
  setRunnerIndicatorState: React.Dispatch<
    React.SetStateAction<RunnerIndicatorState>
  >,
  path?: Path
) => {
  const [positionSections, slowInstances] = useMemo(() => {
    if (path === undefined || path.sections.length === 0) return [[], []];
    let currentScore = 0;
    let currentSection = path.sections[0];
    let slowedSteps = currentSection.clapCoord ? cst.clapLength : 0;
    let slowInstances: SlowInstance[] = currentSection.clapCoord
      ? [
          {
            start: 0,
            end: cst.clapLength / cst.clapModifier,
            epicenter: currentSection.clapCoord,
          },
        ]
      : [];
    const positionSections: PositionSection[] = path.sections
      .slice(1)
      .map((nextSection) => {
        let distX = currentSection.destination.x - nextSection.destination.x;
        let distY = currentSection.destination.y - nextSection.destination.y;

        let distance = Math.sqrt(distX * distX + distY * distY);

        const previousSectionEnd = currentScore;

        currentScore += distance;

        let previousSection = currentSection;
        currentSection = nextSection;
        const unitSlopeX =
          distY === 0 ? (distX > 0 ? -1 : 1) : -distX / distance;
        const unitSlopeY =
          distX === 0 ? (distY > 0 ? -1 : 1) : -distY / distance;

        const angle = normalizeRadian(
          unitSlopeX > 0
            ? Math.asin(unitSlopeY < 0 ? unitSlopeX : -unitSlopeX) -
                Math.PI *
                  (unitSlopeY < 0 ? -1 : 1) *
                  (unitSlopeX > 0 && unitSlopeY < 0 ? 0 : 1)
            : Math.acos(unitSlopeY) - Math.PI
        );

        let sections = [];

        if (slowedSteps > 0) {
          if (distance > slowedSteps) {
            currentScore += slowedSteps;
            const tempSlowedSteps = slowedSteps;
            slowedSteps = 0;
            sections = [
              {
                start: previousSectionEnd,
                end:
                  previousSectionEnd + tempSlowedSteps / cst.clapModifier,
                interceptX: previousSection.destination.x,
                slopeX: unitSlopeX * cst.clapModifier,
                interceptY: previousSection.destination.y,
                slopeY: unitSlopeY * cst.clapModifier,
                isSlowed: true,
                angle: angle,
              },
              {
                start:
                  previousSectionEnd + tempSlowedSteps / cst.clapModifier,
                end: currentScore,
                interceptX:
                  previousSection.destination.x + tempSlowedSteps * unitSlopeX,
                slopeX: unitSlopeX,
                interceptY:
                  previousSection.destination.y + tempSlowedSteps * unitSlopeY,
                slopeY: unitSlopeY,
                isSlowed: false,
                angle: angle,
              },
            ];
          } else {
            slowedSteps -= distance;
            currentScore += distance * (1 / cst.clapModifier - 1);
            sections = [
              {
                start: previousSectionEnd,
                end: currentScore,
                interceptX: previousSection.destination.x,
                slopeX: unitSlopeX * cst.clapModifier,
                interceptY: previousSection.destination.y,
                slopeY: unitSlopeY * cst.clapModifier,
                isSlowed: true,
                angle: angle,
              },
            ];
          }
        } else {
          sections = [
            {
              start: previousSectionEnd,
              end: currentScore,
              interceptX: previousSection.destination.x,
              slopeX: unitSlopeX,
              interceptY: previousSection.destination.y,
              slopeY: unitSlopeY,
              isSlowed: false,
              angle: angle,
            },
          ];
        }
        if (nextSection.clapCoord) {
          slowedSteps = cst.clapLength;
          const lastSection = sections[sections.length - 1];
          slowInstances = [
            ...slowInstances,
            {
              start: lastSection.end,
              end:
                lastSection.end + cst.clapLength / cst.clapModifier,
              epicenter: nextSection.clapCoord,
            },
          ];
        }
        return sections;
      })
      .flat();

    return [positionSections, slowInstances];
  }, [path]);

  const sectionIndex = useRef(0);

  const getRunnerState: (score: number) => RunnerState = useCallback(
    (score: number) => {
      sectionIndex.current = getSectionIndex(
        score,
        sectionIndex.current,
        positionSections
      );

      let currentSection = positionSections[sectionIndex.current];

      const sectionScore = score - currentSection.start;
      setRunnerIndicatorState((s) =>
        s === "completed"
          ? "completed"
          : currentSection.isSlowed
          ? "slowed"
          : "running"
      );

      const forwardInterpolation = 0.5;
      const backwardsInterpolation = 0.5;
      let nextSection =
        positionSections[
          getSectionIndex(
            score + forwardInterpolation,
            sectionIndex.current,
            positionSections
          )
        ];
      let previousSection =
        positionSections[
          getSectionIndex(
            score - backwardsInterpolation,
            sectionIndex.current,
            positionSections
          )
        ];

      let angle = currentSection.angle;
      if (previousSection && previousSection.angle !== currentSection.angle) {
        angle = rLerp(
          previousSection.angle,
          currentSection.angle,
          1 -
            (currentSection.start - (score - forwardInterpolation)) /
              (forwardInterpolation * 2)
        );
      } else if (nextSection && nextSection.angle !== currentSection.angle) {
        angle = rLerp(
          currentSection.angle,
          nextSection.angle,
          (score + backwardsInterpolation - currentSection.end) /
            (backwardsInterpolation * 2)
        );
      }

      return {
        x: currentSection.interceptX + currentSection.slopeX * sectionScore,
        y: currentSection.interceptY + currentSection.slopeY * sectionScore,
        isSlowed: currentSection.isSlowed,
        angle: angle,
      };
    },
    [positionSections, setRunnerIndicatorState]
  );

  return {
    getRunnerState: path ? getRunnerState : undefined,
    slowInstances: path ? slowInstances : undefined,
  };
};
