import React, { useCallback, useMemo, useRef, useState } from "react";

export interface Counter {
  increment: () => number;
  decrement: () => number;
  isZero: boolean;
  isPositive: boolean;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  decrementFromMax: (n: number) => void;
}

export function useCounter(startingValue: number, infinite: boolean): Counter {
  const [value, setValue] = useState(startingValue);
  const componentMounted = useRef(true);

  const maxValue = useRef(startingValue);

  const increment = useCallback(() => {
    if (infinite) return value;
    if (componentMounted.current) setValue(value + 1);
    return value + 1;
  }, [value, setValue, infinite]);

  const decrement = useCallback(() => {
    if (infinite) return value;
    if (value > 0) {
      if (componentMounted.current) setValue(value - 1);
    }
    return value - 1;
  }, [value, setValue, infinite]);

  const isZero = useMemo(() => !infinite && value === 0, [value, infinite]);

  const isPositive = useMemo(() => infinite || value > 0, [value, infinite]);

  const decrementFromMax = useCallback(
    (value: number) => {
      setValue(maxValue.current - value);
    },
    [setValue]
  );

  const counter = useMemo(
    () => ({
      increment,
      decrement,
      isZero,
      isPositive,
      value,
      setValue,
      decrementFromMax,
    }),
    [
      increment,
      decrement,
      isZero,
      isPositive,
      value,
      setValue,
      decrementFromMax,
    ]
  );

  return counter;
}
