import { BoardSize } from "./Board";
import { Player } from "./Player";
import { Round } from "./Round";
import { PlayerSolution } from "./Solution";

export type ChallengeSettings = {
  totalRounds: number;
  roundDuration: number;
  deadline?: number;
  waypoints?: boolean;
  public: boolean;
  size: BoardSize;
};

export type ChallengeParameters = {
  settings: ChallengeSettings;
  id: number;
};

export type PlayerResults = {
  player: Player;
  results: number[];
};

export type ChallengeSnapshot = {
  parameters: ChallengeParameters;
  results: PlayerResults[];
  rounds: Round[];
  host?: Player;
  roundDeadline?: number;
};

export function isChallengeSnapshot(value: any): value is ChallengeSnapshot {
  if ((value as ChallengeSnapshot).parameters) {
    return true;
  }
  return false;
}

export type ChallengeRoundResults = {
  solutions: { [key: number]: PlayerSolution };
};

export function isChallengeRoundResults(
  value: any
): value is ChallengeRoundResults {
  if ((value as ChallengeRoundResults).solutions) {
    return true;
  }
  return false;
}

export type PeriodicalChallenge = {
  id: number;
  period: number;
  players: Player[];
  playerCount: number;
  parameters: ChallengeParameters;
  progress: number;
  previousChallengeId: number;
};

export function isPeriodicalChallenge(
  value: any
): value is PeriodicalChallenge {
  if ((value as PeriodicalChallenge).period) {
    return true;
  }
  return false;
}

export function isPeriodicalChallenges(
  values: any
): values is PeriodicalChallenge[] {
  if (
    Array.isArray(values) &&
    values.every((value) => isPeriodicalChallenge(value))
  ) {
    return true;
  }
  return false;
}

export type PublicChallenge = {
  host: Player;
  players: Player[];
  progress: number;
  parameters: ChallengeParameters;
};

export type PublicChallenges = {
  active: PublicChallenge[];
  past: PublicChallenge[];
};

export function isPublicChallenges(value: any): value is PublicChallenges {
  if ((value as PublicChallenges).active) {
    return true;
  }
  return false;
}
