import { Sprite } from "@pixi/sprite";
import { useEffect, useRef } from "react";

export const useSpriteCleanup = (sprite: Sprite | boolean, text?: string) => {
  const oldSprite = useRef<Sprite>();
  const currentSprite = useRef<Sprite>();

  useEffect(() => {
    if (sprite === true || sprite === false) return;
    if (oldSprite.current && oldSprite.current._texture) {
      oldSprite.current.destroy(true);
    }
    oldSprite.current = currentSprite.current;
    currentSprite.current = sprite;
  }, [sprite]);

  useEffect(
    () => () => {
      if (oldSprite.current && !oldSprite.current.destroyed) {
        oldSprite.current.destroy(true);
      }
      if (currentSprite.current && !currentSprite.current.destroyed) {
        currentSprite.current.destroy(true);
      }
    },
    []
  );
};
