import { Renderer } from "@pixi/core";
import { Sprite, Graphics } from "pixi.js";
import { useMemo } from "react";
import { generateHook } from "../../pages/ToggleTest";
import { Board } from "../../shared/Board";
import { cst } from "../../utils/constants";
import { lt } from "../../utils/lt";
import { useSpriteCleanup } from "./useSpriteCleanup";

export function mulberry32(a: number) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export const dateInteger = +lt(
  new Date(),
  (today) =>
    "" +
    today.getFullYear() +
    (today.getMonth() < 10 ? "0" : "") +
    today.getMonth() +
    (today.getDate() < 10 ? "0" : "") +
    today.getDate()
);

const brickGraphics = (tileSize: number, width: number, height: number) => {
  let random = mulberry32(dateInteger);
  let graphics = new Graphics();
  let brickScale = 18 / 64;
  graphics.beginFill(0x800000);
  graphics.drawRect(0, 0, tileSize * (width + 1), tileSize * (height + 1));
  graphics.endFill();

  let brickHeight = Math.round(tileSize * brickScale);
  let brickWidth = brickHeight * 2;
  for (let i = 0; i <= Math.ceil((height + 1) / brickScale); i++) {
    for (
      let j = random() / 5;
      (j + 1) * brickWidth <= graphics.width;
      j += 1 * (1 + random() / 200)
    ) {
      graphics.beginFill(Math.round((128 + 256 * random()) * 0x010000));
      let x = j * brickWidth + ((i % 2) * brickWidth) / 2;
      let y = i * brickHeight + brickHeight / (2 - brickScale);
      graphics.drawRect(
        x,
        y,
        Math.min((brickWidth * 9) / 10, graphics.width - x),
        Math.min((brickHeight * 4) / 5, graphics.height - y)
      );
      graphics.endFill();
    }
  }
  return graphics;
};


export const scaledStaticPattern = (renderer: Renderer, width: number, height: number) => {
  let sprite = new Sprite(generateHook(renderer, brickGraphics(cst.tileSize / 2, width, height)));
  sprite.scale.x = 2;
  sprite.scale.y = 2;
  return sprite;
};

export const useStaticPattern = (renderer: Renderer, enabled: boolean, board: Board) => {
  const pattern = useMemo(
    () => enabled && scaledStaticPattern(renderer, board.width, board.height),
    [renderer, enabled, board]
  );

  useSpriteCleanup(pattern);

  return pattern;
};
