import { BlockPattern } from "./Cosmetics";

export type ShopItem = {
  id: number;
  item: BlockPattern;
  price: number;
};

export function isShopItem(value: any): value is ShopItem {
  if ((value as ShopItem).item) {
    return true;
  }
  return false;
}

export type ShopDisplay = {
  items: ShopItem[];
  expiration: number;
};

export function isShopDisplay(value: any): value is ShopDisplay {
  if ((value as ShopDisplay).items) {
    return true;
  }
  return false;
}
