import axios from "axios";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface TutorialService {
  getResult: (layout: Layout) => Promise<Path | ErrorMessage>;
}

export const getTutorialService = (): TutorialService => ({
  getResult: (layout: Layout) =>
    axios
      .post<Path>(cst.backendUrl + "/tutorial", layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
