import { Box, Grid } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import { useRef, useState } from "react";
import { Game } from "../../game/Game";
import { CosmeticPlayerSolution } from "../../shared/Cosmetics";
import { Round } from "../../shared/Round";
import { RunnerIndicator, RunnerIndicatorState } from "./RunnerIndicator";

interface ReplayElementProps {
  isPlayer?: boolean;
  playerSolution?: CosmeticPlayerSolution;
  maxScore?: number;
  completed: boolean;
  setScore?: (a: number) => void;
  setWidtho?: (a: number) => void;
  runnerCompleted?: () => void;
  place: number | undefined;
  forcedScore: number;
  startingScoreReference?: React.MutableRefObject<number>;
  round: Round;
  clickable?: boolean;
  isWatched?: boolean;
  offsetIcons?: boolean;
  pause?: boolean;
  tie?: boolean;
  startingWidth: number;
  ready?: () => void;
  start?: boolean;
}

export const ReplayElement = (props: ReplayElementProps) => {
  const {
    isPlayer = false,
    round,
    playerSolution,
    maxScore,
    startingScoreReference,
    place,
    forcedScore,
    completed,
    startingWidth,
    setScore,
    setWidtho = () => {},
    pause = false,
    runnerCompleted = () => {},
    clickable = false,
    isWatched = false,
    offsetIcons = false,
    ready = () => {},
    start = true,
  } = props;

  const [runnerState, setRunnerState] =
    useState<RunnerIndicatorState>("running");

  const target = useRef<any>();

  const [width, setWidth] = useState(startingWidth);

  useResizeObserver(target, (event) => {
    setWidth(Math.round(event.contentRect.width));
    setWidtho(Math.round(event.contentRect.width));
  });

  return (
    <Box width={1} height={1}>
      <Grid container justifyContent="center">
        <Box width={width > 500 ? width - 14 : width}>
          {playerSolution && (
            <RunnerIndicator
              player={playerSolution.player}
              isPlayer={isPlayer}
              result={playerSolution.solution.path.result}
              slowIntervals={playerSolution.solution.slowIntervals}
              state={runnerState}
              offsetIcons={offsetIcons}
              isWatched={isWatched}
              clickable={clickable}
              place={place}
              completed={completed}
              path={playerSolution.solution.path}
              isTitle
            />
          )}
        </Box>
      </Grid>
      <Box width={width} height={(width * 19.5) / 17} ref={target}>
        <Game
          startingWidth={width}
          round={round}
          score={pause ? forcedScore : startingScoreReference?.current}
          setScore={setScore}
          maxScore={maxScore}
          hasLoaded={ready}
          isReplay
          pause={pause}
          cosmetics={playerSolution?.cosmetics}
          setRunnerIndicatorState={setRunnerState}
          runnerCompleted={runnerCompleted}
          path={
            start && playerSolution ? playerSolution.solution.path : undefined
          }
          solutionLayout={
            playerSolution ? playerSolution.solution.layout : undefined
          }
        />
      </Box>
    </Box>
  );
};
