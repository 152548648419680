import { Alert, Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getPlayerService } from "../network/PlayerService";
import { getShopService } from "../network/ShopService";
import { blockPatternId, Inventory, isInventory } from "../shared/Cosmetics";
import { PlayerAccount } from "../shared/Player";
import { isShopDisplay, ShopDisplay } from "../shared/Shop";
import { useSecondsPassed } from "../utils/useSecondsPassed";
import { Points } from "./elements/Emoji";
import { MazePaper } from "./elements/MazePaper";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CoinShop } from "./CoinShop";
import { useNavigate, useParams } from "react-router";
import { isProductCode, productCodeToCoins } from "../shared/Product";
import { TipElement } from "./elements/TipElement";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { CountdownSeconds } from "./elements/CountdownSeconds";
import { InspectablePattern } from "./elements/InspectablePattern";
import { BlockPatternName } from "./elements/BlockPatternName";

export interface StoreProps {
  paymentFailed?: boolean;
  setFreshPoints: (points: number) => void;
  account: PlayerAccount | undefined;
  refreshAccount: () => void;
}

export const Store = (props: StoreProps) => {
  let {
    account,
    refreshAccount,
    setFreshPoints,
    paymentFailed = false,
  } = props;
  const [shopDisplay, setShopDisplay] = useState<ShopDisplay | undefined>();
  const [inventory, setInventory] = useState<Inventory | undefined>();

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (paymentFailed) {
      getShopService()
        .canceledOrder()
        .then(() => navigate("/store"));
    }
  }, [paymentFailed, navigate]);

  const [purchase, setPurchase] = useState<undefined | number>();

  useEffect(() => {
    if (params && params.productCode && isProductCode(params.productCode)) {
      let freshCoins = productCodeToCoins(params.productCode);
      setPurchase(freshCoins);
      if (freshCoins > 0) {
        setFreshPoints(freshCoins);
      }
      navigate("/store");
    }
  }, [params, setFreshPoints, navigate]);

  const refreshInventory = useCallback(() => {
    getPlayerService()
      .inventory()
      .then((response) => {
        if (isInventory(response)) {
          setInventory(response);
        }
      });
  }, [setInventory]);

  useEffect(() => {
    if (!paymentFailed) refreshInventory();
  }, [refreshInventory, paymentFailed]);

  const { secondsPassed, resetSecondsPassed } = useSecondsPassed();

  const refreshDisplay = useCallback(() => {
    getShopService()
      .display()
      .then((response) => {
        if (isShopDisplay(response)) {
          setShopDisplay(response);
          resetSecondsPassed();
        }
      });
  }, [setShopDisplay, resetSecondsPassed]);

  useEffect(() => {
    if (!paymentFailed) refreshDisplay();
  }, [refreshDisplay, paymentFailed]);

  useEffect(() => {
    if (shopDisplay && shopDisplay.expiration / 1000 - secondsPassed <= 0) {
      refreshDisplay();
    }
  }, [shopDisplay, secondsPassed, refreshDisplay]);

  const [justBought, setJustBought] = useState<number[]>([]);

  const [decidedPublic, setDecidedPublic] = useState<
    "anonymous" | "public" | undefined
  >();

  const [openIndex, setOpenIndex] = useState<number | undefined>();

  return (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 5 }}>
      {purchase && (
        <Alert severity="success" variant="outlined" sx={{ mx: 1, mb: 2 }}>
          <Typography>
            <Typography color="#f3fff3">
              <Typography variant="h5">
                <b>Thank you for your purchase!</b>
              </Typography>
              <Typography variant="h6" sx={{ pt: 1 }}>
                Your order of{" "}
                <b>
                  {purchase} <Points variant="h6" />
                </b>{" "}
                has been added to your account
              </Typography>
            </Typography>
            <br />
            Thanks a lot for supporting <b>maze.game</b> <br />
            it is through your support that games like this can exist!
            <br />
            <br />
            As a part of your purchase, another approx. {
              purchase
            } <Points /> will be divided among the prize pools of all upcoming{" "}
            <b>Puzzles</b>, and all mazers have been given a free play of{" "}
            <b>Arena</b>!
            <br />
            <br />
            Choose below whether you would like to make these contributions to
            the community public or keep them anonymous
          </Typography>
          <Grid>
            <Fragment>
              <Tooltip
                title={
                  <Fragment>
                    The upcoming <b>Puzzles</b> will list your username as a
                    sponsor, and mazers will see that you sponsored their free
                    play of <b>Arena</b>
                  </Fragment>
                }
              >
                <Button
                  color={"success"}
                  endIcon={
                    decidedPublic === "public" ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )
                  }
                  onClick={() => {
                    getShopService().sponsorPublic();
                    setDecidedPublic("public");
                  }}
                >
                  Public
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <Fragment>
                    The upcoming <b>Puzzles</b> will credit "
                    <b>an anonymous mazer</b>" as a sponsor, and mazers will see
                    that "<b>an anonymous mazer</b>" sponsored their free play
                    of <b>Arena</b>
                  </Fragment>
                }
              >
                <Button
                  color={"success"}
                  endIcon={
                    decidedPublic === "anonymous" ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )
                  }
                  onClick={() => {
                    getShopService().sponsorAnonymous();
                    setDecidedPublic("anonymous");
                  }}
                >
                  Anonymous
                </Button>
              </Tooltip>
            </Fragment>
          </Grid>
        </Alert>
      )}
      {shopDisplay && (
        <Box>
          <MazePaper title="Store" goBack={() => navigate(-1)}>
            <TipElement localStorageKey="storeTip">
              <Typography>
                You can see your items and change your cosmetics by clicking on
                your Block next to your <Points /> and tapping <b>Inventory</b>
              </Typography>
            </TipElement>
            <Chip
              sx={{ mt: -2, mb: 2 }}
              avatar={<RefreshIcon />}
              label={
                <Typography sx={{ mt: 0.4 }}>
                  <b>
                    <CountdownSeconds seconds={shopDisplay.expiration / 1000} />
                  </b>
                </Typography>
              }
            />
            <Grid container rowSpacing={4} direction="row-reverse">
              {shopDisplay.items.map(({ item, id, price }, index) => {
                let owned =
                  inventory &&
                  inventory.blockPatterns.some(
                    (it) =>
                      blockPatternId(it.blockPattern) === blockPatternId(item)
                  );
                return (
                  <Grid item xs={4} sm={4} key={index}>
                    <BlockPatternName pattern={item} />
                    <InspectablePattern
                      blockPattern={item}
                      forceClosed={openIndex !== index}
                      onInspect={() => setOpenIndex(index)}
                    />
                    <Button
                      sx={{ py: 1, px: 1, mt: 1, textTransform: "none" }}
                      disabled={
                        account === undefined ||
                        inventory === undefined ||
                        account.points < price ||
                        justBought.some((boughtId) => boughtId === id) ||
                        owned
                      }
                      onClick={() => {
                        getShopService()
                          .buy(id)
                          .then(() => {
                            refreshAccount();
                            refreshInventory();
                          });
                        setJustBought((ids) => [id, ...ids]);
                      }}
                      variant={owned ? undefined : "contained"}
                      color="success"
                    >
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Typography sx={{ pr: owned ? 1 : 2, pl: 1 }}>
                            {owned ? "Owned" : "Buy"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Chip
                            color="error"
                            size="small"
                            sx={{
                              cursor: "pointer",
                              display: owned ? "none" : undefined,
                            }}
                            label={
                              <Typography>
                                <Points />
                                {" " + price}
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </MazePaper>
          <CoinShop />
        </Box>
      )}
    </Box>
  );
};
