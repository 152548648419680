import { Button, Chip, Typography } from "@mui/material";
import React from "react";
import { Points } from "./elements/Emoji";

interface CostButtonProps {
  disabled: boolean,
  label: string,
  cost: number,
  onClick: () => void,
  variant?: "outlined" | "contained"
}

export const CostButton = (props: CostButtonProps) => (
  <Button
    sx={{ py: 1, px: 1 }}
    disabled={props.disabled}
    onClick={props.onClick}
    variant={props.variant}
  >
    <Typography sx={{ pr: 2, pl: 1 }}>{props.label}</Typography>
    <Chip
      color="error"
      size="small"
      label={
        <Typography>
          <Points />
          {" " + props.cost}
        </Typography>
      }
    />
  </Button>
);
