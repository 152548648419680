import { lt } from "./lt";

export const periodicalName = (minutes: number) =>
  minutes === 10080
    ? "Weekly"
    : minutes === 1440
    ? "Daily"
    : minutes < 60
    ? minutes + " Minutes"
    : lt(
        Math.floor(minutes / 60),
        (hours) => hours + " Hour" 
      );
