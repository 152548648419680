import axios from "axios";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface NotificationService {
  unread: () => Promise<Notification[] | ErrorMessage>;
  token: () => Promise<string | ErrorMessage>;
  dismiss: (id: number) => Promise<void | ErrorMessage>;
  dismissAll: () => Promise<void | ErrorMessage>;
}

export const getNotificationService = (): NotificationService => ({
  unread: () =>
    axios
      .get<Notification[]>(
        cst.backendUrl + "/notifications/unread",
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  token: () =>
    axios
      .get<string>(cst.backendUrl + "/notifications/token", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  dismiss: (id: number) =>
    axios
      .post<void>(
        cst.backendUrl + "/notifications/dismiss/" + id,
        undefined,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  dismissAll: () =>
    axios
      .post<void>(
        cst.backendUrl + "/notifications/dismiss/all",
        undefined,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
