import { Button, Checkbox, Link, Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getBountyService } from "../network/BountyService";
import {
  ActivationResponse,
  BountyReward,
  BountyRound,
  BountyState,
  isActivationResponse,
  isBountyReward,
  isBountyRound,
  isBountyState,
} from "../shared/Bounty";
import {
  CosmeticRoundResult,
  Cosmetics,
  isCosmeticRoundResult,
} from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { Player } from "../shared/Player";
import { secondsToHHMMSS } from "../utils/secToHHMMSS";
import { RoundResultElement } from "./elements/RoundResultElement";
import { BodyContent } from "./elements/BodyContent";
import { BountyRewardElement } from "./elements/BountyRewardElement";
import { InteractiveGame } from "./elements/InteractiveGame";
import { MazePaper } from "./elements/MazePaper";
import { PlayerProfileLink } from "./elements/PlayerProfileLink";
import { useTentativeLayout } from "../utils/useTentativeLayout";

const calcDurationValue = (it: number) => (it < 12 ? it * 10 : (it - 10) * 60);

interface BountyProps {
  cosmetics: Cosmetics;
  showResults?: boolean;
  player: Player;
  refreshAccount: () => void;
}

export const Bounty = (props: BountyProps) => {
  const { cosmetics, showResults, player, refreshAccount } = props;
  const [state, setState] = useState<BountyState>();

  useEffect(() => {
    getBountyService()
      .state()
      .then((response) => {
        if (isBountyState(response)) {
          setState(response);
        }
      });
  }, [setState]);

  const [activationResponse, setActivationResponse] =
    useState<ActivationResponse>();

  const [roundResult, setRoundResult] = useState<CosmeticRoundResult>();

  const [hasShownResults, setHasShownResults] = useState(false);

  useEffect(() => {
    if (roundResult) setHasShownResults(true);
  }, [roundResult, setHasShownResults]);

  useEffect(() => {
    if (showResults && !roundResult && !hasShownResults) {
      getBountyService()
        .result()
        .then((response) => {
          if (isCosmeticRoundResult(response)) {
            setRoundResult(response);
          }
        });
    }
  }, [showResults, roundResult, hasShownResults]);

  const activate = useCallback(
    () =>
      getBountyService()
        .activate()
        .then((response) => {
          if (isActivationResponse(response)) {
            if (response.newState === "HOSTING") {
              setState("HOSTING");
            } else {
              setActivationResponse(response);
              setState("ACTIVE");
            }
          }
        }),
    [setActivationResponse, setState]
  );

  const navigate = useNavigate();

  const [value, setValue] = useState(3);
  const [waypoints, setWaypoints] = useState(false);

  const [bountyReward, setBountyReward] = useState<BountyReward>();

  const [bountyRound, setBountyRound] = useState<BountyRound>();

  const [deadline, setDeadline] = useState<Date>();
  const [duration, setDuration] = useState(0);

  const getRound = useCallback(() => {
    getBountyService()
      .round()
      .then((response) => {
        if (isBountyRound(response)) {
          setDuration(response.duration);
          setDeadline(new Date(response.duration * 1000 + Date.now()));
          setBountyRound(response);
        }
      });
  }, [setBountyRound]);

  const host = useCallback(() => {
    getBountyService()
      .host({ duration: calcDurationValue(value), waypoints: waypoints })
      .then((response) => {
        if (isBountyRound(response)) {
          setDuration(response.duration);
          setDeadline(new Date(response.duration * 1000 + Date.now()));
          setBountyRound(response);
        }
      });
  }, [setBountyRound, value, waypoints]);

  const claim = useCallback(() => {
    getBountyService()
      .claim()
      .then((response) => {
        if (isBountyReward(response)) {
          setBountyReward(response);
        }
      });
  }, [setBountyReward]);

  const submit = useCallback(
    (layout: Layout) => {
      getBountyService()
        .submit(layout)
        .then((response) => {
          if (isCosmeticRoundResult(response)) {
            setRoundResult(response);
            setBountyRound(undefined);
          }
        });
    },
    [setRoundResult]
  );

  useEffect(() => {
    if (state === "ACTIVE" && !activationResponse) {
      getBountyService()
        .activationResponse()
        .then((response) => {
          if (isActivationResponse(response)) {
            setActivationResponse(response);
          }
        });
    } else if (state === "READY") {
      activate();
    } else if (state === "PLAYING") {
      getRound();
    }
  }, [
    state,
    activationResponse,
    activate,
    setActivationResponse,
    getRound,
    player,
  ]);

  const { onLayoutChange, onBeforeUnload, onPageVisibitilyChange } =
    useTentativeLayout(
      getBountyService().tentative,
      submit,
      setDuration,
      deadline
    );

  return bountyRound ? (
    <BodyContent>
      <InteractiveGame
        round={bountyRound.round}
        submitLayout={submit}
        cosmetics={cosmetics}
        duration={duration}
        onBeforeUnload={onBeforeUnload}
        onLayoutChange={onLayoutChange}
        onPageVisibitilyChange={onPageVisibitilyChange}
        origin={["Bounty"]}
      />
    </BodyContent>
  ) : roundResult ? (
    <RoundResultElement
      roundResult={roundResult}
      replayFinished={() => {
        if (roundResult.opponentSolution) {
          setState("CLAIMABLE");
          claim();
        } else {
          setState("WAITING");
        }
        setRoundResult(undefined);
      }}
    />
  ) : (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 2 }}>
      <MazePaper title={"Bounties"}>
        {state === "ROLLING" && (
          <Box>
            <Typography>
              You do not have an active <b>Bounty</b>, you can get{" "}
              <b>Bounties</b> by playing in the{" "}
              <Link
                underline="none"
                href="#"
                onClick={() => navigate("/arena")}
              >
                Arena
              </Link>
              , in{" "}
              <Link
                underline="none"
                href="#"
                onClick={() => navigate("/puzzle")}
              >
                Puzzles
              </Link>
              , and in the{" "}
              <Link
                underline="none"
                href="#"
                onClick={() => navigate("/challenge/daily")}
              >
                Daily
              </Link>
              !
            </Typography>
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              sx={{ mt: 3 }}
            >
              OK
            </Button>
          </Box>
        )}
        {state === "ACTIVE" && activationResponse && (
          <Box>
            <Typography>
              You uncovered a <b>Bounty</b> set by
              <b>
                {" "}
                <PlayerProfileLink playerName={activationResponse.host.name} />
              </b>
              !
              <br />
              <br />
              They challenged you to a round{" "}
              {activationResponse.bountyParameters.waypoints ? (
                <Fragment>
                  with a <b>Waypoint</b> and{" "}
                </Fragment>
              ) : (
                "with "
              )}
              a{" "}
              <b>
                {secondsToHHMMSS(
                  activationResponse.bountyParameters.duration,
                  true
                )}{" "}
              </b>{" "}
              time limit!
            </Typography>
            <Button
              variant={"contained"}
              sx={{ mt: 3, mb: 2 }}
              onClick={getRound}
            >
              play bounty
            </Button>
            <Typography variant="caption" display="block">
              You have {Math.round(activationResponse.deadline / 60000)} minutes
              to respond to this <b>Bounty</b> before it expires
            </Typography>
          </Box>
        )}
        {state === "HOSTING" && (
          <Box>
            <Typography>
              You get to set a <b>Bounty</b>!<br /> You choose the parameters,
              but the winner will reap the rewards!
              <br />
              <br />
              <b>
                Round Duration:{" "}
                {secondsToHHMMSS(calcDurationValue(value), true)}
              </b>
              <Slider
                value={value}
                step={1}
                max={20}
                min={3}
                onChange={(_, value) =>
                  typeof value === "number" && setValue(value)
                }
              />
              <b>With Waypoint</b>
              <Checkbox
                value={waypoints}
                onChange={(event, value) => setWaypoints(value)}
              />
              <br />
              <Button variant="contained" sx={{ mt: 3 }} onClick={host}>
                Play Bounty
              </Button>
            </Typography>
          </Box>
        )}
        {state === "WAITING" && (
          <Box>
            <Typography>
              You have played your <b>Bounty</b>!<br />
              <br />
              You will be notified when another mazer finds it!
            </Typography>
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              sx={{ mt: 3 }}
            >
              OK
            </Button>
          </Box>
        )}
        {bountyReward && (
          <BountyRewardElement
            bountyReward={bountyReward}
            rewardClaimed={() => {
              refreshAccount();
              navigate(-1);
            }}
          />
        )}
      </MazePaper>
    </Box>
  );
};
