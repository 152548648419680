import { CosmeticPlayerSolution } from "./Cosmetics";
import { Round } from "./Round";

export type SeedInfo = {
  seed: number;
  solutions: number;
  averageScore: number;
};

export function isSeedInfo(value: any): value is SeedInfo {
  if ((value as SeedInfo).solutions) {
    return true;
  }
  return false;
}

export function isSeedInfos(values: any): values is SeedInfo[] {
  if (
    Array.isArray(values) &&
    (values as any[]).every((value) => isSeedInfo(value))
  ) {
    return true;
  }
  return false;
}

export type SeedSolutions = {
  round: Round;
  playerSolutions: CosmeticPlayerSolution[];
};

export function isSeedSolutions(value: any): value is SeedSolutions {
  if ((value as SeedSolutions).round) {
    return true;
  }
  return false;
}
