import { Button, Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { SketchPicker } from "react-color";
import { CosmeticPlayerSolution, defaultCosmetics, Rarity } from "../shared/Cosmetics";
import { Round } from "../shared/Round";
import { hexToRGB, rgbToInt } from "../utils/colors";
import { MultiplayerRoundElement } from "./elements/MultiplayerRoundElement";

const playerSolution: CosmeticPlayerSolution = {
  player: {
    name: "boubou",
    id: 1,
    guest: false,
  },
  cosmetics: defaultCosmetics,
  solution: {
    layout: {
      towers: [
        {
          coord: {
            x: 3,
            y: 14,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 4,
            y: 5,
          },
          static: false,
          clap: true,
        },
        {
          coord: {
            x: 6,
            y: 3,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 6,
            y: 16,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 7,
            y: 1,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 7,
            y: 6,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 9,
            y: 7,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 10,
            y: 11,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 10,
            y: 13,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 11,
            y: 3,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 12,
            y: 15,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 13,
            y: 2,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 13,
            y: 9,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 14,
            y: 4,
          },
          static: false,
          clap: false,
        },
        {
          coord: {
            x: 14,
            y: 12,
          },
          static: false,
          clap: false,
        },
      ],
    },
    path: {
      sections: [
        {
          destination: {
            x: 8.0,
            y: 18.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 8.0,
            y: 17.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 14.0,
            y: 17.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 14.0,
            y: 14.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 13.0,
            y: 14.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 13.0,
            y: 12.800095,
          },
          clapCoord: {
            x: 11,
            y: 9,
          },
        },
        {
          destination: {
            x: 13.0,
            y: 11.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 15.0,
            y: 11.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 15.0,
            y: 8.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 13.0,
            y: 8.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 13.0,
            y: 5.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 8.090351,
            y: 5.0,
          },
          clapCoord: {
            x: 4,
            y: 5,
          },
        },
        {
          destination: {
            x: 6.0,
            y: 5.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 6.0,
            y: 8.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 8.0,
            y: 8.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 8.0,
            y: 8.556271,
          },
          clapCoord: {
            x: 11,
            y: 9,
          },
        },
        {
          destination: {
            x: 8.0,
            y: 9.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 9.0,
            y: 10.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 9.0,
            y: 13.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 7.0,
            y: 13.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 7.0,
            y: 15.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 5.0,
            y: 15.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 5.0,
            y: 16.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 3.0,
            y: 17.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 2.0940132,
            y: 17.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 0.0,
            y: 17.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 0.0,
            y: 14.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 2.0,
            y: 13.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 4.0,
            y: 12.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 5.0,
            y: 12.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 5.0,
            y: 9.090355,
          },
          clapCoord: {
            x: 4,
            y: 5,
          },
        },
        {
          destination: {
            x: 5.0,
            y: 9.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 3.0,
            y: 9.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 3.0,
            y: 2.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 4.0,
            y: 2.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 6.0,
            y: 2.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 6.0,
            y: 0.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 6.909645,
            y: 0.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 8.0,
            y: 0.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 15.0,
            y: 0.0,
          },
          clapCoord: undefined,
        },
        {
          destination: {
            x: 16.0,
            y: 0.0,
          },
          clapCoord: undefined,
        },
      ],
      result: 141.76904296875,
      integerResult: 141769,
    },
    slowIntervals: undefined,
  },
};

const round: Round = {
  towers: 20,
  claps: 1,
  board: {
    width: 16,
    height: 18,
    staticTowers: [
      {
        coord: {
          x: 11,
          y: 9,
        },
        static: true,
        clap: true,
      },
      {
        coord: {
          x: 11,
          y: 6,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 4,
          y: 3,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 1,
          y: 8,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 7,
          y: 11,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 4,
          y: 7,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 9,
          y: 1,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 9,
          y: 3,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 3,
          y: 10,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 1,
          y: 15,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 6,
          y: 9,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 8,
          y: 14,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 1,
          y: 6,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 0,
          y: 11,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 4,
          y: 0,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 0,
          y: 4,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 5,
          y: 13,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 14,
          y: 6,
        },
        static: true,
        clap: false,
      },
    ],
    startArea: [
      {
        x: 8,
        y: 18,
      },
      {
        x: 7,
        y: 18,
      },
    ],
    endArea: [
      {
        x: 16,
        y: 0,
      },
      {
        x: 16,
        y: 1,
      },
    ],
  },
};

export const RunnerTest = () => {

  const [ballColor, setBallColor] = useState("#7ed321");
  const [frostTint, setFrostTint] = useState("#14d55c");

  const [show, setShow] = useState(false)


  return (
    <Fragment>
      {!show && <Button onClick={() => setShow(true)}>Show</Button>}
      {show && 
      <MultiplayerRoundElement
        round={round}
        playerSolution={playerSolution}
        allSolutions={[
          playerSolution,
          {
            ...playerSolution,
            player: { ...playerSolution.player, id: 13, name: "asdfsdsaaf" },
            cosmetics: {
              ...defaultCosmetics,
              ball: {
                color: {
                  frozenTint: rgbToInt(hexToRGB(frostTint)),
                  color: { name: "test", tint: rgbToInt(hexToRGB(ballColor)) },
                  rarity: Rarity.COMMON,
                },
              },
            },
          },
          {
            ...playerSolution,
            player: { ...playerSolution.player, id: 14, name: "ifer" },
          },
          {
            ...playerSolution,
            player: { ...playerSolution.player, id: 15, name: "ssdas" },
          },
          {
            ...playerSolution,
            player: { ...playerSolution.player, id: 15, name: "ridas" },
          },
        ]}
        playerRanks={new Map()}
        runnerCompleted={() => {setShow(false)}}
      />}
      <Grid display="none" container width={1} justifyContent="center">
        <SketchPicker
          onChange={(result) => setBallColor(result.hex)}
          color={ballColor}
        />
        <SketchPicker
          onChange={(result) => setFrostTint(result.hex)}
          color={frostTint}
        />
      </Grid>
    </Fragment>
  );
};
