import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout } from "../shared/Layout";
import { Round } from "../shared/Round";
import { Player } from "../shared/Player";
import { getPuzzleService } from "../network/PuzzleService";
import { isPuzzleResult } from "../shared/Puzzle";
import { BodyContent } from "./elements/BodyContent";
import { useCheckAchievements } from "../utils/useCheckAchievements";
import { MultiplayerRoundElement } from "./elements/MultiplayerRoundElement";
import { InteractiveGame } from "./elements/InteractiveGame";
import {
  CosmeticPlayerSolution,
  CosmeticPuzzleSnapshot,
  Cosmetics,
  defaultCosmetics,
  isCosmeticPuzzleSnapshot,
} from "../shared/Cosmetics";
import { useNavigate, useParams } from "react-router";
import { periodicalName } from "../utils/periodicalName";
import { formatScoreAsNumber } from "../utils/formatScore";

interface PuzzleProps {
  player: Player;
  cosmetics: Cosmetics | undefined;
}

export const Puzzle = (props: PuzzleProps) => {
  const { player, cosmetics = defaultCosmetics } = props;

  const { periodical } = useParams();

  const puzzleId = useMemo(
    () => (periodical && !isNaN(+periodical) ? +periodical : undefined),
    [periodical]
  );

  const [snapshot, setSnapshot] = useState<
    undefined | CosmeticPuzzleSnapshot
  >();

  const [duration, setDuration] = useState<number | undefined>();

  const [deadline, setDeadline] = useState<Date | undefined>();

  const [playerSolution, setPlayerSolution] = useState<
    undefined | CosmeticPlayerSolution
  >();

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (snapshot && snapshot.currentPuzzle.info.solution) {
      setPlayerSolution(snapshot.currentPuzzle.info.solution);
    }
  }, [snapshot, setPlayerSolution]);

  const checkAchievements = useCheckAchievements();

  const navigate = useNavigate();

  const submitLayout = useCallback(
    (layout: Layout) => {
      player &&
        puzzleId &&
        visible &&
        getPuzzleService()
          .submit(puzzleId, layout)
          .then((response) => {
            if (isPuzzleResult(response)) {
              setSnapshot(
                (s) =>
                  s && {
                    ...s,
                    currentPuzzle: {
                      ...s.currentPuzzle,
                      results: response.results,
                      info: {
                        ...s.currentPuzzle.info,
                        solution: {
                          player,
                          solution: { path: response.path, layout: layout },
                          cosmetics,
                        },
                      },
                    },
                  }
              );
              if (!player.guest) {
                checkAchievements();
              }
            } else {
              console.error(response);
            }
          });
    },
    [player, checkAchievements, puzzleId, visible, cosmetics]
  );

  const [round, setRound] = useState<Round | undefined>();

  const runnerCompleted = useCallback(() => {
    puzzleId && navigate("/puzzle/" + puzzleId);
  }, [navigate, puzzleId]);

  const refreshTimer = useRef<number>();
  const checkDeadline = useCallback(() => {
    if (deadline) {
      let timeLeft = (deadline.getTime() - Date.now()) / 1000;
      if (timeLeft < 5) {
        navigate("/puzzle/" + puzzleId);
      } else if (timeLeft < 605) {
        setDuration(Math.floor(timeLeft - 5));
      } else {
        window.clearTimeout(refreshTimer.current);
        refreshTimer.current = window.setTimeout(
          () => mounted.current && checkDeadline(),
          (timeLeft - 604) * 1000
        );
      }
    }
  }, [deadline, setDuration, puzzleId, navigate]);

  const refreshSnapshot = useCallback(() => {
    puzzleId &&
      getPuzzleService()
        .snapshot(puzzleId)
        .then((response) => {
          if (isCosmeticPuzzleSnapshot(response)) {
            setSnapshot(response);
            setRound(response.currentPuzzle.round);
            setDeadline(
              new Date(Date.now() + response.currentPuzzle.info.deadline)
            );
            if (response.currentPuzzle.info.solution === undefined) {
              getPuzzleService().startedRound(puzzleId);
            }
            checkDeadline();
          } else {
            console.error(response);
          }
        });
  }, [puzzleId, setSnapshot, setRound, setDeadline, checkDeadline]);

  useEffect(() => {
    if (snapshot === undefined) {
      refreshSnapshot();
    }
  }, [refreshSnapshot, snapshot]);

  const mounted = useRef(true);

  useEffect(checkDeadline, [checkDeadline]);

  const allSolutions = useMemo(
    () =>
      snapshot && snapshot.currentPuzzle
        ? snapshot.currentPuzzle.results.map((result) => ({
            player: result.player,
            solution: {
              path: {
                sections: [],
                result: result.result ? result.result : 0,
                integerResult: result.result
                  ? formatScoreAsNumber(result.result)
                  : 0,
              },
              slowIntervals: result.slowIntervals,
              layout: { towers: [] },
            },
            cosmetics: defaultCosmetics,
          }))
        : [],
    [snapshot]
  );

  const playerRanks = useMemo(
    () =>
      snapshot && snapshot.currentPuzzle
        ? snapshot.currentPuzzle.results.reduce((rankMap, result, index) => {
            rankMap.set(result.player.id, index + 1);
            return rankMap;
          }, new Map<number, number>())
        : new Map<number, number>(),
    [snapshot]
  );

  return (
    <Fragment>
      {round &&
        (playerSolution && snapshot ? (
          <MultiplayerRoundElement
            hiddenOpponents
            round={round}
            playerSolution={playerSolution}
            allSolutions={allSolutions}
            playerRanks={playerRanks}
            runnerCompleted={runnerCompleted}
          />
        ) : (
          <BodyContent>
            <InteractiveGame
              onPageVisibitilyChange={(isVisible) => {
                setVisible(isVisible);
                if (isVisible && deadline) {
                  checkDeadline();
                }
              }}
              cosmetics={cosmetics}
              round={round}
              duration={duration}
              submitLayout={submitLayout}
              origin={
                puzzleId ? ["Puzzle", periodicalName(puzzleId)] : undefined
              }
            />
          </BodyContent>
        ))}
    </Fragment>
  );
};
