import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { GameTutorial } from "../game/GameTutorial";
import { getMetricsService } from "../network/MetricsService";

export const Tutorial = () => {
  const hasLogged = useRef(false);

  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      getMetricsService().logOpenTutorial()
    }
  }, [hasLogged])
  
  return (
    <Box sx={{ pt: 3 }} maxWidth="lg" mx="auto" height="90vh">
      <GameTutorial />
    </Box>
  );
};
