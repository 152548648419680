import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Table,
  TableCell,
  Typography,
} from "@mui/material";
import { isPlayer, Player } from "../shared/Player";
import { useNavigate, useParams } from "react-router";
import { getLeaderboardService } from "../network/LeaderboardService";
import { MazePaper } from "./elements/MazePaper";
import {
  isLeaderboard,
  isSectionOverview,
  Leaderboard,
  Section,
  SectionOverview,
} from "../shared/Leaderboard";
import { lt } from "../utils/lt";
import { TableBody, TableRow } from "@material-ui/core";
import Emoji from "./elements/Emoji";
import { PlayerProfileLink } from "./elements/PlayerProfileLink";

interface LeaderboardsProps {
  player: Player | undefined | null;
}

export const Leaderboards = (props: LeaderboardsProps) => {
  const { player } = props;
  const { section, subSection } = useParams();

  const [sectionOverview, setSectionOverview] = useState<SectionOverview>();
  const [leaderboard, setLeaderboard] = useState<Leaderboard>();

  useEffect(() => {
    getLeaderboardService()
      .sections()
      .then((response) => {
        if (isSectionOverview(response)) {
          setSectionOverview(response);
        }
      });
  }, [setSectionOverview]);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const gotoMe = useCallback(
    () =>
      section &&
      subSection &&
      getLeaderboardService()
        .personalLeaderboard(section, subSection)
        .then((response) => {
          if (isLeaderboard(response)) {
            setPage(response.page);
          }
        }),
    [setPage, section, subSection]
  );

  useEffect(() => {
    if (section) {
      if (subSection) {
        getLeaderboardService()
          .leaderboard(section, subSection, page)
          .then((response) => {
            if (isLeaderboard(response)) {
              setLeaderboard(response);
            }
          });
      } else {
        setLeaderboard(undefined);
        setPage(0);
      }
    }
  }, [section, subSection, page]);

  return (
    <Box sx={{ pt: 3 }} maxWidth="sm" mx="auto" height="90vh">
      <MazePaper title="Leaderboards" goBack={() => navigate("/")}>
        <Stack direction="row">
          {sectionOverview &&
            Object.keys(sectionOverview).map((it) => (
              <Button
                fullWidth
                disabled={section === it.toLowerCase()}
                onClick={() => navigate("/leaderboards/" + it.toLowerCase())}
                sx={{
                  color: "white",
                  borderBottom: 4,
                  borderRadius: 0,
                  borderColor: "#00000000",
                  ":disabled": {
                    borderColor: "white",
                    color: "white",
                  },
                  ":hover": {
                    borderColor: "gray",
                    bgcolor: "#00000000",
                  },
                }}
              >
                {it}
              </Button>
            ))}
        </Stack>
        <Stack direction="row">
          {section &&
            sectionOverview &&
            lt(
              sectionOverview[section.toUpperCase() as Section],
              (subSections) =>
                subSections.map((it) => (
                  <Button
                    fullWidth
                    disabled={subSection === it.toLowerCase()}
                    onClick={() => {
                      navigate(
                        "/leaderboards/" + section + "/" + it.toLowerCase()
                      );
                      setPage(0);
                    }}
                    sx={{
                      color: "white",
                      borderBottom: 4,
                      borderRadius: 0,
                      borderColor: "#00000000",
                      ":disabled": {
                        borderColor: "white",
                        color: "white",
                      },
                      ":hover": {
                        borderColor: "gray",
                        bgcolor: "#00000000",
                      },
                    }}
                  >
                    {it}
                  </Button>
                ))
            )}
        </Stack>
        {leaderboard && (
          <Fragment>
            <Table sx={{ mt: 1 }}>
              <TableBody>
                {leaderboard.result.map((row, index) => (
                  <TableRow>
                    <TableCell
                      sx={{
                        py: 0.75,
                        bgcolor:
                          player && isPlayer(player) && player.name === row.name
                            ? "rgba(79,109,128, 0.9)"
                            : index % 2 === 1
                            ? "rgba(79, 109, 128, 0.3)"
                            : undefined,
                        width: "30px",
                      }}
                    >
                      <Typography noWrap variant="body2">
                        <Box
                          display="inline-block"
                          minWidth="30px"
                          textAlign="center"
                          fontSize="12px"
                        >
                          <b>
                            {lt(leaderboard.position + index, (place) =>
                              place === 0 ? (
                                <Emoji symbol="🥇" />
                              ) : place === 1 ? (
                                <Emoji symbol="🥈" />
                              ) : place === 2 ? (
                                <Emoji symbol="🥉" />
                              ) : place === undefined ? (
                                ""
                              ) : (
                                place + 1
                              )
                            )}
                          </b>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                        bgcolor:
                          player && isPlayer(player) && player.name === row.name
                            ? "rgba(79,109,128, 0.9)"
                            : index % 2 === 1
                            ? "rgba(79, 109, 128, 0.3)"
                            : undefined,
                      }}
                    >
                      <b>
                        <PlayerProfileLink playerName={row.name} />
                      </b>
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                        bgcolor:
                          player && isPlayer(player) && player.name === row.name
                            ? "rgba(79,109,128, 0.9)"
                            : index % 2 === 1
                            ? "rgba(79, 109, 128, 0.3)"
                            : undefined,
                        textAlign: "right",
                      }}
                    >
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack direction="row" sx={{ overflow: "hidden" }}>
              {lt(Array.from(Array(7).keys()), (pages) =>
                pages.map((pageNumber) =>
                  lt(
                    pageNumber +
                      (leaderboard.page > 3 ? leaderboard.page - 3 : 0),
                    (pageNumber) => (
                      <Button
                        fullWidth
                        disabled={pageNumber === page}
                        onClick={() => setPage(pageNumber)}
                        sx={{
                          px: 0,
                          mx: 0,
                          color: "white",
                          borderBottom: 4,
                          borderRadius: 0,
                          borderColor: "#00000000",
                          ":disabled": {
                            borderColor: "white",
                            color: "white",
                          },
                          ":hover": {
                            borderColor: "gray",
                            bgcolor: "#00000000",
                          },
                        }}
                      >
                        {pageNumber + 1}
                      </Button>
                    )
                  )
                )
              )}
            </Stack>
            {player && (
              <Button
                fullWidth
                onClick={() => gotoMe()}
                sx={{
                  color: "white",
                  borderBottom: 4,
                  borderRadius: 0,
                  borderColor: "#00000000",
                  ":disabled": {
                    borderColor: "white",
                    color: "white",
                  },
                  ":hover": {
                    borderColor: "gray",
                    bgcolor: "#00000000",
                  },
                }}
              >
                View your rank
              </Button>
            )}
          </Fragment>
        )}
      </MazePaper>
    </Box>
  );
};
