import { Path } from "./Path";
import { Player } from "./Player";
import { Round } from "./Round";
import { PlayerSolution, SlowInterval, Solution } from "./Solution";

export type PuzzleId = number;

export type PlayerResult = {
  player: Player;
  result?: number;
  slowIntervals?: SlowInterval[];
};

export type CurrentPuzzle = {
  round: Round;
  info: PuzzleInfo;
  results: PlayerResult[];
};

export function isCurrentPuzzle(value: any): value is CurrentPuzzle {
  if ((value as CurrentPuzzle).round) {
    return true;
  }
  return false;
}

export type PuzzleResult = {
  path: Path;
  results: PlayerResult[];
};

export function isPuzzleResult(value: any): value is PuzzleResult {
  if ((value as PuzzleResult).path) {
    return true;
  }
  return false;
}

export type PuzzlePlayerSolution = {
  player: Player;
  solution: Solution;
};

export type PastPuzzle = {
  round: Round;
  playerSolutions: PuzzlePlayerSolution[];
};

export function isPastPuzzle(value: any): value is PastPuzzle {
  if ((value as PastPuzzle).round) {
    return true;
  }
  return false;
}

export type RoundResults = {
  id: number;
  round: Round;
  results: PlayerResult[];
};

export type PuzzleSnapshot = {
  currentPuzzle: CurrentPuzzle;
  pastPuzzles: RoundResults[];
};

export function isPuzzleSnapshot(value: any): value is PuzzleSnapshot {
  if ((value as PuzzleSnapshot).currentPuzzle) {
    return true;
  }
  return false;
}

export type PuzzleInfo = {
  deadline: number;
  currentPlayers: number;
  solution?: PlayerSolution;
  round: Round;
};

export type PuzzleMap = {
  map: { [key in number]: PuzzleInfo };
};

export function isPuzzleMap(value: any): value is PuzzleMap {
  if ((value as PuzzleMap).map) {
    return true;
  }
  return false;
}

export type PuzzleSponsors = {
  amount: number,
  named: string[],
  anonymous: number
}