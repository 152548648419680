export type Origin = "CHALLENGE" | "ARENA";
export type Save = {
  seed: number,
  origin: Origin,
  savedAt: number,
}

export function isSave(value: any): value is Save {
  if ((value as Save).origin) {
    return true
  }
  return false
}

export function isSaves(value: any): value is Save[] {
  const potentialSaves = (value as Save[])
  if (potentialSaves.length === 0) return true;
  else if (isSave(potentialSaves[0])) return true;
  return false;
}