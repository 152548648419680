import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { getPlayerService } from "../network/PlayerService";
import { useNavigate } from "react-router";
import { MazePaper } from "./elements/MazePaper";
import { isRegistrationResponse } from "../shared/Player";

export interface RegisterProps {
  refreshPlayer: () => void;
}

const i18nAlphaNumericOrEmpty = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  let input = event.target.value;
  if (input === undefined || (input && input.length === 0) || input === "") {
    setValue("");
  } else if (input.match(/^[\p{L}\p{M}0-9]+$/gu) && input.length <= 30) {
    setValue(input);
  } else {
    setValue((value) => value);
  }
};

export const Register = (props: RegisterProps) => {
  const { refreshPlayer } = props;

  const navigate = useNavigate();

  const playerService = useRef(getPlayerService()).current;

  const [name, setName] = useState<string | undefined>();

  const [error, setError] = useState<string | undefined>();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name");

    const referrer = sessionStorage.getItem("referrer");

    if (name) {
      playerService
        .new(name.toString(), referrer)
        .then((response) => {
          if (isRegistrationResponse(response)) {
            if (referrer) {
              sessionStorage.removeItem("referrer");
            }
            if (response.reward) {
              navigate("/arena");
            } else {
              if (sessionStorage.getItem("playedTutorial")) {
                sessionStorage.removeItem("playedTutorial");
                navigate("/redirect");
              } else {
                navigate("/tutorial");
              }
            }
            refreshPlayer();
          } else {
            setError("A player with that name already exist");
          }
        })
        .catch((response) => console.log(response));
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 5 }}>
      <MazePaper title="Choose a name">
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            value={name ? name : ""}
            onChange={(event) => {
              i18nAlphaNumericOrEmpty(event, setName);
              error && setError(undefined);
            }}
            error={
              (name !== undefined && name.length === 0) || error !== undefined
            }
            helperText={error || (name === "" && "Your name cannot be empty")}
            id="name"
            label="Name"
            name="name"
            autoComplete="username"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            disabled={name === "" || name === undefined}
            variant="contained"
            sx={{ mt: 3 }}
          >
            Submit
          </Button>
        </Box>
      </MazePaper>
    </Container>
  );
};
