import { Renderer, RenderTexture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { useCallback, useEffect, useState } from "react";
import { generateHook } from "../../pages/ToggleTest";
import { Board } from "../../shared/Board";
import { Tower } from "../../shared/Tower";
import { cst } from "../../utils/constants";
import { lt } from "../../utils/lt";
import { Textures } from "../sprites/texture";
import { useTextureCleanup } from "./useTextureCleanup";
import { windowToBottoms } from "./windowToBottoms";
import { windowToTops } from "./windowToTops";

export type BlockSpriteProps = {
  identifier: string;
  x: number;
  y: number;
  clap?: boolean;
  static?: boolean;
};

export type BlockType =
  | "static"
  | "player"
  | "wall"
  | "entry"
  | "exit"
  | "waypoint"
  | undefined;

export type BlockState = BlockType[][];

export const windowRadius = 2;

export const stateWindow = (blocksState: BlocksState, x: number, y: number) => {
  let blockState = blocksState.blockState;
  let width = blockState.length;
  let height = blockState[0].length;
  let board = blocksState.board;
  let window: BlockState = [...Array(1 + windowRadius * 2)].map(() =>
    [...Array(1 + windowRadius * 2)].map(() => undefined)
  );
  for (let i = x - windowRadius; i <= x + windowRadius; i++) {
    let windowX = i - (x - windowRadius);
    for (let j = y - windowRadius; j <= y + windowRadius; j++) {
      let windowY = j - (y - windowRadius);
      if (i < 0 || i >= width || j < 0 || j >= height) {
        window[windowX][windowY] = board.startArea
          .map((c) => ({ x: c.x === -1 ? -2 : c.x, y: c.y === -1 ? -2 : c.y }))
          .some((c) => c.x === i && c.y === j)
          ? "entry"
          : board.endArea
              .map((c) => ({
                x: c.x === -1 ? -2 : c.x,
                y: c.y === -1 ? -2 : c.y,
              }))
              .some((c) => c.x === i && c.y === j)
          ? "exit"
          : (i === -1 && j > -2) || (j === -1 && i > -2)
          ? undefined
          : "wall";
      } else {
        window[windowX][windowY] = blockState[i][j];
      }
    }
  }
  return window;
};

export const hash = (string: string) => {
  var hash = 0,
    i,
    chr;
  if (string.length === 0) return "" + hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return "" + hash;
};

export const printState = (state: BlockState) => {
  for (let i = 0; i < state.length; i++) {
    let str = i > 9 ? i + ": " : i + ":  ";
    for (let j = 0; j < state[0].length; j++) {
      str +=
        state[i][j] === "static"
          ? "  s"
          : state[i][j] === "player"
          ? "  p"
          : state[i][j] === "wall"
          ? "  w"
          : state[i][j] === "entry"
          ? "  e"
          : state[i][j] === "exit"
          ? "  x"
          : "  .";
    }
    console.log(str);
  }
};

type BlocksState = {
  towers: Tower[];
  board: Board;
  blockState: BlockState;
};

const blocksState = (board: Board, playerTowers: Tower[]) => {
  const state: BlockState = [...Array(board.width)].map(() =>
    [...Array(board.height)].map(() => undefined)
  );

  board.staticTowers.forEach((tower) => {
    state[tower.coord.x][tower.coord.y] = "static";
  });

  playerTowers.forEach((tower) => {
    state[tower.coord.x][tower.coord.y] = tower.static ? "static" : "player";
  });
  return {
    towers: [...board.staticTowers, ...playerTowers],
    blockState: state,
    board,
  };
};

export const blockStateWindow = (
  board: Board,
  playerTowers: Tower[],
  x: number,
  y: number
) => stateWindow(blocksState(board, playerTowers), x, y);

const generateInitialBlockTextures = (
  board: Board,
  blocksState: BlocksState,
  textures: Textures,
  renderer: Renderer,
  playerBlockTopTexture?: RenderTexture,
  staticBlockTopTexture?: RenderTexture,
  blockBottomTexture?: RenderTexture
) => {
  const staticTopContainer = new Container();

  const playerTopContainer = new Container();

  const bottomContainer = new Container();

  const width = board.width * cst.tileSize;
  const height = board.height * cst.tileSize;
  blocksState.towers.forEach((t) => {
    const window = stateWindow(blocksState, t.coord.x, t.coord.y);
    windowToTops(window, t.coord.x, t.coord.y).forEach((it) => {
      if (it) {
        let sprite = new Sprite();
        sprite.texture = textures[it.texture]!;
        sprite.x = (it.x - 1 / 2) * cst.tileSize;
        sprite.y = (it.y - 1) * cst.tileSize;
        sprite.width = it.width * cst.tileSize;
        sprite.height = it.height * cst.tileSize;
        sprite.tint = it.tint ? it.tint : 0xffffff;
        if (t.static) {
          staticTopContainer.addChild(sprite);
        } else {
          playerTopContainer.addChild(sprite);
        }
      }
    });
    windowToBottoms(window, t.coord.x, t.coord.y).forEach((it) => {
      if (it) {
        let sprite = new Sprite();
        sprite.texture = textures[it.texture]!;
        sprite.x = (it.x - 1 / 4) * cst.tileSize;
        sprite.y = (it.y - 3 / 4) * cst.tileSize;
        sprite.width = it.width * cst.tileSize;
        sprite.height = it.height * cst.tileSize;
        sprite.tint = it.tint ? it.tint : 0xffffff;
        bottomContainer.addChild(sprite);
      }
    });
  });
  const region = new Rectangle(0, 0, width, height);
  playerBlockTopTexture = generateHook(
    renderer,
    playerTopContainer,
    {
      region,
    },
    undefined,
    undefined,
    playerBlockTopTexture
  );
  playerTopContainer.destroy({ children: true });
  staticBlockTopTexture = generateHook(
    renderer,
    staticTopContainer,
    {
      region,
    },
    undefined,
    undefined,
    staticBlockTopTexture
  );
  staticTopContainer.destroy({ children: true });
  const bottomRegion = new Rectangle(
    0,
    0,
    width + cst.tileSize,
    height + cst.tileSize
  );
  blockBottomTexture = generateHook(
    renderer,
    bottomContainer,
    {
      region: bottomRegion,
    },
    undefined,
    undefined,
    blockBottomTexture
  );
  bottomContainer.destroy({ children: true });
  return {
    playerBlockTopTexture,
    staticBlockTopTexture,
    blockBottomTexture,
  };
};
export const generateBlockTextures = (
  board: Board,
  playerTowers: Tower[],
  textures: Textures,
  renderer: Renderer
) =>
  generateInitialBlockTextures(
    board,
    blocksState(board, playerTowers),
    textures,
    renderer
  );

export const useBlocks = (
  board: Board,
  playerTowers: Tower[],
  renderer: Renderer,
  textures: Textures,
  enabled: boolean
) => {
  const [staticBlockTopTexture, setStaticBlockTopTexture] = useState<
    RenderTexture | undefined
  >();
  const [playerBlockTopTexture, setPlayerBlockTopTexture] = useState<
    RenderTexture | undefined
  >();
  const [blockBottomTexture, setBlockBottomTexture] = useState<
    RenderTexture | undefined
  >();

  const updateTextures = useCallback(
    (board: Board, playerTowers: Tower[]) => {
      enabled &&
        lt(
          generateInitialBlockTextures(
            board,
            blocksState(board, playerTowers),
            textures,
            renderer,
            playerBlockTopTexture,
            staticBlockTopTexture,
            blockBottomTexture
          ),
          (blockTextures) => {
            setPlayerBlockTopTexture(blockTextures.playerBlockTopTexture);
            setStaticBlockTopTexture(blockTextures.staticBlockTopTexture);
            setBlockBottomTexture(blockTextures.blockBottomTexture);
          }
        );
    },
    [
      renderer,
      textures,
      enabled,
      playerBlockTopTexture,
      staticBlockTopTexture,
      blockBottomTexture,
    ]
  );

  useEffect(() => {
    updateTextures(board, playerTowers);
  }, [updateTextures, board, playerTowers]);

  useTextureCleanup(playerBlockTopTexture);
  useTextureCleanup(staticBlockTopTexture);
  useTextureCleanup(blockBottomTexture);

  return {
    staticBlockTopTexture,
    playerBlockTopTexture,
    blockBottomTexture,
  };
};
