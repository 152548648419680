import { BallColor, BlockPattern } from "../shared/Cosmetics";
import { Textures } from "../game/sprites/texture";
import { windowToTops } from "../game/utils/windowToTops";
import { Application, Container, Renderer, Sprite } from "pixi.js";
import { BlockState } from "../game/utils/useBlocks";
import { cst } from "./constants";
import { ballFrames } from "../game/sprites/animations";
import { singleBlockPatternImages } from "../game/utils/usePattern";

export const soloWindow: BlockState = [
  [undefined, undefined, undefined, undefined, undefined],
  [undefined, undefined, undefined, undefined, undefined],
  [undefined, undefined, "player", undefined, undefined],
  [undefined, undefined, undefined, undefined, undefined],
  [undefined, undefined, undefined, undefined, undefined],
];
export function downloadBase64File(
  base64: string,
  fileName: string = "img.png"
) {
  const linkSource = base64;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const imageCache = new Map<string, string>();

const cacheName = (blockPattern: BlockPattern) =>
  blockPattern.pattern +
  "," +
  blockPattern.firstColor.color.name +
  "," +
  blockPattern.secondColor?.color.name;

export const blockCosmeticImage = (
  blockPatterns: BlockPattern[],
  textures: Textures
) => {
  const cached = blockPatterns.map((it) => imageCache.get(cacheName(it)));

  if (cached.every((it) => it !== undefined)) return cached as string[];

  let props = windowToTops(soloWindow, 0, 0);
  let app = new Application({ backgroundAlpha: 0 });
  let container = new Container();
  props.forEach((spriteProps) => {
    if (spriteProps) {
      let sprite = new Sprite();
      sprite.x = spriteProps.x * cst.tileSize;
      sprite.y = spriteProps.y * cst.tileSize;
      sprite.tint = spriteProps.tint || 0xffffff;
      sprite.width = spriteProps.width * cst.tileSize;
      sprite.height = spriteProps.height * cst.tileSize;
      sprite.texture = textures[spriteProps.texture]!;
      container.addChild(sprite);
    }
  });

  let renderTexture = (app.renderer as Renderer).generateTexture(container);

  let first = new Sprite(renderTexture);
  first.width = cst.tileSize * 2;
  first.height = cst.tileSize * 2;

  let second = new Sprite(renderTexture);
  second.width = cst.tileSize * 2;
  second.height = cst.tileSize * 2;

  app.stage.addChild(first);
  app.stage.addChild(second);

  blockPatterns.forEach((blockPattern, index) => {
    if (cached[index] === undefined) {
      first.tint = blockPattern.firstColor.color.tint;
      second.visible = false;
      if (blockPattern.secondColor) {
        second.tint = blockPattern.secondColor.color.tint;
        second.visible = true;
        second.mask = singleBlockPatternImages[blockPattern.pattern];
      }
      cached[index] = app.renderer.plugins.extract.base64(app.stage) as string;
      imageCache.set(cacheName(blockPattern), cached[index]!!);
    }
  });
  app.destroy();
  return cached as string[];
};

export const ballCosmeticImage = (
  ballColors: BallColor[],
  textures: Textures
) => {
  let app = new Application();
  let ball = new Sprite();
  ball.tint = 0xffffff;
  ball.width = cst.tileSize;
  ball.height = cst.tileSize;
  ball.texture = textures[ballFrames[37]]!!;
  ball.alpha = 1;
  app.stage.addChild(ball);
  let shade = new Sprite();
  shade.tint = 0xffffff;
  shade.width = cst.tileSize;
  shade.height = cst.tileSize;
  shade.alpha = 0.5;
  shade.texture = textures["ballShade.png"]!!;
  let images = ballColors.map((ball) => {
    app.stage.children.forEach((child) => {
      if (child.isSprite && child.alpha === 1) {
        let sprite: Sprite = child as Sprite;
        sprite.tint = ball.color.tint;
      }
    });
    return app.renderer.plugins.extract.base64(app.stage) as string;
  });
  app.destroy();
  return images;
};
