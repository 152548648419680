import { Box, Button, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router";
import { ArenaResult, ArenaStatus } from "../../shared/Arena";
import { Player } from "../../shared/Player";
import { Points } from "./Emoji";
import { MazePaper } from "./MazePaper";
import { TipElement } from "./TipElement";

export interface ArenaRewardElementProps {
  arenaResult: ArenaResult;
  arenaStatus: ArenaStatus;
  close: () => void;
  player: Player;
  registrationReward?: boolean;
}

export const ArenaRewardElement = (props: ArenaRewardElementProps) => {
  const {
    arenaStatus,
    player,
    arenaResult,
    close,
    registrationReward = false,
  } = props;
  const navigate = useNavigate();

  const arenaReward = arenaResult.reward;

  return (
    <Box
      sx={{
        p: 1,
        pt: 2,
      }}
      mx="auto"
      maxWidth="sm"
      height="90vh"
    >
      <MazePaper title="Arena Reward">
        <Fragment>
          {arenaStatus.wins === 0 && (
            <Typography sx={{ pb: 2 }}>
              You did not get any wins this time around! Better luck next time!
            </Typography>
          )}
          {arenaStatus.wins > 0 && (
            <Typography sx={{ pb: 2 }}>
              Great job! <br />
              You got{" "}
              <b>
                {arenaStatus.wins} win{arenaStatus.wins > 1 ? "s" : ""}
              </b>
              ! <br />
              You have been awarded{" "}
              <b>
                {arenaReward.points} <Points />
              </b>
              {arenaReward.serves > 0 && (
                <Typography display="inline">
                  {" "}
                  and{" "}
                  <b>
                    {arenaReward.serves} <b>Serve</b>
                    {arenaReward.serves > 1 ? "s" : ""}
                  </b>
                  !
                  <br />
                  <br />
                  Play your <b>Serve{arenaReward.serves > 1 ? "s" : ""}</b> and
                  earn <b>even more</b> <Points />
                </Typography>
              )}
              !<br />
            </Typography>
          )}
          {arenaResult.oldRating === -1 && arenaResult.newRating > 0 && (
            <Typography sx={{ pb: 2 }}>
              You've been given an <b>Arena</b> rating of
              <Typography variant="h5" sx={{ py: 2 }}>
                <b>{arenaResult.newRating}</b>
              </Typography>
              When you play in the <b>Arena</b> your rating will be adjusted,{" "}
              <br />
              the <b>Arena</b> will challenge you according to your rating.
            </Typography>
          )}
          {arenaResult.oldRating > 0 && arenaResult.newRating > 0 && (
            <Typography>
              Your new <b>Arena</b> rating is
              <Typography variant="h5" sx={{ py: 2 }}>
                {" "}
                {arenaResult.newRating}{" "}
                <Typography
                  display="inline"
                  variant="h5"
                  color={
                    arenaResult.newRating > arenaResult.oldRating
                      ? "#66dd6a"
                      : arenaResult.newRating < arenaResult.oldRating ? "error" : "darkgrey"
                  }
                >
                  {arenaResult.newRating > arenaResult.oldRating
                    ? "▲" + (arenaResult.newRating - arenaResult.oldRating)
                    : arenaResult.newRating < arenaResult.oldRating
                    ? "▼" + (arenaResult.oldRating - arenaResult.newRating)
                    : "━ " + 0}
                </Typography>
              </Typography>
              <TipElement localStorageKey="profileTip">
                <Typography>
                  You can see your rating, achievements and other stats on your
                  profile by clicking on your Block next to your <Points /> and
                  tapping <b>Profile</b>
                </Typography>
              </TipElement>
            </Typography>
          )}
          {player.guest && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={
                arenaReward.serves === 0 || player.guest ? "success" : "primary"
              }
              onClick={() => navigate("/register")}
            >
              Sign up to claim your {arenaResult.newRating > 0 && "rating and "}
              reward!
            </Button>
          )}
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color={
              arenaReward.serves === 0 && !player.guest ? "success" : "primary"
            }
            onClick={close}
          >
            Play {registrationReward ? "Arena " : ""}Again
          </Button>
          {!player.guest && arenaReward.serves > 0 && (
            <Button
              variant="contained"
              color="success"
              onClick={() => navigate("/arena/serve")}
              sx={{ m: 1 }}
            >
              Play Serve{arenaReward.serves > 1 ? "s" : ""}
            </Button>
          )}
          {registrationReward && (
            <Button
              variant="contained"
              onClick={() => navigate("/")}
              sx={{ m: 1 }}
            >
              Go to Main Menu
            </Button>
          )}
        </Fragment>
      </MazePaper>
    </Box>
  );
};
