import { Link } from "@mui/material";
import React from "react";

interface PlayerProfileLinkProps {
  playerName: string;
  newTab?: boolean;
}

export const PlayerProfileLink = (props: PlayerProfileLinkProps) => {
  const { playerName, newTab = false} = props;

  return (
    <Link
      underline="none"
      href={"/profile/" + playerName}
      target={newTab ? "_blank" : undefined}
      color="inherit"
    >
      {playerName}
    </Link>
  );
};
