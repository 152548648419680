import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-aspect-ratio/aspect-ratio.css";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, Paper, ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";
import { SnackbarProvider } from "notistack";
import {
  notificationsWebSocketUrl,
  NotificationWebSocketContext,
} from "./contexts/NotificationContext";
import { ExponentialBackoff, WebsocketBuilder } from "websocket-ts/lib";
import { LoggableError } from "./shared/Error";
import { cst } from "./utils/constants";
import UAParser from "ua-parser-js";
import { lt } from "./utils/lt";
import { getMetricsService } from "./network/MetricsService";
import emptyRoundImage from "./resources/emptyRound.png";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <NotificationWebSocketContext.Provider
      value={new WebsocketBuilder(notificationsWebSocketUrl)
        .withBackoff(new ExponentialBackoff(500, 5))
        .build()}
    >
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoHideDuration={15000}
        content={(key, message) => (
          <Paper key={key} sx={{ p: 2 }}>
            {message}
          </Paper>
        )}
      >
        <CssBaseline />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <img src={emptyRoundImage} alt={""} style={{ display: "none" }} />
      </SnackbarProvider>
    </NotificationWebSocketContext.Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
window.onerror = function (msg, source, lineNo, columnNo, error) {
  if (cst.logErrors) {
    let userAgent = new UAParser(window.navigator.userAgent);
    let loggableError: LoggableError = {
      lineNumber: lineNo || -1,
      columnNumber: columnNo || -1,
      message: msg.toString(),
      frontendVersion: cst.version,
      script: source || "",
      stackTrace: (error && error.stack) || "",
      browserName: userAgent.getBrowser().name || "",
      browserVersion: userAgent.getBrowser().version || "",
      operatingSystem: lt(
        userAgent.getOS(),
        ({ name, version }) =>
          (name ? name : "") + (version ? " " + version : "")
      ),
      platform: lt(
        userAgent.getDevice(),
        ({ model, type, vendor }) =>
          (vendor ? vendor : "") +
          (model ? " " + model : "") +
          (type ? " " + type : "")
      ),
    };
    getMetricsService().logError(loggableError);
  }
  return false;
};

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
    navigator.serviceWorker.register("service-worker.js");
  }
});
