import { AchievementStatus } from "./Achievement";
import { Cosmetics } from "./Cosmetics";
import { Round } from "./Round";
import { Solution } from "./Solution";

export type Player = {
  name: string;
  id: number;
  guest: boolean;
};

export function isPlayer(value: any): value is Player {
  if ((value as Player).name) {
    return true;
  }
  return false;
}

export const replenishUrl = (player: Player) => {
  if (player.guest) {
    return "/r/g" + player.id.toString(36);
  } else {
    return "/r/" + player.name;
  }
};

export type PlayerAccount = {
  points: number;
  serves: number;
  unclaimedServePoints: number;
  arenaTokenTimeLeft: number;
  arenaTokens: number;
};

export function isPlayerAccount(value: any): value is PlayerAccount {
  if (!isNaN((value as PlayerAccount).serves)) {
    return true;
  }
  return false;
}

export type PuzzleResult = {
  total: number;
  gold: number;
  silver: number;
  bronze: number;
};

export type RoundSolution = {
  round: Round;
  solution: Solution;
};

export type PlayerProfile = {
  name: string;
  createdOn: number;
  arenaRounds: number;
  createdChallenges: number;
  challengeRounds: number;
  rating: number;
  puzzleRating: number;
  bestArenaResult: number;
  bestCompetitiveResult: number;
  bestPrestigeResult: number;
  puzzleResults: { [key in number]: PuzzleResult };
  currentPuzzles: number[];
  achievements: AchievementStatus[];
  cosmetics: Cosmetics;
  bestRound?: RoundSolution;
};

export function isPlayerProfile(value: any): value is PlayerProfile {
  if ((value as PlayerProfile).name) {
    return true;
  }
  return false;
}

export type PlayerCounts = {
  arena: number;
  challenge: number;
  puzzle: number;
  academy: number;
};

export function isPlayerCounts(value: any): value is PlayerCounts {
  if (!isNaN((value as PlayerCounts).arena)) {
    return true;
  }
  return false;
}

export type RegistrationResponse = {
  player: Player;
  reward: Boolean;
};

export function isRegistrationResponse(
  value: any
): value is RegistrationResponse {
  if ((value as RegistrationResponse).player) {
    return true;
  }
  return false;
}
