import { Coord } from "./Coord";

export type PathSection = {
  destination: Coord;
  clapCoord?: Coord;
}

export type Path = {
  sections: PathSection[];
  result: number;
  integerResult: number;
}

export function isPath(value: any): value is Path {
  if ((value as Path).result) {
    return true
  }
  return false
}