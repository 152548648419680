import { Alert, Box, Button, Link, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  isPlayer,
  isPlayerCounts,
  Player,
  PlayerCounts,
} from "../shared/Player";
import StadiumIcon from "@mui/icons-material/Stadium";
import ExtensionIcon from "@mui/icons-material/Extension";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SportsIcon from "@mui/icons-material/Sports";
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from "@mui/icons-material/Store";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { LandingButton } from "./elements/LandingButton";
import Background1 from "../resources/background1.png";
import Background2 from "../resources/background2.png";
import Background3 from "../resources/background3.png";
import Background4 from "../resources/background4.png";
import StoreBackground from "../resources/storeBackground.png";
import titleImage from "../resources/titleImage.png";
import { getPlayerService } from "../network/PlayerService";
import { theme } from "../utils/theme";
import { getMetricsService } from "../network/MetricsService";
import { Points } from "./elements/Emoji";
import { TipElement } from "./elements/TipElement";
import {
  isPeriodicalChallenge,
  PeriodicalChallenge,
} from "../shared/Challenge";
import { getChallengeService } from "../network/ChallengeService";
import { BountyState, isBountyState } from "../shared/Bounty";
import { getBountyService } from "../network/BountyService";

export interface LandingProps {
  player: Player | undefined | null;
}

export const Landing = (props: LandingProps) => {
  const { player } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("unique") == null) {
      getMetricsService().logUnique();
      localStorage.setItem("unique", "1");
    }
  });

  const [counts, setCounts] = useState<undefined | PlayerCounts>();

  const [periodical, setPeriodical] = useState<PeriodicalChallenge>();

  useEffect(() => {
    if (periodical === undefined && player !== undefined) {
      getChallengeService()
        .currentPeriodical()
        .then((response) => {
          if (isPeriodicalChallenge(response)) {
            setPeriodical(response);
          }
        });
    }
  });

  const [bountyState, setBountyState] = useState<BountyState>();

  useEffect(() => {
    if (player && isPlayer(player) && !player.guest) {
      getBountyService()
        .state()
        .then((response) => {
          if (isBountyState(response)) {
            setBountyState(response);
          }
        });
    }
  }, [setBountyState, player]);

  useEffect(() => {
    if (counts === undefined) {
      getPlayerService()
        .counts()
        .then((response) => isPlayerCounts(response) && setCounts(response));
    }
  }, [counts, setCounts]);

  return (
    <Box
      sx={{
        p: 1,
      }}
      mx="auto"
      maxWidth="sm"
      height="90vh"
    >
      <Box sx={{ mt: { xs: 2, sm: 2, md: 3 }, mb: { xs: 1, sm: 1, md: 1 } }}>
        <img
          alt={"maze.game"}
          src={titleImage}
          width={
            Math.min(theme.breakpoints.values.sm, window.innerWidth) * 0.85
          }
        />
      </Box>
      <TipElement localStorageKey={"hideMainMessage"}>
        <Typography sx={{ m: 3, mt: 2 }}>
          Welcome to <b>maze.game</b>, a game about building the best maze you
          can with the resources you've got! <br />
          <br />
          First time playing? check out the tutorial!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.removeItem("returnRoute");
            navigate("/tutorial");
          }}
        >
          Tutorial
        </Button>
      </TipElement>
      {bountyState && bountyState !== "WAITING" && bountyState !== "ROLLING" && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{ "& .MuiAlert-message": { width: "100%" } }}
        >
          <Typography width={1}>
            {bountyState === "CLAIMABLE"
              ? "You have an unclaimed"
              : bountyState === "PLAYING"
              ? "You are in the middle of a"
              : "You have an unplayed"}{" "}
            <b>Bounty</b>!
            {bountyState === "PLAYING" ? (
              <Fragment>
                <br />
                <br />
                Play it before the time runs out!
              </Fragment>
            ) : (
              ""
            )}
          </Typography>
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() =>
              navigate(
                "/bounty" + (bountyState === "CLAIMABLE" ? "/results" : "")
              )
            }
          >
            {bountyState === "PLAYING" ? "Play bounty!" : "go"}
          </Button>
        </Alert>
      )}
      <LandingButton
        background={Background4}
        icon={<StadiumIcon sx={{ fontSize: 70 }} />}
        title="Arena"
        players={counts?.arena || 0}
        mode="versus"
        duration={<Typography align="left">90s</Typography>}
        path="/arena"
      />
      <LandingButton
        background={Background3}
        icon={<AccountBalanceIcon sx={{ fontSize: 70 }} />}
        disabled={player === null || player === undefined || player.guest}
        title="Academy"
        players={counts?.academy || 0}
        midText="Campaign"
        midIcon={<PersonIcon />}
        mode="multiplayer"
        path="/academy"
      />
      <LandingButton
        completed={
          periodical
            ? periodical.progress >= periodical.parameters.settings.totalRounds
            : false
        }
        disabled={player === null || player === undefined || player.guest}
        background={Background1}
        icon={<EventRepeatIcon sx={{ fontSize: 70, py: 1 }} />}
        title="Daily"
        players={periodical ? periodical.playerCount : 0}
        duration={
          <Typography align="left">
            {periodical
              ? periodical.parameters.settings.roundDuration + "s"
              : ""}
          </Typography>
        }
        mode="multiplayer"
        path={"/challenge/daily"}
      />
      <LandingButton
        background={Background2}
        icon={<SportsIcon sx={{ fontSize: 70 }} />}
        disabled={player === null || player === undefined || player.guest}
        title="Challenges"
        players={counts?.challenge || 0}
        duration={<Typography align="left">Varies</Typography>}
        mode="multiplayer"
        path="/challenges"
      />
      <LandingButton
        background={Background4}
        icon={<ExtensionIcon sx={{ fontSize: 70 }} />}
        disabled={player === null || player === undefined || player.guest}
        title="Puzzle"
        players={counts?.puzzle || 0}
        mode="multiplayer"
        path="/puzzle"
      />
      <LandingButton
        background={StoreBackground}
        icon={<StoreIcon sx={{ fontSize: 70 }} />}
        disabled={player === null || player === undefined || player.guest}
        title="Store"
        players={0}
        mode="store"
        path="/store"
      />

      <Typography sx={{ m: 3 }}>
        Join the community on{" "}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://discord.gg/TsQzYuq35J"
        >
          Discord
        </Link>{" "}
        and at{" "}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://www.reddit.com/r/mazedotgame"
        >
          /r/mazedotgame
        </Link>
        . You can support maze.game on{" "}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://patreon.com/mazegame"
        >
          Patreon
        </Link>{" "}
        or by buying <Points /> in the <b>Store</b>. <br /> Have fun and keep
        playing.
      </Typography>
      <Typography variant="caption" sx={{ m: 3 }}>
        <Link
          underline="none"
          href="#"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </Link>{" "}
        —{" "}
        <Link
          underline="none"
          href="#"
          onClick={() => navigate("/terms-of-service")}
        >
          Terms of Service
        </Link>
      </Typography>
    </Box>
  );
};
