import axios from "axios";
import { ArenaMode, ArenaReward, TokenTime } from "../shared/Arena";
import {
  CosmeticRoundResult,
  CosmeticArenaSnapshot,
} from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { Round } from "../shared/Round";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface ArenaService {
  snapshot: () => Promise<CosmeticArenaSnapshot | ErrorMessage>;
  submit: (layout: Layout) => Promise<CosmeticRoundResult | ErrorMessage>;
  modes: () => Promise<ArenaMode[] | ErrorMessage>;
  tentative: (layout: Layout) => Promise<void>;
  startByToken: (
    mode: ArenaMode
  ) => Promise<CosmeticArenaSnapshot | ErrorMessage>;
  startByPoints: (
    mode: ArenaMode
  ) => Promise<CosmeticArenaSnapshot | ErrorMessage>;
  token: () => Promise<TokenTime | ErrorMessage>;
  claimReward: () => Promise<ArenaReward | ErrorMessage>;
  next: () => Promise<Round | ErrorMessage>;
  dismissReward: () => Promise<void>;
  acknowledgeTokens: () => Promise<void>;
}

export const getArenaService = (): ArenaService => ({
  modes: () =>
    axios
      .get<ArenaMode[]>(cst.backendUrl + "/arena/modes", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  snapshot: () =>
    axios
      .get<CosmeticArenaSnapshot>(cst.backendUrl + "/arena", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  submit: (layout: Layout) =>
    axios
      .post<CosmeticRoundResult>(cst.backendUrl + "/arena", layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  tentative: (layout: Layout) =>
    axios
      .post(cst.backendUrl + "/arena/tentative", layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  startByPoints: (mode: ArenaMode) =>
    axios
      .get<CosmeticArenaSnapshot>(
        cst.backendUrl + "/arena/start/points?mode=" + mode,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  startByToken: (mode: ArenaMode) =>
    axios
      .get<CosmeticArenaSnapshot>(
        cst.backendUrl + "/arena/start/token?mode=" + mode,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  token: () =>
    axios
      .get<TokenTime>(cst.backendUrl + "/arena/token", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  claimReward: () =>
    axios
      .get<ArenaReward>(cst.backendUrl + "/arena/reward", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  next: () =>
    axios
      .get<Round>(cst.backendUrl + "/arena/next", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  acknowledgeTokens: () =>
    axios
      .post(cst.backendUrl + "/arena/token/acknowledge", undefined, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  dismissReward: () =>
    axios
      .post(cst.backendUrl + "/arena/dismiss")
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
