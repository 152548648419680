import {
  Alert,
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { getArenaService } from "../network/ArenaService";
import {
  ArenaMode,
  arenaModeDescription,
  arenaModeLabel,
  arenaModeUnlock,
  ArenaResult,
  getRoundOutcome,
  isArenaMode,
  isArenaModes,
  isArenaResult,
  isTokenTime,
  TokenTime,
} from "../shared/Arena";
import { Layout } from "../shared/Layout";
import { isPlayer, Player, PlayerAccount } from "../shared/Player";
import { isRound, Round } from "../shared/Round";
import { cst } from "../utils/constants";
import { secondsToHHMMSS } from "../utils/secToHHMMSS";
import { useCheckAchievements } from "../utils/useCheckAchievements";
import { useSecondsPassed } from "../utils/useSecondsPassed";
import HelpIcon from "@mui/icons-material/Help";
import { ArenaRewardElement } from "./elements/ArenaRewardElement";
import { RoundResultElement } from "./elements/RoundResultElement";
import { ArenaSnapshotElement } from "./elements/ArenaSnapshotElement";
import { BodyContent } from "./elements/BodyContent";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { MazePaper } from "./elements/MazePaper";
import { InteractiveGame } from "./elements/InteractiveGame";
import { usePlayerOrGuest } from "../utils/usePlayerOrGuest";
import { CostButton } from "./CostButton";
import LockIcon from "@mui/icons-material/Lock";
import {
  CosmeticRoundResult,
  CosmeticArenaSnapshot,
  Cosmetics,
  isCosmeticRoundResult,
  isCosmeticArenaSnapshot,
} from "../shared/Cosmetics";
import { CopyInput } from "./elements/CopyInput";
import { Points } from "./elements/Emoji";
import { lt } from "../utils/lt";
import { SponsorText } from "./elements/SponsorText";
import { useTentativeLayout } from "../utils/useTentativeLayout";
import { unselectable } from "../utils/unselectable";
import { theme } from "../utils/theme";

interface ArenaProps {
  player: Player | undefined | null;
  account: PlayerAccount | undefined;
  cosmetics: Cosmetics | undefined;
  refreshAccount: () => void;
}

const isDisabled = (t: TokenTime | undefined, secondsPassed: number) =>
  t === undefined ||
  (t.availableTokens === 0 && t.timeLeft / 1000 - secondsPassed > 0);

export const Arena = (props: ArenaProps) => {
  const { player, account, cosmetics, refreshAccount } = props;
  const [snapshot, setSnapshot] = useState<
    CosmeticArenaSnapshot | undefined | null
  >();

  const playerOrGuest = usePlayerOrGuest(player);

  const arenaService = useRef(getArenaService());

  const { secondsPassed, resetSecondsPassed, pause, unPause } =
    useSecondsPassed();

  const [roundResult, setRoundResult] = useState<
    CosmeticRoundResult | undefined
  >();

  const [round, setRound] = useState<Round | undefined>();

  const checkAchievements = useCheckAchievements();
  const [availableModes, setAvailableModes] = useState<ArenaMode[]>([]);

  useEffect(() => {
    arenaService.current.snapshot().then((response) => {
      if (isCosmeticArenaSnapshot(response)) {
        setSnapshot(response);
        resetSecondsPassed();
      } else {
        setSnapshot(null);
      }
    });
  }, [resetSecondsPassed]);

  const [mode, setMode] = useState<ArenaMode>(
    lt(localStorage.getItem("lastMode"), (lastMode) =>
      isArenaMode(lastMode) ? lastMode : ArenaMode.CLASSIC
    )
  );

  useEffect(() => {
    if (snapshot === null && availableModes.length === 0) {
      arenaService.current.modes().then((response) => {
        if (isArenaModes(response)) {
          setAvailableModes(response);
          if (response.every((it) => it !== mode)) {
            setMode(ArenaMode.CLASSIC);
            localStorage.setItem("lastMode", ArenaMode.CLASSIC);
          }
        }
      });
    }
  }, [availableModes, snapshot, mode]);

  const [tokenTime, setTokenTime] = useState<TokenTime | undefined>();

  const checkTokenTime = useCallback(() => {
    getArenaService()
      .token()
      .then((response) => {
        if (isTokenTime(response)) {
          setTokenTime(response);
        }
      });
  }, [setTokenTime]);

  const [disableStartButtons, setDisableStartButtons] = useState(false);

  useEffect(() => {
    if (snapshot === null) {
      unPause();
      checkTokenTime();
    } else {
      setDisableStartButtons((s) => (s ? false : s));
      pause();
    }
  }, [checkTokenTime, setDisableStartButtons, snapshot, pause, unPause]);

  const submitLayout = useCallback(
    (layout: Layout) =>
      arenaService.current.submit(layout).then((response) => {
        if (isCosmeticRoundResult(response)) {
          setRoundResult(response);
          const outcome = getRoundOutcome(
            response.playerSolution,
            response.opponentSolution
          );
          setSnapshot(
            (s) =>
              s &&
              playerOrGuest && {
                status:
                  outcome === "WIN"
                    ? { ...s.status, wins: s.status.wins + 1 }
                    : outcome === "LOSS"
                    ? { ...s.status, losses: s.status.losses + 1 }
                    : { ...s.status, ties: s.status.ties + 1 },
                results: [...s.results, response],
                mode: s.mode,
              }
          );
          resetSecondsPassed();
          checkAchievements();
        } else {
          console.error(response);
        }
      }),
    [
      setSnapshot,
      setRoundResult,
      checkAchievements,
      resetSecondsPassed,
      playerOrGuest,
    ]
  );
  const [duration, setDuration] = useState(cst.arena.duration);

  const [nextDisabled, setNextDisabled] = useState(false);

  const [deadline, setDeadline] = useState<Date | undefined>();

  const { onLayoutChange, onBeforeUnload, onPageVisibitilyChange } =
    useTentativeLayout(
      arenaService.current.tentative,
      submitLayout,
      setDuration,
      deadline
    );

  const nextRound = useCallback(() => {
    setNextDisabled(true);
    arenaService.current.next().then((response) => {
      let currentDate = new Date();
      if (isRound(response)) {
        if (
          snapshot &&
          snapshot.timeLeft &&
          secondsPassed < snapshot.timeLeft
        ) {
          setDeadline(
            new Date(
              currentDate.getTime() + (snapshot.timeLeft - secondsPassed) * 1000
            )
          );
          setDuration(snapshot.timeLeft - secondsPassed);
        } else {
          setDeadline(
            new Date(currentDate.getTime() + cst.arena.duration * 1000)
          );
        }
        setRound(response);
      } else {
        console.error(response);
      }
    });
  }, [setRound, secondsPassed, snapshot]);

  const startArenaByPoints = useCallback(() => {
    setDisableStartButtons(true);
    arenaService.current.startByPoints(mode).then((response) => {
      if (isCosmeticArenaSnapshot(response)) {
        localStorage.setItem("lastMode", mode);
        setSnapshot(response);
        refreshAccount();
      } else {
        console.error(response);
      }
    });
  }, [setSnapshot, refreshAccount, setDisableStartButtons, mode]);

  const startArenaByToken = useCallback(() => {
    setDisableStartButtons(true);
    arenaService.current.startByToken(mode).then((response) => {
      if (isCosmeticArenaSnapshot(response)) {
        localStorage.setItem("lastMode", mode);
        setSnapshot(response);
        if (player && !player.guest) {
          refreshAccount();
        }
      } else {
        console.error(response);
      }
    });
  }, [player, setSnapshot, refreshAccount, mode, setDisableStartButtons]);

  const [result, setResult] = useState<ArenaResult | undefined>();

  const claimReward = useCallback(
    () =>
      arenaService.current.claimReward().then((response) => {
        if (isArenaResult(response)) {
          setResult(response);
          refreshAccount();
          checkAchievements();
        } else {
          console.error(response);
        }
      }),
    [setResult, refreshAccount, checkAchievements]
  );

  const navigate = useNavigate();

  const [openHelp, setOpenHelp] = useState(false);

  const competitiveDisabled = useMemo(
    () => availableModes.every((it) => it !== ArenaMode.COMPETITIVE),
    [availableModes]
  );
  const prestigeDisabled = useMemo(
    () => availableModes.every((it) => it !== ArenaMode.PRESTIGE),
    [availableModes]
  );

  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Fragment>
      {playerOrGuest && (
        <React.Fragment>
          {snapshot === null && (
            <Box maxWidth="sm" mx="auto" sx={{ pt: 2 }}>
              <MazePaper
                title={
                  <Fragment>
                    Arena{" "}
                    <Tooltip
                      enterTouchDelay={0}
                      disableTouchListener
                      disableFocusListener
                      disableHoverListener
                      open={openHelp}
                      title={
                        <Typography>
                          In the <b>Arena</b> you will face opponents matched to
                          your skill level.
                          <br />
                          <br />
                          In a game of <b>Arena</b> you have <b>3</b> lives, and
                          can win up to <b>12</b> rounds.
                          <br />
                          <br />
                          As you win rounds in the <b>Arena</b> you will be
                          matched up against players with higher skill levels.
                          As the difficulty increases so does the rewards!
                          <br />
                          <br />
                          Every round has a time limit of {
                            cst.arena.duration
                          }{" "}
                          seconds, so you will have to be quick to make your
                          maze!
                        </Typography>
                      }
                    >
                      <IconButton
                        sx={{ transform: "translate(0%, -5%)" }}
                        onClick={() => setOpenHelp((s) => !s)}
                      >
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                }
                goBack={() => navigate(-1)}
              >
                <Typography>
                  You do not have an active <b>Arena</b>
                  <br />
                  <br /> Click below to start a new one
                </Typography>
                <Grid container justifyContent={"center"} sx={{ pt: 2 }}>
                  <RadioGroup row={isSm}>
                    <Tooltip
                      title={
                        <Typography align="center">
                          {arenaModeDescription[ArenaMode.CLASSIC]}
                        </Typography>
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={() => setMode(ArenaMode.CLASSIC)}
                            checked={mode === ArenaMode.CLASSIC}
                          />
                        }
                        sx={unselectable}
                        label={arenaModeLabel[ArenaMode.CLASSIC]}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <Typography align="center">
                          {arenaModeDescription[ArenaMode.COMPETITIVE]}
                          {competitiveDisabled ? (
                            <Fragment>
                              <br />
                              <br />
                              <b>Unlocked By</b>
                              <br />
                              {player && isPlayer(player) ? "" : "Sign up and "}
                              {arenaModeUnlock[ArenaMode.COMPETITIVE]}{" "}
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </Typography>
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={() => setMode(ArenaMode.COMPETITIVE)}
                            checked={mode === ArenaMode.COMPETITIVE}
                            disabled={competitiveDisabled}
                            icon={
                              competitiveDisabled ? <LockIcon /> : undefined
                            }
                          />
                        }
                        sx={unselectable}
                        label={arenaModeLabel[ArenaMode.COMPETITIVE]}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <Typography align="center">
                          {arenaModeDescription[ArenaMode.PRESTIGE]}
                          {prestigeDisabled ? (
                            <Fragment>
                              <br />
                              <br />
                              <b>Unlocked By</b>
                              <br />
                              {player && isPlayer(player) ? "" : "Sign up and "}
                              {arenaModeUnlock[ArenaMode.PRESTIGE]}{" "}
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </Typography>
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={() => setMode(ArenaMode.PRESTIGE)}
                            checked={mode === ArenaMode.PRESTIGE}
                            disabled={prestigeDisabled}
                            icon={prestigeDisabled ? <LockIcon /> : undefined}
                          />
                        }
                        sx={unselectable}
                        label={arenaModeLabel[ArenaMode.PRESTIGE]}
                      />
                    </Tooltip>
                  </RadioGroup>
                </Grid>
                <Grid justifyContent="space-evenly" sx={{ mt: 2 }}>
                  {account && (
                    <CostButton
                      disabled={
                        account.points < cst.arena.cost || disableStartButtons
                      }
                      label={"Play"}
                      cost={cst.arena.cost}
                      variant={"contained"}
                      onClick={startArenaByPoints}
                    />
                  )}
                  <Tooltip
                    title={
                      <Box>
                        {tokenTime &&
                          tokenTime.sponsors &&
                          lt(
                            {
                              anonymous: tokenTime.sponsors.anonymous,
                              names: tokenTime.sponsors.names,
                              total:
                                tokenTime.sponsors.anonymous +
                                tokenTime.sponsors.names.length,
                            },
                            ({ anonymous, names, total }) => (
                              <Fragment>
                                <Typography textAlign="center">
                                  <b>
                                    You've been given
                                    {" " + total + " "}
                                    free play
                                    {total > 1 ? "s" : ""}!
                                  </b>
                                  <br />
                                  <br />
                                  {total === 1
                                    ? "This free play was "
                                    : "These free plays were "}
                                  sponsored by:
                                  <br />
                                  <SponsorText
                                    names={names}
                                    anonymous={anonymous}
                                  />
                                  !
                                  <br />
                                  <br />
                                  <i>
                                    All mazers are given a free play when
                                    someone supports <b>maze.game</b> by buying{" "}
                                    <Points /> in the{" "}
                                    <Link
                                      underline="none"
                                      href="#"
                                      onClick={() => navigate("/store")}
                                    >
                                      Store
                                    </Link>
                                    .
                                  </i>
                                </Typography>
                              </Fragment>
                            )
                          )}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                    open={
                      tokenTime !== undefined &&
                      tokenTime.sponsors !== undefined &&
                      tokenTime.sponsors !== null &&
                      tokenTime.sponsors.anonymous +
                        tokenTime.sponsors.names.length >
                        0
                    }
                  >
                    <Badge
                      badgeContent={
                        tokenTime && !isDisabled(tokenTime, secondsPassed)
                          ? tokenTime.availableTokens +
                            ((player !== null ||
                              tokenTime.availableTokens === 0) &&
                            tokenTime.timeLeft / 1000 - secondsPassed <= 0
                              ? 1
                              : 0)
                          : 0
                      }
                      color="secondary"
                      sx={{ m: 1 }}
                    >
                      <Button
                        sx={{ p: 1, pt: 0.8, pb: 1.2 }}
                        color="success"
                        variant="contained"
                        disabled={
                          isDisabled(tokenTime, secondsPassed) ||
                          disableStartButtons
                        }
                        onClick={startArenaByToken}
                        endIcon={
                          isDisabled(tokenTime, secondsPassed) ? undefined : (
                            <PlayCircleOutlineIcon />
                          )
                        }
                      >
                        <Typography
                          sx={{
                            pr: isDisabled(tokenTime, secondsPassed) ? 1 : 0,
                            transform: "translate(0%, 8%)",
                          }}
                        >
                          {player ? "Free Play" : "Play"}
                        </Typography>
                        {tokenTime && isDisabled(tokenTime, secondsPassed) && (
                          <Chip
                            size="small"
                            label={secondsToHHMMSS(
                              tokenTime.timeLeft / 1000 - secondsPassed
                            )}
                          />
                        )}
                      </Button>
                    </Badge>
                  </Tooltip>
                  <Grid item xs={12}>
                    {isDisabled(tokenTime, secondsPassed) && tokenTime && (
                      <Alert variant="outlined" severity="info">
                        You can{" "}
                        {player === null ? (
                          <Fragment>
                            <Link
                              underline="none"
                              href="#"
                              onClick={() => navigate("/signup")}
                            >
                              <b>Register</b>
                            </Link>{" "}
                            to play again immediately! <Divider>or</Divider>I
                          </Fragment>
                        ) : (
                          "i"
                        )}
                        nvite your friends to <b>maze.game</b> using the link
                        below to get additional{" "}
                        {player && !player.guest ? "free " : ""}plays in the{" "}
                        <b>Arena</b>!
                        <CopyInput
                          link={cst.hostName + tokenTime.replenishUrl}
                          label={"Referral link"}
                        />
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </MazePaper>
            </Box>
          )}
          {snapshot && (
            <React.Fragment>
              {result && (
                <ArenaRewardElement
                  close={() => {
                    setSnapshot(null);
                    setRound(undefined);
                    setResult(undefined);
                    setRoundResult(undefined);
                  }}
                  player={playerOrGuest}
                  arenaResult={result}
                  arenaStatus={snapshot.status}
                />
              )}
              {result === undefined && round === undefined && (
                <ArenaSnapshotElement
                  nextRound={nextRound}
                  nextDisabled={nextDisabled}
                  setSnapshot={setSnapshot}
                  snapshot={snapshot}
                  player={playerOrGuest}
                  claimReward={claimReward}
                />
              )}
              {result === undefined && round && (
                <React.Fragment>
                  {roundResult === undefined && (
                    <BodyContent>
                      <InteractiveGame
                        showSkip={false}
                        cosmetics={cosmetics}
                        round={round}
                        duration={duration}
                        submitLayout={submitLayout}
                        onLayoutChange={onLayoutChange}
                        onBeforeUnload={onBeforeUnload}
                        onPageVisibitilyChange={onPageVisibitilyChange}
                        origin={[
                          arenaModeLabel[snapshot.mode] + " Arena",
                          "" +
                            snapshot.status.wins +
                            " - " +
                            snapshot.status.losses,
                        ]}
                      />
                    </BodyContent>
                  )}
                  {roundResult && (
                    <RoundResultElement
                      showFreeServeTitle
                      account={account}
                      refreshAccount={refreshAccount}
                      roundResult={roundResult}
                      replayFinished={() => {
                        setNextDisabled(false);
                        setRoundResult(undefined);
                        setRound(undefined);
                        setDuration(cst.arena.duration);
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Fragment>
  );
};
