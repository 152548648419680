export type Coord = {
  x: number;
  y: number;
};

export const distance = (c1: Coord, c2: Coord) => {
  let xd = c1.x - c2.x;
  let yd = c1.y - c2.y;
  return Math.sqrt(xd * xd + yd * yd);
};

export const coordString = (prefix: string, coord: Coord) =>
  prefix + coord.x + "," + coord.y;
