import { lt } from "../utils/lt";
import { RoundResult } from "./Arena";
import { BoardSize } from "./Board";
import { CosmeticPlayerSolution, Cosmetics, isCosmetics } from "./Cosmetics";
import { Path, isPath } from "./Path";
import { isPlayer, Player } from "./Player";
import { Round } from "./Round";
import { Solution } from "./Solution";

export enum AcademyMedal {
  PLATINUM = "PLATINUM",
  GOLD = "GOLD",
  SILVER = "SILVER",
  BRONZE = "BRONZE",
}

export const medalButtonColor: { [key in AcademyMedal]: string } = {
  PLATINUM: "#1ab582",
  GOLD: "#cea84f",
  SILVER: "#b0b0b0",
  BRONZE: "#a86e46",
};

export const medalColor: { [key in AcademyMedal]: string } = {
  PLATINUM: "#1ab582",
  GOLD: "#97804a",
  SILVER: "#808080",
  BRONZE: "#91674a",
};

export enum AcademyCategory {
  SPARSE = "SPARSE",
  MEDIUM = "MEDIUM",
  DENSE = "DENSE",
  WAYPOINT = "WAYPOINT",
}

export const academyCategoryLabel: { [key in AcademyCategory]: string } = {
  SPARSE: "Sparse",
  MEDIUM: "Medium",
  DENSE: "Dense",
  WAYPOINT: "Waypoint",
};

export function isAcademyCategory(category: any): category is AcademyCategory {
  return (
    category === AcademyCategory.SPARSE ||
    category === AcademyCategory.MEDIUM ||
    category === AcademyCategory.DENSE ||
    category === AcademyCategory.WAYPOINT
  );
}

export type CuratableRound = {
  round: Round;
  category: AcademyCategory;
  boardSize: BoardSize;
  id: number;
};

export type RoundReview = {
  player: Player;
  categoryFit: number;
  difficulty: number;
  constrain: number;
  score: number;
};

export type AcademyRoundCutoffs = {
  platinum: number;
  gold: number;
  silver: number;
  bronze: number;
};

export type ResultPlayers = {
  result: number;
  players: Player[];
  solution: CosmeticPlayerSolution
};

export type CosmeticPlayerPath = {
  player: Player;
  path: Path;
  cosmetics: Cosmetics;
};

export type AcademyRound = {
  round: Round;
  solution: Solution | null;
  otherResults: number[];
  unlockedMedal: AcademyMedal | null;
  cutOffs: AcademyRoundCutoffs;
  integerResult: number | null;
};

export type AcademyRoundResult = {
  roundResult: RoundResult;
  otherResults: number[];
  unlockedMedal: AcademyMedal | null;
  cutOffs: AcademyRoundCutoffs;
  integerResult: number;
};

// Type guards for AcademyMedal enum
export function isAcademyMedal(medal: any): medal is AcademyMedal {
  return (
    medal === AcademyMedal.PLATINUM ||
    medal === AcademyMedal.GOLD ||
    medal === AcademyMedal.SILVER ||
    medal === AcademyMedal.BRONZE
  );
}

// Type guards for data classes
export function isCuratableRound(obj: any): obj is CuratableRound {
  return (
    typeof obj === "object" &&
    obj.round !== undefined &&
    obj.category !== undefined &&
    obj.boardSize !== undefined &&
    obj.id !== undefined
  );
}

export function isCosmeticPlayerPath(obj: any): obj is CosmeticPlayerPath {
  return (
    typeof obj === "object" &&
    isPlayer(obj.player) &&
    isPath(obj.path) &&
    isCosmetics(obj.cosmetics)
  );
}
export function isCuratableRounds(obj: any): obj is CuratableRound[] {
  return (
    Array.isArray(obj) && (obj as any[]).every((it) => isCuratableRound(it))
  );
}

export function isRoundReview(obj: any): obj is RoundReview {
  return (
    typeof obj === "object" &&
    obj.player !== undefined &&
    obj.categoryFit !== undefined &&
    obj.difficulty !== undefined &&
    obj.constrain !== undefined &&
    obj.score !== undefined
  );
}

export function isRoundReviews(obj: any): obj is RoundReview[] {
  return Array.isArray(obj) && (obj as any[]).every((it) => isRoundReview(it));
}

export function isAcademyRoundCutoffs(obj: any): obj is AcademyRoundCutoffs {
  return (
    typeof obj === "object" &&
    obj.PLATINUM !== undefined &&
    obj.GOLD !== undefined &&
    obj.SILVER !== undefined &&
    obj.BRONZE !== undefined
  );
}

export function isResultPlayers(obj: any): obj is ResultPlayers {
  return (
    typeof obj === "object" &&
    obj.result !== undefined &&
    obj.players !== undefined
  );
}

export function isResultPlayersList(obj: any): obj is ResultPlayers[] {
  return (
    Array.isArray(obj) && (obj as any[]).every((it) => isResultPlayers(it))
  );
}

export function isAcademyRoundResult(obj: any): obj is AcademyRoundResult {
  return typeof obj === "object" && obj.roundResult !== undefined;
}

export type AcademyLevelProgress = {
  medal?: AcademyMedal;
  stale: boolean;
};

export function isAcademyLevelProgress(obj: any): obj is AcademyLevelProgress {
  return (
    typeof obj === "object" && (obj.medal ? isAcademyMedal(obj.medal) : true)
  );
}

export function isAcademyLevelProgresses(
  obj: any
): obj is AcademyLevelProgress[] {
  return Array.isArray(obj) && obj.every((v) => isAcademyLevelProgress(v));
}

export type CategoryProgress = { [key in BoardSize]: AcademyLevelProgress[] };

export function isCategoryProgress(obj: any): obj is CategoryProgress {
  return (
    typeof obj === "object" &&
    Object.values(BoardSize).every((boardSize) => {
      return lt(
        obj[boardSize],
        (value) =>
          Array.isArray(value) && value.every((v) => isAcademyLevelProgress(v))
      );
    })
  );
}

export type AcademyProgress = {
  [key in AcademyCategory]: CategoryProgress;
};

export function isAcademyProgress(obj: any): obj is AcademyProgress {
  return (
    typeof obj === "object" &&
    Object.values(AcademyCategory).every((category) =>
      isCategoryProgress(obj[category])
    )
  );
}

export type CuratableRoundReviews = {
  curatableRound: CuratableRound;
  reviews: RoundReview[];
  plays: number;
  cutOffs: AcademyRoundCutoffs;
};

export function isCuratableRoundReviews(
  obj: any
): obj is CuratableRoundReviews {
  return (
    typeof obj === "object" &&
    isCuratableRound(obj.curatableRound) &&
    isRoundReviews(obj.reviews)
  );
}

export function isCuratableRoundReviewsList(
  obj: any
): obj is CuratableRoundReviews[] {
  return (
    Array.isArray(obj) && obj.every((value) => isCuratableRoundReviews(value))
  );
}

export function isAcademyRound(obj: any): obj is AcademyRound {
  return obj.round && obj.cutOffs;
}
