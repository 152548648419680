import {
  Alert,
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getArenaService } from "../../network/ArenaService";
import {
  arenaModeColor,
  arenaModeDescription,
  arenaModeLabel,
  ArenaStatus,
} from "../../shared/Arena";
import { CosmeticArenaSnapshot } from "../../shared/Cosmetics";
import { Player } from "../../shared/Player";
import { cst } from "../../utils/constants";
import { unselectable } from "../../utils/unselectable";
import { useSecondsPassed } from "../../utils/useSecondsPassed";
import { AreanRoundBadge } from "./ArenaRoundBadge";
import { ArenaStatusElement } from "./ArenaStatusElement";
import Emoji from "./Emoji";
import { MazePaper } from "./MazePaper";
import { TipElement } from "./TipElement";

interface ArenaSnapshotElementProps {
  snapshot: CosmeticArenaSnapshot;
  nextDisabled: boolean;
  setSnapshot: React.Dispatch<
    React.SetStateAction<CosmeticArenaSnapshot | null | undefined>
  >;
  nextRound: () => void;
  claimReward: () => void;
  player: Player;
}

const arenaComplete = (status: ArenaStatus) =>
  status.losses === cst.arena.maxLosses || status.wins === cst.arena.maxWins;

export const ArenaSnapshotElement = (props: ArenaSnapshotElementProps) => {
  const {
    snapshot,
    setSnapshot,
    nextRound,
    claimReward,
    player,
    nextDisabled,
  } = props;

  const [showForfeitWarning, setShowForfeitWarning] = useState(false);

  const { secondsPassed, resetSecondsPassed } = useSecondsPassed();

  useEffect(() => {
    resetSecondsPassed();
  }, [snapshot, resetSecondsPassed]);

  useEffect(() => {
    if (
      snapshot.timeLeft &&
      snapshot.timeLeft > 0 &&
      secondsPassed > snapshot.timeLeft
    ) {
      setSnapshot(
        (s) =>
          s && {
            ...s,
            timeLeft: -1,
            status: { ...s.status, losses: s.status.losses + 1 },
          }
      );
    }
  }, [snapshot, secondsPassed, setSnapshot]);

  const [disableClaim, setDisableClaim] = useState(false);

  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  return (
    <Box
      sx={{
        px: { xs: 0, sm: 1 },
        pt: 2,
      }}
      mx="auto"
      maxWidth="sm"
      height="90vh"
    >
      <MazePaper title="Arena" goBack={() => navigate("/")}>
        <Tooltip
          title={
            <Typography>
              <b>{arenaModeLabel[snapshot.mode]}:</b>{" "}
              {arenaModeDescription[snapshot.mode]}
            </Typography>
          }
        >
          <Typography
            bgcolor={arenaModeColor[snapshot.mode]}
            sx={{
              width: { sm: "calc(100% + 48px)", xs: "calc(100% + 16px)" },
              mx: { xs: -1, sm: -3 },
              mt: -1,
              mb: 1,
              ...unselectable,
            }}
          >
            <Divider />
            <b>{arenaModeLabel[snapshot.mode]}</b>
            <Divider sx={{ mt: 0.25 }} />
          </Typography>
        </Tooltip>
        <ArenaStatusElement arenaStatus={snapshot.status} />
        <Fragment>
          {snapshot.results.length > 0 && (
            <Box sx={{ mx: { xs: -1, sm: -3 }, my: 1 }}>
              <Divider />
              <Box sx={{ py: 1, bgcolor: "#464646" }}>
                {snapshot.results.map((arenaRoundResult, index) => (
                  <Fragment key={"outer" + index}>
                    <AreanRoundBadge
                      freeServe={!arenaRoundResult.opponentSolution}
                      roundResult={arenaRoundResult}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      index={index}
                    />
                  </Fragment>
                ))}
              </Box>
              <Divider />
            </Box>
          )}
        </Fragment>
        <React.Fragment>
          {player.guest && (
            <TipElement localStorageKey="guestArenaTip" severity="warning">
              You are playing as a guest, due to technical reasons (guest
              sessions being in memory), maze.game will lose your session when
              the server is restarted. So sign up if you want to not have your
              progress deleted indiscriminately <Emoji symbol="🙏" />
            </TipElement>
          )}
          {snapshot.timeLeft && snapshot.timeLeft > 0 && (
            <Alert severity="warning" sx={{ mt: 3, justifyContent: "center" }}>
              You have an active Arena round, hurry up before the timer runs
              out!
            </Alert>
          )}
          {snapshot.timeLeft &&
            (snapshot.timeLeft === -1 || snapshot.timeLeft < secondsPassed) && (
              <Alert severity="error" sx={{ mt: 3, justifyContent: "center" }}>
                The time ran out for you to submit a solution to the last round.
              </Alert>
            )}
          {snapshot.status.losses < cst.arena.maxLosses &&
            snapshot.status.wins < cst.arena.maxWins && (
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                onClick={nextRound}
                fullWidth
                disabled={nextDisabled}
              >
                {snapshot.timeLeft && snapshot.timeLeft > secondsPassed
                  ? "Play Round (" + (snapshot.timeLeft - secondsPassed) + ")"
                  : "Next Round"}
              </Button>
            )}
          {showForfeitWarning && (
            <Alert severity="warning" sx={{ mt: 3, justifyContent: "center" }}>
              By forfeiting, your progress in this Arena will be lost
              <br /> Are you sure you wish to forfeit?
            </Alert>
          )}
          {(!player.guest || arenaComplete(snapshot.status)) && (
            <Button
              sx={{ mt: 3 }}
              variant={
                arenaComplete(snapshot.status) ? "contained" : "outlined"
              }
              onClick={() => {
                if (arenaComplete(snapshot.status) || showForfeitWarning) {
                  setDisableClaim(true);
                  if (player.guest) {
                    navigate("/signup");
                  } else {
                    claimReward();
                  }
                } else {
                  setShowForfeitWarning(true);
                  setDisableClaim(true);
                  window.setTimeout(() => {
                    setDisableClaim(false);
                  }, 2000);
                }
              }}
              disabled={disableClaim}
              fullWidth
              color={
                snapshot.status.losses === cst.arena.maxLosses && !player.guest
                  ? "primary"
                  : snapshot.status.wins === cst.arena.maxWins || player.guest
                  ? "success"
                  : "warning"
              }
            >
              {arenaComplete(snapshot.status)
                ? player.guest
                  ? "Sign up to Claim Reward"
                  : "Claim Reward"
                : "Forfeit and claim reward"}
            </Button>
          )}
          {player.guest && arenaComplete(snapshot.status) && (
            <Button
              onClick={() =>
                getArenaService()
                  .dismissReward()
                  .then(() => setSnapshot(null))
              }
              sx={{ mt: 1 }}
              variant={"outlined"}
              fullWidth
              color={"warning"}
            >
              Dismiss Reward
            </Button>
          )}
        </React.Fragment>
      </MazePaper>
    </Box>
  );
};
