import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Sprite, useApp, useTick } from "@inlet/react-pixi";
import { useBoardRepresentation } from "./utils/useBoardRepresentation";
import { Counter } from "./utils/useCounter";
import { Board } from "../shared/Board";
import { Tower } from "../shared/Tower";
import { cst } from "../utils/constants";
import { Layout } from "../shared/Layout";
import { useRunner } from "./Runner";
import { useClaps } from "./utils/useClaps";
import { useRunnerState } from "./utils/useRunnerState";
import { Path } from "../shared/Path";
import { RunnerIndicatorState } from "../pages/elements/RunnerIndicator";
import { ScoreCounter } from "./ui/ScoreCounter";
import { Textures } from "./sprites/texture";
import { useBorders } from "./utils/useBorders";
import { TilingSpriteProps, useEntryZone } from "./utils/useEntryZone";
import { useBoardInteraction } from "./utils/useBoardInteraction";
import { Cosmetics } from "../shared/Cosmetics";
import { Graphics } from "@pixi/graphics";
import { Renderer } from "@pixi/core";
import { useTimerFlash } from "./utils/useTimerFlash";
import { usePattern } from "./utils/usePattern";
import { useStaticPattern } from "./utils/useStaticPattern";
import { useBlocks } from "./utils/useBlocks";
import { useTilingSpriteRenderTexture } from "./utils/useTilingSpriteRenderTexture";
import { MaskedContainer } from "./components/MaskedContainer";
import { ToggleTestProp } from "../pages/ToggleTest";
import PathingLine from "./components/PathingLine";
import HighVisBlocks from "./components/HighVisBlocks";
import { lt } from "../utils/lt";
import Cursor from "./Cursor";

interface BoardElementProps {
  textures: Textures;
  x?: number;
  y?: number;
  tileSize: number;
  setLayout: (layout: Layout) => void;
  solutionLayout?: Layout;
  startingLayout?: Layout;
  submit: () => void;
  skip: () => void;
  isReplay: boolean;
  towerCounter: Counter;
  clapCounter: Counter;
  incrementTutorialStep?: () => void;
  runnerCompleted?: () => void;
  setImage?: (image: string) => void;
  setRunnerIndicatorState: React.Dispatch<
    React.SetStateAction<RunnerIndicatorState>
  >;
  scoreOffset: number;
  tutorialStep?: number;
  board: Board;
  showSkip?: boolean;
  drawingStatic: boolean;
  path?: Path;
  tutorial?: boolean;
  interactive?: boolean;
  score?: number;
  pause?: boolean;
  maxScore?: number;
  drawMode: boolean;
  cosmetics: Cosmetics;
  timerFlash: React.MutableRefObject<() => void>;
  hasLoaded: () => void;
  setScore?: React.Dispatch<number>;
  resetReference?: React.MutableRefObject<() => void>;
  setLayoutReference?: React.MutableRefObject<(layout: Layout) => void>;
  test?: ToggleTestProp;
}

const BoardElement = (props: BoardElementProps) => {
  const {
    x = 0,
    y = 0,
    interactive = true,
    board,
    tileSize,
    solutionLayout,
    startingLayout,
    towerCounter,
    clapCounter,
    drawingStatic,
    isReplay = false,
    drawMode,
    pause = false,
    score = 0,
    timerFlash,
    hasLoaded,
    setImage,
    setRunnerIndicatorState,
    runnerCompleted = () => {},
    incrementTutorialStep = () => {},
    setLayout,
    tutorialStep,
    path,
    submit,
    cosmetics,
    scoreOffset,
    textures,
    setScore,
    maxScore,
    resetReference,
    setLayoutReference,
    test,
  } = props;

  const [towers, setTowers] = useState<Tower[]>(
    startingLayout ? startingLayout.towers : []
  );

  const app = useApp();

  const renderer = useMemo(() => app.renderer as Renderer, [app]);

  const { staticBlockTopTexture, playerBlockTopTexture, blockBottomTexture } =
    useBlocks(
      board,
      solutionLayout ? solutionLayout.towers : towers,
      renderer,
      textures,
      test === undefined || test.renderBlocks
    );

  const added = useRef(false);

  useEffect(() => {
    if (solutionLayout) {
      towerCounter.decrementFromMax(solutionLayout.towers.length);
      clapCounter.decrementFromMax(
        solutionLayout.towers.filter((tower) => tower.clap).length
      );
      added.current = false;
      setTowers(solutionLayout.towers);
    }
    // eslint-disable-next-line
  }, [solutionLayout, setTowers]);

  useEffect(() => setLayout({ towers: towers }), [towers, setLayout]);

  const {
    canSetTower,
    resetRepresentation,
    removeTowerInRepresentation,
    forceRepresentation,
  } = useBoardRepresentation(board, startingLayout);

  const [actions, setActions] = useState<number>(0);

  const [startZoneTops, startZoneBottoms] = useEntryZone(board, false);

  const [endZoneTops, endZoneBottoms] = useEntryZone(board, true);

  const { borderBottoms, borderTops } = useBorders(board, 0xffffff);

  const reset = useCallback(() => {
    towerCounter.setValue((v) => v + towers.length);
    clapCounter.setValue(
      (v) => v + towers.filter((tower) => tower.clap).length
    );
    setTowers([]);
    resetRepresentation();
    setActions((s) => s + 1);
  }, [
    towerCounter,
    clapCounter,
    setTowers,
    towers,
    resetRepresentation,
    setActions,
  ]);

  useEffect(() => {
    if (resetReference) {
      resetReference.current = reset;
    }
  }, [resetReference, reset]);

  const setLoadedLayout = useCallback(
    (layout: Layout) => {
      forceRepresentation(layout);
      setTowers(layout.towers);
      setActions((s) => s + 1);
    },
    [setTowers, forceRepresentation]
  );

  useEffect(() => {
    if (setLayoutReference) {
      setLayoutReference.current = setLoadedLayout;
    }
  }, [setLayoutReference, setLoadedLayout]);

  const { getRunnerState, slowInstances } = useRunnerState(
    setRunnerIndicatorState,
    path
  );

  const hasSubmitted = useRef(false);

  const stillRunning = useRef(true);
  useEffect(() => {
    if (
      (tutorialStep === 5 && towers.length === 3) ||
      (tutorialStep === 8 && towers.length === 0) ||
      (tutorialStep === 14 && towers.length === 10)
    ) {
      incrementTutorialStep();
    }

    if (tutorialStep === 17) {
      if (!path && !hasSubmitted.current) {
        hasSubmitted.current = true;
        submit();
        incrementTutorialStep();
      }
    }
  }, [towers, tutorialStep, incrementTutorialStep, submit, path]);

  const deltaTotal = useRef(score * cst.speedModifier);
  const deltaAcc = useRef(score * cst.speedModifier);

  const [internalScore, setInternalScore] = useState(score);

  useEffect(() => {
    if (
      path === undefined &&
      (deltaAcc.current > 0 || deltaTotal.current > 0 || internalScore > 0)
    ) {
      deltaTotal.current = 0;
      deltaAcc.current = 0;
      setInternalScore(0);
      if (setScore) setScore(0);
    }
  }, [setInternalScore, setScore, internalScore, path]);

  const internalMaxScore = maxScore ? maxScore : path ? path.result : 0;

  useEffect(() => {
    if (score) {
      setInternalScore(score);
      setRunnerIndicatorState((s) => (s === "completed" ? "running" : s));
      stillRunning.current = true;
      deltaAcc.current = score * cst.speedModifier;
      deltaTotal.current = deltaAcc.current;
      if (path && score >= path?.result) {
        setRunnerIndicatorState("completed");
      }
    }
  }, [setInternalScore, score, path, setRunnerIndicatorState]);

  const [hasRendered, setHasRendered] = useState(false);

  useTick((delta) => {
    if (!pause) deltaAcc.current += delta;
    if (path && deltaAcc.current > deltaTotal.current + 1) {
      deltaTotal.current = deltaAcc.current;
      setInternalScore(deltaTotal.current / cst.speedModifier);
      if (setScore) setScore(deltaTotal.current / cst.speedModifier);
      if (
        stillRunning.current &&
        deltaTotal.current / cst.speedModifier >= path.result
      ) {
        stillRunning.current = false;
        setRunnerIndicatorState("completed");
      }
      if (deltaTotal.current / cst.speedModifier >= internalMaxScore) {
        runnerCompleted();
      }
    }
  }, path !== undefined && hasRendered && deltaTotal.current / cst.speedModifier < internalMaxScore);

  const runner = useRunner(
    cst.tileSize,
    Math.min(internalScore, path ? path.result : 0),
    textures,
    getRunnerState,
    cosmetics.ball,
    path?.result
  );

  const clapCoords = useMemo(
    () =>
      [...towers, ...board.staticTowers]
        .filter((t) => t.clap)
        .map((t) => t.coord),
    [board, towers]
  );

  const clapTops = useClaps(
    textures,
    cst.tileSize,
    Math.min(internalScore, path ? path.result : 0),
    clapCoords,
    path,
    slowInstances
  );

  const bottomFrameSpriteProps = useMemo<TilingSpriteProps[]>(
    () =>
      (
        [
          {
            texture: "tile.png",
            y: 1,
            x: 1 / 2,
            width: board.width,
            height: board.height,
          },
        ] as TilingSpriteProps[]
      )
        .concat(borderBottoms)
        .concat(endZoneBottoms)
        .concat(startZoneBottoms),
    [borderBottoms, endZoneBottoms, startZoneBottoms, board.width, board.height]
  );
  const bottomFrameTexture = useTilingSpriteRenderTexture(
    renderer,
    textures,
    bottomFrameSpriteProps,
    test === undefined || test.renderFrame
  );

  const topFrameSpriteProps = useMemo<TilingSpriteProps[]>(
    () => borderTops.concat(endZoneTops).concat(startZoneTops),
    [borderTops, endZoneTops, startZoneTops]
  );

  const topFrameTexture = useTilingSpriteRenderTexture(
    renderer,
    textures,
    topFrameSpriteProps,
    test === undefined || test.renderFrame
  );

  const noListeners = useRef(true);

  useEffect(() => {
    if (
      app.renderer &&
      noListeners.current &&
      bottomFrameTexture &&
      blockBottomTexture &&
      playerBlockTopTexture &&
      staticBlockTopTexture &&
      topFrameTexture
    ) {
      noListeners.current = false;
      app.renderer.addListener("postrender", () => {
        if (app.renderer) {
          app.renderer.removeAllListeners();
          setHasRendered(true);
          hasLoaded();
        }
      });
    }
  }, [
    app,
    hasLoaded,
    setImage,
    setHasRendered,
    bottomFrameTexture,
    blockBottomTexture,
    playerBlockTopTexture,
    staticBlockTopTexture,
    topFrameTexture,
  ]);

  const patternMask = usePattern(
    cosmetics.block.pattern.pattern,
    board.width,
    board.height,
    renderer,
    test === undefined || test.renderPlayerPattern
  );

  const staticPattern = useStaticPattern(
    renderer,
    test === undefined || test.renderStaticPattern,
    board
  );

  const { cursorState, containerInteraction } = useBoardInteraction({
    towers,
    board,
    drawMode,
    towerCounter,
    clapCounter,
    canSetTower,
    setTowers,
    drawingStatic,
    actions,
    setActions,
    removeTowerInRepresentation,
    interactive,
    tutorialStep,
    textures,
    tileSize,
    app,
  });

  useEffect(() => {
    app.render()
  }, [cursorState, app])

  const timerFlashElement = useTimerFlash(
    board,
    scoreOffset,
    renderer,
    topFrameTexture,
    timerFlash,
    test === undefined || test.renderStaticPattern
  );

  const mask = useMemo(
    () =>
      new Graphics()
        .beginFill(0xff0000)
        .drawRect(
          0,
          0,
          (board.width + 1) * cst.tileSize,
          (board.height + 3 / 2) * cst.tileSize
        ),
    [board]
  );

  return (
    <Container
      x={x}
      y={y}
      interactive={interactive}
      width={tileSize * (board.width + 1)}
      height={tileSize * (board.height + 3 / 2)}
    >
      <MaskedContainer
        interactiveChildren={false}
        scale={tileSize / cst.tileSize}
        mask={mask}
      >
        {(test === undefined || (test && test.bottomFrame)) &&
          bottomFrameTexture && <Sprite texture={bottomFrameTexture} />}
        {(test === undefined || (test && test.blockBottoms)) &&
          blockBottomTexture && (
            <Sprite
              texture={blockBottomTexture}
              y={(cst.tileSize * 3) / 4}
              x={(1 / 4) * cst.tileSize}
            />
          )}
        {board.waypoint && (
          <Sprite
            texture={textures["waypoint.png"]}
            x={(board.waypoint.x + 9 / 16) * cst.tileSize}
            y={(board.waypoint.y + 17 / 16) * cst.tileSize}
            width={(cst.tileSize * 7) / 8}
            height={(cst.tileSize * 7) / 8}
            tint={0xff0000}
            alpha={0.4}
          />
        )}
        {path && (
          <PathingLine
            path={path}
            score={internalScore}
            slowInstances={slowInstances || []}
            board={board}
            alpha={lt(localStorage.getItem("pathingLine"), (alpha) =>
              alpha ? +alpha : 0
            )}
          />
        )}
        {(test === undefined || (test && test.runnerBottom)) &&
          runner &&
          runner.bottom}
        <Container y={cst.tileSize} x={(1 / 2) * cst.tileSize}>
          {(test === undefined || (test && test.playerTopOne)) &&
            playerBlockTopTexture && (
              <Sprite
                texture={playerBlockTopTexture}
                tint={cosmetics.block.pattern.firstColor.color.tint}
              />
            )}
          {(test === undefined || (test && test.playerInlays)) &&
            towers
              .filter((it) => it.clap)
              .map(({ coord }, index) => (
                <Sprite
                  key={index}
                  texture={textures["frostInlay.png"]}
                  x={(coord.x + 1 / 4) * cst.tileSize}
                  y={(coord.y + 1 / 4) * cst.tileSize}
                  tint={cosmetics.block.pattern.firstColor.indentTint}
                />
              ))}
          {(test === undefined || (test && test.playerTopTwo)) &&
            cosmetics.block.pattern.secondColor &&
            playerBlockTopTexture && (
              <MaskedContainer mask={patternMask ? patternMask : undefined}>
                <Sprite
                  texture={playerBlockTopTexture}
                  tint={cosmetics.block.pattern.secondColor.color.tint}
                />
                {(test === undefined || (test && test.playerInlays)) &&
                  towers
                    .filter((it) => it.clap)
                    .map(({ coord }, index) => (
                      <Sprite
                        key={index}
                        texture={textures["frostInlay.png"]}
                        x={(coord.x + 1 / 4) * cst.tileSize}
                        y={(coord.y + 1 / 4) * cst.tileSize}
                        tint={cosmetics.block.pattern.secondColor!.indentTint}
                      />
                    ))}
              </MaskedContainer>
            )}
          {(test === undefined || (test && test.staticTopOne)) &&
            staticBlockTopTexture && (
              <Sprite
                texture={staticBlockTopTexture}
                tint={cst.firstStaticTint}
              />
            )}
          {(test === undefined || (test && test.staticInlays)) &&
            board.staticTowers
              .filter((it) => it.clap)
              .map(({ coord }, index) => (
                <Sprite
                  key={index}
                  texture={textures["frostInlay.png"]}
                  x={(coord.x + 1 / 4) * cst.tileSize}
                  y={(coord.y + 1 / 4) * cst.tileSize}
                  tint={cst.firstStaticInlayTint}
                />
              ))}
          {(test === undefined || (test && test.staticTopTwo)) &&
            staticBlockTopTexture && (
              <MaskedContainer mask={staticPattern ? staticPattern : undefined}>
                <Sprite
                  texture={staticBlockTopTexture}
                  tint={cst.secondStaticTint}
                />
                {(test === undefined || (test && test.staticInlays)) &&
                  board.staticTowers
                    .filter((it) => it.clap)
                    .map(({ coord }, index) => (
                      <Sprite
                        key={index}
                        texture={textures["frostInlay.png"]}
                        x={(coord.x + 1 / 4) * cst.tileSize}
                        y={(coord.y + 1 / 4) * cst.tileSize}
                        tint={cst.secondStaticInlayTint}
                      />
                    ))}
              </MaskedContainer>
            )}
        </Container>
        <HighVisBlocks
          board={board}
          playerTowers={solutionLayout ? solutionLayout.towers : towers}
          alpha={lt(localStorage.getItem("highVis"), (alpha) =>
            alpha ? +alpha : 0
          )}
        />
        {(test === undefined || (test && test.topFrame)) && topFrameTexture && (
          <Sprite texture={topFrameTexture} />
        )}
        {(test === undefined || (test && test.timerFlash)) && timerFlashElement}
        {(test === undefined || (test && test.runnerTop)) &&
          runner &&
          runner.top}
        {(test === undefined || (test && test.frostTops)) && clapTops}
        <Container x={cst.tileSize / 2} y={cst.tileSize} visible={!isReplay}>
          {(test === undefined || (test && test.cursor)) &&
            interactive &&
            cursorState.withinBounds && (
              <Cursor
                textures={textures}
                x={cursorState.coord.x}
                y={cursorState.coord.y}
                tileSize={cst.tileSize}
                onFrost={cursorState.selected?.clap}
                cursorType={cursorState.type}
                key="cursor"
              />
            )}
        </Container>
        {tutorialStep !== 33 && path !== undefined && (
          <ScoreCounter
            tileSize={cst.tileSize}
            textures={textures}
            score={Math.min(internalScore, maxScore ? maxScore : path.result)}
            x={(board.width - 18 / 6 - scoreOffset) * cst.tileSize}
            y={(1 / 4) * cst.tileSize}
            height={(cst.tileSize * 2) / 3}
          />
        )}
      </MaskedContainer>
      <Sprite
        texture={textures["empty.png"]}
        alpha={0}
        interactive={
          interactive &&
          (path === undefined || tutorialStep === 24) &&
          !isReplay
        }
        x={0}
        y={0}
        width={tileSize * (board.width + 1)}
        height={tileSize * (board.height + 3 / 2)}
        {...containerInteraction(cursorState)}
      />
    </Container>
  );
};

export default BoardElement;
