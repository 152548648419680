import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BlockColor,
  defaultBlockCosmestics,
  defaultBlockPattern,
  defaultCosmetics,
  Rarity,
} from "../shared/Cosmetics";
import { newColors, tops } from "../utils/chosentops";
import { colornames } from "../utils/colornames";
import {
  cmykToRGB,
  hexToRGB,
  rgbDist,
  rgbToCMYK,
  rgbToInt,
  tintToRGB,
} from "../utils/colors";
import { lt } from "../utils/lt";
import { InspectablePattern } from "./elements/InspectablePattern";
import { InteractiveGame } from "./elements/InteractiveGame";
import { BlockPatternName } from "./elements/BlockPatternName";
import { exampleLayout, exampleRound } from "./SpriteTest";

export const ColorPicker = () => {
  const [color, setColor] = useState<BlockColor>();
  const oldColors = useMemo(() => tops, []);
  const [pickedColors, setPickedColors] = useState<BlockColor[]>(newColors);
  const [convertedColors, setConvertedColors] = useState<BlockColor[]>(() =>
    colornames
      .filter(({ hex }) =>
        lt(
          rgbToCMYK(hexToRGB(hex)),
          (cmyk) => cmyk.k < 0.5 && cmyk.y > cmyk.m * 5 && cmyk.y > cmyk.c * 5
        )
      )
      .map(({ name, hex }) => {
        let rgb = hexToRGB(hex);
        let cmyk = rgbToCMYK(rgb);
        let tint = rgbToInt(rgb);
        let indentTint = rgbToInt(
          cmykToRGB({ ...cmyk, k: Math.min(cmyk.k + 0.1) })
        );
        let retval: BlockColor = {
          color: { name: name, tint },
          indentTint,
          rarity: Rarity.COMMON,
        };
        return retval;
      })
  );
  const sortedColors = useMemo(() => {
    const rgbColors = oldColors
      .concat(pickedColors)
      .map((it) => tintToRGB(it.color.tint));
    return convertedColors
      .map((it) => {
        const rgb = tintToRGB(it.color.tint);
        const minDist = Math.min(
          ...rgbColors.map((otherRgb) => rgbDist(rgb, otherRgb))
        );
        return { color: it, minDist };
      })
      .sort((a, b) => b.minDist - a.minDist)
      .map((it) => it.color);
  }, [convertedColors, pickedColors, oldColors]);

  const cosmetics = useMemo(
    () => ({
      ...defaultCosmetics,
      block: color
        ? { pattern: { ...defaultBlockPattern, firstColor: color } }
        : defaultBlockCosmestics,
    }),
    [color]
  );

  useEffect(() => {
    let str = "";
    pickedColors.forEach((a) => {
      const newIndent = rgbToInt(
        cmykToRGB(
          lt(rgbToCMYK(tintToRGB(a.color.tint)), (cmyk) => ({
            ...cmyk,
            k: cmyk.k + 0.1,
          }))
        )
      );
      str +=
        "UPDATE block_colors SET indent_tint=" +
        newIndent +
        " WHERE tint=" +
        a.color.tint +
        ";\n";
    });
    console.log(str);
  }, [pickedColors]);

  const lastIndex = useRef(0);

  const tryColor = useCallback(() => {
    setColor(sortedColors[lastIndex.current]);
    lastIndex.current += 1;
  }, [setColor, sortedColors]);

  return (
    <Box>
      <Box sx={{ pt: 0 }} maxWidth="lg" mx="auto" height="60vh">
        {color && (
          <Fragment>
            <InteractiveGame
              cosmetics={cosmetics}
              round={exampleRound}
              startingLayout={exampleLayout}
            />
            <Typography>{color.color.name}</Typography>
          </Fragment>
        )}
      </Box>
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" width={0.6} sx={{ pt: 5 }}>
          <Grid item xs={12}>
            <Button onClick={tryColor}>new</Button>
            <Button
              onClick={() => {
                if (color) {
                  const nonPicked = sortedColors.slice(0, lastIndex.current);
                  setConvertedColors((c) =>
                    c.filter(
                      (it) =>
                        nonPicked.find(
                          (other) => other.color.tint === it.color.tint
                        ) === undefined
                    )
                  );
                  setPickedColors((p) => {
                    let newColors = [...p, color];
                    return newColors;
                  });
                  lastIndex.current = 0;
                }
              }}
            >
              pick
            </Button>
          </Grid>
          {pickedColors.map((a, i) => {
            let pattern = { ...defaultBlockPattern, firstColor: a };
            return (
              <Grid item xs={1} key={i}>
                <InspectablePattern blockPattern={pattern} />
                <BlockPatternName pattern={pattern} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <TextField
        id="outlined-multiline-flexible"
        multiline
        value={JSON.stringify(pickedColors, null, 2)}
      />
    </Box>
  );
};
