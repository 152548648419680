import React, { useEffect, useRef, useState } from "react";
import { Textures } from "../sprites/texture";
import { TimeCounter } from "./TimeCounter";

interface CountdownProps {
  x?: number;
  y?: number;
  height: number;
  duration: number;
  tileSize: number;
  ended: boolean;
  textures: Textures;
  timerFlash: () => void;
  timesUp: () => void;
}

export const Countdown = (props: CountdownProps) => {
  const {
    x = 0,
    y = 0,
    height,
    duration,
    tileSize,
    timerFlash,
    timesUp,
    ended,
    textures,
  } = props;

  const [progress, setProgress] = useState<number>(duration);

  const interval = useRef(0);

  useEffect(() => {
    let mounted = true;
    if (mounted && ended) setProgress(0);
    return () => {
      mounted = false;
    };
  }, [ended, setProgress]);

  useEffect(() => {
    var counter = duration;
    let mounted = true;
    interval.current = window.setInterval(() => {
      counter -= 1;
      if (mounted) {
        setProgress(counter);
      }
    }, 1000);
    return () => {
      mounted = false;
    };
  }, [duration, setProgress]);

  useEffect(() => {
    if (progress === 11 || progress === 6) {
      timerFlash();
    }
    if (progress <= 0) {
      clearInterval(interval.current);
      timesUp();
    } // eslint-disable-next-line
  }, [progress]);

  return (
    <TimeCounter
      tileSize={tileSize}
      textures={textures}
      height={height}
      seconds={Math.max(progress, 0)}
      x={x}
      y={y}
    />
  );
};
