import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import useResizeObserver from "@react-hook/resize-observer";
import React, {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Game } from "../../game/Game";
import { Layout } from "../../shared/Layout";
import { Path } from "../../shared/Path";
import { Round } from "../../shared/Round";
import ReplayIcon from "@mui/icons-material/Replay";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import InboxIcon from "@mui/icons-material/Inbox";
import { Fade, Popper, Typography, useMediaQuery } from "@mui/material";
import { SavedLayout } from "./SavedLayout";
import useClickInside from "../../utils/useClickInside";
import { Cosmetics, defaultCosmetics } from "../../shared/Cosmetics";
import { Beforeunload } from "react-beforeunload";
import PageVisibility from "react-page-visibility";
import { roundHash } from "../../utils/puzzleImageCacheUtils";
import { ScoreSlider } from "./ScoreSlider";
import { theme } from "../../utils/theme";
import { lt } from "../../utils/lt";

export interface InteractiveGameProps {
  round: Round;
  duration?: number;
  submitLayout?: (layout: Layout) => void;
  path?: Path;
  showSkip?: boolean;
  tutorial?: boolean;
  startingLayout?: Layout;
  forcedLayout?: Layout;
  tutorialStartStep?: number;
  cosmetics?: Cosmetics;
  onLayoutChange?: (layout: Layout) => void;
  onBeforeUnload?: () => void;
  onPageVisibitilyChange?: (isVisible: boolean) => string | void;
  resetRound?: (layout: Layout) => void;
  runnerCompleted?: () => void;
  retryReference?: MutableRefObject<() => void>;
  setForcedScoreReference?: MutableRefObject<Dispatch<SetStateAction<number>>>;
  origin?: string[];
  showScoreSlider?: boolean;
  showRetry?: boolean;
  onRetry?: () => void;
  floatingElement?: ReactNode;
  topFloatingElement?: ReactNode;
  maxScore?: number;
  floatingScrollRef?: MutableRefObject<any>;
}

export const InteractiveGame = (props: InteractiveGameProps) => {
  const {
    round,
    path,
    duration = 0,
    startingLayout = { towers: [] },
    forcedLayout,
    tutorial = false,
    showSkip = true,
    tutorialStartStep = 0,
    runnerCompleted = () => {},
    resetRound = (_) => {},
    cosmetics = defaultCosmetics,
    setForcedScoreReference,
    submitLayout = (layout: Layout) => {},
    onLayoutChange = (layout: Layout) => {},
    onBeforeUnload = () => {},
    onPageVisibitilyChange = (_) => {},
    retryReference,
    maxScore,
    origin = [],
    showScoreSlider = false,
    showRetry = false,
    onRetry = () => {},
    floatingElement,
    topFloatingElement,
    floatingScrollRef,
  } = props;

  const target = useRef<any>();
  const [width, setWidth] = useState(1);

  const ratio = (round.board.height + 1.5) / (round.board.width + 1);
  useResizeObserver(target, (entry) => {
    let newWidth = 0;
    if (entry.contentRect.width * ratio < entry.contentRect.height) {
      newWidth = Math.round(entry.contentRect.width);
    } else {
      newWidth = Math.round(
        (entry.contentRect.height * (round.board.width + 1)) /
          (round.board.height + 1.5)
      );
    }
    if (newWidth !== width) {
      setWidth(newWidth);
    }
  });

  const reset = useRef<() => void>(() => {});
  const submit = useRef<() => void>(() => {});
  const setLayout = useRef<(layout: Layout) => void>(() => {});

  const [tutorialStep, setTutorialStep] = useState(tutorialStartStep);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setSubmitted(false);
  }, [round]);

  const currentLayoutReference = useRef<Layout>({ towers: [] });

  const [loadOpen, setLoadOpen] = useState(false);

  const [loadPopper, setLoadPopper] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (!loadPopper) {
      window.setTimeout(() => {
        if (mounted) setLoadPopper(true);
      }, 500);
    }
    return () => {
      mounted = false;
    };
  }, [loadPopper, setLoadPopper]);

  const { ref } = useClickInside(() => {
    setLoadOpen(false);
  });

  const [hasRendered, setHasRendered] = useState(false);

  const containerRef = React.useRef(null);
  /*
  useUpdateChecker({
    onBeforeUnload,
    onPageVisibitilyChange,
    width,
    tutorial,
    tutorialStep,
    onLayoutChange,
    cosmetics,
    round,
    path,
    hasRendered,
    duration,
    startingLayout,
    setHasRendered,
    tutorialStartStep,
    setTutorialStep,
    setLayout,
    runnerCompleted,
    resetRound,
    submitLayout,
    reset,
    submit,
    setLoadOpen,
  }); */

  const cachedLayout = useMemo(() => {
    let lastRoundHash = localStorage.getItem("lastRoundHash");
    let thisRoundHash = roundHash(round, 0);
    if (lastRoundHash) {
      if (thisRoundHash === lastRoundHash) {
        let cachedLayout = localStorage.getItem("cachedLayout");
        if (cachedLayout) {
          return JSON.parse(cachedLayout) as Layout;
        } else {
          return undefined;
        }
      }
    }
    localStorage.setItem("lastRoundHash", thisRoundHash);
    return undefined;
  }, [round]);

  useEffect(
    () => forcedLayout && setLayout.current(forcedLayout),
    [forcedLayout]
  );

  const currentScoreReference = useRef<number>(0);
  const [pause, setPause] = useState(false);
  const [forcedScore, setForcedScore] = useState(0);

  useEffect(() => {
    if (setForcedScoreReference) {
      setForcedScoreReference.current = setForcedScore;
    }
  }, [setForcedScore, setForcedScoreReference]);

  const setInteractiveReference = useRef((v: boolean) => {});

  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const retry = useCallback(() => {
    onRetry();
    setSubmitted(false);
    setInteractiveReference.current(true);
  }, [setSubmitted, onRetry]);

  useEffect(() => {
    if (retryReference) {
      retryReference.current = retry;
    }
  }, [retry, retryReference]);

  return (
    <Beforeunload onBeforeunload={onBeforeUnload}>
      <PageVisibility onChange={onPageVisibitilyChange}>
        <Box height={1} width={1}>
          <Typography
            sx={{
              position: "absolute",
              pl: 2,
              color: "darkgrey",
              top: { xs: "1px", sm: "10px" },
            }}
            variant="body2"
          >
            <b>{origin.join(" › ")}</b>
          </Typography>
          <Grid
            container
            direction="column"
            height={1}
            width={1}
            columns={showScoreSlider ? 13 : 12}
          >
            <Grid item xs={10} sm={11} height={0} width={1}>
              <Grid
                container
                justifyContent="center"
                height={1}
                width={1}
                alignItems="flex-end"
              >
                <Paper
                  sx={{
                    width: width,
                    height: width === 1 ? 1 : width * ratio,
                    borderBottomLeftRadius:
                      tutorial && tutorialStep < 30 ? 20 : 0,
                    borderBottomRightRadius:
                      tutorial && tutorialStep < 30 ? 20 : 0,
                    borderTopRightRadius: width / 78,
                    borderTopLeftRadius: width / 78,
                    backgroundColor: width === 1 ? "#353535" : undefined,
                  }}
                  elevation={width === 1 ? 0 : undefined}
                >
                  <Grid
                    container
                    justifyContent="center"
                    height={1}
                    width={1}
                    alignItems="center"
                    ref={ref}
                  >
                    <Box
                      width={width}
                      height={width === 1 ? 1 : width * ratio}
                      ref={target}
                    >
                      {width !== 1 && (
                        <Game
                          setCurrentLayout={(layout) => {
                            const json = JSON.stringify(layout);
                            onLayoutChange(layout);
                            localStorage.setItem("cachedLayout", json);
                            currentLayoutReference.current = layout;
                          }}
                          preDelete
                          cosmetics={cosmetics}
                          startingWidth={width}
                          round={round}
                          score={showScoreSlider ? forcedScore : undefined}
                          path={path}
                          pause={pause}
                          maxScore={maxScore}
                          setScore={(s) => {
                            currentScoreReference.current = s;
                          }}
                          duration={hasRendered ? duration : 0}
                          startingLayout={
                            startingLayout.towers.length > 0
                              ? startingLayout
                              : cachedLayout
                          }
                          hasLoaded={() => setHasRendered(true)}
                          setInteractiveReference={setInteractiveReference}
                          tutorial={tutorial}
                          tutorialStartStep={tutorialStartStep}
                          setExternalTutorialStep={setTutorialStep}
                          setLayoutReference={setLayout}
                          runnerCompleted={runnerCompleted}
                          resetRound={resetRound}
                          submitLayout={submitLayout}
                          resetReference={reset}
                          submitReference={submit}
                        />
                      )}
                    </Box>
                  </Grid>
                  {floatingElement &&
                    isMd &&
                    lt(target.current as HTMLElement, (htmlElement) => (
                      <Box
                        position="absolute"
                        left={
                          htmlElement.offsetWidth + htmlElement.offsetLeft + 8
                        }
                        top={htmlElement.offsetTop}
                        height={width * ratio + 36}
                        sx={{ borderRadius: 1.5 }}
                        width={width / 2}
                        minWidth={Math.min(
                          (window.innerWidth - width) / 2 - 16,
                          400
                        )}
                        pb={1}
                      >
                        <Box>
                          {topFloatingElement}
                        </Box>
                        <Box
                          overflow="auto"
                          sx={{ scrollbarGutter: "stable" }}
                          ref={floatingScrollRef}
                          height={1}
                          width={1}
                        >
                          {floatingElement}
                        </Box>
                      </Box>
                    ))}
                </Paper>
              </Grid>
            </Grid>
            <Grid
              item
              xs={showScoreSlider ? 2.5 : 1.5}
              sm={showScoreSlider ? 2 : 1}
            >
              <Grid
                container
                justifyContent="center"
                height={1}
                width={1}
                alignItems="flex-start"
              >
                <Paper
                  sx={{
                    display: width === 1 ? "none" : undefined,
                    width: width,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: width / 78,
                    borderBottomRightRadius: width / 78,
                  }}
                >
                  {path && (
                    <Box width={1} px={2} py={0.5}>
                      <ScoreSlider
                        scoreReference={currentScoreReference}
                        completed={false}
                        max={path.result}
                        setScore={(s) => {
                          setForcedScore(s);
                          currentScoreReference.current = s;
                        }}
                        pause={() => setPause(true)}
                        unPause={() => setPause(false)}
                        path={path}
                        ballCosmetics={cosmetics.ball}
                      />
                    </Box>
                  )}
                  <Grid container justifyContent="space-between">
                    <Button
                      onClick={() => {
                        reset.current();
                        setLoadOpen(false);
                      }}
                      startIcon={<ReplayIcon />}
                      sx={{
                        display: path || tutorial ? "none" : undefined,
                        borderBottomLeftRadius: width / 78,
                      }}
                    >
                      Reset
                    </Button>
                    {loadPopper && (
                      <Popper
                        open={true}
                        anchorEl={containerRef.current}
                        style={{ display: loadOpen ? undefined : "none" }}
                        placement="top"
                      >
                        <Fade in={loadOpen}>
                          <Paper elevation={4} sx={{ pt: 0.5 }}>
                            <Box maxWidth={(2 * 8 + round.board.width * 4) * 3}>
                              <Grid container>
                                {Array(2)
                                  .fill(undefined)
                                  .map((_, i) => (
                                    <Grid item key={"save" + i}>
                                      <SavedLayout
                                        load={(layout) =>
                                          setLayout.current({
                                            towers: [...layout.towers],
                                          })
                                        }
                                        currentLayoutReference={
                                          currentLayoutReference
                                        }
                                        board={round.board}
                                      />
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          </Paper>
                        </Fade>
                      </Popper>
                    )}
                    <Button
                      onClick={() => {
                        setLoadOpen(!loadOpen);
                      }}
                      endIcon={<InboxIcon />}
                      ref={containerRef}
                      aria-describedby={"loadPopper"}
                      sx={{ display: path || tutorial ? "none" : undefined }}
                    >
                      Saves
                    </Button>
                    <Box
                      sx={{ display: path || tutorial ? undefined : "none" }}
                    />
                    <Button
                      sx={{
                        display:
                          (path || tutorial) &&
                          !(
                            tutorialStep === 30 ||
                            tutorialStep === 31 ||
                            (tutorialStep === 32 && !path)
                          )
                            ? "none"
                            : undefined,
                        borderBottomRightRadius: width / 78,
                      }}
                      onClick={() => {
                        if (tutorialStep !== 30) {
                          submit.current();
                          setSubmitted(true);
                        }
                      }}
                      disabled={tutorialStep === 30 || submitted}
                      endIcon={<PlayArrowIcon />}
                    >
                      Submit
                    </Button>
                    <Box
                      sx={{
                        display:
                          path && (showSkip || showRetry) ? undefined : "none",
                      }}
                    />
                    <Button
                      sx={{
                        display:
                          path && (showSkip || showRetry) ? undefined : "none",
                        borderBottomRightRadius: width / 78,
                      }}
                      onClick={
                        showRetry
                          ? () => {
                              onRetry();
                              setSubmitted(false);
                              setInteractiveReference.current(true);
                            }
                          : runnerCompleted
                      }
                      endIcon={showRetry ? <ReplayIcon /> : <SkipNextIcon />}
                    >
                      {showRetry ? "Retry" : "Skip"}
                    </Button>
                  </Grid>
                </Paper>
                {floatingElement && !isMd && (
                  <Box 
                    width={width}
                      mt={path ? 9.5 : 4.5}
                      position="absolute"
                  
                  >
                    {topFloatingElement && <Box width={1}>{topFloatingElement}</Box>}
                    <Box
                      width={width}
                      maxHeight={width}
                      overflow="auto"
                      ref={floatingScrollRef}
                    >
                      {floatingElement}
                      <Box height={width - 68}/>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PageVisibility>
    </Beforeunload>
  );
};
