import { Sprite, Stage } from "@inlet/react-pixi";
import { Button } from "@mui/material";
import { Application } from "@pixi/app";
import { Renderer, Texture } from "@pixi/core";
import { useEffect, useState } from "react";
import { patterns } from "../game/utils/usePattern";
import { Pattern } from "../shared/Cosmetics";
import { cst } from "../utils/constants";
import { BodyContent } from "./elements/BodyContent";

export const PatternTest = () => {
  const [pattern, setPattern] = useState<Pattern>();
  const [texture, setTexture] = useState<Texture>();
  const [app, setApp] = useState<Application>();
  useEffect(() => {
    if (app && pattern) {
      let sprite = patterns[pattern](
        cst.tileSize,
        16,
        18,
        app.renderer as Renderer
      );
      setTexture(sprite.texture);
    }
  }, [app, setTexture, pattern]);
  return (
    <BodyContent>
      {Object.keys(patterns).map((it) => (
        <Button onClick={() => setPattern(it as Pattern)}>{it}</Button>
      ))}
      {pattern && (
        <Stage onMount={setApp} width={cst.tileSize*16} height={cst.tileSize*18}>
          {texture && <Sprite texture={texture} />}
        </Stage>
      )}
    </BodyContent>
  );
};
