import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Grid, Paper, Popper, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { theme } from "../../utils/theme";
import { ReplayElement } from "./ReplayElement";
import Emoji from "./Emoji";
import { CosmeticRoundResult } from "../../shared/Cosmetics";
import { useClickOutsides } from "../../utils/useClickOutside";
import { ScoreSlider } from "./ScoreSlider";
import { getRoundResultOutcome } from "../../shared/Arena";

interface ArenaRoundBadgeProps {
  roundResult: CosmeticRoundResult;
  index: number;
  activeIndex: number | undefined;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  freeServe: boolean;
}

export const AreanRoundBadge = (props: ArenaRoundBadgeProps) => {
  const { roundResult, activeIndex, setActiveIndex, index, freeServe } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const buttonClass = "buttonClass" + index;

  const [pause, setPause] = useState(false);

  const { refA, refB } = useClickOutsides((event: any) => {
    if (!pause) {
      setActiveIndex((i) => (i === index ? undefined : i));
    }
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setActiveIndex((currentIndex) =>
      currentIndex === index ? undefined : index
    );
  };

  const open = activeIndex === index;
  const popperId = open ? "popper" + index : undefined;

  const width = Math.min(theme.breakpoints.values.md, window.innerWidth);

  const [readyOne, setReadyOne] = useState(false);
  const [readyTwo, setReadyTwo] = useState(false);

  const [start, setStart] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (readyOne && (readyTwo || freeServe)) {
      window.setTimeout(() => mounted && setStart(true), 500);
    }
    return () => {
      mounted = false;
    };
  }, [readyOne, readyTwo, freeServe, setStart]);

  const currentScoreReference = useRef(0);

  const [forcedScore, setForcedScore] = useState(0);

  const maxScore = useMemo(
    () =>
      Math.max(
        roundResult.playerSolution.solution.path.result,
        roundResult.opponentSolution?.solution.path.result || 0
      ),
    [roundResult]
  );

  const outcome = useMemo(
    () => getRoundResultOutcome(roundResult),
    [roundResult]
  );

  return (
    <Fragment key={"fragment" + index}>
      <div style={{ display: "inline" }} ref={refB} key={"divvvo" + index}>
        {(roundResult && (
          <Chip
            key={"chip" + index}
            label={
              <Fragment key={"icon" + index}>
                {outcome === "WIN" && (
                  <EmojiEventsIcon
                    key={"events" + index}
                    className={buttonClass}
                    sx={{ m: -1, color: "#ffcc04" }}
                  />
                )}
                {outcome === "LOSS" && (
                  <CloseIcon
                    key={"close" + index}
                    className={buttonClass}
                    sx={{ m: -1, color: "black" }}
                  />
                )}
                {outcome === "TIE" && (
                  <RemoveIcon
                    key={"remove" + index}
                    className={buttonClass}
                    sx={{ m: -1 }}
                  />
                )}
              </Fragment>
            }
            sx={{ ml: -1, border: 1, borderColor: open ? "white" : "black" }}
            onClick={handleClick}
            aria-describedby={popperId}
            color={
              outcome === "WIN" || freeServe
                ? "success"
                : outcome === "LOSS"
                ? "error"
                : "primary"
            }
          />
        )) || <Chip variant="outlined" disabled key={"chippo" + index} />}
        <Popper
          id={popperId}
          open={open}
          anchorEl={anchorEl}
          key={"popper" + index}
        >
          <Box
            width={freeServe ? width / 2 : width}
            ref={refA}
            key={"box" + index}
          >
            <Grid container justifyContent="center" alignItems="flex-start">
              {freeServe && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Typography variant="h5">
                      <Emoji symbol="🎊" /> Free <b>Serve</b>!{" "}
                      <Emoji symbol="🎊" />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={freeServe ? 12 : 6} zIndex={1}>
                <ReplayElement
                  startingWidth={width / 2}
                  isPlayer
                  playerSolution={roundResult.playerSolution}
                  completed
                  maxScore={maxScore}
                  ready={() => setReadyOne(true)}
                  start={start}
                  forcedScore={forcedScore}
                  pause={pause}
                  setScore={(s) => {
                    currentScoreReference.current = s;
                  }}
                  offsetIcons
                  round={roundResult.round}
                  place={outcome === "LOSS" ? 2 : 1}
                />
              </Grid>
              {!freeServe && (
                <Grid item xs={6} zIndex={1}>
                  <ReplayElement
                    startingWidth={width / 2}
                    playerSolution={roundResult.opponentSolution}
                    completed
                    offsetIcons
                    ready={() => setReadyTwo(true)}
                    start={start}
                    pause={pause}
                    forcedScore={forcedScore}
                    round={roundResult.round}
                    place={outcome === "LOSS" ? 1 : 2}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Paper
                  elevation={11}
                  sx={{
                    pt: 0.5,
                    pb: 0.75,
                    mx: 0.5,
                    mt: 0,
                    px: 2,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    zIndex: 0,
                  }}
                >
                  <ScoreSlider
                    max={maxScore}
                    ballCosmetics={roundResult.playerSolution.cosmetics.ball}
                    scoreReference={currentScoreReference}
                    completed={false}
                    pause={() => setPause(true)}
                    unPause={() => setPause(false)}
                    setScore={setForcedScore}
                    path={roundResult.playerSolution.solution.path}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Popper>
      </div>
    </Fragment>
  );
};
