import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { getChallengeService } from "../network/ChallengeService";
import {
  ChallengeSnapshot,
  isChallengeSnapshot,
  isPeriodicalChallenge,
} from "../shared/Challenge";
import { ChallengeSnapshotElement } from "./elements/ChallengeSnapshotElement";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Layout } from "../shared/Layout";
import { isRound, Round } from "../shared/Round";
import { Player } from "../shared/Player";
import { BodyContent } from "./elements/BodyContent";
import { usePlayerOrGuest } from "../utils/usePlayerOrGuest";
import { useSecondsPassed } from "../utils/useSecondsPassed";
import { timeAgo } from "../utils/timeAgo";
import { secondsToHHMMSS } from "../utils/secToHHMMSS";
import { PlayerProfileLink } from "./elements/PlayerProfileLink";
import {
  MultiplayerRoundElement,
  MultiplayerRoundElementProps,
} from "./elements/MultiplayerRoundElement";
import { InteractiveGame } from "./elements/InteractiveGame";
import { MazePaper } from "./elements/MazePaper";
import {
  CosmeticPlayerSolution,
  Cosmetics,
  isCosmeticPlayerSolutions,
} from "../shared/Cosmetics";
import PageVisibility from "react-page-visibility";
import { TipElement } from "./elements/TipElement";
import { boardSizeLabel } from "../shared/Board";

interface ChallengeProps {
  player: Player | undefined | null;
  cosmetics: Cosmetics | undefined;
  periodical?: boolean;
  past?: boolean;
}

const durationUnderDeadline = (
  roundDuration: number,
  challengeDeadline: Date | undefined,
  roundDeadline: Date | undefined
) => {
  let now = Date.now();
  let challengeTimeLeft =
    challengeDeadline !== undefined
      ? Math.floor((challengeDeadline.getTime() - now) / 1000)
      : undefined;
  let roundTimeLeft = roundDeadline
    ? Math.floor((roundDeadline.getTime() - now) / 1000)
    : roundDuration;
  return challengeTimeLeft
    ? roundDuration === 0
      ? challengeTimeLeft
      : Math.min(roundTimeLeft, challengeTimeLeft)
    : roundTimeLeft;
};

export const Challenge = (props: ChallengeProps) => {
  const { player, cosmetics, periodical = false, past = false } = props;

  const params = useParams();

  const [challengeId, setChallengeId] = useState(
    periodical && !past
      ? undefined
      : params === undefined || params.id === undefined
      ? -1
      : +params.id
  );
  const [periodicalChallengeId] = useState(
    !periodical || past
      ? undefined
      : params === undefined || params.id === undefined
      ? -1
      : +params.id
  );

  const [snapshot, setSnapshot] = useState<undefined | ChallengeSnapshot>();

  const [title, setTitle] = useState("");

  const playerOrGuest = usePlayerOrGuest(player);

  const [pastDeadline, setPastDeadline] = useState(false);

  const navigate = useNavigate();

  const [challengeDeadline, setChallengeDeadline] = useState<
    Date | undefined
  >();
  const [roundDeadline, setRoundDeadline] = useState<Date | undefined>();

  const timeLeft = useMemo(
    () => (challengeDeadline ? challengeDeadline.getTime() - Date.now() : 0),
    [challengeDeadline]
  );

  const refreshPeriodical = useCallback(() => {
    if (periodicalChallengeId !== undefined) {
      getChallengeService()
        .getPeriodical(periodicalChallengeId)
        .then((response) => {
          if (isPeriodicalChallenge(response)) {
            setChallengeId(response.parameters.id);
          }
        });
    }
  }, [periodicalChallengeId, setChallengeId]);

  useEffect(() => {
    refreshPeriodical();
  }, [refreshPeriodical]);

  const { secondsPassed, resetSecondsPassed, pause, unPause } =
    useSecondsPassed();

  const refreshSnapshot = useCallback(() => {
    if (challengeId) {
      getChallengeService()
        .get(challengeId)
        .then((response) => {
          if (isChallengeSnapshot(response)) {
            resetSecondsPassed();
            setSnapshot(response);
            setTitle(periodical ? "Daily Challenge" : "Challenge");
            let deadline = response.parameters.settings.deadline;
            if (deadline && !isNaN(deadline)) {
              if (deadline < 0) {
                setPastDeadline(true);
              }
              setChallengeDeadline(new Date(Date.now() + deadline));
            }
          } else {
            console.error(response);
          }
        });
    }
  }, [
    challengeId,
    setPastDeadline,
    setTitle,
    setSnapshot,
    resetSecondsPassed,
    periodical,
  ]);

  useEffect(refreshSnapshot, [refreshSnapshot]);

  useEffect(() => {
    if (
      snapshot?.parameters.settings.deadline &&
      !pastDeadline &&
      snapshot.parameters.settings.deadline < secondsPassed * 1000
    ) {
      setPastDeadline(true);
    }
  }, [secondsPassed, setPastDeadline, pastDeadline, snapshot]);

  const [allSolutions, setAllSolutions] = useState<
    CosmeticPlayerSolution[] | undefined
  >();

  const [playerSolution, setPlayerSolution] = useState<
    undefined | CosmeticPlayerSolution
  >();

  const submitLayout = useCallback(
    (layout: Layout) => {
      playerOrGuest &&
        challengeId &&
        getChallengeService()
          .submit(challengeId, layout)
          .then((response) => {
            if (isCosmeticPlayerSolutions(response)) {
              setPlayerSolution(
                response.find((it) => it.player.id === playerOrGuest.id)
              );
              setAllSolutions(response);
            } else {
              console.error(response);
            }
          });
    },
    [playerOrGuest, challengeId, setPlayerSolution]
  );

  const [round, setRound] = useState<Round | undefined>();

  const [roundReplay, setRoundReplay] = useState<
    MultiplayerRoundElementProps | undefined
  >();

  useEffect(() => {
    if (playerSolution || roundReplay) {
      pause();
    } else {
      unPause();
    }
  }, [playerSolution, roundReplay, pause, unPause]);

  const [duration, setDuration] = useState(0);

  const playRound = useCallback(() => {
    playerOrGuest &&
      snapshot &&
      challengeId &&
      getChallengeService()
        .next(challengeId)
        .then((response) => {
          if (isRound(response)) {
            setRound(response);
            setSnapshot((s) =>
              s ? { ...s, rounds: [...s.rounds, response] } : undefined
            );
            let roundDuration = snapshot.parameters.settings.roundDuration;
            if (snapshot.roundDeadline && !isNaN(snapshot.roundDeadline)) {
              setSnapshot((s) => (s ? { ...s, roundDeadline: undefined } : s));
              roundDuration =
                Math.floor(snapshot.roundDeadline / 1000) - secondsPassed;
              setDuration(roundDuration);
              setRoundDeadline(new Date(Date.now() + roundDuration * 1000));
            } else {
              let currentRoundDeadline =
                roundDuration > 0
                  ? new Date(Date.now() + roundDuration * 1000)
                  : undefined;
              let dur = durationUnderDeadline(
                roundDuration,
                challengeDeadline,
                currentRoundDeadline
              );
              if (dur < 180) {
                setDuration(dur);
              }
              if (roundDuration > 0) {
                setRoundDeadline(currentRoundDeadline);
              }
            }
          } else {
            console.error(response);
          }
        });
  }, [
    playerOrGuest,
    challengeId,
    setRound,
    snapshot,
    secondsPassed,
    challengeDeadline,
  ]);

  useEffect(() => {
    if (
      snapshot &&
      snapshot.parameters.settings.roundDuration === 0 &&
      round &&
      duration === 0 &&
      challengeDeadline &&
      secondsPassed >= 0
    ) {
      let currentDuration = durationUnderDeadline(
        0,
        challengeDeadline,
        undefined
      );
      if (currentDuration <= 180) {
        setDuration(currentDuration);
      }
    }
  }, [
    challengeDeadline,
    duration,
    round,
    setDuration,
    snapshot,
    secondsPassed,
  ]);

  useEffect(() => {
    if (
      snapshot &&
      snapshot.roundDeadline &&
      snapshot.roundDeadline - secondsPassed * 1000 < 0
    ) {
      refreshSnapshot();
    }
  }, [secondsPassed, snapshot, refreshSnapshot]);

  const runnerCompleted = useCallback(() => {
    playerOrGuest &&
      challengeId &&
      getChallengeService()
        .get(challengeId)
        .then((response) => {
          if (isChallengeSnapshot(response)) {
            setSnapshot(response);
            setRound(undefined);
            setPlayerSolution(undefined);
            resetSecondsPassed();
          } else {
            console.error(response);
          }
        });
  }, [
    playerOrGuest,
    challengeId,
    setSnapshot,
    setRound,
    setPlayerSolution,
    resetSecondsPassed,
  ]);

  const [tentativeLayout, setTentativeLayout] = useState<Layout>({
    towers: [],
  });

  return (
    <Fragment>
      {roundReplay && (
        <MultiplayerRoundElement
          round={roundReplay.round}
          playerSolution={roundReplay.playerSolution}
          allSolutions={roundReplay.allSolutions}
          runnerCompleted={() => setRoundReplay(undefined)}
        />
      )}
      {playerOrGuest && snapshot && roundReplay === undefined && (
        <Fragment>
          {round &&
            (playerSolution ? (
              <MultiplayerRoundElement
                round={round}
                playerSolution={playerSolution}
                allSolutions={allSolutions}
                runnerCompleted={runnerCompleted}
              />
            ) : (
              <BodyContent>
                <InteractiveGame
                  round={round}
                  duration={duration}
                  onLayoutChange={setTentativeLayout}
                  onPageVisibitilyChange={(isVisible) => {
                    if (isVisible) {
                      let currentDuration = durationUnderDeadline(
                        snapshot.parameters.settings.roundDuration,
                        challengeDeadline,
                        roundDeadline
                      );
                      if (currentDuration >= 0) {
                        setDuration(currentDuration);
                      } else {
                        submitLayout(tentativeLayout);
                      }
                    } else if (challengeId) {
                      getChallengeService().tentative(
                        challengeId,
                        tentativeLayout
                      );
                    }
                  }}
                  onBeforeUnload={() => {
                    if (challengeId) {
                      getChallengeService().tentative(
                        challengeId,
                        tentativeLayout
                      );
                    }
                  }}
                  submitLayout={submitLayout}
                  cosmetics={cosmetics}
                  origin={[
                    periodical
                      ? "Daily Challenge"
                      : snapshot.host?.name + "'s Challenge",
                    "Round " + snapshot.rounds.length,
                  ]}
                />
              </BodyContent>
            ))}
          {!round && (
            <Box maxWidth="sm" mx="auto" sx={{ pt: 2 }}>
              <PageVisibility
                onChange={(isVisible) => {
                  if (isVisible) refreshSnapshot();
                }}
              >
                <MazePaper
                  title={
                    <Typography component="h1" variant="h5">
                      <b>
                        {title}
                        {snapshot.host && " by "}
                        {snapshot.host && (
                          <PlayerProfileLink playerName={snapshot.host.name} />
                        )}
                      </b>
                    </Typography>
                  }
                  goBack={() => navigate(-1)}
                >
                  <TipElement localStorageKey="normalizedDaily">
                    In <b>Challenges</b> all scores are scaled to the best score
                    of the round so all rounds have an equal impact on the final
                    result.
                  </TipElement>
                  <React.Fragment>
                    {snapshot.parameters.settings.deadline && (
                      <Typography>
                        <b>
                          {pastDeadline
                            ? "Ended " + timeAgo(-timeLeft, true) + " ago"
                            : "Time Left — " +
                              secondsToHHMMSS(timeLeft / 1000 - secondsPassed)}
                        </b>
                      </Typography>
                    )}
                    <Typography sx={{ pt: 2 }}>
                      {boardSizeLabel[snapshot.parameters.settings.size] + " × "}
                      {snapshot.parameters.settings.roundDuration > 0
                        ? snapshot.parameters.settings.roundDuration +
                          " second rounds"
                        : "No time limit"}
                      {snapshot.parameters.settings.waypoints && " × Waypoints"}
                    </Typography>
                    <Box
                      sx={{
                        marginTop: 2,
                      }}
                      mx="auto"
                      maxWidth="sm"
                      width="100%"
                    >
                      <ChallengeSnapshotElement
                        setReplay={setRoundReplay}
                        hasEnded={pastDeadline}
                        snapshot={snapshot}
                        player={playerOrGuest}
                        playRound={playRound}
                        normalizeScores
                      />
                    </Box>
                  </React.Fragment>
                </MazePaper>
              </PageVisibility>
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
