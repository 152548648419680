import React, { Fragment } from "react";
import { secondsToHHMMSS } from "../../utils/secToHHMMSS";
import { useSecondsPassed } from "../../utils/useSecondsPassed";

interface CountdownSecondsProps {
  seconds: number
}

export const CountdownSeconds= (props: CountdownSecondsProps) => {
  const {seconds }= props
  const {secondsPassed} = useSecondsPassed()
  return <Fragment>{secondsToHHMMSS(seconds - secondsPassed)}</Fragment>

}