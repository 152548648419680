import {
  Divider,
  List,
  ListItem,
  ListSubheader,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AcademyCategory,
  academyCategoryLabel,
  AcademyRound,
  AcademyRoundCutoffs,
  AcademyRoundResult,
  CosmeticPlayerPath,
  isAcademyRoundResult,
  medalColor,
} from "../../shared/Academy";
import { BoardSize, boardSizeLabel } from "../../shared/Board";
import { Cosmetics } from "../../shared/Cosmetics";
import { Layout } from "../../shared/Layout";
import { Path } from "../../shared/Path";
import { cst } from "../../utils/constants";
import { InteractiveGame } from "./InteractiveGame";
import Typography from "@mui/material/Typography";
import Emoji from "./Emoji";
import { rankedBackgroundColor } from "./ChallengeSnapshotElement";
import { useCheckAchievements } from "../../utils/useCheckAchievements";
import { AcademyResult } from "./AcademyResult";
import { academyService } from "../../network/AcademyService";

interface AcademyRoundElementProps {
  academyRound: AcademyRound;
  category: AcademyCategory;
  boardSize: BoardSize;
  level: number;
  playerCosmetics: Cosmetics;
  submit: (layout: Layout) => Promise<AcademyRoundResult | string>;
  getPath: (
    result: number,
    version: number
  ) => Promise<CosmeticPlayerPath | string>;
  resultCardElement?: ReactNode;
  refreshProgress: () => void;
}

interface MedalDividerProps {
  cutOff: number;
  medal: ReactNode;
  lineColor: string;
  bestResult: number;
}

const MedalDivider = (props: MedalDividerProps) => (
  <ListSubheader sx={{ p: 0, m: 0, lineHeight: 0, bgcolor: "#353535", boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.1)" }}>
    <Divider
      sx={{
        pb: 0.5,
        mx: 1,
        "&.MuiDivider-root": {
          "&::before": {
            borderTop: `2px solid ` + props.lineColor,
          },
          "&::after": {
            borderTop: `2px solid ` + props.lineColor,
          },
        },
      }}
    >
      {props.medal}
      <Typography
        display="inline"
        sx={{
          px: 1,
          color: props.bestResult >= props.cutOff ? "white" : undefined,
        }}
      >
        <b>{props.cutOff}</b>
      </Typography>
      {props.medal}
    </Divider>
  </ListSubheader>
);

export const dividerColor = (cutOffs: AcademyRoundCutoffs, result: number) =>
  result === cutOffs.platinum
    ? medalColor.PLATINUM
    : result >= cutOffs.gold
    ? medalColor.GOLD
    : result >= cutOffs.silver
    ? medalColor.SILVER
    : result >= cutOffs.bronze
    ? medalColor.BRONZE
    : "#0000000";

export const AcademyRoundElement = (props: AcademyRoundElementProps) => {
  const {
    academyRound,
    category,
    boardSize,
    level,
    playerCosmetics,
    submit,
    refreshProgress,
    resultCardElement,
  } = props;
  const gameWidth = useMemo(
    () => Math.min(window.innerWidth, window.innerHeight * cst.ratio - 133),
    []
  );

  const [cosmetics, setCosmetics] = useState(playerCosmetics);
  const [path, setPath] = useState<Path>();
  const [startingLayout, setStartingLayout] = useState<Layout>();
  const [otherResults, setOtherResults] = useState<number[]>();
  const [cutOffs, setCutOffs] = useState<AcademyRoundCutoffs>();
  const [integerResult, setIntegerResult] = useState<number>();
  const [academyRoundResult, setAcademyRoundResult] =
    useState<AcademyRoundResult>();

  const retryReference = useRef<() => void>(() => {});

  const setForcedScoreReference = useRef<
    React.Dispatch<React.SetStateAction<number>>
  >(() => {});

  const [accordions, setAccordions] = useState<number[]>([-1]);
  const [completed, setCompleted] = useState(false);
  const [bestPlayerResult, setBestPlayerResult] = useState(0);

  const scrollRef = useRef<any>();

  useEffect(() => {
    if (scrollRef.current && cutOffs && otherResults) {
      const cutoffCount =
          cutOffs.silver <= bestPlayerResult
          ? 0
          : cutOffs.bronze <= bestPlayerResult
          ? 1
          : 2;
      var betterResults =
        bestPlayerResult === 0
          ? otherResults.length - 1
          : otherResults.indexOf(bestPlayerResult);
      if (betterResults > 0) {
        betterResults = betterResults - 0.5
      }
      scrollRef.current.scrollTo(0,cutoffCount * 28 + betterResults * 38);
    }
  }, [otherResults, bestPlayerResult, cutOffs]);

  useEffect(() => {
    setCompleted(false);
    if (academyRound.solution) {
      setStartingLayout(academyRound.solution.layout);
      setBestPlayerResult(academyRound.solution.path.integerResult);
    } else {
      setStartingLayout({ towers: [] });
    }
    setPath(undefined);
    setIntegerResult(
      academyRound.integerResult ? academyRound.integerResult : undefined
    );
    setCutOffs(academyRound.cutOffs);
    setOtherResults(academyRound.otherResults);
    retryReference.current();
  }, [academyRound]);

  useEffect(
    () => setAccordions((s) => (s.length === 0 ? [-1] : s)),
    [accordions]
  );

  useEffect(() => {
    setAccordions([]);
  }, [level]);

  const checkAchievements = useCheckAchievements();

  useEffect(() => {
    if (academyRoundResult) {
      setCompleted(false);
      checkAchievements();
      if (academyRoundResult.unlockedMedal) {
        refreshProgress();
      }
      setStartingLayout(
        academyRoundResult.roundResult.playerSolution.solution.layout
      );
      setPath(academyRoundResult.roundResult.playerSolution.solution.path);
      setIntegerResult(academyRoundResult.integerResult);
      setCutOffs(academyRoundResult.cutOffs);
      setOtherResults(academyRoundResult.otherResults);
    } // eslint-disable-next-line
  }, [academyRoundResult]);

  useEffect(() => {
    if (path) {
      setForcedScoreReference.current(Math.random() / 100);
    }
  }, [path]);

  return (
    <Grid container width={1}>
      <Box
        height={{ xs: gameWidth * cst.ratio * 1.33, md: gameWidth / cst.ratio }}
        mt={{ xs: 0, md: 1.5 }}
        width={1}
        key={"academyRoundElement"}
      >
        <InteractiveGame
          origin={[
            "Academy",
            academyCategoryLabel[category],
            boardSizeLabel[boardSize],
            "" + (level + 1),
          ]}
          round={academyRound.round}
          startingLayout={startingLayout}
          setForcedScoreReference={setForcedScoreReference}
          retryReference={retryReference}
          forcedLayout={startingLayout}
          runnerCompleted={() => {
            setCompleted(true);
            if (path) setForcedScoreReference.current(path.result);
          }}
          cosmetics={cosmetics}
          submitLayout={(layout) =>
            submit(layout).then((response) => {
              if (isAcademyRoundResult(response)) {
                setAcademyRoundResult(response);
                setStartingLayout(layout);
                setBestPlayerResult((currentBest) =>
                  response.integerResult > currentBest
                    ? response.integerResult
                    : currentBest
                );
                setPath(response.roundResult.playerSolution.solution.path);
              }
            })
          }
          maxScore={integerResult ? integerResult / 1000 : undefined}
          path={path}
          showSkip
          showRetry={completed}
          floatingScrollRef={scrollRef}
          topFloatingElement={
            resultCardElement !== undefined && resultCardElement
          }
          floatingElement={
            otherResults &&
            cutOffs && (
              <Box
                key={
                  academyCategoryLabel[category] +
                  boardSizeLabel[boardSize] +
                  "" +
                  (level + 1)
                }
              >
                <List sx={{ width: 1, height: 1, m: 0, p: 0 }}>
                  {otherResults.map((result, index) => (
                    <Fragment key={index}>
                      {index > 0 &&
                        otherResults[index - 1] >= cutOffs.gold &&
                        result < cutOffs.gold && (
                          <MedalDivider
                            cutOff={cutOffs.gold}
                            medal={<Emoji symbol="🥇" />}
                            bestResult={bestPlayerResult}
                            lineColor={rankedBackgroundColor(0, 4)}
                          />
                        )}
                      {index > 0 &&
                        otherResults[index - 1] >= cutOffs.silver &&
                        result < cutOffs.silver && (
                          <MedalDivider
                            cutOff={cutOffs.silver}
                            medal={<Emoji symbol="🥈" />}
                            bestResult={bestPlayerResult}
                            lineColor={rankedBackgroundColor(1, 4)}
                          />
                        )}
                      {index > 0 &&
                        otherResults[index - 1] >= cutOffs.bronze &&
                        result < cutOffs.bronze && (
                          <MedalDivider
                            cutOff={cutOffs.bronze}
                            medal={<Emoji symbol="🥉" />}
                            bestResult={bestPlayerResult}
                            lineColor={rankedBackgroundColor(2, 4)}
                          />
                        )}
                      <ListItem sx={{ width: 1, p: 0, m: 0 }}>
                        <AcademyResult
                          integerResult={result}
                          disabled={result > bestPlayerResult}
                          accentColour={dividerColor(cutOffs, result)}
                          getLayouts={() =>
                            academyService.layouts(
                              category,
                              boardSize,
                              level,
                              result
                            )
                          }
                          setSolution={(cosmeticPlayerSolution) => {
                            setStartingLayout(
                              cosmeticPlayerSolution.solution.layout
                            );
                            if (path) {
                              setIntegerResult(
                                cosmeticPlayerSolution.solution.path
                                  .integerResult
                              );
                              setPath(cosmeticPlayerSolution.solution.path);
                              setCosmetics(cosmeticPlayerSolution.cosmetics);
                              setCompleted(false);
                            }
                          }}
                          academyRound={academyRound}
                        />
                      </ListItem>
                    </Fragment>
                  ))}
                </List>
              </Box>
            )
          }
          onRetry={() => {
            setPath(undefined);
            setCosmetics(playerCosmetics);
          }}
          showScoreSlider
        />
      </Box>
    </Grid>
  );
};
