import React from "react";
import Avatar from "@mui/material/Avatar";
import { CosmeticTool, toolInfo } from "../../shared/Crafting";
import { cst } from "../../utils/constants";
import { ToolName } from "./ToolName";
import { Grid } from "@mui/material";

interface ToolElementProps {
  tool: CosmeticTool;
}

export const ToolElement = (props: ToolElementProps) => {
  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Avatar
        variant="rounded"
        sx={{
          width: cst.tileSize,
          height: cst.tileSize,
        }}
      >
        {toolInfo[props.tool].icon({ color: "action", fontSize: "large" })}
      </Avatar>
      <ToolName tool={props.tool} />
    </Grid>
  );
};
