import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import React, { Fragment, useCallback, useState } from "react";
import { RoundResults } from "../../shared/Puzzle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FixedSizeList } from "react-window";
import { RoundResultsElement } from "./RoundResultsElement";
import {
  CosmeticPlayerSolution,
  isCosmeticPlayerSolution,
} from "../../shared/Cosmetics";
import { VirtualElement } from "@popperjs/core";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

const noBorders = {
  "&:before": {
    display: "none",
  },
  boxShadow: "none",
};

const roundedBottoms = {
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
};
export type AnchoredPlayerSolution = {
  playerSolution: CosmeticPlayerSolution;
  anchorEl?: VirtualElement | (() => VirtualElement) | null | undefined;
};
export type ReplayToggle = {
  index: number;
  playerId: number;
};
const roundedTops = { borderTopRightRadius: 4, borderTopLeftRadius: 4 };
interface RoundResultsListElementProps {
  playerId: number;
  results: RoundResults[];
  roundHeader?: boolean;
  normalizeScores?: boolean;
  highlightPlayedRounds?: boolean;
  replay: (id: number) => void;
  getPlayerSolution: (
    id: number,
    playerId: number
  ) => Promise<string | CosmeticPlayerSolution>;
}

export const RoundResultsListElement = (
  props: RoundResultsListElementProps
) => {
  const {
    results,
    replay,
    highlightPlayedRounds = false,
    playerId,
    getPlayerSolution,
    normalizeScores = false,
    roundHeader = false,
  } = props;

  const [replays, setReplays] = useState<ReplayToggle[]>([]);
  const [disableReplayButton, setDisableReplayButton] = useState(false);

  const showReplay = useCallback(
    (index: number, playerId: number) =>
      replays.some(
        (toggle) => toggle.playerId === playerId && toggle.index === index
      ),
    [replays]
  );

  const closeReplay = useCallback(
    (index: number, playerId: number) => {
      setReplays((rs) =>
        rs.filter((it) => it.playerId !== playerId && it.index !== index)
      );
    },
    [setReplays]
  );

  const [storedPlayerSolutions, setStoredPlayerSolutions] = useState<{
    [key in number]:
      | { [key in number]: AnchoredPlayerSolution | undefined }
      | undefined;
  }>({});

  const [fetching, setFetching] = useState<ReplayToggle[]>([]);

  const isFetching = useCallback(
    (index: number, playerId: number) =>
      fetching.some((f) => f.index === index && f.playerId === playerId),
    [fetching]
  );

  const fetchReplay = useCallback(
    (
      index: number,
      playerId: number,
      anchorEl: EventTarget & HTMLButtonElement
    ) => {
      let roundSolutions = storedPlayerSolutions[index];
      if (
        roundSolutions &&
        roundSolutions[playerId] &&
        !showReplay(playerId, index)
      ) {
        let anchoredSolution = roundSolutions[playerId]!!;
        roundSolutions[playerId] = { ...anchoredSolution, anchorEl };
        setStoredPlayerSolutions((sps) => {
          sps[index] = roundSolutions;
          return { ...sps };
        });
        setReplays((rs) => [
          { index, playerId },
          ...rs.filter((toggle) => toggle.index !== index),
        ]);
      } else {
        setFetching((fs) => [{ index, playerId }, ...fs]);
        getPlayerSolution(results[index].id, playerId).then(
          (playerSolution) => {
            if (isCosmeticPlayerSolution(playerSolution)) {
              let newSolutions: {
                [key in number]: AnchoredPlayerSolution | undefined;
              } = roundSolutions || {};
              newSolutions[playerId] = { playerSolution, anchorEl };
              setStoredPlayerSolutions((sps) => {
                sps[index] = newSolutions;
                return { ...sps };
              });
              setReplays((rs) => [
                { index, playerId },
                ...rs.filter((toggle) => toggle.index !== index),
              ]);
              setFetching((fs) =>
                fs.filter(
                  (it) => it.playerId !== playerId && it.index !== index
                )
              );
            }
          }
        );
      }
    },
    [getPlayerSolution, storedPlayerSolutions, results, showReplay]
  );

  const getReplay = useCallback(
    (index: number, playerId: number) =>
      storedPlayerSolutions[index] !== undefined
        ? storedPlayerSolutions[index]!![playerId]
        : undefined,
    [storedPlayerSolutions]
  );

  const [expanded, setExpanded] = useState<number[]>([]);

  return (
    <Fragment>
      {results.map(
        (roundResults, roundIndex) =>
          roundResults.results.length > 0 && (
            <Accordion
              key={"Acc" + roundIndex}
              disableGutters
              elevation={roundIndex % 2 ? 2 : 5}
              sx={
                roundIndex === results.length - 1
                  ? { ...roundedBottoms, ...noBorders }
                  : roundIndex === 0
                  ? { ...roundedTops, ...noBorders }
                  : noBorders
              }
              TransitionProps={{ mountOnEnter: true }}
              onChange={(_, expanded) =>
                setExpanded((exps) =>
                  expanded
                    ? [...exps, roundIndex]
                    : exps.filter((it) => it !== roundIndex)
                )
              }
            >
              <AccordionSummary
                expandIcon={
                  highlightPlayedRounds &&
                  expanded.every((it) => it !== roundIndex) &&
                  roundResults.results.find(
                    (it) => it.player.id === playerId
                  ) ? (
                    <CheckCircleOutline />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Grid container justifyContent="space-between">
                  {!roundHeader && (
                    <Fragment>
                      <Grid item xs={0} sm={1} />
                      <Grid item xs={4}>
                        <Typography align="right">
                          {roundResults.results[0].player.guest ? (
                            <b>
                              <i>{roundResults.results[0].player.name}</i>
                            </b>
                          ) : (
                            <b>{roundResults.results[0].player.name}</b>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography align="center">—</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography align="left">
                          <b>
                            {(roundResults.results[0].result!! * 1000).toFixed(
                              0
                            )}
                          </b>
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  {roundHeader && (
                    <Fragment>
                      <Typography>
                        Round {roundIndex + 1}
                        {roundResults.results[0].player.guest ? (
                          <b>
                            <i> {roundResults.results[0].player.name}</i>
                          </b>
                        ) : (
                          <b> {roundResults.results[0].player.name}</b>
                        )}
                      </Typography>
                    </Fragment>
                  )}
                  <Grid item>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        setDisableReplayButton(true);
                        replay(roundResults.id);
                      }}
                      className={"replayButton"}
                      disabled={disableReplayButton}
                      sx={{ py: 1, my: -1 }}
                    >
                      <VisibilityIcon className="replayButton" />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, m: 0 }}>
                <FixedSizeList
                  height={Math.min(roundResults.results.length * 40 + 5, 400)}
                  itemSize={40}
                  itemCount={roundResults.results.length}
                  width={"100%"}
                  itemData={{
                    round: roundResults.round,
                    results: roundResults.results,
                    showReplay: (playerId: number) =>
                      showReplay(roundIndex, playerId),
                    closeReplay: (playerId: number) =>
                      closeReplay(roundIndex, playerId),
                    playerId,
                    isFetching: (playerId: number) =>
                      isFetching(roundIndex, playerId),
                    fetchReplay: (
                      playerId: number,
                      anchorEl: EventTarget & HTMLButtonElement
                    ) => fetchReplay(roundIndex, playerId, anchorEl),
                    getReplay: (playerId: number) =>
                      getReplay(roundIndex, playerId),
                    normalizeScores,
                  }}
                  onScroll={() =>
                    setReplays((rs) => (rs.length === 0 ? rs : []))
                  }
                >
                  {RoundResultsElement}
                </FixedSizeList>
              </AccordionDetails>
            </Accordion>
          )
      )}
    </Fragment>
  );
};
