import {
  Alert,
  AlertColor,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { ReactNode, useState } from "react";

interface TipElementProps {
  localStorageKey: string;
  variant?:  "outlined" | "standard" | "filled";
  severity?: AlertColor,
  children: ReactNode;
}

export const TipElement = (props: TipElementProps) => {
  const { localStorageKey,variant, children, severity } = props;

  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showAlert, setShowAlert] = useState(
    localStorage.getItem(localStorageKey) === null
  );

  return (
    <Alert
      variant={variant ? variant : "outlined"}
      severity={severity ? severity : "info"}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            if (dontShowAgain) {
              localStorage.setItem(localStorageKey, "true");
            }
            setShowAlert(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ mb: 2, display: showAlert ? undefined : "none" }}
    >
      {children}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={dontShowAgain}
              onChange={() => setDontShowAgain((v) => !v)}
            />
          }
          label={
            <Typography sx={{ pt: 0.5 }} variant="caption">
              Don't show this message again
            </Typography>
          }
        />
      </FormGroup>
    </Alert>
  );
};
