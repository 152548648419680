import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { craftingService } from "../network/CraftingService";
import { playerService } from "../network/PlayerService";
import {
  Inventory,
  isInventory,
  Pattern,
  UniqueBlockPattern,
  UniquePattern,
} from "../shared/Cosmetics";
import {
  CosmeticTool as Tool,
  isToolResult,
  isToolBelt,
  isUniqueTool,
  ToolBelt,
  toolInfo,
  UniqueTool,
  ToolResult,
} from "../shared/Crafting";
import { InputPicker } from "./elements/InputPicker";
import { MazePaper } from "./elements/MazePaper";
import { PatternElement } from "./elements/PatternElement";
import { ToolElement } from "./elements/ToolElement";
import SouthIcon from "@mui/icons-material/South";
import { BlockPatternElement } from "./elements/BlockPatternElement";
import { blockCosmeticImage } from "../utils/imageGenerators";
import { useTextures } from "../game/sprites/TextureDaemon";
import { Prices } from "./elements/BountyRewardElement";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";

export const Crafting = () => {
  const [inventory, setInventory] = useState<Inventory>();
  const [toolBelt, setToolBelt] = useState<ToolBelt>();

  const toolCounts = useMemo(() => {
    const counts = {
      patterns: new Map<Pattern, number>(),
      tools: new Map<Tool, number>(),
    };

    toolBelt?.patterns.forEach((it) =>
      counts.patterns.set(
        it.pattern,
        (counts.patterns.get(it.pattern) || 0) + 1
      )
    );
    toolBelt?.tools.forEach((it) =>
      counts.tools.set(it.tool, (counts.tools.get(it.tool) || 0) + 1)
    );
    return counts;
  }, [toolBelt]);

  const refreshToolBelt = useCallback(() => {
    craftingService
      .toolBelt()
      .then((response) => isToolBelt(response) && setToolBelt(response));
  }, [setToolBelt]);

  useEffect(refreshToolBelt, [refreshToolBelt]);

  const refreshInventory = useCallback(() => {
    playerService
      .inventory()
      .then((response) => isInventory(response) && setInventory(response));
  }, [setInventory]);

  useEffect(refreshInventory, [refreshInventory]);

  const [currentCraft, setCurrentCraft] = useState<
    UniqueTool | UniquePattern
  >();

  const [currentInputs, setCurrentInputs] = useState<
    (UniqueBlockPattern | undefined)[]
  >([]);

  const navigate = useNavigate();

  const inputFilter = useMemo(
    () =>
      isUniqueTool(currentCraft)
        ? toolInfo[currentCraft.tool].inputFilter
        : (blockPattern: UniqueBlockPattern) =>
            blockPattern.blockPattern.pattern === Pattern.SOLID,
    [currentCraft]
  );

  const nextInputFilter = useMemo(
    () =>
      isUniqueTool(currentCraft)
        ? toolInfo[currentCraft.tool].nextInputFilter
        : undefined,
    [currentCraft]
  );

  const textures = useTextures();

  useEffect(() => {
    if (inventory && textures["tile.png"]) {
      blockCosmeticImage(
        inventory.blockPatterns.map((it) => it.blockPattern),
        textures
      );
    }
  }, [inventory, textures]);

  useEffect(() => {
    if (isUniqueTool(currentCraft)) {
      setCurrentInputs(
        [...Array(toolInfo[currentCraft.tool].inputs)].map(() => undefined)
      );
    } else {
      setCurrentInputs([undefined, undefined]);
    }
  }, [currentCraft, setCurrentInputs]);

  const [disableConfirmation, setDisableConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [toolResult, setToolResult] = useState<ToolResult>();

  const craft = useCallback(() => {
    setDisableConfirmation(true);
    (isUniqueTool(currentCraft)
      ? craftingService.applyTool(
          currentCraft.id,
          currentInputs.map((it) => it!!.id)
        )
      : craftingService.craftPattern(
          currentInputs[0]!!.id,
          currentInputs[1]!!.id,
          currentCraft!!.id
        )
    ).then((response) => {
      if (isToolResult(response)) {
        setCurrentCraft(undefined);
        setCurrentInputs([]);
        refreshInventory();
        refreshToolBelt();
        setShowConfirmation(false);
        setDisableConfirmation(false);
        setToolResult(response);
      }
    });
  }, [
    currentCraft,
    currentInputs,
    setCurrentCraft,
    setCurrentInputs,
    refreshToolBelt,
    refreshInventory,
    setShowConfirmation,
    setDisableConfirmation,
    setToolResult,
  ]);

  return (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 5 }}>
      <MazePaper
        title="Crafting"
        goBack={() => {
          if (currentCraft) {
            setCurrentCraft(undefined);
            setCurrentInputs([]);
          } else {
            navigate(-1);
          }
        }}
      >
        {toolResult ? (
          <Box>
            <Typography sx={{ pb: 2 }}>
              You have received the following
            </Typography>
            <Prices {...toolResult} />
            <Grid container sx={{ pt: 3 }} justifyContent="space-evenly">
              <Grid item />
              <Button
                onClick={() => navigate("/inventory")}
                variant="contained"
              >
                inventory
              </Button>
              <Button
                onClick={() => setToolResult(undefined)}
                variant="contained"
              >
                continue
              </Button>
              <Grid item />
            </Grid>
          </Box>
        ) : currentCraft ? (
          <Fragment>
            <Grid container justifyContent="center">
              <Grid item>
                {isUniqueTool(currentCraft) ? (
                  <ToolElement tool={currentCraft.tool} />
                ) : (
                  <PatternElement pattern={currentCraft.pattern} />
                )}
              </Grid>
            </Grid>
            <Typography>
              {isUniqueTool(currentCraft)
                ? toolInfo[currentCraft.tool].description
                : "Craft a pattern using two blocks"}
            </Typography>
            <Stack direction="row" justifyContent="center" width={1}>
              {inventory &&
                currentInputs.map((blockPattern, index) => (
                  <Fragment>
                    {index === 1 && !isUniqueTool(currentCraft) && (
                      <Grid
                        item
                        xs={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          onClick={() =>
                            setCurrentInputs((cis) =>
                              cis ? [cis[1], cis[0]] : cis
                            )
                          }
                        >
                          <SwapHorizontalCircleIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <InputPicker
                        inventory={inventory}
                        current={
                          currentInputs.filter(
                            (it) => it !== undefined
                          ) as UniqueBlockPattern[]
                        }
                        inputFilter={inputFilter}
                        nextInputFilter={nextInputFilter}
                        picked={blockPattern}
                        pick={(blockPattern) =>
                          setCurrentInputs((c) => {
                            c[index] = blockPattern;
                            return [...c];
                          })
                        }
                        locked={showConfirmation}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </Stack>
            {currentInputs.length > 0 &&
              currentInputs.every((it) => it !== undefined) && (
                <Fragment>
                  <Grid container justifyContent="center">
                    <SouthIcon fontSize="large" />
                  </Grid>
                  {isUniqueTool(currentCraft) ? (
                    toolInfo[currentCraft.tool].output(
                      currentInputs as UniqueBlockPattern[]
                    )
                  ) : (
                    <BlockPatternElement
                      blockPattern={{
                        pattern: currentCraft.pattern,
                        firstColor: currentInputs[0]!!.blockPattern.firstColor,
                        secondColor: currentInputs[1]!!.blockPattern.firstColor,
                      }}
                      inspectable
                    />
                  )}
                  <Grid container justifyContent="center" sx={{ pt: 2 }}>
                    {showConfirmation ? (
                      <Paper>
                        <Typography width={1} sx={{ p: 3 }}>
                          This craft will consume your{" "}
                          {isUniqueTool(currentCraft)
                            ? toolInfo[currentCraft.tool].name
                            : "Pattern"}{" "}
                          tool and the Blocks you chose. <br />
                          <br />
                          Do you wish to continue?
                          <Grid
                            container
                            justifyContent="space-evenly"
                            sx={{ pt: 2 }}
                          >
                            <Grid item />
                            <Button
                              onClick={craft}
                              variant="contained"
                              color="success"
                              disabled={disableConfirmation}
                            >
                              Yes
                            </Button>
                            <Button
                              onClick={() => setShowConfirmation(false)}
                              variant="contained"
                              color="error"
                              disabled={disableConfirmation}
                            >
                              Cancel
                            </Button>
                            <Grid item />
                          </Grid>
                        </Typography>
                      </Paper>
                    ) : (
                      <Button
                        onClick={() => setShowConfirmation(true)}
                        variant="contained"
                      >
                        Craft
                      </Button>
                    )}
                  </Grid>
                </Fragment>
              )}
          </Fragment>
        ) : (
          <Grid container width={1}>
            {toolCounts.patterns.size === 0 && toolCounts.tools.size === 0 && (
              <Typography>
                You do not have any crafting tools! Crafting tools can be
                obtained by winning <b>Bounties</b>!
              </Typography>
            )}
            {Array.from(toolCounts.patterns.entries()).map(
              ([pattern, count]) => (
                <Grid item xs={3} sm={4}>
                  <Badge badgeContent={count} color="primary">
                    <Button
                      sx={{ textTransform: "none", color: "white" }}
                      onClick={() =>
                        setCurrentCraft(
                          toolBelt?.patterns.find(
                            (it) => it.pattern === pattern
                          )
                        )
                      }
                    >
                      <PatternElement pattern={pattern} />
                    </Button>
                  </Badge>
                </Grid>
              )
            )}
            {Array.from(toolCounts.tools.entries()).map(([tool, count]) => (
              <Grid item xs={3} sm={4}>
                <Badge badgeContent={count} color="primary">
                  <Button
                    sx={{ textTransform: "none", color: "white" }}
                    onClick={() =>
                      setCurrentCraft(
                        toolBelt?.tools.find((it) => it.tool === tool)
                      )
                    }
                  >
                    <ToolElement tool={tool} />
                  </Button>
                </Badge>
              </Grid>
            ))}
          </Grid>
        )}
      </MazePaper>
    </Box>
  );
};
