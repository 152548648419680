import { TextureName } from "./texture";

export const frostEffectFrames: TextureName[] = [
  "frostEffect0001.png",
  "frostEffect0002.png",
  "frostEffect0003.png",
  "frostEffect0004.png",
  "frostEffect0005.png",
  "frostEffect0006.png",
  "frostEffect0007.png",
  "frostEffect0008.png",
  "frostEffect0009.png",
  "frostEffect0010.png",
  "frostEffect0011.png",
  "frostEffect0012.png",
  "frostEffect0013.png",
  "frostEffect0014.png",
  "frostEffect0015.png",
  "frostEffect0016.png",
  "frostEffect0017.png",
  "frostEffect0018.png",
  "frostEffect0019.png",
  "frostEffect0020.png",
  "frostEffect0021.png",
  "frostEffect0022.png",
  "frostEffect0023.png",
  "frostEffect0024.png",
  "frostEffect0025.png",
];

export const frostCooldownIndicatorFrames: TextureName[] = [
  "frostCooldownIndicator0001.png",
  "frostCooldownIndicator0002.png",
  "frostCooldownIndicator0003.png",
  "frostCooldownIndicator0004.png",
  "frostCooldownIndicator0005.png",
  "frostCooldownIndicator0006.png",
  "frostCooldownIndicator0007.png",
  "frostCooldownIndicator0008.png",
  "frostCooldownIndicator0009.png",
  "frostCooldownIndicator0010.png",
  "frostCooldownIndicator0011.png",
  "frostCooldownIndicator0012.png",
  "frostCooldownIndicator0013.png",
  "frostCooldownIndicator0014.png",
  "frostCooldownIndicator0015.png",
  "frostCooldownIndicator0016.png",
  "frostCooldownIndicator0017.png",
  "frostCooldownIndicator0018.png",
  "frostCooldownIndicator0019.png",
  "frostCooldownIndicator0020.png",
  "frostCooldownIndicator0021.png",
  "frostCooldownIndicator0022.png",
  "frostCooldownIndicator0023.png",
  "frostCooldownIndicator0024.png",
  "frostCooldownIndicator0025.png",
  "frostCooldownIndicator0026.png",
  "frostCooldownIndicator0027.png",
  "frostCooldownIndicator0028.png",
  "frostCooldownIndicator0029.png",
  "frostCooldownIndicator0030.png",
  "frostCooldownIndicator0031.png",
  "frostCooldownIndicator0032.png",
  "frostCooldownIndicator0033.png",
  "frostCooldownIndicator0034.png",
  "frostCooldownIndicator0035.png",
  "frostCooldownIndicator0036.png",
  "frostCooldownIndicator0037.png",
  "frostCooldownIndicator0038.png",
  "frostCooldownIndicator0039.png",
  "frostCooldownIndicator0040.png",
  "frostCooldownIndicator0041.png",
  "frostCooldownIndicator0042.png",
  "frostCooldownIndicator0043.png",
  "frostCooldownIndicator0044.png",
  "frostCooldownIndicator0045.png",
  "frostCooldownIndicator0046.png",
  "frostCooldownIndicator0047.png",
  "frostCooldownIndicator0048.png",
  "frostCooldownIndicator0049.png",
  "frostCooldownIndicator0050.png",
  "frostCooldownIndicator0051.png",
  "frostCooldownIndicator0052.png",
  "frostCooldownIndicator0053.png",
  "frostCooldownIndicator0054.png",
  "frostCooldownIndicator0055.png",
  "frostCooldownIndicator0056.png",
  "frostCooldownIndicator0057.png",
  "frostCooldownIndicator0058.png",
  "frostCooldownIndicator0059.png",
  "frostCooldownIndicator0060.png",
  "frostCooldownIndicator0061.png",
  "frostCooldownIndicator0062.png",
  "frostCooldownIndicator0063.png",
  "frostCooldownIndicator0064.png",
  "frostCooldownIndicator0065.png",
  "frostCooldownIndicator0066.png",
  "frostCooldownIndicator0067.png",
  "frostCooldownIndicator0068.png",
  "frostCooldownIndicator0069.png",
  "frostCooldownIndicator0070.png",
  "frostCooldownIndicator0071.png",
  "frostCooldownIndicator0072.png",
  "frostCooldownIndicator0073.png",
  "frostCooldownIndicator0074.png",
  "frostCooldownIndicator0075.png",
  "frostCooldownIndicator0076.png",
  "frostCooldownIndicator0077.png",
  "frostCooldownIndicator0078.png",
  "frostCooldownIndicator0079.png",
  "frostCooldownIndicator0080.png",
  "frostCooldownIndicator0081.png",
  "frostCooldownIndicator0082.png",
  "frostCooldownIndicator0083.png",
  "frostCooldownIndicator0084.png",
  "frostCooldownIndicator0085.png",
  "frostCooldownIndicator0086.png",
  "frostCooldownIndicator0087.png",
  "frostCooldownIndicator0088.png",
  "frostCooldownIndicator0089.png",
  "frostCooldownIndicator0090.png",
  "frostCooldownIndicator0091.png",
  "frostCooldownIndicator0092.png",
  "frostCooldownIndicator0093.png",
  "frostCooldownIndicator0094.png",
  "frostCooldownIndicator0095.png",
  "frostCooldownIndicator0096.png",
  "frostCooldownIndicator0097.png",
  "frostCooldownIndicator0098.png",
  "frostCooldownIndicator0099.png",
  "frostCooldownIndicator0100.png",
  "frostCooldownIndicator0101.png",
  "frostCooldownIndicator0102.png",
  "frostCooldownIndicator0103.png",
  "frostCooldownIndicator0104.png",
  "frostCooldownIndicator0105.png",
  "frostCooldownIndicator0106.png",
  "frostCooldownIndicator0107.png",
  "frostCooldownIndicator0108.png",
  "frostCooldownIndicator0109.png",
  "frostCooldownIndicator0110.png",
  "frostCooldownIndicator0111.png",
  "frostCooldownIndicator0112.png",
  "frostCooldownIndicator0113.png",
  "frostCooldownIndicator0114.png",
  "frostCooldownIndicator0115.png",
  "frostCooldownIndicator0116.png",
  "frostCooldownIndicator0117.png",
  "frostCooldownIndicator0118.png",
  "frostCooldownIndicator0119.png",
  "frostCooldownIndicator0120.png",
  "frostCooldownIndicator0121.png",
  "frostCooldownIndicator0122.png",
  "frostCooldownIndicator0123.png",
  "frostCooldownIndicator0124.png",
  "frostCooldownIndicator0125.png",
  "frostCooldownIndicator0126.png",
  "frostCooldownIndicator0127.png",
  "frostCooldownIndicator0128.png",
  "frostCooldownIndicator0129.png",
  "frostCooldownIndicator0130.png",
  "frostCooldownIndicator0131.png",
  "frostCooldownIndicator0132.png",
  "frostCooldownIndicator0133.png",
  "frostCooldownIndicator0134.png",
  "frostCooldownIndicator0135.png",
  "frostCooldownIndicator0136.png",
  "frostCooldownIndicator0137.png",
  "frostCooldownIndicator0138.png",
  "frostCooldownIndicator0139.png",
  "frostCooldownIndicator0140.png",
  "frostCooldownIndicator0141.png",
  "frostCooldownIndicator0142.png",
  "frostCooldownIndicator0143.png",
  "frostCooldownIndicator0144.png",
  "frostCooldownIndicator0145.png",
  "frostCooldownIndicator0146.png",
  "frostCooldownIndicator0147.png",
  "frostCooldownIndicator0148.png",
  "frostCooldownIndicator0149.png",
  "frostCooldownIndicator0150.png",
  "frostCooldownIndicator0151.png",
  "frostCooldownIndicator0152.png",
  "frostCooldownIndicator0153.png",
  "frostCooldownIndicator0154.png",
  "frostCooldownIndicator0155.png",
  "frostCooldownIndicator0156.png",
  "frostCooldownIndicator0157.png",
  "frostCooldownIndicator0158.png",
  "frostCooldownIndicator0159.png",
  "frostCooldownIndicator0160.png",
  "frostCooldownIndicator0161.png",
  "frostCooldownIndicator0162.png",
  "frostCooldownIndicator0163.png",
  "frostCooldownIndicator0164.png",
  "frostCooldownIndicator0165.png",
  "frostCooldownIndicator0166.png",
  "frostCooldownIndicator0167.png",
  "frostCooldownIndicator0168.png",
  "frostCooldownIndicator0169.png",
  "frostCooldownIndicator0170.png",
  "frostCooldownIndicator0171.png",
  "frostCooldownIndicator0172.png",
  "frostCooldownIndicator0173.png",
  "frostCooldownIndicator0174.png",
  "frostCooldownIndicator0175.png",
  "frostCooldownIndicator0176.png",
  "frostCooldownIndicator0177.png",
  "frostCooldownIndicator0178.png",
  "frostCooldownIndicator0179.png",
  "frostCooldownIndicator0180.png",
  "frostCooldownIndicator0181.png",
  "frostCooldownIndicator0182.png",
  "frostCooldownIndicator0183.png",
  "frostCooldownIndicator0184.png",
  "frostCooldownIndicator0185.png",
  "frostCooldownIndicator0186.png",
  "frostCooldownIndicator0187.png",
  "frostCooldownIndicator0188.png",
  "frostCooldownIndicator0189.png",
  "frostCooldownIndicator0190.png",
  "frostCooldownIndicator0191.png",
  "frostCooldownIndicator0192.png",
  "frostCooldownIndicator0193.png",
  "frostCooldownIndicator0194.png",
  "frostCooldownIndicator0195.png",
  "frostCooldownIndicator0196.png",
  "frostCooldownIndicator0197.png",
  "frostCooldownIndicator0198.png",
  "frostCooldownIndicator0199.png",
  "frostCooldownIndicator0200.png",
  "frostCooldownIndicator0201.png",
  "frostCooldownIndicator0202.png",
  "frostCooldownIndicator0203.png",
  "frostCooldownIndicator0204.png",
  "frostCooldownIndicator0205.png",
  "frostCooldownIndicator0206.png",
  "frostCooldownIndicator0207.png",
  "frostCooldownIndicator0208.png",
  "frostCooldownIndicator0209.png",
  "frostCooldownIndicator0210.png",
  "frostCooldownIndicator0211.png",
  "frostCooldownIndicator0212.png",
  "frostCooldownIndicator0213.png",
  "frostCooldownIndicator0214.png",
  "frostCooldownIndicator0215.png",
  "frostCooldownIndicator0216.png",
  "frostCooldownIndicator0217.png",
  "frostCooldownIndicator0218.png",
  "frostCooldownIndicator0219.png",
  "frostCooldownIndicator0220.png",
  "frostCooldownIndicator0221.png",
  "frostCooldownIndicator0222.png",
  "frostCooldownIndicator0223.png",
  "frostCooldownIndicator0224.png",
  "frostCooldownIndicator0225.png",
  "frostCooldownIndicator0226.png",
  "frostCooldownIndicator0227.png",
  "frostCooldownIndicator0228.png",
  "frostCooldownIndicator0229.png",
  "frostCooldownIndicator0230.png",
  "frostCooldownIndicator0231.png",
  "frostCooldownIndicator0232.png",
  "frostCooldownIndicator0233.png",
  "frostCooldownIndicator0234.png",
  "frostCooldownIndicator0235.png",
  "frostCooldownIndicator0236.png",
  "frostCooldownIndicator0237.png",
  "frostCooldownIndicator0238.png",
  "frostCooldownIndicator0239.png",
  "frostCooldownIndicator0240.png",
  "frostCooldownIndicator0241.png",
  "frostCooldownIndicator0242.png",
  "frostCooldownIndicator0243.png",
  "frostCooldownIndicator0244.png",
  "frostCooldownIndicator0245.png",
  "frostCooldownIndicator0246.png",
  "frostCooldownIndicator0247.png",
  "frostCooldownIndicator0248.png",
  "frostCooldownIndicator0249.png",
  "frostCooldownIndicator0250.png",
  "frostCooldownIndicator0251.png",
  "frostCooldownIndicator0252.png",
  "frostCooldownIndicator0253.png",
  "frostCooldownIndicator0254.png",
  "frostCooldownIndicator0255.png",
  "frostCooldownIndicator0256.png",
  "frostCooldownIndicator0257.png",
  "frostCooldownIndicator0258.png",
  "frostCooldownIndicator0259.png",
  "frostCooldownIndicator0260.png",
  "frostCooldownIndicator0261.png",
  "frostCooldownIndicator0262.png",
  "frostCooldownIndicator0263.png",
  "frostCooldownIndicator0264.png",
  "frostCooldownIndicator0265.png",
  "frostCooldownIndicator0266.png",
  "frostCooldownIndicator0267.png",
  "frostCooldownIndicator0268.png",
  "frostCooldownIndicator0269.png",
  "frostCooldownIndicator0270.png",
  "frostCooldownIndicator0271.png",
  "frostCooldownIndicator0272.png",
  "frostCooldownIndicator0273.png",
  "frostCooldownIndicator0274.png",
  "frostCooldownIndicator0275.png",
  "frostCooldownIndicator0276.png",
  "frostCooldownIndicator0277.png",
  "frostCooldownIndicator0278.png",
  "frostCooldownIndicator0279.png",
  "frostCooldownIndicator0280.png",
  "frostCooldownIndicator0281.png",
  "frostCooldownIndicator0282.png",
  "frostCooldownIndicator0283.png",
  "frostCooldownIndicator0284.png",
  "frostCooldownIndicator0285.png",
  "frostCooldownIndicator0286.png",
  "frostCooldownIndicator0287.png",
  "frostCooldownIndicator0288.png",
  "frostCooldownIndicator0289.png",
  "frostCooldownIndicator0290.png",
  "frostCooldownIndicator0291.png",
  "frostCooldownIndicator0292.png",
  "frostCooldownIndicator0293.png",
  "frostCooldownIndicator0294.png",
  "frostCooldownIndicator0295.png",
  "frostCooldownIndicator0296.png",
  "frostCooldownIndicator0297.png",
  "frostCooldownIndicator0298.png",
  "frostCooldownIndicator0299.png",
  "frostCooldownIndicator0300.png",
];

export const ballFrames: TextureName[] = [
  "ball0001.png",
  "ball0002.png",
  "ball0003.png",
  "ball0004.png",
  "ball0005.png",
  "ball0006.png",
  "ball0007.png",
  "ball0008.png",
  "ball0009.png",
  "ball0010.png",
  "ball0011.png",
  "ball0012.png",
  "ball0013.png",
  "ball0014.png",
  "ball0015.png",
  "ball0016.png",
  "ball0017.png",
  "ball0018.png",
  "ball0019.png",
  "ball0020.png",
  "ball0021.png",
  "ball0022.png",
  "ball0023.png",
  "ball0024.png",
  "ball0025.png",
  "ball0026.png",
  "ball0027.png",
  "ball0028.png",
  "ball0029.png",
  "ball0030.png",
  "ball0031.png",
  "ball0032.png",
  "ball0033.png",
  "ball0034.png",
  "ball0035.png",
  "ball0036.png",
  "ball0037.png",
  "ball0038.png",
  "ball0039.png",
  "ball0040.png",
  "ball0041.png",
  "ball0042.png",
  "ball0043.png",
  "ball0044.png",
  "ball0045.png",
  "ball0046.png",
  "ball0047.png",
  "ball0048.png",
  "ball0049.png",
  "ball0050.png",
  "ball0051.png",
  "ball0052.png",
  "ball0053.png",
  "ball0054.png",
  "ball0055.png",
  "ball0056.png",
  "ball0057.png",
  "ball0058.png",
  "ball0059.png",
  "ball0060.png",
  "ball0061.png",
  "ball0062.png",
  "ball0063.png",
  "ball0064.png",
  "ball0065.png",
  "ball0066.png",
  "ball0067.png",
  "ball0068.png",
  "ball0069.png",
  "ball0070.png",
  "ball0071.png",
  "ball0072.png",
  "ball0073.png",
  "ball0074.png",
  "ball0075.png",
  "ball0076.png",
  "ball0077.png",
  "ball0078.png",
  "ball0079.png",
  "ball0080.png",
  "ball0081.png",
  "ball0082.png",
  "ball0083.png",
  "ball0084.png",
  "ball0085.png",
  "ball0086.png",
  "ball0087.png",
  "ball0088.png",
  "ball0089.png",
  "ball0090.png",
];

export const ballFrozenFrames: TextureName[] = [
  "ballFrozen0001.png",
  "ballFrozen0002.png",
  "ballFrozen0003.png",
  "ballFrozen0004.png",
  "ballFrozen0005.png",
  "ballFrozen0006.png",
  "ballFrozen0007.png",
  "ballFrozen0008.png",
  "ballFrozen0009.png",
  "ballFrozen0010.png",
  "ballFrozen0011.png",
  "ballFrozen0012.png",
  "ballFrozen0013.png",
  "ballFrozen0014.png",
  "ballFrozen0015.png",
  "ballFrozen0016.png",
  "ballFrozen0017.png",
  "ballFrozen0018.png",
  "ballFrozen0019.png",
  "ballFrozen0020.png",
  "ballFrozen0021.png",
  "ballFrozen0022.png",
  "ballFrozen0023.png",
  "ballFrozen0024.png",
  "ballFrozen0025.png",
  "ballFrozen0026.png",
  "ballFrozen0027.png",
  "ballFrozen0028.png",
  "ballFrozen0029.png",
  "ballFrozen0030.png",
  "ballFrozen0031.png",
  "ballFrozen0032.png",
  "ballFrozen0033.png",
  "ballFrozen0034.png",
  "ballFrozen0035.png",
  "ballFrozen0036.png",
  "ballFrozen0037.png",
  "ballFrozen0038.png",
  "ballFrozen0039.png",
  "ballFrozen0040.png",
  "ballFrozen0041.png",
  "ballFrozen0042.png",
  "ballFrozen0043.png",
  "ballFrozen0044.png",
  "ballFrozen0045.png",
  "ballFrozen0046.png",
  "ballFrozen0047.png",
  "ballFrozen0048.png",
  "ballFrozen0049.png",
  "ballFrozen0050.png",
  "ballFrozen0051.png",
  "ballFrozen0052.png",
  "ballFrozen0053.png",
  "ballFrozen0054.png",
  "ballFrozen0055.png",
  "ballFrozen0056.png",
  "ballFrozen0057.png",
  "ballFrozen0058.png",
  "ballFrozen0059.png",
  "ballFrozen0060.png",
  "ballFrozen0061.png",
  "ballFrozen0062.png",
  "ballFrozen0063.png",
  "ballFrozen0064.png",
  "ballFrozen0065.png",
  "ballFrozen0066.png",
  "ballFrozen0067.png",
  "ballFrozen0068.png",
  "ballFrozen0069.png",
  "ballFrozen0070.png",
  "ballFrozen0071.png",
  "ballFrozen0072.png",
  "ballFrozen0073.png",
  "ballFrozen0074.png",
  "ballFrozen0075.png",
  "ballFrozen0076.png",
  "ballFrozen0077.png",
  "ballFrozen0078.png",
  "ballFrozen0079.png",
  "ballFrozen0080.png",
  "ballFrozen0081.png",
  "ballFrozen0082.png",
  "ballFrozen0083.png",
  "ballFrozen0084.png",
  "ballFrozen0085.png",
  "ballFrozen0086.png",
  "ballFrozen0087.png",
  "ballFrozen0088.png",
  "ballFrozen0089.png",
  "ballFrozen0090.png",
];
