import { Button, Box, Grid, Typography, Link } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router";
import { BountyReward } from "../../shared/Bounty";
import { UniqueBlockPattern, UniquePattern } from "../../shared/Cosmetics";
import { UniqueTool } from "../../shared/Crafting";
import { BlockPatternElement } from "./BlockPatternElement";
import { CoinRewardElement } from "./CoinRewardElement";
import { PatternElement } from "./PatternElement";
import { TipElement } from "./TipElement";
import { ToolElement } from "./ToolElement";

interface BountyRewardElementProps {
  bountyReward: BountyReward;
  rewardClaimed: () => void;
}

const noRewards = (bountyReward: BountyReward) =>
  bountyReward.patterns.length === 0 &&
  bountyReward.blockPatterns.length === 0 &&
  bountyReward.tools.length === 0 &&
  bountyReward.coins === 0;

interface PricesProps {
  blockPatterns?: UniqueBlockPattern[];
  patterns?: UniquePattern[];
  tools?: UniqueTool[];
  coins?: number;
}

export const Prices = (props: PricesProps) => {
  const { blockPatterns = [], patterns = [], tools = [], coins = 0 } = props;
  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }}>
      {blockPatterns.map((it) => (
        <Grid item xs={4} sm={3}>
          <BlockPatternElement blockPattern={it.blockPattern} inspectable />
        </Grid>
      ))}
      {patterns.map((it) => (
        <Grid item xs={4} sm={3}>
          <PatternElement pattern={it.pattern} />
        </Grid>
      ))}
      {tools.map((it) => (
        <Grid item xs={4} sm={3}>
          <ToolElement tool={it.tool} />
        </Grid>
      ))}
      {coins > 0 && (
        <Grid item xs={4} sm={3}>
          <CoinRewardElement coins={coins} />
        </Grid>
      )}
    </Grid>
  );
};

export const BountyRewardElement = (props: BountyRewardElementProps) => {
  const { bountyReward, rewardClaimed } = props;

  const navigate = useNavigate()

  return (
    <Box>
      {bountyReward.tools.length + bountyReward.patterns.length > 0 && (
        <TipElement localStorageKey={"toolLocation"}>
          <Typography>
            Looks like you just got your first <b>Crafting Tool</b> you can find
            the <b>Crafting</b> menu in the top right corner of your{" "}
            <Link
              underline="none"
              href="#"
              onClick={() => navigate("/inventory")}
            >
              Inventory
            </Link>
          </Typography>
        </TipElement>
      )}
      <Typography>
        {noRewards(bountyReward) ? (
          <Fragment>
            You didn't get the <b>Bounty</b> rewards, better luck next time!
          </Fragment>
        ) : (
          <Fragment>
            Congratulations on the <b>Bounty</b>! Here are your rewards!
          </Fragment>
        )}
      </Typography>
      {!noRewards(bountyReward) && <Prices {...bountyReward} />}
      <Button
        onClick={rewardClaimed}
        sx={{ mt: 3 }}
        color="success"
        variant="contained"
      >
        ok
      </Button>
    </Box>
  );
};
