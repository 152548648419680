import { Badge, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import {
  mixedRarityGradient,
  mixedRarityText,
  Rarity,
} from "../../shared/Cosmetics";
import { theme } from "../../utils/theme";
import { unselectable } from "../../utils/unselectable";

interface RarityBadgeProps {
  hideBadge?: boolean;
  firstRarity: Rarity;
  secondRarity?: Rarity;
  children: ReactNode;
}

export const RarityBadge = (props: RarityBadgeProps) => {
  const { firstRarity, secondRarity, hideBadge = false, children } = props;
  return (
    <Badge
      badgeContent={
        hideBadge ? (
          0
        ) : (
          <Typography
            noWrap
            sx={{ fontSize: "inherit", fontWeight: "inherit" }}
          >
            {mixedRarityText(firstRarity, secondRarity)}
          </Typography>
        )
      }
      sx={{
        "& .MuiBadge-badge": {
          background: mixedRarityGradient(firstRarity, secondRarity),
          border: `2px solid ${theme.palette.background.paper}`,
          bottom: 8,
          right: 8,
          fontSize: 0,
          zIndex: 0,
          color:
            firstRarity === Rarity.UNCOMMON ||
            (secondRarity && secondRarity === Rarity.UNCOMMON)
              ? "#1e1e1e"
              : undefined,
          ":hover": { fontSize: "small", cursor: "default" },
          ...unselectable,
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <span>{children}</span>
    </Badge>
  );
};
