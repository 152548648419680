import { Box, Typography } from "@mui/material";
import React from "react";
import { Points } from "./Emoji";

interface CoinRewardElementProps {
  coins: number;
}

export const CoinRewardElement = (props: CoinRewardElementProps) => {
  return (
    <Box>
      <Points variant={"h2"} />
      <Typography>
        <b>{props.coins}</b>
      </Typography>
    </Box>
  );
};
