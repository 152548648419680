import { useCallback, useRef } from "react";
import { Layout } from "../shared/Layout";

export const useTentativeLayout = (
  submitTentative: (layout: Layout) => void,
  submit: (layout: Layout) => void,
  setDuration: (duration: number) => void,
  deadline: Date | undefined
) => {
  const currentLayoutReference = useRef<Layout>({ towers: [] });

  const onLayoutChange = useCallback(
    (layout: Layout) => {
      currentLayoutReference.current = layout;
    },
    [currentLayoutReference]
  );

  const onBeforeUnload = useCallback(
    () => submitTentative(currentLayoutReference.current),
    [submitTentative]
  );

  const onPageVisibitilyChange = useCallback(
    (isActive: boolean) => {
      if (isActive) {
        if (deadline) {
          let currentTime = Date.now();
          if (deadline.getTime() > currentTime) {
            setDuration(Math.floor((deadline.getTime() - currentTime) / 1000));
          } else {
            submit({ towers: [] });
          }
        }
      } else {
        submitTentative(currentLayoutReference.current);
      }
    },
    [submitTentative, deadline, setDuration, submit]
  );

  return {
    onLayoutChange,
    onBeforeUnload,
    onPageVisibitilyChange,
  };
};
