import { Container, Sprite } from "@inlet/react-pixi";
import React from "react";
import { Textures } from "../sprites/texture";

interface FrostCounterProps {
  textures: Textures;
  counter: number;
  x?: number;
  y?: number;
  size: number;
  tileSize: number;
}

export const FrostCounter = (props: FrostCounterProps) => {
  const { textures, counter, x = 0, y = 0, size } = props;

  return (
    <Container x={x} y={y}>
      {[...Array(Math.max(counter, 0))].map((_, i) => (
        <Container key={"frostResource" + i}>
          <Sprite
            texture={textures["outerCornerTopNW.png"]}
            y={0}
            height={size / 2}
            width={size / 2}
            tint={0x999999}
            x={((size * 9) / 8) * i}
          />
          <Sprite
            texture={textures["outerCornerTopNE.png"]}
            y={0}
            height={size / 2}
            width={size / 2}
            tint={0x999999}
            x={((size * 9) / 8) * i + size / 2 - 1}
          />
          <Sprite
            texture={textures["outerCornerTopSW.png"]}
            y={size / 2 - 1}
            height={size / 2}
            width={size / 2}
            tint={0x999999}
            x={((size * 9) / 8) * i}
          />
          <Sprite
            texture={textures["outerCornerTopSE.png"]}
            y={size / 2 - 1}
            height={size / 2}
            width={size / 2}
            tint={0x999999}
            x={((size * 9) / 8) * i + size / 2 - 1}
          />
          <Sprite
            texture={textures["frostCooldownIndicator0300.png"]}
            width={size}
            height={size}
            y={0}
            x={((size * 9) / 8) * i}
            tint={0xffffff}
            alpha={counter > i ? 1 : 0}
          />
        </Container>
      ))}
    </Container>
  );
};
