import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getPuzzleService } from "../network/PuzzleService";
import { CosmeticPastPuzzle, isCosmeticPastPuzzle } from "../shared/Cosmetics";
import { Player } from "../shared/Player";
import { MultiplayerRoundElement } from "./elements/MultiplayerRoundElement";

interface PastPuzzlePageProps {
  player: Player | undefined | null;
}

export const PastPuzzlePage = (props: PastPuzzlePageProps) => {
  const { player } = props;
  const { pastId } = useParams();

  const [[currentId, pastPuzzle], setPastPuzzle] = useState<
    [number, CosmeticPastPuzzle] | [undefined, undefined]
  >([undefined, undefined]);

  const navigate = useNavigate();

  useEffect(() => {
    if (pastId && (pastPuzzle === undefined || +pastId !== currentId)) {
      getPuzzleService()
        .past(+pastId)
        .then((response) => {
          if (isCosmeticPastPuzzle(response)) {
            setPastPuzzle([+pastId, response]);
          }
        });
    }
  }, [setPastPuzzle, pastPuzzle, currentId, pastId]);

  return (
    <Fragment>
      {pastPuzzle && (
        <MultiplayerRoundElement
          round={pastPuzzle.round}
          playerSolution={
            pastPuzzle.playerSolutions.find(
              (it) => player && it.player.id === player.id
            ) || pastPuzzle.playerSolutions[0]
          }
          allSolutions={pastPuzzle.playerSolutions}
          runnerCompleted={() => navigate(-1)}
        />
      )}
    </Fragment>
  );
};
