import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PlayerAccount } from "../../shared/Player";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Emoji from "./Emoji";
import { CostButton } from "../CostButton";
import { cst } from "../../utils/constants";
import { getSaveService } from "../../network/SaveService";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router";
import { ReplayElement } from "./ReplayElement";
import { ScoreSlider } from "./ScoreSlider";
import { theme } from "../../utils/theme";
import { CosmeticRoundResult } from "../../shared/Cosmetics";
import { getRoundResultOutcome } from "../../shared/Arena";

export interface ButtonContent {
  text: String;
  icon: JSX.Element;
  onClick: () => void;
}

interface RoundResultElementProps {
  roundResult: CosmeticRoundResult;
  account?: PlayerAccount;
  refreshAccount?: () => void;
  replayFinished: () => void;
  showFreeServeTitle?: boolean;
}

export const RoundResultElement = (
  props: RoundResultElementProps
) => {
  const { refreshAccount, account, roundResult, replayFinished, showFreeServeTitle } =
    props;
  const navigate = useNavigate();

  const [savedSeed, setSavedSeed] = useState<number>();

  const [completed, setCompleted] = useState<boolean>(false);

  const currentScoreReference = useRef(0);
  const hasOpponent = useMemo(() => !(!roundResult.opponentSolution), [roundResult])

  const maxScore = useMemo(
    () =>
      Math.max(
        roundResult.playerSolution.solution.path.result,
        roundResult.opponentSolution?.solution.path.result || 0
      ),
    [roundResult]
  );

  const goBack = useCallback(() => {
    window.setTimeout(() => replayFinished(), 50);
  }, [replayFinished]);

  const [forcedScore, setForcedScore] = useState(0);

  const updateScore = useRef(true);

  const buttonContent = useMemo<ButtonContent>(() => {
    if (completed) {
      return {
        text: "Continue",
        icon: <NavigateNextIcon />,
        onClick: goBack,
      };
    } else {
      return {
        text: "Skip",
        icon: <SkipNextIcon />,
        onClick: () => {
          updateScore.current = false;
          setPause(true);
          setCompleted(true);
          setForcedScore(maxScore);
          currentScoreReference.current = maxScore;
        },
      };
    }
  }, [completed, goBack, maxScore]);

  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (pause) {
      if (forcedScore === maxScore) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }
  }, [pause, setCompleted, forcedScore, maxScore]);

  let maxWidth = Math.min(
    window.innerWidth,
    ((window.innerHeight - 56) * 40) / 19.5
  );

  let isXl = window.innerWidth > theme.breakpoints.values["xl"];
  let isMd = window.innerWidth > theme.breakpoints.values["md"] && !isXl;
  let isSm = window.innerWidth > theme.breakpoints.values["sm"] && !isMd;

  let startingWidth = useMemo(
    () =>
      isXl
        ? (maxWidth * 4) / 12 - 4
        : isMd
        ? (maxWidth * 5) / 12 - 4
        : isSm
        ? hasOpponent
          ? maxWidth / 2 - 12
          : (maxWidth * 8) / 12 - 12
        : hasOpponent
        ? maxWidth / 2 - 6
        : maxWidth - 8,
    [maxWidth, isXl, isMd, isSm, hasOpponent]
  );

  const [readyOne, setReadyOne] = useState(false);
  const [readyTwo, setReadyTwo] = useState(false);

  const outcome = useMemo(() => getRoundResultOutcome(roundResult), [roundResult])

  const [start, setStart] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (readyOne && (readyTwo || !hasOpponent)) {
      window.setTimeout(() => mounted && setStart(true), 500);
    }
    return () => {
      mounted = false;
    };
  }, [readyOne, readyTwo, hasOpponent, setStart]);

  return (
    <Grid
      container
      sx={{ pt: 3, px: { xs: 0.5, sm: 1, md: 0 } }}
      width={1}
      justifyContent="center"
      height={1}
    >
      <Grid item xs={12} maxWidth={maxWidth}>
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 1 }}>
          {!hasOpponent && showFreeServeTitle && (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Typography variant="h5">
                  <Emoji symbol="🎊" /> Free <b>Serve</b>! <Emoji symbol="🎊" />
                </Typography>
              </Grid>
            </Grid>
          )}
          {!hasOpponent && <Grid item xs={3} sm={2} md={3.5} xl={4} />}
          <Grid
            item
            xs={hasOpponent ? 6 : 12}
            sm={hasOpponent ? 6 : 8}
            md={5}
            xl={4}
          >
            <ReplayElement
              startingWidth={startingWidth}
              playerSolution={roundResult.playerSolution}
              completed={completed}
              place={outcome === "LOSS" ? 2 : 1}
              forcedScore={forcedScore}
              pause={pause}
              maxScore={maxScore}
              ready={() => setReadyOne(true)}
              start={start}
              runnerCompleted={() => setCompleted(true)}
              round={roundResult.round}
              setScore={(s) => {
                if (updateScore.current) {
                  currentScoreReference.current = s;
                }
              }}
            />
          </Grid>
          {!hasOpponent && <Grid item xs={3} sm={2} md={3.5} xl={4} />}
          {roundResult.opponentSolution &&
            roundResult.opponentSolution.solution.path.sections.length >
              0 && (
              <Grid item xs={6} md={5} xl={4}>
                <ReplayElement
                  startingWidth={startingWidth}
                  playerSolution={roundResult.opponentSolution}
                  completed={completed}
                  place={outcome === "LOSS" ? 1 : 2}
                  round={roundResult.round}
                  ready={() => setReadyTwo(true)}
                  start={start}
                  pause={pause}
                  forcedScore={forcedScore}
                  startingScoreReference={currentScoreReference}
                />
              </Grid>
            )}
          <Grid
            item
            xs={12}
            sm={hasOpponent ? 12 : 8}
            md={hasOpponent ? 10 : 5}
            xl={hasOpponent ? 8 : 4}
          >
            <Box sx={{ mx: 1.5, pb: 0.5, mt: -0.5 }}>
              <ScoreSlider
                ballCosmetics={roundResult.playerSolution.cosmetics.ball}
                max={maxScore}
                scoreReference={currentScoreReference}
                setScore={setForcedScore}
                completed={completed}
                pause={() => {
                  updateScore.current = false;
                  setPause(true);
                }}
                unPause={() => {
                  updateScore.current = true;
                  setPause(false);
                }}
                path={roundResult.playerSolution.solution.path}
              />
            </Box>
            <Paper>
              <Box width={1}>
                <Grid container justifyContent="space-between">
                  {account &&
                    (savedSeed ? (
                      <Button
                        onClick={() => {
                          localStorage.setItem("returnRoute", "/arena");
                          navigate("/save/" + savedSeed);
                        }}
                        endIcon={<PlayArrowIcon />}
                      >
                        Play Save
                      </Button>
                    ) : (
                      <CostButton
                        disabled={account.points < cst.saveCost}
                        label={"Save"}
                        cost={cst.saveCost}
                        onClick={() =>
                          getSaveService()
                            .saveArenaRound(Date.now())
                            .then((response) => {
                              if (!isNaN(+response)) {
                                refreshAccount && refreshAccount();
                                setSavedSeed(+response);
                              }
                            })
                        }
                      />
                    ))}
                  {!account && <Box />}
                  <Button
                    onClick={buttonContent.onClick}
                    endIcon={buttonContent.icon}
                  >
                    {buttonContent.text}
                  </Button>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
