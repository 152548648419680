import React, { useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { ReplayElement } from "./ReplayElement";
import { CosmeticPlayerSolution } from "../../shared/Cosmetics";
import { Round } from "../../shared/Round";
import { ScoreSlider } from "./ScoreSlider";

interface SingleRoundElementProps {
  playerSolution: CosmeticPlayerSolution;
  round: Round;
  place: number;
  width: number;
}

export const SingleRoundElement = (props: SingleRoundElementProps) => {
  const { playerSolution, width, round, place } = props;

  const currentScoreReference = useRef(0);

  const [forcedScore, setForcedScore] = useState(0);
  const [pause, setPause] = useState(false);

  return (
    <Box width={width}>
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} zIndex={10}>
          <ReplayElement
            startingWidth={width}
            isPlayer
            playerSolution={playerSolution}
            setScore={(s) => {
              currentScoreReference.current = s;
            }}
            start={true}
            pause={pause}
            completed
            forcedScore={forcedScore}
            offsetIcons
            round={round}
            place={place}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={22}
            sx={{
              pt: 0,
              pb: 0.75,
              mx: 0.5,
              mt: 0,
              px: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          >
            <Box sx={{ pt: 0.5 }}>
              <ScoreSlider
                max={playerSolution.solution.path.result}
                scoreReference={currentScoreReference}
                completed={false}
                setScore={setForcedScore}
                pause={() => setPause(true)}
                unPause={() => setPause(false)}
                path={playerSolution.solution.path}
                ballCosmetics={playerSolution.cosmetics.ball}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
