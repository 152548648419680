import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import React, { useRef, useState } from "react";
import { Game } from "../game/Game";
import { defaultCosmetics } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { Round } from "../shared/Round";
import { lt } from "../utils/lt";
import { ScoreSlider } from "./elements/ScoreSlider";

export type Diff = {
  round: Round;
  layout: Layout;
  before: Path;
  after: Path;
};

const diffs: Diff[] = [ {
  "round" : {
    "towers" : 7,
    "claps" : 2,
    "board" : {
      "width" : 16,
      "height" : 18,
      "staticTowers" : [ {
        "coord" : {
          "x" : 9,
          "y" : 5
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 12,
          "y" : 15
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 13
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 1,
          "y" : 8
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 9,
          "y" : 13
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 12,
          "y" : 13
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 9,
          "y" : 9
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 8,
          "y" : 1
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 12,
          "y" : 9
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 4,
          "y" : 9
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 4,
          "y" : 11
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 15
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 0,
          "y" : 12
        },
        "static" : true,
        "clap" : false
      } ],
      "startArea" : [ {
        "x" : 14,
        "y" : 18
      }, {
        "x" : 13,
        "y" : 18
      } ],
      "endArea" : [ {
        "x" : -1,
        "y" : 15
      }, {
        "x" : -1,
        "y" : 14
      } ],
      "waypoint" : undefined
    }
  },
  "layout" : {
    "towers" : [ {
      "coord" : {
        "x" : 3,
        "y" : 16
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 13
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 5,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 7,
        "y" : 5
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 9,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 10,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    } ]
  },
  "before" : {
    "sections" : [ {
      "destination" : {
        "x" : 13.0,
        "y" : 18.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.362233,
        "y" : 15.724465
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 9.090352
      },
      "clapCoord" : {
        "x" : 7,
        "y" : 5
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.868569,
        "y" : 2.2628622
      },
      "clapCoord" : {
        "x" : 7,
        "y" : 5
      }
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.7008293,
        "y" : 11.196683
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : -1.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 82.95883178710938,
    "integerResult" : 82959,
  },
  "after" : {
    "sections" : [ {
      "destination" : {
        "x" : 13.0,
        "y" : 18.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.362233,
        "y" : 15.724465
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 6.4437294
      },
      "clapCoord" : {
        "x" : 7,
        "y" : 5
      }
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 4.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.620734,
        "y" : 4.758532
      },
      "clapCoord" : {
        "x" : 7,
        "y" : 5
      }
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.7008293,
        "y" : 11.196683
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : -1.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 84.97749328613281,
    "integerResult" : 84977
  }
}, {
  "round" : {
    "towers" : 21,
    "claps" : 1,
    "board" : {
      "width" : 16,
      "height" : 18,
      "staticTowers" : [ {
        "coord" : {
          "x" : 8,
          "y" : 13
        },
        "static" : true,
        "clap" : true
      }, {
        "coord" : {
          "x" : 12,
          "y" : 7
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 11,
          "y" : 10
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 5,
          "y" : 7
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 6
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 13,
          "y" : 1
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 9,
          "y" : 1
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 9,
          "y" : 11
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 8,
          "y" : 3
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 8,
          "y" : 15
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 4,
          "y" : 14
        },
        "static" : true,
        "clap" : false
      } ],
      "startArea" : [ {
        "x" : 14,
        "y" : -1
      }, {
        "x" : 15,
        "y" : -1
      } ],
      "endArea" : [ {
        "x" : -1,
        "y" : 15
      }, {
        "x" : -1,
        "y" : 14
      } ],
      "waypoint" : undefined
    }
  },
  "layout" : {
    "towers" : [ {
      "coord" : {
        "x" : 0,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 1,
        "y" : 1
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 1,
        "y" : 13
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 5
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 3,
        "y" : 11
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 2
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 4
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 5,
        "y" : 10
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 0
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 7,
        "y" : 6
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 9,
        "y" : 6
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 9,
        "y" : 8
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 1
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 4
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 13,
        "y" : 10
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 14,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    } ]
  },
  "before" : {
    "sections" : [ {
      "destination" : {
        "x" : 14.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 1.875
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 5.875
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.443729,
        "y" : 17.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 10.915867
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 7.8000946
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 3.9638367
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : -1.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 143.20913696289062,
    "integerResult" : 143209
  },
  "after" : {
    "sections" : [ {
      "destination" : {
        "x" : 14.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 1.875
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 5.875
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.108689,
        "y" : 13.156525
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 13.507198
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.909649,
        "y" : 13.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 13
      }
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 7.8000946
      },
      "clapCoord" : {
        "x" : 5,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 3.9638367
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : -1.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 146.61968994140625,
    "integerResult" : 146620
  }
}, {
  "round" : {
    "towers" : 19,
    "claps" : 2,
    "board" : {
      "width" : 16,
      "height" : 18,
      "staticTowers" : [ {
        "coord" : {
          "x" : 10,
          "y" : 9
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 0,
          "y" : 4
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 10,
          "y" : 12
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 3,
          "y" : 10
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 7
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 14
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 12,
          "y" : 5
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 10,
          "y" : 7
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 0,
          "y" : 15
        },
        "static" : true,
        "clap" : false
      } ],
      "startArea" : [ {
        "x" : 8,
        "y" : 18
      }, {
        "x" : 7,
        "y" : 18
      } ],
      "endArea" : [ {
        "x" : 8,
        "y" : -1
      }, {
        "x" : 7,
        "y" : -1
      } ],
      "waypoint" : undefined
    }
  },
  "layout" : {
    "towers" : [ {
      "coord" : {
        "x" : 0,
        "y" : 13
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 1,
        "y" : 11
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 1
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 3,
        "y" : 5
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 3,
        "y" : 14
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 4,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 8
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 13
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 2
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 6
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 6,
        "y" : 11
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 16
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 8,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 8,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 8,
        "y" : 14
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 10,
        "y" : 4
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 14
      },
      "static" : false,
      "clap" : false
    } ]
  },
  "before" : {
    "sections" : [ {
      "destination" : {
        "x" : 8.0,
        "y" : 18.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 14
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.702642,
        "y" : 11.594715
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 14
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.3032389,
        "y" : 12.696761
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.5562706,
        "y" : 10.0
      },
      "clapCoord" : {
        "x" : 6,
        "y" : 6
      }
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 8.556271
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 115.98536682128906,
    "integerResult" : 115985
  },
  "after" : {
    "sections" : [ {
      "destination" : {
        "x" : 8.0,
        "y" : 18.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 14
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 13.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.702642,
        "y" : 11.594715
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 14
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 16.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.3032389,
        "y" : 12.696761
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.5562706,
        "y" : 10.0
      },
      "clapCoord" : {
        "x" : 6,
        "y" : 6
      }
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 10.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 8.556271
      },
      "clapCoord" : {
        "x" : 6,
        "y" : 6
      }
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.5562744,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 130.98538208007812,
    "integerResult" : 130985 
  }
}, {
  "round" : {
    "towers" : 10,
    "claps" : 2,
    "board" : {
      "width" : 16,
      "height" : 18,
      "staticTowers" : [ {
        "coord" : {
          "x" : 8,
          "y" : 6
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 5,
          "y" : 3
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 6,
          "y" : 16
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 8
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 0,
          "y" : 14
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 5,
          "y" : 8
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 13,
          "y" : 11
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 1,
          "y" : 10
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 4,
          "y" : 1
        },
        "static" : true,
        "clap" : false
      } ],
      "startArea" : [ {
        "x" : 8,
        "y" : 18
      }, {
        "x" : 7,
        "y" : 18
      } ],
      "endArea" : [ {
        "x" : 8,
        "y" : -1
      }, {
        "x" : 7,
        "y" : -1
      } ],
      "waypoint" : undefined
    }
  },
  "layout" : {
    "towers" : [ {
      "coord" : {
        "x" : 1,
        "y" : 4
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 3,
        "y" : 12
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 10
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 5
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 7,
        "y" : 8
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 8,
        "y" : 15
      },
      "static" : false,
      "clap" : true
    }, {
      "coord" : {
        "x" : 10,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 10,
        "y" : 13
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 12,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 12,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    } ]
  },
  "before" : {
    "sections" : [ {
      "destination" : {
        "x" : 8.0,
        "y" : 18.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 15
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.443729,
        "y" : 12.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 15
      }
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 7.8000946
      },
      "clapCoord" : {
        "x" : 1,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 89.22966003417969,
    "integerResult" : 89230
  },
  "after" : {
    "sections" : [ {
      "destination" : {
        "x" : 8.0,
        "y" : 18.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 15
      }
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.443729,
        "y" : 12.0
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 15
      }
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.9214637,
        "y" : 8.078536
      },
      "clapCoord" : {
        "x" : 1,
        "y" : 4
      }
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 89.12641143798828,
    "integerResult" : 89126
  }
}, {
  "round" : {
    "towers" : 23,
    "claps" : 0,
    "board" : {
      "width" : 16,
      "height" : 18,
      "staticTowers" : [ {
        "coord" : {
          "x" : 8,
          "y" : 2
        },
        "static" : true,
        "clap" : true
      }, {
        "coord" : {
          "x" : 0,
          "y" : 4
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 13,
          "y" : 6
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 14,
          "y" : 14
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 4,
          "y" : 10
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 10,
          "y" : 12
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 12,
          "y" : 2
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 5,
          "y" : 0
        },
        "static" : true,
        "clap" : false
      }, {
        "coord" : {
          "x" : 3,
          "y" : 1
        },
        "static" : true,
        "clap" : false
      } ],
      "startArea" : [ {
        "x" : 16,
        "y" : 16
      }, {
        "x" : 16,
        "y" : 17
      } ],
      "endArea" : [ {
        "x" : 1,
        "y" : -1
      }, {
        "x" : 2,
        "y" : -1
      } ],
      "waypoint" : undefined
    }
  },
  "layout" : {
    "towers" : [ {
      "coord" : {
        "x" : 1,
        "y" : 1
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 1,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 1,
        "y" : 11
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 4
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 2,
        "y" : 13
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 3,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 3,
        "y" : 16
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 4,
        "y" : 4
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 7
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 5,
        "y" : 14
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 3
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 6,
        "y" : 12
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 7,
        "y" : 6
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 7,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 8,
        "y" : 12
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 9,
        "y" : 5
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 10,
        "y" : 10
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 10,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 0
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 11,
        "y" : 4
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 12,
        "y" : 9
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 12,
        "y" : 15
      },
      "static" : false,
      "clap" : false
    }, {
      "coord" : {
        "x" : 13,
        "y" : 12
      },
      "static" : false,
      "clap" : false
    } ]
  },
  "before" : {
    "sections" : [ {
      "destination" : {
        "x" : 16.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 7.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 5.125
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 2
      }
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 4.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 4.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 2.875
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 101.30056762695312,
    "integerResult" : 101301
  },
  "after" : {
    "sections" : [ {
      "destination" : {
        "x" : 16.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 15.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 17.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 14.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 12.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 14.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 11.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 9.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 11.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 9.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 3.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 12.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 4.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 15.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 13.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 8.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 2.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 6.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 5.125
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 2
      }
    }, {
      "destination" : {
        "x" : 6.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 5.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 8.0,
        "y" : 4.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 4.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 10.0,
        "y" : 1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 1.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 7.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 2.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 2.875
      },
      "clapCoord" : {
        "x" : 8,
        "y" : 2
      }
    }, {
      "destination" : {
        "x" : 5.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 3.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 0.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : 0.0
      },
      "clapCoord" : undefined
    }, {
      "destination" : {
        "x" : 1.0,
        "y" : -1.0
      },
      "clapCoord" : undefined
    } ],
    "result" : 111.42556762695312,
    "integerResult" : 111426
  }
} ]

export const DiffTest = () => {

  const [diffIndex, setDiffIndex] = useState(0);

  const currentScoreReference = useRef(0);
  const [pause, setPause] = useState(false);

  const [forcedScore, setForcedScore] = useState(0);
  return (
    <Grid container width={1} justifyContent={"center"}>
      {diffs.length > 0 && lt(diffs[diffIndex], ({ round, layout, before, after }) => (
        <Grid container width={1200}>
          <Box width={600} height={(600 * 19.5) / 17}>
            <Game
              round={round}
              solutionLayout={layout}
              path={before}
              maxScore={after.result}
              setScore={(s) => {
                currentScoreReference.current = s;
              }}
              pause={pause}
              score={forcedScore}
            />
          </Box>
          <Box width={600} height={(600 * 19.5) / 17}>
            <Game
              round={round}
              solutionLayout={layout}
              path={after}
              maxScore={after.result}
              pause={pause}
              score={forcedScore}
            />
          </Box>
          <Grid xs={12}>
            <ScoreSlider
              max={after.result}
              ballCosmetics={defaultCosmetics.ball}
              scoreReference={currentScoreReference}
              completed={false}
              pause={() => setPause(true)}
              unPause={() => setPause(false)}
              setScore={setForcedScore}
              path={after}
            />
          </Grid>
        </Grid>
      ))}
      <Grid xs={12}>
        <Button
          onClick={() => setDiffIndex((d) => (d === 0 ? 0 : d - 1))}
          disabled={diffIndex === 0}
        >
          Prev
        </Button>
        <Button
          onClick={() =>
            setDiffIndex((d) =>
              d === diffs.length - 1 ? diffs.length - 1 : d + 1
            )
          }
          disabled={diffIndex === diffs.length - 1}
        >
          next
        </Button>
      </Grid>
    </Grid>
  );
};
