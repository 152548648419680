import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { lt } from "../../utils/lt";

export const useSize = (
  target: React.MutableRefObject<any>,
  startingTileSize: number,
  transform: (domRect: DOMRectReadOnly) => number
) => {
  const [size, setSize] = React.useState<number>(startingTileSize);

  useResizeObserver(target, (entry) =>
    setSize((s) =>
      lt(transform(entry.contentRect), (newS) => (newS === s ? s : newS))
    )
  );
  return size;
};
