import { useMemo } from "react";
import { Board } from "../../shared/Board";
import { TilingSpriteProps } from "./useEntryZone";

export const borderBottomTilingSpriteProps = (board: Board) => {
  const inZone = (x: number, y: number) =>
    board.endArea.some((c) => c.x === x && c.y === y) ||
    board.startArea.some((c) => c.x === x && c.y === y);
  return [
    ...(!inZone(board.width - 1, -1) &&
    !inZone(board.width - 2, 0) &&
    !inZone(board.width, 0)
      ? [
          {
            texture: "innerCornerBottomSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: board.width,
            y: 3 / 4,
          },
        ]
      : []),
    ...(!inZone(-1, -1) && !inZone(-1, 0) && !inZone(0, -1)
      ? [
          {
            texture: "innerCornerBottomSW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: 1 / 4,
            y: 3 / 4,
          },
        ]
      : []),
    ...(!inZone(board.width - 1, board.height - 1) &&
    !inZone(board.width - 1, board.height - 0) &&
    !inZone(board.width, board.height - 1)
      ? [
          {
            texture: "innerCornerBottomNE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: board.width,
            y: board.height + 1 / 2,
          },
        ]
      : []),
    ...(!inZone(-1, board.height - 1) &&
    !inZone(0, board.height - 0) &&
    !inZone(-1, board.height - 1)
      ? [
          {
            texture: "innerCornerBottomNW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: 1 / 4,
            y: board.height + 1 / 2,
          },
        ]
      : []),
    {
      texture: "sideBottom4N.png",
      width: board.width - 1,
      height: 3 / 4,
      y: board.height + 1 / 2,
      x: 1,
    },
    {
      texture: "sideBottom4S.png",
      width: board.width - 1,
      height: 3 / 4,
      y: 3 / 4,
      x: 1,
    },
    {
      texture: "sideBottom4E.png",
      width: 3 / 4,
      height: board.height - 1,
      y: 6 / 4,
      x: 1 / 4,
    },
    {
      texture: "sideBottom4W.png",
      width: 3 / 4,
      height: board.height - 1,
      x: board.width,
      y: 6 / 4,
    },
  ] as TilingSpriteProps[];
};

export const borderTopTilingSpriteProps = (board: Board, topTint: number) => {
  const inZone = (x: number, y: number) =>
    board.endArea.some((c) => c.x === x && c.y === y) ||
    board.startArea.some((c) => c.x === x && c.y === y);
  let horizontal = [...Array(board.width - 1)];
  let vertical = [...Array(board.height - 1)];

  return [
    ...horizontal.flatMap((_, x) =>
      !inZone(x + 1, -1) && !inZone(x, -1)
        ? [
            {
              texture: "sideTop4N.png",
              width: 1,
              height: 1 / 4,
              y: 0,
              x: x + 1,
              tint: topTint,
            },
            {
              texture: "topGap.png",
              width: 1,
              height: 1 / 2,
              x: x + 1,
              y: 1 / 4,
              tint: topTint,
            },
            {
              texture: "sideTop4S.png",
              width: 1,
              height: 1 / 4,
              y: 3 / 4,
              x: x + 1,
              tint: topTint,
            },
          ]
        : []
    ),
    ...horizontal.flatMap((_, x) =>
      !inZone(x + 1, board.height) && !inZone(x, board.height)
        ? [
            {
              texture: "sideTop4N.png",
              width: 1,
              height: 1 / 4,
              y: board.height + 1,
              x: x + 1,
              tint: topTint,
            },
            {
              texture: "sideTop4S.png",
              width: 1,
              height: 1 / 4,
              y: board.height + 5 / 4,
              x: x + 1,
              tint: topTint,
            },
          ]
        : []
    ),
    ...vertical.flatMap((_, y) =>
      !inZone(board.width, y + 1) && !inZone(board.width, y)
        ? [
            {
              texture: "sideTop4E.png",
              width: 1 / 4,
              height: 1,
              x: board.width + 3 / 4,
              y: y + 3 / 2,
              tint: topTint,
            },
            {
              texture: "sideTop4W.png",
              width: 1 / 4,
              height: 1,
              x: board.width + 1 / 2,
              y: y + 3 / 2,
              tint: topTint,
            },
          ]
        : []
    ),
    ...(inZone(board.width - 1, board.height) &&
    inZone(board.width - 2, board.height)
      ? [
          {
            texture: "sideTop4E.png",
            width: 1 / 4,
            height: 1 / 2,
            y: board.height + 1 / 2,
            x: board.width + 3 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4W.png",
            width: 1 / 4,
            height: 1 / 2,
            x: board.width + 1 / 2,
            y: board.height + 1 / 2,
            tint: topTint,
          },
        ]
      : []),
    ...vertical.flatMap((_, y) =>
      !inZone(-1, y + 1) && !inZone(-1, y)
        ? [
            {
              texture: "sideTop4E.png",
              width: 1 / 4,
              height: 1,
              y: y + 3 / 2,
              x: 1 / 4,
              tint: topTint,
            },
            {
              texture: "sideTop4W.png",
              width: 1 / 4,
              height: 1,
              x: 0,
              y: y + 3 / 2,
              tint: topTint,
            },
          ]
        : []
    ),
    ...(inZone(0, board.height) && inZone(1, board.height)
      ? [
          {
            texture: "sideTop4E.png",
            width: 1 / 4,
            height: 1 / 2,
            y: board.height + 1 / 2,
            x: 1 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4W.png",
            width: 1 / 4,
            height: 1 / 2,
            x: 0,
            y: board.height + 1 / 2,
            tint: topTint,
          },
        ]
      : []),

    ...(!inZone(board.width - 1, board.height - 1) &&
    !inZone(board.width - 1, board.height - 0) &&
    !inZone(board.width, board.height - 1)
      ? [
          {
            texture: "innerCornerTopNW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: board.width,
            y: board.height + 1 / 2,
            tint: topTint,
          },
          {
            texture: "sideTop4E.png",
            width: 1 / 4,
            height: 3 / 4,
            x: board.width + 3 / 4,
            y: board.height + 2 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4S.png",
            width: 3 / 4,
            height: 1 / 4,
            x: board.width,
            y: board.height + 5 / 4,
            tint: topTint,
          },
          {
            texture: "outerCornerTopSE.png",
            width: 1 / 4,
            height: 1 / 4,
            x: board.width + 3 / 4,
            y: board.height + 5 / 4,
            tint: topTint,
          },
        ]
      : []),

    ...(!inZone(-1, board.height - 1) &&
    !inZone(0, board.height - 0) &&
    !inZone(-1, board.height - 1)
      ? [
          {
            texture: "innerCornerTopNE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: 1 / 4,
            y: board.height + 1 / 2,
            tint: topTint,
          },
          {
            texture: "sideTop4W.png",
            width: 1 / 4,
            height: 3 / 4,
            x: 0,
            y: board.height + 2 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4S.png",
            width: 3 / 4,
            height: 1 / 4,
            x: 1 / 4,
            y: board.height + 5 / 4,
            tint: topTint,
          },
          {
            texture: "outerCornerTopSW.png",
            width: 1 / 4,
            height: 1 / 4,
            x: 0,
            y: board.height + 5 / 4,
            tint: topTint,
          },
        ]
      : []),

    ...(!inZone(board.width - 1, -1) &&
    !inZone(board.width - 2, 0) &&
    !inZone(board.width, 0)
      ? [
          {
            texture: "innerCornerTopSW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: board.width,
            y: 3 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4N.png",
            width: 3 / 4,
            height: 1 / 4,
            y: 0,
            x: board.width,
            tint: topTint,
          },
          {
            texture: "sideTop4E.png",
            width: 1 / 4,
            height: 5 / 4,
            x: board.width + 3 / 4,
            y: 1 / 4,
            tint: topTint,
          },
          {
            texture: "topGap.png",
            width: 3 / 4,
            height: 1 / 2,
            x: board.width,
            y: 1 / 4,
            tint: topTint,
          },
          {
            texture: "outerCornerTopNE.png",
            width: 1 / 4,
            height: 1 / 4,
            x: board.width + 3 / 4,
            y: 0,
            tint: topTint,
          },
        ]
      : []),
    ...(!inZone(-1, -1) && !inZone(-1, 0) && !inZone(0, -1)
      ? [
          {
            texture: "outerCornerTopNW.png",
            width: 1 / 4,
            height: 1 / 4,
            x: 0,
            y: 0,
            tint: topTint,
          },
          {
            texture: "sideTop4N.png",
            width: 3 / 4,
            height: 1 / 4,
            y: 0,
            x: 1 / 4,
            tint: topTint,
          },
          {
            texture: "sideTop4W.png",
            width: 1 / 4,
            height: 5 / 4,
            x: 0,
            y: 1 / 4,
            tint: topTint,
          },
          {
            texture: "topGap.png",
            width: 3 / 4,
            height: 1 / 2,
            x: 1 / 4,
            y: 1 / 4,
            tint: topTint,
          },
          {
            texture: "innerCornerTopSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: 1 / 4,
            y: 3 / 4,
            tint: topTint,
          },
        ]
      : []),
  ] as TilingSpriteProps[];
};

export const useBorders = (board: Board, topTint: number) => {
  const borderBottoms: TilingSpriteProps[] = useMemo(
    () => borderBottomTilingSpriteProps(board),
    [board]
  );

  const borderTops: TilingSpriteProps[] = useMemo(
    () => borderTopTilingSpriteProps(board, topTint),
    [board, topTint]
  );

  return { borderTops, borderBottoms };
};
