import React from "react";
import { Chip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { green, lightBlue, red } from "@mui/material/colors";
import { ServeStatus } from "../../shared/Serve";

interface ServeStatusElementProps {
  serveStatus: ServeStatus;
}


interface StatusChipProps {
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  innerColor: string;
  value: number;
  label: string;
}

const StatusChip = (props: StatusChipProps) => {
  const { color, innerColor, value, label } = props;

  return (
    <Chip
      sx={{ m: 1 }}
      icon={
        <Chip
          size="small"
          sx={{ bgcolor: innerColor }}
          label={
            <Typography variant="caption" align="left">
              {value}
            </Typography>
          }
        />
      }
      label={<Typography variant="caption">{label}</Typography>}
      color={color}
    />
  );
};

export const ServeStatusElement = (props: ServeStatusElementProps) => {
  const { serveStatus } = props;
  return (
    <Box>
      <StatusChip
        color="success"
        label="Wins"
        innerColor={green[700]}
        value={serveStatus.wins}
      />
      <StatusChip
        color="error"
        label="Losses"
        innerColor={red[800]}
        value={serveStatus.losses}
      />
      <StatusChip
        color="primary"
        label="Ties"
        innerColor={lightBlue[700]}
        value={serveStatus.ties}
      />
    </Box>
  );
};
