import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import { InteractiveGame } from "./elements/InteractiveGame";
import { Round } from "../shared/Round";
import { Layout } from "../shared/Layout";
import { tintToRGB } from "../utils/colors";
import { Cosmetics, BlockColor, defaultBall, Rarity, Pattern } from "../shared/Cosmetics";
import { lt } from "../utils/lt";
import {
  commonTops,
  legendaryTops,
  rareTops,
  tops,
  uncommonTops,
} from "../utils/chosentops";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
const round: Round = {
  towers: 0,
  claps: 0,
  board: {
    width: 16,
    height: 18,
    staticTowers: [
      {
        coord: {
          x: 9,
          y: 3,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 0,
          y: 3,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 13,
          y: 13,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 4,
          y: 11,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 6,
          y: 13,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 11,
          y: 12,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 8,
          y: 0,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 9,
          y: 13,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 10,
          y: 1,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 3,
          y: 13,
        },
        static: true,
        clap: true,
      },
      {
        coord: {
          x: 0,
          y: 10,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 7,
          y: 5,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 10,
          y: 9,
        },
        static: true,
        clap: false,
      },
      {
        coord: {
          x: 5,
          y: 5,
        },
        static: true,
        clap: false,
      },
    ],
    startArea: [
      {
        x: 1,
        y: 18,
      },
      {
        x: 0,
        y: 18,
      },
    ],
    endArea: [
      {
        x: 16,
        y: 7,
      },
      {
        x: 16,
        y: 8,
      },
    ],
  },
};

const layout: Layout = {
  towers: [
    {
      coord: {
        x: 8,
        y: 8,
      },
      clap: true,
      static: false,
    },
    {
      coord: {
        x: 7,
        y: 16,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 3,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 2,
        y: 6,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 6,
        y: 8,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 12,
        y: 9,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 15,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 1,
        y: 16,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 1,
        y: 12,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 4,
        y: 15,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 14,
        y: 10,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 4,
        y: 8,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 1,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 10,
        y: 6,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 12,
        y: 5,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 7,
        y: 11,
      },
      clap: false,
      static: false,
    },
  ],
};

const possibleExits = [
  [
    {
      x: 0,
      y: -1,
    },
    {
      x: 1,
      y: -1,
    },
  ],
  [
    {
      x: 1,
      y: -1,
    },
    {
      x: 2,
      y: -1,
    },
  ],
  [
    {
      x: 2,
      y: -1,
    },
    {
      x: 3,
      y: -1,
    },
  ],
  [
    {
      x: 3,
      y: -1,
    },
    {
      x: 4,
      y: -1,
    },
  ],
  [
    {
      x: 4,
      y: -1,
    },
    {
      x: 5,
      y: -1,
    },
  ],
  [
    {
      x: 5,
      y: -1,
    },
    {
      x: 6,
      y: -1,
    },
  ],
  [
    {
      x: 6,
      y: -1,
    },
    {
      x: 7,
      y: -1,
    },
  ],
  [
    {
      x: 7,
      y: -1,
    },
    {
      x: 8,
      y: -1,
    },
  ],
  [
    {
      x: 8,
      y: -1,
    },
    {
      x: 9,
      y: -1,
    },
  ],
  [
    {
      x: 9,
      y: -1,
    },
    {
      x: 10,
      y: -1,
    },
  ],
  [
    {
      x: 10,
      y: -1,
    },
    {
      x: 11,
      y: -1,
    },
  ],
  [
    {
      x: 11,
      y: -1,
    },
    {
      x: 12,
      y: -1,
    },
  ],
  [
    {
      x: 12,
      y: -1,
    },
    {
      x: 13,
      y: -1,
    },
  ],
  [
    {
      x: 13,
      y: -1,
    },
    {
      x: 14,
      y: -1,
    },
  ],
  [
    {
      x: 14,
      y: -1,
    },
    {
      x: 15,
      y: -1,
    },
  ],
  [
    {
      x: 15,
      y: -1,
    },
    {
      x: 16,
      y: -1,
    },
    {
      x: 16,
      y: 0,
    },
  ],
  [
    {
      x: 16,
      y: 0,
    },
    {
      x: 16,
      y: 1,
    },
  ],
  [
    {
      x: 16,
      y: 1,
    },
    {
      x: 16,
      y: 2,
    },
  ],
  [
    {
      x: 16,
      y: 2,
    },
    {
      x: 16,
      y: 3,
    },
  ],
  [
    {
      x: 16,
      y: 3,
    },
    {
      x: 16,
      y: 4,
    },
  ],
  [
    {
      x: 16,
      y: 4,
    },
    {
      x: 16,
      y: 5,
    },
  ],
  [
    {
      x: 16,
      y: 5,
    },
    {
      x: 16,
      y: 6,
    },
  ],
  [
    {
      x: 16,
      y: 6,
    },
    {
      x: 16,
      y: 7,
    },
  ],
  [
    {
      x: 16,
      y: 7,
    },
    {
      x: 16,
      y: 8,
    },
  ],
  [
    {
      x: 16,
      y: 8,
    },
    {
      x: 16,
      y: 9,
    },
  ],
  [
    {
      x: 16,
      y: 9,
    },
    {
      x: 16,
      y: 10,
    },
  ],
  [
    {
      x: 16,
      y: 10,
    },
    {
      x: 16,
      y: 11,
    },
  ],
  [
    {
      x: 16,
      y: 11,
    },
    {
      x: 16,
      y: 12,
    },
  ],
  [
    {
      x: 16,
      y: 12,
    },
    {
      x: 16,
      y: 13,
    },
  ],
  [
    {
      x: 16,
      y: 13,
    },
    {
      x: 16,
      y: 14,
    },
  ],
  [
    {
      x: 16,
      y: 14,
    },
    {
      x: 16,
      y: 15,
    },
  ],
  [
    {
      x: 16,
      y: 15,
    },
    {
      x: 16,
      y: 16,
    },
  ],
  [
    {
      x: 16,
      y: 16,
    },
    {
      x: 16,
      y: 17,
    },
  ],
  [
    {
      x: 16,
      y: 17,
    },
    {
      x: 16,
      y: 18,
    },
    {
      x: 15,
      y: 18,
    },
  ],
  [
    {
      x: 15,
      y: 18,
    },
    {
      x: 14,
      y: 18,
    },
  ],
  [
    {
      x: 14,
      y: 18,
    },
    {
      x: 13,
      y: 18,
    },
  ],
  [
    {
      x: 13,
      y: 18,
    },
    {
      x: 12,
      y: 18,
    },
  ],
  [
    {
      x: 12,
      y: 18,
    },
    {
      x: 11,
      y: 18,
    },
  ],
  [
    {
      x: 11,
      y: 18,
    },
    {
      x: 10,
      y: 18,
    },
  ],
  [
    {
      x: 10,
      y: 18,
    },
    {
      x: 9,
      y: 18,
    },
  ],
  [
    {
      x: 9,
      y: 18,
    },
    {
      x: 8,
      y: 18,
    },
  ],
  [
    {
      x: 8,
      y: 18,
    },
    {
      x: 7,
      y: 18,
    },
  ],
  [
    {
      x: 7,
      y: 18,
    },
    {
      x: 6,
      y: 18,
    },
  ],
  [
    {
      x: 6,
      y: 18,
    },
    {
      x: 5,
      y: 18,
    },
  ],
  [
    {
      x: 5,
      y: 18,
    },
    {
      x: 4,
      y: 18,
    },
  ],
  [
    {
      x: 4,
      y: 18,
    },
    {
      x: 3,
      y: 18,
    },
  ],
  [
    {
      x: 3,
      y: 18,
    },
    {
      x: 2,
      y: 18,
    },
  ],
  [
    {
      x: 2,
      y: 18,
    },
    {
      x: 1,
      y: 18,
    },
  ],
  [
    {
      x: 1,
      y: 18,
    },
    {
      x: 0,
      y: 18,
    },
  ],
  [
    {
      x: -1,
      y: 17,
    },
    {
      x: -1,
      y: 18,
    },
    {
      x: 0,
      y: 18,
    },
  ],
  [
    {
      x: -1,
      y: 17,
    },
    {
      x: -1,
      y: 16,
    },
  ],
  [
    {
      x: -1,
      y: 16,
    },
    {
      x: -1,
      y: 15,
    },
  ],
  [
    {
      x: -1,
      y: 15,
    },
    {
      x: -1,
      y: 14,
    },
  ],
  [
    {
      x: -1,
      y: 14,
    },
    {
      x: -1,
      y: 13,
    },
  ],
  [
    {
      x: -1,
      y: 13,
    },
    {
      x: -1,
      y: 12,
    },
  ],
  [
    {
      x: -1,
      y: 12,
    },
    {
      x: -1,
      y: 11,
    },
  ],
  [
    {
      x: -1,
      y: 11,
    },
    {
      x: -1,
      y: 10,
    },
  ],
  [
    {
      x: -1,
      y: 10,
    },
    {
      x: -1,
      y: 9,
    },
  ],
  [
    {
      x: -1,
      y: 9,
    },
    {
      x: -1,
      y: 8,
    },
  ],
  [
    {
      x: -1,
      y: 8,
    },
    {
      x: -1,
      y: 7,
    },
  ],
  [
    {
      x: -1,
      y: 7,
    },
    {
      x: -1,
      y: 6,
    },
  ],
  [
    {
      x: -1,
      y: 6,
    },
    {
      x: -1,
      y: 5,
    },
  ],
  [
    {
      x: -1,
      y: 5,
    },
    {
      x: -1,
      y: 4,
    },
  ],
  [
    {
      x: -1,
      y: 4,
    },
    {
      x: -1,
      y: 3,
    },
  ],
  [
    {
      x: -1,
      y: 3,
    },
    {
      x: -1,
      y: 2,
    },
  ],
  [
    {
      x: -1,
      y: 2,
    },
    {
      x: -1,
      y: 1,
    },
  ],
  [
    {
      x: -1,
      y: 1,
    },
    {
      x: -1,
      y: 0,
    },
  ],
  [
    {
      x: -1,
      y: 0,
    },
    {
      x: -1,
      y: -1,
    },
    {
      x: 0,
      y: -1,
    },
  ],
];

export const CosmeticTest = () => {
  const [roundWithArea, setRoundWithArea] = useState<Round>(round);

  const [legendary, setLegendary] = useState<BlockColor[]>(
    legendaryTops.map((t) => ({ rarity: Rarity.LEGENDARY, ...t }))
  );
  const [rare, setRare] = useState<BlockColor[]>(
    rareTops.map((t) => ({ rarity: Rarity.RARE, ...t }))
  );
  const [uncommon, setUncommon] = useState<BlockColor[]>(
    uncommonTops.map((t) => ({ rarity: Rarity.UNCOMMON, ...t }))
  );
  const [common, setCommon] = useState<BlockColor[]>(
    commonTops.map((t) => ({ rarity: Rarity.COMMON, ...t }))
  );

  const [topA, setTopA] = useState(common[0]);
  const [topB, setTopB] = useState(common[1]);

  const [aLastSet, setALastSet] = useState(true);

  const setColor = useCallback(
    (topColor: BlockColor) => {
      if (aLastSet) {
        setALastSet(false);
        setTopB(topColor);
      } else {
        setALastSet(true);
        setTopA(topColor);
      }
    },
    [setTopA, setTopB, setALastSet, aLastSet]
  );

  useEffect(() => {
    let len = 0;
    tops.forEach((top) => {
      if (top.color.tint > len) {
        len = top.color.tint;
      }
    });
    let valueString = "";
    common.forEach(
      (t) =>
        (valueString +=
          '("' +
          t.color.name +
          '",' +
          t.indentTint +
          "," +
          t.color.tint +
          ",0),\n")
    );
    uncommon.forEach(
      (t) =>
        (valueString +=
          '("' +
          t.color.name +
          '",' +
          t.indentTint +
          "," +
          t.color.tint +
          ",1),\n")
    );
    rare.forEach(
      (t) =>
        (valueString +=
          '("' +
          t.color.name +
          '",' +
          t.indentTint +
          "," +
          t.color.tint +
          ",2),\n")
    );
    legendary.forEach(
      (t) =>
        (valueString +=
          '("' +
          t.color.name +
          '",' +
          t.indentTint +
          "," +
          t.color.tint +
          ",3),\n")
    );
  }, [legendary, rare, uncommon, common]);

  const bumpDown = useCallback(
    (topColor: BlockColor) => {
      let index = legendary.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        legendary.splice(index, 1);
        setLegendary(legendary);
        setRare([topColor, ...rare]);
        return;
      }
      index = rare.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        rare.splice(index, 1);
        setRare(rare);
        setUncommon([topColor, ...uncommon]);
        return;
      }
      index = uncommon.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        uncommon.splice(index, 1);
        setUncommon(uncommon);
        setCommon([topColor, ...common]);
        return;
      }
    },
    [
      legendary,
      rare,
      uncommon,
      common,
      setLegendary,
      setRare,
      setUncommon,
      setCommon,
    ]
  );

  const bumpUp = useCallback(
    (topColor: BlockColor) => {
      let index = common.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        common.splice(index, 1);
        setCommon(common);
        setUncommon([topColor, ...uncommon]);
        return;
      }
      index = uncommon.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        uncommon.splice(index, 1);
        setUncommon(uncommon);
        setRare([topColor, ...rare]);
        return;
      }
      index = rare.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        rare.splice(index, 1);
        setRare(rare);
        setLegendary([topColor, ...legendary]);
        return;
      }
    },
    [
      legendary,
      rare,
      uncommon,
      common,
      setLegendary,
      setRare,
      setUncommon,
      setCommon,
    ]
  );

  const remove = useCallback(
    (topColor: BlockColor) => {
      let index = common.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        common.splice(index, 1);
        setCommon([...common]);
        return;
      }
      index = uncommon.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        uncommon.splice(index, 1);
        setUncommon([...uncommon]);
        return;
      }
      index = rare.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        rare.splice(index, 1);
        setRare([...rare]);
        return;
      }
      index = legendary.findIndex(
        (other) => other.color.name === topColor.color.name
      );
      if (index !== -1) {
        legendary.splice(index, 1);
        setLegendary([...legendary]);
        return;
      }
    },
    [
      legendary,
      rare,
      uncommon,
      common,
      setLegendary,
      setRare,
      setUncommon,
      setCommon,
    ]
  );

  const cosmeticsA = useMemo<Cosmetics>(
    () => ({
      block: { pattern: { pattern: Pattern.SOLID, firstColor: topA } },
      ball: { color: defaultBall },
    }),
    [topA]
  );
  const cosmeticsB = useMemo<Cosmetics>(
    () => ({
      block: { pattern: { pattern: Pattern.SOLID, firstColor: topB } },
      ball: { color: defaultBall },
    }),
    [topB]
  );

  return (
    <Box sx={{ pt: 0 }} maxWidth="lg" mx="auto" height="90vh">
      <Grid container>
        <Grid xs={6} height="60vh">
          <InteractiveGame
            round={roundWithArea}
            startingLayout={layout}
            cosmetics={cosmeticsA}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{
              bgcolor: lt(
                tintToRGB(cosmeticsA.block.pattern.firstColor.color.tint),
                (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
              ),
            }}
          >
            {cosmeticsA.block.pattern.firstColor.color.name}
          </Button>
        </Grid>
        <Grid xs={6} height="60vh">
          <InteractiveGame
            round={roundWithArea}
            startingLayout={layout}
            cosmetics={cosmeticsB}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{
              bgcolor: lt(
                tintToRGB(cosmeticsB.block.pattern.firstColor.color.tint),
                (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
              ),
            }}
          >
            {cosmeticsB.block.pattern.firstColor.color.name}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mt: 5 }}>
        <Typography sx={{ pt: 2 }}>Legendary</Typography>
        <Grid container>
          {legendary.map((topColor) => (
            <Grid
              item
              xs={1}
              sx={{
                bgcolor: lt(
                  tintToRGB(topColor.color.tint),
                  (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
                ),
              }}
            >
              <IconButton
                onClick={() => remove(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography color="black">{topColor.color.name}</Typography>
              <IconButton
                onClick={() => bumpUp(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => setColor(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() => bumpDown(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ pt: 2 }}>Rare</Typography>
        <Grid container>
          {rare.map((topColor) => (
            <Grid
              item
              xs={1}
              sx={{
                bgcolor: lt(
                  tintToRGB(topColor.color.tint),
                  (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
                ),
              }}
            >
              <IconButton
                onClick={() => remove(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography color="black">{topColor.color.name}</Typography>
              <IconButton
                onClick={() => bumpUp(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => setColor(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() => bumpDown(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ pt: 2 }}>Uncommon</Typography>
        <Grid container>
          {uncommon.map((topColor) => (
            <Grid
              item
              xs={1}
              sx={{
                bgcolor: lt(
                  tintToRGB(topColor.color.tint),
                  (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
                ),
              }}
            >
              <IconButton
                onClick={() => remove(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography color="black">{topColor.color.name}</Typography>
              <IconButton
                onClick={() => bumpUp(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => setColor(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() => bumpDown(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ pt: 2 }}>Common</Typography>
        <Grid container>
          {common.map((topColor) => (
            <Grid
              item
              xs={1}
              sx={{
                bgcolor: lt(
                  tintToRGB(topColor.color.tint),
                  (rgb) => "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")"
                ),
              }}
            >
              <IconButton
                onClick={() => remove(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography color="black">{topColor.color.name}</Typography>
              <IconButton
                onClick={() => bumpUp(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => setColor(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() => bumpDown(topColor)}
                sx={{ color: "black", mx: 0, px: 0 }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Slider
        defaultValue={0}
        valueLabelDisplay="auto"
        step={1}
        sx={{ display: "none" }}
        onChange={(_, entryIndex) =>
          !Array.isArray(entryIndex) &&
          setRoundWithArea((r) => ({
            ...r,
            board: {
              ...r.board,
              startArea: possibleExits[entryIndex],
              endArea: possibleExits[(entryIndex + 33) % possibleExits.length],
            },
          }))
        }
        marks
        min={0}
        max={possibleExits.length - 1}
      />
    </Box>
  );
};
