import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { cst } from "../utils/constants";
import { useNavigate } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Tooltip,
} from "@mui/material";
import { getChallengeService } from "../network/ChallengeService";
import { GoogleSigninButton } from "./elements/GoogleSigninButton";
import { lt } from "../utils/lt";
import { Player } from "../shared/Player";
import { StatusCodes } from "http-status-codes";
import { CopyInput } from "./elements/CopyInput";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { unselectable } from "../utils/unselectable";
import { BoardSize, boardSizeLabel } from "../shared/Board";

const roundMarks = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
];

const durationMarks = [
  {
    value: 45,
    label: "0:45",
  },
  {
    value: 60,
    label: "1:00",
  },
  {
    value: 75,
    label: "1:15",
  },
  {
    value: 90,
    label: "1:30",
  },
  {
    value: 105,
    label: "1:45",
  },
  {
    value: 120,
    label: "2:00",
  },
];

const deadlineMarks = [
  {
    value: 3,
    label: "3h",
  },
  {
    value: 6,
    label: "6h",
  },
  {
    value: 12,
    label: "12h",
  },
  {
    value: 18,
    label: "18h",
  },
  {
    value: 24,
    label: "24h",
  },
];
interface NewChallengeProps {
  player: Player;
  create: boolean;
}

export const NewChallenge = (props: NewChallengeProps) => {
  const { player, create } = props;
  const navigate = useNavigate();

  const willMount = useRef(true);

  const challengeService = useRef(getChallengeService());

  const [challengeLink, setChallengeLink] = useState<undefined | string>();
  const [challengePath, setChallengePath] = useState<undefined | string>();

  const [duration, setDuration] = useState<number | undefined>(75);

  const [withDuration, setWithDuration] = useState(true);

  const [rounds, setRounds] = useState<number | undefined>(5);

  const [waitTime, setWaitTime] = useState<number>(0);

  const [waypoints, setWaypoints] = useState<boolean>(false);
  const [boardSize, setBoardSize] = useState<BoardSize>(BoardSize.NORMAL);
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [deadline, setDeadline] = useState<number>(3);
  const [withDeadline, setWithDeadline] = useState(true);

  const [publicChallengeExists, setPublicChallengeExists] = useState(false);

  useEffect(() => {
    if (isPublic) {
      setWithDeadline(true);
    }
  }, [isPublic, setWithDeadline]);

  const handleSubmit = useCallback(() => {
    player &&
      challengeService.current
        .start(
          withDuration ? duration : 0,
          rounds,
          withDeadline ? deadline : undefined,
          isPublic,
          waypoints,
          boardSize
        )
        .then((response) => {
          setPublicChallengeExists(false);
          if (!isNaN(response)) {
            setChallengePath("/challenge/" + response);
            setChallengeLink(cst.hostName + "/challenge/" + response);
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status) {
            if (error.response.status === StatusCodes.TOO_MANY_REQUESTS) {
              const wait = Math.ceil(error.response.data / 1000);
              setWaitTime(wait);

              const interval = window.setInterval(() => {
                if (willMount)
                  setWaitTime((current) => (current > 0 ? current - 1 : 0));
              }, 1000);

              window.setTimeout(
                () => window.clearInterval(interval),
                (wait + 5) * 1000
              );
            } else if (error.response.status === StatusCodes.CONFLICT) {
              setPublicChallengeExists(true);
              setIsPublic(false);
            }
          }
        });
  }, [
    player,
    waypoints,
    boardSize,
    setChallengeLink,
    setChallengePath,
    setPublicChallengeExists,
    duration,
    withDuration,
    rounds,
    deadline,
    isPublic,
    setIsPublic,
    withDeadline,
  ]);

  const [open, setOpen] = useState(create);

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen((o) => !o)}
      sx={{
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        "&:before": {
          display: "none",
        },
      }}
      disableGutters
      elevation={2}
    >
      <AccordionSummary
        expandIcon={
          open ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
        }
      >
        <Typography variant={"h6"} width={1} sx={{ ml: 3 }}>
          <b>Create Challenge</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {player && player.guest ? (
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ pt: 2, pb: 2 }}>
              Guest users cannot create new Challenges, click the button below
              to register
            </Typography>
            <GoogleSigninButton
              onClick={() => sessionStorage.removeItem("guestId")}
            />
          </Box>
        ) : player ? (
          <Box component="form" onSubmit={handleSubmit} noValidate width={1}>
            <Paper sx={{ p: 2, my: 2, mt: 3 }} elevation={0}>
              <Typography>Rounds</Typography>
              <Slider
                aria-label="Rounds"
                defaultValue={5}
                step={1}
                valueLabelDisplay="auto"
                value={rounds}
                onChange={(event, value) =>
                  Array.isArray(value) ? {} : setRounds(value)
                }
                marks={roundMarks}
                max={25}
                min={1}
              />
              <Grid container justifyContent={"center"}>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        onClick={() => setBoardSize(BoardSize.SMALL)}
                        checked={boardSize === BoardSize.SMALL}
                      />
                    }
                    sx={unselectable}
                    label={boardSizeLabel[BoardSize.SMALL]}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        onClick={() => setBoardSize(BoardSize.NORMAL)}
                        checked={boardSize === BoardSize.NORMAL}
                      />
                    }
                    sx={unselectable}
                    label={boardSizeLabel[BoardSize.NORMAL]}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        onClick={() => setBoardSize(BoardSize.LARGE)}
                        checked={boardSize === BoardSize.LARGE}
                      />
                    }
                    sx={unselectable}
                    label={boardSizeLabel[BoardSize.LARGE]}
                  />
                </RadioGroup>
              </Grid>
              <Tooltip
                title={
                  <Typography textAlign="center">
                    The rounds will have a <b>waypoint</b> that the <b>Ball</b>{" "}
                    must roll through before reaching the exit
                  </Typography>
                }
              >
                <Typography
                  mt={2}
                  onClick={() => setWaypoints((c) => !c)}
                  sx={{ cursor: "pointer", ...unselectable }}
                >
                  <Checkbox checked={waypoints} sx={{ mt: -0.5 }} />
                  With Waypoints
                </Typography>
              </Tooltip>
              <Typography
                sx={{ mt: 2, cursor: "pointer", ...unselectable }}
                onClick={() => setWithDuration((c) => !c)}
              >
                <Checkbox checked={withDuration} sx={{ mt: -0.5 }} />
                Time Limit
              </Typography>
              <Slider
                sx={{ mb: 4 }}
                aria-label="Duration"
                defaultValue={75}
                valueLabelDisplay="auto"
                disabled={!withDuration}
                step={5}
                valueLabelFormat={(value: number) =>
                  "" +
                  Math.floor(value / 60) +
                  ":" +
                  lt(value % 60, (seconds) =>
                    seconds < 10 ? "0" + seconds : seconds
                  )
                }
                value={duration}
                onChange={(event, value) =>
                  Array.isArray(value) ? {} : setDuration(value)
                }
                marks={durationMarks}
                max={150}
                min={10}
              />
              <Typography
                sx={{ cursor: "pointer", ...unselectable, display: "inline" }}
                onClick={() => setIsPublic((c) => !c)}
              >
                <Checkbox
                  checked={isPublic}
                  sx={{ mt: -0.5 }}
                  disabled={publicChallengeExists}
                />
                Public
              </Typography>
              <Typography
                sx={{
                  cursor: isPublic ? undefined : "pointer",
                  ...unselectable,
                  display: "inline",
                }}
                onClick={() => !isPublic && setWithDeadline((c) => !c)}
              >
                <Checkbox
                  checked={withDeadline}
                  sx={{ mt: -0.5 }}
                  disabled={isPublic}
                />
                Deadline
              </Typography>
              <Slider
                aria-label="Deadline"
                defaultValue={3}
                valueLabelDisplay="auto"
                disabled={!withDeadline}
                step={1}
                valueLabelFormat={(value: number) =>
                  value + (value === 1 ? " Hour" : " Hours")
                }
                value={deadline}
                onChange={(_event, value) =>
                  Array.isArray(value) ? {} : setDeadline(value)
                }
                marks={deadlineMarks}
                max={30}
                min={1}
              />
            </Paper>
            {waitTime > 0 && (
              <Alert sx={{ mt: 2 }} variant="outlined" severity="info">
                Too many new challenges, please wait.
              </Alert>
            )}
            {publicChallengeExists && (
              <Alert sx={{ mt: 2 }} variant="outlined" severity="info">
                You can only have one active Public Challenge at a time.
              </Alert>
            )}
            {challengeLink && (
              <Alert
                sx={{ mt: 2 }}
                variant="outlined"
                severity="info"
                icon={<Typography variant="caption">TIP</Typography>}
              >
                <b>Guests</b> do not need to be signed in to play{" "}
                <b>Challenges</b>, but they can only access your{" "}
                <b>Challenge</b> using the direct link below
              </Alert>
            )}
            {challengeLink === undefined && (
              <Button
                onClick={handleSubmit}
                fullWidth
                disabled={
                  duration === 0 ||
                  rounds === 0 ||
                  duration === undefined ||
                  rounds === undefined ||
                  waitTime > 0
                }
                variant="contained"
                sx={{ mt: 3 }}
              >
                {waitTime > 0 ? "Create (" + waitTime + ")" : "Create"}
              </Button>
            )}
            {challengeLink && (
              <Fragment>
                <CopyInput
                  link={challengeLink}
                  label={
                    <div>
                      Send this link to <b>Challenge</b> your friends!
                    </div>
                  }
                />
              </Fragment>
            )}
            {challengePath && (
              <Button
                onClick={() => navigate(challengePath)}
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
              >
                Go!
              </Button>
            )}
          </Box>
        ) : (
          <div />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
