import { Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

interface SortButtonProps {
  active: boolean;
  onClick: (ascending: boolean) => void;
  text: string;
}

export const SortButton = (props: SortButtonProps) => {
  const { active, onClick, text } = props;

  const [ascending, setAscending] = useState<boolean>(false);

  return (
    <Button
      onClick={() => {
        if (active) {
          let asc = ascending;
          setAscending((a) => !a);
          onClick(!asc);
        } else {
          setAscending(false);
          onClick(false);
        }
      }}
      sx={{ textTransform: "none", color:"white"}}
      endIcon={
        !active ? (
          undefined
        ) : !ascending ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )
      }
    >
      {text}
    </Button>
  );
};
