import React, { Fragment, useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { MazePaper } from "./elements/MazePaper";
import { AcademyProgress, isAcademyProgress } from "../shared/Academy";
import { academyService } from "../network/AcademyService";
import { Box, IconButton, Tooltip } from "@mui/material";
import { AcademyProgressElement } from "./elements/AcademyProgressElement";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";

export const Academy = () => {
  const [progress, setProgress] = useState<AcademyProgress>();

  const loadProgress = useCallback(
    () =>
      academyService.progress().then((response) => {
        if (isAcademyProgress(response)) {
          setProgress(response);
        }
      }),
    [setProgress]
  );

  useEffect(() => {
    if (progress === undefined) {
      loadProgress();
    }
  }, [progress, loadProgress]);

  const [openHelp, setOpenHelp] = useState(false);
  const navigate = useNavigate();

  return (
    <Box maxWidth="sm" sx={{ pt: 5, mx: { xs: -0.5, sm: "auto" } }}>
      <MazePaper
        title={
          <Fragment>
            Academy{" "}
            <Tooltip
              enterTouchDelay={0}
              disableTouchListener
              disableFocusListener
              disableHoverListener
              open={openHelp}
              title={
                <Typography>
                  Welcome to the <b>Academy</b>!<br />
                  <br />
                  <b>Academy</b> rounds can be played as many times as you want,
                  and there is no time limit. The objective is simply to find
                  the best solution you can!
                  <br />
                  <br />
                  Try to see how many medals you can get, and beat the very best{" "}
                  <b>maze.game</b> solutions!
                </Typography>
              }
            >
              <IconButton
                sx={{ transform: "translate(0%, -5%)" }}
                onClick={() => setOpenHelp((s) => !s)}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        }
        goBack={() => navigate("/")}
      >
        <AcademyProgressElement academyProgress={progress} />
      </MazePaper>
    </Box>
  );
};
