import { PixiComponent } from "@inlet/react-pixi";
import { Container } from "@pixi/display";
import { LINE_CAP, LINE_JOIN } from "@pixi/graphics";
import { SmoothGraphics } from "@pixi/graphics-smooth";
import { filters } from "pixi.js";
import { AlphaFilter } from "@pixi/filter-alpha";
import { Board } from "../../shared/Board";
import { Path } from "../../shared/Path";
import { cst } from "../../utils/constants";
import { SlowInstance } from "../utils/useRunnerState";

interface PathingLineProps {
  path?: Path;
  board: Board;
  score: number;
  alpha: number;
  slowInstances: SlowInstance[];
}

const min = Math.min;
const max = Math.max;

const round = (v: any) => v;

const clear = (g: SmoothGraphics, width: number, height: number) => {
  g.clear();
  g.beginFill(0xffffff, 0.001);
  g.drawRect(-1, -1.5, width + 1, height + 1.5);
  g.endFill();
};

export default PixiComponent("PathingLine", {
  create: (props: PathingLineProps) => {
    // instantiate something and return it.
    // for instance:
    const { alpha } = props;
    let container = new Container();
    container.scale.x = cst.tileSize;
    container.scale.y = cst.tileSize;
    container.position.x = cst.tileSize;
    container.position.y = (cst.tileSize * 3) / 2;
    let pathingLine = new SmoothGraphics();
    pathingLine.filters = [new filters.AlphaFilter(alpha / 2)];
    container.addChild(pathingLine);
    let instanceFilter = new filters.AlphaFilter(alpha / 3);
    props.slowInstances.forEach(() => {
      let slowLine = new SmoothGraphics();
      slowLine.filters = [instanceFilter];
      slowLine.alpha = 1;
      container.addChild(slowLine);
    });
    return container;
  },

  applyProps: (container, oldProps, newProps) => {
    const { alpha: oldAlpha } = oldProps;
    const { path, score, slowInstances, board, alpha } = newProps;
    if (path === undefined) return;
    let missingSlowLines =
      newProps.slowInstances.length -
      (oldProps.slowInstances ? oldProps.slowInstances.length : 0);
    let instanceFilter = undefined;
    while (missingSlowLines > 0) {
      if (instanceFilter === undefined) {
        instanceFilter = new filters.AlphaFilter(alpha / 3);
      }
      let slowLine = new SmoothGraphics();
      slowLine.filters = [instanceFilter];
      slowLine.alpha = 1;
      container.addChild(slowLine);
      missingSlowLines -= 1;
    }

    let pathingLine = container.getChildAt(0) as SmoothGraphics;
    clear(pathingLine, board.width, board.height);
    let lastSection = path.sections[0];
    pathingLine.moveTo(
      lastSection.destination.x,
      lastSection.destination.y === -1 ? -1.5 : lastSection.destination.y
    );
    pathingLine.alpha = 1;
    pathingLine.lineStyle({
      color: 0,
      width: 1 / 3,
      join: LINE_JOIN.ROUND,
      cap: LINE_CAP.ROUND,
    });
    let index = 1;
    let rollingScore = 0;
    let frostDist = lastSection.clapCoord ? 30 : 0;
    slowInstances.forEach((_, index) => {
      let graphics = container.getChildAt(index + 1) as SmoothGraphics;
      clear(graphics, board.width, board.height);
    });
    if (alpha === 0) return;
    else if (alpha !== oldAlpha) {
      container.children.forEach((it, index) => {
        let filter = it.filters![0] as AlphaFilter;
        filter.alpha = index === 0 ? alpha / 2 : alpha / 3;
      });
    }
    let waypoint = false;
    let slowLines: { slowLine: SmoothGraphics; slowInstance: SlowInstance }[] =
      [];
    while (index < path.sections.length && rollingScore < score) {
      let section = path.sections[index];
      let dx = section.destination.x - lastSection.destination.x;
      let dy = section.destination.y - lastSection.destination.y;
      let dist = Math.sqrt(dy * dy + dx * dx);
      if (
        !waypoint &&
        board.waypoint &&
        lastSection.destination.x === board.waypoint.x &&
        lastSection.destination.y === board.waypoint.y
      ) {
        pathingLine.lineStyle({
          color: 0x990000,
          width: 1 / 6,
          join: LINE_JOIN.ROUND,
          cap: LINE_CAP.ROUND,
        });
        waypoint = true;
      }
      let innerScore = rollingScore;
      let innerWaypoint = waypoint;

      slowLines = slowInstances
        .map((it, index) => ({
          slowInstance: it,
          slowLine: container.getChildAt(index + 1) as SmoothGraphics,
        }))
        .filter(
          ({ slowInstance, slowLine }) =>
            slowInstance.start <= innerScore + 0.00001 &&
            slowInstance.end >= innerScore - 0.00001
        )
        .map(({ slowInstance, slowLine }, index) => {
          if (slowLine.line.width === 0) {
            slowLine.moveTo(
              round(section.destination.x - dx),
              round(section.destination.y - dy)
            );
            slowLine.lineStyle({
              color: innerWaypoint ? 0x7703fc : 0x62c5ef,
              width: 1,
              join: LINE_JOIN.ROUND,
              cap: LINE_CAP.ROUND,
            });
          }
          return { slowInstance, slowLine };
        });
      let flag = false;
      if (frostDist > 0) {
        if (dist * 2 > frostDist) {
          dist = dist + frostDist / 2;
          flag = section.clapCoord !== null && section.clapCoord !== undefined;
        } else {
          dist = dist * 2;
        }
      } else {
        frostDist = 0;
      }
      if (dist + rollingScore > score) {
        let travel = score - rollingScore;
        let frostedTravel = min(travel, frostDist) / travel;
        let nonFrostedTravel = max(0, travel - frostDist) / travel;

        let modifier = flag
          ? (travel / (dist + frostDist / 2)) * frostedTravel +
            (travel / (dist - frostDist / 2)) * nonFrostedTravel
          : travel / dist;
        pathingLine.lineTo(
          round(lastSection.destination.x + dx * modifier),
          round(lastSection.destination.y + dy * modifier)
        );
        let innerLastSection = lastSection;
        let innerFrostDist = frostDist;
        slowLines.forEach(({ slowLine, slowInstance }) => {
          let slowModifier =
            (slowInstance.end - innerScore) /
            (dist + (flag ? innerFrostDist / 2 : 0));
          slowLine.lineTo(
            round(
              innerLastSection.destination.x +
                dx * Math.min(slowModifier, modifier)
            ),
            round(
              innerLastSection.destination.y +
                dy * Math.min(slowModifier, modifier)
            )
          );
        });
        break;
      } else {
        pathingLine.lineTo(
          round(section.destination.x),
          round(section.destination.y)
        );
        let innerFrostDist = frostDist;
        let innerLastSection = lastSection;
        slowLines.forEach(({ slowLine, slowInstance }, index) => {
          let slowModifier =
            (slowInstance.end - innerScore) /
            (flag ? dist + innerFrostDist / 2 : dist);
          slowLine.lineTo(
            round(
              innerLastSection.destination.x + dx * Math.min(slowModifier, 1)
            ),
            round(
              innerLastSection.destination.y + dy * Math.min(slowModifier, 1)
            )
          );
        });
      }
      if (section.clapCoord) {
        frostDist = 30;
      } else if (frostDist > 0) {
        frostDist -= dist;
      }
      lastSection = section;
      index += 1;
      rollingScore += dist;
    }
  },
  config: {
    // destroy instance on unmount?
    // default true
    destroy: true,

    /// destroy its children on unmount?
    // default true
    destroyChildren: true,
  },
});
