import { Round } from "./Round";
import { PlayerSolution } from "./Solution";

export type ServeStatus = {
  wins: number;
  losses: number;
  ties: number;
  points: number;
};

export type ServeStatusMap = { [key in string]: ServeStatus };

export function isServeStatusMap(value: any): value is ServeStatusMap {
  if (value === {}) return true;
  const map = value as ServeStatusMap;
  const entries = Object.entries(map);
  if (entries.length === 0) {
    return false;
  } else if (!isNaN(+entries[0][0]) && isServeStatus(entries[0][1])) {
    return true;
  }
  return false;
}

export function clearPoints(serveStatusMap: ServeStatusMap): ServeStatusMap {
  Object.keys(serveStatusMap).forEach((serveId) => {
    serveStatusMap[serveId].points = 0
  })
  return serveStatusMap
}

export function isServeStatus(value: any): value is ServeStatus {
  if (!isNaN((value as ServeStatus).wins)) {
    return true;
  }
  return false;
}

export type Serve = {
  serveId: number,
  round: Round,
  playerSolution: PlayerSolution,
  allSolutions: PlayerSolution[],
  status: ServeStatus,
}

export function isServe(value: any): value is Serve {
  if ((value as Serve).serveId) {
    return true
  }
  return false
}

export type ServeRound = {
  serveId: number,
  round: Round
}

export function isServeRound(value: any): value is ServeRound {
  if ((value as ServeRound).round) {
    return true
  }
  return false
}