import React from "react";
import { BlockPattern } from "../../shared/Cosmetics";
import { InspectablePattern } from "./InspectablePattern";
import { BlockPatternName } from "./BlockPatternName";
import { Box } from "@mui/material";

interface BlockPatternElementProps {
  blockPattern: BlockPattern;
  inspectable?: boolean;
}

export const BlockPatternElement = (props: BlockPatternElementProps) => {
  const { blockPattern, inspectable = false } = props;
  return (
    <Box>
      <InspectablePattern
        blockPattern={blockPattern}
        inspectDisabled={!inspectable}
      />
      <BlockPatternName pattern={blockPattern} />
    </Box>
  );
};
