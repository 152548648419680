import { PixiComponent } from "@inlet/react-pixi";
import { Container } from "@pixi/display";
import { Graphics, LINE_JOIN } from "@pixi/graphics";
import { SmoothGraphics } from "@pixi/graphics-smooth";
import { Board } from "../../shared/Board";
import { Tower } from "../../shared/Tower";
import { cst } from "../../utils/constants";

interface HighVisBlocks {
  board: Board;
  playerTowers: Tower[];
  alpha: number;
}

export default PixiComponent("HighVisBlocks", {
  create: (props: HighVisBlocks) => {
    // instantiate something and return it.
    // for instance:
    const { alpha } = props;
    let container = new Container();
    container.scale.x = cst.tileSize;
    container.scale.y = cst.tileSize;
    container.position.x = cst.tileSize / 2;
    container.position.y = cst.tileSize;
    let staticMask = new Graphics();
    container.addChild(new SmoothGraphics());
    container.addChild(new SmoothGraphics());
    container.alpha = alpha;
    container.mask = staticMask;
    container.addChild(staticMask);
    return container;
  },

  applyProps: (container, oldProps, newProps) => {
    const { board, playerTowers, alpha } = newProps;
    let sbv = container.getChildAt(0) as SmoothGraphics;
    sbv.clear();
    let pbv = container.getChildAt(1) as SmoothGraphics;
    pbv.clear();
    container.alpha = alpha;
    if (alpha === 0) return;

    const mask = container.mask as Graphics;
    mask.clear();

    mask.beginFill(0xffffff, 1);
    playerTowers.forEach(({ coord, clap }) => {
      const { x, y } = coord;

      pbv.moveTo(x + 1 / 6, y + 4 / 6);
      pbv.lineStyle({
        color: clap ? 0x9ad7ed : 0x000000,
        width: 1 / 3,
        join: LINE_JOIN.ROUND,
      });
      pbv.lineTo(x + 1 / 6, y + 1 / 6);
      pbv.lineTo(x + 4 / 6, y + 1 / 6);
      pbv.moveTo(x + 8 / 6, y + 1 / 6);
      pbv.lineTo(x + 11 / 6, y + 1 / 6);
      pbv.lineTo(x + 11 / 6, y + 4 / 6);
      pbv.moveTo(x + 11 / 6, y + 8 / 6);
      pbv.lineTo(x + 11 / 6, y + 11 / 6);
      pbv.lineTo(x + 8 / 6, y + 11 / 6);
      pbv.moveTo(x + 4 / 6, y + 11 / 6);
      pbv.lineTo(x + 1 / 6, y + 11 / 6);
      pbv.lineTo(x + 1 / 6, y + 8 / 6);
      mask.drawRect(x, y, 2, 2);
    });

    board.staticTowers.forEach(({ coord, clap }) => {
      const { x, y } = coord;
      sbv.beginFill(clap ? 0x417698 : 0x440000, 1);
      sbv.lineStyle({
        color: clap ? 0x417698 : 0x440000,
        width: 1 / 3,
        join: LINE_JOIN.ROUND,
      });
      sbv.drawRect(x + 1 / 6, y + 1 / 6, 10 / 6, 10 / 6);
      mask.drawRect(x, y, 2, 4 / 12);
      mask.drawRect(x, y, 4 / 12, 2);
      mask.drawRect(x + 20 / 12, y, 4 / 12, 2);
      mask.drawRect(x, y + 20 / 12, 2, 2 / 6);
    });
    mask.endFill();
  },
  config: {
    // destroy instance on unmount?
    // default true
    destroy: true,

    /// destroy its children on unmount?
    // default true
    destroyChildren: true,
  },
});
