import { useCallback, useEffect, useRef, useState } from "react";

export const useSecondsPassed = () => {
  const [secondsPassed, setSecondsPassed] = useState(0);

  const deadlineCountDown = useRef<number | undefined>();

  const paused = useRef(false);

  const pause = useCallback(() => {
    paused.current = true;
  }, []);
  const unPause = useCallback(() => {
    paused.current = false;
  }, []);

  const secondsPassedReference = useRef(0);

  useEffect(() => {
    let mounted = true;
    if (deadlineCountDown && deadlineCountDown.current === undefined) {
      deadlineCountDown.current = window.setInterval(() => {
        secondsPassedReference.current += 1;
        if (!paused.current && mounted) {
          setSecondsPassed(secondsPassedReference.current);
        }
      }, 1000);
    }
    return () => {
      mounted = false;
    };
  }, [setSecondsPassed]);

  const resetSecondsPassed = useCallback(() => {
    secondsPassedReference.current = 0;
    setSecondsPassed(0);
  }, [setSecondsPassed]);

  return { secondsPassed, resetSecondsPassed, pause, unPause };
};
