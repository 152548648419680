import { Chip, Typography } from "@mui/material";
import React from "react";
import { getServeService } from "../../network/ServeService";
import { PlayerAccount } from "../../shared/Player";
import { Points } from "./Emoji";

interface ClaimServePointsElementProps {
  account: PlayerAccount;
  refreshAccount: () => void;
}

export const ClaimServePointsElement = (
  props: ClaimServePointsElementProps
) => {
  const { account, refreshAccount } = props;
  return (
    <Chip
      sx={{ mt: 1.5, mx: 1 }}
      onClick={() => {
        getServeService()
          .claim()
          .then(() => refreshAccount());
      }}
      label={
        <Typography variant="caption">
          Claim {account.unclaimedServePoints} <Points /> from <b>Serves</b>
        </Typography>
      }
    />
  );
};
