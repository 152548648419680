import { useCallback, useEffect, useRef } from 'react';

export function useClickOutside(callback: (event?: any) => void) {
    const ref = useRef<any>(null);

    const handleClickOutside = useCallback((event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback(event)
        }
    }, [callback, ref] );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { ref };
}

export function useClickOutsides(callback: (event?: any) => void) {
    const refA = useRef<any>(null);
    const refB = useRef<any>(null);

    const handleClickOutside = useCallback((event: any) => {
        if (refA.current && !refA.current.contains(event.target) && refB.current && !refB.current.contains(event.target)) {
          callback(event)
        }
    }, [callback, refA, refB] );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { refA, refB };
}

