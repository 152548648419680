import { Texture } from "@pixi/core";
import { useEffect, useRef } from "react";

export const useTextureCleanup = (texture?: Texture) => {
  const oldTexture = useRef<Texture>();
  const currentTexture = useRef<Texture>();

  useEffect(() => {
    if (oldTexture.current) {
      oldTexture.current.destroy(true);
    }
    oldTexture.current = currentTexture.current;
    currentTexture.current = texture;
  }, [texture]);

  useEffect(
    () => () => {
      if (oldTexture.current) {
        oldTexture.current.destroy(true);
      }
      if (currentTexture.current) {
        currentTexture.current.destroy(true);
      }
    },
    []
  );
};
