import { Fragment } from "react";
import { PlayerProfileLink } from "./PlayerProfileLink";

interface SponsorTextProps {
  anonymous: number;
  names: string[];
}

export const numberText = (a: number) =>
  a === 1
    ? "an"
    : a === 2
    ? "two"
    : a === 3
    ? "three"
    : a === 4
    ? "four"
    : a === 5
    ? "five"
    : a === 6
    ? "six"
    : a === 7
    ? "seven"
    : a === 8
    ? "eight"
    : a === 9
    ? "nine"
    : a === 10
    ? "ten"
    : "" + a;

export const SponsorText = (props: SponsorTextProps) => {
  const { names, anonymous } = props;

  return (
    <Fragment>
      {names.length === 1 ? (
        <Fragment>
          <b>
            <PlayerProfileLink playerName={names[0]} />
          </b>
        </Fragment>
      ) : (
        names.length > 1 && (
          <Fragment>
            {names.slice(0, -1).map((name, index) => (
              <Fragment key={index}>
                <b>
                  <PlayerProfileLink playerName={name} />
                </b>
                {index === names.length - 2 ? "" : ", "}
              </Fragment>
            ))}
            {anonymous === 0 ? " and " : ", "}
            <b>
              <PlayerProfileLink playerName={names[names.length - 1]} />
            </b>
          </Fragment>
        )
      )}
      {names.length > 0 && anonymous > 0 && " and "}
      <b>
        {anonymous > 0
          ? numberText(anonymous) +
            " anonymous mazer" +
            (anonymous > 1 ? "s" : "")
          : ""}
      </b>
    </Fragment>
  );
};
