import { formatScoreAsNumber } from "../utils/formatScore";
import { Round } from "./Round";
import { PlayerSolution } from "./Solution";

export type ArenaResult = {
  oldRating: number;
  newRating: number;
  reward: ArenaReward;
};

export function isArenaResult(value: any): value is ArenaResult {
  if (!isNaN((value as ArenaResult).oldRating)) {
    return true;
  }
  return false;
}

export interface TokenSponsors {
  anonymous: number;
  names: string[];
}

export type TokenTime = {
  timeLeft: number;
  availableTokens: number;
  replenishUrl: string;
  sponsors?: TokenSponsors;
};

export function isTokenTime(value: any): value is TokenTime {
  if ((value as TokenTime).replenishUrl) {
    return true;
  }
  return false;
}

export type ArenaReward = {
  points: number;
  serves: number;
};

export function isArenaReward(value: any): value is ArenaReward {
  if (!isNaN((value as ArenaReward).points)) {
    return true;
  }
  return false;
}

export enum ArenaMode {
  CLASSIC = "CLASSIC",
  COMPETITIVE = "COMPETITIVE",
  PRESTIGE = "PRESTIGE",
}

export const arenaModeLabel: { [key in ArenaMode]: string } = {
  CLASSIC: "Classic",
  COMPETITIVE: "Competitive",
  PRESTIGE: "Prestige",
};

export const arenaModeColor: { [key in ArenaMode]: string } = {
  CLASSIC: "#ffffff25",
  COMPETITIVE: "#0087ff6e",
  PRESTIGE: "#ffce104f",
};

export const arenaModeDescription: { [key in ArenaMode]: string } = {
  CLASSIC:
    "You will be matched against progressively more difficult opponents the further you progress in the Arena",
  COMPETITIVE:
    "You will always be matched against opponents at your skill level",
  PRESTIGE: "You will always face the best known solution to any given round",
};

export const arenaModeUnlock: { [key in ArenaMode]: string | undefined } = {
  CLASSIC: undefined,
  COMPETITIVE: "Finish a Classic Arena with 12 wins",
  PRESTIGE: "Finish a Competitive Arena with 12 wins",
};

export function isArenaMode(value: any): value is ArenaMode {
  return value === "CLASSIC" || value === "COMPETITIVE" || value === "PRESTIGE";
}

export function isArenaModes(value: any): value is ArenaMode[] {
  if (Array.isArray(value)) {
    let values = value as any[];
    return values.every((it) => isArenaMode(it));
  }
  return false;
}

export type RoundOutcome = "WIN" | "LOSS" | "TIE";

export const getRoundOutcome = (
  playerSolution: PlayerSolution,
  opponentSolution?: PlayerSolution
) => {
  if (
    !opponentSolution ||
    formatScoreAsNumber(playerSolution.solution.path.result) > formatScoreAsNumber(opponentSolution.solution.path.result)
  ) {
    return "WIN";
  } else if (
    formatScoreAsNumber(playerSolution.solution.path.result) < formatScoreAsNumber(opponentSolution.solution.path.result)
  ) {
    return "LOSS";
  }
  return "TIE";
};

export type RoundResult = {
  playerSolution: PlayerSolution;
  opponentSolution?: PlayerSolution;
  round: Round;
};

export const getRoundResultOutcome = (roundResult: RoundResult) =>
  getRoundOutcome(roundResult.playerSolution, roundResult.opponentSolution);

export function isArenaRoundResult(value: any): value is RoundResult {
  if ((value as RoundResult).playerSolution) {
    return true;
  }
  return false;
}

export type ArenaStatus = {
  wins: number;
  losses: number;
  ties: number;
};

export type ArenaSnapshot = {
  results: RoundResult[];
  status: ArenaStatus;
  timeLeft?: number;
  mode: ArenaMode;
};

export function isArenaSnapshot(value: any): value is ArenaSnapshot {
  if ((value as ArenaSnapshot).status) {
    return true;
  }
  return false;
}
