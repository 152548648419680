import React, { Fragment } from "react";
import { Button, Grid, Link, Tooltip, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router";
import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
export type GameMode = "versus" | "multiplayer" | "store";

interface LandingButtonProps {
  title: string;
  players: number;
  duration?: JSX.Element;
  mode: GameMode;
  path: string;
  icon: JSX.Element;
  background: string;
  disabled?: boolean;
  midIcon?: JSX.Element;
  midText?: string;
  completed?: boolean;
}

export const LandingButton = (props: LandingButtonProps) => {
  const {
    title,
    players,
    duration,
    mode,
    path,
    icon,
    background,
    disabled = false,
    completed = false,
    midIcon,
    midText,
  } = props;

  const navigate = useNavigate();

  return (
    <Tooltip
      placement="top"
      open={disabled}
      PopperProps={{ style: { zIndex: 1 } }}
      title={
        <Typography>
          <b>
            <Link underline="none" href="#" onClick={() => navigate("/signin")}>
              Sign In
            </Link>{" "}
          </b>
          {mode === "store" ? (
            <Fragment>
              to access the <b>{title}</b>
            </Fragment>
          ) : (
            <Fragment>
              to play <b>{title}</b>
            </Fragment>
          )}
        </Typography>
      }
    >
      <span>
        <Button
          fullWidth
          onClick={() => navigate(path)}
          variant="outlined"
          disabled={disabled}
          sx={{
            borderColor: "white",
            borderWidth: 2,
            color: "white",
            ":hover": { borderWidth: 2 },
            ":disabled": { borderWidth: 2, borderColor: "gray", color: "gray" },
            textTransform: "none",
            my: 0.5,
            backgroundImage: `url(${background})`,
            backgroundSize: 600,
            p: 0,
          }}
        >
          <Grid
            sx={{
              backgroundColor: "#00000088",
              ":hover": { backgroundColor: "#2c3d4d77" },
            }}
            container
            alignItems="center"
            justifyContent="center"
            height={1}
          >
            <Grid item xs={3} sm={2}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={1}
              >
                {icon}
                <Typography
                  variant="h6"
                  sx={{ display: { xs: "block", sm: "none" } }}
                  align="center"
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={0}
              sm={4}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: { xs: "block", sm: "none" } }}
              sm={0}
            />
            <Grid item xs={4} sm={4}>
              <Grid container display={mode === "store" ? "none" : undefined}>
                <Grid item xs={12}>
                  <Grid
                    sx={{ mt: 0.5 }}
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mb: 0.5 }}
                      >
                        {duration ? <AlarmIcon /> : <AlarmOffIcon />}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      {duration ? (
                        duration
                      ) : (
                        <Typography align="left">No&nbsp;limit</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mb: 0.5 }}
                      >
                        {midIcon ? (
                          midIcon
                        ) : mode === "versus" ? (
                          <SafetyDividerIcon sx={{ fontSize: 27 }} />
                        ) : (
                          <GroupAddIcon />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography noWrap display="inline" align="left">
                        {midText
                          ? midText
                          : mode === "versus"
                          ? "1 vs 1"
                          : "Multiplayer"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mb: 0.5 }}
                      >
                        <GroupIcon />
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align="left">
                        {players ? players : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={1}>
              <Grid container direction="column" height={1}>
                {completed ? (
                  <CheckCircleOutlineIcon sx={{ fontSize: 65 }} />
                ) : (
                  <PlayCircleOutlineIcon sx={{ fontSize: 65 }} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </span>
    </Tooltip>
  );
};
