import { useEffect, useState } from "react";
import { getPlayerService } from "../network/PlayerService";
import { isPlayer, Player } from "../shared/Player";

export const usePlayerOrGuest = (player: Player | undefined | null) => {

  const [playerOrGuest, setPlayerOrGuest] = useState(player);

  useEffect(() => {
    if (player === null) {
      getPlayerService()
        .guest()
        .then((response) => {
          if (isPlayer(response)) {
            setPlayerOrGuest(response);
          }
        });
    } else {
      setPlayerOrGuest(player);
    }
  }, [player, setPlayerOrGuest]);

  return playerOrGuest
}