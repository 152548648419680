import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
interface MazePaperProps {
  title?: string | JSX.Element;
  goBack?: () => void;
  topRight?: JSX.Element;
  children?: ReactNode;
}

export const MazePaper = (props: MazePaperProps) => {
  const { title, children, goBack, topRight } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 1,
        px: 1,
      }}
    >
      <Paper
        elevation={22}
        sx={{ p: { xs: 1, sm: 3, md: 3, lg: 3, xl: 3 }, width: 1 }}
      >
        <Grid container>
          <Grid item xs={3}>
            {goBack && (
              <Button
                sx={{ mt: 1.8, color: "white", borderColor: "white" }}
                onClick={goBack}
                variant="outlined"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {title && (
              <Typography component="h1" variant="h5" sx={{ p: 2, pb: 3 }}>
                <b>{title}</b>
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            {topRight}
          </Grid>
        </Grid>
        <Fragment>{children}</Fragment>
      </Paper>
    </Box>
  );
};
