import { Box, Grid, Typography } from "@mui/material";
import { CosmeticTool, toolInfo } from "../../shared/Crafting";

interface ToolNameProps {
  tool: CosmeticTool;
}

export const ToolName = (props: ToolNameProps) => {
  const { tool } = props;
  return (
    <Grid
      minHeight={"3.5em"}
      container
      justifyContent="center"
    >
      <Box>
        <Typography sx={{ mt: 1 }} lineHeight={1}>
          <b>{toolInfo[tool].name}</b>
        </Typography>
      </Box>
    </Grid>
  );
};
