import React from "react";
import { Container, Sprite, TilingSprite } from "@inlet/react-pixi";
import { Textures } from "./sprites/texture";
import { cst } from "../utils/constants";

export enum CursorType {
  None,
  Build,
  BuildClap,
  BuildError,
  Remove,
  Upgrade,
  Downgrade,
}

interface CursorProps {
  x: number;
  y: number;
  textures: Textures;
  tileSize: number;
  onFrost?: boolean;
  cursorType: CursorType;
}

const Cursor = (props: CursorProps) => {
  const {
    x,
    y,
    tileSize,
    textures,
    cursorType,
    onFrost,
  } = props;
  const tiledX = x * tileSize;
  const tiledY = y * tileSize;
  const offset =
    cursorType === CursorType.Build ||
    cursorType === CursorType.BuildClap ||
    cursorType === CursorType.BuildError
      ? 0
      : tileSize / 2;
  const size =
    cursorType === CursorType.Build ||
    cursorType === CursorType.BuildClap ||
    cursorType === CursorType.BuildError
      ? tileSize * 2
      : tileSize;

  return (
    <Container>
      <Sprite
        texture={
          cursorType === CursorType.Build
            ? textures["cursorBuild.png"]
            : cursorType === CursorType.BuildClap
            ? textures["cursorBuildClap.png"]
            : cursorType === CursorType.Remove
            ? textures["cursorRemove.png"]
            : cursorType === CursorType.Upgrade
            ? textures["cursorUpgrade.png"]
            : cursorType === CursorType.BuildError
            ? textures["cursorBuildError.png"]
            : cursorType === CursorType.None
            ? textures["empty.png"]
            : textures["cursorDowngrade.png"]
        }
        width={size}
        height={size}
        x={tiledX + offset}
        y={tiledY + offset}
        alpha={0.5}
      />
      {onFrost && (
        <TilingSprite
          texture={textures["clap.png"]}
          x={(x - 9 / 16 - cst.clapSize / 2) * tileSize}
          y={(y - 9 / 16 - cst.clapSize / 2) * tileSize}
          width={2 * cst.clapSize * tileSize}
          height={2 * cst.clapSize * tileSize}
          alpha={0.7}
          tint={0x03bafc}
          tilePosition={{
            x: Math.min(tileSize, 0),
            y: Math.min(tileSize, 0),
          }}
          tileScale={tileSize / (cst.tileSize * 2)}
        />
      )}
    </Container>
  );
};

export default Cursor;
