import { Board } from "./Board";

export type Round = {
  towers: number;
  claps: number;
  board: Board;
};

export function isRound(value: any): value is Round {
  if ((value as Round).board) {
    return true
  }
  return false
}

export const emptyRound: Round = {
  towers: 0,
  claps: 0,
  board: {
    width: 16,
    height: 18,
    staticTowers: [],
    startArea: [],
    endArea: [],
  },
};