import { Grid, IconButton, Typography } from "@mui/material";
import { Player } from "../../shared/Player";
import { theme } from "../../utils/theme";
import Emoji from "./Emoji";
import { PlayerProfileLink } from "./PlayerProfileLink";
import { CellProps } from "./ScoreCard";
import { SingleRoundPopperElement } from "./SingleRoundPopperElement";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import LoopIcon from "@mui/icons-material/Loop";
import { AnchoredPlayerSolution } from "./RoundResultsListElement";

const selectedColor = theme.palette.info.light + "15";

export const RoundResultsElement = ({ data, index, style }: CellProps) => {
  let round = data.round;
  let results: { player: Player; result: number }[] = data.results;
  let normalizeScores = data.normalizeScores;
  let showReplay: (playerId: number) => boolean = data.showReplay;
  let closeReplay: (playerId: number) => void = data.closeReplay;
  let playerId = data.playerId;
  let isFetching: (playerId: number) => boolean = data.isFetching;
  let fetchReplay: (
    playerId: number,
    anchorEl: EventTarget & HTMLButtonElement
  ) => void = data.fetchReplay;
  let getReplay: (playerId: number) => AnchoredPlayerSolution | undefined =
    data.getReplay;
  let player: Player = results[index].player;
  let result = results[index].result;
  let normalized = result / Math.max(...results.map((it) => it.result));

  const anchoredSolution = getReplay(player.id);

  const displayReplay = anchoredSolution !== undefined && showReplay(player.id);

  return (
    <div style={{ ...style }}>
      <Grid
        container
        width={1}
        justifyContent="space-between"
        key={"row" + player.id + "round" + index}
        sx={{
          pr: { xs: 2, sm: 0 },
          ...(player.id === playerId ? { bgcolor: selectedColor } : {}),
        }}
      >
        <Grid container width="12%" alignItems="center">
          <Typography variant="body2" textAlign="center" width={1}>
            {index === 0 ? (
              <Emoji symbol="🥇" label="1st" />
            ) : index === 1 ? (
              <Emoji symbol="🥈" label="2nd" />
            ) : index === 2 ? (
              <Emoji symbol="🥉" label="3rd" />
            ) : (
              index + 1
            )}
          </Typography>
        </Grid>
        <Grid container width="58%" alignItems="center">
          <Typography variant="body2" textAlign="left" noWrap>
            {player.guest ? (
              <i>{player.name}</i>
            ) : (
              <PlayerProfileLink playerName={player.name} />
            )}
          </Typography>
        </Grid>
        <Grid container width="22%" alignItems="center">
          <Typography
            variant="body2"
            textAlign="right"
            width={1}
            sx={{ mr: 1 }}
            lineHeight={1}
          >
            {normalizeScores
              ? (normalized * 100000).toFixed(0)
              : (result!! * 1000).toFixed(0)}
            {normalizeScores && (
              <div style={{color: "grey"}}>
                {(result!! * 1000).toFixed(0)}
              </div>
            )}
          </Typography>
        </Grid>
        <Grid container width="8%">
          <SingleRoundPopperElement
            playerSolution={anchoredSolution?.playerSolution}
            anchorEl={anchoredSolution?.anchorEl}
            open={displayReplay}
            place={index + 1}
            round={round}
            buttonElement={
              displayReplay ? (
                <IconButton onClick={() => closeReplay(player.id)}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton
                  disabled={isFetching(player.id)}
                  onClick={(event) =>
                    fetchReplay(player.id, event.currentTarget)
                  }
                >
                  {isFetching(player.id) ? (
                    <LoopIcon
                      sx={{
                        animation: "spin 1s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(360deg)",
                          },
                          "100%": {
                            transform: "rotate(0deg)",
                          },
                        },
                      }}
                    />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
