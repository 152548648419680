import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AcademyLevelProgress,
  AcademyRound,
  isAcademyCategory,
  isAcademyLevelProgresses,
  isAcademyRound,
} from "../shared/Academy";
import { academyService } from "../network/AcademyService";
import { Button, Stack } from "@mui/material";
import { AcademyRoundElement } from "./elements/AcademyRoundElement";
import { Cosmetics } from "../shared/Cosmetics";
import { useNavigate, useParams } from "react-router";
import { isBoardSize } from "../shared/Board";
import { BodyContent } from "./elements/BodyContent";
import { lt } from "../utils/lt";
import { AcademyLevelProgressesElement } from "./elements/AcademyProgressElement";

interface AcademyRoundPageProps {
  cosmetics: Cosmetics;
}

export const AcademyRoundPage = (props: AcademyRoundPageProps) => {
  const { cosmetics } = props;

  const params = useParams();

  const category = useMemo(
    () =>
      lt(params.category?.toUpperCase(), (cat) =>
        isAcademyCategory(cat) ? cat : undefined
      ),
    [params]
  );
  const boardSize = useMemo(
    () =>
      lt(params.boardSize?.toUpperCase(), (bs) =>
        isBoardSize(bs) ? bs : undefined
      ),
    [params]
  );
  const level = useMemo(
    () => (params.level && !isNaN(+params.level) ? +params.level : undefined),
    [params]
  );

  const [academyRound, setAcademyRound] = useState<AcademyRound>();

  useEffect(() => {
    if (category && boardSize && (level || level === 0)) {
      academyService.round(category, boardSize, level).then((response) => {
        if (isAcademyRound(response)) {
          setAcademyRound(response);
        }
      });
    }
  }, [category, boardSize, level]);

  const [progress, setProgress] = useState<AcademyLevelProgress[]>();

  const refreshProgress = useCallback(
    () =>
      category &&
      boardSize &&
      academyService.catSizeProgress(category, boardSize).then((response) => {
        if (isAcademyLevelProgresses(response)) {
          setProgress(response);
        }
      }),
    [category, boardSize, setProgress]
  );

  useEffect(() => {
    if (category && boardSize) {
      refreshProgress();
    }
  }, [category, boardSize, refreshProgress]);

  const navigate = useNavigate();

  return (
    <BodyContent>
      {category && boardSize && (level || level === 0) && academyRound && (
        <AcademyRoundElement
          academyRound={academyRound}
          category={category}
          boardSize={boardSize}
          level={level}
          refreshProgress={refreshProgress}
          playerCosmetics={cosmetics}
          submit={(layout) =>
            academyService.submit(category, boardSize, level, layout)
          }
          getPath={(result, version) =>
            academyService.path(category, boardSize, level, result, version)
          }
          resultCardElement={
            <Stack direction="row" pb={1} spacing={1} height={40} sx={{pt: {xs: 1, md: 0}}}>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "gray",
                }}
                onClick={() => navigate("/academy")}
              >
                back
              </Button>
              {progress && (
                <AcademyLevelProgressesElement
                  progress={progress}
                  category={category}
                  boardSize={boardSize}
                  numbers
                  currentLevel={level}
                />
              )}
            </Stack>
          }
        />
      )}
    </BodyContent>
  );
};
