import { TextureName } from "../sprites/texture";
import { BlockState, BlockType, windowRadius } from "./useBlocks";

export type SpriteProps = {
  texture: TextureName;
  width: number;
  height: number;
  x: number;
  y: number;
  tint?: number;
};

export const windowToBottoms: (
  window: BlockState,
  x: number,
  y: number
) => (false | SpriteProps)[] = (window: BlockState, x: number, y: number) => {
  let blockType = window[windowRadius][windowRadius];
  let opposite = blockType === "player" ? "static" : "player";
  let oppositeOrWall = (b: BlockType) => b === opposite || b === "wall";
  let entryOrExit = (b: BlockType) => b === "entry" || b === "exit";
  return [
    entryOrExit(window[1][0]) &&
      window[0][2] === undefined && {
        texture: "sideBottom1WN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 1 / 4,
      },

    entryOrExit(window[1][0]) &&
      window[0][2] === undefined && {
        texture: "clashJuxtaposedBottomEN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1,
      },

    entryOrExit(window[1][4]) &&
      window[0][3] === undefined && {
        texture: "sideBottom1WS.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 6 / 4,
      },

    entryOrExit(window[1][4]) &&
      window[0][3] === undefined && {
        texture: "clashJuxtaposedBottomES.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 7 / 4,
      },
    entryOrExit(window[4][0]) &&
      window[4][1] === undefined && {
        texture: "sideBottom1EN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 1 / 4,
      },
    entryOrExit(window[4][0]) &&
      window[4][1] === undefined && {
        texture: "clashJuxtaposedBottomWN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 0 / 4,
      },
    entryOrExit(window[4][4]) &&
      window[4][3] === undefined && {
        texture: "sideBottom1ES.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 6 / 4,
      },
    entryOrExit(window[4][4]) &&
      window[4][3] === undefined && {
        texture: "clashJuxtaposedBottomWS.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 7 / 4,
      },
    entryOrExit(window[4][1]) &&
      window[3][0] === undefined && {
        texture: "sideBottom1NE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + -2 / 4,
      },
    entryOrExit(window[4][1]) &&
      window[3][0] === undefined && {
        texture: "clashJuxtaposedBottomNE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + -2 / 4,
      },
    entryOrExit(window[0][1]) &&
      window[2][0] === undefined && {
        texture: "sideBottom1NW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + -2 / 4,
      },
    entryOrExit(window[0][1]) &&
      window[2][0] === undefined && {
        texture: "clashJuxtaposedBottomNW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 0 / 4,
        y: y + 1 + -2 / 4,
      },
    entryOrExit(window[4][4]) &&
      window[3][4] === undefined && {
        texture: "sideBottom1SE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + 7 / 4,
      },

    entryOrExit(window[4][4]) &&
      window[3][4] === undefined && {
        texture: "clashJuxtaposedBottomSE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 7 / 4,
      },

    entryOrExit(window[0][4]) &&
      window[2][4] === undefined && {
        texture: "sideBottom1SW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 7 / 4,
      },

    entryOrExit(window[0][4]) &&
      window[2][4] === undefined && {
        texture: "clashJuxtaposedBottomSW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 0 / 4,
        y: y + 1 + 7 / 4,
      },
    oppositeOrWall(window[0][2]) &&
      window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined && {
        texture: "clashJuxtaposedBottomN.png",
        width: 2 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + -2 / 4,
      },
    oppositeOrWall(window[0][2]) &&
      window[0][4] === undefined &&
      window[1][4] === undefined &&
      window[2][4] === undefined && {
        texture: "clashJuxtaposedBottomS.png",
        width: 2 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + 7 / 4,
      },
    oppositeOrWall(window[2][0]) &&
      window[4][0] === undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined && {
        texture: "clashJuxtaposedBottomW.png",
        width: 3 / 4,
        height: 2 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + -1 / 4,
      },
    oppositeOrWall(window[2][0]) &&
      window[0][0] === undefined &&
      window[0][1] === undefined &&
      window[0][2] === undefined && {
        texture: "clashJuxtaposedBottomE.png",
        width: 3 / 4,
        height: 2 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + -1 / 4,
      },
    window[0][0] === undefined &&
      window[0][1] === undefined &&
      window[0][2] === undefined &&
      window[0][3] === undefined &&
      window[0][4] === undefined &&
      window[1][0] === undefined &&
      window[1][4] === undefined && {
        texture: "sideBottom6W.png",
        width: 3 / 4,
        height: 3 / 2,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 1 / 4,
      },
    window[4][0] === undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined &&
      window[4][3] === undefined &&
      window[4][4] === undefined &&
      window[3][0] === undefined &&
      window[3][4] === undefined && {
        texture: "sideBottom6E.png",
        width: 3 / 4,
        height: 3 / 2,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 1 / 4,
      },
    window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined &&
      window[3][0] === undefined &&
      window[4][0] === undefined &&
      window[0][1] === undefined &&
      window[4][1] === undefined && {
        texture: "sideBottom6N.png",
        width: 3 / 2,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + -1 / 2,
      },
    window[0][4] === undefined &&
      window[1][4] === undefined &&
      window[2][4] === undefined &&
      window[3][4] === undefined &&
      window[4][4] === undefined &&
      window[4][3] === undefined &&
      window[0][3] === undefined && {
        texture: "sideBottom6S.png",
        width: 3 / 2,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 7 / 4,
      },
    window[0][0] === blockType &&
      (window[0][2] === blockType && window[2][0] !== blockType
        ? {
            texture: "innerCornerBottomNW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + -1 / 4,
            y: y + 1 + -2 / 4,
          }
        : window[2][0] === blockType && window[0][2] !== blockType
        ? {
            texture: "innerCornerBottomSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + -2 / 4,
            y: y + 1 + -1 / 4,
          }
        : window[2][0] !== blockType &&
          window[0][2] !== blockType && {
            texture: "connectorBottomB.png",
            width: 1,
            height: 1,
            x: x + 1 / 2 + -1 / 2,
            y: y + 1 + -1 / 2,
          }),
    window[4][0] === blockType &&
      (window[2][0] === blockType && window[4][2] !== blockType
        ? {
            texture: "innerCornerBottomSW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 7 / 4,
            y: y + 1 + -1 / 4,
          }
        : window[4][2] === blockType && window[2][0] !== blockType
        ? {
            texture: "innerCornerBottomNE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 6 / 4,
            y: y + 1 + -2 / 4,
          }
        : window[4][2] !== blockType &&
          window[2][0] !== blockType && {
            texture: "connectorBottomA.png",
            width: 1,
            height: 1,
            x: x + 1 / 2 + 3 / 2,
            y: y + 1 + -1 / 2,
          }),
    window[0][2] === blockType &&
      window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined && {
        texture: "sideBottom2N.png",
        width: 1 / 2,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + -2 / 4,
      },
    window[0][2] === blockType &&
      window[0][4] === undefined &&
      window[1][4] === undefined &&
      window[2][4] === undefined && {
        texture: "sideBottom2S.png",
        width: 1 / 2,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + 7 / 4,
      },
    window[2][0] === blockType &&
      window[0][0] === undefined &&
      window[0][1] === undefined &&
      window[0][2] === undefined && {
        texture: "sideBottom2W.png",
        width: 3 / 4,
        height: 1 / 2,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + -1 / 4,
      },
    window[2][0] === blockType &&
      window[4][0] === undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined && {
        texture: "sideBottom2E.png",
        width: 3 / 4,
        height: 1 / 2,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + -1 / 4,
      },

    window[0][2] === undefined &&
      window[0][1] === undefined &&
      window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined && {
        texture: "outerCornerBottomSE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + -2 / 4,
      },
    window[2][0] === undefined &&
      window[3][0] === undefined &&
      window[4][0] === undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined && {
        texture: "outerCornerBottomSW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + -2 / 4,
      },
    window[4][2] === undefined &&
      window[4][3] === undefined &&
      window[4][4] === undefined &&
      window[3][4] === undefined &&
      window[2][4] === undefined && {
        texture: "outerCornerBottomNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 7 / 4,
      },
    window[2][4] === undefined &&
      window[1][4] === undefined &&
      window[0][4] === undefined &&
      window[0][3] === undefined &&
      window[0][2] === undefined && {
        texture: "outerCornerBottomNE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 7 / 4,
      },

    (window[0][0] || window[0][1] || window[4][0] || window[4][1]) !==
      undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined &&
      window[3][0] === undefined && {
        texture: "sideBottom4N.png",
        width: 4 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 2 / 4,
        y: y + 1 + -1 / 2,
      },
    (window[0][0] || window[1][0] || window[0][4] || window[1][4]) !==
      undefined &&
      window[0][1] === undefined &&
      window[0][2] === undefined &&
      window[0][3] === undefined && {
        texture: "sideBottom4W.png",
        width: 3 / 4,
        height: 4 / 4,
        x: x + 1 / 2 + -1 / 2,
        y: y + 1 + 2 / 4,
      },
    (window[4][0] || window[3][0] || window[4][4] || window[3][4]) !==
      undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined &&
      window[4][3] === undefined && {
        texture: "sideBottom4E.png",
        width: 3 / 4,
        height: 4 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 2 / 4,
      },
    (window[0][4] || window[0][3] || window[4][4] || window[4][3]) !==
      undefined &&
      window[1][4] === undefined &&
      window[2][4] === undefined &&
      window[3][4] === undefined && {
        texture: "sideBottom4S.png",
        width: 4 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 2 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[4][1] || window[4][0] || window[3][0]) !== undefined &&
      window[0][1] === undefined &&
      window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[2][0] === undefined && {
        texture: "sideBottom1NW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + -1 / 2,
      },
    (window[1][0] || window[0][0] || window[0][1]) !== undefined &&
      window[4][1] === undefined &&
      window[4][0] === undefined &&
      window[3][0] === undefined &&
      window[2][0] === undefined && {
        texture: "sideBottom1NE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + -1 / 2,
      },
    (window[3][4] || window[4][4] || window[4][3]) !== undefined &&
      window[0][3] === undefined &&
      window[0][4] === undefined &&
      window[1][4] === undefined &&
      window[2][4] === undefined && {
        texture: "sideBottom1SW.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[1][4] || window[0][4] || window[0][3]) !== undefined &&
      window[4][3] === undefined &&
      window[4][4] === undefined &&
      window[3][4] === undefined &&
      window[2][4] === undefined && {
        texture: "sideBottom1SE.png",
        width: 1 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[0][3] || window[0][4] || window[1][4]) !== undefined &&
      window[0][0] === undefined &&
      window[1][0] === undefined &&
      window[0][1] === undefined &&
      window[0][2] === undefined && {
        texture: "sideBottom1WN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -1 / 2,
        y: y + 1 + 1 / 4,
      },
    (window[1][0] || window[0][0] || window[0][1]) !== undefined &&
      window[0][2] === undefined &&
      window[0][3] === undefined &&
      window[0][4] === undefined &&
      window[1][4] === undefined && {
        texture: "sideBottom1WS.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + -1 / 2,
        y: y + 1 + 6 / 4,
      },
    (window[4][3] || window[4][4] || window[3][4]) !== undefined &&
      window[3][0] === undefined &&
      window[4][0] === undefined &&
      window[4][1] === undefined &&
      window[4][2] === undefined && {
        texture: "sideBottom1EN.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 1 / 4,
      },
    (window[4][1] || window[4][0] || window[3][0]) !== undefined &&
      window[4][2] === undefined &&
      window[4][3] === undefined &&
      window[4][4] === undefined &&
      window[3][4] === undefined && {
        texture: "sideBottom1ES.png",
        width: 3 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 6 / 4,
      },
    window[0][1] === blockType &&
      window[2][0] !== blockType && {
        texture: "innerCornerBottomNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + -1 / 2,
      },
    window[0][1] === blockType &&
      window[0][3] !== blockType && {
        texture: "innerCornerBottomSE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 3 / 4,
      },
    window[1][0] === blockType &&
      window[3][0] !== blockType && {
        texture: "innerCornerBottomNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 3 / 4,
        y: y + 1 + -1 / 2,
      },
    window[1][0] === blockType &&
      window[0][2] !== blockType && {
        texture: "innerCornerBottomSE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + -1 / 4,
      },

    window[0][3] === blockType &&
      window[0][1] !== blockType && {
        texture: "innerCornerBottomNE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 2 / 4,
      },
    window[0][3] === blockType &&
      window[2][4] !== blockType && {
        texture: "innerCornerBottomSW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + 7 / 4,
      },
    window[1][4] === blockType &&
      window[0][2] !== blockType && {
        texture: "innerCornerBottomNE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 6 / 4,
      },
    window[1][4] === blockType &&
      window[3][4] !== blockType && {
        texture: "innerCornerBottomSW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 3 / 4,
        y: y + 1 + 7 / 4,
      },
    oppositeOrWall(window[0][0]) &&
      window[2][0] === undefined &&
      window[0][2] === undefined && {
        texture: "clashConnectorBottomB.png",
        width: 1,
        height: 1,
        x: x + 1 / 2 + -1 / 2,
        y: y + 1 + -1 / 2,
      },
    oppositeOrWall(window[4][0]) &&
      window[2][0] === undefined &&
      window[4][2] === undefined && {
        texture: "clashConnectorBottomA.png",
        width: 1,
        height: 1,
        x: x + 1 / 2 + 3 / 2,
        y: y + 1 + -1 / 2,
      },
    (oppositeOrWall(window[4][3]) ||
      (oppositeOrWall(window[4][2]) && oppositeOrWall(window[4][4]))) &&
      window[2][4] === undefined &&
      !entryOrExit(window[4][4]) && {
        texture: "clashPerpendicularBottomES.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + 7 / 4,
      },
    (oppositeOrWall(window[4][1]) ||
      (oppositeOrWall(window[4][0]) && oppositeOrWall(window[4][2]))) &&
      window[2][0] === undefined && {
        texture: "clashPerpendicularBottomEN.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + -2 / 4,
      },
    (oppositeOrWall(window[0][1]) ||
      (oppositeOrWall(window[0][0]) && oppositeOrWall(window[0][2]))) &&
      window[2][0] === undefined &&
      !entryOrExit(window[0][1]) && {
        texture: "clashPerpendicularBottomWN.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + -2 / 4,
      },
    (oppositeOrWall(window[0][3]) ||
      (oppositeOrWall(window[0][4]) && oppositeOrWall(window[0][2]))) &&
      window[2][4] === undefined &&
      !entryOrExit(window[0][4]) && {
        texture: "clashPerpendicularBottomWS.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -1 / 4,
        y: y + 1 + 7 / 4,
      },
    (oppositeOrWall(window[3][0]) ||
      (oppositeOrWall(window[2][0]) && oppositeOrWall(window[4][0]))) &&
      window[4][2] === undefined &&
      !entryOrExit(window[4][0]) && {
        texture: "clashPerpendicularBottomNE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + -1 / 4,
      },
    (oppositeOrWall(window[1][0]) ||
      (oppositeOrWall(window[0][0]) && oppositeOrWall(window[2][0]))) &&
      window[0][2] === undefined &&
      !entryOrExit(window[1][0]) && {
        texture: "clashPerpendicularBottomNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + -1 / 4,
      },
    (oppositeOrWall(window[1][4]) ||
      (oppositeOrWall(window[0][4]) && oppositeOrWall(window[2][4]))) &&
      window[0][2] === undefined && {
        texture: "clashPerpendicularBottomSW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + -2 / 4,
        y: y + 1 + 6 / 4,
      },
    (oppositeOrWall(window[3][4]) ||
      (oppositeOrWall(window[2][4]) && oppositeOrWall(window[4][4]))) &&
      window[4][2] === undefined &&
      !entryOrExit(window[4][4]) && {
        texture: "clashPerpendicularBottomSE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 6 / 4,
      },
  ];
};
