import axios from "axios";
import { SeedInfo, SeedSolutions } from "../shared/Admin";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { Round } from "../shared/Round";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface SeedService {
  getSeed: (seed: number) => Promise<Round | ErrorMessage>;
  getResult: (seed: number, layout: Layout) => Promise<Path | ErrorMessage>;
  getSeeds: () => Promise<SeedInfo[] | ErrorMessage>;
  getSeedSolutions: (seed: number) => Promise<SeedSolutions | ErrorMessage>;
}

export const getSeedService = (): SeedService => ({
  getSeed: (seed: number) =>
    axios
      .get<Round>(cst.backendUrl + "/seed/" + seed)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getResult: (seed: number, layout: Layout) =>
    axios
      .post<Path>(cst.backendUrl + "/seed/" + seed, layout)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSeeds: () =>
    axios
      .get<SeedInfo[]>(cst.backendUrl + "/admin/seeds", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSeedSolutions: (seed: number) =>
    axios
      .get<SeedSolutions>(cst.backendUrl + "/admin/seed/" + seed, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
