import React, { Fragment } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Seed } from "./Seed";
import { Signin } from "./pages/Signin";
import { Challenge } from "./pages/Challenge";
import { Freeplay } from "./Freeplay";
import { Redirect } from "./Redirect";
import { Register } from "./pages/Register";
import { Arena } from "./pages/Arena";
import { ServePage } from "./pages/Serve";
import { Landing } from "./pages/Landing";
import ResponsiveAppBar from "./pages/elements/AppBar";
import { usePlayerSession } from "./utils/usePlayerSession";
import { TermsOfService } from "./pages/TermsOfService";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Tutorial } from "./pages/Tutorial";
import { Saves } from "./pages/Saves";
import { Save } from "./pages/Save";
import { Puzzles } from "./pages/Puzzles";
import { Profile } from "./pages/Profile";
import { ChallengesPage } from "./pages/Challenges";
import { Reference } from "./pages/Reference";
import { Stats } from "./pages/Stats";
import { Signup } from "./pages/Signup";
import { CosmeticTest } from "./pages/CosmeticsTest";
import { RunnerTest } from "./pages/RunnerTest";
import { InventoryPage } from "./pages/InventoryPage";
import { Store } from "./pages/Store";
import { PastPuzzlePage } from "./pages/PastPuzzle";
import { SpriteTest } from "./pages/SpriteTest";
import { SeedViewer } from "./pages/SeedViewer";
import { DailyChallengePage } from "./pages/DailyChallenge";
import { PuzzleInfo } from "./pages/PuzzleInfo";
import { Puzzle } from "./pages/Puzzle";
import { ToggleTest } from "./pages/ToggleTest";
import { ColorPicker } from "./pages/ColorPicker";
import { PatternTest } from "./pages/PatternTest";
import { DiffTest } from "./pages/DiffTest";
import { Bounty } from "./pages/Bounty";
import { defaultCosmetics } from "./shared/Cosmetics";
import { Crafting } from "./pages/Crafting";
import { Leaderboards } from "./pages/Leaderboards";
import { GithubPattern } from "./pages/GithubPattern";
import { Academy } from "./pages/Academy";
import { AcademyRoundPage } from "./pages/AcademyRound";

const Router = () => {
  const playerSession = usePlayerSession();
  const {
    cosmetics,
    player,
    account,
    refreshAccount,
    refreshPlayer,
    refreshCosmetics,
    setFreshPoints,
    freshPoints,
  } = playerSession;

  return (
    <div className="App">
      <ResponsiveAppBar
        playerSession={playerSession}
        freshPoints={freshPoints}
      />
      <div style={{ paddingLeft: "calc(100vw - 100%)" }}>
        {player === undefined ? (
          <Routes>
            <Route path="/diff-test" element={<DiffTest />} />
            <Route path="/pattern-test" element={<PatternTest />} />
            <Route path="/sprite-test" element={<SpriteTest />} />
            <Route path="/cosmetic-test" element={<CosmeticTest />} />
            <Route path="/runner-test" element={<RunnerTest />} />
            <Route path="/color-picker" element={<ColorPicker />} />
            <Route path="/toggle-test" element={<ToggleTest />} />
          </Routes>
        ) : player === "unregistered" ? (
          <Register refreshPlayer={refreshPlayer} />
        ) : (
          <Routes>
            <Route path="/academy" element={<Academy />} />
            <Route
              path="/academy/:category/:boardSize/:level"
              element={
                cosmetics ? (
                  <AcademyRoundPage cosmetics={cosmetics} />
                ) : (
                  <Fragment />
                )
              }
            />
            <Route path="/custom-pattern" element={<GithubPattern />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/freeplay" element={<Freeplay />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/seed/:seed" element={<Seed />} />
            <Route path="/seed/:duration/:seed" element={<Seed />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/diff-test" element={<DiffTest />} />
            <Route path="/pattern-test" element={<PatternTest />} />
            <Route path="/sprite-test" element={<SpriteTest />} />
            <Route path="/cosmetic-test" element={<CosmeticTest />} />
            <Route path="/runner-test" element={<RunnerTest />} />
            <Route path="/color-picker" element={<ColorPicker />} />
            <Route path="/pattern-test" element={<PatternTest />} />
            <Route path="/toggle-test" element={<ToggleTest />} />
            <Route path="/view-seeds" element={<SeedViewer />} />
            <Route
              path="/leaderboards"
              element={<Leaderboards player={player} />}
            />
            <Route
              path="/leaderboards/:section"
              element={<Leaderboards player={player} />}
            />
            <Route
              path="/leaderboards/:section/:subSection"
              element={<Leaderboards player={player} />}
            />
            <Route path="/" element={<Landing player={player} />} />
            <Route
              path="/arena"
              element={
                <Arena
                  player={player}
                  account={account}
                  cosmetics={cosmetics}
                  refreshAccount={refreshAccount}
                />
              }
            />
            <Route
              path="/arena/serve"
              element={
                (player && account && (
                  <ServePage
                    cosmetics={cosmetics}
                    player={player}
                    refreshAccount={refreshAccount}
                    account={account}
                  />
                )) || <Signin />
              }
            />
            <Route
              path="/challenge/daily"
              element={(player && <DailyChallengePage />) || <Signin />}
            />
            <Route
              path="/challenge/daily/:id"
              element={
                <Challenge cosmetics={cosmetics} player={player} periodical />
              }
            />
            <Route
              path="/challenge/daily/past/:id"
              element={
                <Challenge
                  cosmetics={cosmetics}
                  player={player}
                  periodical
                  past
                />
              }
            />
            <Route
              path="/challenge/:id"
              element={<Challenge cosmetics={cosmetics} player={player} />}
            />
            <Route
              path="/challenges"
              element={
                (player && <ChallengesPage player={player} />) || <Signin />
              }
            />
            <Route
              path="/challenges/new"
              element={
                (player && <ChallengesPage player={player} create />) || (
                  <Signin />
                )
              }
            />
            <Route
              path="/saves"
              element={(player && <Saves player={player} />) || <Signin />}
            />
            <Route
              path="/save/:saveId"
              element={
                (player && <Save player={player} cosmetics={cosmetics} />) || (
                  <Signin />
                )
              }
            />
            <Route
              path="/puzzle"
              element={(player && <Puzzles player={player} />) || <Signin />}
            />
            <Route
              path="/puzzle/past/:pastId"
              element={
                (player && <PastPuzzlePage player={player} />) || <Signin />
              }
            />
            <Route
              path="/puzzle/:periodical"
              element={(player && <PuzzleInfo player={player} />) || <Signin />}
            />
            <Route
              path="/puzzle/:periodical/:play"
              element={
                (player && (
                  <Puzzle cosmetics={cosmetics} player={player} />
                )) || <Signin />
              }
            />
            <Route
              path="/store"
              element={
                <Store
                  setFreshPoints={setFreshPoints}
                  account={account}
                  refreshAccount={refreshAccount}
                />
              }
            />
            <Route
              path="/store/cancel"
              element={
                <Store
                  setFreshPoints={setFreshPoints}
                  account={account}
                  refreshAccount={refreshAccount}
                  paymentFailed
                />
              }
            />
            <Route
              path="/store/success/:productCode"
              element={
                <Store
                  setFreshPoints={setFreshPoints}
                  account={account}
                  refreshAccount={refreshAccount}
                />
              }
            />
            <Route path="/redirect" element={<Redirect player={player} />} />
            <Route
              path="/inventory"
              element={
                player && (
                  <InventoryPage
                    player={player}
                    refreshCosmetics={refreshCosmetics}
                  />
                )
              }
            />
            <Route
              path="/profile"
              element={(player && <Profile player={player} />) || <Signin />}
            />
            <Route
              path="/profile/:playerName"
              element={<Profile player={player} />}
            />
            <Route
              path="/r/:reference"
              element={<Reference player={player} playerReference />}
            />
            <Route
              path="/ref/:reference"
              element={<Reference player={player} />}
            />
            <Route
              path="/bounty"
              element={
                (player && (
                  <Bounty
                    cosmetics={cosmetics || defaultCosmetics}
                    player={player}
                    refreshAccount={refreshAccount}
                  />
                )) || <Signin />
              }
            />
            <Route
              path="/bounty/results"
              element={
                (player && (
                  <Bounty
                    cosmetics={cosmetics || defaultCosmetics}
                    player={player}
                    refreshAccount={refreshAccount}
                    showResults
                  />
                )) || <Signin />
              }
            />
            <Route
              path="/crafting"
              element={(player && <Crafting />) || <Signin />}
            />
            <Route path="*" element={<Landing player={player} />} />
          </Routes>
        )}
      </div>
    </div>
  );
};

export default Router;
