import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import { PlayerSolution } from "../../shared/Solution";
import { ButtonContent } from "./RoundResultElement";
import { ScoreOfInterest } from "./MultiplayerRoundElement";
import { RunnerIndicator } from "./RunnerIndicator";
import { lt } from "../../utils/lt";

interface ScoreCardProps {
  playerSolution: PlayerSolution;
  allSolutions: PlayerSolution[];
  scoresOfInterest: ScoreOfInterest[];
  completed: boolean;
  ranks: Map<number, number>;
  title: string;
  setInterestIndexReference: React.MutableRefObject<(a: number) => void>;
  isRemainingPlayers?: boolean;
  buttonContent: ButtonContent;
  titleButton?: boolean;
  forceHeight?: boolean;
  clickable?: boolean;
  prioritize?: (playerId: number) => void;
  watched: number[];
}

export interface CellProps {
  data: any;
  index: number;
  style: React.CSSProperties | undefined;
}

export const IndicatorElement = ({ data, index, style }: CellProps) => {
  const { clickable, interestIndex, indicators, prioritize } = data;
  const props = lt(
    indicators[Math.min(interestIndex, indicators.length - 1)],
    (inner) => inner[Math.min(index, inner.length - 1)]
  );
  return (
    <div
      style={style && { ...style }}
      onMouseDown={() =>
        clickable && props.clickable && prioritize(props.player.id)
      }
    >
      <RunnerIndicator
        isPlayer={props.isPlayer}
        isWatched={props.isWatched}
        clickable={props.clickable && clickable}
        prioritize={(_) => {}}
        completed={props.completed}
        slowIntervals={props.slowIntervals}
        player={props.player}
        result={props.result}
        score={props.score}
        place={props.place}
      />
    </div>
  );
};

export const ScoreCard = (props: ScoreCardProps) => {
  const {
    playerSolution,
    allSolutions,
    isRemainingPlayers = false,
    scoresOfInterest,
    setInterestIndexReference,
    ranks,
    title,
    buttonContent,
    titleButton = false,
    watched,
    prioritize = (a: number) => {},
    clickable = false,
  } = props;

  const [interestIndex, setInterestIndex] = useState(0);

  const indicators = useMemo(
    () =>
      scoresOfInterest.map((scoreOfInterest) =>
        [
          ...allSolutions.filter(
            (it) =>
              it.solution.path.result > scoreOfInterest.score &&
              it.player.id !== playerSolution.player.id
          ),
          ...(playerSolution.solution.path.result > scoreOfInterest.score
            ? [playerSolution]
            : []),
          ...allSolutions
            .filter((it) => it.solution.path.result <= scoreOfInterest.score)
            .sort((a, b) => ranks.get(a.player.id)! - ranks.get(b.player.id)!),
        ].map((opponentPlayerSolution) => ({
          isPlayer:
            opponentPlayerSolution.player.id === playerSolution.player.id,
          isWatched: watched.indexOf(opponentPlayerSolution.player.id) !== -1,
          clickable: watched.indexOf(opponentPlayerSolution.player.id) === -1,
          completed:
            scoreOfInterest.score >=
            opponentPlayerSolution.solution.path.result,
          slowIntervals: opponentPlayerSolution.solution.slowIntervals,
          player: opponentPlayerSolution.player,
          result: opponentPlayerSolution.solution.path.result,
          score: scoreOfInterest.score,
          place: ranks.get(opponentPlayerSolution.player.id),
        }))
      ),
    [scoresOfInterest, allSolutions, ranks, playerSolution, watched]
  );

  const cutoffIndex = useMemo(
    () =>
      scoresOfInterest.map(
        (score) =>
          allSolutions.filter((it) => it.solution.path.result > score.score)
            .length
      ),
    [scoresOfInterest, allSolutions]
  );

  const list = useRef<any>();

  const target = useRef<any>();
  const [width, setWidth] = useState(2);
  const [height, setHeight] = useState(2);

  const scrollToCutoff = useCallback(
    (i) =>
      list.current?.scrollToItem(
        Math.max(cutoffIndex[i] - Math.ceil(height / 35 / 2), 0),
        "start"
      ),
    [cutoffIndex, height]
  );

  const autoScroll = useRef(true);

  useEffect(() => {
    setInterestIndexReference.current = (i) => {
      setInterestIndex(i);
      if (autoScroll.current) scrollToCutoff(i);
    };
  }, [setInterestIndex, setInterestIndexReference, scrollToCutoff]);

  useResizeObserver(target, (event) => {
    setWidth(event.contentRect.width);
    setHeight(event.contentRect.height);
  });

  const autoScrollTimeout = useRef<number | undefined>();

  return (
    <Box
      width={1}
      height={1}
      sx={{ pt: { sm: isRemainingPlayers ? 0 : 2, xs: 0 } }}
    >
      <Paper sx={{ height: 1 }} elevation={4}>
        <Grid container direction="column" height={1}>
          <Grid container>
            <Grid item xs={2} sm={0} />
            <Grid item xs={titleButton ? 4 : 8} sm={12}>
              <Typography sx={{ pt: 1 }}>{title}</Typography>
            </Grid>
            <Grid item xs={titleButton ? 6 : 2} sm={0}>
              {titleButton && (
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  <Button
                    sx={{ my: 0.5, py: 0.5 }}
                    variant="contained"
                    size="small"
                    onClick={buttonContent.onClick}
                    endIcon={buttonContent.icon}
                  >
                    {buttonContent.text}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs width={1} height={1}>
            <Divider />
            <Box
              ref={target}
              bgcolor="#424242"
              sx={{ pt: 0.5 }}
              height={1}
              width={1}
            >
              <FixedSizeList
                height={Math.max(
                  height - 2,
                  Math.min(35 * 3.5, allSolutions.length * 35)
                )}
                itemCount={allSolutions.length}
                itemSize={35}
                width={width}
                ref={list}
                itemData={{
                  clickable,
                  indicators,
                  interestIndex,
                  prioritize,
                }}
                onScroll={({ scrollUpdateWasRequested }) => {
                  if (!scrollUpdateWasRequested) {
                    autoScroll.current = false;
                    if (autoScrollTimeout.current !== undefined) {
                      window.clearTimeout(autoScrollTimeout.current);
                    }
                    autoScrollTimeout.current = window.setTimeout(() => {
                      autoScroll.current = true;
                    }, 1500);
                  }
                }}
              >
                {IndicatorElement}
              </FixedSizeList>
            </Box>
            <Divider />
          </Grid>
          <Box
            sx={{
              my: titleButton ? 0.5 : 1,
              py: 0.5,
            }}
          >
            <Button
              sx={{
                display: {
                  xs: titleButton ? "none" : "inline-flex",
                  sm: "inline-flex",
                },
              }}
              variant="contained"
              onClick={buttonContent.onClick}
              endIcon={buttonContent.icon}
            >
              {buttonContent.text}
            </Button>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};
