import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { GoogleSigninButton } from "./elements/GoogleSigninButton";
import { useLocation, useNavigate } from "react-router";
import { MazePaper } from "./elements/MazePaper";
import { Box, Link, List, ListItem } from "@mui/material";
import { getMetricsService } from "../network/MetricsService";

export const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasLogged = useRef(false);

  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      getMetricsService().logSignUp();
    }
  }, [hasLogged]);

  return (
    <Container component="main" maxWidth="sm" sx={{ pt: 5 }}>
      <MazePaper title="Register">
        <Typography>
          By registering you will gain access to
          <List sx={{ listStyleType: 'disc', pl: 3 }}>
            <ListItem sx={{ display: "list-item" }}>
              Playing <b>Puzzles</b>, where you have as much time as you like to devise the best maze you can!
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Playing the <b>Daily Challenge</b> against other mazers!
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Your <b>Arena</b> rating that shows how you compare to other mazers in the <b>Arena</b>!
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Creating <b>Challenges</b> to challenge your friends to a <b>maze.game</b>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Gaining <b>Achievements</b> and show off your <b>Puzzle</b> results
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Cosmetics that makes your maze stand out!
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              And much more to come!
            </ListItem>
          </List>
          So come on in and join the <b>mazing</b> fun!
        </Typography>
        <br />
        <GoogleSigninButton returnRoute={location.pathname} />
        <br />
        <Typography variant="caption">
          To ensure your privacy we use Google as our means of
          registration, the only data saved from your Google account is the
          unique ID associated with your account, we do not store or show email
          addresses, profile pictures or anything else.<br/><br/>

          Selling your data will <b>never</b> be a source of revenue for <b>maze.game</b>.
          That is not the kind of business that we want to run.
        </Typography>
      </MazePaper>
      <Box sx={{ pt: 3 }}>
        <Typography variant="caption">
          <Link
            underline="none"
            href="#"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </Link>{" "}
          —{" "}
          <Link
            underline="none"
            href="#"
            onClick={() => navigate("/terms-of-service")}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};
