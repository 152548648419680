import { Container, Sprite } from "@inlet/react-pixi";
import React from "react";
import { Textures } from "../sprites/texture";

interface BlockCounterProps {
  textures: Textures;
  counter: number;
  x?: number;
  y?: number;
  size: number;
  tileSize: number;
}

export const BlockCounter = (props: BlockCounterProps) => {
  const { textures, counter, x = 0, y = 0, size } = props;

  return (
    <Container x={x} y={y}>
      {[...Array(Math.max(counter, 0))].map((_, i) => (
        <Sprite
          texture={textures["resourceBlock.png"]}
          width={size/4}
          height={size/4}
          y={size /3 * (i % 3 + 1/8)}
          x={size / 3 * (Math.floor(i / 3) + 1/4) + ((size /8) * Math.floor(i/9))}
          tint={0x6e6e6e}
          key={"resourceBlock" + i}
        />
      ))}
    </Container>
  );
};
