import { Avatar, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import { green, lightBlue, red } from "@mui/material/colors";
import React from "react";
import { ArenaStatus } from "../../shared/Arena";

interface ArenaStatusElementProps {
  arenaStatus: ArenaStatus;
}

export const ArenaStatusElement = (props: ArenaStatusElementProps) => {
  const { arenaStatus } = props;
  return (
    <Box>
      <Chip
        sx={{ m: 1 }}
        avatar={
          <Avatar sx={{ bgcolor: green[700] }}>{arenaStatus.wins}</Avatar>
        }
        label="Wins"
        color="success"
      />
      <Chip
        sx={{ m: 1 }}
        avatar={
          <Avatar sx={{ bgcolor: red[800] }}>{arenaStatus.losses}/3</Avatar>
        }
        label="Losses"
        color="error"
      />
      <Chip
        sx={{ m: 1 }}
        avatar={
          <Avatar sx={{ bgcolor: lightBlue[700] }}>{arenaStatus.ties}</Avatar>
        }
        label="Ties"
        color="primary"
      />
    </Box>
  );
};
