import { Coord } from "./Coord";
import { Tower } from "./Tower";

export enum BoardSize {
  SMALL = "SMALL",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
}

export function isBoardSize(category: any): category is BoardSize {
  return (
    category === BoardSize.SMALL ||
    category === BoardSize.NORMAL ||
    category === BoardSize.LARGE
  );
}

export const boardSizeLabel: { [key in BoardSize]: string } = {
  SMALL: "Small (9×10)",
  NORMAL: "Normal (16×18)",
  LARGE: "Large (23×26)",
};

export type Board = {
  width: number;
  height: number;
  staticTowers: Tower[];
  startArea: Coord[];
  endArea: Coord[];
  waypoint?: Coord;
};
