import React from "react";
import { Popper } from "@mui/material";
import { theme } from "../../utils/theme";
import { CosmeticPlayerSolution } from "../../shared/Cosmetics";
import { VirtualElement } from "@popperjs/core";
import { Round } from "../../shared/Round";
import { SingleRoundElement } from "./SingleRoundElement";

interface SingleRoundPopperElementProps {
  playerSolution?: CosmeticPlayerSolution;
  round: Round;
  open: boolean;
  place: number;
  buttonElement: JSX.Element;
  anchorEl?: VirtualElement | (() => VirtualElement) | null | undefined;
}

export const SingleRoundPopperElement = (
  props: SingleRoundPopperElementProps
) => {
  const { playerSolution, open, buttonElement, round, place, anchorEl } = props;

  const width = Math.min(
    (theme.breakpoints.values.md * 3) / 4,
    window.innerWidth
  );

  return (
    <div style={{ display: "inline" }}>
      {buttonElement}
      <Popper open={open} anchorEl={anchorEl}>
        {playerSolution && (
          <SingleRoundElement
            playerSolution={playerSolution}
            width={width}
            round={round}
            place={place}
          />
        )}
      </Popper>
    </div>
  );
};
