import { Variant } from "@mui/material/styles/createTypography";
import { Box } from "@mui/system";
import React, { Fragment } from "react";
import PointSvg from "../../resources/points.svg";
import { lt } from "../../utils/lt";
import { theme } from "../../utils/theme";

interface EmojiProps {
  symbol: string;
  label?: string;
}

const Emoji = (props: EmojiProps) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);

export default Emoji;

interface PointsProps {
  variant?: Variant;
}

export const Points = (props: PointsProps) => (
  <Fragment>
    <Box
      component="span"
      sx={{
        position: "relative",
        top: 2.5,
      }}
    >
      <img
        src={PointSvg}
        style={
          props.variant
            ? lt(theme.typography[props.variant], (it) => {
                return { height: it.fontSize };
              })
            : undefined
        }
        alt="Points"
      />
    </Box>
  </Fragment>
);
