const ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const b62Encode = (value: number): string => {
  var result = "",
    mod;
  do {
    mod = value % 62;
    result = ALPHA.charAt(mod) + result;
    value = Math.floor(value / 62) - 1;
  } while (value >= 0);

  return result;
};

export const b62Decode = (value: string): number => {
  var result = 0;
  for (var i = 0, len = value.length; i < len; i++) {
    result *= 62;
    result += ALPHA.indexOf(value[i]) + 1
  }

  return result - 1;
};
