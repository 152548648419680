export type SunburstLeaf = {
  name: string;
  size: number;
  color: string;
};

export type SunburstNode = {
  name: string;
  color: string;
  size: number; 
  children: (SunburstNode | SunburstLeaf)[];
};

export function isSunburstNode(value: any): value is SunburstNode {
  if ((value as SunburstNode).name) return true;
  return false;
}

export type SessionLengths = {
  median: string,
  longest: string,
  total: string
}

export type ActivityResult = {
  total: number,
  unique: number
}

export type ActivityResults = {
  sunburst: SunburstNode;
  sessionLengths: SessionLengths;
  totalSessions: number;
  map: { [key in string]: ActivityResult };
};

export function isActivityResults(value: any): value is ActivityResults {
  if ((value as ActivityResults).sunburst) return true;
  return false;
}
