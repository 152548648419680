import { Avatar, Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTextures } from "../../game/sprites/TextureDaemon";
import { BlockPattern, defaultCosmetics } from "../../shared/Cosmetics";
import { cst } from "../../utils/constants";
import { blockCosmeticImage } from "../../utils/imageGenerators";
import { RarityBadge } from "./RarityBadge";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import emptyRoundImage from "../../resources/emptyRound.png";
import { Application } from "@pixi/app";
import { generateBoardImage } from "../../game/utils/generateBoardImage";
import { exampleLayout, exampleRound } from "../SpriteTest";
import { Renderer } from "@pixi/core";
import ConstructionIcon from "@mui/icons-material/Construction";

interface InspectablePatternProps {
  blockPattern: BlockPattern;
  image?: string;
  onInspect?: () => void;
  isTool?: boolean;
  forceClosed?: boolean;
  inspectDisabled?: boolean;
}

export const InspectablePattern = (props: InspectablePatternProps) => {
  const {
    blockPattern,
    image,
    onInspect = () => {},
    forceClosed = false,
    isTool = false,
    inspectDisabled = false,
  } = props;
  const textures = useTextures();

  const blockImage = useMemo(
    () => (image ? image : blockCosmeticImage([blockPattern], textures)[0]),
    [image, blockPattern, textures]
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (forceClosed) {
      setOpen(false);
    }
  }, [setOpen, forceClosed]);

  const [preview, setPreview] = useState<string | undefined>();
  const width = useMemo(() => Math.min(window.innerWidth, 568), []);

  useEffect(() => {
    if (blockPattern && open && preview !== undefined)
      setPreview((p) => {
        let app = new Application();
        let image = generateBoardImage(
          app.renderer as Renderer,
          {
            ...defaultCosmetics,
            block: { pattern: blockPattern },
          },
          textures,
          exampleLayout.towers,
          exampleRound.board,
          cst.tileSize
        );
        app.destroy();
        return image;
      });
  }, [setPreview, textures, blockPattern, open, preview]);

  return (
    <Box>
      <Box width={1}>
        <RarityBadge
          hideBadge={isTool}
          firstRarity={blockPattern.firstColor.rarity}
          secondRarity={isTool ? undefined : blockPattern.secondColor?.rarity}
        >
          <Avatar
            variant="rounded"
            src={blockImage}
            sx={{
              width: cst.tileSize,
              height: cst.tileSize,
            }}
          />
          {!inspectDisabled && (
            <Grid
              container
              position="absolute"
              top={0}
              left={0}
              width={1}
              height={1}
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip
                open={open}
                title={
                  <Box width={width} height={(width * 19.5) / 17}>
                    <img
                      src={emptyRoundImage}
                      style={{ position: "absolute" }}
                      width={width}
                      alt=""
                      height={(width * 19.5) / 17}
                    />
                    <img
                      style={{ position: "absolute" }}
                      src={preview ? preview : emptyRoundImage}
                      width={width}
                      alt=""
                      height={(width * 19.5) / 17}
                    />
                  </Box>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: width,
                      p: 0,
                      m: 0,
                      bgcolor: "#00000000",
                    },
                  },
                }}
              >
                {isTool ? (
                  <ConstructionIcon />
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen((o) => !o);
                      window.setTimeout(
                        () =>
                          setPreview((p) => {
                            if (p) {
                              return p;
                            }
                            let app = new Application();
                            let image = generateBoardImage(
                              app.renderer as Renderer,
                              {
                                ...defaultCosmetics,
                                block: { pattern: blockPattern },
                              },
                              textures,
                              exampleLayout.towers,
                              exampleRound.board,
                              cst.tileSize
                            );
                            app.destroy();
                            return image;
                          }),
                        0
                      );
                      onInspect();
                    }}
                  >
                    {open ? <CloseIcon /> : <VisibilityIcon />}
                  </IconButton>
                )}
              </Tooltip>
            </Grid>
          )}
        </RarityBadge>
      </Box>
    </Box>
  );
};
