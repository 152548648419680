import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface BodyContentProps {
  hidden?: boolean;
  children?: ReactNode;
}

export const BodyContent = (props: BodyContentProps) => {
  const {children, hidden = false} = props;
  return (
      <Box
        sx={{
          display: hidden ? "none" : "flex",
          alignContent: "stretch",
          flexDirection: "row",
          height: "calc(100vh - 56px)",
          width: "100vw",
          position: "absolute",
          top: "56px",
          left: "0px",
          py: 1.5,
        }}
      >
        {children}
      </Box>
  );
};
