import axios from "axios";
import { LoggableError } from "../shared/Error";
import { SunburstNode } from "../shared/Sunburst";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface MetricsService {
  reference: (reference: string) => Promise<void | ErrorMessage>;
  logSignIn: () => Promise<void | ErrorMessage>;
  logSignUp: () => Promise<void | ErrorMessage>;
  logUnique: () => Promise<void | ErrorMessage>;
  logOpenTutorial: () => Promise<void | ErrorMessage>;
  logError: (error: LoggableError) => Promise<void | ErrorMessage>;
  sunburst: (hours: number) => Promise<any | ErrorMessage>;
}

export const getMetricsService = (): MetricsService => ({
  reference: (reference: string) =>
    axios
      .post(cst.backendUrl + "/ref/" + reference)
      .then((_) => {})
      .catch((reason) => "" + reason),
  logSignIn: () =>
    axios
      .post(cst.backendUrl + "/log/signin")
      .then((_) => {})
      .catch((reason) => "" + reason),
  logSignUp: () =>
    axios
      .post(cst.backendUrl + "/log/signup")
      .then((_) => {})
      .catch((reason) => "" + reason),
  logUnique: () =>
    axios
      .post(cst.backendUrl + "/log/unique")
      .then((_) => {})
      .catch((reason) => "" + reason),
  logOpenTutorial: () =>
    axios
      .post(cst.backendUrl + "/log/tutorial")
      .then((_) => {})
      .catch((reason) => "" + reason),
  logError: (error: LoggableError) =>
    axios
      .post(cst.backendUrl + "/log/error", error, creds)
      .then((_) => {})
      .catch((reason) => "" + reason),
  sunburst: (hours) =>
    axios
      .get<SunburstNode>(
        cst.backendUrl + "/admin/activity/" + hours,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
