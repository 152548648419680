import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { SCALE_MODES } from "@pixi/constants";
import { IGenerateTextureOptions, Renderer, RenderTexture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { Fragment, useEffect, useState } from "react";
import { Game } from "../game/Game";
import { Cosmetics, Pattern, Rarity } from "../shared/Cosmetics";
import { cst } from "../utils/constants";
import { exampleLayout, exampleRound } from "./SpriteTest";

const cosmetics: Cosmetics = {
  block: {
    pattern: {
      pattern: Pattern.VERTICAL_GRADIENT,
      firstColor: {
        color: {
          name: "Hot Pink",
          tint: 16712333,
        },
        indentTint: 15008126,
        rarity: Rarity.LEGENDARY,
      },
      secondColor: {
        color: {
          name: "Too Blue to be True",
          tint: 35071,
        },
        indentTint: 31461,
        rarity: Rarity.LEGENDARY,
      },
    },
  },
  ball: {
    color: {
      color: {
        name: "Inviter",
        tint: 16728385,
      },
      frozenTint: 16718169,
      rarity: Rarity.SUPPORTER,
    },
  },
};

export type ToggleTestProp = {
  bottomFrame: boolean;
  topFrame: boolean;
  timerFlash: boolean;
  blockBottoms: boolean;
  runnerBottom: boolean;
  frostTops: boolean;
  runnerTop: boolean;
  playerTopOne: boolean;
  playerTopTwo: boolean;
  playerInlays: boolean;
  staticTopOne: boolean;
  staticTopTwo: boolean;
  staticInlays: boolean;
  renderBlocks: boolean;
  renderFrame: boolean;
  renderPlayerPattern: boolean;
  renderStaticPattern: boolean;
  renderTimerFlash: boolean;
  cursor: boolean;
};

export const generateHook = (
  renderer: Renderer,
  displayObject: Container | Sprite,
  options?: IGenerateTextureOptions | SCALE_MODES | undefined,
  resolution?: number | undefined,
  region?: Rectangle | undefined,
  oldTexture?: RenderTexture
) => {
  const maxRegion = new Rectangle(
    0,
    0,
    Math.min(displayObject.width, cst.tileSize * 24),
    Math.min(displayObject.height, cst.tileSize * 30.5)
  );
  const modifiedOptions = options ? options : { region: maxRegion };

  if (oldTexture && oldTexture.baseTexture) {
    renderer.render(displayObject, { renderTexture: oldTexture });
    return oldTexture;
  }
  let texture = renderer.generateTexture(
    displayObject,
    modifiedOptions,
    resolution,
    region
  );

  return texture;
};

export const ToggleTest = () => {
  const [toggle, setToggle] = useState<ToggleTestProp>({
    bottomFrame: true,
    topFrame: true,
    timerFlash: true,
    blockBottoms: true,
    runnerBottom: true,
    frostTops: true,
    runnerTop: true,
    playerTopOne: true,
    playerTopTwo: true,
    playerInlays: true,
    staticTopOne: true,
    staticTopTwo: true,
    staticInlays: true,
    cursor: true,
    renderBlocks: true,
    renderFrame: true,
    renderPlayerPattern: true,
    renderStaticPattern: true,
    renderTimerFlash: true,
  });
  const [show, setShow] = useState(false);

  const [hookedLog, setHookedLog] = useState("");

  useEffect(() => {
    console.log = (message?: any, ...optionalParams: any[]) => {
      setHookedLog((o) => "log: " + message + "\n" + o);
    };
    console.error = (message?: any, ...optionalParams: any[]) => {
      setHookedLog((o) => "err: " + message + "\n" + o);
    };
    console.trace = (message?: any, ...optionalParams: any[]) => {
      setHookedLog((o) => "trc: " + message + "\n" + o);
    };
    console.warn = (message?: any, ...optionalParams: any[]) => {
      setHookedLog((o) => "wrn: " + message + "\n" + o);
    };
  }, []);

  return (
    <Grid container justifyContent="center">
      <Box
        width={Math.min(
          window.innerWidth,
          Math.round((window.innerHeight * 17) / 19.5) - 200
        )}
      >
        <Button onClick={() => setShow((s) => !s)} fullWidth>
          {show ? "hide" : "show"}
        </Button>
        {show && (
          <Fragment>
            <Box
              height={
                (Math.min(
                  window.innerWidth,
                  Math.round((window.innerHeight * 17) / 19.5) - 200
                ) *
                  19.5) /
                17
              }
              width={Math.min(
                window.innerWidth,
                Math.round((window.innerHeight * 17) / 19.5) - 200
              )}
            >
              {show && (
                <Game
                  round={exampleRound}
                  startingLayout={exampleLayout}
                  cosmetics={cosmetics}
                  iosTest={toggle}
                  startingWidth={Math.min(
                    window.innerWidth,
                    Math.round((window.innerHeight * 17) / 19.5) - 200
                  )}
                />
              )}
            </Box>
          </Fragment>
        )}
        {Object.entries(toggle).map(([prop, value]) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onClick={() =>
                  setToggle((s) => {
                    return Object.fromEntries(
                      Object.entries(s).map((it) =>
                        it[0] === prop ? [prop, !value] : it
                      )
                    ) as ToggleTestProp;
                  })
                }
              />
            }
            label={prop}
          />
        ))}
      </Box>
      <TextField
        id="outlined-multiline-flexible"
        multiline
        fullWidth
        value={hookedLog}
      />
    </Grid>
  );
};
