import { lt } from "../utils/lt";
import { UniqueBlockPattern, UniquePattern } from "./Cosmetics";
import { UniqueTool } from "./Crafting";
import { Player } from "./Player";
import { Round } from "./Round";

export type BountyParameters = {
  duration: number;
  waypoints: boolean;
};

export type ActivationResponse = {
  newState: BountyState;
  activeRound: boolean;
  bountyParameters: BountyParameters;
  host: Player;
  deadline: number;
};

export function isActivationResponse(value: any): value is ActivationResponse {
  if (Object.keys(value).find((a) => a === "host")) {
    return true;
  }
  return false;
}

export type BountyRound = {
  round: Round;
  duration: number;
};

export function isBountyRound(value: any): value is BountyRound {
  if ((value as BountyRound).round) {
    return true;
  }
  return false;
}

export type BountyReward = {
  blockPatterns: UniqueBlockPattern[];
  patterns: UniquePattern[];
  tools: UniqueTool[];
  coins: number;
};

export function isBountyReward(value: any): value is BountyReward {
  if (
    lt(value as BountyReward, (a) => a.coins || a.patterns || a.blockPatterns)
  ) {
    return true;
  }
  return false;
}

export type BountyState =
  | "ROLLING"
  | "READY"
  | "ACTIVE"
  | "CLAIMABLE"
  | "WAITING"
  | "HOSTING"
  | "PLAYING";

export function isBountyState(value: any): value is BountyState {
  return (
    value === "ROLLING" ||
    value === "READY" ||
    value === "ACTIVE" ||
    value === "CLAIMABLE" ||
    value === "WAITING" ||
    value === "HOSTING" ||
    value === "PLAYING"
  );
}
