import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import { Game } from "./game/Game";
import { getSeedService } from "./network/SeedService";
import { isRound, Round } from "./shared/Round";
import { useNavigate, useParams } from "react-router";
import { isPath, Path } from "./shared/Path";
import { Box, Button, Typography } from "@mui/material";
import { b62Encode } from "./utils/base62";
import { lt } from "./utils/lt";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";

export const Seed = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [seed, setSeed] = useState<number>(
    params === undefined || params.seed === undefined
      ? Date.now()
      : +params.seed
  );

  const [duration, setDuration] = useState<number>(
    params === undefined || params.duration === undefined
      ? 90
      : +params.duration
  );

  useEffect(() => {
    if (params !== undefined && params.seed === undefined) {
      navigate("/seed/90/" + b62Encode(seed));
    } else if (params.duration === undefined && params.seed !== undefined) {
      navigate("/seed/90/" + params.seed);
    }
  }, [navigate, params, seed]);

  const seedService = useRef(getSeedService()).current;

  const [round, setRound] = useState<Round | undefined>(undefined);

  const [path, setPath] = useState<Path | undefined>();

  const sameRound = useRef<Round | undefined>();

  useEffect(() => {
    if (params && params.seed && params.duration && round === undefined) {
      setDuration(+params.duration);
      if (sameRound.current === undefined) {
        seedService.getSeed(seed).then((response) => {
          if (response && isRound(response)) {
            setRound(response);
          } else {
            console.log(response);
          }
        });
      } else {
        setRound(sameRound.current);
      }
    }
  }, [round, seedService, params, seed]);

  useEffect(() => console.log(seed), [seed]);

  const game = useMemo(
    () => (
      <Fragment>
        {params && params.duration && params.seed && round && isRound(round) && (
          <React.Fragment>
            <Box sx={{ height: "90vh", mt: "5vh", width: "100vw" }}>
              <Game
                round={round}
                duration={duration}
                path={path}
                animationSpeed={2}
                submitLayout={(layout) => {
                  seedService
                    .getResult(seed, layout)
                    .then((response) =>
                      isPath(response)
                        ? setPath(response)
                        : console.log(response)
                    );
                }}
              />
            </Box>
            <Box
              maxWidth="sm"
              mx="auto"
              sx={{
                pt: 0,
                width: 1,
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                variant="contained"
                color="info"
                style={{ textTransform: "none" }}
                onClick={() => {
                  sameRound.current = round;
                  setRound(undefined);
                  setPath(undefined);
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <Typography sx={{ pr: 1 }}>Retry</Typography>
                  <ReplayIcon />
                </Box>
              </Button>
              <Button
                variant="contained"
                color="info"
                style={{ textTransform: "none" }}
                onClick={() => {
                  lt(Date.now(), (newSeed) => {
                    navigate("/seed/" + duration + "/" + b62Encode(newSeed));
                    sameRound.current = undefined;
                    setSeed(newSeed);
                    setRound(undefined);
                    setPath(undefined);
                  });
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <Typography sx={{ pr: 1 }}>Next</Typography>
                  <SkipNextIcon />
                </Box>
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Fragment>
    ),
    [params, round, seed, seedService, path, duration, setRound, navigate]
  );

  return game;
};
