import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { Board } from "../../shared/Board";
import { Layout } from "../../shared/Layout";

import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

interface SavedLayoutProps {
  currentLayoutReference: React.MutableRefObject<Layout>;
  board: Board;
  load: (layout: Layout) => void;
  startingLayout?: Layout;
}

type BlockType =
  | "variableBlock"
  | "variableClap"
  | "staticBlock"
  | "staticClap";

interface IconLayoutProps {
  board: Board;
  layout: Layout;
}

export const IconLayout = (props: IconLayoutProps) => {
  const { board, layout } = props;

  const blockMap: (undefined | BlockType)[][] = useMemo(() => {
    let state: (undefined | BlockType)[][] = Array(board.height)
      .fill(0)
      .map(() => Array(board.width).fill(undefined));

    board.staticTowers.forEach((tower, i) => {
      state[tower.coord.y][tower.coord.x] = tower.clap
        ? "staticClap"
        : "staticBlock";
      state[tower.coord.y][tower.coord.x + 1] = tower.clap
        ? "staticClap"
        : "staticBlock";
      state[tower.coord.y + 1][tower.coord.x] = tower.clap
        ? "staticClap"
        : "staticBlock";
      state[tower.coord.y + 1][tower.coord.x + 1] = tower.clap
        ? "staticClap"
        : "staticBlock";
    });

    layout?.towers.forEach((tower) => {
      state[tower.coord.y][tower.coord.x] = tower.clap
        ? "variableClap"
        : "variableBlock";
      state[tower.coord.y][tower.coord.x + 1] = tower.clap
        ? "variableClap"
        : "variableBlock";
      state[tower.coord.y + 1][tower.coord.x] = tower.clap
        ? "variableClap"
        : "variableBlock";
      state[tower.coord.y + 1][tower.coord.x + 1] = tower.clap
        ? "variableClap"
        : "variableBlock";
    });

    return state;
  }, [layout, board]);
  return (
    <Box
      sx={{
        borderRadius: 0.5,
        overflow: "hidden",
      }}
      width={board.width * 4}
      height={board.height * 4}
    >
      <Table>
        <TableBody>
          {blockMap.map((row, i) => (
            <TableRow key={"saveRow" + i}>
              {row.map((blockType, j) => (
                <TableCell
                  key={"saveCell" + i + "-" + j}
                  sx={{
                    backgroundColor:
                      blockType === "staticBlock"
                        ? "#834f53"
                        : blockType === "staticClap"
                        ? "#705288"
                        : blockType === "variableBlock"
                        ? "#2b2b2b"
                        : blockType === "variableClap"
                        ? "#4d6a80"
                        : "#969696",
                    borderColor: "#00000000",
                    m: 0,
                    p: 0,
                  }}
                  width={4}
                  height={4}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const SavedLayout = (props: SavedLayoutProps) => {
  const { currentLayoutReference, board, load, startingLayout } = props;

  const [layout, setLayout] = useState<Layout>(
    startingLayout ? startingLayout : { towers: [] }
  );

  return (
    <Button
      onClick={() => {
        if (startingLayout === undefined) {
          setLayout({ towers: [...currentLayoutReference.current.towers] });
        }
        load(layout);
      }}
    >
      <IconLayout board={board} layout={layout} />
      <Box
        sx={{
          borderRadius: 0.5,
          overflow: "hidden",
          position: "absolute",
          backgroundColor: "#00000066",
          display: layout ? "none" : undefined,
        }}
        width={board.width * 4}
        height={board.height * 4}
      >
        <Grid height={1} justifyContent="center" alignItems="center" container>
          <MoveToInboxIcon color="action" />
        </Grid>
      </Box>
    </Button>
  );
};
