import React, { Fragment, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { isPlayer, Player } from "../shared/Player";
import { getPuzzleService } from "../network/PuzzleService";
import { isPuzzleMap } from "../shared/Puzzle";
import { MazePaper } from "./elements/MazePaper";
import { Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { usePlayerOrGuest } from "../utils/usePlayerOrGuest";
import { PuzzlePreview } from "./elements/PuzzlePreview";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router";
import { periodicalName } from "../utils/periodicalName";
import { CosmeticPuzzleMap, defaultCosmetics } from "../shared/Cosmetics";
import { Points } from "./elements/Emoji";
import PageVisibility from "react-page-visibility";
import { getImage, storeImage } from "../utils/puzzleImageCacheUtils";
import { lt } from "../utils/lt";
import { Application } from "@pixi/app";
import { generateBoardImage } from "../game/utils/generateBoardImage";
import { useTextures } from "../game/sprites/TextureDaemon";
import { cst } from "../utils/constants";
import { Renderer } from "@pixi/core";

interface PuzzlesProps {
  player: Player | undefined | null;
}

export const Puzzles = (props: PuzzlesProps) => {
  const { player } = props;

  const [puzzleMap, setPuzzleMap] = useState<undefined | CosmeticPuzzleMap>();

  const playerOrGuest = usePlayerOrGuest(player);

  const refreshPuzzleMap = useCallback(() => {
    getPuzzleService()
      .all()
      .then((response) => {
        if (isPuzzleMap(response)) {
          setPuzzleMap(response);
        } else {
          console.error(response);
        }
      });
  }, [setPuzzleMap]);

  useEffect(refreshPuzzleMap, [refreshPuzzleMap]);

  const textures = useTextures();

  useEffect(() => {
    if (
      playerOrGuest &&
      isPlayer(playerOrGuest) &&
      puzzleMap &&
      Object.values(puzzleMap.map).some((info) => !info.roundImage) &&
      textures["tile.png"] !== undefined
    ) {
      let app: Application | undefined;
      setPuzzleMap(() => ({
        map: Object.fromEntries(
          Object.entries(puzzleMap.map).map(([period, info]) => {
            let roundImage = lt(
              getImage(
                +period,
                playerOrGuest.id,
                info.round,
                info.solution?.solution
              ),
              (cachedImage) => {
                if (cachedImage) return cachedImage;
                if (app === undefined) {
                  app = new Application();
                }
                let generatedImage = generateBoardImage(
                  app.renderer as Renderer,
                  info.solution?.cosmetics || defaultCosmetics,
                  textures,
                  info.solution?.solution.layout.towers || [],
                  info.round.board,
                  cst.tileSize / 2
                );
                storeImage(
                  +period,
                  playerOrGuest.id,
                  info.round,
                  generatedImage,
                  info.solution?.solution
                );
                return generatedImage;
              }
            );
            return [+period, { ...info, roundImage }];
          })
        ),
      }));
      app?.destroy();
    }
  }, [playerOrGuest, puzzleMap, textures]);

  const navigate = useNavigate();

  const [openHelp, setOpenHelp] = useState(false);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          width: 1,
          justifyContent: "center",
        }}
      >
        {puzzleMap && playerOrGuest && (
          <PageVisibility
            onChange={(visible) => {
              if (visible) refreshPuzzleMap();
            }}
          >
            <Box
              maxWidth="sm"
              mx="auto"
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                pt: 2,
              }}
            >
              <MazePaper
                goBack={() => navigate("/")}
                title={
                  <Fragment>
                    Puzzle{" "}
                    <Tooltip
                      disableHoverListener
                      disableFocusListener
                      disableTouchListener
                      open={openHelp}
                      title={
                        <Typography>
                          In <b>Puzzle</b> mode you have much longer to create
                          your maze <br />
                          <br />
                          There are always seven different <b>Puzzles</b>{" "}
                          available that refreshes at a different intervals:
                          <br />
                          <br />
                          Every <b>Week</b>, <b>24</b> hours, <b>12</b> hours,{" "}
                          <b>6</b> hours,
                          <br />
                          <b>3</b> hours, <b>1</b> hour and <b>15</b> minutes
                          <br /> <br />
                          The top players of Puzzles are given a <Points />{" "}
                          reward, the more players the bigger the reward!
                          <br /> <br />
                          When mazers support <b>maze.game</b> by buying{" "}
                          <Points /> in the{" "}
                          <Link
                            underline="none"
                            href="#"
                            onClick={() => navigate("/store")}
                          >
                            Store
                          </Link>
                          , approx. the same amount of <Points /> will be
                          divided among the prize pools of all upcoming{" "}
                          <b>Puzzles</b>.
                          <br /> <br />
                          If a <b>Puzzle</b> has less than three submissions
                          when the time runs out, the round will not be
                          refreshed.
                          <br /> <br /> You can click{" "}
                          <InfoIcon
                            sx={{ transform: "translate(0%, 25%)" }}
                          />{" "}
                          to see past results for that interval
                        </Typography>
                      }
                    >
                      <IconButton
                        sx={{ transform: "translate(0%, -5%)" }}
                        onClick={() => setOpenHelp((s) => !s)}
                      >
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                }
              >
                <Fragment>
                  <Grid
                    container
                    spacing={{ xs: 0.0, sm: 0.5 }}
                    justifyContent="center"
                    sx={{ display: undefined }}
                  >
                    {Object.entries(puzzleMap.map)
                      .sort(([a, _], [b, __]) => +b - +a)
                      .map(([periodicalId, info], index) => (
                        <Grid item xs={6} md={6} key={"puzzle" + index}>
                          <PuzzlePreview
                            info={info}
                            onTimeout={refreshPuzzleMap}
                            onInfoClick={() => {
                              navigate("/puzzle/" + periodicalId);
                            }}
                            thumbnail
                            title={periodicalName(+periodicalId)}
                            onClick={() => {
                              if (!info.solution) {
                                navigate("/puzzle/" + periodicalId + "/play");
                              } else {
                                navigate("/puzzle/" + periodicalId);
                              }
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Fragment>
              </MazePaper>
            </Box>
          </PageVisibility>
        )}
      </Box>
    </Fragment>
  );
};
