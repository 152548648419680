import { Graphics, Sprite, Stage, Container, useTick } from "@inlet/react-pixi";
import { Box } from "@mui/material";
import { Graphics as PixiGraphics } from "@pixi/graphics";
import useResizeObserver from "@react-hook/resize-observer";
import React, { useRef, useState } from "react";
import { invert } from "../../game/Runner";
import { ballFrames, ballFrozenFrames } from "../../game/sprites/animations";
import { useTextures } from "../../game/sprites/TextureDaemon";
import { useAnimation } from "../../game/utils/useAnimation";
import { useRunnerState } from "../../game/utils/useRunnerState";
import { BallCosmetics, defaultCosmetics } from "../../shared/Cosmetics";
import { Path } from "../../shared/Path";
import { lt } from "../../utils/lt";

interface ScoreSliderProps {
  max: number;
  scoreReference: React.MutableRefObject<number>;
  completed: boolean;
  setScore: (score: number) => void;
  pause: () => void;
  unPause: () => void;
  path: Path;
  ballCosmetics: BallCosmetics;
}

const ballSize = 32;

export const ScoreSlider = (props: ScoreSliderProps) => {
  const [width, setWidth] = useState<number>();

  const target = useRef<any>();

  useResizeObserver(target, (event) => {
    setWidth(event.contentRect.width);
  });

  return (
    <Box
      height={ballSize * 2}
      width={"calc(100% + 24px)"}
      sx={{ mx: -1.5, my: -2 }}
      ref={target}
      zIndex={0}
    >
      {width && (
        <Stage
          height={ballSize * 2}
          width={width}
          options={{ backgroundAlpha: 0 }}
        >
          <ScoreSliderPixiElement {...props} width={width} />
        </Stage>
      )}
    </Box>
  );
};

interface ScoreSliderPixiElementProps extends ScoreSliderProps {
  width: number;
}

export const ScoreSliderPixiElement = (props: ScoreSliderPixiElementProps) => {
  const {
    width,
    max,
    scoreReference,
    setScore,
    pause,
    unPause,
    path,
    ballCosmetics = defaultCosmetics.ball,
  } = props;

  const [internalScore, setInternalScore] = useState(scoreReference.current);

  const dragging = useRef(false);

  const textures = useTextures();

  const { getRunnerState, slowInstances } = useRunnerState(() => {}, path);

  useTick(() => {
    setInternalScore((s) =>
      s === scoreReference.current ? s : scoreReference.current
    );
  });

  const animation = useAnimation(ballFrames, textures);
  const frozenAnimation = useAnimation(ballFrozenFrames, textures);

  return (
    <Container>
      <Container y={ballSize / 2} interactiveChildren={false}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.beginFill(0x828282);
            graphics.drawRect(
              ballSize / 2,
              ballSize / 2 - 1,
              width - ballSize,
              2
            );
            graphics.endFill();
          }}
        />
        <Container
          mask={lt(new PixiGraphics(), (graphics) => {
            graphics.beginFill(0xff0000);
            graphics.drawRect(
              0,
              0,
              (internalScore / max) * (width - ballSize) + ballSize / 2,
              ballSize * 2
            );
            graphics.endFill();
            return graphics;
          })}
        >
          <Graphics
            draw={(graphics) => {
              graphics.clear();
              graphics.beginFill(0xffffff);
              graphics.drawRect(
                ballSize / 2,
                ballSize / 2 - 1,
                ((width - ballSize) * path.result) / max,
                2
              );
              graphics.endFill();
            }}
          />
          <Graphics
            draw={(graphics) => {
              graphics.clear();
              graphics.beginFill(0xffffff);
              graphics.drawRect(
                ((width - ballSize) * path.result) / max + ballSize / 2 - 1,
                (ballSize * 3) / 16,
                2,
                (ballSize * 5) / 8
              );
              graphics.endFill();
            }}
          />
          {(invert
            ? slowInstances?.map(({ start, end }) => ({
                start: Math.max(path.result - end, 0),
                end: path.result - start,
              }))
            : slowInstances
          )?.map(({ start, end }, index) => {
            let newEnd = Math.min(end, path.result);
            return (
              <Graphics
                key={index}
                draw={(graphics) => {
                  graphics.clear();
                  graphics.beginFill(0x62c5ef);
                  graphics.drawRoundedRect(
                    (start / max) * (width - ballSize) + (ballSize * 2) / 4,
                    ballSize / 4,
                    ((newEnd - start) / max) * (width - ballSize),
                    ballSize / 2,
                    ballSize / 8
                  );
                  graphics.alpha = 0.3;
                  graphics.endFill();
                }}
              />
            );
          })}
        </Container>
        {getRunnerState &&
          lt(
            getRunnerState(
              invert ? path.result - internalScore : internalScore
            ),
            (state) => (
              <Container
                y={0}
                x={Math.min(
                  (internalScore / max) * (width - ballSize) + ballSize,
                  width
                )}
              >
                <Sprite
                  texture={
                    animation[
                      Math.floor(
                        ((width - ballSize) * (internalScore / max) * 360) /
                          ballSize /
                          Math.PI
                      ) % animation.length
                    ]
                  }
                  rotation={Math.PI / 2}
                  y={0}
                  x={0}
                  tint={
                    internalScore >= path.result
                      ? 0xa1a1a1
                      : state.isSlowed
                      ? ballCosmetics.color.frozenTint
                      : ballCosmetics.color.color.tint
                  }
                  height={ballSize}
                  width={ballSize}
                />
                <Sprite
                  texture={
                    frozenAnimation[
                      Math.floor(
                        ((width - ballSize) * (internalScore / max) * 360) /
                          ballSize /
                          Math.PI
                      ) % animation.length
                    ]
                  }
                  visible={state.isSlowed && internalScore < path.result}
                  rotation={Math.PI / 2}
                  y={0}
                  x={0}
                  tint={0xccffff}
                  height={ballSize}
                  width={ballSize}
                />
                <Sprite
                  texture={textures["ballShade.png"]}
                  y={0}
                  x={-ballSize}
                  tint={defaultCosmetics.ball.color.color.tint}
                  height={ballSize}
                  width={ballSize}
                  alpha={0.5}
                />
              </Container>
            )
          )}
      </Container>
      <Graphics
        interactive
        alpha={0.0}
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(0);
          graphics.drawRect(0, 0, width + ballSize, ballSize * 2);
          graphics.endFill();
        }}
        pointerdown={(event) => {
          pause();
          dragging.current = true;
          const newScore =
            (max *
              Math.min(
                Math.max(event.data.global.x - ballSize / 2, 0),
                width - ballSize
              )) /
            (width - ballSize);
          setInternalScore(newScore);
          setScore(newScore);
          scoreReference.current = newScore;
        }}
        pointerup={() => {
          unPause();
          dragging.current = false;
        }}
        pointerupoutside={() => {
          unPause();
          dragging.current = false;
        }}
        pointermove={(event) => {
          if (dragging.current) {
            let newScore =
              (max *
                Math.min(
                  Math.max(event.data.global.x - ballSize / 2, 0),
                  width - ballSize
                )) /
              (width - ballSize);
            if (newScore === 0) {
              newScore += Math.random() / 10000000;
            } else if (newScore === max) {
              newScore -= Math.random() / 10000000;
            }
            setInternalScore(newScore);
            setScore(newScore);
            scoreReference.current = newScore;
          }
        }}
      />
    </Container>
  );
};
