import { Paper, Typography } from "@mui/material";
import {
  AchievementStatus,
} from "../../shared/Achievement";
import { areaToString } from "./AchievementInformationElement";

interface AchievementStatusProps {
  achievementStatus: AchievementStatus;
}

export const AchievementStatusElement = (props: AchievementStatusProps) => {
  const { achievementStatus } = props;

  const achievementInformation = achievementStatus.information;

  const area = areaToString(achievementInformation.area);

  return (
    <Paper sx={{p:1,my: 1}} elevation={achievementStatus.achieved ? 21 : 1}>
      <Typography variant="body2" align="left" color={achievementStatus.achieved ? "" : "gray"}>
        <b>
          {area === "" ? "" : area + ": "}
          {achievementInformation.name}{" "}
        </b>
        <br />
        {achievementInformation.description}
      </Typography>
    </Paper>
  );
};
