import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { MazePaper } from "./elements/MazePaper";
import { getChallengeService } from "../network/ChallengeService";
import {
  isPeriodicalChallenges,
  PeriodicalChallenge,
} from "../shared/Challenge";
import { ChallengeListElement } from "./elements/ChallengeListElement";
import { useNavigate } from "react-router";
import SportsIcon from "@mui/icons-material/Sports";
import Background3 from "../resources/background3.png";
import { LandingButton } from "./elements/LandingButton";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useSecondsPassed } from "../utils/useSecondsPassed";
import { secondsToHHMMSS } from "../utils/secToHHMMSS";
import { lt } from "../utils/lt";
import { useStoredPrioritizedList } from "../utils/useStoredPrioritizedList";
import PageVisibility from "react-page-visibility";

export const DailyChallengePage = () => {
  const [periodicals, setPeriodicals] = useState<PeriodicalChallenge[]>();
  const [current, setCurrent] = useState<PeriodicalChallenge>();

  const refresh = useCallback(() => {
    getChallengeService()
      .periodicals()
      .then((response) => {
        if (isPeriodicalChallenges(response)) {
          setCurrent(response[0]);
          setPeriodicals(response.slice(1));
        }
      });
  }, [setCurrent, setPeriodicals]);

  useEffect(refresh, [refresh]);

  const { secondsPassed } = useSecondsPassed();

  const navigate = useNavigate();

  const [prioritizedList] = useStoredPrioritizedList("replays");

  return (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 2 }}>
      <PageVisibility
        onChange={(isVisible) => {
          if (isVisible) refresh();
        }}
      >
        <MazePaper title="Daily Challenge" goBack={() => navigate("/")}>
          <Tooltip
            title={
              current && current.playerCount > 0 ? (
                <Box
                  maxHeight={300}
                  sx={{
                    overflow: "auto",
                    scrollbarGutter: "stable",
                    pr: 1,
                    mr: -1,
                    my: -0.5,
                  }}
                >
                  {current
                    ? current.players
                        .sort((a, b) => {
                          const aPriority = lt(
                            prioritizedList.indexOf(a.id),
                            (priority) =>
                              priority !== -1
                                ? priority
                                : prioritizedList.length +
                                  (a.guest ? -a.id : a.id)
                          );
                          const bPriority = lt(
                            prioritizedList.indexOf(b.id),
                            (priority) =>
                              priority !== -1
                                ? priority
                                : prioritizedList.length +
                                  (b.guest ? -b.id : b.id)
                          );
                          return aPriority - bPriority;
                        })
                        .map((player) => (
                          <Typography>
                            {player.guest ? <i>{player.name}</i> : player.name}
                          </Typography>
                        ))
                    : undefined}
                </Box>
              ) : (
                ""
              )
            }
          >
            <span>
              <LandingButton
                completed={
                  current &&
                  current.progress >= current.parameters.settings.totalRounds
                }
                background={Background3}
                icon={<SportsIcon sx={{ fontSize: 70 }} />}
                title={"Play"}
                midText={
                  current
                    ? current.parameters.settings.totalRounds +
                      "×" +
                      current.parameters.settings.roundDuration +
                      "s"
                    : ""
                }
                midIcon={<DisplaySettingsIcon />}
                players={current ? current.players.length : 0}
                duration={
                  <Typography align="left">
                    {current &&
                      secondsToHHMMSS(
                        current.parameters.settings.deadline! / 1000 -
                          secondsPassed
                      )}
                  </Typography>
                }
                mode="multiplayer"
                path={"/challenge/daily/" + (current ? current.id : "")}
              />
            </span>
          </Tooltip>
          {periodicals && (
            <Fragment>
              <ChallengeListElement
                daily
                title={"Previous"}
                challenges={periodicals.map((periodical, index) => ({
                  host: {
                    name: lt(
                      new Date(Date.now() - 24 * 60 * 60 * 1000 * (index + 1)),
                      (t) =>
                        t.toLocaleDateString(undefined, {
                          day: "numeric",
                          month: "numeric",
                          weekday: "short",
                        })
                    ),
                    id: -1,
                    guest: false,
                  },
                  players: periodical.players,
                  progress: periodical.progress,
                  parameters: periodical.parameters,
                }))}
              />
            </Fragment>
          )}
        </MazePaper>
      </PageVisibility>
    </Box>
  );
};
