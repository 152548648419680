import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Round } from "../../shared/Round";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { theme } from "../../utils/theme";
import { ButtonContent } from "./RoundResultElement";
import { ReplayElement } from "./ReplayElement";
import { ScoreCard } from "./ScoreCard";
import { useStoredPrioritizedList } from "../../utils/useStoredPrioritizedList";
import { lt } from "../../utils/lt";
import { ScoreSlider } from "./ScoreSlider";
import { CosmeticPlayerSolution } from "../../shared/Cosmetics";
import useResizeObserver from "@react-hook/resize-observer";

export type ScoreOfInterest = {
  playerIds: number[];
  score: number;
};

export interface MultiplayerRoundElementProps {
  round: Round;
  playerSolution: CosmeticPlayerSolution;
  allSolutions?: CosmeticPlayerSolution[];
  runnerCompleted?: () => void;
  hiddenOpponents?: boolean;
  playerRanks?: Map<number, number>;
}

export const MultiplayerRoundElement = (
  props: MultiplayerRoundElementProps
) => {
  const {
    round,
    playerSolution,
    allSolutions = [],
    hiddenOpponents = false,
    playerRanks,
    runnerCompleted = () => {},
  } = props;

  const isXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const maxReplays = useMemo(() => (isMd ? 6 : 4), [isMd]);

  const solutions = useMemo(
    () => [
      playerSolution,
      ...allSolutions.filter((it) => it.player.id !== playerSolution.player.id),
    ],
    [playerSolution, allSolutions]
  );

  const [prioritizedList, prioritize] = useStoredPrioritizedList("replays");

  const shuffledSolutions = useMemo(
    () =>
      allSolutions
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value),
    [allSolutions]
  );

  const [keyIndex, increment] = useReducer((k: number, _: void) => k + 1, 0);

  const shownSolutions = useMemo(
    () => [
      playerSolution,
      ...solutions
        .slice(1)
        .sort((a, b) => {
          const aPriority = lt(
            prioritizedList.indexOf(a.player.id),
            (priority) =>
              priority !== -1
                ? priority
                : prioritizedList.length +
                  (a.player.guest ? -a.player.id : a.player.id)
          );
          const bPriority = lt(
            prioritizedList.indexOf(b.player.id),
            (priority) =>
              priority !== -1
                ? priority
                : prioritizedList.length +
                  (b.player.guest ? -b.player.id : b.player.id)
          );
          return aPriority - bPriority;
        })
        .slice(0, maxReplays - 2),
    ],
    [solutions, maxReplays, prioritizedList, playerSolution]
  );

  const scoresOfInterest: ScoreOfInterest[] = useMemo(() => {
    const interestingScores = solutions.flatMap((playerSolution) => [
      [playerSolution.player.id, playerSolution.solution.path.result],
      ...(playerSolution.solution.slowIntervals
        ? playerSolution.solution.slowIntervals.flatMap((interval) => [
            [playerSolution.player.id, interval.from],
            [playerSolution.player.id, interval.to],
          ])
        : []),
    ]);

    const uniquelyInterestingScores = new Set(
      interestingScores.map((a) => a[1])
    );

    const bestScore = solutions.reduce(
      (acc, playerSolution) =>
        playerSolution.solution.path.result > acc
          ? playerSolution.solution.path.result
          : acc,
      0
    );

    return [
      { score: 0, playerIds: solutions.map((it) => it.player.id) },
      { score: 0, playerIds: solutions.map((it) => it.player.id) },
      ...Array.from(uniquelyInterestingScores)
        .filter((score) => score <= bestScore)
        .map((score) => ({
          playerIds: interestingScores
            .filter((a) => a[1] === score)
            .map((a) => a[0]),
          score: score,
        }))
        .sort((a, b) => a.score - b.score),
    ];
  }, [solutions]);

  const [completed, setCompleted] = useState(false);

  const bestSolution = useMemo(() => {
    let bestSolution = playerSolution;
    allSolutions.forEach((otherPlayerSolution) => {
      if (
        otherPlayerSolution.solution.path.result >
        bestSolution.solution.path.result
      ) {
        bestSolution = otherPlayerSolution;
      }
    });
    return bestSolution;
  }, [allSolutions, playerSolution]);

  const [forcedScore, setForcedScore] = useState(0);

  const [pause, setPause] = useState(false);

  const [buttonContent, setButtonContent] = useState<ButtonContent>({
    text: "Skip",
    icon: <SkipNextIcon />,
    onClick: () => {
      setPause(true);
      setCompleted(true);
      setForcedScore(bestSolution.solution.path.result);
    },
  });

  useEffect(() => {
    if (completed) {
      setButtonContent({
        text: "Continue",
        icon: <NavigateNextIcon />,
        onClick: runnerCompleted,
      });
    } else {
      setButtonContent({
        text: "Skip",
        icon: <SkipNextIcon />,
        onClick: () => {
          setPause(true);
          setCompleted(true);
          setForcedScore(bestSolution.solution.path.result);
          currentScoreReference.current = bestSolution.solution.path.result;
        },
      });
    }
  }, [setButtonContent, completed, runnerCompleted, bestSolution]);

  const ranks = useMemo(() => {
    const rankMap = new Map<number, number>();

    if (playerRanks) {
      return playerRanks;
    }

    allSolutions
      .map((playerSolution) => [
        playerSolution.player.id,
        playerSolution.solution.path.result,
      ])
      .sort((a, b) => b[1] - a[1])
      .forEach(([playerId, result], index) => {
        rankMap.set(playerId, index + 1);
      });
    return rankMap;
  }, [playerRanks, allSolutions]);

  const interestIndexReference = useRef(0);
  const setInterestIndexReference = useRef((a: number) => {});
  const currentScoreReference = useRef(0);

  const setScore = useCallback(
    (a: number) => {
      currentScoreReference.current = a;
      while (
        interestIndexReference.current < scoresOfInterest.length - 1 &&
        a > scoresOfInterest[interestIndexReference.current + 1].score
      ) {
        interestIndexReference.current += 1;
      }
      setInterestIndexReference.current(interestIndexReference.current);
    },
    [scoresOfInterest]
  );

  useEffect(() => {
    if (pause) {
      let index = scoresOfInterest.findIndex(
        (scoreOfInterest, index) =>
          scoreOfInterest.score <= forcedScore &&
          (index === scoresOfInterest.length - 1 ||
            scoresOfInterest[index + 1].score > forcedScore)
      );
      if (index !== -1) {
        interestIndexReference.current = index;
        setInterestIndexReference.current(interestIndexReference.current);
        if (completed && index !== scoresOfInterest.length - 1) {
          setCompleted(false);
        } else if (!completed && index === scoresOfInterest.length - 1) {
          setCompleted(true);
        }
      }
    }
  }, [forcedScore, pause, scoresOfInterest, completed]);

  const [startingWidth, setStartingWidth] = useState<number | undefined>();

  const target = useRef<any>();

  useResizeObserver(target, (event) => {
    setStartingWidth(Math.round(event.contentRect.width));
  });

  const [readyOne, setReadyOne] = useState(false);
  const [readyTwo, setReadyTwo] = useState(shownSolutions[1] === undefined);
  const [readyThree, setReadyThree] = useState(shownSolutions[2] === undefined);
  const [readyFour, setReadyFour] = useState(shownSolutions[3] === undefined);
  const [readyFive, setReadyFive] = useState(shownSolutions[4] === undefined);

  const [start, setStart] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (readyOne && readyTwo && readyThree && readyFour && readyFive)
      window.setTimeout(() => mounted && setStart(true), 200);
    return () => {
      mounted = false;
    };
  }, [readyOne, readyTwo, readyThree, readyFour, readyFive]);
  return (
    <Box sx={{ px: { xs: 0.5, sm: 1, md: 0 } }} width={1} height={1}>
      {hiddenOpponents ? (
        isXs && !isSm ? (
          <Grid container direction="column" height={1} display="flex">
            <Box width={1} sx={{ px: 0, pt: { xs: 1, sm: 2, zIndex: 10 } }}>
              <ReplayElement
                startingWidth={window.innerWidth - 8}
                isPlayer
                forcedScore={forcedScore}
                playerSolution={shownSolutions[0]}
                maxScore={bestSolution.solution.path.result}
                completed={completed}
                ready={() => setStart(true)}
                start={start}
                place={ranks.get(shownSolutions[0].player.id)}
                round={round}
                pause={pause}
                setScore={setScore}
                runnerCompleted={() => setCompleted(true)}
              />
            </Box>
            <Grid container justifyContent="center" sx={{ pb: 1 }}>
              <Box width={1}>
                <Paper
                  elevation={11}
                  sx={{
                    pt: 0.5,
                    pb: 0.5,
                    mx: 0.5,
                    mt: 0,
                    px: 2,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                  }}
                >
                  <ScoreSlider
                    ballCosmetics={playerSolution.cosmetics.ball}
                    max={bestSolution.solution.path.result}
                    scoreReference={currentScoreReference}
                    setScore={setForcedScore}
                    completed={completed}
                    pause={() => setPause(true)}
                    unPause={() => setPause(false)}
                    path={playerSolution.solution.path}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs>
              <ScoreCard
                title="Scores"
                playerSolution={playerSolution}
                allSolutions={shuffledSolutions}
                scoresOfInterest={scoresOfInterest}
                setInterestIndexReference={setInterestIndexReference}
                completed={completed}
                watched={[playerSolution.player.id]}
                ranks={ranks}
                buttonContent={buttonContent}
                clickable={false}
                titleButton
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            spacing={{ xs: 0.5, sm: 1, lg: 2 }}
          >
            {startingWidth && (
              <Grid item sm={8} md={6} lg={5} xl={4}>
                <Box width={1} sx={{ px: 0, pt: { xs: 1, sm: 2 } }}>
                  <ReplayElement
                    isPlayer
                    startingWidth={startingWidth}
                    forcedScore={forcedScore}
                    playerSolution={shownSolutions[0]}
                    maxScore={bestSolution.solution.path.result}
                    completed={completed}
                    ready={() => setStart(true)}
                    start={start}
                    place={ranks.get(shownSolutions[0].player.id)}
                    round={round}
                    pause={pause}
                    setScore={setScore}
                    runnerCompleted={() => setCompleted(true)}
                  />
                  <Grid container justifyContent="center">
                    <Box width={{ xs: 0.9, sm: 0.98 }} zIndex={0}>
                      <Paper
                        elevation={11}
                        sx={{
                          pt: 0.5,
                          pb: 0.75,
                          mx: 0.5,
                          mt: 0,
                          px: 2,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 16,
                          borderBottomLeftRadius: 16,
                        }}
                      >
                        <ScoreSlider
                          ballCosmetics={playerSolution.cosmetics.ball}
                          max={bestSolution.solution.path.result}
                          scoreReference={currentScoreReference}
                          setScore={setForcedScore}
                          completed={completed}
                          pause={() => setPause(true)}
                          unPause={() => setPause(false)}
                          path={playerSolution.solution.path}
                        />
                      </Paper>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid item sm={4} md={4} lg={3} xl={2}>
              <ScoreCard
                title="Scores"
                playerSolution={playerSolution}
                allSolutions={shuffledSolutions}
                scoresOfInterest={scoresOfInterest}
                setInterestIndexReference={setInterestIndexReference}
                completed={completed}
                watched={[playerSolution.player.id]}
                ranks={ranks}
                buttonContent={buttonContent}
                clickable={false}
                titleButton
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item sm={8} md={6} lg={5} xl={4} ref={target} />
          </Grid>
        )
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          sx={{ pt: { xs: 1, sm: 3 } }}
        >
          <Grid item xs={12} lg={8} xl={6}>
            <Grid
              container
              justifyContent={solutions.length === 1 ? "center" : "left"}
              alignItems="stretch"
              spacing={{ xs: 0.5, sm: 1 }}
            >
              {shownSolutions[0] && startingWidth && (
                <Grid item xs={6} md={4}>
                  <ReplayElement
                    isPlayer
                    forcedScore={forcedScore}
                    playerSolution={shownSolutions[0]}
                    maxScore={bestSolution.solution.path.result}
                    completed={completed}
                    ready={() => setReadyOne(true)}
                    start={start}
                    place={ranks.get(shownSolutions[0].player.id)}
                    startingWidth={startingWidth}
                    round={round}
                    pause={pause}
                    setScore={setScore}
                    runnerCompleted={() => setCompleted(true)}
                  />
                </Grid>
              )}
              {isXs && !isMd && startingWidth && (
                <Fragment>
                  <Grid item xs={6} key={"scorecardXs"}>
                    <ScoreCard
                      title={"Scores"}
                      isRemainingPlayers
                      playerSolution={playerSolution}
                      allSolutions={solutions}
                      scoresOfInterest={scoresOfInterest}
                      setInterestIndexReference={setInterestIndexReference}
                      completed={completed}
                      watched={shownSolutions.map(
                        (solution) => solution.player.id
                      )}
                      clickable
                      prioritize={(id) => {
                        prioritize(id);
                        increment();
                      }}
                      ranks={ranks}
                      buttonContent={buttonContent}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mx: 1.5 }}>
                    <ScoreSlider
                      ballCosmetics={playerSolution.cosmetics.ball}
                      max={bestSolution.solution.path.result}
                      scoreReference={currentScoreReference}
                      setScore={setForcedScore}
                      completed={completed}
                      pause={() => setPause(true)}
                      unPause={() => setPause(false)}
                      path={playerSolution.solution.path}
                    />
                  </Grid>
                </Fragment>
              )}
              {shownSolutions[1] && startingWidth && (
                <Grid item xs={6} md={4} key={(keyIndex + 3) % 4}>
                  <ReplayElement
                    playerSolution={shownSolutions[1]}
                    completed={completed}
                    ready={() => setReadyTwo(true)}
                    start={start}
                    place={ranks.get(shownSolutions[1].player.id)}
                    round={round}
                    startingWidth={startingWidth}
                    pause={pause}
                    forcedScore={forcedScore}
                    startingScoreReference={currentScoreReference}
                  />
                </Grid>
              )}
              {isMd && startingWidth && (
                <Fragment>
                  <Grid item md={4} key={"scorecard"}>
                    <ScoreCard
                      title={"Scores"}
                      isRemainingPlayers
                      playerSolution={playerSolution}
                      allSolutions={solutions}
                      scoresOfInterest={scoresOfInterest}
                      setInterestIndexReference={setInterestIndexReference}
                      completed={completed}
                      watched={shownSolutions.map(
                        (solution) => solution.player.id
                      )}
                      clickable
                      prioritize={(id) => {
                        prioritize(id);
                        increment();
                      }}
                      ranks={ranks}
                      buttonContent={buttonContent}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={shownSolutions[1] ? 0 : 2} />
                      <Grid item xs={12} md={shownSolutions[1] ? 12 : 8}>
                        <ScoreSlider
                          ballCosmetics={playerSolution.cosmetics.ball}
                          max={bestSolution.solution.path.result}
                          scoreReference={currentScoreReference}
                          setScore={setForcedScore}
                          completed={completed}
                          pause={() => setPause(true)}
                          unPause={() => setPause(false)}
                          path={playerSolution.solution.path}
                        />
                      </Grid>
                      <Grid item xs={12} md={shownSolutions[1] ? 0 : 2} />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              {shownSolutions[2] && startingWidth && (
                <Grid item xs={6} md={4} key={(keyIndex + 2) % 4}>
                  <ReplayElement
                    playerSolution={shownSolutions[2]}
                    completed={completed}
                    ready={() => setReadyThree(true)}
                    start={start}
                    place={ranks.get(shownSolutions[2].player.id)}
                    round={round}
                    startingWidth={startingWidth}
                    pause={pause}
                    forcedScore={forcedScore}
                    startingScoreReference={currentScoreReference}
                  />
                </Grid>
              )}
              {shownSolutions[3] && startingWidth && (
                <Grid item xs={6} md={4} key={(keyIndex + 1) % 4}>
                  <ReplayElement
                    playerSolution={shownSolutions[3]}
                    completed={completed}
                    ready={() => setReadyFour(true)}
                    start={start}
                    place={ranks.get(shownSolutions[3].player.id)}
                    round={round}
                    startingWidth={startingWidth}
                    pause={pause}
                    forcedScore={forcedScore}
                    startingScoreReference={currentScoreReference}
                  />
                </Grid>
              )}
              {shownSolutions[4] && startingWidth && (
                <Grid item xs={6} md={4} key={keyIndex % 4}>
                  <ReplayElement
                    playerSolution={shownSolutions[4]}
                    completed={completed}
                    ready={() => setReadyFive(true)}
                    start={start}
                    place={ranks.get(shownSolutions[4].player.id)}
                    round={round}
                    startingWidth={startingWidth}
                    pause={pause}
                    forcedScore={forcedScore}
                    startingScoreReference={currentScoreReference}
                  />
                </Grid>
              )}
              <Grid item xs={6} md={4} ref={target} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
