import React, { Fragment, useEffect, useState } from "react";
import { getTutorialService } from "../network/TutorialService";
import { InteractiveGame } from "../pages/elements/InteractiveGame";
import { Layout } from "../shared/Layout";
import { isPath, Path } from "../shared/Path";
import { Round } from "../shared/Round";
import exampleBoard from "./tutorial/exampleBoard.json";

export const GameTutorial = () => {
  const [path, setPath] = useState<Path | undefined>();

  const [round, setRound] = useState<Round>({
    towers: 3,
    claps: 0,
    board: {
      width: 16,
      height: 18,
      staticTowers: [],
      startArea: [
        { x: 8, y: 18 },
        { x: 7, y: 18 },
      ],
      endArea: [
        { x: 8, y: -1 },
        { x: 7, y: -1 },
      ],
    },
  });

  const [layout, setLayout] = useState<Layout | undefined>();

  const [tutorialStep, setTutorialStep] = useState<number>(0);

  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    if (!refresh) setRefresh(true);
  }, [refresh, setRefresh]);

  return (
    <Fragment>
      {refresh && (
        <InteractiveGame
          round={round}
          path={path}
          submitLayout={(layout) => {
            getTutorialService()
              .getResult(layout)
              .then((response) =>
                isPath(response) ? setPath(response) : console.log(response)
              );
          }}
          showSkip={false}
          tutorialStartStep={tutorialStep}
          startingLayout={layout}
          resetRound={(l) => {
            var placedClaps = 0;
            l.towers.forEach((tower) => {
              if (tower.clap) {
                placedClaps++;
              }
            });
            setRound({
              towers: 10 - l.towers.length,
              claps: 1 - placedClaps,
              board: exampleBoard,
            });
            setTutorialStep(32);
            setLayout(l);
            setPath(undefined);
            setRefresh(false);
          }}
          duration={0}
          tutorial
        />
      )}
    </Fragment>
  );
};
