import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useCallback, useEffect, useMemo, useState } from "react";
import { importCustomPattern } from "../game/utils/CustomPattern";
import {
  Cosmetics,
  Pattern,
  Rarity,
  UniqueBlockPattern,
} from "../shared/Cosmetics";
import { generateBoardImage } from "../game/utils/generateBoardImage";
import { Application } from "@pixi/app";
import { Renderer } from "@pixi/core";
import { exampleLayout, exampleRound } from "./SpriteTest";
import { cst } from "../utils/constants";
import { useTextures } from "../game/sprites/TextureDaemon";
import { InputPicker } from "./elements/InputPicker";
import { allUniqueSolidBlockPatterns } from "../utils/chosentops";

const testCosmetics: Cosmetics = {
  block: {
    pattern: {
      pattern: Pattern.HARLEQUIN,
      firstColor: {
        color: {
          name: "Lemon",
          tint: 15662882,
        },
        indentTint: 14083358,
        rarity: Rarity.UNCOMMON,
      },
      secondColor: {
        color: {
          name: "Hot Pink",
          tint: 16712333,
        },
        indentTint: 15008126,
        rarity: Rarity.LEGENDARY,
      },
    },
  },
  ball: {
    color: {
      color: {
        name: "Supporter",
        tint: 3705343,
      },
      frozenTint: 40447,
      rarity: Rarity.SUPPORTER,
    },
  },
};

export const GithubPattern = () => {
  const [repository, setRepository] = useState<string>(
    "mazedotgame/pattern-template"
  );

  const [image, setImage] = useState<string>();

  const app = useMemo(() => new Application(), []);

  const textures = useTextures();

  const [firstColor, setFirstColor] = useState<UniqueBlockPattern>();
  const [secondColor, setSecondColor] = useState<UniqueBlockPattern>();
  const cosmetics: Cosmetics | undefined = useMemo(
    () =>
      firstColor &&
      secondColor && {
        ...testCosmetics,
        block: {
          pattern: {
            ...testCosmetics.block.pattern,
            firstColor: firstColor.blockPattern.firstColor,
            secondColor: secondColor.blockPattern.firstColor,
          },
        },
      },
    [firstColor, secondColor]
  );

  const loadPattern = useCallback(() => {
    if (cosmetics && repository && textures["tile.png"]) {
      importCustomPattern(repository, (customPattern) => {
        setImage(
          generateBoardImage(
            app.renderer as Renderer,
            cosmetics,
            textures,
            exampleLayout.towers,
            exampleRound.board,
            cst.tileSize,
            customPattern
          )
        );
      });
    }
  }, [repository, cosmetics, setImage, textures, app]);

  const [tipOpen, setTipOpen] = useState<boolean | undefined>(true);

  useEffect(() => {
    window.setTimeout(() => {
      setTipOpen(undefined);
    }, 4000);
  });

  return (
    <Box sx={{ pt: 3 }} maxWidth="lg" mx="auto" height="90vh">
      <Stack direction="row" justifyContent="center" spacing={1}>
        <TextField
          sx={{ width: 600, py: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: 0 }}>
                <Tooltip
                  arrow
                  title="Click here to see Pattern Template repository!"
                  open={tipOpen}
                >
                  <IconButton
                    href="https://github.com/mazedotgame/pattern-template"
                    target="_blank"
                  >
                    <GitHubIcon />
                  </IconButton>
                </Tooltip>
                &nbsp; https://github.com/
              </InputAdornment>
            ),
          }}
          value={repository}
          onChange={(event) => setRepository(event.target.value.replace("https://github.com/","").replace("http://github.com/","").replace("github.com/", ""))}
        />
        <InputPicker
          inventory={{
            blockPatterns: allUniqueSolidBlockPatterns,
            ballColors: [],
            activeBlockPatternId: -1,
            activeBallColorId: -1,
            bestRound: undefined,
          }}
          current={[]}
          inputFilter={() => true}
          locked={false}
          pick={(c) => setFirstColor(c)}
          picked={firstColor}
        />
        <InputPicker
          inventory={{
            blockPatterns: allUniqueSolidBlockPatterns,
            ballColors: [],
            activeBlockPatternId: -1,
            activeBallColorId: -1,
            bestRound: undefined,
          }}
          current={[]}
          inputFilter={() => true}
          locked={false}
          pick={(c) => setSecondColor(c)}
          picked={secondColor}
        />
        <Tooltip
          arrow
          title={"You need to specify a repository and two colors"}
          disableTouchListener={
            firstColor !== undefined &&
            secondColor !== undefined &&
            repository.length > 0
          }
          disableHoverListener={
            firstColor !== undefined &&
            secondColor !== undefined &&
            repository.length > 0
          }
          disableFocusListener={
            firstColor !== undefined &&
            secondColor !== undefined &&
            repository.length > 0
          }
        >
          <Box width={64} height={64}>
            <span>
              <Button
                onClick={loadPattern}
                color="success"
                variant="outlined"
                disabled={
                  firstColor === undefined ||
                  secondColor === undefined ||
                  repository.length === 0
                }
                sx={{ width: 64, height: 64, my: 1 }}
              >
                GO!
              </Button>
            </span>
          </Box>
        </Tooltip>
      </Stack>
      {image && <img src={image} alt={"pattern"} width={800} />}
    </Box>
  );
};
