import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { getMetricsService } from "../network/MetricsService";
import { ActivityResults, isActivityResults } from "../shared/Sunburst";
import sunburstKapsule from "sunburst-chart";

export const Stats = () => {
  const [hours, setHours] = useState(24);

  const [data, setData] = useState<ActivityResults | undefined>(undefined);

  useEffect(() => {
    getMetricsService()
      .sunburst(hours)
      .then((response) => {
        if (isActivityResults(response)) {
          document
            .getElementById("chart")
            ?.childNodes.forEach((child) => child.remove());
          setData(response);
        }
      });
  }, [hours]);

  const dataRef = useRef<ActivityResults | undefined>();

  useEffect(() => {
    if (data) {
      dataRef.current = undefined;
      sunburstKapsule()
        .data(data.sunburst)
        .label("name")
        .size("size")
        .tooltipContent((node) => node.treeSize || node.size)
        .maxLevels(8)
        .height(Math.min(window.innerHeight * 0.8, window.innerWidth))
        .width(Math.min(window.innerHeight * 0.8, window.innerWidth))
        .transitionDuration(275)
        .excludeRoot(true)
        .color("color")(document.getElementById("chart")!);
    } else if (dataRef.current) {
      document
        .getElementById("chart")
        ?.childNodes.forEach((child) => child.remove());
      setData(dataRef.current);
    }
  }, [data, setData]);

  return (
    <Box>
      <Box sx={{ py: 2 }}>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 1 ? "outlined" : "contained"}
          onClick={() => setHours(1)}
        >
          1 hour
        </Button>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 6 ? "outlined" : "contained"}
          onClick={() => setHours(6)}
        >
          6 hour
        </Button>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 12 ? "outlined" : "contained"}
          onClick={() => setHours(12)}
        >
          12 hour
        </Button>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 24 ? "outlined" : "contained"}
          onClick={() => setHours(24)}
        >
          day
        </Button>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 24 * 7 ? "outlined" : "contained"}
          onClick={() => setHours(24 * 7)}
        >
          week
        </Button>
        <Button
          sx={{ m: 0.5 }}
          variant={hours === 24 * 30 ? "outlined" : "contained"}
          onClick={() => setHours(24 * 30)}
        >
          month
        </Button>
      </Box>
      <div id="chart" />
      {data && (
        <Typography>
          <b>Total Sessions </b><br/>{data.totalSessions}
          <br />
          <br />
          <b>Session Lengths</b>
          <br />
          median: {data.sessionLengths.median}
          <br />
          longest: {data.sessionLengths.longest}
          <br />
          total: {data.sessionLengths.total}
          <br />
          <br />
          <b>Activity Totals</b>
        </Typography>
      )}
      {data && (
        <Grid container justifyContent="center">
          <Box maxWidth="sm" mx="auto" sx={{ mx: 2 }}>
            <Table>
              {Object.entries(data.map).map(([activity, result], index) => (
                <TableRow
                  sx={{
                    backgroundColor: index % 2 === 1 ? "#1e1e1e" : "272727",
                    py: 0,
                    my: 0,
                  }}
                >
                  <TableCell sx={{ py: 0.5, my: 0 }}>{activity}</TableCell>
                  <TableCell sx={{ py: 0.5, my: 0 }}>{result.unique}</TableCell>
                  <TableCell sx={{ py: 0.5, my: 0 }}>{result.total}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Box>
        </Grid>
      )}
    </Box>
  );
};
