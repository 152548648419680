import React, { useState } from "react";
import { Container, Sprite } from "@inlet/react-pixi";
import { cst } from "../../utils/constants";
import { SlowInstance } from "./useRunnerState";
import { Textures } from "../sprites/texture";
import { useAnimation } from "./useAnimation";
import {
  frostCooldownIndicatorFrames,
  frostEffectFrames,
} from "../sprites/animations";
import { Coord } from "../../shared/Coord";
import { Path } from "../../shared/Path";
import { lt } from "../../utils/lt";
import { invert } from "../Runner";

export const useClaps = (
  textures: Textures,
  tileSize: number,
  score: number,
  clapCoords: Coord[],
  path: Path | undefined,
  slowInstances?: SlowInstance[]
) => {
  const cooldownAnimation = useAnimation(
    frostCooldownIndicatorFrames,
    textures
  );

  const frostAnimation = useAnimation(frostEffectFrames, textures);

  useState()
  return path === undefined
    ? <Container x={0} y={0}>
        {clapCoords.map((c, index) => (
          <Container key={"staticEffect" + index}>
            <Sprite
              texture={cooldownAnimation[cooldownAnimation.length - 1]}
              x={(c.x + 3 / 4) * tileSize}
              y={(c.y + 5 / 4) * tileSize}
              width={(tileSize * 3) / 2}
              height={(tileSize * 3) / 2}
              key={"clapEffect" + index}
            />
          </Container>
        ))}
      </Container>
    : lt(invert ? path.result - score : score, (s) => (
        <Container x={tileSize} y={tileSize}>
          {slowInstances &&
            slowInstances.map((slowInstance: SlowInstance, index) => (
              <Container key={"frostTop" + index}>
                <Sprite
                  visible={s > slowInstance.start && s < slowInstance.end}
                  texture={
                    s > slowInstance.start && s < slowInstance.end
                      ? cooldownAnimation[
                          Math.floor(
                            (cooldownAnimation.length *
                              (s - slowInstance.start)) /
                              (cst.clapLength / cst.clapModifier)
                          )
                        ]
                      : cooldownAnimation[cooldownAnimation.length - 1]
                  }
                  x={(slowInstance.epicenter.x - 1 / 4) * tileSize}
                  y={(slowInstance.epicenter.y + 1 / 4) * tileSize}
                  width={(tileSize * 3) / 2}
                  height={(tileSize * 3) / 2}
                  key={"cooldownEffect" + index}
                />
                <Sprite
                  visible={
                    s > slowInstance.start - 1 && s < slowInstance.start + 1.5
                  }
                  texture={
                    s > slowInstance.start - 1 && s < slowInstance.start + 1.5
                      ? frostAnimation[
                          Math.floor(
                            (frostAnimation.length *
                              (s - (slowInstance.start - 1.5))) /
                              2.5
                          )
                        ]
                      : frostAnimation[frostAnimation.length - 1]
                  }
                  x={(slowInstance.epicenter.x + 1 / 2) * tileSize}
                  y={(slowInstance.epicenter.y + 1) * tileSize}
                  anchor={{ x: 0.5, y: 0.5 }}
                  scale={tileSize / cst.tileSize}
                  alpha={0.8}
                  key={"frostEffect" + index}
                />
              </Container>
            ))}
          {clapCoords.map(
            (c, index) =>
              !slowInstances?.some(
                (slowInstance) =>
                  slowInstance.epicenter.x === c.x &&
                  slowInstance.epicenter.y === c.y &&
                  s < slowInstance.end &&
                  s > slowInstance.start
              ) && (
                <Container key={"staticEffect" + index}>
                  <Sprite
                    texture={cooldownAnimation[cooldownAnimation.length - 1]}
                    x={(c.x - 1 / 4) * tileSize}
                    y={(c.y + 1 / 4) * tileSize}
                    width={(tileSize * 3) / 2}
                    height={(tileSize * 3) / 2}
                    key={"clapEffect" + index}
                  />
                </Container>
              )
          )}
        </Container>
      ));
};
