import { Grid, Typography } from "@mui/material";
import { SnackbarKey } from "notistack";
import {
  AchievementArea,
  AchievementInformation,
} from "../../shared/Achievement";
import Emoji from "./Emoji";

interface AchievementInformationProps {
  achievementInformation: AchievementInformation;
  closeSnackbar: (snackbarKey: SnackbarKey | undefined) => void;
  keyRef: React.MutableRefObject<{
    [x: number]: SnackbarKey | undefined;
  }>;
  index: number;
}

export const areaToString = (achievementArea: AchievementArea): string => {
  return achievementArea === "ARENA"
    ? "Arena"
    : achievementArea === "PUZZLE"
    ? "Puzzle"
    : achievementArea === "CHALLENGE"
    ? "Challenge"
    :  achievementArea === "ACADEMY"
    ? "Academy"
    :"";
};

export const AchievementInformationElement = (
  props: AchievementInformationProps
) => {
  const { achievementInformation, closeSnackbar, keyRef, index } = props;

  const area = areaToString(achievementInformation.area);

  return (
    <Grid
      onClick={() => closeSnackbar(keyRef.current[index])}
      container
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ cursor: "pointer" }}
    >
      <Grid item sm={2}>
        <Typography variant="h4" align="center" alignContent="center">
          <Emoji symbol="🏆" />
        </Typography>
      </Grid>
      <Grid item sm={10}>
        <Typography variant="body2" align="left">
          <b>
            {area === "" ? "" : area + ": "}
            {achievementInformation.name}{" "}
          </b>
          <br />
          {achievementInformation.description}
        </Typography>
      </Grid>
    </Grid>
  );
};
