import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { getMetricsService } from "../network/MetricsService";
import { getPlayerService } from "../network/PlayerService";
import { Player } from "../shared/Player";
import { Landing } from "./Landing";

interface ReferenceProps {
  player: Player | undefined | null;
  playerReference?: boolean;
}

export const Reference = (props: ReferenceProps) => {
  const { player, playerReference = false } = props;
  const params = useParams();

  const hasReferenced = useRef(false);

  useEffect(() => {
    if (params && params.reference && !hasReferenced.current) {
      hasReferenced.current = true;
      if (playerReference) {
        sessionStorage.setItem("referrer", params.reference);
      }
      (playerReference ? getPlayerService() : getMetricsService())
        .reference(params.reference)
        .then(() => window.history.replaceState(null, "maze.game", "/"));
    }
  }, [params, playerReference]);

  return <Landing player={player} />;
};
