export type AchievementArea =
  | "ARENA"
  | "PUZZLE"
  | "CHALLENGE"
  | "GENERAL"
  | "ACADEMY"
  | "ANY_MODE";

export type AchievementInformation = {
  name: string;
  description: string;
  area: AchievementArea;
};

export function isAchievementInformation(value: any): value is AchievementInformation {
  if ((value as AchievementInformation).description) {
    return true;
  }
  return false;
}

export function isAchievementInformations(value: any): value is AchievementInformation[] {
  const potential = (value as AchievementInformation[])
  if (potential.length === 0) return true;
  else if (isAchievementInformation(potential[0])) return true;
  return false;
}

export type AchievementStatus = {
  information: AchievementInformation;
  achieved: boolean;
};

export function isAchievementStatus(value: any): value is AchievementStatus {
  if ((value as AchievementStatus).information) {
    return true;
  }
  return false;
}
