import { Renderer, RenderTexture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { TilingSprite } from "@pixi/sprite-tiling";
import { useEffect, useState } from "react";
import { generateHook } from "../../pages/ToggleTest";
import { cst } from "../../utils/constants";
import { Textures } from "../sprites/texture";
import { TilingSpriteProps } from "./useEntryZone";
import { useTextureCleanup } from "./useTextureCleanup";

export const tilingSpriteRenderTexture = (
  renderer: Renderer,
  textures: Textures,
  tilingSpriteProps: TilingSpriteProps[]
) => {
  const container = new Container();
  let maxX = 0;
  let maxY = 0;
  tilingSpriteProps.forEach((props) => {
    const tilingSprite = new TilingSprite(textures[props.texture]!);
    tilingSprite.x = props.x * cst.tileSize;
    tilingSprite.y = props.y * cst.tileSize;
    tilingSprite.width = props.width * cst.tileSize;
    tilingSprite.height = props.height * cst.tileSize;
    tilingSprite.tilePosition.x = props.tilePosition ? props.tilePosition.x : 0;
    tilingSprite.tilePosition.y = props.tilePosition ? props.tilePosition.y : 0;
    tilingSprite.tint = props.tint ? props.tint : 0xffffff;
    tilingSprite.alpha = props.alpha ? props.alpha : 1;
    if (tilingSprite.width + tilingSprite.x > maxX) {
      maxX = tilingSprite.width + tilingSprite.x;
    }
    if (tilingSprite.height + tilingSprite.y > maxY) {
      maxX = tilingSprite.height + tilingSprite.y;
    }
    container.addChild(tilingSprite);
  });
  let graphics = new Graphics();
  graphics.beginFill(0);
  graphics.drawRect(0, 0, maxX, maxY);
  graphics.endFill();
  graphics.alpha = 0;
  container.addChild(graphics);
  return generateHook(renderer, container);
};

export const useTilingSpriteRenderTexture = (
  renderer: Renderer,
  textures: Textures,
  tilingSpriteProps: TilingSpriteProps[],
  enabled: boolean
) => {
  const [renderTexture, setRenderTexture] = useState<RenderTexture>();

  useEffect(() => {
    enabled &&
      setRenderTexture(
        tilingSpriteRenderTexture(renderer, textures, tilingSpriteProps)
      );
  }, [renderer, tilingSpriteProps, textures, enabled]);

  useTextureCleanup(renderTexture);

  return renderTexture;
};
