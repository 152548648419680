import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { Player } from "../shared/Player";
import { getPuzzleService } from "../network/PuzzleService";
import { PuzzleSnapshotElement } from "./elements/PuzzleSnapshotElement";
import { MazePaper } from "./elements/MazePaper";
import { Grid } from "@mui/material";
import { PuzzlePreview } from "./elements/PuzzlePreview";
import { periodicalName } from "../utils/periodicalName";
import {
  CosmeticPuzzleSnapshot,
  defaultCosmetics,
  isCosmeticPuzzleSnapshot,
} from "../shared/Cosmetics";
import { useNavigate, useParams } from "react-router";
import PageVisibility from "react-page-visibility";
import { lt } from "../utils/lt";
import { getImage, storeImage } from "../utils/puzzleImageCacheUtils";
import { Application, Renderer } from "pixi.js";
import { generateBoardImage } from "../game/utils/generateBoardImage";
import { cst } from "../utils/constants";
import { useTextures } from "../game/sprites/TextureDaemon";

interface PuzzleProps {
  player: Player;
}

export const PuzzleInfo = (props: PuzzleProps) => {
  const { player } = props;

  const { periodical } = useParams();

  const puzzleId = useMemo(
    () => (periodical && !isNaN(+periodical) ? +periodical : undefined),
    [periodical]
  );

  const [snapshot, setSnapshot] = useState<
    undefined | CosmeticPuzzleSnapshot
  >();

  const textures = useTextures();

  const navigate = useNavigate();
  const refreshSnapshot = useCallback(() => {
    puzzleId &&
      textures["tile.png"] !== undefined &&
      getPuzzleService()
        .snapshot(puzzleId)
        .then((response) => {
          if (isCosmeticPuzzleSnapshot(response)) {
            let roundImage = lt(
              getImage(
                puzzleId,
                player.id,
                response.currentPuzzle.round,
                response.currentPuzzle.info.solution?.solution
              ),
              (cachedImage) => {
                if (cachedImage) return cachedImage;
                let app = new Application();
                let generatedImage = generateBoardImage(
                  app.renderer as Renderer,
                  response.currentPuzzle.info.solution?.cosmetics ||
                    defaultCosmetics,
                  textures,
                  response.currentPuzzle.info.solution?.solution.layout
                    .towers || [],
                  response.currentPuzzle.round.board,
                  cst.tileSize / 2
                );
                app.destroy();
                storeImage(
                  puzzleId,
                  player.id,
                  response.currentPuzzle.round,
                  generatedImage,
                  response.currentPuzzle.info.solution?.solution
                );
                return generatedImage;
              }
            );
            setSnapshot(() => ({
              ...response,
              currentPuzzle: {
                ...response.currentPuzzle,
                info: { ...response.currentPuzzle.info, roundImage },
              },
            }));
          } else {
            console.error(response);
          }
        });
  }, [puzzleId, textures, player]);

  useEffect(refreshSnapshot, [refreshSnapshot]);

  return (
    <Fragment>
      {puzzleId && snapshot && snapshot.currentPuzzle && (
        <PageVisibility
          onChange={(isVisible) => {
            if (isVisible) {
              refreshSnapshot();
            }
          }}
        >
          <Box
            sx={{
              mt: 2,
              display: "flex",
              width: 1,
              justifyContent: "center",
            }}
          >
            <Box maxWidth="sm" mx="auto" width={1}>
              <MazePaper
                goBack={() => navigate("/puzzle")}
                title={"Puzzle: " + periodicalName(puzzleId)}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={10}>
                    <PuzzlePreview
                      onTimeout={refreshSnapshot}
                      onClick={() => navigate("/puzzle/" + puzzleId + "/play")}
                      info={snapshot.currentPuzzle.info}
                    />
                  </Grid>
                </Grid>
                <React.Fragment>
                  <Box
                    sx={{
                      p: 2,
                      mt: 1,
                    }}
                    mx="auto"
                    maxWidth="sm"
                    width="100%"
                  >
                    <PuzzleSnapshotElement
                      replay={() => {
                        navigate("/puzzle/" + puzzleId + "/play");
                      }}
                      playerId={player.id}
                      snapshot={snapshot}
                    />
                  </Box>
                </React.Fragment>
              </MazePaper>
            </Box>
          </Box>
        </PageVisibility>
      )}
    </Fragment>
  );
};
