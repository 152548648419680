import { lt } from "./lt"


const minute = 60 * 1000
const hour = 60 * minute
const day = 24 * hour

export const timeAgo = (ms: number, longFormat?: boolean) => {
  if (ms > day) return lt(Math.floor(ms/day), (days) => days + (longFormat ? " days" : "d"))
  if (ms > hour) return lt(Math.floor(ms/hour), (hours) => hours + (longFormat ? " hours" : "h"))
  if (ms > minute) return lt(Math.floor(ms/minute), (minutes) => minutes + (longFormat ? " minutes" : "m"))
  return longFormat ? "less than 1 minute" : "< 1m";
}