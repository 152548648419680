import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { getSeedService } from "../network/SeedService";
import {
  isSeedInfos,
  isSeedSolutions,
  SeedInfo,
  SeedSolutions,
} from "../shared/Admin";
import { CosmeticPlayerSolution } from "../shared/Cosmetics";
import { formatScore } from "../utils/formatScore";
import { RoundResultElement } from "./elements/RoundResultElement";
import { MultiplayerRoundElement } from "./elements/MultiplayerRoundElement";
import { SeedInfosElement } from "./elements/SeedInfosElement";

export const SeedViewer = () => {
  const [seedInfos, setSeedInfos] = useState<SeedInfo[]>();
  const [solutions, setSolutions] = useState<CosmeticPlayerSolution[]>([]);
  const [seedSolutions, setSeedSolutions] = useState<SeedSolutions>();
  const [viewReplay, setViewReplay] = useState(false);

  useEffect(() => {
    getSeedService()
      .getSeeds()
      .then((response) => {
        if (isSeedInfos(response)) {
          setSeedInfos(response);
        }
      });
  }, [setSeedInfos]);

  const getSeedSolutions = useCallback(
    (seed: number) =>
      getSeedService()
        .getSeedSolutions(seed)
        .then((response) => {
          if (isSeedSolutions(response)) {
            setSeedSolutions(response);
          }
        }),
    [setSeedSolutions]
  );

  return (
    <Grid container width={1} justifyContent="center" alignItems="flex-start">
      <Fragment>
        {seedInfos && seedSolutions === undefined && (
          <Box width={400}>
            <SeedInfosElement
              seedInfos={seedInfos}
              getSeedSolutions={getSeedSolutions}
            />
          </Box>
        )}
        {seedSolutions && !viewReplay && (
          <Fragment>
            <Button
              onClick={() => {
                setSeedSolutions(undefined);
                setSolutions([]);
              }}
            >
              Back{" "}
            </Button>
            <Box width={400}>
              <Table>
                {seedSolutions.playerSolutions
                  .sort(
                    (a, b) => b.solution.path.result - a.solution.path.result
                  )
                  .map((playerSolution) => (
                    <TableRow>
                      <TableCell size="small">
                        {playerSolution.player.name}
                      </TableCell>
                      <TableCell size="small">
                        {formatScore(playerSolution.solution.path.result)}
                      </TableCell>
                      <TableCell size="small">
                        {playerSolution.solution.path.sections.reduce(
                          (previousValue, section) =>
                            section.clapCoord
                              ? previousValue + 1
                              : previousValue,
                          0
                        )}
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{
                          color:
                            playerSolution.cosmetics.block.pattern.firstColor.color.name !==
                            "Default"
                              ? "green"
                              : "red",
                        }}
                      >
                        {playerSolution.cosmetics.block.pattern.firstColor.color.name !==
                        "Default"
                          ? "yes"
                          : "no"}
                      </TableCell>
                      <TableCell size="small">
                        <Checkbox
                          checked={
                            solutions.find(
                              (it) =>
                                it.player.name === playerSolution.player.name
                            ) !== undefined
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.checked) {
                              setSolutions((s) => [...s, playerSolution]);
                            } else {
                              setSolutions((s) =>
                                s.filter(
                                  (ps) =>
                                    ps.player.name !==
                                    playerSolution.player.name
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Box>
            <Button onClick={() => setViewReplay(solutions.length > 0)}>
              View Replay
            </Button>
          </Fragment>
        )}
        {seedSolutions &&
          viewReplay &&
          solutions.length > 0 &&
          (solutions.length === 2 ? (
            <RoundResultElement
              refreshAccount={() => {}}
              account={undefined}
              replayFinished={() => setViewReplay(false)}
              roundResult={{
                playerSolution: solutions[0],
                opponentSolution: solutions[1],
                round: seedSolutions.round,
              }}
            />
          ) : (
            <MultiplayerRoundElement
              round={seedSolutions.round}
              playerSolution={solutions[0]}
              allSolutions={solutions}
              runnerCompleted={() => setViewReplay(false)}
            />
          ))}
      </Fragment>
    </Grid>
  );
};
