import { TextStyle } from "pixi.js";

export const tutorialTextStyle = (tileSize: number, wordWrapWidth?: number) =>
  new TextStyle({
    fontFamily: "Arial",
    fontSize: Math.max(1, (22 * (tileSize / 64))),
    fontWeight: "bold",
    wordWrap: wordWrapWidth !== undefined,
    wordWrapWidth: wordWrapWidth,
    align: "center",
    fill: ["#ffffff"], // gradient
    stroke: "#222222",
    strokeThickness: 1,
  });

export const scoreTextStyle = (tileSize: number, wordWrapWidth?: number) =>
  new TextStyle({
    fontFamily: "monospace",
    fontSize: Math.max(1, (22 * (tileSize / 64))),
    fontWeight: "bold",
    wordWrap: wordWrapWidth !== undefined,
    wordWrapWidth: wordWrapWidth,
    align: "left",
    fill: ["#000000"],
  });