import { Box, Grid, Typography } from "@mui/material";
import { Pattern, patternName } from "../../shared/Cosmetics";

interface PatternNameProps {
  pattern: Pattern;
}

export const PatternName = (props: PatternNameProps) => {
  const { pattern } = props;
  return (
    <Grid
      minHeight={"3.5em"}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Typography sx={{ mb: -0.9 }} lineHeight={1}>
          <b>Pattern</b>
        </Typography>
        <Typography
          sx={{ fontVariant: "small-caps", color: "lightgray" }}
          variant="caption"
        >
          <b>{patternName(pattern).toLowerCase()}</b>
        </Typography>
      </Box>
    </Grid>
  );
};
