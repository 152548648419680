import axios from "axios";
import { CosmeticPastPuzzle, CosmeticPlayerSolution, CosmeticPuzzleInfo, CosmeticPuzzleMap, CosmeticPuzzleSnapshot } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { PuzzleId, PuzzleResult } from "../shared/Puzzle";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";


export interface PuzzleService {
  all: () => Promise<CosmeticPuzzleMap | ErrorMessage>;
  info: (id: PuzzleId) => Promise<CosmeticPuzzleInfo | ErrorMessage>;
  snapshot: (id: PuzzleId) => Promise<CosmeticPuzzleSnapshot | ErrorMessage>;
  submit: (id: PuzzleId, layout: Layout) => Promise<PuzzleResult | ErrorMessage>;
  deadline: (id: PuzzleId) => Promise<number | ErrorMessage>;
  past: (id: number) => Promise<CosmeticPastPuzzle | ErrorMessage>;
  pastSolution: (id: number, playerId: number) => Promise<CosmeticPlayerSolution | ErrorMessage>;
  startedRound: (id: PuzzleId) => Promise<number | ErrorMessage>;
  currentPlayerCount: () => Promise<number | ErrorMessage>;
}

export const getPuzzleService = (): PuzzleService => ({
  all: () => 
    axios
      .get<CosmeticPuzzleMap>(cst.backendUrl + "/puzzle/all", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  info: (id) =>
    axios
      .get<CosmeticPuzzleInfo>(cst.backendUrl + "/puzzle/" + id, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  snapshot: (id) =>
    axios
      .get<CosmeticPuzzleSnapshot>(cst.backendUrl + "/puzzle/" + id + "/snapshot", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  past: (id) =>
    axios
      .get<CosmeticPastPuzzle>(cst.backendUrl + "/puzzle/past/" + id, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  pastSolution: (id, playerId) =>
    axios
      .get<CosmeticPlayerSolution>(cst.backendUrl + "/puzzle/past/" + id + "/" + playerId, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  submit: (id, layout: Layout) =>
    axios
      .post<PuzzleResult>(cst.backendUrl + "/puzzle/" + id + "/submit", layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  deadline: (id) =>
    axios
      .get<number>(cst.backendUrl + "/puzzle/" + id + "/deadline", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  startedRound: (id) =>
    axios
      .get<number>(cst.backendUrl + "/puzzle/" + id + "/startedRound", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  currentPlayerCount: () =>
    axios
      .get<number>(cst.backendUrl + "/puzzle/playerCount", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
