import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlayerSession } from "../../utils/usePlayerSession";
import { Points } from "./Emoji";
import {
  Badge,
  Collapse,
  Grid,
  Link,
  ListItemIcon,
  ListItemText,
  Slider,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ClaimServePointsElement } from "./ClaimServePointsElement";
import SchoolIcon from "@mui/icons-material/School";
import StadiumIcon from "@mui/icons-material/Stadium";
import ExtensionIcon from "@mui/icons-material/Extension";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import SportsIcon from "@mui/icons-material/Sports";
import StoreIcon from "@mui/icons-material/Store";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { theme } from "../../utils/theme";
import { isPlayer } from "../../shared/Player";
import { useNotifications } from "../../utils/useNotifications";
import { blockCosmeticImage } from "../../utils/imageGenerators";
import { useTextures } from "../../game/sprites/TextureDaemon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { blockCosmeticsId } from "../../shared/Cosmetics";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { lt } from "../../utils/lt";

export const playerLogoKey = "playerBlock";
export const currentBlockCosmeticsIdKey = "blockColor";
interface ResponsiveAppBarProps {
  playerSession: PlayerSession;
  freshPoints: number | undefined;
}

interface TopBarButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon: React.ReactNode;
  name: string;
}

const TopBarButton = (props: TopBarButtonProps) => {
  const { onClick, disabled = false, icon, name } = props;

  return (
    <span>
      <Button
        onClick={onClick}
        sx={{ mx: 1, color: "white" }}
        disabled={disabled}
      >
        <Stack alignItems="center">
          {icon}
          <Typography
            variant="caption"
            sx={{ transform: "translate(0%, 10%)" }}
          >
            <b>{name}</b>
          </Typography>
        </Stack>
      </Button>
    </span>
  );
};

const ResponsiveAppBar = (props: ResponsiveAppBarProps) => {
  const { playerSession, freshPoints } = props;
  const { player, logout, signin, signup, account, refreshAccount, cosmetics } =
    playerSession;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const location = useLocation();

  const handleOpenNavMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    },
    [setAnchorElNav]
  );

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [setAnchorElUser]
  );

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, [setAnchorElNav]);

  const [options, setOptions] = useState(false);
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
    setOptions(false);
  }, [setAnchorElUser]);

  const { notificationElement, notificationCount } = useNotifications({
    player: player,
    closeMenu: handleCloseUserMenu,
  });
  const navigate = useNavigate();

  const [showFreshPoints, setShowFreshPoints] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (freshPoints) {
      setShowFreshPoints(true);
      window.setTimeout(() => mounted && setShowFreshPoints(false), 2700);
    }
    return () => {
      mounted = false;
    };
  }, [freshPoints]);

  const textures = useTextures();

  const [pathingLine, setPathingLine] = useState(() =>
    lt(localStorage.getItem("pathingLine"), (alpha) => (alpha ? +alpha : 0))
  );

  useEffect(
    () => localStorage.setItem("pathingLine", "" + pathingLine),
    [pathingLine]
  );
  const [highVis, setHighVis] = useState(() =>
    lt(localStorage.getItem("highVis"), (alpha) => (alpha ? +alpha : 0))
  );

  useEffect(() => localStorage.setItem("highVis", "" + highVis), [highVis]);

  const playerLogo = useMemo(() => {
    if (cosmetics) {
      let cachedBlockCosmeticsId = localStorage.getItem(
        currentBlockCosmeticsIdKey
      );
      if (cachedBlockCosmeticsId !== blockCosmeticsId(cosmetics.block)) {
        localStorage.setItem(
          currentBlockCosmeticsIdKey,
          blockCosmeticsId(cosmetics.block)
        );
        localStorage.setItem(
          playerLogoKey,
          blockCosmeticImage([cosmetics.block.pattern], textures)[0]
        );
      }
      return localStorage.getItem(playerLogoKey) as string;
    }
  }, [cosmetics, textures]);

  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{ paddingLeft: "calc(100vw - 100%)" }}
    >
      <Container maxWidth="xl" sx={{ maxHeight: 56 }}>
        <Toolbar disableGutters variant="dense">
          <Button
            disableElevation
            disableRipple
            size="small"
            sx={{
              mr: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => navigate("/")}
          >
            <Avatar
              variant="rounded"
              src={process.env.PUBLIC_URL + "/logo512.png"}
            />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/Arena");
                }}
              >
                <ListItemIcon>
                  <StadiumIcon />
                </ListItemIcon>
                <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                  <b>Arena</b>
                </ListItemText>
              </MenuItem>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Typography>
                    <b>
                      <Link
                        underline="none"
                        href="#"
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate("/signin");
                        }}
                      >
                        Sign In
                      </Link>{" "}
                    </b>
                    to play <b>Academy</b>
                  </Typography>
                }
                placement={isSm ? "right" : "bottom"}
                arrow
                disableFocusListener={!!player}
                disableHoverListener={!!player}
                disableTouchListener={!!player}
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate("/academy");
                    }}
                    disabled={!player}
                  >
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                      <b>Academy</b>
                    </ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Typography>
                    <b>
                      <Link
                        underline="none"
                        href="#"
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate("/signin");
                        }}
                      >
                        Sign In
                      </Link>{" "}
                    </b>
                    to play <b>Challenges</b>
                  </Typography>
                }
                placement={isSm ? "right" : "bottom"}
                arrow
                disableFocusListener={!!player}
                disableHoverListener={!!player}
                disableTouchListener={!!player}
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate("/challenge/daily");
                    }}
                    disabled={!player}
                  >
                    <ListItemIcon>
                      <EventRepeatIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                      <b>Daily</b>
                    </ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Typography>
                    <b>
                      <Link
                        underline="none"
                        href="#"
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate("/signin");
                        }}
                      >
                        Sign In
                      </Link>{" "}
                    </b>
                    to play <b>Daily</b>
                  </Typography>
                }
                placement={isSm ? "right" : "bottom"}
                arrow
                disableFocusListener={!!player}
                disableHoverListener={!!player}
                disableTouchListener={!!player}
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate("/challenges");
                    }}
                    disabled={!player}
                  >
                    <ListItemIcon>
                      <SportsIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                      <b>Challenges</b>
                    </ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Typography>
                    <b>
                      <Link
                        underline="none"
                        href="#"
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate("/signin");
                        }}
                      >
                        Sign In
                      </Link>{" "}
                    </b>
                    to play <b>Puzzle</b>
                  </Typography>
                }
                placement={isSm ? "right" : "bottom"}
                arrow
                disableFocusListener={!!player}
                disableHoverListener={!!player}
                disableTouchListener={!!player}
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate("/puzzle");
                    }}
                    disabled={!player}
                  >
                    <ListItemIcon>
                      <ExtensionIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                      <b>Puzzle</b>
                    </ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  localStorage.setItem("returnRoute", location.pathname);
                  navigate("/tutorial");
                }}
              >
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                  <b>Tutorial</b>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  localStorage.setItem("returnRoute", location.pathname);
                  navigate("/leaderboards/arena/rating");
                }}
              >
                <ListItemIcon>
                  <LeaderboardIcon />
                </ListItemIcon>
                <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                  <b>Leaderboards</b>
                </ListItemText>
              </MenuItem>
              {player && isPlayer(player) && (
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate("/store");
                  }}
                >
                  <ListItemIcon>
                    <StoreIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                    <b>Store</b>
                  </ListItemText>
                </MenuItem>
              )}
              {player && isPlayer(player) && player.id === 1 && (
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate("/stats");
                  }}
                >
                  <ListItemText sx={{ transform: "translate(0%, 10%)" }}>
                    <b>Stats</b>
                  </ListItemText>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <TopBarButton
              onClick={() => navigate("/arena")}
              icon={<StadiumIcon />}
              name="Arena"
            />
            <Tooltip
              enterTouchDelay={0}
              title={
                <Typography>
                  <b>
                    <Link
                      underline="none"
                      href="#"
                      onClick={() => navigate("/signin")}
                    >
                      Sign In
                    </Link>{" "}
                  </b>
                  to play <b>Academy</b>
                </Typography>
              }
              disableFocusListener={player !== null}
              disableHoverListener={player !== null}
              disableTouchListener={player !== null}
            >
              <TopBarButton
                onClick={() => navigate("/Academy")}
                disabled={!player}
                icon={<AccountBalanceIcon />}
                name="Academy"
              />
            </Tooltip>
            <Tooltip
              enterTouchDelay={0}
              title={
                <Typography>
                  <b>
                    <Link
                      underline="none"
                      href="#"
                      onClick={() => navigate("/signin")}
                    >
                      Sign In
                    </Link>{" "}
                  </b>
                  to play <b>Daily</b>
                </Typography>
              }
              disableFocusListener={player !== null}
              disableHoverListener={player !== null}
              disableTouchListener={player !== null}
            >
              <TopBarButton
                onClick={() => navigate("/challenge/daily")}
                icon={<EventRepeatIcon />}
                disabled={!player}
                name="daily"
              />
            </Tooltip>
            <Tooltip
              enterTouchDelay={0}
              title={
                <Typography>
                  <b>
                    <Link
                      underline="none"
                      href="#"
                      onClick={() => navigate("/signin")}
                    >
                      Sign In
                    </Link>{" "}
                  </b>
                  to play <b>Challenges</b>
                </Typography>
              }
              disableFocusListener={player !== null}
              disableHoverListener={player !== null}
              disableTouchListener={player !== null}
            >
              <TopBarButton
                onClick={() => navigate("/challenges")}
                icon={<SportsIcon />}
                disabled={!player}
                name="Challenges"
              />
            </Tooltip>
            <Tooltip
              title={
                <Typography>
                  <b>
                    <Link
                      underline="none"
                      href="#"
                      onClick={() => navigate("/signin")}
                    >
                      Sign In
                    </Link>{" "}
                  </b>
                  to play <b>Puzzle</b>
                </Typography>
              }
              disableFocusListener={player !== null}
              disableHoverListener={player !== null}
              disableTouchListener={player !== null}
              enterTouchDelay={0}
            >
              <TopBarButton
                onClick={() => {
                  navigate("/puzzle");
                }}
                icon={<ExtensionIcon />}
                disabled={!player}
                name="Puzzle"
              />
            </Tooltip>
            <TopBarButton
              onClick={() => {
                localStorage.setItem("returnRoute", location.pathname);
                navigate("/tutorial");
              }}
              icon={<SchoolIcon />}
              name={"Tutorial"}
            />
            <TopBarButton
              onClick={() => {
                navigate("/leaderboards/arena/rating");
              }}
              icon={<LeaderboardIcon />}
              name="Leaderboards"
            />
            {player && isPlayer(player) && (
              <TopBarButton
                onClick={() => {
                  navigate("/store");
                }}
                icon={<StoreIcon />}
                name="Store"
              />
            )}
            {player && isPlayer(player) && player.id === 1 && (
              <Button
                onClick={() => {
                  navigate("/stats");
                }}
                sx={{ my: 1, mx: 1, color: "white" }}
              >
                <Typography sx={{ transform: "translate(0%, 10%)" }}>
                  Stats
                </Typography>
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex", justifyContent: "center" }}>
            {account && account.unclaimedServePoints > 0 && (
              <React.Fragment>
                <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                  <ClaimServePointsElement
                    account={account}
                    refreshAccount={refreshAccount}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 52,
                    right: 0,
                    display: { xs: "flex", lg: "none" },
                    zIndex: 10000,
                  }}
                >
                  <ClaimServePointsElement
                    account={account}
                    refreshAccount={refreshAccount}
                  />
                </Box>
              </React.Fragment>
            )}
            {account && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  px: 2,
                }}
              >
                {(freshPoints && (
                  <Tooltip
                    title={
                      <Typography>
                        {freshPoints > 0 ? "+" : "-"}{" "}
                        {freshPoints < 0 ? -freshPoints : freshPoints}{" "}
                        <Points />
                      </Typography>
                    }
                    enterTouchDelay={0}
                    open={showFreshPoints}
                  >
                    <Typography>
                      <Points /> {account.points}
                    </Typography>
                  </Tooltip>
                )) || (
                  <Typography>
                    <Points /> {account.points}
                  </Typography>
                )}
              </Box>
            )}
            {player && player !== "unregistered" && account && cosmetics && (
              <Badge
                badgeContent={notificationCount}
                color="info"
                sx={{ top: 4, right: 4, my: 1 }}
              >
                <Button
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, m: 0, minWidth: 0, top: -4 }}
                >
                  {!player.guest && account && (
                    <Avatar
                      sx={{ p: 0, m: 0 }}
                      variant="rounded"
                      src={playerLogo}
                    ></Avatar>
                  )}
                </Button>
              </Badge>
            )}
            {(player === null || player === undefined) &&
              location.pathname !== "/register" && (
                <React.Fragment>
                  <Button
                    variant={"outlined"}
                    sx={{ color: "white", borderColor: "white", mr: 1 }}
                    onClick={() => signup(location.pathname)}
                  >
                    Register
                  </Button>
                  <Button
                    variant={"contained"}
                    sx={{ backgroundColor: "white" }}
                    onClick={() => signin(location.pathname)}
                  >
                    Sign in
                  </Button>
                </React.Fragment>
              )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {account && (
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/arena/serve");
                  }}
                >
                  <Typography textAlign="center">Serve</Typography>
                  {account.serves > 0 && (
                    <Badge
                      sx={{ mx: 1, pl: 2 }}
                      color="success"
                      badgeContent={account.serves}
                    />
                  )}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate("/inventory");
                }}
              >
                <Typography textAlign="center">Inventory</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate("/saves");
                }}
              >
                <Typography textAlign="center">Saved Rounds</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate("/profile");
                }}
              >
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  handleCloseUserMenu();
                  navigate("/");
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOptions((o) => !o);
                }}
              >
                <Grid container justifyContent="space-between" width={1}>
                  <Typography textAlign="center">Options</Typography>
                  {options ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Grid>
              </MenuItem>
              <Collapse in={options} sx={{ width: 1 }}>
                <MenuItem sx={{ mx: -2 }}>
                  <Box width={1} sx={{ px: 2, mb: -1 }}>
                    <Typography
                      id="non-linear-slider"
                      gutterBottom
                      sx={{ mb: -0.5 }}
                    >
                      Pathing Line
                    </Typography>
                    <Slider
                      sx={{ px: 0 }}
                      value={pathingLine}
                      min={0}
                      step={0.05}
                      max={1}
                      onChange={(_, value) => {
                        setPathingLine(Array.isArray(value) ? value[0] : value);
                      }}
                      valueLabelFormat={(v) =>
                        v === 0 ? "Off" : "" + Math.round(v * 100) + "%"
                      }
                      valueLabelDisplay="auto"
                      aria-labelledby="non-linear-slider"
                    />
                  </Box>
                </MenuItem>
                <MenuItem sx={{ mx: -2 }}>
                  <Box width={1} sx={{ px: 2, mb: -1 }}>
                    <Typography
                      id="non-linear-slider"
                      gutterBottom
                      sx={{ mb: -0.5 }}
                    >
                      High Visibility
                    </Typography>
                    <Slider
                      sx={{ px: 0 }}
                      value={highVis}
                      min={0}
                      step={0.05}
                      max={1}
                      onChange={(_, value) => {
                        setHighVis(Array.isArray(value) ? value[0] : value);
                      }}
                      valueLabelFormat={(v) =>
                        v === 0 ? "Off" : "" + Math.round(v * 100) + "%"
                      }
                      valueLabelDisplay="auto"
                      aria-labelledby="non-linear-slider"
                    />
                  </Box>
                </MenuItem>
              </Collapse>
              {notificationCount > 0 && notificationElement}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
