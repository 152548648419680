import React, { useMemo, useState } from "react";
import { Box, Button, Slider } from "@mui/material";
import { InteractiveGame } from "./elements/InteractiveGame";
import { Round } from "../shared/Round";
import { Layout } from "../shared/Layout";
import { lt } from "../utils/lt";
import { cst } from "../utils/constants";
import { Application } from "@pixi/app";
import { patterns } from "../game/utils/usePattern";
import { downloadBase64File } from "../utils/imageGenerators";
import { Pattern } from "../shared/Cosmetics";
import { Renderer } from "@pixi/core";

export const exampleRound: Round = {
  towers: 24,
  claps: 3,
  board: {
    width: 16,
    height: 18,
    staticTowers: [
      {
        coord: {
          x: 3,
          y: 13,
        },
        clap: true,
        static: true,
      },
      {
        coord: {
          x: 9,
          y: 13,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 11,
          y: 12,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 10,
          y: 0,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 10,
          y: 2,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 5,
          y: 3,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 4,
          y: 0,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 8,
          y: 4,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 14,
          y: 6,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 0,
          y: 4,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 0,
          y: 11,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 3,
          y: 7,
        },
        clap: false,
        static: true,
      },
      {
        coord: {
          x: 3,
          y: 10,
        },
        clap: false,
        static: true,
      },
    ],
    startArea: [
      {
        x: 1,
        y: 18,
      },
      {
        x: 0,
        y: 18,
      },
    ],
    endArea: [
      {
        x: 16,
        y: 7,
      },
      {
        x: 16,
        y: 8,
      },
    ],
  },
};

export const exampleLayout: Layout = {
  towers: [
    {
      coord: {
        x: 1,
        y: 13,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 1,
        y: 8,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 5,
        y: 15,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 5,
        y: 10,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 3,
        y: 5,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 10,
        y: 16,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 7,
        y: 15,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 6,
        y: 12,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 8,
        y: 10,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 11,
        y: 5,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 11,
        y: 7,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 10,
        y: 9,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 8,
        y: 6,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 6,
        y: 7,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 1,
        y: 16,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 15,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 13,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 14,
        y: 10,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 12,
        y: 9,
      },
      clap: true,
      static: false,
    },
    {
      coord: {
        x: 1,
        y: 1,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 3,
        y: 3,
      },
      clap: true,
      static: false,
    },
    {
      coord: {
        x: 7,
        y: 1,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 1,
      },
      clap: false,
      static: false,
    },
    {
      coord: {
        x: 13,
        y: 3,
      },
      clap: false,
      static: false,
    },
  ],
};

const possibleExits = [
  [
    {
      x: 0,
      y: -1,
    },
    {
      x: 1,
      y: -1,
    },
  ],
  [
    {
      x: 1,
      y: -1,
    },
    {
      x: 2,
      y: -1,
    },
  ],
  [
    {
      x: 2,
      y: -1,
    },
    {
      x: 3,
      y: -1,
    },
  ],
  [
    {
      x: 3,
      y: -1,
    },
    {
      x: 4,
      y: -1,
    },
  ],
  [
    {
      x: 4,
      y: -1,
    },
    {
      x: 5,
      y: -1,
    },
  ],
  [
    {
      x: 5,
      y: -1,
    },
    {
      x: 6,
      y: -1,
    },
  ],
  [
    {
      x: 6,
      y: -1,
    },
    {
      x: 7,
      y: -1,
    },
  ],
  [
    {
      x: 7,
      y: -1,
    },
    {
      x: 8,
      y: -1,
    },
  ],
  [
    {
      x: 8,
      y: -1,
    },
    {
      x: 9,
      y: -1,
    },
  ],
  [
    {
      x: 9,
      y: -1,
    },
    {
      x: 10,
      y: -1,
    },
  ],
  [
    {
      x: 10,
      y: -1,
    },
    {
      x: 11,
      y: -1,
    },
  ],
  [
    {
      x: 11,
      y: -1,
    },
    {
      x: 12,
      y: -1,
    },
  ],
  [
    {
      x: 12,
      y: -1,
    },
    {
      x: 13,
      y: -1,
    },
  ],
  [
    {
      x: 13,
      y: -1,
    },
    {
      x: 14,
      y: -1,
    },
  ],
  [
    {
      x: 14,
      y: -1,
    },
    {
      x: 15,
      y: -1,
    },
  ],
  [
    {
      x: 15,
      y: -1,
    },
    {
      x: 16,
      y: -1,
    },
    {
      x: 16,
      y: 0,
    },
  ],
  [
    {
      x: 16,
      y: 0,
    },
    {
      x: 16,
      y: 1,
    },
  ],
  [
    {
      x: 16,
      y: 1,
    },
    {
      x: 16,
      y: 2,
    },
  ],
  [
    {
      x: 16,
      y: 2,
    },
    {
      x: 16,
      y: 3,
    },
  ],
  [
    {
      x: 16,
      y: 3,
    },
    {
      x: 16,
      y: 4,
    },
  ],
  [
    {
      x: 16,
      y: 4,
    },
    {
      x: 16,
      y: 5,
    },
  ],
  [
    {
      x: 16,
      y: 5,
    },
    {
      x: 16,
      y: 6,
    },
  ],
  [
    {
      x: 16,
      y: 6,
    },
    {
      x: 16,
      y: 7,
    },
  ],
  [
    {
      x: 16,
      y: 7,
    },
    {
      x: 16,
      y: 8,
    },
  ],
  [
    {
      x: 16,
      y: 8,
    },
    {
      x: 16,
      y: 9,
    },
  ],
  [
    {
      x: 16,
      y: 9,
    },
    {
      x: 16,
      y: 10,
    },
  ],
  [
    {
      x: 16,
      y: 10,
    },
    {
      x: 16,
      y: 11,
    },
  ],
  [
    {
      x: 16,
      y: 11,
    },
    {
      x: 16,
      y: 12,
    },
  ],
  [
    {
      x: 16,
      y: 12,
    },
    {
      x: 16,
      y: 13,
    },
  ],
  [
    {
      x: 16,
      y: 13,
    },
    {
      x: 16,
      y: 14,
    },
  ],
  [
    {
      x: 16,
      y: 14,
    },
    {
      x: 16,
      y: 15,
    },
  ],
  [
    {
      x: 16,
      y: 15,
    },
    {
      x: 16,
      y: 16,
    },
  ],
  [
    {
      x: 16,
      y: 16,
    },
    {
      x: 16,
      y: 17,
    },
  ],
  [
    {
      x: 16,
      y: 17,
    },
    {
      x: 16,
      y: 18,
    },
    {
      x: 15,
      y: 18,
    },
  ],
  [
    {
      x: 15,
      y: 18,
    },
    {
      x: 14,
      y: 18,
    },
  ],
  [
    {
      x: 14,
      y: 18,
    },
    {
      x: 13,
      y: 18,
    },
  ],
  [
    {
      x: 13,
      y: 18,
    },
    {
      x: 12,
      y: 18,
    },
  ],
  [
    {
      x: 12,
      y: 18,
    },
    {
      x: 11,
      y: 18,
    },
  ],
  [
    {
      x: 11,
      y: 18,
    },
    {
      x: 10,
      y: 18,
    },
  ],
  [
    {
      x: 10,
      y: 18,
    },
    {
      x: 9,
      y: 18,
    },
  ],
  [
    {
      x: 9,
      y: 18,
    },
    {
      x: 8,
      y: 18,
    },
  ],
  [
    {
      x: 8,
      y: 18,
    },
    {
      x: 7,
      y: 18,
    },
  ],
  [
    {
      x: 7,
      y: 18,
    },
    {
      x: 6,
      y: 18,
    },
  ],
  [
    {
      x: 6,
      y: 18,
    },
    {
      x: 5,
      y: 18,
    },
  ],
  [
    {
      x: 5,
      y: 18,
    },
    {
      x: 4,
      y: 18,
    },
  ],
  [
    {
      x: 4,
      y: 18,
    },
    {
      x: 3,
      y: 18,
    },
  ],
  [
    {
      x: 3,
      y: 18,
    },
    {
      x: 2,
      y: 18,
    },
  ],
  [
    {
      x: 2,
      y: 18,
    },
    {
      x: 1,
      y: 18,
    },
  ],
  [
    {
      x: 1,
      y: 18,
    },
    {
      x: 0,
      y: 18,
    },
  ],
  [
    {
      x: -1,
      y: 17,
    },
    {
      x: -1,
      y: 18,
    },
    {
      x: 0,
      y: 18,
    },
  ],
  [
    {
      x: -1,
      y: 17,
    },
    {
      x: -1,
      y: 16,
    },
  ],
  [
    {
      x: -1,
      y: 16,
    },
    {
      x: -1,
      y: 15,
    },
  ],
  [
    {
      x: -1,
      y: 15,
    },
    {
      x: -1,
      y: 14,
    },
  ],
  [
    {
      x: -1,
      y: 14,
    },
    {
      x: -1,
      y: 13,
    },
  ],
  [
    {
      x: -1,
      y: 13,
    },
    {
      x: -1,
      y: 12,
    },
  ],
  [
    {
      x: -1,
      y: 12,
    },
    {
      x: -1,
      y: 11,
    },
  ],
  [
    {
      x: -1,
      y: 11,
    },
    {
      x: -1,
      y: 10,
    },
  ],
  [
    {
      x: -1,
      y: 10,
    },
    {
      x: -1,
      y: 9,
    },
  ],
  [
    {
      x: -1,
      y: 9,
    },
    {
      x: -1,
      y: 8,
    },
  ],
  [
    {
      x: -1,
      y: 8,
    },
    {
      x: -1,
      y: 7,
    },
  ],
  [
    {
      x: -1,
      y: 7,
    },
    {
      x: -1,
      y: 6,
    },
  ],
  [
    {
      x: -1,
      y: 6,
    },
    {
      x: -1,
      y: 5,
    },
  ],
  [
    {
      x: -1,
      y: 5,
    },
    {
      x: -1,
      y: 4,
    },
  ],
  [
    {
      x: -1,
      y: 4,
    },
    {
      x: -1,
      y: 3,
    },
  ],
  [
    {
      x: -1,
      y: 3,
    },
    {
      x: -1,
      y: 2,
    },
  ],
  [
    {
      x: -1,
      y: 2,
    },
    {
      x: -1,
      y: 1,
    },
  ],
  [
    {
      x: -1,
      y: 1,
    },
    {
      x: -1,
      y: 0,
    },
  ],
  [
    {
      x: -1,
      y: 0,
    },
    {
      x: -1,
      y: -1,
    },
    {
      x: 0,
      y: -1,
    },
  ],
];

let lastLog: number = 0;

export const log = (text: any) => {
  if (lastLog === 0 || text === "") {
    lastLog = Date.now();
    return;
  }
  let now = Date.now();
  let time = now - lastLog;
  lastLog = now;

  document.getElementById("console")?.appendChild(
    lt(document.createElement("p"), (p) => {
      p.textContent = "" + text + ": " + time;
      return p;
    })
  );
};

const downloadPattern = (p: Pattern, renderer: Renderer) => {
  let mask = patterns[p](cst.tileSize, 2, 2, renderer);
  let image = renderer.plugins.extract.base64(mask);
  downloadBase64File(image, "pattern_" + p.toLowerCase() + ".png");
};

export const SpriteTest = () => {
  const [roundWithArea, setRoundWithArea] = useState<Round>(exampleRound);

  const [show, setShow] = useState(false);

  const gradientApp = useMemo(() => new Application(), []);
  return (
    <Box sx={{ pt: 0 }} maxWidth="lg" mx="auto" height="90vh">
      {Object.keys(patterns).map((it) => (
        <Button
          onClick={() => {
            downloadPattern(it as Pattern, gradientApp.renderer as Renderer);
          }}
          key={it}
        >
          {it}
        </Button>
      ))}
      {!show && (
        <Button
          onClick={() => {
            let element = document.getElementById("console")!!;
            while (element.firstChild) {
              element.removeChild(element.firstChild);
            }
            setShow(true);
          }}
        >
          show
        </Button>
      )}
      <div id="console" />
      {show && (
        <InteractiveGame
          round={roundWithArea}
          startingLayout={exampleLayout}
          submitLayout={() => setShow(false)}
        />
      )}

      <Slider
        defaultValue={0}
        valueLabelDisplay="auto"
        step={1}
        onChange={(_, entryIndex) =>
          !Array.isArray(entryIndex) &&
          setRoundWithArea((r) => ({
            ...r,
            board: {
              ...r.board,
              startArea: possibleExits[entryIndex],
              endArea: possibleExits[(entryIndex + 33) % possibleExits.length],
            },
          }))
        }
        marks
        min={0}
        max={possibleExits.length - 1}
      />
    </Box>
  );
};
