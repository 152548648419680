export type LeaderboardRow = {
  name: string;
  value: number;
};

export type Leaderboard = {
  section: Section;
  subSection: SubSection;
  page: number;
  position: number;
  decimals: number;
  result: LeaderboardRow[];
};

export enum SubSection {
  RATING = "RATING",
  ROUNDS = "ROUNDS",
}

export enum Section {
  ARENA = "ARENA",
}

export function isLeaderboardRow(obj: any): obj is LeaderboardRow {
  return typeof obj.name === "string" && typeof obj.value === "number";
}

export function isLeaderboard(obj: any): obj is Leaderboard {
  return (
    typeof obj.section === "string" &&
    typeof obj.subSection === "string" &&
    typeof obj.page === "number" &&
    typeof obj.position === "number" &&
    typeof obj.decimals === "number" &&
    Array.isArray(obj.result) &&
    obj.result.every((row: any) => isLeaderboardRow(row))
  );
}

export type SectionOverview = { [key in Section]: SubSection[] };

export function isSectionOverview(obj: any): obj is SectionOverview {
  if (typeof obj !== "object") {
    return false;
  }

  for (const key in Section) {
    if (!Object.prototype.hasOwnProperty.call(Section, key)) {
      continue;
    }

    const sectionKey = key as Section;
    const subSectionArray = obj[sectionKey];

    if (!Array.isArray(subSectionArray)) {
      return false;
    }

    for (const subSection of subSectionArray) {
      if (!(subSection in SubSection)) {
        return false;
      }
    }
  }

  return true;
}