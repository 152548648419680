import React, { useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import { Round } from "./shared/Round";
import { isPath, Path } from "./shared/Path";
import { cst } from "./utils/constants";
import { Box, Button, Grid, Typography } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { getFreeplayService } from "./network/FreeplayService";
import { Layout } from "./shared/Layout";
import { InteractiveGame } from "./pages/elements/InteractiveGame";
import { SingleRoundElement } from "./pages/elements/SingleRoundElement";
import { defaultCosmetics } from "./shared/Cosmetics";

const freePlayRound: Round = {
  towers: 40,
  claps: 3,
  board: {
    width: cst.defaults.boardWidth,
    height: cst.defaults.boardHeight,
    staticTowers: [],
    startArea: [
      { x: 8, y: 18 },
      { x: 7, y: 18 },
    ],
    endArea: [
      { x: 8, y: -1 },
      { x: 7, y: -1 },
    ],
    waypoint: { x: 8, y: 9 },
  },
};

export const Freeplay = () => {
  const freePlayService = useRef(getFreeplayService()).current;

  const [round, setRound] = useState<Round>(freePlayRound);

  const [layout, setLayout] = useState<Layout>({ towers: [] });

  const [path, setPath] = useState<Path | undefined>();

  const [refresh, setRefresh] = useState(true);

  const game = useMemo(
    () =>
      refresh && (
        <Box sx={{ height: "80vh", mt: "5vh", width: "100vw" }}>
          {path ? (
            <Grid container justifyContent="center">
              <SingleRoundElement
                round={round}
                playerSolution={{
                  player: { name: "asdf", id: 1, guest: false },
                  solution: { layout: layout, path: path },
                  cosmetics: defaultCosmetics,
                }}
                place={0}
                width={800}
              />
            </Grid>
          ) : (
            <InteractiveGame
              round={{ ...round }}
              duration={0}
              path={path}
              startingLayout={layout}
              submitLayout={(layout) => {
                setLayout(layout);
                freePlayService
                  .submit(layout)
                  .then((response) =>
                    isPath(response) ? setPath(response) : console.log(response)
                  );
              }}
            />
          )}
        </Box>
      ),
    [round, freePlayService, path, setLayout, layout, refresh]
  );

  useEffect(() => {
    if (!refresh) setRefresh(true);
  }, [refresh, setRefresh]);

  const buttons = useMemo(
    () => (
      <Box
        maxWidth="sm"
        mx="auto"
        sx={{
          pt: 0,
          width: 1,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="contained"
          color="info"
          style={{ textTransform: "none" }}
          onClick={() => {
            setRound({
              ...freePlayRound,
            });
            setPath(undefined);
            setRefresh(false);
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <Typography sx={{ pr: 1 }}>Retry</Typography>
            <ReplayIcon />
          </Box>
        </Button>
        <Button
          variant="contained"
          color="info"
          style={{ textTransform: "none" }}
          onClick={() => {
            setRound({ ...freePlayRound });
            setLayout({ towers: [] });
            setPath(undefined);
            setRefresh(false);
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <Typography sx={{ pr: 1 }}>Clear</Typography>
            <SkipNextIcon />
          </Box>
        </Button>
      </Box>
    ),
    [setRound, setPath, setLayout]
  );

  return (
    <React.Fragment>
      {game}
      {buttons}
    </React.Fragment>
  );
};
