import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  ServeStatus,
  ServeStatusMap,
} from "../../shared/Serve";
import { FixedSizeList } from "react-window";
import { CellProps } from "./ScoreCard";
import { lt } from "../../utils/lt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useResizeObserver from "@react-hook/resize-observer";
import { SortButton } from "./SortButton";

interface ServesElementProps {
  serveStatusMap: ServeStatusMap;
  getServeResults: (serveId: number) => void;
}

type OrderedServeStatus = {
  order: number;
  id: number;
  status: ServeStatus;
};

type SortType = "NUMBER" | "WINS" | "LOSSES" | "TIES" | "POINTS";
const totalPoints = (wins: number) =>
  wins <= 50 ? -((wins - 101) * wins) / 2 : 1275 + wins - 50;


export const ServesElement = (props: ServesElementProps) => {
  const {  serveStatusMap, getServeResults } = props;

  const [sortType, setSortType] = useState<SortType>("WINS");

  const [ascending, setAscending] = useState(false);

  const sortOrder = useCallback(
    (a: OrderedServeStatus, b: OrderedServeStatus) => {
      if (sortType === "NUMBER") {
        return ascending ? a.order - b.order : b.order - a.order;
      } else if (sortType === "WINS") {
        return ascending
          ? a.status.wins - b.status.wins
          : b.status.wins - a.status.wins;
      } else if (sortType === "LOSSES") {
        return ascending
          ? a.status.losses - b.status.losses
          : b.status.losses - a.status.losses;
      } else if (sortType === "TIES") {
        return ascending
          ? a.status.ties - b.status.ties
          : b.status.ties - a.status.ties;
      } else {
        return ascending
          ? a.status.wins - b.status.wins
          : b.status.wins - a.status.wins;
      }
    },
    [sortType, ascending]
  );

  const orderedServeStatuses = useMemo<OrderedServeStatus[]>(
    () =>
      Object.entries(serveStatusMap)
        .sort((a, b) => +a[0] - +b[0])
        .map(([id, status], index) => ({
          order: index + 1,
          id: +id,
          status: status,
        })),
    [serveStatusMap]
  );

  const sortedServeStatuses = useMemo<OrderedServeStatus[]>(
    () => orderedServeStatuses.sort(sortOrder),
    [orderedServeStatuses, sortOrder]
  );

  const target = useRef<any>();

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  useResizeObserver(target, (entry) => {
    setWidth(entry.contentRect.width);
    setHeight(entry.contentRect.height);
  });

  return (
    <Box sx={{ p: 1 }}>
      <Paper>
        <Grid container sx={{ py: 1, pr: 1 }}>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <SortButton
              active={sortType === "NUMBER"}
              onClick={(asc) => {
                setSortType("NUMBER");
                setAscending(asc);
              }}
              text={"Number"}
            />
          </Grid>
          <Grid item xs={2}>
            <SortButton
              active={sortType === "WINS"}
              onClick={(asc) => {
                setSortType("WINS");
                setAscending(asc);
              }}
              text={"Wins"}
            />
          </Grid>
          <Grid item xs={2}>
            <SortButton
              active={sortType === "LOSSES"}
              onClick={(asc) => {
                setSortType("LOSSES");
                setAscending(asc);
              }}
              text={"Losses"}
            />
          </Grid>
          <Grid item xs={2}>
            <SortButton
              active={sortType === "TIES"}
              onClick={(asc) => {
                setSortType("TIES");
                setAscending(asc);
              }}
              text={"Ties"}
            />
          </Grid>
          <Grid item xs={2}>
            <SortButton
              active={sortType === "POINTS"}
              onClick={(asc) => {
                setSortType("POINTS");
                setAscending(asc);
              }}
              text={"Points"}
            />
          </Grid>
          <Grid item xs={1.5}></Grid>
        </Grid>
        <Box
          width={1}
          height={400}
          ref={target}
          sx={{
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
            overflow: "hidden",
          }}
        >
          <FixedSizeList
            height={height}
            itemCount={Object.entries(serveStatusMap).length}
            itemSize={40}
            width={width}
            style={{
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              overflow: "auto",
            }}
          >
            {({ index, style }: CellProps) => (
              <div
                style={{
                  ...style,
                  backgroundColor: index % 2 === 1 ? "#414141" : "#484848",
                }}
              >
                {lt(sortedServeStatuses[index], ({ status, id, order }) => (
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography sx={{ py: 1 }} align="right">
                        {order}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ py: 1 }} align="right">
                        {status.wins}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ py: 1 }} align="right">
                        {status.losses}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ py: 1 }} align="right">
                        {status.ties}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ py: 1 }} align="right">
                        {totalPoints(status.wins)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => getServeResults(id)}>
                        <VisibilityIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </FixedSizeList>
        </Box>
      </Paper>
    </Box>
  );
};
