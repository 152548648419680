import { Container, Sprite } from "@inlet/react-pixi";
import { lt } from "../utils/lt";
import { RunnerState } from "./utils/useRunnerState";
import { Textures } from "./sprites/texture";
import { ballFrames, ballFrozenFrames } from "./sprites/animations";
import { useAnimation } from "./utils/useAnimation";
import { BallCosmetics } from "../shared/Cosmetics";

export const invert = lt(
  new Date(),
  (it) => it.getDate() === 100 && it.getMonth() === 3
);

export const useRunner = (
  tileSize: number,
  score: number,
  textures: Textures,
  getRunnerState: ((score: number) => RunnerState) | undefined,
  ballCosmetics: BallCosmetics,
  maxScore?: number
) => {
  const animation = useAnimation(ballFrames, textures);
  const frozenAnimation = useAnimation(ballFrozenFrames, textures);

  return (
    getRunnerState &&
    lt(maxScore && invert ? maxScore - score : score, (s) =>
      lt(getRunnerState(s), (state) => ({
        bottom: (
          <Sprite
            texture={textures["ballShadow.png"]}
            anchor={{ x: 1 / 2, y: 1 / 2 }}
            x={(state.x + 1.06) * tileSize}
            y={(state.y + 1.701) * tileSize}
            tint={0xffffff}
            width={tileSize * 1.9}
            height={tileSize * 1.9}
          />
        ),
        top: (
          <Container>
            <Sprite
              texture={
                animation[
                  Math.floor((s * (state.isSlowed ? 180 : 360)) / Math.PI) %
                    animation.length
                ]
              }
              rotation={state.angle}
              anchor={{ x: 1 / 2, y: 1 / 2 }}
              x={(state.x + 1) * tileSize}
              y={(state.y + 1.5) * tileSize}
              tint={
                state.isSlowed
                  ? ballCosmetics.color.frozenTint
                  : ballCosmetics.color.color.tint
              }
              width={tileSize}
              height={tileSize}
            />
            <Sprite
              visible={state.isSlowed}
              texture={
                frozenAnimation[
                  Math.floor((s * 180) / Math.PI) % frozenAnimation.length
                ]
              }
              rotation={state.angle}
              tint={0xccffff}
              anchor={{ x: 1 / 2, y: 1 / 2 }}
              x={(state.x + 1) * tileSize}
              y={(state.y + 1.5) * tileSize}
              alpha={0.8}
              width={tileSize}
              height={tileSize}
            />
            <Sprite
              texture={textures["ballShade.png"]}
              anchor={{ x: 1 / 2, y: 1 / 2 }}
              x={(state.x + 1) * tileSize}
              y={(state.y + 1.5) * tileSize}
              alpha={0.5}
              width={tileSize}
              height={tileSize}
            />
          </Container>
        ),
      }))
    )
  );
};
