import axios from "axios";
import {
  AcademyCategory,
  AcademyLevelProgress,
  AcademyProgress,
  AcademyRound,
  AcademyRoundResult,
  CosmeticPlayerPath,
  ResultPlayers,
} from "../shared/Academy";
import { BoardSize } from "../shared/Board";
import { Layout } from "../shared/Layout";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface AcademyService {
  round: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number
  ) => Promise<AcademyRound | ErrorMessage>;
  submit: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    layout: Layout
  ) => Promise<AcademyRoundResult | ErrorMessage>;
  progress: () => Promise<AcademyProgress | ErrorMessage>;
  catSizeProgress: (category: AcademyCategory, boardSize: BoardSize) =>
    Promise <AcademyLevelProgress[] | ErrorMessage>;
  path: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    result: number,
    version: number
  ) => Promise<CosmeticPlayerPath | ErrorMessage>;
  layouts: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    result: number,
  ) => Promise<ResultPlayers[] | ErrorMessage>;
}

export const academyService: AcademyService = {
  round: (category: AcademyCategory, boardSize: BoardSize, level: number) =>
    axios
      .get<AcademyRound>(
        cst.backendUrl +
          "/academy/round/" +
          category +
          "/" +
          boardSize +
          "/" +
          level,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  progress: () =>
    axios
      .get<AcademyProgress>(cst.backendUrl + "/academy/progress", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  catSizeProgress: (category, boardSize) =>
    axios
      .get<AcademyLevelProgress[]>(cst.backendUrl + "/academy/progress/" + category + "/" + boardSize, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  submit: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    layout: Layout
  ) =>
    axios
      .post<AcademyRoundResult>(
        cst.backendUrl +
          "/academy/submit/" +
          category +
          "/" +
          boardSize +
          "/" +
          level,
        layout,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  layouts: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    result: number,
  ) =>
    axios
      .get<ResultPlayers[]>(
        cst.backendUrl +
          "/academy/layouts/" +
          category +
          "/" +
          boardSize +
          "/" +
          level +
          "/" +
          result,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  path: (
    category: AcademyCategory,
    boardSize: BoardSize,
    level: number,
    result: number,
    version: number
  ) =>
    axios
      .get<CosmeticPlayerPath>(
        cst.backendUrl +
          "/academy/path/" +
          category +
          "/" +
          boardSize +
          "/" +
          level +
          "/" +
          result +
          "/" +
          version,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
};
