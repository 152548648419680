import {
  Badge,
  Box,
  Chip,
  Grid,
  List,
  ListItemButton,
  ListSubheader,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { PublicChallenge } from "../../shared/Challenge";
import { lt } from "../../utils/lt";
import { useSecondsPassed } from "../../utils/useSecondsPassed";
import GroupIcon from "@mui/icons-material/Group";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { secondsToHHMMSS } from "../../utils/secToHHMMSS";
import { timeAgo } from "../../utils/timeAgo";
import { stringShorter } from "../../utils/stringShorter";
import { useStoredPrioritizedList } from "../../utils/useStoredPrioritizedList";
import { unselectable } from "../../utils/unselectable";

interface ChallengeListElementProps {
  title: String;
  challenges: PublicChallenge[];
  active?: boolean;
  daily?: boolean;
  reloadChallenges?: () => void;
}

export const ChallengeListElement = (props: ChallengeListElementProps) => {
  const {
    title,
    challenges,
    active = false,
    daily = false,
    reloadChallenges = () => {},
  } = props;

  const { secondsPassed } = useSecondsPassed();

  const [prioritizedList] = useStoredPrioritizedList("replays");

  const navigate = useNavigate();

  return (
    <Paper sx={{ my: 1 }}>
      <Typography variant="h6" sx={{ py: 1, ...unselectable }} align="center">
        {title}
      </Typography>
      <List
        sx={{ maxHeight: 400, overflow: "auto" }}
        subheader={
          <ListSubheader
            sx={{
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              zIndex: 100,
            }}
          >
            <Grid container lineHeight={2} sx={unselectable}>
              <Grid item xs={2} sm={3}>
                {!daily && (
                  <Typography variant="caption">
                    <b>Host</b>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography variant="caption" align="left">
                  <b>Rounds</b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" align="left">
                  <b>Duration</b>
                </Typography>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        {challenges
          .sort((a, b) =>
            lt(
              a.parameters.settings.deadline === undefined
                ? 0
                : a.parameters.settings.deadline,
              (deadlineA) =>
                lt(
                  b.parameters.settings.deadline === undefined
                    ? 0
                    : b.parameters.settings.deadline,
                  (deadlineB) =>
                    active ? deadlineA - deadlineB : deadlineB - deadlineA
                )
            )
          )
          .map((challenge, index) => (
            <ListItemButton
              key={index}
              onClick={() => navigate("/challenge/" + (daily ? "daily/past/" : "") + challenge.parameters.id)}
              sx={{ mx: 0, pr: 0 }}
            >
              <Grid container sx={{ pt: 1, pb: active ? 0 : 1 }}>
                <Grid item xs={3} sm={4}>
                  <Typography>
                    <b>
                      {stringShorter(
                        challenge.host.name,
                        window.innerWidth > 550
                          ? 15
                          : 15 - Math.round((550 - window.innerWidth) / 20)
                      )}
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="left">
                    {challenge.parameters.settings.totalRounds}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align="center">
                    {secondsToHHMMSS(
                      challenge.parameters.settings.roundDuration
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="center">
                    {challenge.parameters.settings.deadline &&
                      (active
                        ? secondsToHHMMSS(
                            lt(
                              Math.round(
                                challenge.parameters.settings.deadline / 1000
                              ) - secondsPassed,
                              (seconds) => {
                                if (seconds <= 0) {
                                  reloadChallenges();
                                  return 0;
                                } else {
                                  return seconds;
                                }
                              }
                            )
                          )
                        : timeAgo(-challenge.parameters.settings.deadline))}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {challenge.players.length > 0 && (
                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        <Box
                          maxHeight={300}
                          sx={{
                            overflow: "auto",
                            scrollbarGutter: "stable",
                            pr: 1,
                            mr: -1,
                            my: -0.5,
                          }}
                        >
                          {challenge.players
                            .sort((a, b) => {
                              const aPriority = lt(
                                prioritizedList.indexOf(a.id),
                                (priority) =>
                                  priority !== -1
                                    ? priority
                                    : prioritizedList.length +
                                      (a.guest ? -a.id : a.id)
                              );
                              const bPriority = lt(
                                prioritizedList.indexOf(b.id),
                                (priority) =>
                                  priority !== -1
                                    ? priority
                                    : prioritizedList.length +
                                      (b.guest ? -b.id : b.id)
                              );
                              return aPriority - bPriority;
                            })
                            .map((player, index) => (
                              <Typography noWrap key={index}>
                                {player.guest ? (
                                  <i>{player.name}</i>
                                ) : (
                                  player.name
                                )}
                              </Typography>
                            ))}
                        </Box>
                      }
                    >
                      <Badge
                        badgeContent={
                          <Typography variant="caption">
                            {challenge.players.length}
                          </Typography>
                        }
                      >
                        <GroupIcon />
                      </Badge>
                    </Tooltip>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={1}
                  sx={{ pr: 2 }}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {active &&
                    (challenge.progress === 0 ? (
                      <PlayCircleOutlineIcon />
                    ) : challenge.progress ===
                      challenge.parameters.settings.totalRounds ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <Chip
                        size="small"
                        label={
                          challenge.progress +
                          "/" +
                          challenge.parameters.settings.totalRounds
                        }
                      />
                    ))}
                  {!active &&
                    challenge.progress ===
                      challenge.parameters.settings.totalRounds && (
                      <CheckCircleOutlineIcon />
                    )}
                </Grid>
              </Grid>
            </ListItemButton>
          ))}
      </List>
    </Paper>
  );
};
