import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MazePaper } from "./elements/MazePaper";
import { getChallengeService } from "../network/ChallengeService";
import { isPublicChallenges, PublicChallenges } from "../shared/Challenge";
import { ChallengeListElement } from "./elements/ChallengeListElement";
import { useNavigate } from "react-router";
import { NewChallenge } from "./NewChallenge";
import { Player } from "../shared/Player";
import PageVisibility from "react-page-visibility";

interface ChallengesPageProps {
  player: Player;
  create?: boolean;
}

export const ChallengesPage = (props: ChallengesPageProps) => {
  const { player, create = false } = props;
  const [publicChallenges, setPublicChallenges] = useState<
    PublicChallenges | undefined
  >();

  const reloadChallenges = useCallback(() => {
    getChallengeService()
      .public()
      .then((response) => {
        if (isPublicChallenges(response)) {
          setPublicChallenges(response);
        }
      });
  }, [setPublicChallenges]);

  useEffect(reloadChallenges, [reloadChallenges]);

  const navigate = useNavigate();

  return (
    <Box maxWidth="sm" mx="auto" sx={{ pt: 2 }}>
      <PageVisibility
        onChange={(visible) => {
          if (visible) reloadChallenges();
        }}
      >
        <MazePaper title="Challenges" goBack={() => navigate("/")}>
          <NewChallenge player={player} create={create} />
          <Box sx={{ pt: 1 }} />
          {publicChallenges && (
            <Fragment>
              {publicChallenges.active.length > 0 && (
                <ChallengeListElement
                  title={"Active Challenges"}
                  challenges={publicChallenges.active || []}
                  active
                />
              )}
              <ChallengeListElement
                title={"Past Challenges"}
                challenges={publicChallenges.past || []}
              />
            </Fragment>
          )}
        </MazePaper>
      </PageVisibility>
    </Box>
  );
};
