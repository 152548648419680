import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Stage, TilingSprite } from "@inlet/react-pixi";
import BoardElement from "./BoardElement";
import { Countdown } from "./ui/Countdown";
import { useCounter } from "./utils/useCounter";
import { Round } from "../shared/Round";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { cst } from "../utils/constants";
import Box from "@mui/system/Box";
import { BlockCounter } from "./ui/BlockCounter";
import { useSize } from "./utils/useSize";
import { TutorialText } from "./TutorialText";
import { Board } from "../shared/Board";
import exampleBoardJson from "./tutorial/exampleBoard.json";
import { useNavigate } from "react-router";
import { RunnerIndicatorState } from "../pages/elements/RunnerIndicator";
import { lt } from "../utils/lt";
import { FrostCounter } from "./ui/FrostCounter";
import { Cosmetics, defaultCosmetics } from "../shared/Cosmetics";
import { useTextures } from "./sprites/TextureDaemon";
import { ToggleTestProp } from "../pages/ToggleTest";

export interface GameProps {
  round: Round;
  duration?: number;
  submitLayout?: (layout: Layout) => void;
  path?: Path;
  solutionLayout?: Layout;
  animationSpeed?: number;
  preDelete?: boolean;
  showSkip?: boolean;
  deleteNow?: boolean;
  isReplay?: boolean;
  tutorial?: boolean;
  startingLayout?: Layout;
  hideSkipReplay?: boolean;
  tutorialStartStep?: number;
  resetRound?: (layout: Layout) => void;
  runnerCompleted?: () => void;
  setExternalTutorialStep?: React.Dispatch<React.SetStateAction<number>>;
  setRunnerIndicatorState?: React.Dispatch<
    React.SetStateAction<RunnerIndicatorState>
  >;
  skipWaitAfterRunner?: boolean;
  score?: number;
  maxScore?: number;
  pause?: boolean;
  cosmetics?: Cosmetics;
  drawingStatic?: boolean;
  startingWidth?: number;
  drawMode?: boolean;
  startingTileSize?: number;
  setScore?: React.Dispatch<number>;
  setCurrentLayout?: (layout: Layout) => void;
  resetReference?: React.MutableRefObject<() => void>;
  submitReference?: React.MutableRefObject<() => void>;
  setLayoutReference?: React.MutableRefObject<(layout: Layout) => void>;
  skipReference?: React.MutableRefObject<() => void>;
  setInteractiveReference?: React.MutableRefObject<(v: boolean) => void>;
  iosTest?: ToggleTestProp;
  setImage?: (s: string) => void;
  hasLoaded?: () => void;
  resetInteraction?: boolean;
}

export const Game = (props: GameProps) => {
  const {
    round,
    path,
    duration = 0,
    solutionLayout,
    startingLayout = { towers: [] },
    deleteNow = false,
    tutorial = false,
    showSkip = true,
    pause = false,
    drawingStatic = false,
    drawMode = false,
    isReplay = false,
    iosTest,
    setImage,
    score,
    startingWidth = 1,
    resetReference,
    submitReference,
    setLayoutReference,
    setInteractiveReference,
    hasLoaded = () => {},
    maxScore,
    cosmetics = defaultCosmetics,
    tutorialStartStep = 0,
    setCurrentLayout = (_) => {},
    setExternalTutorialStep,
    setRunnerIndicatorState = (_) => {},
    runnerCompleted = () => {},
    resetRound = (_) => {},
    setScore,
    submitLayout = (layout: Layout) => {},
  } = props;

  const gameTarget = useRef<HTMLElement>();

  const tileSize = useSize(
    gameTarget,
    Math.floor((startingWidth / (round.board.width + 1)) * cst.tileSize) /
      cst.tileSize,
    (domRect) => {
      const width = domRect.width / (round.board.width + 1);
      const height = domRect.height / (round.board.height + 9 / 6);
      const result = width < height ? width : height;
      return Math.floor(result * cst.tileSize) / cst.tileSize;
    }
  );

  const [touchTutorial, setTouchTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(tutorialStartStep);

  const textures = useTextures();

  const towerCounter = useCounter(round.towers, drawMode);
  const clapCounter = useCounter(round.claps, drawMode);

  const [layout, setLayout] = useState<Layout>(startingLayout);

  useEffect(() => setCurrentLayout(layout), [layout, setCurrentLayout]);

  const [interactive, setInteractive] = useState(solutionLayout === undefined);

  useEffect(() => {
    if (setInteractiveReference) {
      setInteractiveReference.current = setInteractive;
    }
  }, [setInteractive, setInteractiveReference]);

  const submit = useCallback(() => {
    setInteractive(false);
    submitLayout(layout);
  }, [layout, submitLayout]);

  useEffect(() => {
    if (submitReference) {
      submitReference.current = () => submit();
    }
  }, [submitReference, submit]);

  const [showGame, setShowGame] = useState(true);

  useEffect(() => {
    if (deleteNow) setShowGame(false);
  }, [deleteNow, setShowGame]);

  const resourceOffset = useMemo(
    () =>
      Math.max(
        lt(
          round.board.endArea
            .filter(({ x, y }) => y === -1)
            .map(({ x, y }) => x),
          (xCoords) =>
            lt(Math.max(...xCoords), (maxX) =>
              maxX < 6 && maxX > -1 ? (maxX * 6 + 7) / 6 : 0
            )
        ),
        lt(
          round.board.startArea
            .filter(({ x, y }) => y === -1)
            .map(({ x, y }) => x),
          (xCoords) =>
            lt(Math.max(...xCoords), (maxX) =>
              maxX < 6 && maxX > -1 ? (maxX * 6 + 7) / 6 : 0
            )
        )
      ),
    [round]
  );
  const [board, setBoard] = useState<Board>(round.board);
  useEffect(() => {
    if (tutorialStep > 11) {
      setBoard(exampleBoardJson);
      towerCounter.setValue(10 - layout.towers.length);
    } else {
      setBoard(round.board);
      clapCounter.setValue(
        round.claps - layout.towers.filter((it) => it.clap).length
      );
      towerCounter.setValue(round.towers - layout.towers.length);
    }
    // eslint-disable-next-line
  }, [round, layout, tutorialStep]);

  const scoreOffset = useMemo(
    () =>
      lt(
        [...board.endArea, ...board.startArea]
          .filter(({ x, y }) => y === -1)
          .map(({ x, y }) => x),
        (xCoords) => {
          if (
            xCoords.some((it) => it === board.width - 1) &&
            xCoords.some((it) => it === board.width - 2)
          ) {
            return 1;
          } else if (
            xCoords.some((it) => it === board.width - 2) &&
            xCoords.some((it) => it === board.width - 3)
          ) {
            return 2;
          } else if (
            xCoords.some((it) => it === board.width - 3) &&
            xCoords.some((it) => it === board.width - 4)
          ) {
            return 3;
          } else if (
            xCoords.some((it) => it === board.width - 4) &&
            xCoords.some((it) => it === board.width - 5)
          ) {
            return -1;
          } else {
            return 0;
          }
        }
      ),
    [board]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (tutorial) {
      if (setExternalTutorialStep) setExternalTutorialStep(tutorialStep);
      if (tutorialStep === 12) {
        setBoard(exampleBoardJson);
        towerCounter.setValue(10);
      }
      if (tutorialStep === 22) {
        clapCounter.setValue(1);
        setInteractive(true);
      }
      if (tutorialStep === 24 && clapCounter.value === 0) {
        setTutorialStep((s) => s + 1);
      }

      if (tutorialStep === 31) {
        resetRound(layout);
      }

      if (tutorialStep === 35) {
        setShowGame(false);
        sessionStorage.setItem("playedTutorial", "true");
        window.setTimeout(() => navigate("/redirect"), 10);
      }
    }
  }, [
    tutorialStep,
    tutorial,
    clapCounter,
    towerCounter,
    navigate,
    setExternalTutorialStep,
    setTutorialStep,
    setShowGame,
    setInteractive,
    layout,
    resetRound,
  ]);

  const timerFlash = useRef<() => void>(() => {});

  return (
    <Box
      onContextMenu={interactive ? (e) => e.preventDefault() : undefined}
      ref={gameTarget}
      height={1}
      width={1}
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: interactive || tutorial ? undefined : "none",
      }}
    >
      <Stage
        width={tileSize * (round.board.width + 1)}
        height={tileSize * (round.board.height + 3 / 2)}
        options={{ backgroundAlpha: 0, sharedLoader: true }}
        raf={path !== undefined}
        onUnmount={(app) => {
          app.stage.width = 0;
          app.stage.height = 0;
          app.view.width = 0;
          app.view.height = 0;
          app.stage.destroy();
        }}
      >
        {showGame && (
          <Container>
            <BoardElement
              setRunnerIndicatorState={setRunnerIndicatorState}
              isReplay={isReplay}
              textures={textures}
              interactive={
                interactive &&
                !(
                  tutorial &&
                  [5, 8, 14, 17, 18, 24, 32].indexOf(tutorialStep) === -1
                )
              }
              cosmetics={cosmetics}
              solutionLayout={solutionLayout}
              startingLayout={startingLayout}
              setLayout={setLayout}
              scoreOffset={scoreOffset}
              setLayoutReference={setLayoutReference}
              submit={submit}
              drawMode={drawMode}
              showSkip={showSkip}
              skip={runnerCompleted}
              runnerCompleted={() => {
                if (tutorial)
                  window.setTimeout(() => setTutorialStep((s) => s + 1), 1000);
                runnerCompleted();
              }}
              drawingStatic={drawingStatic}
              towerCounter={towerCounter}
              clapCounter={clapCounter}
              tileSize={tileSize}
              maxScore={maxScore}
              hasLoaded={hasLoaded}
              board={board}
              timerFlash={timerFlash}
              path={path}
              pause={pause}
              test={iosTest}
              setScore={setScore}
              score={score}
              tutorial={tutorial}
              resetReference={resetReference}
              incrementTutorialStep={() =>
                tutorial && setTutorialStep((s) => s + 1)
              }
              tutorialStep={tutorialStep}
              setImage={setImage}
            />
            <BlockCounter
              tileSize={tileSize}
              x={
                (3 / 6 + resourceOffset + clapCounter.value * (3 / 4)) *
                tileSize
              }
              y={(tileSize * 1) / 6}
              textures={textures}
              counter={towerCounter.value}
              size={(tileSize * 2) / 3}
            />
            <FrostCounter
              tileSize={tileSize}
              x={(3 / 6 + resourceOffset) * tileSize}
              y={(tileSize * 1) / 6}
              textures={textures}
              counter={clapCounter.value}
              size={(tileSize * 2) / 3}
            />
            {((solutionLayout === undefined &&
              duration > 0 &&
              path === undefined) ||
              tutorialStep === 33) && (
              <Countdown
                textures={textures}
                ended={tutorial ? false : !interactive}
                x={(board.width - 18 / 6 - scoreOffset) * tileSize}
                y={(1 / 4) * tileSize}
                height={(tileSize * 2) / 3}
                tileSize={tileSize}
                duration={tutorial ? 90 : duration}
                timerFlash={timerFlash.current}
                timesUp={() => {
                  if (interactive && !tutorial) {
                    setInteractive(false);
                    submit();
                  }
                }}
              />
            )}
          </Container>
        )}
        {tutorial &&
          [5, 8, 14, 17, 18, 24, 32].indexOf(tutorialStep) === -1 &&
          tutorial && (
            <TilingSprite
              texture={textures["tile.png"]}
              clampMargin={1.5}
              y={
                [2, 3, 11, 20, 22, 33].indexOf(tutorialStep) !== -1
                  ? tileSize
                  : 0
              }
              width={(round.board.width + 1) * tileSize}
              height={
                (round.board.height +
                  ([2, 3, 10, 11, 20, 22, 30].indexOf(tutorialStep) !== -1
                    ? 1
                    : 2)) *
                tileSize
              }
              tilePosition={{ x: 0, y: 0 }}
              tileScale={tileSize / cst.tileSize}
              tint={0}
              alpha={0.8}
              interactive
              mouseup={(_) => {
                setTutorialStep((s) => s + 1);
                setTouchTutorial(false);
              }}
              touchend={(_) => {
                setTutorialStep((s) => s + 1);
                setTouchTutorial(true);
              }}
            />
          )}
        {tutorial && tutorialStep !== undefined && (
          <TutorialText
            tutorialStep={tutorialStep}
            boardHeight={round.board.height}
            boardWidth={round.board.width + 1}
            tileSize={tileSize}
            isTouch={touchTutorial}
          />
        )}
      </Stage>
    </Box>
  );
};
