import { Button } from "@mui/material";
import React, { useState } from "react";
import googleSigninNormal from "../../resources/google/btn_google_signin_dark_normal_web.png";
import googleSigninFocus from "../../resources/google/btn_google_signin_dark_focus_web.png";
import googleSigninPressed from "../../resources/google/btn_google_signin_dark_pressed_web.png";
import { useLocation } from "react-router";
import { cst } from "../../utils/constants";

interface GoogleSigninButtonProps {
  returnRoute?: string;
  onClick?: () => void;
}

export const GoogleSigninButton = (props: GoogleSigninButtonProps) => {
  const { returnRoute, onClick = () => {} } = props;

  const [googleButton, setGoogleButton] = useState<string>(googleSigninNormal);
  const location = useLocation();

  return (
    <Button
      disableElevation
      disableRipple
      size="small"
      sx={{
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent",
        },
      }}
      onClick={() => {
        setGoogleButton(googleSigninPressed);
        if (localStorage.getItem("returnRoute") === null) {
          localStorage.setItem("returnRoute", returnRoute ? returnRoute : location.pathname);
        }
        onClick() 
        window.open(cst.backendUrl + "/oauth/google/signin", "_self");
      }}
      onPointerDown={() => setGoogleButton(googleSigninPressed)}
      onFocus={() =>
        setGoogleButton((button) =>
          button === googleSigninPressed
            ? googleSigninPressed
            : googleSigninFocus
        )
      }
      onBlur={() => setGoogleButton(googleSigninNormal)}
      onPointerUp={() => setGoogleButton(googleSigninNormal)}
    >
      <img src={googleButton} alt="Sign in with Google"/>
    </Button>
  );
};
