import { useMemo } from "react";
import { Board } from "../../shared/Board";
import { TextureName } from "../sprites/texture";

export type TilingSpriteProps = {
  texture: TextureName;
  width: number;
  height: number;
  x: number;
  y: number;
  tint?: number;
  alpha?: number;
  tilePosition?: { x: number; y: number };
};

export const entryZoneBottomTilingProps = (board: Board, exit: boolean) => {
  let area = exit ? board.endArea : board.startArea;
  let tint = exit ? 0xf5b158 : 0x81b2fc;

  let corner = area.length === 3;

  let innerCorner =
    !corner &&
    area.some(
      ({ x, y }) =>
        y === 0 || y === board.height - 1 || x === 0 || x === board.width - 1
    );

  let north = area.some(({ x, y }) => y === -1);
  let south = area.some(({ x, y }) => y === board.height);
  let west = area.some(({ x, y }) => x === -1);
  let east = area.some(({ x, y }) => x === board.width);
  if (corner) {
    if (north) {
      if (west) {
        return [
          {
            texture: "cornerExitNW.png",
            x: 0,
            y: 0,
            height: 5 / 2,
            width: 2,
          },
          {
            texture: "cornerExitZoneNW.png",
            x: 0,
            y: 0,
            tint: tint,
            height: 5 / 2,
            width: 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: 3 / 4,
                  height: 1 / 4,
                  width: 5 / 4,
                  alpha: 1 / 2,
                },
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: 4 / 4,
                  height: 4 / 4,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "cornerExitNE.png",
            x: board.width - 1,
            y: 0,
            height: 5 / 2,
            width: 2,
          },
          {
            texture: "cornerExitZoneNE.png",
            x: board.width - 1,
            y: 0,
            tint: tint,
            height: 5 / 2,
            width: 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width - 1 / 2,
                  y: 3 / 4,
                  height: 1 / 4,
                  width: 5 / 4,
                  alpha: 1 / 2,
                },
                {
                  texture: "checker.png",
                  x: board.width + 1 / 2,
                  y: 4 / 4,
                  height: 4 / 4,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    } else {
      if (west) {
        return [
          {
            texture: "cornerExitSW.png",
            x: 0,
            y: board.height - 1 / 2,
            height: 2,
            width: 2,
          },
          {
            texture: "cornerExitZoneSW.png",
            x: 0,
            y: board.height - 1 / 2,
            tint: tint,
            height: 2,
            width: 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: board.height + 1,
                  height: 1 / 4,
                  width: 5 / 4,
                  alpha: 1 / 2,
                },
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: board.height,
                  height: 4 / 4,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "cornerExitSE.png",
            x: board.width - 1,
            y: board.height - 1 / 2,
            height: 2,
            width: 2,
          },
          {
            texture: "cornerExitZoneSE.png",
            x: board.width - 1,
            y: board.height - 1 / 2,
            tint: tint,
            height: 2,
            width: 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width - 1 / 2,
                  y: board.height + 1,
                  height: 1 / 4,
                  width: 5 / 4,
                  alpha: 1 / 2,
                },
                {
                  texture: "checker.png",
                  x: board.width + 1 / 2,
                  y: board.height,
                  height: 4 / 4,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    }
  } else if (innerCorner) {
    if (north) {
      if (area.some(({ x, y }) => x === 0)) {
        return [
          {
            texture: "innerCornerExitNW.png",
            x: 0,
            y: 0,
            height: 3 / 2,
            width: 3,
          },
          {
            texture: "innerCornerExitZoneNW.png",
            x: 1 / 4,
            y: 0,
            tint: tint,
            height: 1,
            width: 5 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 2,
                  y: 3 / 4,
                  height: 1 / 4,
                  width: 2,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "innerCornerExitNE.png",
            x: board.width - 2,
            y: 0,
            height: 3 / 2,
            width: 3,
          },
          {
            texture: "innerCornerExitZoneNE.png",
            x: board.width - 7 / 4,
            y: 0,
            tint: tint,
            height: 1,
            width: 5 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width - 6 / 4,
                  y: 3 / 4,
                  height: 1 / 4,
                  width: 2,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    }
    if (south) {
      if (area.some(({ x, y }) => x === 0)) {
        return [
          {
            texture: "innerCornerExitSW.png",
            x: 0,
            y: board.height + 1 / 2,
            height: 1,
            width: 3,
          },
          {
            texture: "innerCornerExitZoneSW.png",
            x: 1 / 4,
            y: board.height + 1,
            tint: tint,
            height: 1 / 2,
            width: 5 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 2,
                  y: board.height + 1,
                  height: 1 / 4,
                  width: 2,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "innerCornerExitSE.png",
            x: board.width - 2,
            y: board.height + 1 / 2,
            height: 1,
            width: 3,
          },
          {
            texture: "innerCornerExitZoneSE.png",
            x: board.width - 7 / 4,
            y: board.height + 1,
            tint: tint,
            height: 1 / 2,
            width: 5 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width - 6 / 4,
                  y: board.height + 1,
                  height: 1 / 4,
                  width: 2,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    }
    if (west) {
      if (area.some(({ x, y }) => y === 0)) {
        return [
          {
            texture: "innerCornerExitWN.png",
            x: 0,
            y: 0,
            height: 7 / 2,
            width: 1,
          },
          {
            texture: "innerCornerExitZoneWN.png",
            x: 0,
            y: 3 / 4,
            tint: tint,
            height: 5 / 2,
            width: 1 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: 1,
                  height: 2,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "innerCornerExitWS.png",
            x: 0,
            y: board.height - 3 / 2,
            height: 3,
            width: 1,
          },
          {
            texture: "innerCornerExitZoneWS.png",
            x: 0,
            y: board.height - 5 / 4,
            tint: tint,
            height: 5 / 2,
            width: 1 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: 1 / 4,
                  y: board.height - 1,
                  height: 2,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    }
    if (east) {
      if (area.some(({ x, y }) => y === 0)) {
        return [
          {
            texture: "innerCornerExitEN.png",
            x: board.width,
            y: 0,
            height: 7 / 2,
            width: 1,
          },
          {
            texture: "innerCornerExitZoneEN.png",
            x: board.width + 1 / 2,
            y: 3 / 4,
            tint: tint,
            height: 5 / 2,
            width: 1 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width + 1 / 2,
                  y: 1,
                  height: 2,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      } else {
        return [
          {
            texture: "innerCornerExitES.png",
            x: board.width,
            y: board.height - 3 / 2,
            height: 3,
            width: 1,
          },
          {
            texture: "innerCornerExitZoneES.png",
            x: board.width + 1 / 2,
            y: board.height - 5 / 4,
            tint: tint,
            height: 5 / 2,
            width: 1 / 2,
          },
          ...(exit
            ? [
                {
                  texture: "checker.png",
                  x: board.width + 1 / 2,
                  y: board.height - 1,
                  height: 2,
                  width: 1 / 4,
                  alpha: 1 / 2,
                },
              ]
            : []),
        ] as TilingSpriteProps[];
      }
    }
  }

  let minX = Math.min(...area.map((c) => c.x));
  let minY = Math.min(...area.map((c) => c.y));

  if (north) {
    return [
      {
        texture: "exitN.png",
        x: minX,
        y: 0,
        height: 3 / 2,
        width: 3,
      },
      {
        texture: "exitZoneN.png",
        x: minX + 1 / 4,
        y: 0,
        tint: tint,
        height: 1,
        width: 5 / 2,
      },
      ...(exit
        ? [
            {
              texture: "checker.png",
              x: minX + 1 / 2,
              y: 3 / 4,
              height: 1 / 4,
              width: 2,
              alpha: 1 / 2,
            },
          ]
        : []),
    ] as TilingSpriteProps[];
  }
  if (south) {
    return [
      {
        texture: "exitS.png",
        x: minX,
        y: board.height + 1 / 2,
        height: 1,
        width: 3,
      },
      {
        texture: "exitZoneS.png",
        x: minX + 1 / 4,
        y: board.height + 1,
        tint: tint,
        height: 1 / 2,
        width: 5 / 2,
      },
      ...(exit
        ? [
            {
              texture: "checker.png",
              x: minX + 1 / 2,
              y: board.height + 1,
              height: 1 / 4,
              width: 2,
              alpha: 1 / 2,
            },
          ]
        : []),
    ] as TilingSpriteProps[];
  }
  if (west) {
    return [
      {
        texture: "exitW.png",
        x: 0,
        y: minY + 1 / 2,
        height: 3,
        width: 1,
      },
      {
        texture: "exitZoneW.png",
        x: 0,
        y: minY + 3 / 4,
        tint: tint,
        height: 5 / 2,
        width: 1 / 2,
      },
      ...(exit
        ? [
            {
              texture: "checker.png",
              x: 1 / 4,
              y: minY + 1,
              height: 2,
              width: 1 / 4,
              alpha: 1 / 2,
            },
          ]
        : []),
    ] as TilingSpriteProps[];
  }
  if (east) {
    return [
      {
        texture: "exitE.png",
        x: board.width,
        y: minY + 1 / 2,
        height: 3,
        width: 1,
      },
      {
        texture: "exitZoneE.png",
        x: board.width + 1 / 2,
        y: minY + 3 / 4,
        tint: tint,
        height: 5 / 2,
        width: 1 / 2,
      },
      ...(exit
        ? [
            {
              texture: "checker.png",
              x: board.width + 1 / 2,
              y: minY + 1,
              height: 2,
              width: 1 / 4,
              alpha: 1 / 2,
            },
          ]
        : []),
    ] as TilingSpriteProps[];
  }
  return [] as TilingSpriteProps[];
};

export const entryZoneTopTilingProps: (
  board: Board,
  exit: boolean
) => TilingSpriteProps[] = (board: Board, exit: boolean) => {
  let area = exit ? board.endArea : board.startArea;

  let corner = area.length === 3;

  let innerCorner =
    !corner &&
    area.some(
      ({ x, y }) =>
        y === 0 || y === board.height - 1 || x === 0 || x === board.width - 1
    );

  let north = area.some(({ x, y }) => y === -1);
  let south = area.some(({ x, y }) => y === board.height);
  let west = area.some(({ x, y }) => x === -1);
  let east = area.some(({ x, y }) => x === board.width);
  if (corner) {
    if (north) {
      if (west) {
        return [
          {
            texture: "exitTopNE.png",
            x: 3 / 2,
            y: 0,
            height: 2 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopS.png",
            x: 0,
            y: 2,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitTopNW.png",
            x: board.width - 1,
            y: 0,
            height: 2 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopS.png",
            x: board.width + 1 / 2,
            y: 2,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    } else {
      if (west) {
        return [
          {
            texture: "exitTopSE.png",
            x: 3 / 2,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopN.png",
            x: 0,
            y: board.height - 1 / 2,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitTopSW.png",
            x: board.width - 1,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopN.png",
            x: board.width + 1 / 2,
            y: board.height - 1 / 2,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    }
  } else if (innerCorner) {
    if (north) {
      if (area.some(({ x, y }) => x === 0)) {
        return [
          {
            texture: "exitTopNE.png",
            x: 5 / 2,
            y: 0,
            height: 1,
            width: 1 / 2,
          },
          {
            texture: "sideTop4W.png",
            x: 0,
            y: 1 / 2,
            height: 1 / 2,
            width: 1 / 4,
          },
          {
            texture: "sideTop4E.png",
            x: 1 / 4,
            y: 1 / 2,
            height: 1 / 2,
            width: 1 / 4,
          },
          {
            texture: "exitSideTopS.png",
            x: 0,
            y: 0,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitTopNW.png",
            x: board.width - 2,
            y: 0,
            height: 2 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopS.png",
            x: board.width + 1 / 2,
            y: 0,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    }
    if (south) {
      if (area.some(({ x, y }) => x === 0)) {
        return [
          {
            texture: "exitSideTopN.png",
            x: 0,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitTopSE.png",
            x: 10 / 4,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitSideTopN.png",
            x: board.width + 1 / 2,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitTopSW.png",
            x: board.width - 8 / 4,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    }
    if (west) {
      if (area.some(({ x, y }) => y === 0)) {
        return [
          {
            texture: "exitTopNE.png",
            x: 0,
            y: 0,
            height: 2 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopS.png",
            x: 0,
            y: 12 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "sideTop4N.png",
            x: 1 / 2,
            y: 0,
            height: 1 / 4,
            width: 1 / 2,
          },
          {
            texture: "sideTop4S.png",
            x: 1 / 2,
            y: 3 / 4,
            height: 1 / 4,
            width: 1 / 2,
          },
          {
            texture: "topGap.png",
            x: 1 / 2,
            y: 1 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitTopSE.png",
            x: 0,
            y: board.height + 2 / 2,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopN.png",
            x: 0,
            y: board.height - 6 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    }
    if (east) {
      if (area.some(({ x, y }) => y === 0)) {
        return [
          {
            texture: "exitTopNW.png",
            x: board.width + 1 / 2,
            y: 0,
            height: 2 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopS.png",
            x: board.width + 1 / 2,
            y: 12 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "sideTop4N.png",
            x: board.width,
            y: 0,
            height: 1 / 4,
            width: 1 / 2,
          },
          {
            texture: "sideTop4S.png",
            x: board.width,
            y: 3 / 4,
            height: 1 / 4,
            width: 1 / 2,
          },
          {
            texture: "topGap.png",
            x: board.width,
            y: 1 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      } else {
        return [
          {
            texture: "exitTopSW.png",
            x: board.width + 1 / 2,
            y: board.height + 1,
            height: 1 / 2,
            width: 1 / 2,
          },
          {
            texture: "exitSideTopN.png",
            x: board.width + 1 / 2,
            y: board.height - 6 / 4,
            height: 1 / 2,
            width: 1 / 2,
          },
        ];
      }
    }
  }

  let minX = Math.min(...area.map((c) => c.x));
  let minY = Math.min(...area.map((c) => c.y));

  if (north) {
    return [
      {
        texture: "exitTopNW.png",
        x: minX,
        y: 0,
        height: 2 / 2,
        width: 1 / 2,
      },
      {
        texture: "exitTopNE.png",
        x: minX + 10 / 4,
        y: 0,
        height: 1,
        width: 1 / 2,
      },
    ];
  }
  if (south) {
    return [
      {
        texture: "exitTopSW.png",
        x: minX,
        y: board.height + 1,
        height: 1 / 2,
        width: 1 / 2,
      },
      {
        texture: "exitTopSE.png",
        x: minX + 10 / 4,
        y: board.height + 1,
        height: 1 / 2,
        width: 1 / 2,
      },
    ];
  }
  if (west) {
    return [
      {
        texture: "exitSideTopN.png",
        x: 0,
        y: minY + 1 / 2,
        height: 1 / 2,
        width: 1 / 2,
      },
      {
        texture: "exitSideTopS.png",
        x: 0,
        y: minY + 12 / 4,
        height: 1 / 2,
        width: 1 / 2,
      },
    ];
  }
  if (east) {
    return [
      {
        texture: "exitSideTopN.png",
        x: board.width + 1 / 2,
        y: minY + 1 / 2,
        height: 1 / 2,
        width: 1 / 2,
      },
      {
        texture: "exitSideTopS.png",
        x: board.width + 1 / 2,
        y: minY + 12 / 4,
        height: 1 / 2,
        width: 1 / 2,
      },
    ];
  }
  return [] as TilingSpriteProps[];
};

export const useEntryZone = (board: Board, exit: boolean) => {
  const bottoms = useMemo<TilingSpriteProps[]>(
    () => entryZoneBottomTilingProps(board, exit),
    [board, exit]
  );

  const tops: TilingSpriteProps[] = useMemo(
    () => entryZoneTopTilingProps(board, exit),
    [board, exit]
  );

  return [tops, bottoms];
};
