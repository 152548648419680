import { Round } from "../shared/Round";
import { Solution } from "../shared/Solution";
import { lt } from "./lt";

export const roundHash = function (
  round: Round,
  playerId: number,
  solution?: Solution
) {
  const string =
    JSON.stringify(round) +
    (solution ? JSON.stringify(solution) : "") +
    playerId;
  var hash = 0,
    i,
    chr;
  if (string.length === 0) return "" + hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return "" + hash;
};

const hashKeyPrefix = "puzzleHash";

const imageKeyPrefix = "puzzleImage";

const hashKey = (period: number) => hashKeyPrefix + period;
const imageKey = (period: number) => imageKeyPrefix + period;

const puzzleImageCache = new Map<string, string>()

export const getImage = (
  period: number,
  playerId: number,
  round: Round,
  solution?: Solution
) => {
  const currentHash = puzzleImageCache.get(hashKey(period));
  if (currentHash === null) return null;
  const hash = roundHash(round, playerId, solution);
  if (currentHash === hash) {
    return puzzleImageCache.get(imageKey(period));
  }
  return null;
};

export const storeImage = (
  period: number,
  playerId: number,
  round: Round,
  image: string,
  solution?: Solution
) => {
  const hash = roundHash(round, playerId, solution);
  puzzleImageCache.set(hashKey(period), hash);
  puzzleImageCache.set(imageKey(period), image);
};

export const clearPuzzleImageCacheOld = () => {
  let index = 0;
  let key = localStorage.key(index);
  let keysToRemove: string[] = [];
  while (key !== null) {
    if (key.startsWith(hashKeyPrefix) || key.startsWith(imageKeyPrefix)) {
      keysToRemove = [key, ...keysToRemove];
    }
    index += 1;
    key = localStorage.key(index);
  }
  keysToRemove.forEach((key) => localStorage.removeItem(key));
};

export const clearPuzzleImageCache = () => {
  puzzleImageCache.clear()
};

const cacheVersionKey = "puzzleCacheVersion";
const cacheVersion = 3;
const currentVersion = lt(localStorage.getItem(cacheVersionKey), (version) =>
  version ? +version : -1
);

if (currentVersion !== cacheVersion) {
  clearPuzzleImageCacheOld();
  localStorage.setItem(cacheVersionKey, "" + cacheVersion);
  console.log("cleared puzzle image cache");
}
