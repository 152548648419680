import {
  Avatar,
  Badge,
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { coordString, distance } from "../../shared/Coord";
import { Path } from "../../shared/Path";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import FrostIndicator from "../../resources/frostIndicator.png";
import { formatScore } from "../../utils/formatScore";
import { lt } from "../../utils/lt";

interface PathStatsProps {
  path: Path;
}

type FrostLength = {
  label: number;
  hit: number;
  start: number;
  end: number;
};

type BlockEntry = {
  label: number;
  hits: number;
};

type BlockTable = { [key in string]: BlockEntry };

const perfectFilter = "drop-shadow( 0px 0px 3px rgba(255, 245, 203, 0.7))";

const roundedPathScore = (result: number, frostLengths: FrostLength[]) => {
  const totalRoundedFrost = frostLengths.reduce(
    (acc, frostLength) =>
      (acc += +formatScore(frostLength.end - frostLength.start)),
    0
  );
  const totalRounded = +formatScore(result);
  return totalRounded - totalRoundedFrost;
};

export const PathStats = (props: PathStatsProps) => {
  const { path } = props;

  const frostLengths: FrostLength[] = useMemo(() => {
    let lastLabel = 0;
    const resultWithoutFrost = path.sections.reduce(
      ({ result, lastSection }, section) => ({
        result: result + distance(lastSection.destination, section.destination),
        lastSection: section,
      }),
      { result: 0, lastSection: path.sections[0] }
    ).result;
    return path.sections.reduce(
      ({ lengths, lastSection, runningTotal, blockTable }, section) => {
        let frostLength: FrostLength | undefined = undefined;
        let dist = distance(lastSection.destination, section.destination);
        if (section.clapCoord) {
          let coordKey = coordString("", section.clapCoord);
          let hit = 1;
          let label = 0;
          let entry = blockTable[coordKey];
          if (entry) {
            label = entry.label;
            hit = entry.hits + 1;
            blockTable[coordKey] = { label, hits: hit };
          } else {
            label = lastLabel;
            entry = { label, hits: hit };
            blockTable[coordKey] = entry;
            lastLabel += 1;
          }

          frostLength = {
            label,
            hit,
            start: runningTotal + dist,
            end: Math.min(runningTotal + dist + 15.0, resultWithoutFrost),
          };

          if (lengths.length > 0) {
            let lastLength = lengths[lengths.length - 1];
            if (lastLength.end > frostLength.start) {
              lengths[lengths.length - 1].end = frostLength.start;
            }
          }
        }

        return {
          lengths:
            frostLength === undefined ? lengths : [...lengths, frostLength],
          lastSection: section,
          runningTotal: runningTotal + dist,
          blockTable,
        };
      },
      {
        lengths: [] as FrostLength[],
        lastSection: path.sections[0],
        runningTotal: 0,
        blockTable: {} as BlockTable,
      }
    ).lengths;
  }, [path]);

  return (
    <Table size="small">
      <TableRow>
        <TableCell>Path</TableCell>
        <TableCell></TableCell>
        <TableCell align="right">
          {roundedPathScore(path.result, frostLengths)}
        </TableCell>
      </TableRow>
      {frostLengths.map((frostLength) =>
        lt(frostLength.end - frostLength.start, (frostTotal) => (
          <TableRow>
            <TableCell>
              <Badge
                badgeContent={"ABCDEF"[frostLength.label]}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                sx={{ top: -2 }}
              >
                <Badge
                  badgeContent={frostLength.hit}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Avatar
                    sx={{ width: 24, height: 24 }}
                    variant="rounded"
                    src={FrostIndicator}
                  />
                </Badge>
              </Badge>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  mt: 0.23,
                  color: frostTotal > 14.999 ? "#ffe346" : undefined,
                  WebkitFilter: frostTotal > 14.999 ? perfectFilter : undefined,
                  filter: frostTotal > 14.999 ? perfectFilter : undefined,
                  whiteSpace: "nowrap",
                }}
              >
                {frostTotal > 0 ? (
                  frostTotal > 3 ? (
                    <StarIcon fontSize="small" />
                  ) : (
                    <StarHalfIcon fontSize="small" />
                  )
                ) : (
                  <StarBorderIcon fontSize="small" />
                )}
                {frostTotal > 4.5 ? (
                  frostTotal > 6 ? (
                    <StarIcon fontSize="small" />
                  ) : (
                    <StarHalfIcon fontSize="small" />
                  )
                ) : (
                  <StarBorderIcon fontSize="small" />
                )}
                {frostTotal > 7.5 ? (
                  frostTotal > 9 ? (
                    <StarIcon fontSize="small" />
                  ) : (
                    <StarHalfIcon fontSize="small" />
                  )
                ) : (
                  <StarBorderIcon fontSize="small" />
                )}
                {frostTotal > 10.5 ? (
                  frostTotal > 12 ? (
                    <StarIcon fontSize="small" />
                  ) : (
                    <StarHalfIcon fontSize="small" />
                  )
                ) : (
                  <StarBorderIcon fontSize="small" />
                )}
                {frostTotal > 13.5 ? (
                  frostTotal > 14.999 ? (
                    <StarIcon fontSize="small" />
                  ) : (
                    <StarHalfIcon fontSize="small" />
                  )
                ) : (
                  <StarBorderIcon fontSize="small" />
                )}
              </Box>
            </TableCell>
            <TableCell align="right" sx={{ color: "#82c6e3" }}>
              {formatScore(frostTotal)}
            </TableCell>
          </TableRow>
        ))
      )}
      <TableRow>
        <TableCell sx={{ borderBottom: 0 }}>Total</TableCell>
        <TableCell sx={{ borderBottom: 0 }}></TableCell>
        <TableCell sx={{ borderBottom: 0 }} align="right">
          {roundedPathScore(path.result, frostLengths)}
          {frostLengths.length > 0 && (
            <Typography variant="body2" color="#82c6e3">
              +{" "}
              {Math.round(
                path.result * 1000 - roundedPathScore(path.result, frostLengths)
              )}{" "}
            </Typography>
          )}
          <u>{formatScore(path.result)}</u>
        </TableCell>
      </TableRow>
    </Table>
  );
};
