import {
  Pattern,
  Rarity,
  UniqueBlockPattern,
  UniquePattern,
} from "./Cosmetics";
import { SvgIconProps } from "@mui/material";
import { RecycleIcon } from "../resources/icons/Recycle";
import MediationIcon from "@mui/icons-material/Mediation";
import CompareIcon from "@mui/icons-material/Compare";
import CasinoIcon from "@mui/icons-material/Casino";
import { Prices } from "../pages/elements/BountyRewardElement";
import { BlockPatternElement } from "../pages/elements/BlockPatternElement";
import { lt } from "../utils/lt";

export type PatternCraftProps = {
  firstColorId: number;
  secondColorId: number;
  patternId: number;
};

export function isPatternCraftProps(value: any): value is PatternCraftProps {
  if (typeof value === "object" && value !== null) {
    const { firstColorId, secondColorId, patternId } = value;
    if (
      typeof firstColorId === "number" &&
      typeof secondColorId === "number" &&
      typeof patternId === "number"
    ) {
      return true;
    }
  }
  return false;
}

export type ToolProps = {
  toolId: number;
  blockPatternIds: number[];
};

export function isToolProps(value: any): value is ToolProps {
  if (typeof value === "object" && value !== null) {
    const { toolId, blockPatternIds } = value;
    if (
      typeof toolId === "number" &&
      Array.isArray(blockPatternIds) &&
      blockPatternIds.every((id) => typeof id === "number")
    ) {
      return true;
    }
  }
  return false;
}

export type ToolResult = {
  blockPatterns: UniqueBlockPattern[];
  patterns: UniquePattern[];
};

export function isToolResult(value: any): value is ToolResult {
  if (typeof value === "object" && value !== null) {
    const { blockPatterns, patterns } = value;
    if (Array.isArray(blockPatterns) && Array.isArray(patterns)) {
      return true;
    }
  }
  return false;
}

export type ToolBelt = {
  tools: UniqueTool[];
  patterns: UniquePattern[];
};

export function isToolBelt(value: any): value is ToolBelt {
  if (typeof value === "object" && value !== null) {
    const { tools, patterns } = value;
    if (
      Array.isArray(tools) &&
      tools.every(isUniqueTool) &&
      Array.isArray(patterns)
    ) {
      return true;
    }
  }
  return false;
}

export enum CosmeticTool {
  RECYCLE = "RECYCLE",
  FLIP = "FLIP",
  COMBINE = "COMBINE",
  REROLL = "REROLL",
}

export interface ToolInfo {
  validateInput: (blockPatterns: UniqueBlockPattern[]) => boolean;
  inputFilter: (blockPattern: UniqueBlockPattern) => boolean;
  nextInputFilter?: (
    current: UniqueBlockPattern[],
    blockPattern: UniqueBlockPattern
  ) => boolean;
  name: string;
  description: string;
  inputs: number;
  icon: (props: SvgIconProps) => JSX.Element;
  output: (current: UniqueBlockPattern[]) => JSX.Element;
}

export const toolInfo: { [key in CosmeticTool]: ToolInfo } = {
  RECYCLE: {
    validateInput: (blockPatterns) =>
      blockPatterns.length === 1 &&
      blockPatterns[0].blockPattern.pattern !== Pattern.SOLID,
    inputFilter: (blockPattern) =>
      blockPattern.blockPattern.pattern !== Pattern.SOLID,
    inputs: 1,
    name: "Recycle",
    description:
      "Destroy a patterned block and get the pattern and the block colours.",
    icon: (props) => <RecycleIcon {...props} />,
    output: (current) => (
      <Prices
        patterns={[{ id: 0, pattern: current[0].blockPattern.pattern }]}
        blockPatterns={[
          {
            blockPattern: {
              firstColor: { ...current[0].blockPattern.firstColor },
              pattern: Pattern.SOLID,
            },
            id: 0,
          },
          {
            blockPattern: {
              firstColor: { ...current[0].blockPattern.secondColor!! },
              pattern: Pattern.SOLID,
            },
            id: 0,
          },
        ]}
      />
    ),
  },
  FLIP: {
    validateInput: (blockPatterns) =>
      blockPatterns.length === 1 &&
      blockPatterns[0].blockPattern.pattern !== Pattern.SOLID,
    inputFilter: (blockPattern) =>
      blockPattern.blockPattern.pattern !== Pattern.SOLID,
    name: "Flip",
    inputs: 1,
    description: "Flips the colours of a patterned block.",
    icon: (props) => <CompareIcon {...props} />,
    output: (current) => (
      <BlockPatternElement
        blockPattern={{
          ...current[0].blockPattern,
          firstColor: current[0].blockPattern.secondColor!!,
          secondColor: current[0].blockPattern.firstColor!!,
        }}
        inspectable
      />
    ),
  },
  COMBINE: {
    validateInput: (blockPatterns) =>
      blockPatterns.length === 3 &&
      blockPatterns.every(
        (it) =>
          it.blockPattern.pattern === Pattern.SOLID &&
          it.blockPattern.firstColor.rarity ===
            blockPatterns[0].blockPattern.firstColor.rarity
      ),
    nextInputFilter: (current, blockPattern) =>
      current.length === 0 ||
      blockPattern.blockPattern.firstColor.rarity ===
        current[0].blockPattern.firstColor.rarity,
    inputs: 3,
    inputFilter: (blockPattern) =>
      blockPattern.blockPattern.pattern === Pattern.SOLID &&
      blockPattern.blockPattern.firstColor.rarity !== Rarity.LEGENDARY,
    name: "Combine",
    description:
      "Takes 3 non-patterned blocks of the same rarity, and gives a random block of the rarity above",
    icon: (props) => <MediationIcon {...props} />,
    output: (current) => (
      <BlockPatternElement
        blockPattern={lt(
          current[0].blockPattern.firstColor.rarity,
          (rarity) => ({
            pattern: Pattern.SOLID,
            firstColor: {
              indentTint: 0,
              color: { name: "?", tint: 0x111111 },
              rarity:
                rarity === Rarity.COMMON
                  ? Rarity.UNCOMMON
                  : rarity === Rarity.UNCOMMON
                  ? Rarity.RARE
                  : Rarity.LEGENDARY,
            },
          })
        )}
      />
    ),
  },
  REROLL: {
    validateInput: (blockPatterns) =>
      blockPatterns.length === 1 &&
      blockPatterns[0].blockPattern.pattern === Pattern.SOLID,
    inputFilter: (blockPattern) =>
      blockPattern.blockPattern.pattern === Pattern.SOLID,
    name: "Reroll",
    inputs: 1,
    description:
      "Takes a non-patterned block and gives back a random block of the same rarity",
    icon: (props) => <CasinoIcon {...props} />,
    output: (current) => (
      <BlockPatternElement
        blockPattern={lt(
          current[0].blockPattern.firstColor.rarity,
          (rarity) => ({
            pattern: Pattern.SOLID,
            firstColor: {
              indentTint: 0,
              color: { name: "?", tint: 0x111111 },
              rarity: rarity,
            },
          })
        )}
      />
    ),
  },
};

export function isCosmeticTool(value: any): value is CosmeticTool {
  return (
    value === "RECYCLE" ||
    value === "FLIP" ||
    value === "COMBINE" ||
    value === "REROLL"
  );
}

export type UniqueTool = {
  id: number;
  tool: CosmeticTool;
};

export function isUniqueTool(value: any): value is UniqueTool {
  if (typeof value === "object" && value !== null) {
    const { id, tool } = value;
    if (typeof id === "number" && isCosmeticTool(tool)) {
      return true;
    }
  }
  return false;
}
