import { Avatar, Button, Grid, Stack, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  BlockColor,
  Inventory,
  UniqueBlockPattern,
} from "../../shared/Cosmetics";
import { BlockPatternElement } from "./BlockPatternElement";
import { cst } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { HuePicker } from "react-color";
import {
  hexToRGB,
  rgbDist,
  tintToRGB,
} from "../../utils/colors";

interface InputPickerProps {
  inventory: Inventory;
  current: UniqueBlockPattern[];
  inputFilter: (blockPattern: UniqueBlockPattern) => boolean;
  nextInputFilter?: (
    current: UniqueBlockPattern[],
    blockPattern: UniqueBlockPattern
  ) => boolean;
  picked?: UniqueBlockPattern;
  locked: boolean;
  pick: (blockPattern?: UniqueBlockPattern) => void;
}

export const blockHueDist = (color: string, blockColor: BlockColor) => {
  return rgbDist(tintToRGB(blockColor.color.tint), hexToRGB(color));
};

export const hueDist = (color: string, blockPattern: UniqueBlockPattern) => {
  return blockPattern.blockPattern.secondColor
    ? Math.min(
        blockHueDist(color, blockPattern.blockPattern.firstColor),
        blockHueDist(color, blockPattern.blockPattern.secondColor)
      )
    : blockHueDist(color, blockPattern.blockPattern.firstColor);
};

export const InputPicker = (props: InputPickerProps) => {
  const {
    inventory,
    current,
    inputFilter,
    nextInputFilter = () => true,
    pick,
    picked,
    locked,
  } = props;

  const available = useMemo(
    () =>
      inventory.blockPatterns.filter(
        (it) =>
          inputFilter(it) &&
          nextInputFilter(current, it) &&
          current.every((that) => that.id !== it.id) &&
          it.blockPattern.firstColor.color.name !== "Default" &&
          inventory.activeBlockPatternId !== it.id
      ),
    [current, inputFilter, nextInputFilter, inventory]
  );

  const [open, setOpen] = useState<boolean>(false);

  const [sortColor, setSortColor] = useState<string>();
  const sorted = useMemo(
    () =>
      sortColor === undefined
        ? available
        : available.sort(
            (a, b) => hueDist(sortColor, a) - hueDist(sortColor, b)
          ),
    [available, sortColor]
  );

  return (
    <Tooltip
      disableHoverListener
      disableFocusListener
      disableTouchListener
      open={open}
      title={
        <Grid container maxHeight={600} overflow="auto">
          {available.length > 20 && (
            <Grid item xs={12}>
              <HuePicker
                width={"100%"}
                color={sortColor}
                onChangeComplete={(a) => setSortColor(a.hex)}
              />
            </Grid>
          )}
          {sorted.map((blockPattern) => (
            <Grid
              item
              xs={available.length === 1 ? 12 : available.length === 2 ? 6 : 4}
              sm={
                available.length === 1
                  ? 12
                  : available.length === 2
                  ? 6
                  : available.length === 3
                  ? 4
                  : 3
              }
              sx={{ p: 2 }}
            >
              <Button
                sx={{ textTransform: "none", color: "white" }}
                onClick={() => {
                  setOpen(false);
                  pick(blockPattern);
                }}
                fullWidth
              >
                <BlockPatternElement blockPattern={blockPattern.blockPattern} />
              </Button>
            </Grid>
          ))}
        </Grid>
      }
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "sm",
            p: 0,
            m: 0,
          },
        },
      }}
    >
      <Stack>
        <Button
          onClick={() => {
            if (picked) {
              pick();
            } else {
              setOpen((o) => !o);
            }
          }}
          disabled={locked}
          sx={{ textTransform: "none", color: "white" }}
        >
          {picked ? (
            <BlockPatternElement blockPattern={picked.blockPattern} />
          ) : (
            <Avatar
              variant="rounded"
              sx={{
                width: cst.tileSize,
                height: cst.tileSize,
              }}
            >
              <AddIcon fontSize="large" />
            </Avatar>
          )}
          <Grid
            container
            justifyContent={"center"}
            height={1}
            width={1}
            position="absolute"
          >
            {locked && (
              <LockIcon fontSize="large" sx={{ height: cst.tileSize + 8 }} />
            )}
          </Grid>
        </Button>
      </Stack>
    </Tooltip>
  );
};
