export const secondsToHHMMSS = (secondsArg: number, precedingMinuteZero?: boolean) => {
  if (secondsArg < 0) return "";
  let rounded = Math.round(secondsArg);
  var hours = Math.floor(rounded / 3600);
  var minutes = Math.floor(rounded / 60) % 60;
  var seconds = rounded % 60;

  var stringBuilder = "";

  if (hours) {
    stringBuilder += hours + ":";
    stringBuilder += minutes < 10 ? "0" + minutes : minutes;
    stringBuilder += ":";
    stringBuilder += seconds < 10 ? "0" + seconds : seconds;
  } else if (minutes) {
    stringBuilder += minutes + ":";
    stringBuilder += seconds < 10 ? "0" + seconds : seconds;
  } else if (seconds) {
    stringBuilder += precedingMinuteZero ? "0:" + seconds : seconds;
  }

  return "" + stringBuilder;
};
