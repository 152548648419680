import React from "react";
import { Container, Sprite, Text } from "@inlet/react-pixi";
import { scoreTextStyle } from "./textStyle";
import { Textures } from "../sprites/texture";

interface ScoreCounterProps {
  score: number;
  x: number;
  y: number;
  height: number;
  tileSize: number;
  textures: Textures;
}

export const ScoreCounter = (props: ScoreCounterProps) => {
  const { tileSize, score, x, y, height, textures } = props;
  return (
    <Container x={x} y={y}>
        <Sprite
          texture={textures["sideTop1WN.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 5) / 16}
          x={(tileSize * 1) / 32}
          y={(tileSize * 1.5) / 16}
        />
        <Sprite
          texture={textures["sideTop1EN.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 63) / 32}
          y={(tileSize * 1) / 8}
        />
        <Sprite
          texture={textures["sideTop4N.png"]}
          width={(tileSize * 7) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 1) / 4}
          y={(tileSize * -1) / 8}
        />
        <Sprite
          texture={textures["sideTop4S.png"]}
          width={(tileSize * 7) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 1) / 4}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopNW.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 1) / 32}
          y={(tileSize * -4) / 32}
        />
        <Sprite
          texture={textures["outerCornerTopSW.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 1) / 32}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopNE.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 63) / 32}
          y={(tileSize * -1) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopSE.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          x={(tileSize * 63) / 32}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["topGap.png"]}
          width={(tileSize * 6) / 4}
          height={(tileSize * 5) / 16}
          x={(tileSize * 11) / 32}
          y={(tileSize * 1.5) / 16}
        />
      {score > 0 && (
        <Text
          anchor={{ x: 1, y: 0.62 }}
          x={(tileSize * 16.3) / 8}
          y={height / 2}
          alpha={0.7}
          text={("" + (score * 1000).toFixed(0)).replaceAll("0", "O")}
          style={scoreTextStyle(tileSize * 1.4)}
        />
      )}
    </Container>
  );
};
