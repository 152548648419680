import React, { useEffect, useRef } from "react";
import * as PIXI from "pixi.js";
import { Container, _ReactPixi } from "@inlet/react-pixi";

export const MaskedContainer: React.FC<_ReactPixi.IContainer> = (props) => {
  const containerRef = useRef<PIXI.Container>(null);

  const currentMaskRef = useRef<PIXI.Container>();

  useEffect(() => {
    if (
      props.mask &&
      props.mask instanceof PIXI.Container &&
      containerRef.current
    ) {
      if (currentMaskRef.current) {
        containerRef.current.removeChild(currentMaskRef.current);
      }
      containerRef.current.addChild(props.mask);
      currentMaskRef.current = props.mask;
    } else if (currentMaskRef.current && !props.mask && containerRef.current) {
      containerRef.current?.removeChild(currentMaskRef.current);
    }
  }, [props.mask]);

  return (
    <Container ref={containerRef} {...props}>
      {props.children}
    </Container>
  );
};
