import { Application } from "@pixi/app";
import { InteractionEvent } from "@pixi/interaction";
import { useCallback, useRef, useState } from "react";
import { Board } from "../../shared/Board";
import { Coord } from "../../shared/Coord";
import { Tower } from "../../shared/Tower";
import { cst } from "../../utils/constants";
import { CursorType } from "../Cursor";
import { Textures } from "../sprites/texture";
import { blockStateWindow } from "./useBlocks";
import { Counter } from "./useCounter";
import { windowToBlockInfo } from "./windowToBlockInfo";

type CursorState = {
  type: CursorType;
  coord: Coord;
  withinBounds: boolean;
  selected?: Tower;
};
const isOn = (a: Coord, b: Coord) => a.x === b.x && a.y === b.y;

const overlaps = (a: Coord, b: Coord) =>
  (a.x === b.x || a.x === b.x - 1 || a.x === b.x + 1) &&
  (a.y === b.y || a.y === b.y - 1 || a.y === b.y + 1);

const overlapsWaypoint = (block: Coord, waypoint?: Coord) =>
  waypoint &&
  (block.x === waypoint.x || block.x === waypoint.x - 1) &&
  (block.y === waypoint.y || block.y === waypoint.y - 1);

interface BoardInteractionProps {
  board: Board;
  towers: Tower[];
  drawMode: boolean;
  towerCounter: Counter;
  clapCounter: Counter;
  canSetTower: (coord: Coord) => boolean;
  setTowers: React.Dispatch<React.SetStateAction<Tower[]>>;
  drawingStatic: boolean;
  actions: number;
  setActions: React.Dispatch<React.SetStateAction<number>>;
  removeTowerInRepresentation: (coord: Coord) => void;
  interactive: boolean;
  textures: Textures;
  tileSize: number;
  tutorialStep?: number;
  app: Application;
}

export const useBoardInteraction = (props: BoardInteractionProps) => {
  const {
    towers,
    board,
    drawMode,
    towerCounter,
    clapCounter,
    canSetTower,
    setTowers,
    drawingStatic,
    actions,
    setActions,
    tutorialStep,
    removeTowerInRepresentation,
    tileSize,
    app,
  } = props;

  const [cursorState, setCursorState] = useState<CursorState>({
    type: CursorType.None,
    coord: { x: -1, y: -1 },
    withinBounds: false,
  });

  const altInteract = useCallback(
    (coord: Coord) => {
      const allTowers = [...towers, ...board.staticTowers];
      const index = allTowers.findIndex((tower) =>
        overlaps(tower.coord, coord)
      );
      if (index === -1) {
        if (
          drawMode ||
          (towerCounter.isPositive &&
            clapCounter.isPositive &&
            canSetTower(coord))
        ) {
          let tower = {
            coord: { x: coord.x, y: coord.y },
            clap: true,
            static: drawingStatic,
          };
          setTowers([...towers, tower]);

          clapCounter.decrement();
          towerCounter.decrement();
          setCursorState((s) => ({
            ...s,
            type: CursorType.Build,
            selected: tower,
          }));
        } else {
          setCursorState((s) => ({ ...s, type: CursorType.BuildError }));
        }
      } else if (index < towers.length) {
        const tower = towers[index];
        if (isOn(tower.coord, coord)) {
          if (tower.clap) {
            towers[index] = { ...tower, clap: false };
            clapCounter.increment();
            setTowers([...towers]);
            setCursorState((s) => ({
              ...s,
              type: CursorType.Build,
              selected: towers[index],
            }));
          } else {
            if (clapCounter.isPositive) {
              towers[index] = { ...tower, clap: true };
              clapCounter.decrement();
              setTowers([...towers]);
              setCursorState((s) => ({
                ...s,
                type: CursorType.Build,
                selected: towers[index],
              }));
            } else {
              setCursorState((s) => ({ ...s, type: CursorType.BuildError }));
            }
          }
        }
      }
      setActions(actions + 1);
    },
    [
      drawMode,
      drawingStatic,
      towerCounter,
      clapCounter,
      canSetTower,
      actions,
      towers,
      board,
      setTowers,
      setActions,
    ]
  );

  const interact = useCallback(
    (coord: Coord) => {
      if (tutorialStep === 24 || tutorialStep === 23) return;
      const allTowers = [...towers, ...board.staticTowers];
      const index = allTowers.findIndex((tower) =>
        overlaps(tower.coord, coord)
      );
      if (!overlapsWaypoint(coord, board.waypoint)) {
        if (index === -1) {
          if (drawMode || (towerCounter.isPositive && canSetTower(coord))) {
            let tower = {
              coord: { x: coord.x, y: coord.y },
              clap: false,
              static: drawingStatic,
            };
            setTowers([...towers, tower]);
            towerCounter.decrement();
            setCursorState((s) => ({
              ...s,
              type: CursorType.Build,
              selected: tower,
            }));
          } else {
            setCursorState((s) => ({ ...s, type: CursorType.BuildError }));
          }
        } else if (index < towers.length) {
          const tower = towers[index];
          if (isOn(tower.coord, coord)) {
            towers.splice(index, 1);
            towerCounter.increment();
            if (tower.clap) {
              clapCounter.increment();
            }
            removeTowerInRepresentation(coord);
            setTowers([...towers]);
            setCursorState((s) => ({
              ...s,
              type: CursorType.Build,
              selected: undefined,
            }));
          }
        }
      }
      setActions(actions + 1);
    },
    [
      towerCounter,
      clapCounter,
      canSetTower,
      drawMode,
      drawingStatic,
      removeTowerInRepresentation,
      actions,
      towers,
      board,
      tutorialStep,
      setTowers,
      setActions,
    ]
  );

  const halfTileCoord = useCallback(
    (event: InteractionEvent) => {
      const local = event.data.getLocalPosition(
        app.stage.children[0],
        event.data.global
      );
      return {
        x: Math.max(
          Math.min(
            Math.floor((2 * local.x) / tileSize - 1),
            board.width * 2 - 2
          ),
          1
        ),
        y: Math.max(
          Math.min(
            Math.floor((2 * local.y) / tileSize - 2),
            board.height * 2 - 2
          ),
          1
        ),
      };
    },
    [app, tileSize, board]
  );

  const lastHalfCoord = useRef({ x: -1, y: -1 });
  const lastCoord = useRef({ x: -1, y: -1 });

  const touchTime = useRef(-1);
  const pointermove = useCallback(
    (event: InteractionEvent, force?: boolean) => {
      if (event.target === null && !force) {
        setCursorState((s) =>
          s.withinBounds ? { ...s, withinBounds: false } : s
        );
        return;
      }
      const withinBounds = true;

      let halfCoord = halfTileCoord(event);
      if (
        !force &&
        lastHalfCoord.current.x === halfCoord.x &&
        lastHalfCoord.current.y === halfCoord.y
      ) {
        return;
      } else {
        lastHalfCoord.current = halfCoord;
      }

      let coord = {
        x: Math.floor((halfCoord.x - 1) / 2),
        y: Math.floor((halfCoord.y - 1) / 2),
      };

      if (coord.x !== lastCoord.current.x || coord.y !== lastCoord.current.y) {
        touchTime.current = Date.now();
        lastCoord.current = coord;
      }

      const halfX = halfCoord.x % 2 === 1;
      const halfY = halfCoord.y % 2 === 1;
      const blockWindow = blockStateWindow(board, towers, coord.x, coord.y);

      const blockInfo = windowToBlockInfo(blockWindow, halfX, halfY);

      coord.x += blockInfo.xOffset;
      coord.y += blockInfo.yOffset;

      let type =
        (blockInfo.blockType === "empty" || blockInfo.blockType === "player") &&
        !overlapsWaypoint(coord, board.waypoint)
          ? CursorType.Build
          : CursorType.None;

      const selected =
        blockInfo.blockType === "player"
          ? towers.find((t) => t.coord.x === coord.x && t.coord.y === coord.y)
          : blockInfo.blockType === "static"
          ? board.staticTowers.find(
              (t) => t.coord.x === coord.x && t.coord.y === coord.y
            )
          : undefined;

      const cursorState = { type, coord, withinBounds, selected };

      setCursorState((s) =>
        s.coord.x === coord.x &&
        s.coord.y === coord.y &&
        s.selected === selected &&
        s.withinBounds &&
        s.type === type
          ? s
          : cursorState
      );

      return cursorState;
    },
    [setCursorState, halfTileCoord, towers, board]
  );

  const mousedown = useCallback(
    (cursorState: CursorState) => {
      if (cursorState.selected?.static === false) {
        setCursorState((s) => ({ ...s, type: CursorType.Remove }));
      }
    },
    [setCursorState]
  );

  const rightdown = useCallback(
    (cursorState: CursorState) => {
      if (cursorState.selected?.static === false) {
        if (cursorState.selected.clap) {
          setCursorState((s) => ({ ...s, type: CursorType.Downgrade }));
        } else {
          setCursorState((s) => ({ ...s, type: CursorType.Upgrade }));
        }
      }
    },
    [setCursorState]
  );

  const mouseup = useCallback(
    (cursorState) => {
      interact(cursorState.coord);
    },
    [interact]
  );

  const rightup = useCallback(
    (cursorState) => {
      altInteract(cursorState.coord);
    },
    [altInteract]
  );

  const touchstart = useCallback(
    (event: InteractionEvent) => {
      const cursorState = pointermove(event, true);
      let timestamp = Date.now();
      touchTime.current = timestamp;

      window.setTimeout(() => {
        if (touchTime.current === timestamp)
          setCursorState((s) => ({
            ...s,
            type: cursorState?.selected
              ? cursorState.selected.clap
                ? CursorType.Downgrade
                : CursorType.Upgrade
              : CursorType.BuildClap,
          }));
      }, cst.longTapDuration);
    },
    [touchTime, pointermove]
  );

  const touchend = useCallback(
    (cursorState) => {
      const diff = Date.now() - touchTime.current;
      let interacted = false;
      if (diff < cst.longTapDuration) {
        interacted = true;
        interact(cursorState.coord);
      } else if (diff < cst.longTapTooLong) {
        interacted = true;
        altInteract(cursorState.coord);
      } else {
        setCursorState((s) => ({
          ...s,
          type: CursorType.None,
          selected: undefined,
        }));
      }
      if (interacted) {
        touchTime.current = -1;
        window.setTimeout(() => {
          if (touchTime.current === -1)
            setCursorState((s) => ({
              ...s,
              type: CursorType.None,
              selected: undefined,
            }));
        }, 300);
      }
    },
    [touchTime, setCursorState, interact, altInteract]
  );

  const containerInteraction = useCallback(
    (cs: CursorState) => ({
        pointermove,
        mousedown: () => mousedown(cs),
        rightdown: () => rightdown(cs),
        mouseup: () => mouseup(cs),
        rightup: () => rightup(cs),
        touchend: () => touchend(cs),
        touchstart,
      }),
    [
      pointermove,
      mousedown,
      rightdown,
      mouseup,
      rightup,
      touchend,
      touchstart,
    ]
  );

  return { cursorState, containerInteraction };
};
