import { Renderer, RenderTexture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";
import { useMemo } from "react";
import { Pattern } from "../../shared/Cosmetics";
import solid from "../../resources/pattern_solid.png";
import verticalGradient from "../../resources/pattern_vertical_gradient.png";
import radialGradient from "../../resources/pattern_radial_gradient.png";
import harlequin from "../../resources/pattern_harlequin.png";
import bubbles from "../../resources/pattern_bubbles.png";
import { cst } from "../../utils/constants";
import { GradientFactory } from "./GradientFactory";
import { Board } from "../../shared/Board";
import { generateHook } from "../../pages/ToggleTest";
import { Rectangle } from "@pixi/math";
import { SmoothGraphics } from "@pixi/graphics-smooth";
import { useSpriteCleanup } from "./useSpriteCleanup";
import { Container } from "@pixi/display";
import { utils } from "pixi.js";
import { dateInteger, mulberry32 } from "./useStaticPattern";

export const singleBlockPatternImages: { [key in Pattern]: Sprite } = {
  SOLID: Sprite.from(solid),
  HARLEQUIN: Sprite.from(harlequin),
  BUBBLES: Sprite.from(bubbles),
  VERTICAL_GRADIENT: Sprite.from(verticalGradient),
  RADIAL_GRADIENT: Sprite.from(radialGradient),
};

export const patterns: {
  [key in Pattern]: (
    tileSize: number,
    width: number,
    height: number,
    renderer: Renderer
  ) => Sprite;
} = {
  SOLID: () => {
    return new Sprite();
  },
  VERTICAL_GRADIENT: (tileSize, width, height, renderer) => {
    let overlay = new Sprite();
    let renderTexture = RenderTexture.create({
      width: (width * tileSize) / 2,
      height: (height * tileSize) / 2,
    });
    GradientFactory.createLinearGradient(renderer, renderTexture, {
      x0: 0,
      y0: 0,
      x1: 0,
      y1: (height * tileSize) / 2,
      colorStops: [
        { color: 0x000000, offset: 0 },
        { color: 0xffffff, offset: 1 },
      ],
    });
    overlay.texture = renderTexture;
    overlay.scale.x = 2;
    overlay.scale.y = 2;
    return overlay;
  },
  RADIAL_GRADIENT: (tileSize, width, height, renderer) => {
    let overlay = new Sprite();
    let renderTexture = RenderTexture.create({
      width: (width * tileSize) / 2,
      height: (height * tileSize) / 2,
    });
    let centerX = (width * tileSize) / 4;
    let centerY = (height * tileSize) / 4;
    GradientFactory.createRadialGradient(renderer, renderTexture, {
      x0: centerX,
      y0: centerY,
      r0: 0,
      x1: centerX,
      y1: centerY,
      r1: Math.sqrt(centerY * centerY + centerX * centerX),
      colorStops: [
        { color: 0x000000, offset: 0 },
        { color: 0xffffff, offset: 1 },
      ],
    });
    overlay.texture = renderTexture;
    overlay.scale.x = 2;
    overlay.scale.y = 2;
    return overlay;
  },
  HARLEQUIN: (tileSize, width, height, renderer) => {
    const container = new Container();
    let scale = 2 / 3;
    let t = tileSize / scale;
    let m = (width + 2) * scale;
    let n = (height + 5) * scale;
    const graphics = new SmoothGraphics();

    let shifter = 0;
    let mod = 7;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        graphics.beginFill(
          utils.rgb2hex([
            (shifter % mod) / (mod - 1),
            (shifter % mod) / (mod - 1),
            (shifter % mod) / (mod - 1),
          ]),
          1,
          true
        );

        shifter += 1;

        const x = j * t + (i % 2 === 0 ? 0 : t / 2);
        const y = i * t * (Math.sqrt(3) / 2);
        graphics.drawPolygon([
          x,
          y,
          x + t / 2,
          y - t * (Math.sqrt(3) / 2),
          x + t,
          y,
        ]);

        graphics.endFill();

        graphics.beginFill(
          utils.rgb2hex([
            (shifter % mod) / (mod - 1),
            (shifter % mod) / (mod - 1),
            (shifter % mod) / (mod - 1),
          ]),
          1,
          true
        );
        shifter += 1;
        graphics.drawPolygon([
          x + t / 2,
          y - t * (Math.sqrt(3) / 2),
          x + t,
          y,
          x + t * 2,
          y - t * (Math.sqrt(3) / 2),
        ]);
        graphics.endFill();
      }
    }
    container.addChild(graphics);

    let texture = generateHook(renderer, container, {
      region: new Rectangle(
        tileSize * 1.5,
        0,
        tileSize * width,
        tileSize * height
      ),
    });

    let sprite = new Sprite(texture);

    return sprite;
  },
  BUBBLES: (tileSize, width, height, renderer) => {
    const graphics = new SmoothGraphics();
    const random = mulberry32(dateInteger);
    graphics.beginFill(0, 1);
    graphics.drawRect(0, 0, (width + 2) * tileSize, (height + 2) * tileSize);
    graphics.endFill();

    let amount = ((width * height) / 288) * 1000;

    for (let i = 0; i < amount; i++) {
      const size = tileSize / 2 + random() * tileSize * 1.5;
      const x = random() * ((width + 2) * tileSize - size) + size / 2;
      const y = random() * ((height + 2) * tileSize - size) + size / 2;
      const alpha = random() * random();

      graphics.beginFill(0xffffff, alpha, true);
      graphics.drawCircle(x, y, size / 2);
      graphics.endFill();
    }

    let texture = generateHook(renderer, graphics, {
      region: new Rectangle(
        tileSize,
        tileSize,
        width * tileSize,
        height * tileSize
      ),
    });

    let sprite = new Sprite(texture);

    return sprite;
  },
};

export const generatePatternSprite = (
  tileSize: number,
  renderer: Renderer,
  pattern: Pattern,
  board: Board
) => {
  return patterns[pattern](tileSize, board.width, board.height, renderer);
};

export const usePattern = (
  type: Pattern,
  width: number,
  height: number,
  renderer: Renderer,
  enabled: boolean
) => {
  const sprite = useMemo(
    () => enabled && patterns[type](cst.tileSize, width, height, renderer),
    [type, width, height, renderer, enabled]
  );

  useSpriteCleanup(sprite);

  return sprite;
};
