import { Link, Typography } from "@mui/material";
import { NavigateFunction } from "react-router";
import { CopyInput } from "../pages/elements/CopyInput";
import { Points } from "../pages/elements/Emoji";
import { ArenaMode, ArenaStatus } from "./Arena";
import { Player, RoundSolution } from "./Player";
import { RoundResults, PlayerResult, PuzzleSponsors } from "./Puzzle";
import { Round } from "./Round";
import { ServeStatus } from "./Serve";
import { Solution } from "./Solution";

export type NamedColor = {
  name: string;
  tint: number;
};

export const defaultColor: NamedColor = { name: "Default", tint: 0x888888 };

export enum Rarity {
  COMMON = "COMMON",
  UNCOMMON = "UNCOMMON",
  RARE = "RARE",
  LEGENDARY = "LEGENDARY",
  SUPPORTER = "SUPPORTER",
}

type TwoToneGradient = {
  center: string;
  edge: string;
};

export const twoToneGradient: { [key in Rarity]: TwoToneGradient } = {
  COMMON: { center: "#868686", edge: "#565656" },
  UNCOMMON: { center: "#eaeaea", edge: "#aaaaaa" },
  RARE: { center: "#2b69e6", edge: "#512dfd" },
  LEGENDARY: { center: "#ec780e", edge: "#a62e1a" },
  SUPPORTER: { center: "#c72388", edge: "#8e1aa6" },
};

export const rarityColor = (rarity: Rarity) =>
  rarity === "COMMON"
    ? "radial-gradient(#868686, #565656)"
    : rarity === "UNCOMMON"
    ? "radial-gradient(#eaeaea, #aaaaaa)"
    : rarity === "RARE"
    ? "radial-gradient(#2b69e6, #512dfd)"
    : rarity === "LEGENDARY"
    ? "radial-gradient(#ec780e, #a62e1a)"
    : "radial-gradient(#c72388, #8e1aa6)";

export const rarityText: { [key in Rarity]: string } = {
  COMMON: "common",
  UNCOMMON: "uncommon",
  RARE: "Rare",
  LEGENDARY: "Legendary",
  SUPPORTER: "Supporter",
};

export const rarityOrdinal: { [key in Rarity]: number } = {
  COMMON: 0,
  UNCOMMON: 1,
  RARE: 2,
  LEGENDARY: 3,
  SUPPORTER: 4,
};

export const mixedRarityGradient = (first: Rarity, second?: Rarity) =>
  second
    ? first === second
      ? rarityColor(first)
      : rarityOrdinal[first] < rarityOrdinal[second]
      ? "radial-gradient(" +
        twoToneGradient[first].center +
        "," +
        twoToneGradient[second].edge +
        ")"
      : "radial-gradient(" +
        twoToneGradient[second].center +
        "," +
        twoToneGradient[first].edge +
        ")"
    : rarityColor(first);

export const patternRarityGradient = (pattern: BlockPattern) =>
  mixedRarityGradient(pattern.firstColor.rarity, pattern.secondColor?.rarity);

export const mixedRarityText = (first: Rarity, second?: Rarity) =>
  second
    ? rarityOrdinal[first] > rarityOrdinal[second]
      ? rarityText[first] + " × " + rarityText[second]
      : rarityText[second] + " × " + rarityText[first]
    : rarityText[first];

export const patternRarityText = (pattern: BlockPattern) =>
  mixedRarityText(pattern.firstColor.rarity, pattern.secondColor?.rarity);

export type BallColor = {
  color: NamedColor;
  frozenTint: number;
  rarity: Rarity;
};

export const defaultBallColor: NamedColor = {
  name: "Default",
  tint: 0x7ed321,
};

export const defaultBall: BallColor = {
  color: defaultBallColor,
  frozenTint: 0x00ff99,
  rarity: Rarity.COMMON,
};
export const supporterBallColor: NamedColor = {
  name: "Supporter",
  tint: 0x3889ff,
};

export const supporterBall: BallColor = {
  color: supporterBallColor,
  frozenTint: 0x009dff,
  rarity: Rarity.SUPPORTER,
};
export const inviterBallColor: NamedColor = {
  name: "Inviter",
  tint: 0xff4141,
};

export const inviterBall: BallColor = {
  color: inviterBallColor,
  frozenTint: 0xff1959,
  rarity: Rarity.SUPPORTER,
};

export const challengerBallColor: NamedColor = {
  name: "Challenger",
  tint: 13959423,
};

export const challengerBall: BallColor = {
  color: challengerBallColor,
  frozenTint: 10092799,
  rarity: Rarity.SUPPORTER,
};

export const allBalls: BallColor[] = [
  defaultBall,
  supporterBall,
  inviterBall,
  challengerBall,
];

export type UnlockableBallColor = {
  ballColor: BallColor;
  condition: (player: Player, navigate: NavigateFunction) => JSX.Element;
};

export const unlockableBallColors: UnlockableBallColor[] = [
  {
    ballColor: supporterBall,
    condition: () => (
      <Typography align="center">
        <b>Unlocked By</b>
        <br />
        Buying <Points /> in the <b>Store</b>, supporting <b>maze.game</b>'s
        further development
      </Typography>
    ),
  },
  {
    ballColor: inviterBall,
    condition: (player: Player) => (
      <Typography align="center">
        <b>Unlocked By</b>
        <br />
        Having a new player sign up using your referral link
        <CopyInput
          link={"https://maze.game/r/" + player.name}
          label={"Referral Link"}
        />
      </Typography>
    ),
  },
  {
    ballColor: challengerBall,
    condition: (_, navigate) => (
      <Typography align="center">
        <b>Unlocked By</b>
        <br />
        <Link
          underline="none"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/challenges/new")}
        >
          Creating a Challenge
        </Link>{" "}
        and having at least 5 <b>Guests</b> play it!
        <br />{" "}
        <i>
          <b>Guests</b> are players who do not have an account.
        </i>
      </Typography>
    ),
  },
];

export type BlockColor = {
  color: NamedColor;
  indentTint: number;
  rarity: Rarity;
};

export const defaultBlockColor: BlockColor = {
  color: defaultColor,
  indentTint: 0x6e6e6e,
  rarity: Rarity.COMMON,
};

export enum Pattern {
  SOLID = "SOLID",
  RADIAL_GRADIENT = "RADIAL_GRADIENT",
  VERTICAL_GRADIENT = "VERTICAL_GRADIENT",
  HARLEQUIN = "HARLEQUIN",
  BUBBLES = "BUBBLES",
}

export const patternName = (pattern: Pattern) =>
  pattern === Pattern.SOLID
    ? ""
    : pattern === Pattern.RADIAL_GRADIENT
    ? "Radial Fade"
    : pattern === Pattern.HARLEQUIN
    ? "Harlequin"
    : pattern === Pattern.BUBBLES
    ? "Bubbled"
    : "Vertical Fade";

export type BlockPattern = {
  pattern: Pattern;
  firstColor: BlockColor;
  secondColor?: BlockColor;
};

export const defaultBlockPattern: BlockPattern = {
  pattern: Pattern.SOLID,
  firstColor: defaultBlockColor,
};

export type BlockCosmetics = {
  pattern: BlockPattern;
};

export const blockCosmeticsId = (blockCosmetics: BlockCosmetics) =>
  blockCosmetics.pattern.pattern +
  blockCosmetics.pattern.firstColor.color.name +
  (blockCosmetics.pattern.secondColor?.color?.name || "");

export const blockPatternId = (pattern: BlockPattern) =>
  pattern.pattern +
  pattern.firstColor.color.name +
  (pattern.secondColor?.color?.name || "");

export const blockPatternName = (blockPattern: BlockPattern) =>
  blockPattern.secondColor
    ? blockPattern.firstColor.color.name +
      " × " +
      patternName(blockPattern.pattern) +
      " × " +
      blockPattern.secondColor.color.name
    : blockPattern.firstColor.color.name;

export type UniqueBlockPattern = {
  id: number;
  blockPattern: BlockPattern;
};

export type UniquePattern = {
  id: number;
  pattern: Pattern;
};

export type UniqueBallColor = {
  id: number;
  ballColor: BallColor;
};

export type BallCosmetics = {
  color: BallColor;
};

export type Inventory = {
  blockPatterns: UniqueBlockPattern[];
  ballColors: UniqueBallColor[];
  activeBlockPatternId: number;
  activeBallColorId: number;
  bestRound?: RoundSolution;
};

export function isInventory(value: any): value is Inventory {
  if ((value as Inventory).activeBlockPatternId) {
    return true;
  }
  return false;
}

export const defaultBlockCosmestics: BlockCosmetics = {
  pattern: defaultBlockPattern,
};

export type Cosmetics = {
  block: BlockCosmetics;
  ball: BallCosmetics;
};

export function isCosmetics(value: any): value is Cosmetics {
  if ((value as Cosmetics).block) {
    return true;
  }
  return false;
}

export const defaultCosmetics: Cosmetics = {
  block: defaultBlockCosmestics,
  ball: { color: defaultBall },
};

export type CosmeticPlayerSolution = {
  player: Player;
  solution: Solution;
  cosmetics: Cosmetics;
};

export function isCosmeticPlayerSolution(
  value: any
): value is CosmeticPlayerSolution {
  if ((value as CosmeticPlayerSolution).player) {
    return true;
  }
  return false;
}

export function isCosmeticPlayerSolutions(
  values: any
): values is CosmeticPlayerSolution[] {
  if (
    Array.isArray(values) &&
    values.every((value) => isCosmeticPlayerSolution(value))
  ) {
    return true;
  }
  return false;
}

export type CosmeticRoundResult = {
  playerSolution: CosmeticPlayerSolution;
  opponentSolution?: CosmeticPlayerSolution;
  round: Round;
};

export function isCosmeticRoundResult(
  value: any
): value is CosmeticRoundResult {
  if ((value as CosmeticRoundResult).playerSolution) {
    return true;
  }
  return false;
}

export type CosmeticArenaSnapshot = {
  results: CosmeticRoundResult[];
  status: ArenaStatus;
  mode: ArenaMode;
  timeLeft?: number;
};

export function isCosmeticArenaSnapshot(
  value: any
): value is CosmeticArenaSnapshot {
  if ((value as CosmeticArenaSnapshot).status) {
    return true;
  }
  return false;
}

export type CosmeticServe = {
  serveId: number;
  round: Round;
  playerSolution: CosmeticPlayerSolution;
  allSolutions: CosmeticPlayerSolution[];
  status: ServeStatus;
};

export function isCosmeticServe(value: any): value is CosmeticServe {
  if ((value as CosmeticServe).serveId) {
    return true;
  }
  return false;
}

export type CosmeticPuzzleInfo = {
  deadline: number;
  currentPlayers: number;
  solution?: CosmeticPlayerSolution;
  sponsors: PuzzleSponsors;
  round: Round;
  roundImage?: string;
};

export type CosmeticPuzzleMap = {
  map: { [key: number]: CosmeticPuzzleInfo };
};

export function isCosmeticPuzzleMap(value: any): value is CosmeticPuzzleMap {
  if ((value as CosmeticPuzzleMap).map) {
    return true;
  }
  return false;
}

export type CosmeticCurrentPuzzle = {
  round: Round;
  info: CosmeticPuzzleInfo;
  results: PlayerResult[];
};

export type CosmeticPuzzlePlayerSolution = {
  player: Player;
  solution: Solution;
  submissionTime: number;
  cosmetics: Cosmetics;
};

export type CosmeticPastPuzzle = {
  round: Round;
  playerSolutions: CosmeticPuzzlePlayerSolution[];
};

export type CosmeticPuzzleSnapshot = {
  currentPuzzle: CosmeticCurrentPuzzle;
  pastPuzzles: RoundResults[];
};

export function isCosmeticPuzzleSnapshot(
  value: any
): value is CosmeticPuzzleSnapshot {
  if ((value as CosmeticPuzzleSnapshot).currentPuzzle) {
    return true;
  }
  return false;
}

export function isCosmeticPastPuzzle(value: any): value is CosmeticPastPuzzle {
  if ((value as CosmeticPastPuzzle).round) {
    return true;
  }
  return false;
}
