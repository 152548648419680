import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Typography, Button, Badge } from "@mui/material";
import { getServeService } from "../network/ServeService";
import { Layout } from "../shared/Layout";
import { isPath, Path } from "../shared/Path";
import { Player, PlayerAccount } from "../shared/Player";
import { Round } from "../shared/Round";
import {
  isServeRound,
  isServeStatus,
  isServeStatusMap,
  ServeStatus,
  ServeStatusMap,
} from "../shared/Serve";
import { cst } from "../utils/constants";
import { lt } from "../utils/lt";
import { MazePaper } from "./elements/MazePaper";
import { ServeStatusElement } from "./elements/ServeStatusElement";
import { Points } from "./elements/Emoji";
import { ServesElement } from "./elements/ServesElement";
import { BodyContent } from "./elements/BodyContent";
import { useCheckAchievements } from "../utils/useCheckAchievements";
import { InteractiveGame } from "./elements/InteractiveGame";
import { MultiplayerRoundElement } from "./elements/MultiplayerRoundElement";
import { Cosmetics, CosmeticServe, isCosmeticServe } from "../shared/Cosmetics";
import { useNavigate } from "react-router";

interface ServeProps {
  player: Player;
  account: PlayerAccount;
  cosmetics: Cosmetics | undefined;
  refreshAccount: () => void;
}

type ServeMap = { [key in string]: CosmeticServe };

export const ServePage = (props: ServeProps) => {
  const { account, player, cosmetics, refreshAccount } = props;

  const [serveStatusMap, setServeStatusMap] = useState<
    ServeStatusMap | undefined
  >();
  const [serveTotal, setServeTotal] = useState<ServeStatus | undefined>();

  const [round, setRound] = useState<Round | undefined>();

  const getServeRound = useCallback(() => {
    if (player && !player.guest && account && account.serves > 0) {
      getServeService()
        .getServeRound()
        .then((response) => {
          if (isServeRound(response)) {
            refreshAccount();
            setRound(response.round);
          } else {
            console.error(response);
          }
        });
    }
  }, [player, account, refreshAccount]);

  const [path, setPath] = useState<Path | undefined>();

  useEffect(() => {
    if (player && !player.guest && round === undefined) {
      lt(getServeService(), (serveService) => {
        serveService.all().then((response) => {
          if (isServeStatusMap(response)) {
            setServeStatusMap(response);
          } else {
            console.error(response);
          }
        });
        serveService.total().then((response) => {
          if (isServeStatus(response)) {
            setServeTotal(response);
          } else {
            console.error(response);
          }
        });
      });
    }
  }, [player, round]);

  const checkAchievements = useCheckAchievements();

  const submitServe = useCallback(
    (layout: Layout) => {
      getServeService()
        .submitServe(layout)
        .then((response) => {
          if (isPath(response)) {
            setPath(response);
            checkAchievements();
          } else {
            console.error(response);
          }
        });
    },
    [checkAchievements]
  );

  const [serve, setServe] = useState<CosmeticServe | undefined>();

  const [serveMap, setServeMap] = useState<ServeMap>({});

  const getServeResults = useCallback(
    (serveId: number) => {
      const serve = serveMap[serveId];
      if (serve === undefined) {
        getServeService()
          .getServe(serveId)
          .then((response) => {
            if (isCosmeticServe(response)) {
              setServeMap((serveMap) => {
                serveMap[serveId] = response;
                return { ...serveMap };
              });
              setServe(response);
            }
          });
      } else {
        setServe(serve);
      }
    },
    [serveMap]
  );

  const navigate = useNavigate()

  return (
    <Fragment>
      {player && !player.guest && !round && (
        <Box
          maxWidth="sm"
          mx="auto"
          sx={{ pt: 5, pb: 1, display: serve ? "none" : undefined }}
        >
          <MazePaper title="Serve" goBack={() => navigate(-1)}>
            <Typography sx={{ px: 4 }}>
              You can get <b>Serves</b> by winning rounds in the Arena.
              <br />A <b>Serve</b> is the first play of a round, whenever
              someone <br />
              encounters the same round in the <b>Arena</b>, you will
              <br />
              be awarded <Points /> if your solution is better than theirs!
            </Typography>
            {serveTotal && <ServeStatusElement serveStatus={serveTotal} />}
            {account && (
              <Box sx={{ m: 2 }}>
                <Badge
                  badgeContent={account.serves}
                  color={account.serves === 0 ? "primary" : "success"}
                >
                  <Button
                    variant="contained"
                    onClick={getServeRound}
                    disabled={account.serves < 1}
                  >
                    Play Serve
                  </Button>
                </Badge>
              </Box>
            )}
            {serveStatusMap && (
              <ServesElement
                serveStatusMap={serveStatusMap}
                getServeResults={getServeResults}
              />
            )}
          </MazePaper>
        </Box>
      )}
      {round && !serve && (
        <BodyContent>
          <InteractiveGame
            round={round}
            path={path}
            cosmetics={cosmetics}
            duration={cst.arena.duration}
            submitLayout={submitServe}
            runnerCompleted={() => {
              setRound(undefined);
              setPath(undefined);
            }}
            origin={["Serve"]}
          />
        </BodyContent>
      )}
      {serve && (
        <MultiplayerRoundElement
          round={serve.round}
          playerSolution={serve.playerSolution}
          allSolutions={serve.allSolutions}
          runnerCompleted={() => setServe(undefined)}
        />
      )}
    </Fragment>
  );
};
