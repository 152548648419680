export type NamedHex = { name: string; hex: string };

export const colornames: NamedHex[] = [
  { name: "100 Mph", hex: "#c93f38" },
  { name: "20000 Leagues Under the Sea", hex: "#191970" },
  { name: "24 Carrot", hex: "#e56e24" },
  { name: "24 Karat", hex: "#dfc685" },
  { name: "3AM in Shibuya", hex: "#225577" },
  { name: "3am Latte", hex: "#c0a98e" },
  { name: "8 Bit Eggplant", hex: "#990066" },
  { name: "A Dime a Dozen", hex: "#d3dde4" },
  { name: "À L'Orange", hex: "#f2850d" },
  { name: "A Smell of Bakery", hex: "#f3e9d9" },
  { name: "A State of Mint", hex: "#88ffcc" },
  { name: "Abandoned Spaceship", hex: "#747a8a" },
  { name: "Abloom", hex: "#f1cbcd" },
  { name: "Abyssal", hex: "#404c57" },
  { name: "Abyssal Waters", hex: "#005765" },
  { name: "Acapulco Dive", hex: "#65a7dd" },
  { name: "Acid", hex: "#00ff22" },
  { name: "Acid Green", hex: "#8ffe09" },
  { name: "Acorn", hex: "#7e5e52" },
  { name: "Active Volcano", hex: "#bb1133" },
  { name: "Admiral Blue", hex: "#50647f" },
  { name: "Adrift on the Nile", hex: "#93b8e3" },
  { name: "After Midnight", hex: "#38393f" },
  { name: "Aged Antics", hex: "#886b2e" },
  { name: "Agressive Aqua", hex: "#00fbff" },
  { name: "Ahoy! Blue", hex: "#0082a1" },
  { name: "Air-Kiss", hex: "#f6dcd2" },
  { name: "Airborne", hex: "#a2c2d0" },
  { name: "Akari Red", hex: "#c90b42" },
  { name: "Alarm", hex: "#ec0003" },
  { name: "Alaska", hex: "#dadad1" },
  { name: "Alien Abduction", hex: "#0cff0c" },
  { name: "Alienated", hex: "#00cc55" },
  { name: "Alley Cat", hex: "#656874" },
  { name: "Alligator", hex: "#886600" },
  { name: "Almond", hex: "#eddcc8" },
  { name: "Almond Milk", hex: "#d6cebe" },
  { name: "Aloe Tip", hex: "#8a9480" },
  { name: "Aloe Vera", hex: "#678779" },
  { name: "Alone in the Dark", hex: "#000066" },
  { name: "Alpaca", hex: "#f7e5da" },
  { name: "Alpine Expedition", hex: "#99eeff" },
  { name: "Aluminium", hex: "#848789" },
  { name: "Always Green Grass", hex: "#11aa00" },
  { name: "Amaretto", hex: "#ab6f60" },
  { name: "Amaretto Sour", hex: "#c09856" },
  { name: "Amazon", hex: "#387b54" },
  { name: "Amber", hex: "#ffbf00" },
  { name: "Ambrosia", hex: "#d2e7ca" },
  { name: "Ambrosial Oceanside", hex: "#47ae9c" },
  { name: "Amnesiac White", hex: "#f8fbeb" },
  { name: "Amor", hex: "#ee3377" },
  { name: "Amora Purple", hex: "#bb22aa" },
  { name: "Amore", hex: "#ae2f48" },
  { name: "Anarchist", hex: "#db304a" },
  { name: "Anchovy", hex: "#756f6b" },
  { name: "Ancient Scroll", hex: "#f0e4d1" },
  { name: "Andromeda Blue", hex: "#abcdee" },
  { name: "Angel Wing", hex: "#f3dfd7" },
  { name: "Angel's Trumpet", hex: "#f6dd34" },
  { name: "Angelic White", hex: "#f4ede4" },
  { name: "Anger", hex: "#dd0055" },
  { name: "Angry Flamingo", hex: "#f04e45" },
  { name: "Angry Ghost", hex: "#eebbbb" },
  { name: "Angry Pasta", hex: "#ffcc55" },
  { name: "Angry Tomato", hex: "#d82029" },
  { name: "Animal Kingdom", hex: "#bcc09e" },
  { name: "Anime Blush", hex: "#ff7a83" },
  { name: "Anise Biscotti", hex: "#c0baaf" },
  { name: "Anna Banana", hex: "#f5d547" },
  { name: "Annatto", hex: "#8c5341" },
  { name: "Another One Bites the Dust", hex: "#c7bba4" },
  { name: "Antarctic Love", hex: "#eddee6" },
  { name: "Anthracite", hex: "#28282d" },
  { name: "Antique", hex: "#8b846d" },
  { name: "Antique Brass", hex: "#6c461f" },
  { name: "Aphrodisiac", hex: "#e35a63" },
  { name: "Aphrodite Aqua", hex: "#45e9c1" },
  { name: "Aphroditean Fuchsia", hex: "#dd14ab" },
  { name: "Apocalyptic Orange", hex: "#f4711e" },
  { name: "Apollo Landing", hex: "#e5e5e1" },
  { name: "Apple Cherry", hex: "#f81404" },
  { name: "Apricot", hex: "#ffb16d" },
  { name: "Apricot Freeze", hex: "#f3cfb7" },
  { name: "Apricotta", hex: "#d8a48f" },
  { name: "April Showers", hex: "#dadeb5" },
  { name: "Aqua", hex: "#00ffff" },
  { name: "Aqua Fiesta", hex: "#96e2e1" },
  { name: "Aquamarine", hex: "#2ee8bb" },
  { name: "Aquarius", hex: "#3cadd4" },
  { name: "Aquatic Edge", hex: "#bfd6d1" },
  { name: "Arabica Mint", hex: "#c0ffee" },
  { name: "Arancio", hex: "#ff7013" },
  { name: "Arcade Fire", hex: "#ee3311" },
  { name: "Arcane Red", hex: "#6a2f2f" },
  { name: "Archeology", hex: "#6e6a5e" },
  { name: "Arctic", hex: "#648589" },
  { name: "Arctic Ice", hex: "#bfc7d6" },
  { name: "Arctic Water", hex: "#00fcfc" },
  { name: "Argan Oil", hex: "#8b593e" },
  { name: "Argent", hex: "#888888" },
  { name: "Argento", hex: "#cecac3" },
  { name: "Ariel", hex: "#aed7ea" },
  { name: "Aristocratic Velvet", hex: "#980b4a" },
  { name: "Armadillo", hex: "#484a46" },
  { name: "Armor", hex: "#74857f" },
  { name: "Aromatic Herbs", hex: "#98c945" },
  { name: "Around the Gills", hex: "#a1b670" },
  { name: "Arrowwood", hex: "#bc8d1f" },
  { name: "Artichoke", hex: "#8f9779" },
  { name: "Artisans Gold", hex: "#f2ab46" },
  { name: "Arugula", hex: "#75ad5b" },
  { name: "Ash", hex: "#bebaa7" },
  { name: "Ashes to Ashes", hex: "#bbb3a2" },
  { name: "Asparagus", hex: "#77ab56" },
  { name: "Asphalt", hex: "#130a06" },
  { name: "Assassin", hex: "#2d4f83" },
  { name: "Assassin's Red", hex: "#f60206" },
  { name: "Aster", hex: "#867ba9" },
  { name: "Astral", hex: "#376f89" },
  { name: "Atlantic Navy", hex: "#13336f" },
  { name: "Atlantis", hex: "#336172" },
  { name: "Atlas Cedar", hex: "#5ca0a7" },
  { name: "Atomic Lime", hex: "#b9ff03" },
  { name: "Atomic Orange", hex: "#f88605" },
  { name: "Atomic Pink", hex: "#fb7efd" },
  { name: "Atomic Tangerine", hex: "#ff9966" },
  { name: "Aubergine", hex: "#372528" },
  { name: "Augustus Asparagus", hex: "#90aa0b" },
  { name: "Aurora", hex: "#eddd59" },
  { name: "Autumn Crocodile", hex: "#447744" },
  { name: "Autumn Fire", hex: "#c44e4f" },
  { name: "Autumn Gold", hex: "#7d623c" },
  { name: "Autumnal", hex: "#a15325" },
  { name: "Avant-Garde Pink", hex: "#ff77ee" },
  { name: "Avocado", hex: "#568203" },
  { name: "Avocado Stone", hex: "#4e3e1f" },
  { name: "Award Winning White", hex: "#fef0de" },
  { name: "Awkward Purple", hex: "#d208cc" },
  { name: "Ayahuasca Vine", hex: "#665500" },
  { name: "Aztec", hex: "#293432" },
  { name: "Aztec Temple", hex: "#84705b" },
  { name: "Aztec Warrior", hex: "#bb0066" },
  { name: "Azulado", hex: "#211d49" },
  { name: "Azure", hex: "#007fff" },
  { name: "Baba Ganoush", hex: "#eebb88" },
  { name: "Babe", hex: "#dc7b7c" },
  { name: "Baby Bear", hex: "#6f5944" },
  { name: "Baby Blue", hex: "#a2cffe" },
  { name: "Baby Pink", hex: "#ffb7ce" },
  { name: "Back In Black", hex: "#16141c" },
  { name: "Backyard", hex: "#879877" },
  { name: "Bacon Strips", hex: "#df3f32" },
  { name: "Badass Grass", hex: "#b4da55" },
  { name: "Baker's Dream", hex: "#c98f70" },
  { name: "Baker’s Bread", hex: "#d0b393" },
  { name: "Bakery Brown", hex: "#ab9078" },
  { name: "Baklava", hex: "#efb435" },
  { name: "Ballerina", hex: "#f2cfdc" },
  { name: "Ballet", hex: "#f7d5d4" },
  { name: "Balsamico", hex: "#130d07" },
  { name: "Baltic", hex: "#279d9f" },
  { name: "Bambino", hex: "#8edacc" },
  { name: "Bamboo Forest", hex: "#b1a979" },
  { name: "Banan-appeal", hex: "#faf3a6" },
  { name: "Banana", hex: "#fffc79" },
  { name: "Banana Bandanna", hex: "#f8f739" },
  { name: "Banana Bombshell", hex: "#f7e82e" },
  { name: "Banana Bread", hex: "#ffcf73" },
  { name: "Banana Brick", hex: "#e8d82c" },
  { name: "Banana Cream", hex: "#fff49c" },
  { name: "Banana Flash", hex: "#eefe02" },
  { name: "Banana Frappé", hex: "#ddd5b6" },
  { name: "Banana King", hex: "#fffb08" },
  { name: "Banana Mania", hex: "#fbe7b2" },
  { name: "Banana Milk", hex: "#fff7ad" },
  { name: "Banana Pepper", hex: "#fdd630" },
  { name: "Banana Propaganda", hex: "#f3db00" },
  { name: "Banana Republic", hex: "#ffe292" },
  { name: "Banana Split", hex: "#f7eec8" },
  { name: "Bangalore", hex: "#bbaa88" },
  { name: "Bank Vault", hex: "#757374" },
  { name: "Banner Gold", hex: "#a28557" },
  { name: "Barbara", hex: "#ff0ff3" },
  { name: "Barbarossa", hex: "#a84734" },
  { name: "Barbecue", hex: "#c26157" },
  { name: "Barberry", hex: "#ee1133" },
  { name: "Bark", hex: "#5f5854" },
  { name: "Barrel Aged", hex: "#8b6945" },
  { name: "Basil", hex: "#879f84" },
  { name: "Basil Smash", hex: "#b7e1a1" },
  { name: "Basketball", hex: "#ee6730" },
  { name: "Bat Wing", hex: "#7e7466" },
  { name: "Bat-Signal", hex: "#feff00" },
  { name: "Bat's Blood Soup", hex: "#ee3366" },
  { name: "Bath Bubbles", hex: "#e6f2ea" },
  { name: "Bath Water", hex: "#88eeee" },
  { name: "Batman", hex: "#656e72" },
  { name: "Bats Cloak", hex: "#1f1518" },
  { name: "Battletoad", hex: "#11cc55" },
  { name: "Bavarian Green", hex: "#749a54" },
  { name: "Bay", hex: "#bae5d6" },
  { name: "Bay View", hex: "#6a819e" },
  { name: "Beach Dune", hex: "#c6bb9c" },
  { name: "Beach Glass", hex: "#96dfce" },
  { name: "Beach View", hex: "#4f7694" },
  { name: "Bear Hug", hex: "#796359" },
  { name: "Beat Around the Bush", hex: "#6e6a44" },
  { name: "Beau Monde", hex: "#7db39e" },
  { name: "Beau Vert", hex: "#0c6064" },
  { name: "Beautiful Darkness", hex: "#686d70" },
  { name: "Beauty and the Beach", hex: "#c99680" },
  { name: "Béchamel", hex: "#f4eee0" },
  { name: "Becquerel", hex: "#4bec13" },
  { name: "Bee Yellow", hex: "#feff32" },
  { name: "Beekeeper", hex: "#f6e491" },
  { name: "Beet Red", hex: "#7a1f3d" },
  { name: "Beetroot Purple", hex: "#cf2d71" },
  { name: "Begonia", hex: "#fa6e79" },
  { name: "Beige", hex: "#e6daa6" },
  { name: "Bejewelled", hex: "#25a26f" },
  { name: "Belgian Waffle", hex: "#f3dfb6" },
  { name: "Bell Tower", hex: "#dad0bb" },
  { name: "Belladonna", hex: "#220011" },
  { name: "Bellflower", hex: "#5d66aa" },
  { name: "Bellini", hex: "#f4c9b1" },
  { name: "Beloved Sunflower", hex: "#ffba24" },
  { name: "Below the Surface", hex: "#0a2f7c" },
  { name: "Below Zero", hex: "#87cded" },
  { name: "Beluga", hex: "#eff2f1" },
  { name: "Benevolent Pink", hex: "#dd1188" },
  { name: "Bengal", hex: "#cc974d" },
  { name: "Bento Box", hex: "#cc363c" },
  { name: "Berber", hex: "#d8cfb6" },
  { name: "Bergamot", hex: "#95c703" },
  { name: "Bermuda", hex: "#1b7d8d" },
  { name: "Bermuda Grass", hex: "#a19f79" },
  { name: "Bermuda Onion", hex: "#9d5a8f" },
  { name: "Berries n Cream", hex: "#f2b8ca" },
  { name: "Berry", hex: "#990f4b" },
  { name: "Berry Jam", hex: "#655883" },
  { name: "Berrylicious", hex: "#d75e6c" },
  { name: "Bethlehem Superstar", hex: "#eaeeda" },
  { name: "Beurre Blanc", hex: "#ede1be" },
  { name: "Beveled Glass", hex: "#7accb8" },
  { name: "Beyond the Pines", hex: "#688049" },
  { name: "Beyond the Stars", hex: "#0a3251" },
  { name: "Bianca", hex: "#f4efe0" },
  { name: "Big Bang Pink", hex: "#ff0099" },
  { name: "Big Fish to Fry", hex: "#dadbe1" },
  { name: "Big Spender", hex: "#acddaf" },
  { name: "Big Yellow Taxi", hex: "#ffff33" },
  { name: "Bigfoot", hex: "#715145" },
  { name: "Billiard", hex: "#00aa92" },
  { name: "Bindi Red", hex: "#b0003c" },
  { name: "Biohazard Suit", hex: "#fbfb4c" },
  { name: "BioShock", hex: "#889900" },
  { name: "Birch White", hex: "#f6eedf" },
  { name: "Bird Bath Blue", hex: "#cddfe7" },
  { name: "Biscuit", hex: "#feedca" },
  { name: "Biscuit Dough", hex: "#e8dbbd" },
  { name: "Bison", hex: "#6e4f3a" },
  { name: "Bisque", hex: "#ffe4c4" },
  { name: "Bite My Tongue", hex: "#d47d72" },
  { name: "Bitter Chocolate", hex: "#4f2923" },
  { name: "Bitter Lemon", hex: "#d2db32" },
  { name: "Bitter Liquorice", hex: "#262926" },
  { name: "Bitter Melon", hex: "#cfd1b2" },
  { name: "Bittersweet", hex: "#fea051" },
  { name: "Black", hex: "#000000" },
  { name: "Black Box", hex: "#0f282f" },
  { name: "Black Cherry", hex: "#2c1620" },
  { name: "Black Chocolate", hex: "#441100" },
  { name: "Black Forest", hex: "#5e6354" },
  { name: "Black Hole", hex: "#010203" },
  { name: "Black Knight", hex: "#010b13" },
  { name: "Black Magic", hex: "#4f4554" },
  { name: "Black Mana", hex: "#858585" },
  { name: "Black Metal", hex: "#060606" },
  { name: "Black Olive", hex: "#3b3c36" },
  { name: "Black Orchid", hex: "#525463" },
  { name: "Black Out", hex: "#222222" },
  { name: "Black Panther", hex: "#424242" },
  { name: "Black Pearl", hex: "#1e272c" },
  { name: "Black Power", hex: "#654b37" },
  { name: "Black Sabbath", hex: "#220022" },
  { name: "Black Sheep", hex: "#0f0d0d" },
  { name: "Black Truffle", hex: "#463d3e" },
  { name: "Black Turmeric", hex: "#2c4364" },
  { name: "Black Wash", hex: "#0c0c0c" },
  { name: "Blackberry", hex: "#43182f" },
  { name: "Blackberry Yogurt", hex: "#e5bddf" },
  { name: "Blackn't", hex: "#020f03" },
  { name: "Blackout", hex: "#0e0702" },
  { name: "Blackwater", hex: "#545663" },
  { name: "Blanc Cassé", hex: "#f1eee2" },
  { name: "Blanco", hex: "#ebeae5" },
  { name: "Blank Canvas", hex: "#ffefd6" },
  { name: "Blank Stare", hex: "#8b9cac" },
  { name: "Blasphemous Blue", hex: "#3356aa" },
  { name: "Blazing Dragonfruit", hex: "#ff0054" },
  { name: "Bleached Olive", hex: "#55bb88" },
  { name: "Bleached Sunflower", hex: "#fbe8a8" },
  { name: "Bleeding Crimson", hex: "#9b1414" },
  { name: "Bleeding Heart", hex: "#c02e4c" },
  { name: "Bleu Ciel", hex: "#007ba1" },
  { name: "Blinking Terminal", hex: "#66cc00" },
  { name: "Blister Pearl", hex: "#aaffee" },
  { name: "Blizzard Blue", hex: "#a3e3ed" },
  { name: "Blond", hex: "#faf0be" },
  { name: "Blood Brother", hex: "#770011" },
  { name: "Blood Burst", hex: "#ff474c" },
  { name: "Blood Donor", hex: "#ea1822" },
  { name: "Blood Kiss", hex: "#c30b0a" },
  { name: "Blood Orange", hex: "#d1001c" },
  { name: "Blood Rush", hex: "#aa2222" },
  { name: "Bloodhound", hex: "#bb5511" },
  { name: "Bloodthirsty", hex: "#880011" },
  { name: "Bloodthirsty Beige", hex: "#f8d7d0" },
  { name: "Bloodthirsty Lips", hex: "#c6101e" },
  { name: "Bloody Mary", hex: "#ba0105" },
  { name: "Bloody Salmon", hex: "#cc4433" },
  { name: "Blossom", hex: "#fee9d8" },
  { name: "Blossoming Dynasty", hex: "#de5346" },
  { name: "Blowing Kisses", hex: "#f6dee0" },
  { name: "Blue", hex: "#0000ff" },
  { name: "Blue Bay", hex: "#619ad6" },
  { name: "Blue Bell", hex: "#93b4d7" },
  { name: "Blue Bikini", hex: "#00bbee" },
  { name: "Blue Blood", hex: "#6b7f81" },
  { name: "Blue Bobbin", hex: "#52b4ca" },
  { name: "Blue Chip", hex: "#1d5699" },
  { name: "Blue Haze", hex: "#bdbace" },
  { name: "Blue Hour", hex: "#0034ab" },
  { name: "Blue Lips", hex: "#a6bce2" },
  { name: "Blue Mana", hex: "#68c2f5" },
  { name: "Blue Martini", hex: "#52b4d3" },
  { name: "Blue Moon", hex: "#3686a0" },
  { name: "Blue Overdose", hex: "#0020ef" },
  { name: "Blue Ribbon", hex: "#0066ff" },
  { name: "Blue Screen of Death", hex: "#0033bb" },
  { name: "Blue Silk", hex: "#d0dce8" },
  { name: "Blue Triumph", hex: "#4376ab" },
  { name: "Blue Velvet", hex: "#0d6183" },
  { name: "Blue Whale", hex: "#1e3442" },
  { name: "Bluebell", hex: "#333399" },
  { name: "Blueberry", hex: "#464196" },
  { name: "Bluebonnet", hex: "#1c1cf0" },
  { name: "Bluerocratic", hex: "#1f66ff" },
  { name: "Blues White Shoes", hex: "#99badd" },
  { name: "Bluetiful", hex: "#3c69e7" },
  { name: "Blush Bomb", hex: "#dd99aa" },
  { name: "Blush d'Amour", hex: "#de5d83" },
  { name: "Blush Kiss", hex: "#eabcc0" },
  { name: "Blush Rush", hex: "#f0bcbe" },
  { name: "Blushing Cinnamon", hex: "#ffbf99" },
  { name: "Blushing Coconut", hex: "#ebd5ca" },
  { name: "Blustering Blue", hex: "#4411ff" },
  { name: "Boa", hex: "#8e855f" },
  { name: "Bohemian Blue", hex: "#0000aa" },
  { name: "Boho Copper", hex: "#b96033" },
  { name: "Boiling Magma", hex: "#ff3300" },
  { name: "Bok Choy", hex: "#bccab3" },
  { name: "Bold Irish", hex: "#2a814d" },
  { name: "Bollywood Gold", hex: "#fffbab" },
  { name: "Bolognese", hex: "#bb4400" },
  { name: "Bone", hex: "#e0d7c6" },
  { name: "Bone-Chilling", hex: "#e1f2f0" },
  { name: "Bonfire", hex: "#f78058" },
  { name: "Bonne Nuit", hex: "#3a4866" },
  { name: "Bonsai", hex: "#787b54" },
  { name: "Bonsai Garden", hex: "#9e9e7c" },
  { name: "Bookworm", hex: "#ebe3de" },
  { name: "Bordeaux", hex: "#7b002c" },
  { name: "Borderline Pink", hex: "#ee1166" },
  { name: "Borlotti Bean", hex: "#d9b1aa" },
  { name: "Botanical", hex: "#4d6e2f" },
  { name: "Botanical Garden", hex: "#44aa11" },
  { name: "Botanical Night", hex: "#12403c" },
  { name: "Bottom of my Heart", hex: "#cc0077" },
  { name: "Boulevardier", hex: "#d40701" },
  { name: "Bourbon", hex: "#af6c3e" },
  { name: "Bourbon Peach", hex: "#ec842f" },
  { name: "Boutique Beige", hex: "#e1cead" },
  { name: "Brain Freeze", hex: "#00eeff" },
  { name: "Brandy", hex: "#dcb68a" },
  { name: "Brandy Bear", hex: "#aa5412" },
  { name: "Brandywine Spritz", hex: "#e69dad" },
  { name: "Brass", hex: "#b5a642" },
  { name: "Brass Buttons", hex: "#dfac4c" },
  { name: "Bread and Butter", hex: "#faedd2" },
  { name: "Bread Crumb", hex: "#e4d4be" },
  { name: "Break the Ice", hex: "#b2e1ee" },
  { name: "Breath of Fire", hex: "#ee0011" },
  { name: "Breath of Fresh Air", hex: "#c7dbe4" },
  { name: "Breeze", hex: "#c2dde6" },
  { name: "Breeze of Chilli", hex: "#f4706e" },
  { name: "Brick", hex: "#a03623" },
  { name: "Brick Red", hex: "#8f1402" },
  { name: "Bricky Brick", hex: "#b33a22" },
  { name: "Bride", hex: "#efe7eb" },
  { name: "Bride's Blush", hex: "#f9e2e1" },
  { name: "Bright Star", hex: "#dde2e6" },
  { name: "Brilliance", hex: "#fdfdfd" },
  { name: "Brilliant Blue", hex: "#0075b3" },
  { name: "Brilliant Gold", hex: "#f0dbaa" },
  { name: "Brink Pink", hex: "#fb607f" },
  { name: "Brisket", hex: "#6e4534" },
  { name: "British Phone Booth", hex: "#ff0015" },
  { name: "Broad Daylight", hex: "#bbddff" },
  { name: "Broccoli", hex: "#87b364" },
  { name: "Broccoli Green", hex: "#4b5338" },
  { name: "Broccoli Paradise", hex: "#008833" },
  { name: "Bronze", hex: "#a87900" },
  { name: "Bronzed", hex: "#dd6633" },
  { name: "Broom", hex: "#eecc24" },
  { name: "Brown", hex: "#653700" },
  { name: "Brown Alpaca", hex: "#b86d29" },
  { name: "Brown Coffee", hex: "#4a2c2a" },
  { name: "Brown Sugar", hex: "#a17249" },
  { name: "Brown Sugar Glaze", hex: "#cf7a4b" },
  { name: "Brownie", hex: "#964b00" },
  { name: "Bruise", hex: "#7e4071" },
  { name: "Bruised Plum", hex: "#3b1921" },
  { name: "Brume", hex: "#c6c6c2" },
  { name: "Brunette", hex: "#664238" },
  { name: "Bruschetta", hex: "#a75949" },
  { name: "Brutal Pink", hex: "#ff00bb" },
  { name: "Bubbelgum Heart", hex: "#ffbadf" },
  { name: "Bubble Gum", hex: "#ff85ff" },
  { name: "Bubblegum Baby Girl", hex: "#cc55ee" },
  { name: "Bubblegum Crisis", hex: "#eeccee" },
  { name: "Bubblegum Kisses", hex: "#f092d6" },
  { name: "Bubbles", hex: "#e7feff" },
  { name: "Büchel Cherry", hex: "#aa1111" },
  { name: "Buckeye", hex: "#674834" },
  { name: "Bucolic", hex: "#1b6634" },
  { name: "Bud Green", hex: "#79b465" },
  { name: "Buddha's Love Handles", hex: "#ffbb33" },
  { name: "Buff It", hex: "#d9cfbe" },
  { name: "Bullet Hell", hex: "#faf1c8" },
  { name: "Bullfrog", hex: "#8a966a" },
  { name: "Bulma Hair", hex: "#359e6b" },
  { name: "Bumblebee", hex: "#ffc82a" },
  { name: "Bunny Tail", hex: "#ffe3f4" },
  { name: "Bureaucracy", hex: "#746c8f" },
  { name: "Burning Fireflies", hex: "#ff1166" },
  { name: "Burning Flame", hex: "#ffb162" },
  { name: "Burning Orange", hex: "#ff7124" },
  { name: "Burning Raspberry", hex: "#ff0599" },
  { name: "Burning Trail", hex: "#ee9922" },
  { name: "Burning Ultrablue", hex: "#150aec" },
  { name: "Burnt Coffee", hex: "#271b10" },
  { name: "Burnt Red", hex: "#9f2305" },
  { name: "Burrito", hex: "#eed7c1" },
  { name: "Busty Blue", hex: "#3300cc" },
  { name: "Busy Bee", hex: "#f4ff00" },
  { name: "Butter", hex: "#ffff81" },
  { name: "Butter Bronze", hex: "#c88849" },
  { name: "Butter Honey", hex: "#f5e5ab" },
  { name: "Butter Muffin", hex: "#f6dfb2" },
  { name: "Butter Up", hex: "#f4e0bb" },
  { name: "Butterbeer", hex: "#af7934" },
  { name: "Buttercream", hex: "#efe0cd" },
  { name: "Buttered Popcorn", hex: "#fff0a4" },
  { name: "Buttered Up", hex: "#f7f0d2" },
  { name: "Buttermelon", hex: "#fff7db" },
  { name: "Buttermilk", hex: "#fffee4" },
  { name: "Butternut", hex: "#ffa177" },
  { name: "Butternut Squash", hex: "#fc7604" },
  { name: "Butterscotch", hex: "#fdb147" },
  { name: "Butterscotch Cake", hex: "#f1c882" },
  { name: "Butterum", hex: "#c68f65" },
  { name: "Buttery", hex: "#ffc283" },
  { name: "Buttery Croissant", hex: "#f6e19c" },
  { name: "Buzz", hex: "#f0c641" },
  { name: "C64 Blue", hex: "#003aff" },
  { name: "Cabaret", hex: "#cd526c" },
  { name: "Cabbage", hex: "#87d7be" },
  { name: "Cacodemon Red", hex: "#9f0000" },
  { name: "Cactus", hex: "#5b6f55" },
  { name: "Cactus Spike", hex: "#c1e0a3" },
  { name: "Cadillac Coupe", hex: "#c0362c" },
  { name: "Caduceus Staff", hex: "#eedd22" },
  { name: "Café Au Lait", hex: "#a57c5b" },
  { name: "Cafe Creme", hex: "#c79685" },
  { name: "Cafe Latte", hex: "#d6c6b4" },
  { name: "Café Noir", hex: "#4b3621" },
  { name: "Cafe Royale", hex: "#6a4928" },
  { name: "Cajeta", hex: "#c46d29" },
  { name: "Cakepop Sorbet", hex: "#f8c649" },
  { name: "Calabrese", hex: "#f4a6a3" },
  { name: "Calcium Rock", hex: "#eee9d9" },
  { name: "Call It a Night", hex: "#42364c" },
  { name: "Calm Waters", hex: "#e7fafa" },
  { name: "Calypso", hex: "#3d7188" },
  { name: "Camel", hex: "#c69f59" },
  { name: "Camel Cardinal", hex: "#cc9944" },
  { name: "Camellia", hex: "#f6745f" },
  { name: "Cameo", hex: "#f2debc" },
  { name: "Camo Clay", hex: "#747f71" },
  { name: "Campfire", hex: "#ce5f38" },
  { name: "Camping Trip", hex: "#67786e" },
  { name: "Canadian Maple", hex: "#cab266" },
  { name: "Canadian Tuxedo", hex: "#579aca" },
  { name: "Canary", hex: "#fdff63" },
  { name: "Candied Apple", hex: "#b95b6d" },
  { name: "Candied Snow", hex: "#d8fff3" },
  { name: "Candle Glow", hex: "#ffe8c3" },
  { name: "Candle in the Wind", hex: "#f9ebbf" },
  { name: "Candlelight", hex: "#fcd917" },
  { name: "Candy", hex: "#ff9b87" },
  { name: "Candy Apple Red", hex: "#ff0800" },
  { name: "Candy Bar", hex: "#ffb7d5" },
  { name: "Candy Corn", hex: "#fcfc5d" },
  { name: "Candy Dreams", hex: "#e9aef2" },
  { name: "Candy Floss", hex: "#e8a7e2" },
  { name: "Candy Grape Fizz", hex: "#7755ee" },
  { name: "Candy Green", hex: "#33cc00" },
  { name: "Candy Pink", hex: "#ff63e9" },
  { name: "Cane Sugar", hex: "#e3b982" },
  { name: "Cannoli Cream", hex: "#f0efe2" },
  { name: "Cantaloupe", hex: "#ffd479" },
  { name: "Canyon Sunset", hex: "#e1927a" },
  { name: "Capers", hex: "#897a3e" },
  { name: "Capital Blue", hex: "#1a4157" },
  { name: "Caponata", hex: "#822a10" },
  { name: "Cappuccino", hex: "#633f33" },
  { name: "Cappuccino Cosmico", hex: "#e1ddcd" },
  { name: "Capri", hex: "#00bfff" },
  { name: "Captain Kirk", hex: "#9b870c" },
  { name: "Caramel", hex: "#af6f09" },
  { name: "Caramel Coating", hex: "#bb7711" },
  { name: "Caramel Crumb", hex: "#c39355" },
  { name: "Caramel Dream", hex: "#b8623b" },
  { name: "Caramel Finish", hex: "#ffd59a" },
  { name: "Caramel Gold", hex: "#b1936d" },
  { name: "Caramel Macchiato", hex: "#c58d4b" },
  { name: "Caramel Mousse", hex: "#e5caa4" },
  { name: "Caramelize", hex: "#d58a37" },
  { name: "Caramelized Pecan", hex: "#a17b4d" },
  { name: "Carbon", hex: "#333333" },
  { name: "Carbon Fiber", hex: "#2e2e2e" },
  { name: "Cardamom", hex: "#aaaa77" },
  { name: "Cardboard", hex: "#c19a6c" },
  { name: "Cardinal", hex: "#c41e3a" },
  { name: "Caribbean", hex: "#caf0e5" },
  { name: "Caribbean Blue", hex: "#1ac1dd" },
  { name: "Caribou", hex: "#816d5e" },
  { name: "Carmine", hex: "#d60036" },
  { name: "Carnivore", hex: "#991111" },
  { name: "Carolina Reaper", hex: "#ff1500" },
  { name: "Carona", hex: "#fba52e" },
  { name: "Carpaccio", hex: "#e34234" },
  { name: "Carrot", hex: "#fd6f3b" },
  { name: "Carrot Lava", hex: "#fc5a1f" },
  { name: "Cartoon Violence", hex: "#d01722" },
  { name: "Cascade Twilight", hex: "#234893" },
  { name: "Cascara", hex: "#ee4433" },
  { name: "Cashew Nut", hex: "#edccb3" },
  { name: "Cashmere", hex: "#d1b399" },
  { name: "Cashmere Clay", hex: "#cda291" },
  { name: "Casino Lights", hex: "#f9f2b3" },
  { name: "Casper", hex: "#aab5b8" },
  { name: "Castaway", hex: "#6dbac0" },
  { name: "Castaway Beach", hex: "#d0c19f" },
  { name: "Castle in the Sky", hex: "#d1eaed" },
  { name: "Castro", hex: "#44232f" },
  { name: "Catacomb Walls", hex: "#dbd7d0" },
  { name: "Catfish", hex: "#657d82" },
  { name: "Cathedral", hex: "#acaaa7" },
  { name: "Cathode Green", hex: "#00ff55" },
  { name: "Cauliflower", hex: "#ebe5d0" },
  { name: "Caveman", hex: "#625c58" },
  { name: "Caviar", hex: "#292a2d" },
  { name: "Cavolo Nero", hex: "#72939e" },
  { name: "Cayenne", hex: "#941100" },
  { name: "Cedar Chest", hex: "#c95a49" },
  { name: "Celadon", hex: "#ace1af" },
  { name: "Celadon Porcelain", hex: "#7ebea5" },
  { name: "Celery", hex: "#b4c04c" },
  { name: "Celery Mousse", hex: "#c1fd95" },
  { name: "Celery Scepter", hex: "#e1df9a" },
  { name: "Celestial", hex: "#006380" },
  { name: "Celestial Cathedral", hex: "#daeaf6" },
  { name: "Celestial Horizon", hex: "#7c94b3" },
  { name: "Cement Feet", hex: "#7b737b" },
  { name: "Ceramic", hex: "#fcfff9" },
  { name: "Cereal Flake", hex: "#efd7ab" },
  { name: "Cerulean", hex: "#55aaee" },
  { name: "Chai Latte", hex: "#f9cba0" },
  { name: "Chai Tea", hex: "#b1832f" },
  { name: "Chain Mail", hex: "#81777f" },
  { name: "Chalet", hex: "#c29867" },
  { name: "Champagne", hex: "#e9d2ac" },
  { name: "Champagne Gold", hex: "#e8d6b3" },
  { name: "Channel", hex: "#f1c3c2" },
  { name: "Chantilly", hex: "#edb8c7" },
  { name: "Chaotic Roses", hex: "#bb2266" },
  { name: "Charcoal", hex: "#343837" },
  { name: "Charismatic Red", hex: "#ee2244" },
  { name: "Charm", hex: "#d0748b" },
  { name: "Charming Peach", hex: "#f5ad75" },
  { name: "Chartreuse", hex: "#c1f80a" },
  { name: "Che Guevara Red", hex: "#ed214d" },
  { name: "Cheddar", hex: "#ee9a09" },
  { name: "Cheek Red", hex: "#a55a55" },
  { name: "Cheeky Chestnut", hex: "#7b4d3a" },
  { name: "Cheerly Kiwi", hex: "#bccb08" },
  { name: "Cheese", hex: "#ffa600" },
  { name: "Cheese It Up", hex: "#fdde45" },
  { name: "Cheese Please", hex: "#ff9613" },
  { name: "Cheesecake", hex: "#fffcda" },
  { name: "Cheesus", hex: "#ffcc77" },
  { name: "Cheesy Cheetah", hex: "#eeb033" },
  { name: "Cheesy Frittata", hex: "#f0e093" },
  { name: "Chef's Hat", hex: "#f3f4f5" },
  { name: "Cherry", hex: "#cf0234" },
  { name: "Cherry Berry", hex: "#9f4d65" },
  { name: "Cherry Blossom", hex: "#f7cee0" },
  { name: "Cherry Bomb", hex: "#b73d3f" },
  { name: "Cherry Paddle Pop", hex: "#fe314b" },
  { name: "Cherry Picking", hex: "#620b15" },
  { name: "Cherry Sangria", hex: "#c92435" },
  { name: "Cherry Soda", hex: "#ff0044" },
  { name: "Cherry Tomato", hex: "#f2013f" },
  { name: "Cherry Velvet", hex: "#e10646" },
  { name: "Cherryade", hex: "#b22743" },
  { name: "Chess Ivory", hex: "#ffe9c5" },
  { name: "Chestnut", hex: "#742802" },
  { name: "Chewing Gum", hex: "#e6b0af" },
  { name: "Chickadee", hex: "#ffcf65" },
  { name: "Chicken Comb", hex: "#dd2222" },
  { name: "Chicken Masala", hex: "#cc8822" },
  { name: "Child of the Night", hex: "#220077" },
  { name: "Chili Con Carne", hex: "#985e2b" },
  { name: "Chili Crab", hex: "#e93a0e" },
  { name: "Chili Pepper", hex: "#9b1b30" },
  { name: "Chimera", hex: "#74626d" },
  { name: "China Silk", hex: "#e3d1cc" },
  { name: "Chinese New Year", hex: "#ff3366" },
  { name: "Chinotto", hex: "#554747" },
  { name: "Chipmunk", hex: "#cfa14a" },
  { name: "Chivalrous Fox", hex: "#c7662a" },
  { name: "Chivalrous Walrus", hex: "#816558" },
  { name: "Choco Chic", hex: "#993311" },
  { name: "Chocoholic", hex: "#993300" },
  { name: "Chocolate", hex: "#d2691e" },
  { name: "Chocolate Bells", hex: "#775130" },
  { name: "Chocolate Castle", hex: "#452207" },
  { name: "Chocolate Chili", hex: "#ab4231" },
  { name: "Chocolate Covered", hex: "#8b4121" },
  { name: "Chocolate Escape", hex: "#623d2e" },
  { name: "Chocolate Explosion", hex: "#8e473b" },
  { name: "Chocolate Fantasies", hex: "#5c3612" },
  { name: "Chocolate Kiss", hex: "#3c1421" },
  { name: "Chocolate Lust", hex: "#993322" },
  { name: "Chocolate Magma", hex: "#7a463a" },
  { name: "Chocolate Pretzel", hex: "#60504b" },
  { name: "Chocolate Rain", hex: "#714f29" },
  { name: "Chocolate Rush", hex: "#4e1b0b" },
  { name: "Chocolate Temptation", hex: "#956e5f" },
  { name: "Chocolate Truffle", hex: "#612e35" },
  { name: "Chocolate Velvet", hex: "#7f7453" },
  { name: "Chorizo", hex: "#aa0011" },
  { name: "Choux à la Crème", hex: "#ebcf7d" },
  { name: "Christmas Red", hex: "#b01b2e" },
  { name: "Chrome White", hex: "#cac7b7" },
  { name: "Chubby Kiss", hex: "#b43548" },
  { name: "Chutney", hex: "#98594b" },
  { name: "Cigar", hex: "#7d4e38" },
  { name: "Cigarette Glow", hex: "#ee5500" },
  { name: "Cinder", hex: "#242a2e" },
  { name: "Cinderella", hex: "#fbd7cc" },
  { name: "Cinnamon", hex: "#d26911" },
  { name: "Cinnamon Buff", hex: "#ffbf6e" },
  { name: "Cinnapink", hex: "#a6646f" },
  { name: "Citadel", hex: "#748995" },
  { name: "Citrus", hex: "#9fb70a" },
  { name: "Citrus Splash", hex: "#ffc400" },
  { name: "City Dweller", hex: "#c0b9ac" },
  { name: "Clairvoyant", hex: "#480656" },
  { name: "Clam Up", hex: "#ebdbc1" },
  { name: "Classy Mauve", hex: "#bb99aa" },
  { name: "Clay", hex: "#b66a50" },
  { name: "Clear Sky", hex: "#8eccfe" },
  { name: "Clear Water", hex: "#aad5db" },
  { name: "Clementine", hex: "#e96e00" },
  { name: "Cloak and Dagger", hex: "#550055" },
  { name: "Cloak Grey", hex: "#605e63" },
  { name: "Clotted Cream", hex: "#f3efcd" },
  { name: "Cloud Break", hex: "#f6f1fe" },
  { name: "Cloud Dancer", hex: "#f0eee9" },
  { name: "Cloudless", hex: "#d6eafc" },
  { name: "Cloudy Valley", hex: "#b1c6d6" },
  { name: "Clover", hex: "#008f00" },
  { name: "Coalmine", hex: "#220033" },
  { name: "Cobalt", hex: "#030aa7" },
  { name: "Coca Mocha", hex: "#bd9d95" },
  { name: "Cockatoo", hex: "#58c8b6" },
  { name: "Coco Malt", hex: "#e4dcc9" },
  { name: "Coco Muck", hex: "#994a25" },
  { name: "Cocoa", hex: "#875f42" },
  { name: "Cocoloco", hex: "#aa8f7a" },
  { name: "Coconut", hex: "#965a3e" },
  { name: "Coconut Agony", hex: "#ebe8e7" },
  { name: "Coconut Macaroon", hex: "#dacac0" },
  { name: "Coconut Milk", hex: "#f0ede5" },
  { name: "Cocoon", hex: "#dedbcc" },
  { name: "Coffee", hex: "#6f4e37" },
  { name: "Coffee Adept", hex: "#775511" },
  { name: "Coffee Diva", hex: "#bea88d" },
  { name: "Cognac", hex: "#d48c46" },
  { name: "Coin Slot", hex: "#ff4411" },
  { name: "Cola", hex: "#3c2f23" },
  { name: "Cold and Dark", hex: "#154250" },
  { name: "Cold Blue", hex: "#88dddd" },
  { name: "Cold Canada", hex: "#dbfffe" },
  { name: "Cold Light of Day", hex: "#00eeee" },
  { name: "Cold Lips", hex: "#9ba0ef" },
  { name: "Cold Press Coffee", hex: "#6c2e09" },
  { name: "Cold Shoulder", hex: "#d4e0ef" },
  { name: "Cold Turkey", hex: "#cab5b2" },
  { name: "Cold Wave", hex: "#c2e2e3" },
  { name: "Cold White", hex: "#edfcfb" },
  { name: "Colonial White", hex: "#ffedbc" },
  { name: "Columbo's Coat", hex: "#d0cbce" },
  { name: "Communist", hex: "#cc0000" },
  { name: "Concord", hex: "#827f79" },
  { name: "Concrete", hex: "#d2d1cd" },
  { name: "Concrete Jungle", hex: "#999988" },
  { name: "Concrete Landscape", hex: "#5c606e" },
  { name: "Conifer", hex: "#b1dd52" },
  { name: "Conker", hex: "#b94e41" },
  { name: "Continental Waters", hex: "#98c6cb" },
  { name: "Cookie Crumb", hex: "#b19778" },
  { name: "Cookie Crust", hex: "#e3b258" },
  { name: "Cookie Dough", hex: "#ab7100" },
  { name: "Cool", hex: "#96b3b3" },
  { name: "Cool as a Cucumber", hex: "#c6d86b" },
  { name: "Cooler Than Ever", hex: "#77bbff" },
  { name: "Copacabana", hex: "#006c8d" },
  { name: "Copious Caramel", hex: "#d0851d" },
  { name: "Copper", hex: "#b87333" },
  { name: "Copper Coin", hex: "#da8a67" },
  { name: "Copper Hopper", hex: "#bf4000" },
  { name: "Copper Patina", hex: "#9db4a0" },
  { name: "Coquelicot", hex: "#ff3800" },
  { name: "Coral", hex: "#ff7f50" },
  { name: "Coral Commander", hex: "#ee6666" },
  { name: "Coral Kiss", hex: "#ffddc7" },
  { name: "Coral Red", hex: "#ff4040" },
  { name: "Coralistic", hex: "#ff917a" },
  { name: "Corbeau", hex: "#111122" },
  { name: "Corfu Waters", hex: "#008aad" },
  { name: "Cork Wood", hex: "#cc7744" },
  { name: "Corn", hex: "#fbec5d" },
  { name: "Cornflake", hex: "#f0e68c" },
  { name: "Cornsilk", hex: "#fff8dc" },
  { name: "Corona", hex: "#ffb437" },
  { name: "Corrosive Green", hex: "#54d905" },
  { name: "Cortex", hex: "#a99592" },
  { name: "Cosmic", hex: "#b8b9cb" },
  { name: "Cosmic Bit Flip", hex: "#001000" },
  { name: "Cosmic Explorer", hex: "#551155" },
  { name: "Cosmic Heart", hex: "#9601f4" },
  { name: "Cosmic Latte", hex: "#fff8e7" },
  { name: "Cotinga Purple", hex: "#340059" },
  { name: "Cotton Ball", hex: "#f2f7fd" },
  { name: "Cotton Boll", hex: "#e7effb" },
  { name: "Cotton Candy", hex: "#ffbcd9" },
  { name: "Cotton Candy Explosions", hex: "#dd22ff" },
  { name: "Cotton Field", hex: "#f2f0e8" },
  { name: "Countryside", hex: "#a4a404" },
  { name: "Court-Bouillon", hex: "#cecb97" },
  { name: "Couscous", hex: "#ffe29b" },
  { name: "Cousteau", hex: "#55a9d6" },
  { name: "Cover of Night", hex: "#494e4f" },
  { name: "Cow's Milk", hex: "#f1ede5" },
  { name: "Cowboy", hex: "#443736" },
  { name: "Coyote", hex: "#dc9b68" },
  { name: "Cozy Summer Sunset", hex: "#eb9f9f" },
  { name: "Cozy Wool", hex: "#d1b99b" },
  { name: "Cranberry", hex: "#9e003a" },
  { name: "Cranberry Splash", hex: "#da5265" },
  { name: "Crash Dummy", hex: "#eeee66" },
  { name: "Cream", hex: "#ffffc2" },
  { name: "Cream and Butter", hex: "#feeea5" },
  { name: "Cream and Sugar", hex: "#ddcfb9" },
  { name: "Cream Puff", hex: "#ffbb99" },
  { name: "Creamed Caramel", hex: "#b79c94" },
  { name: "Creamy", hex: "#efe8db" },
  { name: "Creamy Apricot", hex: "#ffe8bd" },
  { name: "Creamy Avocado", hex: "#d8f19c" },
  { name: "Creamy Berry", hex: "#debccd" },
  { name: "Creamy Cloud Dreams", hex: "#fff5e0" },
  { name: "Creamy Garlic", hex: "#ecefe3" },
  { name: "Creamy Ivory", hex: "#eeddaa" },
  { name: "Creamy Lemon", hex: "#fff0b2" },
  { name: "Creamy Mint", hex: "#aaffaa" },
  { name: "Creamy Peach", hex: "#f4a384" },
  { name: "Creamy Strawberry", hex: "#fcd2df" },
  { name: "Creamy Sweet Corn", hex: "#f7c34c" },
  { name: "Crème Brûlée", hex: "#ffe39b" },
  { name: "Crème de la Crème", hex: "#f3e7b4" },
  { name: "Crème Fraîche", hex: "#eceee6" },
  { name: "Creole", hex: "#393227" },
  { name: "Crepe", hex: "#d4bc94" },
  { name: "Crimson Boy", hex: "#b44933" },
  { name: "Crisps", hex: "#e2bd67" },
  { name: "Croissant", hex: "#c4ab86" },
  { name: "Crow", hex: "#180614" },
  { name: "Crown Jewel", hex: "#482d54" },
  { name: "Crown of Thorns", hex: "#763c33" },
  { name: "Crude Banana", hex: "#21c40e" },
  { name: "Crumbling Statue", hex: "#cabfb4" },
  { name: "Crunchy Carrot", hex: "#ea5013" },
  { name: "Crusade King", hex: "#dbc364" },
  { name: "Crushed Ice", hex: "#c4fff7" },
  { name: "Cry Me a River", hex: "#427898" },
  { name: "Cry of a Rose", hex: "#b23c5d" },
  { name: "Cryo Freeze", hex: "#ddece0" },
  { name: "Crystal", hex: "#a7d8de" },
  { name: "Crystal Gem", hex: "#79d0a7" },
  { name: "Crystal Lake", hex: "#88b5c4" },
  { name: "Crystal Palace", hex: "#d3cfab" },
  { name: "Cuba Libre", hex: "#73383c" },
  { name: "Cucumber", hex: "#006400" },
  { name: "Cucumber Bomber", hex: "#bbdd11" },
  { name: "Cucumber Milk", hex: "#c2f177" },
  { name: "Cumin", hex: "#a58459" },
  { name: "Cumulus", hex: "#f3f3e6" },
  { name: "Cupid", hex: "#f5b2c5" },
  { name: "Cupid's Eye", hex: "#ff22dd" },
  { name: "Curry", hex: "#d6a332" },
  { name: "Curry Bubbles", hex: "#f5b700" },
  { name: "Curry Sauce", hex: "#be9e6f" },
  { name: "Currywurst", hex: "#ddaa33" },
  { name: "Cursed Black", hex: "#131313" },
  { name: "Cute Crab", hex: "#dd4444" },
  { name: "Cuttlefish", hex: "#7fbbc2" },
  { name: "Cyan", hex: "#0ff0fe" },
  { name: "Cyber Neon Green", hex: "#00ff26" },
  { name: "Cyber Yellow", hex: "#ffd400" },
  { name: "Cyberpink", hex: "#ff2077" },
  { name: "Cypress", hex: "#545a3e" },
  { name: "Daffodil", hex: "#ffff31" },
  { name: "Dainty Peach", hex: "#ffcdb9" },
  { name: "Daisy Desi", hex: "#fcdf8a" },
  { name: "Dallas Dust", hex: "#ece0d6" },
  { name: "Dancing Sea", hex: "#1c4d8f" },
  { name: "Dandelion", hex: "#fedf08" },
  { name: "Danger", hex: "#ff0e0e" },
  { name: "Dangerous Affair", hex: "#d00220" },
  { name: "Dangerous Robot", hex: "#cbc5c6" },
  { name: "Dark", hex: "#1b2431" },
  { name: "Dark & Stormy", hex: "#353f51" },
  { name: "Dark Ages", hex: "#9698a3" },
  { name: "Dark as Night", hex: "#495252" },
  { name: "Dark Blue", hex: "#305679" },
  { name: "Dark Charcoal", hex: "#333232" },
  { name: "Dark Chocolate", hex: "#624a49" },
  { name: "Dark Crypt", hex: "#3f4551" },
  { name: "Dark Cyan", hex: "#008b8b" },
  { name: "Dark Denim", hex: "#005588" },
  { name: "Dark Dreams", hex: "#332266" },
  { name: "Dark Eclipse", hex: "#112244" },
  { name: "Dark Forest", hex: "#556962" },
  { name: "Dark Galaxy", hex: "#0018a8" },
  { name: "Dark Knight", hex: "#151931" },
  { name: "Dark Matter", hex: "#110101" },
  { name: "Dark Olive", hex: "#373e02" },
  { name: "Dark Orange", hex: "#c65102" },
  { name: "Dark Orchestra", hex: "#251b19" },
  { name: "Dark Pink", hex: "#cb416b" },
  { name: "Dark Prince", hex: "#6b6c89" },
  { name: "Dark Purple", hex: "#35063e" },
  { name: "Dark Red", hex: "#840000" },
  { name: "Dark Rift", hex: "#060b14" },
  { name: "Dark Rum", hex: "#45362b" },
  { name: "Dark Salmon Injustice", hex: "#e8957a" },
  { name: "Dark Sanctuary", hex: "#3f012c" },
  { name: "Dark Sapphire", hex: "#082567" },
  { name: "Dark Secret", hex: "#3e5361" },
  { name: "Dark Serpent", hex: "#113311" },
  { name: "Dark Soul", hex: "#112255" },
  { name: "Dark Souls", hex: "#a3a3a2" },
  { name: "Dark Space", hex: "#414a4c" },
  { name: "Dark Veil", hex: "#141311" },
  { name: "Dark Void", hex: "#151517" },
  { name: "Dark Wood", hex: "#855e42" },
  { name: "Darkest Dungeon", hex: "#660011" },
  { name: "Darkest Forest", hex: "#223311" },
  { name: "Darth Vader", hex: "#27252a" },
  { name: "Day On Mercury", hex: "#d5d2d1" },
  { name: "Dazzling Red", hex: "#d82c0d" },
  { name: "Dead Forest", hex: "#434b4f" },
  { name: "Dead Pixel", hex: "#3b3a3a" },
  { name: "Deadly Depths", hex: "#111144" },
  { name: "Deadly Yellow", hex: "#dead00" },
  { name: "Dear Darling", hex: "#a30112" },
  { name: "Dear Reader", hex: "#f5f3e6" },
  { name: "Death by Chocolate", hex: "#60443f" },
  { name: "Death of a Star", hex: "#e760d2" },
  { name: "Debian Red", hex: "#d70a53" },
  { name: "Decadial Pink", hex: "#decade" },
  { name: "Decreasing Brown", hex: "#987654" },
  { name: "Deep Forest", hex: "#37413a" },
  { name: "Deep Forestial Escapade", hex: "#335500" },
  { name: "Deep Fried", hex: "#f0b054" },
  { name: "Deep Fried Sun Rays", hex: "#f6c75e" },
  { name: "Deep Green", hex: "#02590f" },
  { name: "Deep Indigo", hex: "#4c567a" },
  { name: "Deep Lagoon", hex: "#005265" },
  { name: "Deep Night", hex: "#494c55" },
  { name: "Deep Pond", hex: "#014420" },
  { name: "Deep Saffron", hex: "#ff9932" },
  { name: "Deep Sea Base", hex: "#2c2c57" },
  { name: "Deep Sea Diver", hex: "#255c61" },
  { name: "Deep Sea Nightmare", hex: "#002366" },
  { name: "Deep Sky Blue", hex: "#0d75f8" },
  { name: "Deep Space Rodeo", hex: "#332277" },
  { name: "Deeply Embarrassed", hex: "#ecb2b3" },
  { name: "Deepsea Kraken", hex: "#082599" },
  { name: "Deer", hex: "#ba8759" },
  { name: "Delayed Yellow", hex: "#fdf901" },
  { name: "Deli Yellow", hex: "#e8b523" },
  { name: "Delicate Cloud", hex: "#dddfe8" },
  { name: "Delicate Ice", hex: "#b7d2e3" },
  { name: "Delicate Lemon", hex: "#eedd77" },
  { name: "Delicate Seashell", hex: "#ffefdd" },
  { name: "Délicieux au Chocolat", hex: "#412010" },
  { name: "Delightful Pastry", hex: "#f9e7c8" },
  { name: "Delta Mint", hex: "#c5e6cf" },
  { name: "Demeter Green", hex: "#02cc02" },
  { name: "Demonic Kiss", hex: "#d02b48" },
  { name: "Denim", hex: "#2243b6" },
  { name: "Densetsu Green", hex: "#889911" },
  { name: "Desert", hex: "#ccad60" },
  { name: "Desert Dessert", hex: "#ffba6b" },
  { name: "Desert Dune", hex: "#b5ab9c" },
  { name: "Desert Locust", hex: "#a9a450" },
  { name: "Desert Temple", hex: "#ddcc99" },
  { name: "Deserted Beach", hex: "#e7dbbf" },
  { name: "Desirable", hex: "#a93435" },
  { name: "Desire", hex: "#ea3c53" },
  { name: "Desired Dawn", hex: "#d8d7d9" },
  { name: "Detective Coat", hex: "#8b8685" },
  { name: "Devil's Advocate", hex: "#ff3344" },
  { name: "Deviled Eggs", hex: "#fecd82" },
  { name: "Devilish", hex: "#dd3322" },
  { name: "Devilish Diva", hex: "#ce7790" },
  { name: "Diamond", hex: "#faf7e2" },
  { name: "Diamond Cut", hex: "#e9e9f0" },
  { name: "Diamond White", hex: "#e2eff3" },
  { name: "Diesel", hex: "#322c2b" },
  { name: "Dijon Mustard", hex: "#e2ca73" },
  { name: "Dill", hex: "#6f7755" },
  { name: "Dim", hex: "#c8c2be" },
  { name: "Dinosaur Egg", hex: "#cabaa9" },
  { name: "Dipped in Cream", hex: "#fcf6eb" },
  { name: "Dire Wolf", hex: "#282828" },
  { name: "Disappearing Memories", hex: "#eae3e0" },
  { name: "Disco Ball", hex: "#d4d4d4" },
  { name: "Discreet Orange", hex: "#ffad98" },
  { name: "Discrete Pink", hex: "#ebdbdd" },
  { name: "Distant Cloud", hex: "#e5eae6" },
  { name: "Distant Homeworld", hex: "#acdcee" },
  { name: "Distant Landscape", hex: "#e1efdd" },
  { name: "Diva", hex: "#c9a0ff" },
  { name: "Diva Mecha", hex: "#ee99ee" },
  { name: "Diver's Eden", hex: "#3a797e" },
  { name: "Divine Pleasure", hex: "#f4efe1" },
  { name: "Dockside Red", hex: "#813533" },
  { name: "Doctor", hex: "#f9f9f9" },
  { name: "Dogwood", hex: "#faeae2" },
  { name: "Dolce Pink", hex: "#f0d9e0" },
  { name: "Dollar Bill", hex: "#85bb65" },
  { name: "Dolly", hex: "#f5f171" },
  { name: "Dolly Cheek", hex: "#fcc9b6" },
  { name: "Dolphin", hex: "#86c4da" },
  { name: "Don't Be Shy", hex: "#ed2c1a" },
  { name: "Donegal Green", hex: "#115500" },
  { name: "Döner Kebab", hex: "#bb7766" },
  { name: "Donkey Kong", hex: "#ab4210" },
  { name: "Dorn Yellow", hex: "#fff200" },
  { name: "Double Cream", hex: "#f3e0ac" },
  { name: "Dove", hex: "#b3ada7" },
  { name: "Dove Wing", hex: "#d7d9d5" },
  { name: "Down Feathers", hex: "#fff9e7" },
  { name: "Dr Who", hex: "#78587d" },
  { name: "Dr. White", hex: "#fafafa" },
  { name: "Dragon Ball", hex: "#ff9922" },
  { name: "Dragon Fire", hex: "#fc642d" },
  { name: "Dragon Fruit", hex: "#d75969" },
  { name: "Dragon's Blood", hex: "#b84048" },
  { name: "Dragon's Breath", hex: "#d41003" },
  { name: "Dragon's Gold", hex: "#e7e04e" },
  { name: "Dragonfly", hex: "#314a76" },
  { name: "Drama Queen", hex: "#a37298" },
  { name: "Dramatic Blue", hex: "#240093" },
  { name: "Dream of Spring", hex: "#f7cf26" },
  { name: "Dream Setting", hex: "#ff77bb" },
  { name: "Dream Vapor", hex: "#cc99ee" },
  { name: "Dreamless Sleep", hex: "#111111" },
  { name: "Dreamy Candy Forest", hex: "#b195e4" },
  { name: "Dried Tomatoes", hex: "#ab6057" },
  { name: "Driftwood", hex: "#a67a45" },
  { name: "Drip Coffee", hex: "#7a280a" },
  { name: "Dripping Wisteria", hex: "#bb99bb" },
  { name: "Droplet", hex: "#aaddff" },
  { name: "Dropped Brick", hex: "#bb3300" },
  { name: "Drover", hex: "#fbeb9b" },
  { name: "Drunk-Tank Pink", hex: "#dd11dd" },
  { name: "Drunken Flamingo", hex: "#ff55cc" },
  { name: "Dry Bone", hex: "#eadfce" },
  { name: "Dry Rose", hex: "#c22f4d" },
  { name: "Duck Butter", hex: "#ddc75b" },
  { name: "Duck Hunt", hex: "#005800" },
  { name: "Duckling Fluff", hex: "#fafc5d" },
  { name: "Dumpling", hex: "#f7ddaa" },
  { name: "Dune", hex: "#d5c0a1" },
  { name: "Dungeon Keeper", hex: "#ef3038" },
  { name: "Durian White", hex: "#e6d0ab" },
  { name: "Dusk", hex: "#4e5481" },
  { name: "Dusky Mood", hex: "#979ba8" },
  { name: "Dust of the Moon", hex: "#cfc9df" },
  { name: "Dust Storm", hex: "#e7d3b7" },
  { name: "Dust to Dust", hex: "#bbbcbc" },
  { name: "Dusty Boots", hex: "#f3c090" },
  { name: "Dusty Chimney", hex: "#888899" },
  { name: "Dusty Duchess", hex: "#b18377" },
  { name: "Dwarf Fortress", hex: "#1d0200" },
  { name: "Dwarven Bronze", hex: "#bf652e" },
  { name: "Dwindling Dandelion", hex: "#f9e9d7" },
  { name: "Dying Storm Blue", hex: "#111166" },
  { name: "Dynamite", hex: "#ff4422" },
  { name: "Dynasty Green", hex: "#008e80" },
  { name: "Eagle", hex: "#a26c36" },
  { name: "Earl Grey", hex: "#a6978a" },
  { name: "Earthbound", hex: "#a48a80" },
  { name: "Earthworm", hex: "#c3816e" },
  { name: "Easter Egg", hex: "#919bc9" },
  { name: "Eat Your Greens", hex: "#696845" },
  { name: "Ebi Brown", hex: "#773c30" },
  { name: "Ebony", hex: "#313337" },
  { name: "Eclipse", hex: "#3f3939" },
  { name: "Ecological", hex: "#677f70" },
  { name: "Ecstatic Red", hex: "#aa1122" },
  { name: "Edamame", hex: "#9ca389" },
  { name: "EGA Green", hex: "#01ff07" },
  { name: "Egg Toast", hex: "#f2c911" },
  { name: "Egg Yolk", hex: "#ffce81" },
  { name: "Eggnog", hex: "#fdea9f" },
  { name: "Eggplant", hex: "#430541" },
  { name: "Eggshell", hex: "#f0ead6" },
  { name: "Eiffel Tower", hex: "#998e83" },
  { name: "Eigengrau", hex: "#16161d" },
  { name: "Eight Ball", hex: "#03050a" },
  { name: "Elastic Pink", hex: "#eca6ca" },
  { name: "Elden Ring Orange", hex: "#ed8a09" },
  { name: "Electra", hex: "#55b492" },
  { name: "Electric Banana", hex: "#fbff00" },
  { name: "Electric Eel", hex: "#88bbee" },
  { name: "Electric Laser Lime", hex: "#26ff2a" },
  { name: "Electric Yellow", hex: "#fffc00" },
  { name: "Electrifying Kiss", hex: "#d41c4e" },
  { name: "Elegant Purple Gown", hex: "#552367" },
  { name: "Elephant in the Room", hex: "#a8a9a8" },
  { name: "Elite Teal", hex: "#133337" },
  { name: "Embarrassed", hex: "#ee7799" },
  { name: "Embarrassed Frog", hex: "#996611" },
  { name: "Embarrassment", hex: "#ff7777" },
  { name: "Emerald", hex: "#028f1e" },
  { name: "Emerald Forest", hex: "#224347" },
  { name: "Emerald Glitter", hex: "#66bb00" },
  { name: "Emerald Ice Palace", hex: "#2af589" },
  { name: "Emergency", hex: "#911911" },
  { name: "Eminence", hex: "#6e3974" },
  { name: "Emoji Yellow", hex: "#ffde34" },
  { name: "Emperor Jade", hex: "#007b75" },
  { name: "Empress", hex: "#7c7173" },
  { name: "Emptiness", hex: "#fcfdfc" },
  { name: "Enchanted Lavender", hex: "#bfa3d9" },
  { name: "Enchanting Ivy", hex: "#315955" },
  { name: "End of Summer", hex: "#cc8f15" },
  { name: "Endive", hex: "#cee1c8" },
  { name: "Endless Galaxy", hex: "#000044" },
  { name: "Endless River", hex: "#567aad" },
  { name: "Endless Summer", hex: "#f7cf00" },
  { name: "Endo", hex: "#5da464" },
  { name: "English Breakfast", hex: "#441111" },
  { name: "English Manor", hex: "#7181a4" },
  { name: "English Walnut", hex: "#3e2b23" },
  { name: "Engulfed in Light", hex: "#f5f3e9" },
  { name: "Enoki", hex: "#f8faee" },
  { name: "Enraged", hex: "#ee0044" },
  { name: "Envy", hex: "#8ba58f" },
  { name: "Eosin Pink", hex: "#ff5ec4" },
  { name: "Ephemeral Blue", hex: "#cbd4df" },
  { name: "Ephemeral Red", hex: "#e4cfd7" },
  { name: "Epic Blue", hex: "#0066ee" },
  { name: "Epink", hex: "#dd33ff" },
  { name: "Equanimity", hex: "#83a9b3" },
  { name: "Equestrienne", hex: "#a07569" },
  { name: "Errigal White", hex: "#f2f2f4" },
  { name: "Escalope", hex: "#cc8866" },
  { name: "Escargot", hex: "#fff1d8" },
  { name: "Espresso", hex: "#4e312d" },
  { name: "Espresso Bar", hex: "#5b3f34" },
  { name: "Espresso Crema", hex: "#d09c43" },
  { name: "Espresso Macchiato", hex: "#4f4744" },
  { name: "Espresso Martini", hex: "#8c3a00" },
  { name: "Estragon", hex: "#a5af76" },
  { name: "Eternal Flame", hex: "#a13f49" },
  { name: "Eternal Summer", hex: "#f7e504" },
  { name: "Eternal Winter", hex: "#9cfaff" },
  { name: "Ether", hex: "#9eb6b8" },
  { name: "Ethereal Woods", hex: "#3e5e4e" },
  { name: "Eucalyptus", hex: "#329760" },
  { name: "Evening Glow", hex: "#fdd792" },
  { name: "Everglade", hex: "#264334" },
  { name: "Evergreen", hex: "#11574a" },
  { name: "Everlasting Ice", hex: "#f6fdfa" },
  { name: "Evil Cigar", hex: "#522000" },
  { name: "Evil Forces", hex: "#770022" },
  { name: "Excalibur", hex: "#676168" },
  { name: "Exclusive Elixir", hex: "#f9f1dd" },
  { name: "Exclusive Ivory", hex: "#e2d8c3" },
  { name: "Exit Light", hex: "#55bb33" },
  { name: "Exotic Escape", hex: "#96d9df" },
  { name: "Exotic Lilac", hex: "#d198b5" },
  { name: "Exploding Star", hex: "#fed83a" },
  { name: "Explorer of the Galaxies", hex: "#3a1f76" },
  { name: "Explosive Grey", hex: "#c4c4c4" },
  { name: "Explosive Purple", hex: "#cc11bb" },
  { name: "Extra Fuchsia", hex: "#ef347c" },
  { name: "Extra Life", hex: "#6ab417" },
  { name: "Extraviolet", hex: "#661188" },
  { name: "Eyeball", hex: "#fffbf8" },
  { name: "Fabric of Love", hex: "#aa1177" },
  { name: "Fabric of Space", hex: "#341758" },
  { name: "Fabulous Fawn", hex: "#e5c1a3" },
  { name: "Fabulous Fuchsia", hex: "#ee1188" },
  { name: "Faded Letter", hex: "#bfac86" },
  { name: "Fading Horizon", hex: "#442266" },
  { name: "Fading Love", hex: "#c973a2" },
  { name: "Fading Night", hex: "#3377cc" },
  { name: "Fail Whale", hex: "#99ccee" },
  { name: "Faint Gold", hex: "#b59410" },
  { name: "Fairy Dust", hex: "#ffe8f4" },
  { name: "Fairy Tale", hex: "#f2c1d1" },
  { name: "Fairy Tale Green", hex: "#88cc55" },
  { name: "Fake Blonde", hex: "#efe6c1" },
  { name: "Fake Jade", hex: "#13eac9" },
  { name: "Fake Love", hex: "#cc77ee" },
  { name: "Falafel", hex: "#aa7711" },
  { name: "Fallen Blossoms", hex: "#edb2c4" },
  { name: "Fallen Petals", hex: "#f2e0da" },
  { name: "Fanatic Fuchsia", hex: "#ee1199" },
  { name: "Fancy Fuchsia", hex: "#ff0088" },
  { name: "Fancy Red Wine", hex: "#b40441" },
  { name: "Fandango", hex: "#b53389" },
  { name: "Fantasy Romance", hex: "#e83a72" },
  { name: "Farmer's Market", hex: "#8f917c" },
  { name: "Fat Gold", hex: "#e6bc00" },
  { name: "Fat Smooch", hex: "#c1537d" },
  { name: "Fatal Fury", hex: "#da321c" },
  { name: "Fatback", hex: "#fff7ed" },
  { name: "Fatty Fuchsia", hex: "#ee0077" },
  { name: "Fatty Sashimi", hex: "#eec4b4" },
  { name: "Fawn", hex: "#cfaf7b" },
  { name: "Feasty Fuchsia", hex: "#ee0088" },
  { name: "Featherbed", hex: "#afcbe5" },
  { name: "Federation of Love", hex: "#b71010" },
  { name: "Fedora", hex: "#625665" },
  { name: "Feijoa", hex: "#a5d785" },
  { name: "Feminism", hex: "#9d5783" },
  { name: "Femme Fatale", hex: "#948593" },
  { name: "Fennec Fox", hex: "#dad7c8" },
  { name: "Fennel Fiasco", hex: "#00aa44" },
  { name: "Fennel Fiesta", hex: "#00bb77" },
  { name: "Fennel Flower", hex: "#77aaff" },
  { name: "Fennelly", hex: "#9a9e80" },
  { name: "Fern", hex: "#548d44" },
  { name: "Ferntastic", hex: "#71ab62" },
  { name: "Ferocious", hex: "#e2261f" },
  { name: "Ferocious Flamingo", hex: "#ee00cc" },
  { name: "Ferocious Fox", hex: "#e25d1b" },
  { name: "Ferocious Fuchsia", hex: "#aa00cc" },
  { name: "Fertility Green", hex: "#66fc00" },
  { name: "Festive Bordeaux", hex: "#6e0f12" },
  { name: "Festive Ferret", hex: "#dfdfe5" },
  { name: "Feta", hex: "#dbe0d0" },
  { name: "Fever Dream", hex: "#dd5577" },
  { name: "Feverish", hex: "#dd6677" },
  { name: "Feverish Passion", hex: "#de4d7b" },
  { name: "Fibonacci Blue", hex: "#112358" },
  { name: "Ficus", hex: "#3b593a" },
  { name: "Ficus Elastica", hex: "#006131" },
  { name: "Fiddle-Leaf Fig", hex: "#a6c875" },
  { name: "Fiery Glow", hex: "#f0531c" },
  { name: "Fiesta", hex: "#edd8d2" },
  { name: "Fiji Sands", hex: "#d8caa9" },
  { name: "Film Noir", hex: "#473933" },
  { name: "Final Departure", hex: "#f1f5db" },
  { name: "Fine Pine", hex: "#008800" },
  { name: "Finger Banana", hex: "#e1c12f" },
  { name: "Finnish Fiord", hex: "#5db0be" },
  { name: "Fiord", hex: "#4b5a62" },
  { name: "Fire", hex: "#8f3f2a" },
  { name: "Fire Ant", hex: "#be6400" },
  { name: "Fire Bolt", hex: "#cc4411" },
  { name: "Fire Engine", hex: "#fe0002" },
  { name: "Fire Hydrant", hex: "#ff0d00" },
  { name: "Fireball", hex: "#ce2029" },
  { name: "Firebug", hex: "#cd5c51" },
  { name: "Firecracker", hex: "#f36944" },
  { name: "Firefly Glow", hex: "#fff3a1" },
  { name: "Firewatch", hex: "#ee8866" },
  { name: "First Crush", hex: "#f6e2ea" },
  { name: "First Day of Summer", hex: "#f1e798" },
  { name: "First Love", hex: "#cf758a" },
  { name: "First Snow", hex: "#e8eff8" },
  { name: "Fish Bone", hex: "#e4d9c5" },
  { name: "Fish Boy", hex: "#11dddd" },
  { name: "Fish Ceviche", hex: "#e1e1d5" },
  { name: "Fish Pond", hex: "#86c8ed" },
  { name: "Fisher King", hex: "#5182b9" },
  { name: "Fist of the North Star", hex: "#225599" },
  { name: "Five Star", hex: "#ffaa4a" },
  { name: "Fizz", hex: "#b1dbaa" },
  { name: "Fizzy Peach", hex: "#f7bc5c" },
  { name: "Flamboyant", hex: "#f73d37" },
  { name: "Flamboyant Flamingo", hex: "#f74480" },
  { name: "Flame", hex: "#e25822" },
  { name: "Flame Seal", hex: "#f4e25a" },
  { name: "Flamenco", hex: "#ea8645" },
  { name: "Flaming Cauldron", hex: "#f6a374" },
  { name: "Flaming Flamingo", hex: "#dd55ff" },
  { name: "Flaming Hot Flamingoes", hex: "#ff005d" },
  { name: "Flaming June", hex: "#eebb66" },
  { name: "Flaming Orange", hex: "#ee6633" },
  { name: "Flamingo Queen", hex: "#cc33ff" },
  { name: "Flan", hex: "#f6e3b4" },
  { name: "Flash in the Pan", hex: "#ff9977" },
  { name: "Flashlight", hex: "#f9eed6" },
  { name: "Flattered Flamingo", hex: "#ee6655" },
  { name: "Fleur de Sel Caramel", hex: "#da8704" },
  { name: "Fleur-De-Lis", hex: "#b090c7" },
  { name: "Flickering Firefly", hex: "#f8f6e6" },
  { name: "Flickering Light", hex: "#fff1dc" },
  { name: "Flickr Pink", hex: "#fb0081" },
  { name: "Flint Rock", hex: "#989493" },
  { name: "Flip a Coin", hex: "#ccddcc" },
  { name: "Flip-Flop", hex: "#f2c4a7" },
  { name: "Flirt", hex: "#7a2e4d" },
  { name: "Flirtatious Flamingo", hex: "#cc22ff" },
  { name: "Flirty Rose", hex: "#d65e93" },
  { name: "Flirty Salmon", hex: "#fa7069" },
  { name: "Floating Feather", hex: "#e9d8c2" },
  { name: "Flood", hex: "#6677bb" },
  { name: "Flora", hex: "#73fa79" },
  { name: "Florida's Alligator", hex: "#664422" },
  { name: "Fluffy Duckling", hex: "#fcdf39" },
  { name: "Fluorescence", hex: "#89d178" },
  { name: "Fluorescent Green", hex: "#08ff08" },
  { name: "Fluorescent Pink", hex: "#fe1493" },
  { name: "Flush Orange", hex: "#ff6f01" },
  { name: "Fly a Kite", hex: "#c8daf5" },
  { name: "Fly Away", hex: "#85b3f3" },
  { name: "Fly by Night", hex: "#1c1e4d" },
  { name: "Fly-by-Night", hex: "#495a67" },
  { name: "Flying Carpet", hex: "#787489" },
  { name: "Flying Fish Blue", hex: "#024aca" },
  { name: "Fog", hex: "#d6d7d2" },
  { name: "Foggy Day", hex: "#e7e3db" },
  { name: "Foggy Love", hex: "#d5c7e8" },
  { name: "Foggy Plateau", hex: "#cfcbe5" },
  { name: "Fogtown", hex: "#eef0e7" },
  { name: "Foil", hex: "#c0c3c4" },
  { name: "Foliage", hex: "#95b388" },
  { name: "Fool's Gold", hex: "#cad175" },
  { name: "Forbidden Fruit", hex: "#fe7b7c" },
  { name: "Force of Nature", hex: "#d5ce69" },
  { name: "Forest", hex: "#0b5509" },
  { name: "Forest Empress", hex: "#3d7016" },
  { name: "Forester", hex: "#9aa77c" },
  { name: "Forestial", hex: "#007733" },
  { name: "Forestial Outpost", hex: "#556611" },
  { name: "Forestry", hex: "#2f441f" },
  { name: "Forget-Me-Not", hex: "#0087bd" },
  { name: "Forgiven Sin", hex: "#ff1199" },
  { name: "Forgotten Mosque", hex: "#e2d9db" },
  { name: "Forgotten Sandstone", hex: "#afa696" },
  { name: "Fortune Cookie", hex: "#e0c5a1" },
  { name: "Fossil Stone", hex: "#e3ddcc" },
  { name: "Foundation White", hex: "#efeeff" },
  { name: "Four Leaf Clover", hex: "#738f5d" },
  { name: "Fox", hex: "#ca4e33" },
  { name: "Foxy Pink", hex: "#db95ab" },
  { name: "Frail Fuchsia", hex: "#ee88ee" },
  { name: "Framboise", hex: "#e40058" },
  { name: "Frankenstein", hex: "#7ba05b" },
  { name: "Frappé", hex: "#d1b7a0" },
  { name: "Frappé au Chocolat", hex: "#9a6840" },
  { name: "Free Spirit", hex: "#deeeed" },
  { name: "Freefall", hex: "#565266" },
  { name: "Freeze Up", hex: "#dee9f4" },
  { name: "Freezing Vapor", hex: "#d4e9f5" },
  { name: "Freezy Breezy", hex: "#99eeee" },
  { name: "French Blue", hex: "#0072bb" },
  { name: "French Fry", hex: "#ebc263" },
  { name: "French Oak", hex: "#bb9e7c" },
  { name: "French Porcelain", hex: "#f6f4f6" },
  { name: "French Wine", hex: "#ac1e44" },
  { name: "French Winery", hex: "#991133" },
  { name: "Fresco", hex: "#f4dbd9" },
  { name: "Fresh Air", hex: "#a6e7ff" },
  { name: "Fresh Blue of Bel Air", hex: "#069af3" },
  { name: "Fresh Breeze", hex: "#beeddc" },
  { name: "Fresh Cut Grass", hex: "#91cb7d" },
  { name: "Fresh Gum", hex: "#ffaadd" },
  { name: "Fresh Snow", hex: "#f6efe1" },
  { name: "Freshly Roasted Coffee", hex: "#663322" },
  { name: "Fricassée", hex: "#ffe6c2" },
  { name: "Friendly Frost", hex: "#bffbff" },
  { name: "Frog", hex: "#58bc08" },
  { name: "Frog on a Log", hex: "#8fb943" },
  { name: "Frog Pond", hex: "#73b683" },
  { name: "Frog Prince", hex: "#bbd75a" },
  { name: "Frogger", hex: "#8cd612" },
  { name: "Froggy Pond", hex: "#7fba9e" },
  { name: "Frost", hex: "#e1e4c5" },
  { name: "Frost Fairy", hex: "#bbcfef" },
  { name: "Frostbite", hex: "#acfffc" },
  { name: "Frosted Blueberries", hex: "#0055dd" },
  { name: "Frosted Mint Hills", hex: "#ccffc2" },
  { name: "Frothy Milk", hex: "#faede6" },
  { name: "Frozen Boubble", hex: "#00eedd" },
  { name: "Frozen Civilization", hex: "#e1f5e5" },
  { name: "Frozen Forest", hex: "#cfe8b6" },
  { name: "Frozen Landscape", hex: "#aee4ff" },
  { name: "Frozen Mammoth", hex: "#dfd9da" },
  { name: "Frozen Periwinkle", hex: "#c9d1ef" },
  { name: "Frozen Salmon", hex: "#fea993" },
  { name: "Frozen Tomato", hex: "#dd5533" },
  { name: "Frozen Turquoise", hex: "#53f6ff" },
  { name: "Frozen Wave", hex: "#56acca" },
  { name: "Fruit of Passion", hex: "#946985" },
  { name: "Fruity Licious", hex: "#f69092" },
  { name: "Fuchsia", hex: "#ed0dd9" },
  { name: "Fuchsia Felicity", hex: "#f44772" },
  { name: "Fuchsia Fever", hex: "#ff5599" },
  { name: "Fuchsia Nebula", hex: "#7722aa" },
  { name: "Fuchsia Pheromone", hex: "#9f4cb7" },
  { name: "Fuego", hex: "#ee5533" },
  { name: "Fugitive Flamingo", hex: "#ee66aa" },
  { name: "Fuji Peak", hex: "#f6eee2" },
  { name: "Full Moon", hex: "#f4f3e0" },
  { name: "Funki Porcini", hex: "#ee9999" },
  { name: "Funky Frog", hex: "#98bd3c" },
  { name: "Furious Fox", hex: "#e35519" },
  { name: "Furious Frog", hex: "#55ee00" },
  { name: "Furious Fuchsia", hex: "#ee2277" },
  { name: "Furious Red", hex: "#ff1100" },
  { name: "Furious Tiger", hex: "#ea5814" },
  { name: "Furnace", hex: "#dd4124" },
  { name: "Furry Lion", hex: "#f09338" },
  { name: "Fusilli", hex: "#f1e8d6" },
  { name: "Fusion Red", hex: "#ff6163" },
  { name: "Futon", hex: "#edf6db" },
  { name: "Fuzzy Duckling", hex: "#ffea70" },
  { name: "Fuzzy Sheep", hex: "#f0e9d1" },
  { name: "Fuzzy Wuzzy", hex: "#cc6666" },
  { name: "Galactic Civilization", hex: "#442288" },
  { name: "Galactic Cruise", hex: "#111188" },
  { name: "Galactic Federation", hex: "#330077" },
  { name: "Galactic Highway", hex: "#3311bb" },
  { name: "Galaxy Blue", hex: "#2a4b7c" },
  { name: "Galaxy Express", hex: "#444499" },
  { name: "Gale of the Wind", hex: "#007844" },
  { name: "Gallant Green", hex: "#99aa66" },
  { name: "Gameboy Screen", hex: "#8bac0f" },
  { name: "Gangsters Gold", hex: "#ffdd22" },
  { name: "Garden Goddess", hex: "#99cea0" },
  { name: "Garden Snail", hex: "#cdb1ab" },
  { name: "Garden Weed", hex: "#786e38" },
  { name: "Garfield", hex: "#a75429" },
  { name: "Gargoyle", hex: "#abb39e" },
  { name: "Garlic Butter", hex: "#eddf5e" },
  { name: "Garlic Clove", hex: "#e2d7c1" },
  { name: "Garlic Toast", hex: "#dddd88" },
  { name: "Gateway Grey", hex: "#a0a09c" },
  { name: "Gatsby Glitter", hex: "#eed683" },
  { name: "Gauntlet Grey", hex: "#78736e" },
  { name: "Gazelle", hex: "#947e68" },
  { name: "Gecko", hex: "#9d913c" },
  { name: "Gecko's Dream", hex: "#669900" },
  { name: "Genie", hex: "#3e4364" },
  { name: "Gentian Blue", hex: "#312297" },
  { name: "Gentle Caress", hex: "#fcd7ba" },
  { name: "Gentle Cold", hex: "#c3ece9" },
  { name: "Gentle Frost", hex: "#dce0cd" },
  { name: "Gentle Glow", hex: "#f6e5b9" },
  { name: "Gentle Sky", hex: "#99bdd2" },
  { name: "Georgia Peach", hex: "#f97272" },
  { name: "German Hop", hex: "#89ac27" },
  { name: "Getting Wet", hex: "#c3dae3" },
  { name: "Ghost", hex: "#c0bfc7" },
  { name: "Ghost Pepper", hex: "#c10102" },
  { name: "Ghost Whisperer", hex: "#cbd1d0" },
  { name: "Ghost White", hex: "#f8f8ff" },
  { name: "Ghosted", hex: "#e2e0dc" },
  { name: "Ghoul", hex: "#667744" },
  { name: "Giggle", hex: "#eff0d3" },
  { name: "Gin", hex: "#d9dfcd" },
  { name: "Gin Fizz", hex: "#f8eaca" },
  { name: "Gin Tonic", hex: "#ecebe5" },
  { name: "Ginger", hex: "#b06500" },
  { name: "Ginger Ale", hex: "#c9a86a" },
  { name: "Ginger Beer", hex: "#c27f38" },
  { name: "Ginger Dough", hex: "#b06d3b" },
  { name: "Ginger Lemon Tea", hex: "#ffffaa" },
  { name: "Ginger Scent", hex: "#cb8f7b" },
  { name: "Gingerbread", hex: "#8c4a2f" },
  { name: "Gingerbread Crumble", hex: "#9c5e33" },
  { name: "Gingerbread House", hex: "#ca994e" },
  { name: "Giraffe", hex: "#fefe33" },
  { name: "Girl Power", hex: "#d39bcb" },
  { name: "Girlie", hex: "#ffd3cf" },
  { name: "Glacial Ice", hex: "#eae9e7" },
  { name: "Glacier", hex: "#78b1bf" },
  { name: "Glamour Pink", hex: "#ff1dcd" },
  { name: "Glamour White", hex: "#fffcec" },
  { name: "Glaucous Green", hex: "#b3e8c2" },
  { name: "Glazed Chestnut", hex: "#967217" },
  { name: "Glazed Sugar", hex: "#ffdccc" },
  { name: "Glimpse into Space", hex: "#121210" },
  { name: "Glimpse of Void", hex: "#335588" },
  { name: "Glistening Dawn", hex: "#f6ba25" },
  { name: "Glitter is not Gold", hex: "#fedc57" },
  { name: "Glitter Lake", hex: "#44bbff" },
  { name: "Glitter Shower", hex: "#88ffff" },
  { name: "Glorious Green Glitter", hex: "#aaee11" },
  { name: "Glorious Sunset", hex: "#f88517" },
  { name: "Glossy Black", hex: "#110011" },
  { name: "Glow in the Dark", hex: "#befdb7" },
  { name: "Glow Worm", hex: "#bed565" },
  { name: "Glowing Lantern", hex: "#fbb736" },
  { name: "Glowing Meteor", hex: "#ee4400" },
  { name: "Glowlight", hex: "#fff6b9" },
  { name: "Go Go Green", hex: "#008a7d" },
  { name: "Goblin Green", hex: "#76ff7a" },
  { name: "God of Rain", hex: "#4466cc" },
  { name: "Goddess", hex: "#d0e1e8" },
  { name: "Godzilla", hex: "#3c4d03" },
  { name: "Goku Orange", hex: "#f0833a" },
  { name: "Gold", hex: "#ffd700" },
  { name: "Gold Buttercup", hex: "#ffe8bb" },
  { name: "Gold Digger", hex: "#d1b075" },
  { name: "Gold Grillz", hex: "#ece086" },
  { name: "Gold Rush", hex: "#c4a777" },
  { name: "Gold Tooth", hex: "#dbb40c" },
  { name: "Gold Vein", hex: "#d6b956" },
  { name: "Gold Winged", hex: "#e6d682" },
  { name: "Golden Blood", hex: "#ff1155" },
  { name: "Golden Boy", hex: "#ffdd44" },
  { name: "Golden Churro", hex: "#f4ce74" },
  { name: "Golden Coin", hex: "#fcd975" },
  { name: "Golden Frame", hex: "#e2b31b" },
  { name: "Golden Ginkgo", hex: "#f9f525" },
  { name: "Golden Glitter Storm", hex: "#ead771" },
  { name: "Golden Harvest", hex: "#cccc11" },
  { name: "Golden Hour", hex: "#f1b457" },
  { name: "Golden Kingdom", hex: "#e0c84b" },
  { name: "Golden Lion", hex: "#f3ca6c" },
  { name: "Golden Mist", hex: "#d5cd94" },
  { name: "Golden Nugget", hex: "#db9b59" },
  { name: "Golden Opportunity", hex: "#f7c070" },
  { name: "Golden Period", hex: "#fedb2d" },
  { name: "Golden Relic", hex: "#e8ce49" },
  { name: "Golden Retriever", hex: "#eedec7" },
  { name: "Golden Sprinkles", hex: "#f6d263" },
  { name: "Golden Talisman", hex: "#e9c89b" },
  { name: "Goldfinch", hex: "#f8dc6c" },
  { name: "Goldfinger", hex: "#eebb11" },
  { name: "Goldfish", hex: "#f2ad62" },
  { name: "Goldie", hex: "#c89d3f" },
  { name: "Goldilocks", hex: "#fff39a" },
  { name: "Golem", hex: "#836e59" },
  { name: "Golf Course", hex: "#5a9e4b" },
  { name: "Good Karma", hex: "#333c76" },
  { name: "Good Luck", hex: "#86c994" },
  { name: "Good Morning", hex: "#fcfcda" },
  { name: "Good Night!", hex: "#46565f" },
  { name: "Goose Bill", hex: "#ffba80" },
  { name: "Gory Red", hex: "#a30800" },
  { name: "Gossip", hex: "#9fd385" },
  { name: "Gotham", hex: "#807872" },
  { name: "Gothic", hex: "#698890" },
  { name: "Gothic Gold", hex: "#bb852f" },
  { name: "Gourmet Honey", hex: "#e3cba8" },
  { name: "Grain of Salt", hex: "#d8dbe1" },
  { name: "Gramps Shoehorn", hex: "#a3896c" },
  { name: "Grand Bleu", hex: "#015482" },
  { name: "Grand Casino Gold", hex: "#edcd62" },
  { name: "Grand Sunset", hex: "#c38d87" },
  { name: "Grandma's Cameo", hex: "#f7e7dd" },
  { name: "Grandma's Pink Tiles", hex: "#e0b8c0" },
  { name: "Granite", hex: "#746a5e" },
  { name: "Granola", hex: "#f5ce9f" },
  { name: "Grape", hex: "#6c3461" },
  { name: "Grape Candy", hex: "#905284" },
  { name: "Grape Fizz", hex: "#64435f" },
  { name: "Grape Green", hex: "#a8e4a0" },
  { name: "Grape Kiss", hex: "#7b4368" },
  { name: "Grape Riot", hex: "#9b4682" },
  { name: "Grape Taffy", hex: "#f4daf1" },
  { name: "Grapefruit", hex: "#fd5956" },
  { name: "Grapes of Italy", hex: "#714a8b" },
  { name: "Grapest", hex: "#880066" },
  { name: "Graphite", hex: "#383428" },
  { name: "Graphite Black", hex: "#262a2b" },
  { name: "Grass", hex: "#5cac2d" },
  { name: "Grasshopper", hex: "#77824a" },
  { name: "Gratin Dauphinois", hex: "#e0d2a9" },
  { name: "Grauzone", hex: "#85a3b2" },
  { name: "Gravlax", hex: "#ec834f" },
  { name: "Greasy Greens", hex: "#117755" },
  { name: "Great Void", hex: "#3b5760" },
  { name: "Greedy Gecko", hex: "#aa9922" },
  { name: "Greek Goddess", hex: "#ede9ef" },
  { name: "Greek Olive", hex: "#a08650" },
  { name: "Green", hex: "#00ff00" },
  { name: "Green Bell Pepper", hex: "#228800" },
  { name: "Green Envy", hex: "#77aa00" },
  { name: "Green Field", hex: "#88aa77" },
  { name: "Green Glimmer", hex: "#00bb00" },
  { name: "Green Glint", hex: "#dcf1c7" },
  { name: "Green Goblin", hex: "#11bb33" },
  { name: "Green Goddess", hex: "#76ad83" },
  { name: "Green Mana", hex: "#26b467" },
  { name: "Green not Found", hex: "#404404" },
  { name: "Green Olive", hex: "#8d8b55" },
  { name: "Green Priestess", hex: "#11dd55" },
  { name: "Green Relict", hex: "#7b8762" },
  { name: "Green Revolution", hex: "#009944" },
  { name: "Green Screen", hex: "#22ff00" },
  { name: "Green Symphony", hex: "#66aa22" },
  { name: "Green Tea", hex: "#b5b68f" },
  { name: "Green Tea Mochi", hex: "#90a96e" },
  { name: "Green Thumb", hex: "#779900" },
  { name: "Green Venom", hex: "#b8f818" },
  { name: "Green With Envy", hex: "#22bb33" },
  { name: "Greenfinch", hex: "#bda928" },
  { name: "Greenhouse", hex: "#3e6334" },
  { name: "Greenivorous", hex: "#cae03b" },
  { name: "Gremlin", hex: "#a79954" },
  { name: "Grenadine Pink", hex: "#ff616b" },
  { name: "Grey", hex: "#808080" },
  { name: "Grey Area", hex: "#8f9394" },
  { name: "Grey Marble", hex: "#b9b4b1" },
  { name: "Grey Sheep", hex: "#baaaaa" },
  { name: "Grey Web", hex: "#616669" },
  { name: "Greybeard", hex: "#d4d0c5" },
  { name: "Greystone", hex: "#b7b9b5" },
  { name: "Griffin", hex: "#8d8f8f" },
  { name: "Grilled", hex: "#633f2e" },
  { name: "Grilled Tomato", hex: "#af3519" },
  { name: "Grim Grey", hex: "#e3dcd6" },
  { name: "Grim White", hex: "#f6f1f4" },
  { name: "Grizzly", hex: "#885818" },
  { name: "Groovy Giraffe", hex: "#eeaa11" },
  { name: "Groovy Lemon Pie", hex: "#d6be01" },
  { name: "Grotesque Green", hex: "#64e986" },
  { name: "Gruyère Cheese", hex: "#f5deb3" },
  { name: "Guacamole", hex: "#95986b" },
  { name: "Guardian of Gardens", hex: "#88aa22" },
  { name: "Guinea Pig", hex: "#987652" },
  { name: "Gull", hex: "#918c8f" },
  { name: "Gum Leaf", hex: "#acc9b2" },
  { name: "Gummy Dolphins", hex: "#06a9ca" },
  { name: "Gun Powder", hex: "#484753" },
  { name: "Gunmetal", hex: "#536267" },
  { name: "Guns N' Roses", hex: "#ff0077" },
  { name: "Gunsmoke", hex: "#7a7c76" },
  { name: "Gypsy Dancer", hex: "#c07c7b" },
  { name: "H₂O", hex: "#bfe1e6" },
  { name: "Habanero", hex: "#f98513" },
  { name: "Habanero Gold", hex: "#fed450" },
  { name: "Haddock's Sweater", hex: "#277aba" },
  { name: "Hadfield Blue", hex: "#1177ff" },
  { name: "Hairy Heath", hex: "#633528" },
  { name: "Haiti", hex: "#2c2a35" },
  { name: "Halloween", hex: "#fe653c" },
  { name: "Halloween Punch", hex: "#dd2211" },
  { name: "Halo", hex: "#e2c392" },
  { name: "Halt Red", hex: "#ff004f" },
  { name: "Hammam Blue", hex: "#65dcd6" },
  { name: "Hamster Fur", hex: "#a6814c" },
  { name: "Hamtaro Brown", hex: "#b07426" },
  { name: "Hanami Pink", hex: "#f2abe1" },
  { name: "Handmade", hex: "#7f735f" },
  { name: "Hanging Gardens of Babylon", hex: "#11aa44" },
  { name: "Happy Cement", hex: "#979ea1" },
  { name: "Happy Hearts", hex: "#d46362" },
  { name: "Happy Hippo", hex: "#818581" },
  { name: "Happy Piglets", hex: "#f6cbca" },
  { name: "Happy Skeleton", hex: "#faeed7" },
  { name: "Harbor Mist", hex: "#88aaaa" },
  { name: "Harbour Blue", hex: "#417491" },
  { name: "Hard Candy", hex: "#ffbbbb" },
  { name: "Harem Silk", hex: "#006383" },
  { name: "Harlock's Cape", hex: "#bb0000" },
  { name: "Harvest at Dusk", hex: "#cb862c" },
  { name: "Harvest Gold", hex: "#eab76a" },
  { name: "Harvest Time", hex: "#cf875f" },
  { name: "Hatoba Pigeon", hex: "#95859c" },
  { name: "Haunted Candelabra", hex: "#57446a" },
  { name: "Haunted Forest", hex: "#032e0e" },
  { name: "Haunted Hills", hex: "#003311" },
  { name: "Haunted Purple", hex: "#991177" },
  { name: "Haute Couture", hex: "#a0252a" },
  { name: "Havana", hex: "#3b2b2c" },
  { name: "Hawaii Morning", hex: "#00bbff" },
  { name: "Hawaiian Raspberry", hex: "#ff0051" },
  { name: "Hay Day", hex: "#dacd81" },
  { name: "Haystacks", hex: "#cfac47" },
  { name: "Hazel", hex: "#ae7250" },
  { name: "Hazelnut", hex: "#a8715a" },
  { name: "Hazelnut Chocolate", hex: "#7b3f00" },
  { name: "Hazelwood", hex: "#fff3d5" },
  { name: "Hazy Moon", hex: "#f1dca1" },
  { name: "Head in the Clouds", hex: "#d1dde1" },
  { name: "Heart of Ice", hex: "#f7fcff" },
  { name: "Heart Potion", hex: "#a97fb1" },
  { name: "Heartbeat", hex: "#aa0000" },
  { name: "Heartless", hex: "#623b70" },
  { name: "Heartwarming", hex: "#bf1818" },
  { name: "Heat Signature", hex: "#e3000e" },
  { name: "Heat Wave", hex: "#ff7a00" },
  { name: "Heather Berry", hex: "#e75480" },
  { name: "Heaven Gates", hex: "#c7f1ff" },
  { name: "Heavenly Sky", hex: "#6b90b3" },
  { name: "Heavy Brown", hex: "#73624a" },
  { name: "Heavy Charcoal", hex: "#565350" },
  { name: "Heavy Cream", hex: "#e8ddc6" },
  { name: "Heavy Green", hex: "#49583e" },
  { name: "Heavy Heart", hex: "#771122" },
  { name: "Heavy Metal", hex: "#46473e" },
  { name: "Heavy Orange", hex: "#ee4328" },
  { name: "Heavy Red", hex: "#9e1212" },
  { name: "Heavy Violet", hex: "#4f566c" },
  { name: "Hedge Garden", hex: "#00aa11" },
  { name: "Helium", hex: "#eae5d8" },
  { name: "Hello Darkness My Old Friend", hex: "#802280" },
  { name: "Hello Fall", hex: "#995533" },
  { name: "Hello Spring", hex: "#44dd66" },
  { name: "Hello Summer", hex: "#55bbff" },
  { name: "Hello Winter", hex: "#99ffee" },
  { name: "Helvetia Red", hex: "#f00000" },
  { name: "Hemp", hex: "#987d73" },
  { name: "Her Fierceness", hex: "#6f123c" },
  { name: "Her Highness", hex: "#432e6f" },
  { name: "Her Majesty", hex: "#f9a4a4" },
  { name: "Her Velour", hex: "#bb5f62" },
  { name: "Herbal", hex: "#29ab87" },
  { name: "Herbal Vapors", hex: "#ddffcc" },
  { name: "Herbalist", hex: "#969e86" },
  { name: "Herbalist's Garden", hex: "#119900" },
  { name: "Herbivore", hex: "#88ee77" },
  { name: "Here Comes the Sun", hex: "#fcdf63" },
  { name: "Heroic Red", hex: "#d1191c" },
  { name: "Heron", hex: "#62617e" },
  { name: "Herring Silver", hex: "#c6c8cf" },
  { name: "Hey Blue!", hex: "#16f8ff" },
  { name: "Hibernation", hex: "#6f5166" },
  { name: "Hibiscus", hex: "#b6316c" },
  { name: "Hickory", hex: "#b7a28e" },
  { name: "Hidden Paradise", hex: "#5e8b3d" },
  { name: "Hidden Sea Glass", hex: "#6fd1c9" },
  { name: "High Blue", hex: "#4ca8e0" },
  { name: "High Dive", hex: "#59b9cc" },
  { name: "High Grass", hex: "#bbdd00" },
  { name: "High Seas", hex: "#7dabd8" },
  { name: "High Sierra", hex: "#cedee2" },
  { name: "High Tide", hex: "#85a6c8" },
  { name: "High Voltage", hex: "#eeff11" },
  { name: "Highland", hex: "#7a9461" },
  { name: "Highlander", hex: "#3a533d" },
  { name: "Highlands", hex: "#449084" },
  { name: "Highway to Hell", hex: "#cd1102" },
  { name: "Himalaya", hex: "#736330" },
  { name: "Himalayan Salt", hex: "#c07765" },
  { name: "Hindu Lotus", hex: "#ee4d83" },
  { name: "Hint of Blue", hex: "#cee1f2" },
  { name: "Hint of Green", hex: "#dfeade" },
  { name: "Hint of Mint", hex: "#dff1d6" },
  { name: "Hint of Orange", hex: "#f8e6d9" },
  { name: "Hint of Pink", hex: "#f1e4e1" },
  { name: "Hint of Red", hex: "#f6dfe0" },
  { name: "Hint of Yellow", hex: "#faf1cd" },
  { name: "Hinterland", hex: "#616c51" },
  { name: "Hippie Trail", hex: "#c6aa2b" },
  { name: "Hippy", hex: "#eae583" },
  { name: "Hipster Hippo", hex: "#bfb3ab" },
  { name: "Hipster Salmon", hex: "#fd7c6e" },
  { name: "Hive", hex: "#ffff77" },
  { name: "Hobgoblin", hex: "#01ad8f" },
  { name: "Hokkaido Lavender", hex: "#7736d9" },
  { name: "Hold Your Horses", hex: "#705446" },
  { name: "Hole In One", hex: "#4aae97" },
  { name: "Holland Tulip", hex: "#f89851" },
  { name: "Hollandaise", hex: "#ffee44" },
  { name: "Hollow Knight", hex: "#330055" },
  { name: "Holy Cannoli", hex: "#db783e" },
  { name: "Holy Crow", hex: "#332f2c" },
  { name: "Holy Ghost", hex: "#efe9e6" },
  { name: "Holy Grail", hex: "#e8d720" },
  { name: "Home Brew", hex: "#897b66" },
  { name: "Homegrown", hex: "#63884a" },
  { name: "Homeopathic Blue", hex: "#dbe7e3" },
  { name: "Homeopathic Green", hex: "#e1ebd8" },
  { name: "Homeopathic Lavender", hex: "#e5e0ec" },
  { name: "Homeopathic Lilac", hex: "#e1e0eb" },
  { name: "Homeopathic Lime", hex: "#e9f6e2" },
  { name: "Homeopathic Mint", hex: "#e5ead8" },
  { name: "Homeopathic Orange", hex: "#f2e6e1" },
  { name: "Homeopathic Red", hex: "#ecdbe0" },
  { name: "Homeopathic Rose", hex: "#e8dbdd" },
  { name: "Homeopathic Yellow", hex: "#ede7d7" },
  { name: "Homeworld", hex: "#2299dd" },
  { name: "Honey", hex: "#ba9238" },
  { name: "Honey and Thyme", hex: "#aaaa00" },
  { name: "Honey Bee", hex: "#fcdfa4" },
  { name: "Honey Bunny", hex: "#dbb881" },
  { name: "Honey Crisp", hex: "#e9c160" },
  { name: "Honey Do", hex: "#ededc7" },
  { name: "Honey Glow", hex: "#e8b447" },
  { name: "Honey Gold", hex: "#e1b67c" },
  { name: "Honey Teriyaki", hex: "#ee6611" },
  { name: "Honeycomb", hex: "#ddaa11" },
  { name: "Honeydew", hex: "#f0fff0" },
  { name: "Honeydew Sand", hex: "#eece8d" },
  { name: "Honeysuckle", hex: "#e8ed69" },
  { name: "Hong Kong Mist", hex: "#948e90" },
  { name: "Hong Kong Taxi", hex: "#a8102a" },
  { name: "Honied White", hex: "#fcefd1" },
  { name: "Honolulu Blue", hex: "#007fbf" },
  { name: "Horizon", hex: "#648894" },
  { name: "Hornet Sting", hex: "#ff0033" },
  { name: "Horror Snob", hex: "#d34d4d" },
  { name: "Horseradish", hex: "#e6dfc4" },
  { name: "Hot Beach", hex: "#fff6d9" },
  { name: "Hot Brown", hex: "#984218" },
  { name: "Hot Butter", hex: "#e69d00" },
  { name: "Hot Cacao", hex: "#a5694f" },
  { name: "Hot Caramel", hex: "#cc6e3b" },
  { name: "Hot Chilli", hex: "#b7513a" },
  { name: "Hot Chocolate", hex: "#683b39" },
  { name: "Hot Cuba", hex: "#bb0033" },
  { name: "Hot Curry", hex: "#815b28" },
  { name: "Hot Flamin Chilli", hex: "#dd180e" },
  { name: "Hot Flamingo", hex: "#b35966" },
  { name: "Hot Fudge", hex: "#5e2912" },
  { name: "Hot Jazz", hex: "#bc3033" },
  { name: "Hot Lava", hex: "#aa0033" },
  { name: "Hot Lips", hex: "#c9312b" },
  { name: "Hot Magenta", hex: "#ff00cc" },
  { name: "Hot Pink", hex: "#ff028d" },
  { name: "Hot Sand", hex: "#ccaa00" },
  { name: "Hot Sauce", hex: "#ab4f41" },
  { name: "Hot Shot", hex: "#ec4f28" },
  { name: "Hotspot", hex: "#ff4433" },
  { name: "Hotter Butter", hex: "#e68a00" },
  { name: "Hotter Than Hell", hex: "#ff4455" },
  { name: "Hottest Of Pinks", hex: "#ff80ff" },
  { name: "Hourglass", hex: "#e5e0d5" },
  { name: "Howling Pink", hex: "#e50752" },
  { name: "Hulk", hex: "#008000" },
  { name: "Humble Blush", hex: "#e3cdc2" },
  { name: "Humble Hippo", hex: "#aaaa99" },
  { name: "Hummus", hex: "#eecc99" },
  { name: "Hunter", hex: "#33534b" },
  { name: "Hurricane", hex: "#8b7e77" },
  { name: "Hydra", hex: "#006995" },
  { name: "Hydro", hex: "#426972" },
  { name: "Hyper Blue", hex: "#015f97" },
  { name: "Hyper Light Drifter", hex: "#eddbda" },
  { name: "Hyper Pink", hex: "#ec006c" },
  { name: "Hyperlink Blue", hex: "#0000ee" },
  { name: "Hyperpop Green", hex: "#17f9a6" },
  { name: "Hypnotic Green", hex: "#73e608" },
  { name: "Hypnotic Red", hex: "#cf0d14" },
  { name: "I Love You Pink", hex: "#d97d8f" },
  { name: "Ibis", hex: "#f4b3c2" },
  { name: "Ice", hex: "#d6fffa" },
  { name: "Ice Climber", hex: "#25e2cd" },
  { name: "Ice Cold", hex: "#d2eaf1" },
  { name: "Ice Cube", hex: "#afe3d6" },
  { name: "Ice Dagger", hex: "#cee5df" },
  { name: "Ice Desert", hex: "#d1dce8" },
  { name: "Ice Ice", hex: "#baebae" },
  { name: "Ice Ice Baby", hex: "#00ffdd" },
  { name: "Ice Temple", hex: "#11ffee" },
  { name: "Iceberg", hex: "#dae4ee" },
  { name: "Icebreaker", hex: "#b7c2cc" },
  { name: "Iced Coffee", hex: "#b18f6a" },
  { name: "Icelandic Winter", hex: "#d9e7e3" },
  { name: "Icy Plains", hex: "#cfdafb" },
  { name: "Igniting", hex: "#f4d69a" },
  { name: "Iguana", hex: "#818455" },
  { name: "Illicit Darkness", hex: "#00022e" },
  { name: "Illicit Green", hex: "#56fca2" },
  { name: "Illicit Pink", hex: "#ff5ccd" },
  { name: "Illicit Purple", hex: "#bf77f6" },
  { name: "Illuminati Green", hex: "#419168" },
  { name: "Illuminating", hex: "#eeee77" },
  { name: "Imperial", hex: "#602f6b" },
  { name: "Imperial Ivory", hex: "#f1e8d2" },
  { name: "Imperial Purple", hex: "#542c5d" },
  { name: "Imperial Red", hex: "#ec2938" },
  { name: "Imperial Yellow", hex: "#ffb200" },
  { name: "In A Pickle", hex: "#978c59" },
  { name: "In for a Penny", hex: "#ee8877" },
  { name: "In Good Taste", hex: "#b6d4a0" },
  { name: "In the Dark", hex: "#3b3c41" },
  { name: "In the Pink", hex: "#f4c4d0" },
  { name: "In the Red", hex: "#ff2233" },
  { name: "In the Shadows", hex: "#cbc4c0" },
  { name: "In the Vines", hex: "#5c457b" },
  { name: "Inca Gold", hex: "#bb7a2c" },
  { name: "Inca Yellow", hex: "#ffd301" },
  { name: "Incense", hex: "#af9a7e" },
  { name: "Inchworm", hex: "#b2ec5d" },
  { name: "Incision", hex: "#ff0022" },
  { name: "Incubation Red", hex: "#da1d38" },
  { name: "Indian Mesa", hex: "#d5a193" },
  { name: "Indian Pale Ale", hex: "#d5bc26" },
  { name: "Indian Red", hex: "#850e04" },
  { name: "Indian Saffron", hex: "#ff9933" },
  { name: "Indian Silk", hex: "#8a5773" },
  { name: "Indica", hex: "#588c3a" },
  { name: "Indigo", hex: "#4b0082" },
  { name: "Indochine", hex: "#9c5b34" },
  { name: "Indocile Tiger", hex: "#b96b00" },
  { name: "Inescapable Lover", hex: "#820e3b" },
  { name: "Infectious Love", hex: "#bb1177" },
  { name: "Inferno Orange", hex: "#ff4400" },
  { name: "Infinite Night", hex: "#071037" },
  { name: "Infinity", hex: "#222831" },
  { name: "Infinity and Beyond", hex: "#6e7e99" },
  { name: "Infinity Pool", hex: "#94d4e4" },
  { name: "Infrared", hex: "#fe486c" },
  { name: "Infusion", hex: "#c8d0ca" },
  { name: "Ink Black", hex: "#252024" },
  { name: "Inkblot", hex: "#393f4b" },
  { name: "Inkjet", hex: "#44556b" },
  { name: "Inland Waters", hex: "#7c939d" },
  { name: "Innocent Snowdrop", hex: "#d0c7ff" },
  { name: "Insomniac Blue", hex: "#110077" },
  { name: "Instant Noodles", hex: "#f4d493" },
  { name: "Intense Passion", hex: "#df3163" },
  { name: "Intergalactic", hex: "#4d516c" },
  { name: "Intergalactic Cowboy", hex: "#222266" },
  { name: "Intergalactic Highway", hex: "#273287" },
  { name: "Intergalactic Settlement", hex: "#5b1e8b" },
  { name: "International Klein Blue", hex: "#002fa6" },
  { name: "Interstellar Blue", hex: "#001155" },
  { name: "Intimate Journal", hex: "#ccbb99" },
  { name: "Into the Blue", hex: "#4f7ba7" },
  { name: "Into the Green", hex: "#0d6c49" },
  { name: "Into the Night", hex: "#1e3642" },
  { name: "Into the Stratosphere", hex: "#425267" },
  { name: "Intoxicate", hex: "#11bb55" },
  { name: "Intrigue Red", hex: "#b24648" },
  { name: "Inuit Blue", hex: "#d8e4e7" },
  { name: "Iridescent", hex: "#3a5b52" },
  { name: "Irish Clover", hex: "#53734c" },
  { name: "Irish Coffee", hex: "#62422b" },
  { name: "Irish Moor", hex: "#b5c0b3" },
  { name: "Irish Spring", hex: "#90cca3" },
  { name: "Iron", hex: "#5e5e5e" },
  { name: "Iron Fist", hex: "#cbcdcd" },
  { name: "Iron Maiden", hex: "#d6d1dc" },
  { name: "Ironside", hex: "#7e8082" },
  { name: "Islamic Green", hex: "#009900" },
  { name: "Island Coral", hex: "#d8877a" },
  { name: "Isle of Dreams", hex: "#bcccb5" },
  { name: "Isolation", hex: "#494d55" },
  { name: "Italian Basil", hex: "#5f6957" },
  { name: "Italian Grape", hex: "#413d4b" },
  { name: "Italian Roast", hex: "#221111" },
  { name: "Ivory", hex: "#fffff0" },
  { name: "Ivory Buff", hex: "#ebd999" },
  { name: "Ivory Tower", hex: "#fbf3f1" },
  { name: "Ivory Wedding", hex: "#edede4" },
  { name: "Ivy", hex: "#226c63" },
  { name: "Ivy Topiary", hex: "#67614f" },
  { name: "Jack and Coke", hex: "#920f0e" },
  { name: "Jack-O", hex: "#fb9902" },
  { name: "Jackpot", hex: "#d19431" },
  { name: "Jacuzzi", hex: "#007cac" },
  { name: "Jade", hex: "#00a86b" },
  { name: "Jade Jewel", hex: "#247e81" },
  { name: "Jade Sea", hex: "#b8e0d0" },
  { name: "Jaffa", hex: "#e27945" },
  { name: "Jaguar", hex: "#29292f" },
  { name: "Jakarta", hex: "#efddc3" },
  { name: "Jalapeño", hex: "#9a8d3f" },
  { name: "Jalapeño Red", hex: "#b2103c" },
  { name: "Jambalaya", hex: "#f7b572" },
  { name: "James Blonde", hex: "#f2e3b5" },
  { name: "Japanese Bonsai", hex: "#829f96" },
  { name: "Jasmine", hex: "#fff4bb" },
  { name: "Java", hex: "#259797" },
  { name: "Jazz", hex: "#5f2c2f" },
  { name: "Jazzy Jade", hex: "#55ddcc" },
  { name: "Jealous Jellyfish", hex: "#bb0099" },
  { name: "Jedi Night", hex: "#041108" },
  { name: "Jelly Berry", hex: "#ee1177" },
  { name: "Jelly Slug", hex: "#de6646" },
  { name: "Jellyfish Sting", hex: "#ee6688" },
  { name: "Jet Black", hex: "#2d2c2f" },
  { name: "Jet d'Eau", hex: "#d1eaec" },
  { name: "Jewel", hex: "#136843" },
  { name: "Jewel Beetle", hex: "#8cc90b" },
  { name: "Jewel Weed", hex: "#46a795" },
  { name: "Jigglypuff", hex: "#ffaaff" },
  { name: "Jinza Safflower", hex: "#ee827c" },
  { name: "Jittery Jade", hex: "#77eebb" },
  { name: "John Lemon", hex: "#eeff22" },
  { name: "Joker's Smile", hex: "#d70141" },
  { name: "Jolly Jade", hex: "#77ccbb" },
  { name: "Jovial Jade", hex: "#88ddaa" },
  { name: "Jubilant Jade", hex: "#44aa77" },
  { name: "Juggernaut", hex: "#255367" },
  { name: "Juicy Lime", hex: "#b1cf5d" },
  { name: "Juicy Peach", hex: "#d99290" },
  { name: "Jumbo", hex: "#878785" },
  { name: "June Bud", hex: "#bdda57" },
  { name: "Jungle", hex: "#00a466" },
  { name: "Jungle Civilization", hex: "#69673a" },
  { name: "Jungle Jam", hex: "#115511" },
  { name: "Jungle King", hex: "#4f4d32" },
  { name: "Jupiter", hex: "#e1e1e2" },
  { name: "Jurassic Park", hex: "#3c663e" },
  { name: "Just Pink Enough", hex: "#ffebee" },
  { name: "Kabuki", hex: "#a73a3e" },
  { name: "Kabul", hex: "#6c5e53" },
  { name: "Kaiser Cheese", hex: "#eed484" },
  { name: "Kale", hex: "#5a7247" },
  { name: "Kaltes Klares Wasser", hex: "#0ffef9" },
  { name: "Kashmir", hex: "#6f8d6a" },
  { name: "Kathmandu", hex: "#ad9a5d" },
  { name: "Katy Berry", hex: "#aa0077" },
  { name: "Kefir", hex: "#d5d5ce" },
  { name: "Kelp", hex: "#4d503c" },
  { name: "Kelp Forest", hex: "#448811" },
  { name: "Kendall Rose", hex: "#f7cccd" },
  { name: "Kenyan Copper", hex: "#6c322e" },
  { name: "Kermit Green", hex: "#5cb200" },
  { name: "Ketchup", hex: "#9a382d" },
  { name: "Ketchup Later", hex: "#a91c1c" },
  { name: "Khaki", hex: "#c3b091" },
  { name: "Kid Icarus", hex: "#a81000" },
  { name: "Kilimanjaro", hex: "#3a3532" },
  { name: "Kimchi", hex: "#ed4b00" },
  { name: "Kimono", hex: "#6d86b6" },
  { name: "Kindleflame", hex: "#e9967a" },
  { name: "King Kong", hex: "#161410" },
  { name: "King Lime", hex: "#add900" },
  { name: "King Lizard", hex: "#77dd22" },
  { name: "King Nacho", hex: "#ffb800" },
  { name: "King Neptune", hex: "#7794c0" },
  { name: "King of Waves", hex: "#c6dce7" },
  { name: "King Triton", hex: "#3c85be" },
  { name: "King's Plum Pie", hex: "#b3107a" },
  { name: "Kingfisher Daisy", hex: "#583580" },
  { name: "Kingly Cloud", hex: "#dedede" },
  { name: "Kingpin Gold", hex: "#de9930" },
  { name: "Kings Yellow", hex: "#ead665" },
  { name: "Kinky Pinky", hex: "#ee55cc" },
  { name: "Kirby", hex: "#d74894" },
  { name: "Kirsch", hex: "#b2132b" },
  { name: "Kiss", hex: "#d28ca7" },
  { name: "Kiss A Frog", hex: "#bec187" },
  { name: "Kiss Me More", hex: "#de6b86" },
  { name: "Kiss of a Vampire", hex: "#8a0009" },
  { name: "Kiss of the Scorpion", hex: "#dc331a" },
  { name: "Kissable", hex: "#fd8f79" },
  { name: "Kissed by Mist", hex: "#fcccf5" },
  { name: "Kisses", hex: "#ff66bb" },
  { name: "Kisses and Hugs", hex: "#ff6677" },
  { name: "Kitten's Eye", hex: "#8aadf7" },
  { name: "Kitten's Paw", hex: "#daa89b" },
  { name: "Kitty Kitty", hex: "#c7bdb3" },
  { name: "Kiwi", hex: "#7aab55" },
  { name: "Kiwi Kiss", hex: "#eef9c1" },
  { name: "Knight Rider", hex: "#0f0707" },
  { name: "Knight's Armor", hex: "#5c5d5d" },
  { name: "Knit Cardigan", hex: "#6d6c5f" },
  { name: "Knock On Wood", hex: "#9f9b84" },
  { name: "Kobe", hex: "#882d17" },
  { name: "Köfte Brown", hex: "#773644" },
  { name: "Koi", hex: "#d15837" },
  { name: "Kombucha", hex: "#d89f66" },
  { name: "Komodo Dragon", hex: "#b38052" },
  { name: "Koopa Green Shell", hex: "#58d854" },
  { name: "Koromiko", hex: "#feb552" },
  { name: "Kosher Khaki", hex: "#888877" },
  { name: "Kryptonite Green", hex: "#439946" },
  { name: "La La Love", hex: "#bf90bb" },
  { name: "La Luna", hex: "#ffffe5" },
  { name: "La Palma", hex: "#428929" },
  { name: "LA Vibes", hex: "#eeccdd" },
  { name: "La Vie en Rose", hex: "#d2a5a3" },
  { name: "Labrador", hex: "#f2ecd9" },
  { name: "Lacquered Liquorice", hex: "#383838" },
  { name: "Lacrosse", hex: "#2e5c58" },
  { name: "Lagoon", hex: "#4d9e9a" },
  { name: "Laguna", hex: "#36a5c9" },
  { name: "Lake Lucerne", hex: "#689db7" },
  { name: "Laksa", hex: "#e6bf95" },
  { name: "Lama", hex: "#e0bb95" },
  { name: "Lambs Wool", hex: "#e6d1b2" },
  { name: "Landjäger", hex: "#af403c" },
  { name: "Langoustine", hex: "#dc5226" },
  { name: "Lapis on Neptune", hex: "#1f22d2" },
  { name: "Larb Gai", hex: "#dfc6aa" },
  { name: "Laser Lemon", hex: "#ffff66" },
  { name: "Laser Trap", hex: "#ff3f6a" },
  { name: "Last of Lettuce", hex: "#aadd66" },
  { name: "Last of the Lilacs", hex: "#cbbbcd" },
  { name: "Last Straw", hex: "#e3dbcd" },
  { name: "Last Warning", hex: "#d30f3f" },
  { name: "Lasting Lime", hex: "#88ff00" },
  { name: "Later Gator", hex: "#008a51" },
  { name: "Latte", hex: "#c5a582" },
  { name: "Lava", hex: "#cf1020" },
  { name: "Lava Pit", hex: "#e46f34" },
  { name: "Lava Rock", hex: "#535e64" },
  { name: "Lava Stone", hex: "#3c4151" },
  { name: "Lavender", hex: "#b56edc" },
  { name: "Lavender Ash", hex: "#9998a7" },
  { name: "Lavender Bliss", hex: "#cec3dd" },
  { name: "Lavender Candy", hex: "#fcb4d5" },
  { name: "Lavish Spending", hex: "#8469bc" },
  { name: "Lawn Green", hex: "#4da409" },
  { name: "Lazy Daisy", hex: "#f6eba1" },
  { name: "Lazy Lizard", hex: "#9c9c4b" },
  { name: "Lead", hex: "#212121" },
  { name: "Leaf", hex: "#71aa34" },
  { name: "Leafy", hex: "#679b6a" },
  { name: "Leafy Canopy", hex: "#aacc11" },
  { name: "Leafy Lemon", hex: "#c0f000" },
  { name: "Leafy Woodland", hex: "#aabb11" },
  { name: "Leapfrog", hex: "#41a94f" },
  { name: "Leather", hex: "#906a54" },
  { name: "Leek", hex: "#98d98e" },
  { name: "Left on Red", hex: "#ff0303" },
  { name: "Legendary Lavender", hex: "#9d61d4" },
  { name: "Lemon", hex: "#fff700" },
  { name: "Lemon Burst", hex: "#fed67e" },
  { name: "Lemon Curd", hex: "#ffee11" },
  { name: "Lemon Grass", hex: "#999a86" },
  { name: "Lemon Meringue", hex: "#f6e199" },
  { name: "Lemon Tart", hex: "#ffdd66" },
  { name: "Lemon Zest", hex: "#f9d857" },
  { name: "Lemonade", hex: "#f0e79d" },
  { name: "Les Demoiselles d'Avignon", hex: "#e59d7b" },
  { name: "Let it Snow", hex: "#d8f1f4" },
  { name: "Lethal Lime", hex: "#88ff11" },
  { name: "Liaison", hex: "#8c3f52" },
  { name: "Lichen", hex: "#9bc2b1" },
  { name: "Lick and Kiss", hex: "#ee5577" },
  { name: "Lifeguard", hex: "#e50000" },
  { name: "Lifeline", hex: "#990033" },
  { name: "Light Blue", hex: "#add8e6" },
  { name: "Light Blush", hex: "#e9c4cc" },
  { name: "Light Brown", hex: "#b5651d" },
  { name: "Light Green", hex: "#76ff7b" },
  { name: "Light Grey", hex: "#d8dcd6" },
  { name: "Light Lilac", hex: "#dcc6d2" },
  { name: "Light Mint", hex: "#b6ffbb" },
  { name: "Light My Fire", hex: "#f8611a" },
  { name: "Light Pink", hex: "#ffd1df" },
  { name: "Light Red", hex: "#f3d3d9" },
  { name: "Light Spirited", hex: "#d8eee7" },
  { name: "Light Yellow", hex: "#fffe7a" },
  { name: "Lighthouse", hex: "#f3f4f4" },
  { name: "Lighthouse Glow", hex: "#f8d568" },
  { name: "Lightning Bolt", hex: "#e5ebe6" },
  { name: "Lightning Bug", hex: "#efde74" },
  { name: "Lights Out", hex: "#3d474b" },
  { name: "Lilac", hex: "#cea2fd" },
  { name: "Lilac Lace", hex: "#c6a1cf" },
  { name: "Lilac Lotion", hex: "#ff3388" },
  { name: "Lilac Spring", hex: "#8822cc" },
  { name: "Lily", hex: "#c19fb3" },
  { name: "Lily Pads", hex: "#6db083" },
  { name: "Lima", hex: "#a9f971" },
  { name: "Lime", hex: "#aaff32" },
  { name: "Lime Fizz", hex: "#cfe838" },
  { name: "Lime Mist", hex: "#ddffaa" },
  { name: "Lime Punch", hex: "#c0d725" },
  { name: "Lime Twist", hex: "#c6d624" },
  { name: "Lime Zest", hex: "#ddff00" },
  { name: "Limolicious", hex: "#97b73a" },
  { name: "Limon", hex: "#f7eb73" },
  { name: "Limonana", hex: "#11dd66" },
  { name: "Limoncello", hex: "#bfff00" },
  { name: "Lincoln Green", hex: "#195905" },
  { name: "Lindworm Green", hex: "#172808" },
  { name: "Linen", hex: "#faf0e6" },
  { name: "Lingering Storm", hex: "#858381" },
  { name: "Link to the Past", hex: "#d2b48c" },
  { name: "Lion", hex: "#c19a62" },
  { name: "Lion King", hex: "#dd9933" },
  { name: "Lionheart", hex: "#cc2222" },
  { name: "Lip Gloss", hex: "#dfcdc7" },
  { name: "Lipstick", hex: "#c95b83" },
  { name: "Lipstick Illusion", hex: "#d4696d" },
  { name: "Liquid Gold", hex: "#fdc675" },
  { name: "Liquid Lava", hex: "#f77511" },
  { name: "Liquid Lime", hex: "#cdf80c" },
  { name: "Liquid Neon", hex: "#c8ff00" },
  { name: "Liquorice", hex: "#0a0502" },
  { name: "Liquorice Red", hex: "#740900" },
  { name: "Lit", hex: "#fffed8" },
  { name: "Little Ladybug", hex: "#ff1414" },
  { name: "Little Lamb", hex: "#eae6d7" },
  { name: "Little Mermaid", hex: "#2d454a" },
  { name: "Liver", hex: "#654a46" },
  { name: "Lizard", hex: "#71643e" },
  { name: "Llilacquered", hex: "#c35b99" },
  { name: "Lobster", hex: "#bb240c" },
  { name: "Local Curry", hex: "#cb9e34" },
  { name: "Loch Ness", hex: "#5f6db0" },
  { name: "Lolita", hex: "#bf2735" },
  { name: "Lollipop", hex: "#cc1c3b" },
  { name: "Lone Hunter", hex: "#94c84c" },
  { name: "Lonely Chocolate", hex: "#4a0a00" },
  { name: "Lonestar", hex: "#522426" },
  { name: "Long Beach", hex: "#faefdf" },
  { name: "Long-Haul Flight", hex: "#002277" },
  { name: "Looney Blue", hex: "#11ffff" },
  { name: "Lords of the Night", hex: "#664488" },
  { name: "Lost at Sea", hex: "#8d9ca7" },
  { name: "Lost Golfer", hex: "#74af54" },
  { name: "Lost in Heaven", hex: "#002489" },
  { name: "Lost in Space", hex: "#03386a" },
  { name: "Lost in the Woods", hex: "#014426" },
  { name: "Lost in Time", hex: "#9fafbd" },
  { name: "Lost Space", hex: "#969389" },
  { name: "Lotion", hex: "#fefdfa" },
  { name: "Lotus Flower", hex: "#f4f0da" },
  { name: "Loud Lime", hex: "#88ff22" },
  { name: "Loudicious Pink", hex: "#d92fb4" },
  { name: "Love Affair", hex: "#ffbec8" },
  { name: "Love Dust", hex: "#eb94da" },
  { name: "Love Fumes", hex: "#fdd0d5" },
  { name: "Love Goddess", hex: "#cd0d0d" },
  { name: "Love Juice", hex: "#cc1155" },
  { name: "Love Letter", hex: "#e4658e" },
  { name: "Love Potion", hex: "#c01352" },
  { name: "Love Priestess", hex: "#bb55cc" },
  { name: "Love Spell", hex: "#f8b4c4" },
  { name: "Love Vessel", hex: "#ee0099" },
  { name: "Loveland", hex: "#e6718d" },
  { name: "Lovely Breeze", hex: "#f9d8e4" },
  { name: "Lovely Little Rosy", hex: "#e35f66" },
  { name: "Lucid Dream", hex: "#632f92" },
  { name: "Lucid Dreams", hex: "#cceeff" },
  { name: "Lucius Lilac", hex: "#baa2ce" },
  { name: "Lucky", hex: "#ab9a1c" },
  { name: "Lucky Clover", hex: "#008400" },
  { name: "Lucky Grey", hex: "#777777" },
  { name: "Lucky Lobster", hex: "#cc3322" },
  { name: "Lucky Penny", hex: "#bc6f37" },
  { name: "Ludicrous Lemming", hex: "#bb8877" },
  { name: "Luigi", hex: "#4cbb17" },
  { name: "Lumberjack", hex: "#9d4542" },
  { name: "Luna", hex: "#d4d8ce" },
  { name: "Lunar Base", hex: "#878786" },
  { name: "Lunar Landing", hex: "#d2cfc1" },
  { name: "Lunar Light", hex: "#9b959c" },
  { name: "Lunar Outpost", hex: "#828287" },
  { name: "Lunatic Lynx", hex: "#ddaa88" },
  { name: "Lurid Lettuce", hex: "#b4f319" },
  { name: "Luscious Lemongrass", hex: "#517933" },
  { name: "Lush", hex: "#c5bda0" },
  { name: "Lush Bamboo", hex: "#afbb33" },
  { name: "Lush Garden", hex: "#008811" },
  { name: "Lush Grass", hex: "#468d45" },
  { name: "Lush Green", hex: "#bbee00" },
  { name: "Lush Plains", hex: "#22bb22" },
  { name: "Lust", hex: "#e62020" },
  { name: "Lustful Wishes", hex: "#cc4499" },
  { name: "Lusty Lavender", hex: "#8d5eb7" },
  { name: "Lusty Lips", hex: "#d5174e" },
  { name: "Lusty Lizard", hex: "#00bb11" },
  { name: "Luxor Gold", hex: "#ab8d3f" },
  { name: "Luxurious", hex: "#d4b75d" },
  { name: "Luxurious Lime", hex: "#88ee22" },
  { name: "Lynx", hex: "#604d47" },
  { name: "M. Bison", hex: "#b4023d" },
  { name: "Macabre", hex: "#880033" },
  { name: "Macadamia", hex: "#e4cfb6" },
  { name: "Macaroni", hex: "#f3d085" },
  { name: "Macaroni and Cheese", hex: "#ffb97b" },
  { name: "Macaroon", hex: "#b38b71" },
  { name: "Macau", hex: "#46c299" },
  { name: "Machinery", hex: "#9999aa" },
  { name: "Machu Picchu Gardens", hex: "#99bb33" },
  { name: "Mad For Mango", hex: "#f8a200" },
  { name: "Made in the Shade", hex: "#6b717a" },
  { name: "Mademoiselle Pink", hex: "#f504c9" },
  { name: "Madonna", hex: "#3f4250" },
  { name: "Madras", hex: "#473e23" },
  { name: "Magenta", hex: "#ff00ff" },
  { name: "Magenta Affair", hex: "#aa44dd" },
  { name: "Magenta Elephant", hex: "#de0170" },
  { name: "Magenta Memoir", hex: "#b4559b" },
  { name: "Magentarama", hex: "#cf3476" },
  { name: "Magentle", hex: "#aa11aa" },
  { name: "Magentleman", hex: "#aa22bb" },
  { name: "Magento", hex: "#bf3cff" },
  { name: "Magic Carpet", hex: "#9488be" },
  { name: "Magic Ink", hex: "#0247fe" },
  { name: "Magic Magenta", hex: "#7f4774" },
  { name: "Magic Potion", hex: "#ff4466" },
  { name: "Magical Merlin", hex: "#3d8ed0" },
  { name: "Magical Moonlight", hex: "#f0eeeb" },
  { name: "Magical Stardust", hex: "#eaeadb" },
  { name: "Magma", hex: "#ff4e01" },
  { name: "Magna Cum Laude", hex: "#dd0066" },
  { name: "Magnesium", hex: "#c1c2c3" },
  { name: "Magnet", hex: "#4d4b4f" },
  { name: "Magnetic", hex: "#b2b5af" },
  { name: "Magnificent Magenta", hex: "#ee22aa" },
  { name: "Magnolia", hex: "#fff9e4" },
  { name: "Magnolia Petal", hex: "#f7eee3" },
  { name: "Maharaja", hex: "#3f354f" },
  { name: "Mahogany", hex: "#c04000" },
  { name: "Mai Tai", hex: "#a56531" },
  { name: "Maiden's Blush", hex: "#f3d3bf" },
  { name: "Maison Verte", hex: "#e5f0d9" },
  { name: "Maize", hex: "#f4d054" },
  { name: "Maizena", hex: "#fbec5e" },
  { name: "Majestic Dune", hex: "#f3bc80" },
  { name: "Majestic Eggplant", hex: "#443388" },
  { name: "Majestic Evergreen", hex: "#7d8878" },
  { name: "Majestic Magenta", hex: "#ee4488" },
  { name: "Majestic Magic", hex: "#555570" },
  { name: "Majesty", hex: "#593761" },
  { name: "Major Brown", hex: "#5b5149" },
  { name: "Major Magenta", hex: "#f246a7" },
  { name: "Majorelle Gardens", hex: "#337766" },
  { name: "Makin it Rain", hex: "#88bb55" },
  { name: "Malevolent Mauve", hex: "#bb6688" },
  { name: "Malibu", hex: "#66b7e1" },
  { name: "Malibu Sun", hex: "#fff2d9" },
  { name: "Mallard", hex: "#254855" },
  { name: "Malt", hex: "#ddcfbc" },
  { name: "Mamba", hex: "#766d7c" },
  { name: "Man Cave", hex: "#816045" },
  { name: "Mana Tree", hex: "#4f7942" },
  { name: "Mandarin", hex: "#f37a48" },
  { name: "Mandarin Rind", hex: "#f1903d" },
  { name: "Manga Pink", hex: "#f5b9d8" },
  { name: "Mangala Pink", hex: "#e781a6" },
  { name: "Mango", hex: "#ffa62b" },
  { name: "Mango Cheesecake", hex: "#fbedda" },
  { name: "Mango Latte", hex: "#ffbb4d" },
  { name: "Mango Madness", hex: "#fd8c23" },
  { name: "Mango Tango", hex: "#ff8243" },
  { name: "Mangrove", hex: "#757461" },
  { name: "Manhattan", hex: "#e2af80" },
  { name: "Maniac Green", hex: "#009000" },
  { name: "Maniac Mansion", hex: "#004058" },
  { name: "Mantis", hex: "#74c365" },
  { name: "Maple Syrup", hex: "#bb9351" },
  { name: "Maraschino", hex: "#ff2600" },
  { name: "Marble Grape", hex: "#dee2c7" },
  { name: "Marble Quarry", hex: "#e2dcd7" },
  { name: "Marble White", hex: "#f2f0e6" },
  { name: "Marigold", hex: "#fcc006" },
  { name: "Marilyn MonRouge", hex: "#c9001e" },
  { name: "Marina", hex: "#4f84c4" },
  { name: "Marinara Red", hex: "#ff0008" },
  { name: "Marine", hex: "#042e60" },
  { name: "Mario", hex: "#e4000f" },
  { name: "Maritime", hex: "#bdcfea" },
  { name: "Maritime Outpost", hex: "#1e4581" },
  { name: "Maroon", hex: "#800000" },
  { name: "Mars", hex: "#ad6242" },
  { name: "Marsh Fog", hex: "#c6d8c7" },
  { name: "Marshmallow", hex: "#f0eee4" },
  { name: "Marshmallow Heart", hex: "#f9dce3" },
  { name: "Marsupilami", hex: "#fdf200" },
  { name: "Martian", hex: "#aea132" },
  { name: "Martian Cerulean", hex: "#57958b" },
  { name: "Martian Colony", hex: "#e5750f" },
  { name: "Martini", hex: "#b7a8a3" },
  { name: "Masala", hex: "#57534b" },
  { name: "Mascarpone", hex: "#ece6d4" },
  { name: "Master Chief", hex: "#507d2a" },
  { name: "Master Key", hex: "#ddcc88" },
  { name: "Master Nacho", hex: "#ffb81b" },
  { name: "Master Sword Blue", hex: "#00ffee" },
  { name: "Matcha Mecha", hex: "#9faf6c" },
  { name: "Matt Black", hex: "#151515" },
  { name: "Matt Blue", hex: "#2c6fbb" },
  { name: "Matt Demon", hex: "#dd4433" },
  { name: "Matt Green", hex: "#39ad48" },
  { name: "Matt Lilac", hex: "#dec6d3" },
  { name: "Matt Pink", hex: "#ffb6c1" },
  { name: "Matt Purple", hex: "#9370db" },
  { name: "Matt White", hex: "#ffffd4" },
  { name: "Matterhorn", hex: "#524b4b" },
  { name: "Mature Cognac", hex: "#9a463d" },
  { name: "Mauve", hex: "#e0b0ff" },
  { name: "Mauve It", hex: "#bb4466" },
  { name: "Mauve Magic", hex: "#bf91b2" },
  { name: "Mauvelous", hex: "#d6b3c0" },
  { name: "May Green", hex: "#4c9141" },
  { name: "Mayan Treasure", hex: "#ce9844" },
  { name: "Mayonnaise", hex: "#f6eed1" },
  { name: "McNuke", hex: "#33ff11" },
  { name: "Meadow Yellow", hex: "#f7da90" },
  { name: "Mean Girls Lipstick", hex: "#ff00ae" },
  { name: "Meat", hex: "#f08080" },
  { name: "Meatloaf", hex: "#663311" },
  { name: "Mecha Kitty", hex: "#d0c4d3" },
  { name: "Mecha Metal", hex: "#848393" },
  { name: "Medallion", hex: "#c3a679" },
  { name: "Medieval Blue", hex: "#29304e" },
  { name: "Mediterranea", hex: "#32575d" },
  { name: "Mediterranean Sea", hex: "#1e8cab" },
  { name: "Medium Roast", hex: "#3c2005" },
  { name: "Medlar", hex: "#d5d7bf" },
  { name: "Mega Metal Mecha", hex: "#dfcbcf" },
  { name: "Megaman", hex: "#3cbcfc" },
  { name: "Melancholia", hex: "#12390d" },
  { name: "Melanzane", hex: "#342931" },
  { name: "Mellow Apricot", hex: "#f8b878" },
  { name: "Mellow Mango", hex: "#cc4400" },
  { name: "Mellow Melon", hex: "#ee2266" },
  { name: "Mellow Mint", hex: "#ddedbd" },
  { name: "Mellow Yellow", hex: "#f8de7f" },
  { name: "Melodramatic Magenta", hex: "#dd22aa" },
  { name: "Melon", hex: "#ff7855" },
  { name: "Melondrama", hex: "#ee8170" },
  { name: "Melting Glacier", hex: "#e9f9f5" },
  { name: "Melting Point", hex: "#cbe1e4" },
  { name: "Memory Lane", hex: "#c7d1db" },
  { name: "Mental Floss", hex: "#deb4c5" },
  { name: "Menthol Kiss", hex: "#a0e2d4" },
  { name: "Mercurial", hex: "#b6b0a9" },
  { name: "Mercury", hex: "#ebebeb" },
  { name: "Merguez", hex: "#650021" },
  { name: "Meringue", hex: "#f3e4b3" },
  { name: "Merino", hex: "#e1dbd0" },
  { name: "Merlot", hex: "#730039" },
  { name: "Merlot Fields", hex: "#712735" },
  { name: "Merlot Magic", hex: "#b64055" },
  { name: "Mermaid Blues", hex: "#004477" },
  { name: "Mermaid Dreams", hex: "#0088bb" },
  { name: "Mermaid Tears", hex: "#d9e6a6" },
  { name: "Mermaid's Kiss", hex: "#59c8a5" },
  { name: "Metal", hex: "#babfbc" },
  { name: "Metal Gear", hex: "#a2c3db" },
  { name: "Metal Petal", hex: "#b090b2" },
  { name: "Meteor", hex: "#bb7431" },
  { name: "Meteor Shower", hex: "#5533ff" },
  { name: "Meteorite", hex: "#4a3b6a" },
  { name: "Methadone", hex: "#cc2233" },
  { name: "Metroid Red", hex: "#f83800" },
  { name: "Metropolis", hex: "#61584f" },
  { name: "Metropolitan Silhouette", hex: "#3e4244" },
  { name: "Mexican Chile", hex: "#d16d76" },
  { name: "Mexican Standoff", hex: "#ec9f76" },
  { name: "Microchip", hex: "#babcc0" },
  { name: "Midnight", hex: "#03012d" },
  { name: "Midnight Dreams", hex: "#002233" },
  { name: "Midnight Express", hex: "#21263a" },
  { name: "Midnight in Tokyo", hex: "#000088" },
  { name: "Midnight Interlude", hex: "#32496f" },
  { name: "Midnight Melancholia", hex: "#002266" },
  { name: "Midnight Pines", hex: "#17240b" },
  { name: "Midnight Serenade", hex: "#41434e" },
  { name: "Midnight Shadow", hex: "#566373" },
  { name: "Midnight Sky", hex: "#424753" },
  { name: "Midori", hex: "#2a603b" },
  { name: "Midsummer Nights", hex: "#0011ee" },
  { name: "Mighty Mauve", hex: "#8f7f85" },
  { name: "Mikado Yellow", hex: "#ffc40c" },
  { name: "Militant Vegan", hex: "#229955" },
  { name: "Milk", hex: "#fdfff5" },
  { name: "Milk and Cookies", hex: "#e9e1df" },
  { name: "Milk Chocolate", hex: "#7f4e1e" },
  { name: "Milk Foam", hex: "#f6ffe8" },
  { name: "Milk Mustache", hex: "#faf3e6" },
  { name: "Milk Tooth", hex: "#faebd7" },
  { name: "Mille-Feuille", hex: "#efc87d" },
  { name: "Millennial Pink", hex: "#f6c8c1" },
  { name: "Million Grey", hex: "#999999" },
  { name: "Mimi Pink", hex: "#ffdae9" },
  { name: "Mimosa", hex: "#f5e9d5" },
  { name: "Mindaro", hex: "#daea6f" },
  { name: "Minestrone", hex: "#c72616" },
  { name: "Ming", hex: "#407577" },
  { name: "Minion Yellow", hex: "#fed55d" },
  { name: "Mink", hex: "#8a7561" },
  { name: "Minotaurus Brown", hex: "#882211" },
  { name: "Mint", hex: "#3eb489" },
  { name: "Mint Chip", hex: "#cfebea" },
  { name: "Mint Coffee", hex: "#ccffee" },
  { name: "Mint Twist", hex: "#98cbba" },
  { name: "Mint-o-licious", hex: "#b6e9c8" },
  { name: "Mintastic", hex: "#afffd5" },
  { name: "Minted Blueberry Lemonade", hex: "#b32651" },
  { name: "Mintnight", hex: "#7cbbae" },
  { name: "Minty Paradise", hex: "#00ffbb" },
  { name: "Minute Mauve", hex: "#f2e4f5" },
  { name: "Mississippi River", hex: "#3b638c" },
  { name: "Misty Cold Sea", hex: "#83bbc1" },
  { name: "Misty Marsh", hex: "#d3e1d3" },
  { name: "Misty Morning", hex: "#b2c8bd" },
  { name: "Misty Mountains", hex: "#c0d0e6" },
  { name: "Mithril", hex: "#878787" },
  { name: "Miyamoto Red", hex: "#e4030f" },
  { name: "Moccasin", hex: "#fbebd6" },
  { name: "Mocha", hex: "#9d7651" },
  { name: "Mocha Ice", hex: "#dfd2ca" },
  { name: "Mocha Madness", hex: "#8b6b58" },
  { name: "Mochaccino", hex: "#945200" },
  { name: "Mochito", hex: "#8efa00" },
  { name: "Modern Monument", hex: "#d6d6d1" },
  { name: "Moelleux Au Chocolat", hex: "#553311" },
  { name: "Mohalla", hex: "#a79b7e" },
  { name: "Mojito", hex: "#e4f3e0" },
  { name: "Molasses", hex: "#574a47" },
  { name: "Mole", hex: "#392d2b" },
  { name: "Molten Caramel", hex: "#bb7a39" },
  { name: "Molten Core", hex: "#ff5800" },
  { name: "Mom's Pancake", hex: "#f5c553" },
  { name: "Momo Peach", hex: "#f47983" },
  { name: "Mona Lisa", hex: "#ff9889" },
  { name: "Monet Magic", hex: "#c1acc3" },
  { name: "Money", hex: "#7b9a6d" },
  { name: "Monkey Island", hex: "#553b39" },
  { name: "Monstera", hex: "#5f674b" },
  { name: "Monstrous Green", hex: "#22cc11" },
  { name: "Mont Blanc", hex: "#9eb6d8" },
  { name: "Montezuma Gold", hex: "#eecc44" },
  { name: "Montreux Blue", hex: "#5879a2" },
  { name: "Monument", hex: "#84898c" },
  { name: "Monument Valley", hex: "#ad5c34" },
  { name: "Monza", hex: "#c7031e" },
  { name: "Moon Base", hex: "#7d7d77" },
  { name: "Moon Glow", hex: "#f5f3ce" },
  { name: "Moon Landing", hex: "#a7a7a7" },
  { name: "Moon Rock", hex: "#958b84" },
  { name: "Moon Veil", hex: "#8d99b1" },
  { name: "Moondance", hex: "#e5decc" },
  { name: "Moonless Mystery", hex: "#1e2433" },
  { name: "Moonless Night", hex: "#2f2d30" },
  { name: "Moonlight", hex: "#f6eed5" },
  { name: "Moonlit Forest", hex: "#3e6d6a" },
  { name: "Moonraker", hex: "#c0b2d7" },
  { name: "Moonscape", hex: "#725f69" },
  { name: "Moonwalk", hex: "#bebec4" },
  { name: "Moor-Monster", hex: "#1f5429" },
  { name: "Moorland", hex: "#a6ab9b" },
  { name: "Morel", hex: "#685c53" },
  { name: "Morning Bread", hex: "#e7e6de" },
  { name: "Morning Mist", hex: "#e5edf1" },
  { name: "Morning Snow", hex: "#f5f4ed" },
  { name: "Moroccan Blue", hex: "#0f4e67" },
  { name: "Morocco", hex: "#b67267" },
  { name: "Morris Leaf", hex: "#c2d3af" },
  { name: "Mosque", hex: "#005f5b" },
  { name: "Moss", hex: "#009051" },
  { name: "Mosslands", hex: "#779966" },
  { name: "Mossy", hex: "#857349" },
  { name: "Moth", hex: "#d2cbaf" },
  { name: "Mother Earth", hex: "#849c8d" },
  { name: "Mother Nature", hex: "#bde1c4" },
  { name: "Mother's Milk", hex: "#f7edca" },
  { name: "Motherland", hex: "#bcb667" },
  { name: "Mothy", hex: "#cebbb3" },
  { name: "Mount Eden", hex: "#e7efe0" },
  { name: "Mount Olympus", hex: "#d4ffff" },
  { name: "Mountain Dew", hex: "#cfe2e0" },
  { name: "Mountain Peak", hex: "#e9e0d4" },
  { name: "Mountain View", hex: "#2e3d30" },
  { name: "Moutarde de Bénichon", hex: "#bf9005" },
  { name: "Ms. Pac-Man Kiss", hex: "#ff00aa" },
  { name: "Mt. Rushmore", hex: "#7f8181" },
  { name: "Muddy", hex: "#a13905" },
  { name: "Muddy Brown", hex: "#886806" },
  { name: "Muddy Green", hex: "#657432" },
  { name: "Muddy Mauve", hex: "#e4b3cc" },
  { name: "Muddy Olive", hex: "#4b5d46" },
  { name: "Muddy Quicksand", hex: "#c3988b" },
  { name: "Muddy Rose", hex: "#e2beb4" },
  { name: "Muddy Yellow", hex: "#bfac05" },
  { name: "Muesli", hex: "#9e7e53" },
  { name: "Mulberry", hex: "#920a4e" },
  { name: "Mule", hex: "#827b77" },
  { name: "Mulled Cider", hex: "#a18162" },
  { name: "Mummy's Tomb", hex: "#828e84" },
  { name: "Munch On Melon", hex: "#f23e67" },
  { name: "Munsell Blue", hex: "#0093af" },
  { name: "Munsell Yellow", hex: "#efcc00" },
  { name: "Murasaki", hex: "#4f284b" },
  { name: "Murderous Magenta", hex: "#b3205f" },
  { name: "Murmur", hex: "#d2d8d2" },
  { name: "Muscat Blanc", hex: "#ebe2cf" },
  { name: "Mushroom", hex: "#bdaca3" },
  { name: "Mushroom Forest", hex: "#8e8062" },
  { name: "Mushroom Risotto", hex: "#dbd0ca" },
  { name: "Mustang", hex: "#5e4a47" },
  { name: "Mustard", hex: "#ceb301" },
  { name: "Mustard Musketeers", hex: "#d5a129" },
  { name: "Mustard Seed", hex: "#c69f26" },
  { name: "Mutabilis", hex: "#c29594" },
  { name: "Muted Berry", hex: "#91788c" },
  { name: "Muted Blue", hex: "#3b719f" },
  { name: "Muted Clay", hex: "#d29380" },
  { name: "Muted Green", hex: "#5fa052" },
  { name: "Muted Lime", hex: "#d1c87c" },
  { name: "Muted Mauve", hex: "#b3a9a3" },
  { name: "Muted Pink", hex: "#d1768f" },
  { name: "Muted Purple", hex: "#805b87" },
  { name: "MVS Red", hex: "#ee0000" },
  { name: "Mykonos", hex: "#387abe" },
  { name: "Myrtle", hex: "#21421e" },
  { name: "Mysterious Blue", hex: "#3e7a85" },
  { name: "Mysterious Depths", hex: "#060929" },
  { name: "Mysterious Waters", hex: "#27454a" },
  { name: "Mystery Mint", hex: "#bbefd3" },
  { name: "Mystic Blue", hex: "#48a8d0" },
  { name: "Mystic Magenta", hex: "#e02e82" },
  { name: "Mystic White", hex: "#ebebe9" },
  { name: "Mystical Shadow", hex: "#352b30" },
  { name: "Mystifying Magenta", hex: "#c920b0" },
  { name: "Nacho", hex: "#ffcb5d" },
  { name: "Nacho Cheese", hex: "#ffbb00" },
  { name: "Naga Viper Pepper", hex: "#ed292b" },
  { name: "Naked Noodle", hex: "#f7cb6e" },
  { name: "Namibia", hex: "#7c6d61" },
  { name: "Naples Yellow", hex: "#fada5f" },
  { name: "Napoleonic Blue", hex: "#2c4170" },
  { name: "Narwhal Grey", hex: "#080813" },
  { name: "Nattō", hex: "#c79843" },
  { name: "Natural Light", hex: "#f1ebc8" },
  { name: "Natural Orchestra", hex: "#4c9c77" },
  { name: "Natural Order", hex: "#77b033" },
  { name: "Natural Wool", hex: "#fff6d7" },
  { name: "Nature", hex: "#bfd5b3" },
  { name: "Naughty Hottie", hex: "#ba403a" },
  { name: "Nautical", hex: "#2e4a7d" },
  { name: "Nautical Creatures", hex: "#295c7a" },
  { name: "Nautilus", hex: "#273c5a" },
  { name: "Naval", hex: "#41729f" },
  { name: "Naval Adventures", hex: "#072688" },
  { name: "Naval Blue", hex: "#384b6b" },
  { name: "Naval Night", hex: "#011c39" },
  { name: "Near Moon", hex: "#5ee7df" },
  { name: "Nebula", hex: "#a104c3" },
  { name: "Nebulous", hex: "#c4b9b8" },
  { name: "Necklace Pearl", hex: "#f6eeed" },
  { name: "Nectar", hex: "#ecdacd" },
  { name: "Nectar Jackpot", hex: "#f0d38f" },
  { name: "Nectarine", hex: "#ff8656" },
  { name: "Negishi Green", hex: "#938b4b" },
  { name: "Negroni", hex: "#eec7a2" },
  { name: "Neo Mint", hex: "#aaffcc" },
  { name: "Neon Blue", hex: "#04d9ff" },
  { name: "Neon Boneyard", hex: "#dfc5fe" },
  { name: "Neon Carrot", hex: "#ff9832" },
  { name: "Neon Fuchsia", hex: "#fe4164" },
  { name: "Neon Green", hex: "#39ff14" },
  { name: "Neon Pink", hex: "#fe019a" },
  { name: "Neon Purple", hex: "#bc13fe" },
  { name: "Neon Red", hex: "#ff073a" },
  { name: "Neon Romance", hex: "#e9023a" },
  { name: "Neon Rose", hex: "#ff0080" },
  { name: "Neon Violet", hex: "#674876" },
  { name: "Neon Yellow", hex: "#cfff04" },
  { name: "Neptune Green", hex: "#7fbb9e" },
  { name: "Nero", hex: "#252525" },
  { name: "Nervous Neon Pink", hex: "#ff6ec7" },
  { name: "Netherworld", hex: "#881111" },
  { name: "Nettle", hex: "#bbac7d" },
  { name: "Never Forget", hex: "#a67283" },
  { name: "Nevermind Nirvana", hex: "#7bc8f6" },
  { name: "New Gold", hex: "#ead151" },
  { name: "New Love", hex: "#c6bbdb" },
  { name: "Niagara Falls", hex: "#cbe3ee" },
  { name: "Nickel", hex: "#929292" },
  { name: "Nicotine Gold", hex: "#eebb33" },
  { name: "Night Edition", hex: "#20586d" },
  { name: "Night Kite", hex: "#005572" },
  { name: "Night Market", hex: "#4c6177" },
  { name: "Night Mode", hex: "#234e86" },
  { name: "Night Owl", hex: "#5d7b89" },
  { name: "Night Rendezvous", hex: "#66787e" },
  { name: "Night Rider", hex: "#332e2e" },
  { name: "Night Sky", hex: "#2a2a35" },
  { name: "Night Snow", hex: "#aaccff" },
  { name: "Night Watch", hex: "#3c4f4e" },
  { name: "Nightfall", hex: "#43535e" },
  { name: "Nightlife", hex: "#27426b" },
  { name: "Nightly Activities", hex: "#2e5090" },
  { name: "Nightly Expedition", hex: "#221188" },
  { name: "Nightmare", hex: "#112211" },
  { name: "Nile", hex: "#b4bb85" },
  { name: "Nimbus Cloud", hex: "#d5d5d8" },
  { name: "Ninja Princess", hex: "#75528b" },
  { name: "Ninja Turtle", hex: "#94b1a9" },
  { name: "Nipple", hex: "#bb7777" },
  { name: "Nippon", hex: "#bc002c" },
  { name: "Nirvana", hex: "#a2919b" },
  { name: "No Way Rosé", hex: "#fbaa95" },
  { name: "Noble Black", hex: "#202124" },
  { name: "Noble Knight", hex: "#394d78" },
  { name: "Noble Plum", hex: "#871f78" },
  { name: "Noble Red", hex: "#92181d" },
  { name: "Nocturnal", hex: "#767d86" },
  { name: "Nocturnal Expedition", hex: "#114c5a" },
  { name: "Nocturne", hex: "#344d58" },
  { name: "Nocturne Blue", hex: "#37525f" },
  { name: "Nocturne Red", hex: "#7a4b56" },
  { name: "Noir", hex: "#312b27" },
  { name: "Noir Fiction", hex: "#150811" },
  { name: "Nomad", hex: "#a19986" },
  { name: "Noodles", hex: "#f9e3b4" },
  { name: "Nordic Breeze", hex: "#d3dde7" },
  { name: "Nordic Noir", hex: "#003344" },
  { name: "North Atlantic", hex: "#536d70" },
  { name: "North Star", hex: "#f2dea4" },
  { name: "Northern Star", hex: "#ffffea" },
  { name: "Northwind", hex: "#cee5e9" },
  { name: "Norway", hex: "#a4b88f" },
  { name: "Not Yet Caramel", hex: "#b1714c" },
  { name: "Not Yo Cheese", hex: "#ffc12c" },
  { name: "Nougat", hex: "#b69885" },
  { name: "Nouveau-Riche", hex: "#ffbb77" },
  { name: "Novel Lilac", hex: "#c2a4c2" },
  { name: "Noxious", hex: "#89a203" },
  { name: "Nuclear Blast", hex: "#bbff00" },
  { name: "Nuclear Mango", hex: "#ee9933" },
  { name: "Nuclear Meltdown", hex: "#44ee00" },
  { name: "Nuclear Throne", hex: "#00de00" },
  { name: "Nude Flamingo", hex: "#e58f7c" },
  { name: "Nude Lips", hex: "#b5948d" },
  { name: "Nugget", hex: "#bc9229" },
  { name: "Nuit Blanche", hex: "#1e488f" },
  { name: "Nut Cracker", hex: "#816c5b" },
  { name: "Nutmeg", hex: "#7e4a3b" },
  { name: "NYC Taxi", hex: "#f7b731" },
  { name: "Nylon", hex: "#e9e3cb" },
  { name: "Nymph's Delight", hex: "#7b6c8e" },
  { name: "O Tannenbaum", hex: "#005522" },
  { name: "Oak Barrel", hex: "#715636" },
  { name: "Oakwood", hex: "#bda58b" },
  { name: "Oasis", hex: "#0092a3" },
  { name: "Oat Milk", hex: "#dedacd" },
  { name: "Oatmeal", hex: "#cbc3b4" },
  { name: "Oatmeal Cookie", hex: "#eadac6" },
  { name: "Oblivion", hex: "#000435" },
  { name: "Obscure Ogre", hex: "#771908" },
  { name: "Obsidian", hex: "#445055" },
  { name: "Obsidian Shard", hex: "#060313" },
  { name: "Ocean Blue", hex: "#009dc4" },
  { name: "Ocean Blues", hex: "#508693" },
  { name: "Ocean Breeze", hex: "#d3e5eb" },
  { name: "Oceanic Motion", hex: "#1d5c83" },
  { name: "Ochre Spice", hex: "#e96d03" },
  { name: "Oh Boy!", hex: "#bbdaf8" },
  { name: "Oh Em Ghee", hex: "#e3c81c" },
  { name: "Oh My Gold", hex: "#eebb55" },
  { name: "Oh Pistachio", hex: "#abca99" },
  { name: "Old Gold", hex: "#cfb53b" },
  { name: "Old Heart", hex: "#e66a77" },
  { name: "Old Rose", hex: "#c08081" },
  { name: "Old Silver", hex: "#848482" },
  { name: "Old Whiskey", hex: "#ddaa55" },
  { name: "Old World", hex: "#91a8cf" },
  { name: "Olivary", hex: "#6e592c" },
  { name: "Olive", hex: "#808010" },
  { name: "Olive Bark", hex: "#5f5537" },
  { name: "Olive Conquering White", hex: "#e4e5d8" },
  { name: "Olive Leaf", hex: "#4e4b35" },
  { name: "Olive Niçoise", hex: "#88432e" },
  { name: "Olive Tree", hex: "#aba77c" },
  { name: "Olivia", hex: "#996622" },
  { name: "Olympian Blue", hex: "#1a4c8b" },
  { name: "Olympic Blue", hex: "#4f8fe6" },
  { name: "Olympus White", hex: "#d4d8d7" },
  { name: "On Cloud Nine", hex: "#c2e7e8" },
  { name: "Once Bitten", hex: "#bd2f10" },
  { name: "Once in a Blue Moon", hex: "#0044bb" },
  { name: "One Minute to Midnight", hex: "#003388" },
  { name: "One Year of Rain", hex: "#29465b" },
  { name: "Onion Skin", hex: "#eeeddf" },
  { name: "Onsen", hex: "#66eebb" },
  { name: "Onyx", hex: "#464544" },
  { name: "Opal", hex: "#aee0e4" },
  { name: "Opal Fire", hex: "#e49c86" },
  { name: "Opal Flame", hex: "#e95c4b" },
  { name: "Opal Green", hex: "#157954" },
  { name: "Open Book", hex: "#f5f1e5" },
  { name: "Open Seas", hex: "#83afbc" },
  { name: "Opera", hex: "#816575" },
  { name: "Opium", hex: "#987e7e" },
  { name: "Opulent Blue", hex: "#0055ee" },
  { name: "Opulent Lime", hex: "#88dd11" },
  { name: "Opulent Orange", hex: "#f16640" },
  { name: "Opulent Purple", hex: "#673362" },
  { name: "Opulent Turquoise", hex: "#88ddcc" },
  { name: "Orange", hex: "#ffa500" },
  { name: "Orange Crush", hex: "#ee7733" },
  { name: "Orange Delight", hex: "#ffc355" },
  { name: "Orange Juice", hex: "#ff7f00" },
  { name: "Orange Piñata", hex: "#ff6611" },
  { name: "Orangealicious", hex: "#ee5511" },
  { name: "Orangina", hex: "#fec615" },
  { name: "Orb of Discord", hex: "#772299" },
  { name: "Orb of Harmony", hex: "#eedd44" },
  { name: "Orbital", hex: "#6d83bb" },
  { name: "Orbital Kingdom", hex: "#220088" },
  { name: "Orchid", hex: "#7a81ff" },
  { name: "Organic", hex: "#747261" },
  { name: "Oriental Blush", hex: "#d7c6e1" },
  { name: "Oriental Herbs", hex: "#118822" },
  { name: "Oriental Nights", hex: "#4b2c74" },
  { name: "Oriental Olive", hex: "#445533" },
  { name: "Oriental Pink", hex: "#c28e88" },
  { name: "Oriental Ruby", hex: "#ce536b" },
  { name: "Oriental Scent", hex: "#e2bfa8" },
  { name: "Oriental Silk", hex: "#efe5d6" },
  { name: "Oriental Spice", hex: "#8b5131" },
  { name: "Ottoman Red", hex: "#ee2222" },
  { name: "Our Little Secret", hex: "#a84b7a" },
  { name: "Out of the Blue", hex: "#1199ee" },
  { name: "Outback", hex: "#c9a375" },
  { name: "Outer Space", hex: "#314e64" },
  { name: "Over the Hills", hex: "#4d6d08" },
  { name: "Over the Moon", hex: "#abb8d5" },
  { name: "Over the Sky", hex: "#98d5ea" },
  { name: "Overdue Blue", hex: "#4400ff" },
  { name: "Overgrown", hex: "#88dd00" },
  { name: "Overgrown Citadel", hex: "#888844" },
  { name: "Overgrown Mausoleum", hex: "#448833" },
  { name: "Overgrown Temple", hex: "#116611" },
  { name: "Overgrowth", hex: "#88cc33" },
  { name: "Oxblood", hex: "#800020" },
  { name: "Oyster", hex: "#e3d3bf" },
  { name: "Oyster Island", hex: "#efefe5" },
  { name: "Pac-Man", hex: "#ffe737" },
  { name: "Pacific", hex: "#1f595c" },
  { name: "Pacific Blue", hex: "#1ca9c9" },
  { name: "Pacific Depths", hex: "#004488" },
  { name: "Pacific Navy", hex: "#25488a" },
  { name: "Pacific Pleasure", hex: "#167d97" },
  { name: "Packing Paper", hex: "#ba9b5d" },
  { name: "Paella", hex: "#dcc61f" },
  { name: "Paid in Full", hex: "#8c8e65" },
  { name: "Painter's Canvas", hex: "#f9f2de" },
  { name: "Pale Canary", hex: "#f1efa6" },
  { name: "Pale King's Blue", hex: "#abf5ed" },
  { name: "Pale Sky", hex: "#bdf6fe" },
  { name: "Pale Whale", hex: "#b6d3df" },
  { name: "Palladian", hex: "#eee9df" },
  { name: "Palm", hex: "#afaf5e" },
  { name: "Palm Leaf", hex: "#36482f" },
  { name: "Pan Purple", hex: "#657aef" },
  { name: "Pancake", hex: "#f7d788" },
  { name: "Pandora's Box", hex: "#fedbb7" },
  { name: "Panela", hex: "#9b5227" },
  { name: "Pani Puri", hex: "#f4aa53" },
  { name: "Panorama", hex: "#327a88" },
  { name: "Pansy", hex: "#f75394" },
  { name: "Papaya", hex: "#fea166" },
  { name: "Paper Heart", hex: "#f7dbc7" },
  { name: "Paper Hearts", hex: "#cc4466" },
  { name: "Paper Plane", hex: "#f1ece0" },
  { name: "Paper White", hex: "#eef0f3" },
  { name: "Paperwhite", hex: "#f6efdf" },
  { name: "Paprika", hex: "#7c2d37" },
  { name: "Paprika Kisses", hex: "#c24325" },
  { name: "Papyrus", hex: "#999911" },
  { name: "Paradise Bird", hex: "#ff8c55" },
  { name: "Paradise Island", hex: "#5aa7a0" },
  { name: "Paradise Pink", hex: "#e4445e" },
  { name: "Paradiso", hex: "#488084" },
  { name: "Parchment", hex: "#fefcaf" },
  { name: "Parfait", hex: "#c8a6a1" },
  { name: "Paris Paving", hex: "#737274" },
  { name: "Parisian Patina", hex: "#7d9b89" },
  { name: "Parmesan", hex: "#ffffdd" },
  { name: "Parsley Sprig", hex: "#3d7049" },
  { name: "Partial Pink", hex: "#ffedf8" },
  { name: "Party Pig", hex: "#ee99ff" },
  { name: "Passion Flower", hex: "#6d5698" },
  { name: "Passion for Revenge", hex: "#dd0d06" },
  { name: "Passion Potion", hex: "#e398af" },
  { name: "Passionate Plum", hex: "#753a58" },
  { name: "Pasta", hex: "#f7dfaf" },
  { name: "Pasta Luego", hex: "#fae17f" },
  { name: "Pasta Rasta", hex: "#eec474" },
  { name: "Pastel Blue", hex: "#a2bffe" },
  { name: "Pastel Brown", hex: "#836953" },
  { name: "Pastel Day", hex: "#dfd8e1" },
  { name: "Pastel de Nata", hex: "#f2c975" },
  { name: "Pastel Green", hex: "#77dd77" },
  { name: "Pastel Grey", hex: "#cfcfc4" },
  { name: "Pastel Lavender", hex: "#d8a1c4" },
  { name: "Pastel Lilac", hex: "#bdb0d0" },
  { name: "Pastel Magenta", hex: "#f49ac2" },
  { name: "Pastel Mint", hex: "#cef0cc" },
  { name: "Pastel Orange", hex: "#ff964f" },
  { name: "Pastel Pea", hex: "#bee7a5" },
  { name: "Pastel Pink", hex: "#dea5a4" },
  { name: "Pastel Purple", hex: "#b39eb5" },
  { name: "Pastel Red", hex: "#ff6961" },
  { name: "Pastel Smirk", hex: "#deece1" },
  { name: "Pastel Turquoise", hex: "#99c5c4" },
  { name: "Pastel Violet", hex: "#cb99c9" },
  { name: "Pastel Yellow", hex: "#fdfd96" },
  { name: "Pastrami", hex: "#e87175" },
  { name: "Pastry", hex: "#f8deb8" },
  { name: "Patina", hex: "#639283" },
  { name: "Patisserie", hex: "#eddbc8" },
  { name: "Pāua", hex: "#2a2551" },
  { name: "Paved With Gold", hex: "#e8d284" },
  { name: "PCB Green", hex: "#002d04" },
  { name: "Pea", hex: "#a4bf20" },
  { name: "Peace & Quiet", hex: "#e0dac8" },
  { name: "Peach", hex: "#ffb07c" },
  { name: "Peach Beach", hex: "#fdcfa1" },
  { name: "Peach Beauty", hex: "#e7c3ab" },
  { name: "Peach Bud", hex: "#fdb2ab" },
  { name: "Peach Cream", hex: "#fff0db" },
  { name: "Peach Crème Brûlée", hex: "#ffe19d" },
  { name: "Peach Dunes", hex: "#b3695f" },
  { name: "Peach Fizz", hex: "#ffa883" },
  { name: "Peach Fury", hex: "#f88435" },
  { name: "Peach Fuzz", hex: "#ffc7b9" },
  { name: "Peach Puff", hex: "#ffdab9" },
  { name: "Peach Punch", hex: "#f59997" },
  { name: "Peach Scone", hex: "#ffbcbc" },
  { name: "Peach Velour", hex: "#f7b28b" },
  { name: "Peach's Daydream", hex: "#fd9b88" },
  { name: "Peaches of Immortality", hex: "#d98586" },
  { name: "Peachy Feeling", hex: "#ed8666" },
  { name: "Peachy Milk", hex: "#f3e0d8" },
  { name: "Peachy Pinky", hex: "#ff775e" },
  { name: "Peachy-Kini", hex: "#f1bf92" },
  { name: "Peacock Pride", hex: "#006663" },
  { name: "Peanut", hex: "#7a4434" },
  { name: "Peanut Butter", hex: "#be893f" },
  { name: "Peanut Butter Biscuit", hex: "#f7b565" },
  { name: "Peanut Butter Chicken", hex: "#ffb75f" },
  { name: "Peanut Butter Jelly", hex: "#ce4a2d" },
  { name: "Pear", hex: "#d1e231" },
  { name: "Pearl", hex: "#eae0c8" },
  { name: "Pearl Brite", hex: "#e6e6e3" },
  { name: "Pearl Powder", hex: "#faffed" },
  { name: "Pearl White", hex: "#f3f2ed" },
  { name: "Pearly", hex: "#f4e3df" },
  { name: "Pearly Pink", hex: "#ee99cc" },
  { name: "Peas Please", hex: "#8c7f3c" },
  { name: "Peat Brown", hex: "#5a3d29" },
  { name: "Pebble", hex: "#9d9880" },
  { name: "Pebble Beach", hex: "#7f8285" },
  { name: "Pedigrey", hex: "#8f8e8c" },
  { name: "Peek a Blue", hex: "#c5e1e1" },
  { name: "Peekaboo", hex: "#e6dee6" },
  { name: "Pegasus", hex: "#e8e9e4" },
  { name: "Pelati", hex: "#ff3333" },
  { name: "Pelican", hex: "#c1bcac" },
  { name: "Pepper Green", hex: "#007d60" },
  { name: "Peppermint", hex: "#d7e7d0" },
  { name: "Pepperoni", hex: "#aa4400" },
  { name: "Peppy Peacock", hex: "#55ccbb" },
  { name: "Peppy Pineapple", hex: "#ffff44" },
  { name: "Perfect Dark", hex: "#313390" },
  { name: "Perfect Pink", hex: "#e5b3b2" },
  { name: "Perfume Cloud", hex: "#e2c9ce" },
  { name: "Perfume Haze", hex: "#f3e9f7" },
  { name: "Pergament", hex: "#bfa58a" },
  { name: "Peri Peri", hex: "#c62d2c" },
  { name: "Periwinkle", hex: "#8e82fe" },
  { name: "Permafrost", hex: "#98eff9" },
  { name: "Perrywinkle", hex: "#8f8ce7" },
  { name: "Persian Luxury Purple", hex: "#990077" },
  { name: "Persian Melon", hex: "#ffdcbf" },
  { name: "Persian Mosaic", hex: "#206874" },
  { name: "Persian Red", hex: "#cc3333" },
  { name: "Persicus", hex: "#ffb49b" },
  { name: "Persimmon", hex: "#e59b34" },
  { name: "Peru", hex: "#cd853f" },
  { name: "Pestering Pesto", hex: "#119922" },
  { name: "Pesto", hex: "#c1b23e" },
  { name: "Pesto Alla Genovese", hex: "#558800" },
  { name: "Pesto di Pistacchio", hex: "#a7c437" },
  { name: "Pesto di Rucola", hex: "#748a35" },
  { name: "Pesto Rosso", hex: "#bb3333" },
  { name: "Petal of a Dying Rose", hex: "#9f0630" },
  { name: "Petal Pink", hex: "#f2e2e0" },
  { name: "Petite Pink", hex: "#eacacb" },
  { name: "Petrified", hex: "#8b8680" },
  { name: "Petrified Purple", hex: "#9c87c1" },
  { name: "Petrol Slumber", hex: "#243640" },
  { name: "Phantom", hex: "#6e797b" },
  { name: "Phantom Ship", hex: "#2f3434" },
  { name: "Pharmaceutical Green", hex: "#087e34" },
  { name: "Phaser Beam", hex: "#ff4d00" },
  { name: "Pheasant", hex: "#c68463" },
  { name: "Philodendron", hex: "#116356" },
  { name: "Phoenix Red", hex: "#e2725b" },
  { name: "Phosphor Green", hex: "#00aa00" },
  { name: "Piano Black", hex: "#17171a" },
  { name: "Picante", hex: "#8d3f2d" },
  { name: "Piccadilly Purple", hex: "#51588e" },
  { name: "Pickled", hex: "#b3a74b" },
  { name: "Pickled Pineapple", hex: "#eeff33" },
  { name: "Pickled Radish", hex: "#ee1144" },
  { name: "Pie Crust", hex: "#f1d99f" },
  { name: "Piercing Pink", hex: "#dd00ee" },
  { name: "Piercing Red", hex: "#dd1122" },
  { name: "Pig Pink", hex: "#fdd7e4" },
  { name: "Pigeon", hex: "#a9afaa" },
  { name: "Piggy", hex: "#ef98aa" },
  { name: "Piggy Bank", hex: "#ffccbb" },
  { name: "Piglet", hex: "#ffc0c6" },
  { name: "Pika Yellow", hex: "#eee92d" },
  { name: "Pilsener", hex: "#f8f753" },
  { name: "Piment Piquant", hex: "#cc2200" },
  { name: "Pimento", hex: "#dc5d47" },
  { name: "Pimm's", hex: "#c3585c" },
  { name: "Pina Colada", hex: "#f4deb3" },
  { name: "Pinball", hex: "#d3d3d3" },
  { name: "Pinch Me", hex: "#c88ca4" },
  { name: "Pine", hex: "#2b5d34" },
  { name: "Pine Needle", hex: "#334d41" },
  { name: "Pineapple", hex: "#563c0d" },
  { name: "Pineapple Perfume", hex: "#eeee88" },
  { name: "Pineapple Sorbet", hex: "#f7f4da" },
  { name: "Pineapple Whip", hex: "#ead988" },
  { name: "Pink", hex: "#ffc0cb" },
  { name: "Pink Bliss", hex: "#e3abce" },
  { name: "Pink Blush", hex: "#f4acb6" },
  { name: "Pink Elephants", hex: "#ff99ee" },
  { name: "Pink Fit", hex: "#f5a8b2" },
  { name: "Pink Flamingo", hex: "#ff66ff" },
  { name: "Pink Floyd", hex: "#eb9a9d" },
  { name: "Pink Glitter", hex: "#fddfda" },
  { name: "Pink Horror", hex: "#90305d" },
  { name: "Pink Ink", hex: "#ff1476" },
  { name: "Pink Lemonade", hex: "#ffeaeb" },
  { name: "Pink Macaroon", hex: "#eaacc6" },
  { name: "Pink Orchid", hex: "#da70d6" },
  { name: "Pink Palazzo", hex: "#df9f8f" },
  { name: "Pink Panther", hex: "#ff0090" },
  { name: "Pink Party", hex: "#ff55ee" },
  { name: "Pink Pepper", hex: "#ef586c" },
  { name: "Pink Pleasure", hex: "#ffdfe5" },
  { name: "Pink Poison", hex: "#ff007e" },
  { name: "Pink Prestige", hex: "#ee99aa" },
  { name: "Pink Pride", hex: "#ef1de7" },
  { name: "Pink Punk", hex: "#d983bd" },
  { name: "Pink Supremecy", hex: "#ffd9e6" },
  { name: "Pinkalicious", hex: "#ff99ff" },
  { name: "Pinkling", hex: "#eb84f5" },
  { name: "Pinkman", hex: "#dd11ff" },
  { name: "Pinky", hex: "#fc86aa" },
  { name: "Pinky Pickle", hex: "#b96d8e" },
  { name: "Pinky Swear", hex: "#eeaaee" },
  { name: "Pinot Noir", hex: "#605258" },
  { name: "Pirate Gold", hex: "#ba782a" },
  { name: "Pirate Treasure", hex: "#ddca69" },
  { name: "Pirate's Hook", hex: "#b08f42" },
  { name: "Pisco Sour", hex: "#beeb71" },
  { name: "Pistachio", hex: "#93c572" },
  { name: "Pistachio Shell", hex: "#d7cfbb" },
  { name: "Pita", hex: "#f5e7d2" },
  { name: "Pitch Black", hex: "#483c41" },
  { name: "Pitch-Black Forests", hex: "#003322" },
  { name: "Pixel Bleeding", hex: "#bb0022" },
  { name: "Pizza", hex: "#bf8d3c" },
  { name: "Pizza Flame", hex: "#cd2217" },
  { name: "Placebo", hex: "#e7e7e7" },
  { name: "Placebo Yellow", hex: "#fcfbeb" },
  { name: "Planet Earth", hex: "#daddc3" },
  { name: "Planet of the Apes", hex: "#883333" },
  { name: "Plantation", hex: "#3e594c" },
  { name: "Plaster", hex: "#eaeaea" },
  { name: "Plastic Carrot", hex: "#f65d20" },
  { name: "Plastic Clouds", hex: "#f5f0f1" },
  { name: "Plastic Lips", hex: "#aa2266" },
  { name: "Plastic Veggie", hex: "#22ff22" },
  { name: "Platinum", hex: "#e5e4e2" },
  { name: "Platinum Blonde", hex: "#f0e8d7" },
  { name: "Pleasant Pomegranate", hex: "#cc3300" },
  { name: "Pleasant Purple", hex: "#8833aa" },
  { name: "Pleasing Pink", hex: "#f5cdd2" },
  { name: "Pleasure", hex: "#80385c" },
  { name: "Plein Air", hex: "#bfcad6" },
  { name: "Plum", hex: "#5a315d" },
  { name: "Plum Cheese", hex: "#670728" },
  { name: "Plum Highness", hex: "#885577" },
  { name: "Plum Kingdom", hex: "#aa3377" },
  { name: "Plum Perfect", hex: "#aa1155" },
  { name: "Plumbeous", hex: "#5c7287" },
  { name: "Plummy", hex: "#675a75" },
  { name: "Plunge", hex: "#035568" },
  { name: "Plunge Pool", hex: "#00ffcc" },
  { name: "Plushy Pink", hex: "#eab7a8" },
  { name: "Plutonium", hex: "#35fa00" },
  { name: "Poached Egg", hex: "#f5d893" },
  { name: "Poblano", hex: "#077f1b" },
  { name: "Poison Ivy", hex: "#00ad43" },
  { name: "Poison Purple", hex: "#7f01fe" },
  { name: "Poison Purple Paradise", hex: "#b300ff" },
  { name: "Poisonous", hex: "#55ff11" },
  { name: "Poisonous Dart", hex: "#77ff66" },
  { name: "Poisonous Pesto", hex: "#cae80a" },
  { name: "Poisonous Pistachio", hex: "#88ee11" },
  { name: "Poisonous Potion", hex: "#99dd33" },
  { name: "Polar", hex: "#e5f2e7" },
  { name: "Polar Bear", hex: "#eae9e0" },
  { name: "Polar Bear In A Blizzard", hex: "#fcffff" },
  { name: "Polar Expedition", hex: "#c9e7e3" },
  { name: "Polar Wind", hex: "#b4dfed" },
  { name: "Polenta", hex: "#efc47f" },
  { name: "Polished Apple", hex: "#862a2e" },
  { name: "Polished Bronze", hex: "#cd7f32" },
  { name: "Polished Copper", hex: "#b66325" },
  { name: "Polished Gold", hex: "#eeaa55" },
  { name: "Polished Limestone", hex: "#dcd5c8" },
  { name: "Polished Pearl", hex: "#f8edd3" },
  { name: "Polished Silver", hex: "#c5d1da" },
  { name: "Pollen", hex: "#eeeeaa" },
  { name: "Pollination", hex: "#eedd66" },
  { name: "Polly", hex: "#ffcaa4" },
  { name: "Pomegranate", hex: "#c35550" },
  { name: "Pomelo Red", hex: "#e38fac" },
  { name: "Pomodoro", hex: "#c30232" },
  { name: "Pompeian Red", hex: "#a4292e" },
  { name: "Pompelmo", hex: "#ff6666" },
  { name: "Pony", hex: "#c6aa81" },
  { name: "Poodle Skirt", hex: "#ffaebb" },
  { name: "Pool Table", hex: "#039578" },
  { name: "Pool Water", hex: "#2188ff" },
  { name: "Pop That Gum", hex: "#f771b3" },
  { name: "Popcorn", hex: "#f8de8d" },
  { name: "Poppy Flower", hex: "#ec5800" },
  { name: "Poppy Red", hex: "#dc343b" },
  { name: "Porcelain", hex: "#dddcdb" },
  { name: "Porcelain Mint", hex: "#dbe7e1" },
  { name: "Porcelain Skin", hex: "#ffe7eb" },
  { name: "Porcini", hex: "#cca580" },
  { name: "Pork Belly", hex: "#f8e0e7" },
  { name: "Port Wine", hex: "#a17a83" },
  { name: "Poseidon", hex: "#123955" },
  { name: "Poseidon Jr.", hex: "#66eeee" },
  { name: "Possessed Purple", hex: "#881166" },
  { name: "Possessed Red", hex: "#c2264d" },
  { name: "Pot of Gold", hex: "#f6cd23" },
  { name: "Potato Chip", hex: "#fddc57" },
  { name: "Powder Blush", hex: "#d8948b" },
  { name: "Powder Puff", hex: "#ffeff3" },
  { name: "Powdered", hex: "#f9f2e7" },
  { name: "Prairie Land", hex: "#e2cc9c" },
  { name: "Prairie Winds", hex: "#e8e6d9" },
  { name: "Praise the Sun", hex: "#f3f4d9" },
  { name: "Precious Copper", hex: "#885522" },
  { name: "Precious Persimmon", hex: "#ff7744" },
  { name: "Precious Pumpkin", hex: "#e16233" },
  { name: "Prehistoric Pink", hex: "#c3738d" },
  { name: "Preppy Rose", hex: "#d1668f" },
  { name: "Pressing my Luck", hex: "#00cc11" },
  { name: "Pretty in Pink", hex: "#fabfe4" },
  { name: "Pretty in Prune", hex: "#6b295a" },
  { name: "Pretty Pastry", hex: "#dfcdb2" },
  { name: "Pretty Twilight Night", hex: "#254770" },
  { name: "Prickly Pink", hex: "#f42c93" },
  { name: "Prickly Purple", hex: "#a264ba" },
  { name: "Primal Green", hex: "#11875d" },
  { name: "Primal Rage", hex: "#f4301c" },
  { name: "Primavera", hex: "#6fa77a" },
  { name: "Primrose", hex: "#d6859f" },
  { name: "Prince Charming", hex: "#cc2277" },
  { name: "Princess Blue Feather", hex: "#cceeee" },
  { name: "Princess Peach", hex: "#f878f8" },
  { name: "Prismarine", hex: "#117777" },
  { name: "Pristine Oceanic", hex: "#00ccbb" },
  { name: "Pristine Seas", hex: "#007799" },
  { name: "Professor Plum", hex: "#393540" },
  { name: "Prom Corsage", hex: "#e7c3e7" },
  { name: "Prom Queen", hex: "#9b1dcd" },
  { name: "Promenade", hex: "#f8f6df" },
  { name: "Prometheus Orange", hex: "#f4581e" },
  { name: "Promiscuous Pink", hex: "#bb11ee" },
  { name: "Prophet Violet", hex: "#6f58a6" },
  { name: "Prosciutto", hex: "#e0b4a4" },
  { name: "Prosecco", hex: "#fad6a5" },
  { name: "Prune", hex: "#701c11" },
  { name: "Prunella", hex: "#864788" },
  { name: "Psychedelic Purple", hex: "#dd00ff" },
  { name: "Pucker Up", hex: "#ff1177" },
  { name: "Puffy Cloud", hex: "#d2def2" },
  { name: "Puffy Pillow", hex: "#e8e5de" },
  { name: "Pulp", hex: "#e18289" },
  { name: "Puma", hex: "#96711c" },
  { name: "Pumping Spice", hex: "#f7504a" },
  { name: "Pumpkin", hex: "#ff7518" },
  { name: "Pumpkin Cat", hex: "#eb7b07" },
  { name: "Pumpkin Pie", hex: "#e99e56" },
  { name: "Punch", hex: "#dc4333" },
  { name: "Punk Rock Purple", hex: "#bb11aa" },
  { name: "Pure Blue", hex: "#0203e2" },
  { name: "Pure Passion", hex: "#b40039" },
  { name: "Pure Pleasure", hex: "#f51360" },
  { name: "Pure Sunshine", hex: "#ffee15" },
  { name: "Purple", hex: "#800080" },
  { name: "Purple Climax", hex: "#8800ff" },
  { name: "Purple Emperor", hex: "#6633bb" },
  { name: "Purple Excellency", hex: "#943589" },
  { name: "Purple Haze", hex: "#807396" },
  { name: "Purple Heart", hex: "#69359c" },
  { name: "Purple Illusion", hex: "#b8b8f8" },
  { name: "Purple Ink", hex: "#9a2ca0" },
  { name: "Purple Noir", hex: "#322c56" },
  { name: "Purple Passion", hex: "#683d62" },
  { name: "Purple Pirate", hex: "#bb00aa" },
  { name: "Purple Pizzazz", hex: "#fe4eda" },
  { name: "Purple Pleasures", hex: "#81459e" },
  { name: "Purple Poodle", hex: "#dab4cc" },
  { name: "Purple Pristine", hex: "#7733aa" },
  { name: "Purple Prose", hex: "#543254" },
  { name: "Purple Protégé", hex: "#593569" },
  { name: "Purple Rain", hex: "#7442c8" },
  { name: "Purple Sultan", hex: "#853682" },
  { name: "Purple Velour", hex: "#581a57" },
  { name: "Purple Void", hex: "#442244" },
  { name: "Purple's Baby Sister", hex: "#eec3ee" },
  { name: "Put on Ice", hex: "#c8ddea" },
  { name: "Pyramid", hex: "#9f7d4f" },
  { name: "Quack Quack", hex: "#ffe989" },
  { name: "Queen Blue", hex: "#436b95" },
  { name: "Queen of Gardens", hex: "#bbdd55" },
  { name: "Queen of Hearts", hex: "#98333a" },
  { name: "Queen of Trees", hex: "#1c401f" },
  { name: "Queer Blue", hex: "#88ace0" },
  { name: "Quiche Lorraine", hex: "#fed56f" },
  { name: "Quick-Freeze", hex: "#bddbe1" },
  { name: "Quicksand", hex: "#ac9884" },
  { name: "Quicksilver", hex: "#a6a6a6" },
  { name: "Quiet Abyss", hex: "#160435" },
  { name: "Quill Grey", hex: "#cbc9c0" },
  { name: "Quince", hex: "#d4cb60" },
  { name: "Race the Sun", hex: "#eef3d0" },
  { name: "Radiant Hulk", hex: "#10f144" },
  { name: "Radiant Sunrise", hex: "#eebe1b" },
  { name: "Radical Red", hex: "#ff355e" },
  { name: "Radioactive", hex: "#89fe05" },
  { name: "Radish", hex: "#a42e41" },
  { name: "Radishical", hex: "#ec4872" },
  { name: "Rage", hex: "#ff1133" },
  { name: "Raging Raisin", hex: "#aa3333" },
  { name: "Rainforest", hex: "#009a70" },
  { name: "Rainy Mood", hex: "#4499aa" },
  { name: "Rajah", hex: "#fbab60" },
  { name: "Rampant Rhubarb", hex: "#603231" },
  { name: "Rampart", hex: "#bcb7b1" },
  { name: "Ranch House", hex: "#7b645a" },
  { name: "Rapeseed", hex: "#c19a13" },
  { name: "Rapture's Light", hex: "#f6f3e7" },
  { name: "Rapunzel Silver", hex: "#d2d2d4" },
  { name: "Rare Blue", hex: "#0044ff" },
  { name: "Rare Red", hex: "#dd1133" },
  { name: "Raspberry", hex: "#b00149" },
  { name: "Raspberry Mousse", hex: "#e06f8b" },
  { name: "Raspberry Romantic", hex: "#972b51" },
  { name: "Raven", hex: "#0b0b0b" },
  { name: "Raven's Coat", hex: "#030205" },
  { name: "Ravenclaw", hex: "#0a0555" },
  { name: "Ravioli al Limone", hex: "#fade79" },
  { name: "Razzle Dazzle", hex: "#ba417b" },
  { name: "Re-Entry Red", hex: "#cd0317" },
  { name: "Reading Tea Leaves", hex: "#7d5d5e" },
  { name: "Realm of the Underworld", hex: "#114411" },
  { name: "Rebellion Red", hex: "#cc0404" },
  { name: "Red", hex: "#ff0000" },
  { name: "Red Alert", hex: "#ff0f0f" },
  { name: "Red Baron", hex: "#bb0011" },
  { name: "Red Carpet", hex: "#bc2026" },
  { name: "Red Cent", hex: "#ad654c" },
  { name: "Red Devil", hex: "#860111" },
  { name: "Red Elegance", hex: "#85464b" },
  { name: "Red Flag", hex: "#ff2244" },
  { name: "Red Herring", hex: "#dd1144" },
  { name: "Red Hot Chili Pepper", hex: "#db1d27" },
  { name: "Red Mana", hex: "#f95554" },
  { name: "Red Menace", hex: "#aa2121" },
  { name: "Red My Mind", hex: "#994341" },
  { name: "Red Octopus", hex: "#773243" },
  { name: "Red Panda", hex: "#c34b1b" },
  { name: "Red Pegasus", hex: "#dd0000" },
  { name: "Red Radish", hex: "#ee3344" },
  { name: "Red Reign", hex: "#800707" },
  { name: "Red Ribbon", hex: "#ed0a3f" },
  { name: "Red Riding Hood", hex: "#fe2713" },
  { name: "Red Robin", hex: "#7d4138" },
  { name: "Red Stop", hex: "#ff2222" },
  { name: "Red Tape", hex: "#cc1133" },
  { name: "Red Wrath of Zeus", hex: "#e0180c" },
  { name: "Redolency", hex: "#ea8a7a" },
  { name: "Redstone", hex: "#e46b71" },
  { name: "Redsurrection", hex: "#d90b0b" },
  { name: "RedЯum", hex: "#ff2200" },
  { name: "Reign of Tomatoes", hex: "#f7250b" },
  { name: "Reptile Revenge", hex: "#5e582b" },
  { name: "Restful Rain", hex: "#f1f2dd" },
  { name: "Retro Nectarine", hex: "#ef7d16" },
  { name: "Retro Pink Pop", hex: "#ff0073" },
  { name: "Retro Vibe", hex: "#cb9711" },
  { name: "Rich Gold", hex: "#aa8833" },
  { name: "Ridgeback", hex: "#ef985c" },
  { name: "Ripe Malinka", hex: "#f5576c" },
  { name: "Riverbed", hex: "#86bebe" },
  { name: "Roasted Pepper", hex: "#890a01" },
  { name: "Roastery", hex: "#692302" },
  { name: "Robotic Gods", hex: "#94a2b1" },
  { name: "Rock Lobster", hex: "#f00b52" },
  { name: "Roland-Garros", hex: "#bb5522" },
  { name: "Romantic Embers", hex: "#b23e4f" },
  { name: "Romantic Thriller", hex: "#a2101b" },
  { name: "Romantic Vampire", hex: "#991166" },
  { name: "Romesco", hex: "#f48101" },
  { name: "Rooftop Garden", hex: "#9ead92" },
  { name: "Root Beer", hex: "#714a41" },
  { name: "Rosé", hex: "#f7746b" },
  { name: "Rose Laffy Taffy", hex: "#a6465b" },
  { name: "Rosecco", hex: "#eebbdd" },
  { name: "Rosemarried", hex: "#819b4f" },
  { name: "Rosemary", hex: "#405e5c" },
  { name: "Roses are Red", hex: "#aa3646" },
  { name: "Roses in the Snow", hex: "#e7aecd" },
  { name: "Rosetti", hex: "#cf929a" },
  { name: "Rosewood", hex: "#65000b" },
  { name: "Rosy Brown", hex: "#bc8f8f" },
  { name: "Rouge", hex: "#ab1239" },
  { name: "Rough Asphalt", hex: "#bdbebf" },
  { name: "Royal", hex: "#0c1793" },
  { name: "Royal Decree", hex: "#403547" },
  { name: "Royal Lavender", hex: "#7851a9" },
  { name: "Royal Milk Tea", hex: "#f7cfb4" },
  { name: "Royal Neptune", hex: "#1c3b42" },
  { name: "Royal Night", hex: "#2b3191" },
  { name: "Royal Plum", hex: "#654161" },
  { name: "Royal Purpleness", hex: "#881177" },
  { name: "Royal Star", hex: "#fede4f" },
  { name: "Royalty", hex: "#5930a9" },
  { name: "Rubber Ducky", hex: "#facf58" },
  { name: "Ruby", hex: "#ca0147" },
  { name: "Ruby Queen", hex: "#b0063d" },
  { name: "Rubylicious", hex: "#db1459" },
  { name: "Ruined Smores", hex: "#0f1012" },
  { name: "Ruins of Civilization", hex: "#cadece" },
  { name: "Ruins of Metal", hex: "#9b8b84" },
  { name: "Rum", hex: "#716675" },
  { name: "Run Lola Run", hex: "#da2811" },
  { name: "Rural Green", hex: "#8d844d" },
  { name: "Rural Red", hex: "#bb1144" },
  { name: "Rust", hex: "#a83c09" },
  { name: "Rusty Heart", hex: "#a04039" },
  { name: "Ruthless Empress", hex: "#573894" },
  { name: "Sablé", hex: "#f6d8be" },
  { name: "Sable Cloaked", hex: "#c4a7a1" },
  { name: "Sacred Scarlet", hex: "#950c1b" },
  { name: "Sacrifice Altar", hex: "#850101" },
  { name: "Sacro Bosco", hex: "#229911" },
  { name: "Saddle Up", hex: "#ab927a" },
  { name: "Safety Orange", hex: "#ff6600" },
  { name: "Safflower", hex: "#fdae44" },
  { name: "Saffron", hex: "#f4c430" },
  { name: "Saffron Desires", hex: "#c24359" },
  { name: "Sage", hex: "#87ae73" },
  { name: "Sail into the Horizon", hex: "#a3bbdc" },
  { name: "Sail On", hex: "#4575ad" },
  { name: "Sail to the Sea", hex: "#99c3f0" },
  { name: "Sailor", hex: "#445780" },
  { name: "Sailor Boy", hex: "#aebbd0" },
  { name: "Sailor Moon", hex: "#ffee00" },
  { name: "Sakura", hex: "#dfb1b6" },
  { name: "Sakura Night", hex: "#7b6c7c" },
  { name: "Salametti", hex: "#e25e31" },
  { name: "Salami", hex: "#820000" },
  { name: "Salmon", hex: "#ff796c" },
  { name: "Salmon Flush", hex: "#f1c9cc" },
  { name: "Salmon Glow", hex: "#ebb9af" },
  { name: "Salmon Nigiri", hex: "#f9906f" },
  { name: "Salmon Poké Bowl", hex: "#ee7777" },
  { name: "Salmon Sashimi", hex: "#ff7e79" },
  { name: "Salsa Picante", hex: "#ab250b" },
  { name: "Salsa Verde", hex: "#cec754" },
  { name: "Salt", hex: "#efede6" },
  { name: "Salt Caramel", hex: "#d3934d" },
  { name: "Salt Mountain", hex: "#d7fefe" },
  { name: "Salt n Pepa", hex: "#dcd9db" },
  { name: "Salted", hex: "#ebeadc" },
  { name: "Salted Capers", hex: "#a69151" },
  { name: "Salted Caramel Popcorn", hex: "#fdb251" },
  { name: "Salty Ice", hex: "#cce2f3" },
  { name: "Salvia", hex: "#a8b59e" },
  { name: "Samba", hex: "#a2242f" },
  { name: "Sand", hex: "#e2ca76" },
  { name: "Sand Dune", hex: "#e3d2c0" },
  { name: "Sand Ripples", hex: "#c1b7b0" },
  { name: "Sandstorm", hex: "#ecd540" },
  { name: "Sandworm", hex: "#fce883" },
  { name: "Sanguinary", hex: "#f01a4d" },
  { name: "Sanguine", hex: "#6c110e" },
  { name: "Sappanwood", hex: "#9e3d3f" },
  { name: "Sapphire Glitter", hex: "#0033cc" },
  { name: "Sapphire Siren", hex: "#662288" },
  { name: "Sapphire Splendour", hex: "#2425b9" },
  { name: "Sassy Salmon", hex: "#ee7c54" },
  { name: "Satin Chocolate", hex: "#773344" },
  { name: "Satin Cream White", hex: "#fdf3d5" },
  { name: "Satin Deep Black", hex: "#1c1e21" },
  { name: "Satin Lime", hex: "#33ee00" },
  { name: "Saturn", hex: "#fae5bf" },
  { name: "Sauerkraut", hex: "#eee0b9" },
  { name: "Sauna Steam", hex: "#edebe1" },
  { name: "Savannah Grass", hex: "#babc72" },
  { name: "Savory Salmon", hex: "#d19c97" },
  { name: "Savoy Blue", hex: "#4b61d1" },
  { name: "Scampi", hex: "#6f63a0" },
  { name: "Scandinavian Liquorice", hex: "#1a1110" },
  { name: "Scarlet", hex: "#ff2400" },
  { name: "Scarlet Glow", hex: "#cb0103" },
  { name: "Scarlet Splendour", hex: "#cc0c1b" },
  { name: "Scented Spring", hex: "#eed5ee" },
  { name: "Schiaparelli Pink", hex: "#e84998" },
  { name: "School Bus", hex: "#ffd800" },
  { name: "Scoville Highness", hex: "#900405" },
  { name: "Screamin' Green", hex: "#66ff66" },
  { name: "Sea", hex: "#3c9992" },
  { name: "Sea Creature", hex: "#00586d" },
  { name: "Sea Foam", hex: "#87e0cf" },
  { name: "Sea Foam Mist", hex: "#cbdce2" },
  { name: "Sea Glass Teal", hex: "#a0e5d9" },
  { name: "Sea Goddess", hex: "#216987" },
  { name: "Sea Lion", hex: "#7f8793" },
  { name: "Sea of Galilee", hex: "#466590" },
  { name: "Sea Paint", hex: "#00507a" },
  { name: "Sea Salt Rivers", hex: "#5087bd" },
  { name: "Sea Serpent", hex: "#4bc7cf" },
  { name: "Sea Serpent's Tears", hex: "#5511cc" },
  { name: "Seaborn", hex: "#85c2b2" },
  { name: "Seafarer", hex: "#204d68" },
  { name: "Seafoam Splashes", hex: "#b0efce" },
  { name: "Seashell", hex: "#fff5ee" },
  { name: "Seaweed", hex: "#18d17b" },
  { name: "Secret Affair", hex: "#c41661" },
  { name: "Secret Blush", hex: "#e1d2d5" },
  { name: "Secret Garden", hex: "#11aa66" },
  { name: "Secret of Mana", hex: "#4166f5" },
  { name: "Secret Passage", hex: "#372a05" },
  { name: "Secret Path", hex: "#737054" },
  { name: "Secret Scarlet", hex: "#7a0e0e" },
  { name: "Seedling", hex: "#c0cba1" },
  { name: "Self-Love", hex: "#d22b6d" },
  { name: "Semolina", hex: "#ceb899" },
  { name: "Serene", hex: "#dce3e4" },
  { name: "Serene Sea", hex: "#78a7c3" },
  { name: "Sereni Teal", hex: "#76baa8" },
  { name: "Serial Kisses", hex: "#dd3744" },
  { name: "Serious Cloud", hex: "#7d848b" },
  { name: "Serpent Scepter", hex: "#bbcc00" },
  { name: "Serrano Pepper", hex: "#556600" },
  { name: "Sesame", hex: "#baa38b" },
  { name: "Sesame Seed", hex: "#e1d9b8" },
  { name: "Seven Seas", hex: "#4a5c6a" },
  { name: "Shades of Ruby", hex: "#9c0009" },
  { name: "Shadow of Night", hex: "#2a4f61" },
  { name: "Shadow Purple", hex: "#4e334e" },
  { name: "Shadows", hex: "#6b6d6a" },
  { name: "Shady Character", hex: "#4c4b4c" },
  { name: "Shallow Sea", hex: "#9ab8c2" },
  { name: "Shallow Water", hex: "#8af1fe" },
  { name: "Shamrock", hex: "#009e60" },
  { name: "Shark", hex: "#cadcde" },
  { name: "Shattered Ice", hex: "#daeee6" },
  { name: "Shaving Cream", hex: "#e1e5e5" },
  { name: "She Loves Pink", hex: "#e39b96" },
  { name: "Sheikh White", hex: "#efecee" },
  { name: "Shimmering Blue", hex: "#82dbcc" },
  { name: "Shimmering Love", hex: "#ff88cc" },
  { name: "Shin Godzilla", hex: "#9a373f" },
  { name: "Shiny Trumpet", hex: "#ecae58" },
  { name: "Shipmate", hex: "#7aa3cc" },
  { name: "Shipwreck", hex: "#968772" },
  { name: "Shipyard", hex: "#4f6f85" },
  { name: "Shocking Crimson", hex: "#ff0d04" },
  { name: "Shocking Pink", hex: "#fe02a2" },
  { name: "Shōji", hex: "#ded5c7" },
  { name: "Shrimp", hex: "#e29a86" },
  { name: "Shrimp Cocktail", hex: "#f4a461" },
  { name: "Shrimp Toast", hex: "#f7c5a0" },
  { name: "Shrine of Pleasures", hex: "#cc3388" },
  { name: "Shuriken", hex: "#333344" },
  { name: "Sienna", hex: "#a9561e" },
  { name: "Signal Green", hex: "#33ff00" },
  { name: "Silence", hex: "#eaede5" },
  { name: "Silent Film", hex: "#9fa5a5" },
  { name: "Silent Night", hex: "#526771" },
  { name: "Silent Sea", hex: "#3a4a63" },
  { name: "Silk Dessou", hex: "#eee9dc" },
  { name: "Silk for the Gods", hex: "#ecddc9" },
  { name: "Silk Lining", hex: "#fcefe0" },
  { name: "Silk Satin", hex: "#8b4248" },
  { name: "Silk Star", hex: "#f5eec6" },
  { name: "Silken Chocolate", hex: "#b77d5f" },
  { name: "Silken Gold", hex: "#fce17c" },
  { name: "Silken Jade", hex: "#11a39e" },
  { name: "Silken Pebble", hex: "#d0d0c9" },
  { name: "Silken Ruby", hex: "#e81320" },
  { name: "Silkworm", hex: "#eeeecc" },
  { name: "Silky Green", hex: "#bdc2bb" },
  { name: "Silky Mint", hex: "#d7ecd9" },
  { name: "Silver", hex: "#c0c0c0" },
  { name: "Silver Birch", hex: "#d2cfc4" },
  { name: "Silver Bird", hex: "#fbf5f0" },
  { name: "Silver Fern", hex: "#e1ddbf" },
  { name: "Silver Fox", hex: "#bdbcc4" },
  { name: "Silver Lake", hex: "#dedddd" },
  { name: "Silver Lining", hex: "#bdb6ab" },
  { name: "Silver Phoenix", hex: "#ebecf5" },
  { name: "Silver Surfer", hex: "#7e7d88" },
  { name: "Silver-Tongued", hex: "#cdc7c7" },
  { name: "Silverback", hex: "#cbcbcb" },
  { name: "Silverfish", hex: "#8d95aa" },
  { name: "Silverplate", hex: "#c2c0ba" },
  { name: "Single Origin", hex: "#713e39" },
  { name: "Sinister", hex: "#12110e" },
  { name: "Sinsemilla", hex: "#b6bd4a" },
  { name: "Sip of Mint", hex: "#dedfc9" },
  { name: "Siren", hex: "#69293b" },
  { name: "Sizzling Watermelon", hex: "#fa005c" },
  { name: "Skeleton", hex: "#ebdecc" },
  { name: "Sky", hex: "#76d6ff" },
  { name: "Sky Dancer", hex: "#4499ff" },
  { name: "Sky Dive", hex: "#60bfd3" },
  { name: "Sky Fall", hex: "#89c6df" },
  { name: "Sky High", hex: "#a7c2eb" },
  { name: "Skydiver", hex: "#83acd3" },
  { name: "Skyscraper", hex: "#d3dbe2" },
  { name: "Skyvory", hex: "#dcd7cd" },
  { name: "Sleepless Blue", hex: "#badbed" },
  { name: "Sleepy Hollows", hex: "#839c6d" },
  { name: "Slice of Heaven", hex: "#0022ee" },
  { name: "Slightly in Love", hex: "#fce6db" },
  { name: "Slime Girl", hex: "#00bb88" },
  { name: "Slipper Satin", hex: "#bfc1cb" },
  { name: "Slippery Salmon", hex: "#f87e63" },
  { name: "Slippery Soap", hex: "#efedd8" },
  { name: "Slumber", hex: "#2d517c" },
  { name: "Sly Fox", hex: "#804741" },
  { name: "Smalt", hex: "#003399" },
  { name: "Smashing Pumpkins", hex: "#ff5522" },
  { name: "Smell of Garlic", hex: "#d9ddcb" },
  { name: "Smell the Mint", hex: "#bef7cf" },
  { name: "Smiley Face", hex: "#ffc962" },
  { name: "Smoke and Mirrors", hex: "#d9e6e8" },
  { name: "Smoke Dragon", hex: "#ccbbaa" },
  { name: "Smoked Black Coffee", hex: "#3b2f2f" },
  { name: "Smoked Oyster", hex: "#d9d2cd" },
  { name: "Smoked Salmon", hex: "#fa8072" },
  { name: "Smokescreen", hex: "#5e5755" },
  { name: "Smoking Mirror", hex: "#a29587" },
  { name: "Smoky Studio", hex: "#7e8590" },
  { name: "Smooch Rouge", hex: "#d13d4b" },
  { name: "Smooth Pebbles", hex: "#cabab1" },
  { name: "Smouldering Red", hex: "#ca3434" },
  { name: "Snake Fruit", hex: "#db2217" },
  { name: "Snakes in the Grass", hex: "#889717" },
  { name: "Snarky Mint", hex: "#9ae37d" },
  { name: "Sneaky Devil", hex: "#840014" },
  { name: "Sneaky Sesame", hex: "#896a46" },
  { name: "Snow", hex: "#fffafa" },
  { name: "Snow White", hex: "#eeffee" },
  { name: "Snowflake", hex: "#eff0f0" },
  { name: "Snowman", hex: "#fefafb" },
  { name: "Snowy Mint", hex: "#d6f0cd" },
  { name: "Snowy Summit", hex: "#c5d8e9" },
  { name: "So Sour", hex: "#00ff11" },
  { name: "Soaked in Sun", hex: "#f7d163" },
  { name: "Soba", hex: "#d1b49f" },
  { name: "Socialist", hex: "#921a1c" },
  { name: "Soda Pop", hex: "#c3c67e" },
  { name: "Soft Blush", hex: "#e3bcbc" },
  { name: "Soft Boiled", hex: "#ffb737" },
  { name: "Soft Butter", hex: "#f4e1b6" },
  { name: "Soft Cashmere", hex: "#efb6d8" },
  { name: "Soft Pillow", hex: "#fff5e7" },
  { name: "Soft Pumpkin", hex: "#dc8e31" },
  { name: "Solar", hex: "#fbeab8" },
  { name: "Solar Ash", hex: "#cc6622" },
  { name: "Solar Flare", hex: "#e67c41" },
  { name: "Solar Power", hex: "#f4bf3a" },
  { name: "Sombrero", hex: "#b39c8c" },
  { name: "Somewhere in a Fairytale", hex: "#cc99dd" },
  { name: "Sooty", hex: "#141414" },
  { name: "Sorreno Lemon", hex: "#f1d058" },
  { name: "Soufflé", hex: "#edd1a8" },
  { name: "Soulless", hex: "#1b150d" },
  { name: "Sour Apple Candy", hex: "#aaee22" },
  { name: "Sour Apple Rings", hex: "#33bb00" },
  { name: "Sour Cherry", hex: "#e24736" },
  { name: "Sour Green", hex: "#c1e613" },
  { name: "Sour Lemon", hex: "#ffeea5" },
  { name: "Sour Yellow", hex: "#eeff04" },
  { name: "Sovereign Red", hex: "#ce243f" },
  { name: "Soviet Gold", hex: "#ffd900" },
  { name: "Soy Milk", hex: "#d5d2c7" },
  { name: "Spa", hex: "#ceece7" },
  { name: "Space Battle Blue", hex: "#440099" },
  { name: "Space Colonization", hex: "#150f5b" },
  { name: "Space Dust", hex: "#002299" },
  { name: "Space Exploration", hex: "#001199" },
  { name: "Space Missions", hex: "#324471" },
  { name: "Space Opera", hex: "#5511dd" },
  { name: "Spaghetti Monster", hex: "#eecc88" },
  { name: "Sparkling Champagne", hex: "#efcf98" },
  { name: "Sparkling Cider", hex: "#fffdeb" },
  { name: "Sparkling Snow", hex: "#f5fefd" },
  { name: "Sparky Blue", hex: "#22eeff" },
  { name: "Spätzle Yellow", hex: "#ffee88" },
  { name: "Spearmint", hex: "#64bfa4" },
  { name: "Spectacular Purple", hex: "#bb02fe" },
  { name: "Spectacular Saffron", hex: "#edd924" },
  { name: "Spectacular Scarlet", hex: "#f72305" },
  { name: "Spice Market", hex: "#b84823" },
  { name: "Spiced", hex: "#bb715b" },
  { name: "Spiced Up Orange", hex: "#e67a37" },
  { name: "Spicy Berry", hex: "#cc3366" },
  { name: "Spicy Cinnamon", hex: "#a85624" },
  { name: "Spicy Paella", hex: "#f38f39" },
  { name: "Spicy Purple", hex: "#b9396e" },
  { name: "Spicy Sweetcorn", hex: "#f6ac00" },
  { name: "Spikey Red", hex: "#600000" },
  { name: "Splashdown", hex: "#d4e8d8" },
  { name: "Splashing Wave", hex: "#44ddff" },
  { name: "Splatter Movie", hex: "#d01a2c" },
  { name: "Spring Forth", hex: "#11bb22" },
  { name: "Springtide Melodies", hex: "#9aa955" },
  { name: "Sprinkled With Pink", hex: "#e7a2ae" },
  { name: "Sprouted", hex: "#f3d48b" },
  { name: "Stainless Steel", hex: "#b4bdc7" },
  { name: "Star", hex: "#ffe500" },
  { name: "Star Dust", hex: "#f9f3dd" },
  { name: "Star of Life", hex: "#057bc1" },
  { name: "Stardust Evening", hex: "#b8bfdc" },
  { name: "Stargazing", hex: "#414549" },
  { name: "Starlet", hex: "#854e51" },
  { name: "Starlit Night", hex: "#3b476b" },
  { name: "Starry Night", hex: "#286492" },
  { name: "Starship Tonic", hex: "#cce7e8" },
  { name: "Statuary", hex: "#9ea4a5" },
  { name: "Stay the Night", hex: "#314662" },
  { name: "Steam", hex: "#dddddd" },
  { name: "Steam Bath", hex: "#ccd0da" },
  { name: "Steam Engine", hex: "#b2b2ad" },
  { name: "Steampunk Gold", hex: "#c39c55" },
  { name: "Steamy Dumpling", hex: "#eae9b4" },
  { name: "Steel Mist", hex: "#c6ceda" },
  { name: "Steely Grey", hex: "#90979b" },
  { name: "Stellar", hex: "#46647e" },
  { name: "Stereotypical Duck", hex: "#fff5cf" },
  { name: "Still Water", hex: "#4a5d5f" },
  { name: "Stone Cold", hex: "#555555" },
  { name: "Stone Fortress", hex: "#c5c0b0" },
  { name: "Stone Guardians", hex: "#caba97" },
  { name: "Stop", hex: "#c33a36" },
  { name: "Stoplight", hex: "#dd1111" },
  { name: "Storm Is Coming", hex: "#3d3d63" },
  { name: "Stormy", hex: "#b0bcc3" },
  { name: "Stormy Bay", hex: "#9aafaf" },
  { name: "Stormy Horizon", hex: "#777799" },
  { name: "Stormy Night", hex: "#372354" },
  { name: "Stormy Oceans", hex: "#70818e" },
  { name: "Stormy Passion", hex: "#c36666" },
  { name: "Stormy Sea", hex: "#6e8082" },
  { name: "Straw Gold", hex: "#fcf679" },
  { name: "Strawberry", hex: "#fb2943" },
  { name: "Strawberry Blonde", hex: "#ffdadc" },
  { name: "Strawberry Bonbon", hex: "#ffebfa" },
  { name: "Strawberry Buttercream", hex: "#f8b3ff" },
  { name: "Strawberry Dreams", hex: "#ff88aa" },
  { name: "Strawberry Field", hex: "#fa8383" },
  { name: "Strawberry Frappé", hex: "#ffa2aa" },
  { name: "Strawberry Milk", hex: "#ffd9e7" },
  { name: "Strawberry Milkshake", hex: "#d47186" },
  { name: "Strawberry Moon", hex: "#cf5570" },
  { name: "Strawberry Ripple", hex: "#f7cdce" },
  { name: "Stroopwafel", hex: "#a86f48" },
  { name: "Submerged", hex: "#4a7d82" },
  { name: "Subnautical", hex: "#012253" },
  { name: "Subterrain Kingdom", hex: "#4f4e4a" },
  { name: "Succubus", hex: "#990022" },
  { name: "Succulent", hex: "#8ba477" },
  { name: "Succulent Lime", hex: "#dcdd65" },
  { name: "Succulents", hex: "#007744" },
  { name: "Such a Peach", hex: "#fbddaf" },
  { name: "Sugar Chic", hex: "#ffccff" },
  { name: "Sugar Coated", hex: "#ffedf1" },
  { name: "Sugar Cookie", hex: "#f2e2a4" },
  { name: "Sugar Glaze", hex: "#fff0e1" },
  { name: "Sugar High", hex: "#efc9ec" },
  { name: "Sugar Milk", hex: "#fff9f5" },
  { name: "Sugar Mint", hex: "#c0e2c5" },
  { name: "Sugar Quill", hex: "#ebe5d7" },
  { name: "Sugarwinkle", hex: "#fdc5e3" },
  { name: "Sulfur Pit", hex: "#e5cc69" },
  { name: "Sulfuric", hex: "#eeed56" },
  { name: "Sulphur", hex: "#ddb614" },
  { name: "Sultan Gold", hex: "#f6ac17" },
  { name: "Sultan of Pink", hex: "#e89bc7" },
  { name: "Summer Crush", hex: "#f2d6da" },
  { name: "Summer Glow", hex: "#eeaa44" },
  { name: "Summer Mist", hex: "#cbeaee" },
  { name: "Summer of '82", hex: "#74cdd8" },
  { name: "Summer's End", hex: "#dc9367" },
  { name: "Summit", hex: "#8bb6b8" },
  { name: "Sun Flooded Woods", hex: "#d0d418" },
  { name: "Sunbathed", hex: "#f5dd98" },
  { name: "Sunbathed Beach", hex: "#fad28f" },
  { name: "Sunbathing Beauty", hex: "#7e4730" },
  { name: "Sunbeam", hex: "#f5edb2" },
  { name: "Sunburst", hex: "#f6c289" },
  { name: "Sunflower", hex: "#ffc512" },
  { name: "Sunflower Island", hex: "#ffcd01" },
  { name: "Sunflower Mango", hex: "#ffb700" },
  { name: "Sunflower Valley", hex: "#fdbd27" },
  { name: "Sunken Gold", hex: "#b29700" },
  { name: "Sunken Harbor", hex: "#1c3d44" },
  { name: "Sunkissed Beach", hex: "#deab9b" },
  { name: "Sunlight", hex: "#edd59e" },
  { name: "Sunny Glory", hex: "#e8d99c" },
  { name: "Sunny Honey", hex: "#f8f0d8" },
  { name: "Sunny Yellow", hex: "#fff917" },
  { name: "Sunray", hex: "#e3ab57" },
  { name: "Sunset Gold", hex: "#f7c46c" },
  { name: "Sunset Orange", hex: "#fd5e53" },
  { name: "Sunshine Mellow", hex: "#f5c20b" },
  { name: "Sunshone Plum", hex: "#886688" },
  { name: "Super Banana", hex: "#fffe71" },
  { name: "Super Pink", hex: "#ce6ba4" },
  { name: "Super Rare Jade", hex: "#14bab4" },
  { name: "Super Rose Red", hex: "#cb1028" },
  { name: "Super Saiyan", hex: "#ffdd00" },
  { name: "Super Silver", hex: "#eeeeee" },
  { name: "Supremely Cool", hex: "#afbed4" },
  { name: "Surgical Green", hex: "#59a4c1" },
  { name: "Swamp Monster", hex: "#005511" },
  { name: "Swan Dive", hex: "#e5e4dd" },
  { name: "Swan Lake", hex: "#c5e5e2" },
  { name: "Sweet and Sassy", hex: "#e1c9d1" },
  { name: "Sweet Butter", hex: "#fffcd7" },
  { name: "Sweet Chilli", hex: "#f5160b" },
  { name: "Sweet Corn", hex: "#f9e176" },
  { name: "Sweet Desire", hex: "#aa33ee" },
  { name: "Sweet Lilac", hex: "#e8b5ce" },
  { name: "Sweet Lucid Dreams", hex: "#ccbbdd" },
  { name: "Sweet Mint Pesto", hex: "#bbee99" },
  { name: "Sweet Perfume", hex: "#d49ab9" },
  { name: "Sweet Potato", hex: "#d87c3b" },
  { name: "Sweet Venom", hex: "#b6ff1a" },
  { name: "Sweetly", hex: "#ffe5ef" },
  { name: "Swimmer", hex: "#0a91bf" },
  { name: "Swimming", hex: "#c2e5e5" },
  { name: "Tabasco", hex: "#a02712" },
  { name: "Taco", hex: "#f3c7b3" },
  { name: "Tadorna Teal", hex: "#7ad7ad" },
  { name: "Tahini Brown", hex: "#9b856b" },
  { name: "Tamahagane", hex: "#3b3f40" },
  { name: "Tamed Beast", hex: "#9c2626" },
  { name: "Tamed Beauty", hex: "#cfbccf" },
  { name: "Tandoori", hex: "#bb5c4d" },
  { name: "Tangent Periwinkle", hex: "#50507f" },
  { name: "Tangerine Tango", hex: "#ff9e4b" },
  { name: "Tangled Web", hex: "#b2b2b2" },
  { name: "Tardis Blue", hex: "#003b6f" },
  { name: "Tartan Red", hex: "#b1282a" },
  { name: "Tatami", hex: "#deccaf" },
  { name: "Taupe", hex: "#b9a281" },
  { name: "Tea Green", hex: "#d0f0c0" },
  { name: "Teak", hex: "#ab8953" },
  { name: "Teakwood", hex: "#8d7e6d" },
  { name: "Teal", hex: "#008080" },
  { name: "Teal Me No Lies", hex: "#0daca7" },
  { name: "Teal With It", hex: "#01697a" },
  { name: "Teardrop", hex: "#d1eaea" },
  { name: "Techno Taupe", hex: "#bfb9aa" },
  { name: "Technolust", hex: "#ff80f9" },
  { name: "Telemagenta", hex: "#aa22cc" },
  { name: "Tempest", hex: "#79839b" },
  { name: "Templar's Gold", hex: "#f2e688" },
  { name: "Temptatious Tangerine", hex: "#ff7733" },
  { name: "Tender Shoot", hex: "#e8eace" },
  { name: "Tender Taupe", hex: "#c4b198" },
  { name: "Tennis Ball", hex: "#dfff4f" },
  { name: "Terrestrial", hex: "#276757" },
  { name: "Thai Chili", hex: "#ce0001" },
  { name: "Thai Hot", hex: "#fe1c06" },
  { name: "Thalassophile", hex: "#44aadd" },
  { name: "The Count's Black", hex: "#102030" },
  { name: "The Devil's Grass", hex: "#666420" },
  { name: "The End", hex: "#2a2a2a" },
  { name: "The Grape War of 97'", hex: "#bb00ff" },
  { name: "The Legend of Green", hex: "#558844" },
  { name: "The Vast of Night", hex: "#110066" },
  { name: "Think Pink", hex: "#e5a5c1" },
  { name: "Thor's Thunder", hex: "#44ccff" },
  { name: "Threatening Red", hex: "#c30305" },
  { name: "Thunder & Lightning", hex: "#f9f5db" },
  { name: "Thunderbird", hex: "#923830" },
  { name: "Thunderbolt", hex: "#fdefad" },
  { name: "Thundercloud", hex: "#698589" },
  { name: "Tiger", hex: "#be9c67" },
  { name: "Tiger King", hex: "#dd9922" },
  { name: "Tiger of Mysore", hex: "#ff8855" },
  { name: "Timeless Beauty", hex: "#b6273e" },
  { name: "Tin", hex: "#919191" },
  { name: "Toad", hex: "#748d70" },
  { name: "Toad King", hex: "#3d6c54" },
  { name: "Toadstool", hex: "#b8282f" },
  { name: "Toast and Butter", hex: "#d2ad84" },
  { name: "Toasted Marshmallow Fluff", hex: "#fff9eb" },
  { name: "Toasted Paprika", hex: "#a34631" },
  { name: "Tobacco", hex: "#684f3c" },
  { name: "Tobacco Leaf", hex: "#8c724f" },
  { name: "Toes in the Sand", hex: "#f8dcbf" },
  { name: "Toffee", hex: "#755139" },
  { name: "Tofu", hex: "#e8e3d9" },
  { name: "Tomato", hex: "#ef4026" },
  { name: "Tomato Baby", hex: "#e10d18" },
  { name: "Tomato Bisque", hex: "#d15915" },
  { name: "Tomato Burst", hex: "#d6201a" },
  { name: "Tomato Queen", hex: "#dd4422" },
  { name: "Tonkatsu", hex: "#edac36" },
  { name: "Too Big to Whale", hex: "#9596a4" },
  { name: "Too Blue to be True", hex: "#0088ff" },
  { name: "Too Dark Tonight", hex: "#0011bb" },
  { name: "Topiary Green", hex: "#667700" },
  { name: "Torchlight", hex: "#ffc985" },
  { name: "Toreador", hex: "#b61032" },
  { name: "Tortilla", hex: "#efdba7" },
  { name: "Tostada", hex: "#e3c19c" },
  { name: "Tosty Crust", hex: "#a67e4b" },
  { name: "Total Eclipse", hex: "#2c313d" },
  { name: "Totally Broccoli", hex: "#909853" },
  { name: "Touch of Glamor", hex: "#dd8844" },
  { name: "Toupe", hex: "#c7ac7d" },
  { name: "Toxic Boyfriend", hex: "#ccff11" },
  { name: "Toxic Frog", hex: "#98fb98" },
  { name: "Toxic Latte", hex: "#e1f8e7" },
  { name: "Toxic Sludge", hex: "#00bb33" },
  { name: "Toxic Steam", hex: "#c1fdc9" },
  { name: "Track and Field", hex: "#d66352" },
  { name: "Tractor Beam", hex: "#00bffe" },
  { name: "Tradewind", hex: "#b7c5c6" },
  { name: "Traffic Green", hex: "#55ff22" },
  { name: "Traffic Red", hex: "#ff1c1c" },
  { name: "Traffic Yellow", hex: "#fedc39" },
  { name: "Trail Dust", hex: "#d0c4ac" },
  { name: "Tranquili Teal", hex: "#6c9da9" },
  { name: "Transcendence", hex: "#f8f4d8" },
  { name: "Transfusion", hex: "#ea1833" },
  { name: "Translucent Unicorn", hex: "#ffedef" },
  { name: "Trapped Darkness", hex: "#0e1d32" },
  { name: "Treasure", hex: "#e7d082" },
  { name: "Treasure Chest", hex: "#726854" },
  { name: "Treasure Map", hex: "#d0bb9d" },
  { name: "Treasure Map Waters", hex: "#658faa" },
  { name: "Treasured Teal", hex: "#52c1b3" },
  { name: "Treasures", hex: "#ba8b36" },
  { name: "Treasury", hex: "#dbd186" },
  { name: "Tree Hugger", hex: "#79774a" },
  { name: "Treetop", hex: "#91b6ac" },
  { name: "Treetop Cathedral", hex: "#2f4a15" },
  { name: "Trippy Velvet", hex: "#cc00ee" },
  { name: "Tropical Dream", hex: "#d9eae5" },
  { name: "Tropical Escape", hex: "#4dbbaf" },
  { name: "Tropical Fog", hex: "#cbcab6" },
  { name: "Tropical Forest", hex: "#024a43" },
  { name: "Tropical Freeze", hex: "#99ddcc" },
  { name: "Tropical Funk", hex: "#55dd00" },
  { name: "Tropical Mist", hex: "#cae8e8" },
  { name: "Tropical Rain", hex: "#447777" },
  { name: "Tropical Rainforest", hex: "#00755e" },
  { name: "Tropical Turquoise", hex: "#04cdff" },
  { name: "Trout", hex: "#4c5356" },
  { name: "Trout Caviar", hex: "#f75300" },
  { name: "True Blue", hex: "#010fcc" },
  { name: "Truffle Trouble", hex: "#a35139" },
  { name: "Trumpet Gold", hex: "#e9b413" },
  { name: "Tulip", hex: "#ff878d" },
  { name: "Tuna", hex: "#46494e" },
  { name: "Tunic Green", hex: "#00cc00" },
  { name: "Turf", hex: "#5f4f42" },
  { name: "Turf Master", hex: "#009922" },
  { name: "Turkish Jade", hex: "#2b888d" },
  { name: "Turquoise", hex: "#06c2ac" },
  { name: "Turquoise Fantasies", hex: "#6dafa7" },
  { name: "Turquoise Pearl", hex: "#89f5e3" },
  { name: "Turquoise Tortoise", hex: "#457b74" },
  { name: "Turtle", hex: "#523f31" },
  { name: "Turtle Warrior", hex: "#35b76d" },
  { name: "Tuscan", hex: "#fbd5a6" },
  { name: "Tuscan Sun", hex: "#ffd84d" },
  { name: "Tutu", hex: "#f8e4e3" },
  { name: "Tuxedo", hex: "#3f3c43" },
  { name: "Twilight", hex: "#4e518b" },
  { name: "Twilight Meadow", hex: "#51a5a4" },
  { name: "Twilight Zone", hex: "#191916" },
  { name: "Twinkle Night", hex: "#636ca8" },
  { name: "Twinkle Pink", hex: "#fbd8cc" },
  { name: "Twinkly Pinkily", hex: "#cf4796" },
  { name: "Ultimate Grey", hex: "#a9a8a9" },
  { name: "Ultra Green", hex: "#7eba4d" },
  { name: "Ultra Mint", hex: "#a3efb8" },
  { name: "Ultra Moss", hex: "#d1f358" },
  { name: "Ultraberry", hex: "#770088" },
  { name: "Umbra", hex: "#211e1f" },
  { name: "Under the Sea", hex: "#395d68" },
  { name: "Underground", hex: "#665a51" },
  { name: "Underpass Shrine", hex: "#cc4422" },
  { name: "Underwater Moonlight", hex: "#4488aa" },
  { name: "Underwater World", hex: "#657f7a" },
  { name: "Underworld", hex: "#1e231c" },
  { name: "Unicorn Dust", hex: "#ff2f92" },
  { name: "Urban Snowfall", hex: "#dbd8da" },
  { name: "Va Va Voom", hex: "#e3b34c" },
  { name: "Valentine Lava", hex: "#ba0728" },
  { name: "Valentine's Kiss", hex: "#b63364" },
  { name: "Valkyrie", hex: "#eecc22" },
  { name: "Valley of Tears", hex: "#d1e1e4" },
  { name: "Vampire Bite", hex: "#c40233" },
  { name: "Vampire Fangs", hex: "#cc2255" },
  { name: "Vampire Fiction", hex: "#9b0f11" },
  { name: "Vampire Hunter", hex: "#610507" },
  { name: "Vampire Love Story", hex: "#dd0077" },
  { name: "Vampire Red", hex: "#dd4132" },
  { name: "Vampire State Building", hex: "#cc1100" },
  { name: "Vampirella", hex: "#9b2848" },
  { name: "Vampiric Bloodlust", hex: "#cc0066" },
  { name: "Van Dyke Brown", hex: "#664228" },
  { name: "Vanilla", hex: "#f3e5ab" },
  { name: "Vanilla Blush", hex: "#fcede4" },
  { name: "Vanilla Drop", hex: "#ffffeb" },
  { name: "Vanilla Ice", hex: "#fdf2d1" },
  { name: "Vanishing Point", hex: "#ddeedd" },
  { name: "Vapor", hex: "#f0ffff" },
  { name: "Vapor Trail", hex: "#f5eedf" },
  { name: "Vaporwave", hex: "#ff66ee" },
  { name: "Vaporwave Blue", hex: "#22ddff" },
  { name: "Varnished Ivory", hex: "#e6dccc" },
  { name: "Vegas Gold", hex: "#c5b358" },
  { name: "Vegetarian", hex: "#22aa00" },
  { name: "Vegetation", hex: "#5ccd97" },
  { name: "Veiled Rose", hex: "#f8cdc9" },
  { name: "Veiled Treasure", hex: "#f6edb6" },
  { name: "Veiling Waterfalls", hex: "#d4eaff" },
  { name: "Velvet", hex: "#750851" },
  { name: "Velvet Black", hex: "#241f20" },
  { name: "Velvet Cosmos", hex: "#441144" },
  { name: "Velvet Magic", hex: "#bb1155" },
  { name: "Velvet Scarf", hex: "#e3dfec" },
  { name: "Velvet Wine", hex: "#9a435d" },
  { name: "Venom Dart", hex: "#01ff01" },
  { name: "Venomous Green", hex: "#66ff22" },
  { name: "Venus", hex: "#eed053" },
  { name: "Venus Mist", hex: "#5f606e" },
  { name: "Venus Slipper Orchid", hex: "#df73ff" },
  { name: "Venus Violet", hex: "#7a6dc0" },
  { name: "Veranda Gold", hex: "#af9968" },
  { name: "Verdant Forest", hex: "#28615d" },
  { name: "Verde", hex: "#7fb383" },
  { name: "Verdigris", hex: "#43b3ae" },
  { name: "Vermicelle", hex: "#dabe82" },
  { name: "Vermicelles", hex: "#bb835f" },
  { name: "Vermilion Scarlet", hex: "#d1062b" },
  { name: "Vermillion", hex: "#da3b1f" },
  { name: "Verminal", hex: "#55cc11" },
  { name: "Vertigo Cherry", hex: "#990055" },
  { name: "Very Berry", hex: "#b73275" },
  { name: "Very Coffee", hex: "#664411" },
  { name: "Vibrant Amber", hex: "#d1902e" },
  { name: "Vibrant Blue", hex: "#0339f8" },
  { name: "Vibrant Honey", hex: "#ffbd31" },
  { name: "Vibrant Mint", hex: "#00ffe5" },
  { name: "Vibrant Orange", hex: "#ff7420" },
  { name: "Vibrant Velvet", hex: "#bb0088" },
  { name: "Vibrant Vine", hex: "#4b373a" },
  { name: "Vibrant Yellow", hex: "#ffda29" },
  { name: "Vice City", hex: "#ee00dd" },
  { name: "Vicious Violet", hex: "#8f509d" },
  { name: "Victorian Crown", hex: "#c38b36" },
  { name: "Victorian Garden", hex: "#558e4c" },
  { name: "Vienna Roast", hex: "#330022" },
  { name: "Viking", hex: "#4db1c8" },
  { name: "Viking Diva", hex: "#cabae0" },
  { name: "Vin Cuit", hex: "#b47463" },
  { name: "Vinaceous", hex: "#f59994" },
  { name: "Vinaceous Cinnamon", hex: "#f48b8b" },
  { name: "Vinaceous Tawny", hex: "#c74300" },
  { name: "Vinaigrette", hex: "#efdaae" },
  { name: "Vineyard", hex: "#819e84" },
  { name: "Vintage", hex: "#847592" },
  { name: "Vintage Copper", hex: "#9d5f46" },
  { name: "Vintage Porcelain", hex: "#f2edec" },
  { name: "Viola", hex: "#966ebd" },
  { name: "Violaceous", hex: "#bf8fc4" },
  { name: "Violent Violet", hex: "#7f00ff" },
  { name: "Violet", hex: "#9a0eea" },
  { name: "Violet Heaven", hex: "#cdb7fa" },
  { name: "Violet Kiss", hex: "#f0a0d1" },
  { name: "Violet Pink", hex: "#fb5ffc" },
  { name: "Violet Poison", hex: "#8601bf" },
  { name: "Violet Vapor", hex: "#e5dae1" },
  { name: "Violet Velvet", hex: "#b19cd9" },
  { name: "Violet Vision", hex: "#b7bdd1" },
  { name: "Violet Vixen", hex: "#883377" },
  { name: "Violet Vogue", hex: "#e9e1e8" },
  { name: "Virgin Olive Oil", hex: "#e2dcab" },
  { name: "Viridian", hex: "#1e9167" },
  { name: "Virtual Boy", hex: "#fe0215" },
  { name: "Virtual Golf", hex: "#c1ee13" },
  { name: "Vitality", hex: "#8f9b5b" },
  { name: "Vitamin C", hex: "#ff9900" },
  { name: "Vivid Blue", hex: "#152eff" },
  { name: "Vivid Orange", hex: "#ff5f00" },
  { name: "Vivid Raspberry", hex: "#ff006c" },
  { name: "Vivid Violet", hex: "#9f00ff" },
  { name: "Void", hex: "#050d25" },
  { name: "Voila!", hex: "#af8ba8" },
  { name: "Volcanic Ash", hex: "#6f7678" },
  { name: "Volcanic Island", hex: "#605244" },
  { name: "Volcanic Rock", hex: "#6b6965" },
  { name: "Voldemort", hex: "#2d135f" },
  { name: "Volt", hex: "#ceff00" },
  { name: "Voluptuous Violet", hex: "#7711dd" },
  { name: "Voodoo", hex: "#443240" },
  { name: "Voracious White", hex: "#feeeed" },
  { name: "Vulcan", hex: "#36383c" },
  { name: "Vulcan Fire", hex: "#e6390d" },
  { name: "Vulcanized", hex: "#424443" },
  { name: "Waffle Cone", hex: "#e2c779" },
  { name: "Waikiki", hex: "#218ba0" },
  { name: "Wakame Green", hex: "#00656e" },
  { name: "Walk in the Park", hex: "#88bb11" },
  { name: "Walkie Chalkie", hex: "#faf5fa" },
  { name: "Walkway", hex: "#a3999c" },
  { name: "Walled Garden", hex: "#11cc44" },
  { name: "Walnut", hex: "#773f1a" },
  { name: "Walnut Milkies", hex: "#fff0cf" },
  { name: "Walrus", hex: "#999b9b" },
  { name: "Warlord", hex: "#ba0033" },
  { name: "Warm Ash", hex: "#cfc9c7" },
  { name: "Warm Blue", hex: "#4b57db" },
  { name: "Warm Brown", hex: "#964e02" },
  { name: "Warm Light", hex: "#fff9d8" },
  { name: "Warm Neutral", hex: "#c1b19d" },
  { name: "Warm Oats", hex: "#d8cfba" },
  { name: "Warm Pink", hex: "#fb5581" },
  { name: "Warm Purple", hex: "#952e8f" },
  { name: "Warm Spring", hex: "#4286bc" },
  { name: "Warm Welcome", hex: "#ea9073" },
  { name: "Warm White", hex: "#efebd8" },
  { name: "Warp Drive", hex: "#eaf2f1" },
  { name: "Warrior", hex: "#7d685b" },
  { name: "Warrior Queen", hex: "#a32d48" },
  { name: "Wasabi", hex: "#afd77f" },
  { name: "Wasabi Nori", hex: "#333300" },
  { name: "Washed Dollar", hex: "#e1e3d7" },
  { name: "Wasteland", hex: "#9c8855" },
  { name: "Water", hex: "#d4f1f9" },
  { name: "Water Leaf", hex: "#b6ecde" },
  { name: "Water Lily", hex: "#dde3d5" },
  { name: "Water Nymph", hex: "#81d0df" },
  { name: "Water Park", hex: "#54af9c" },
  { name: "Waterfall", hex: "#3ab0a2" },
  { name: "Watermelon", hex: "#fd4659" },
  { name: "Watermelon Gelato", hex: "#c0686e" },
  { name: "Watermelon Milk", hex: "#dfcfca" },
  { name: "Watermelon Mousse", hex: "#fbe0e8" },
  { name: "Watermelon Sugar", hex: "#e42b73" },
  { name: "Watermelonade", hex: "#eb4652" },
  { name: "Waterworld", hex: "#00718a" },
  { name: "Wave", hex: "#a5ced5" },
  { name: "Wave Splash", hex: "#cbe4e7" },
  { name: "Wavelet", hex: "#7dc4cd" },
  { name: "Wavy Navy", hex: "#006597" },
  { name: "Wax", hex: "#ddbb33" },
  { name: "Wax Flower", hex: "#eeb39e" },
  { name: "Waxy Corn", hex: "#f8b500" },
  { name: "Way Beyond the Blue", hex: "#1188cc" },
  { name: "We Peep", hex: "#fdd7d8" },
  { name: "Weathered Leather", hex: "#90614a" },
  { name: "Weathered Stone", hex: "#c4c5c6" },
  { name: "Weathered Wood", hex: "#b19c86" },
  { name: "Wedding Dress", hex: "#fefee7" },
  { name: "Wedding in White", hex: "#fffee5" },
  { name: "Weissbier", hex: "#b3833b" },
  { name: "Wet Asphalt", hex: "#989cab" },
  { name: "Wet Concrete", hex: "#353838" },
  { name: "Wet Taupe", hex: "#907e6c" },
  { name: "Whale", hex: "#7c8181" },
  { name: "Whale Shark", hex: "#607c8e" },
  { name: "Whale's Tale", hex: "#115a82" },
  { name: "What We Do in the Shadows", hex: "#441122" },
  { name: "Wheat", hex: "#fbdd7e" },
  { name: "Where There Is Smoke", hex: "#c7ccce" },
  { name: "Whipped Cream", hex: "#f2f0e7" },
  { name: "Whirlpool", hex: "#a5d8cd" },
  { name: "Whiskers", hex: "#f6f1e2" },
  { name: "Whiskey", hex: "#d29062" },
  { name: "Whiskey and Wine", hex: "#49463f" },
  { name: "Whiskey Sour", hex: "#d4915d" },
  { name: "Whisky", hex: "#c2877b" },
  { name: "Whisky Barrel", hex: "#96745b" },
  { name: "Whisky Cola", hex: "#772233" },
  { name: "Whisky Sour", hex: "#eeaa33" },
  { name: "Whisper of Smoke", hex: "#cbcecf" },
  { name: "Whisper of White", hex: "#eadbca" },
  { name: "Whispering Smoke", hex: "#d8d8d4" },
  { name: "White", hex: "#ffffff" },
  { name: "White Asparagus", hex: "#eceabe" },
  { name: "White Beach", hex: "#f5efe5" },
  { name: "White Bullet", hex: "#dfdfda" },
  { name: "White Chalk", hex: "#f6f4f1" },
  { name: "White Chocolate", hex: "#f0e3c7" },
  { name: "White Elephant", hex: "#dedee5" },
  { name: "White Frost", hex: "#dee6ec" },
  { name: "White Glove", hex: "#f0efed" },
  { name: "White Mecca", hex: "#ecf3e1" },
  { name: "White Pearl", hex: "#ede1d1" },
  { name: "White Porcelain", hex: "#f8fbf8" },
  { name: "White Russian", hex: "#f0e0dc" },
  { name: "White Sand", hex: "#f5ebd8" },
  { name: "White Smoke", hex: "#f5f5f5" },
  { name: "White Truffle", hex: "#efdbcd" },
  { name: "White Warm Wool", hex: "#efe6d1" },
  { name: "Wicked Green", hex: "#9bca47" },
  { name: "Widowmaker", hex: "#99aaff" },
  { name: "Wild Berry", hex: "#7e3a3c" },
  { name: "Wild Chocolate", hex: "#665134" },
  { name: "Wild Forest", hex: "#38914a" },
  { name: "Wild Horses", hex: "#8d6747" },
  { name: "Wild Rice", hex: "#d5bfb4" },
  { name: "Wild Violet", hex: "#63209b" },
  { name: "Wild West", hex: "#7e5c52" },
  { name: "Wild Wheat", hex: "#e0e1d1" },
  { name: "Wilderness", hex: "#8f886c" },
  { name: "Wildfire", hex: "#ff8833" },
  { name: "Willow Leaf", hex: "#a1a46d" },
  { name: "Wind Blown", hex: "#dde3e7" },
  { name: "Wind Chime", hex: "#dfe0e2" },
  { name: "Wind Chimes", hex: "#cac5c2" },
  { name: "Windfall", hex: "#84a7ce" },
  { name: "Windjammer", hex: "#62a5df" },
  { name: "Windows 95 Desktop", hex: "#018281" },
  { name: "Windsor Toffee", hex: "#ccb490" },
  { name: "Windstorm", hex: "#6d98c4" },
  { name: "Windsurfing", hex: "#3a7099" },
  { name: "Windy", hex: "#bdd1d2" },
  { name: "Windy Meadow", hex: "#b0a676" },
  { name: "Wine & Roses", hex: "#a33540" },
  { name: "Wine Barrel", hex: "#aa5522" },
  { name: "Wine Cellar", hex: "#70403d" },
  { name: "Wine Grape", hex: "#941751" },
  { name: "Wine Stain", hex: "#69444f" },
  { name: "Wine Tasting", hex: "#492a34" },
  { name: "Wine Tour", hex: "#653b66" },
  { name: "Wineberry", hex: "#663366" },
  { name: "Wing Commander", hex: "#0065ac" },
  { name: "Winter Duvet", hex: "#ffffe0" },
  { name: "Winter Lakes", hex: "#5c97cf" },
  { name: "Winter Scene", hex: "#becedb" },
  { name: "Winter Storm", hex: "#4b7079" },
  { name: "Winter Wizard", hex: "#a0e6ff" },
  { name: "Wintermint", hex: "#94d2bf" },
  { name: "Wishing Well", hex: "#d0d1c1" },
  { name: "Wisteria", hex: "#a87dc2" },
  { name: "Wisteria Blue", hex: "#84a2d4" },
  { name: "Witch Brew", hex: "#888738" },
  { name: "Witch Hazel", hex: "#fbf073" },
  { name: "Witchcraft", hex: "#474c50" },
  { name: "Wizard", hex: "#4d5b88" },
  { name: "Wizard's Brew", hex: "#a090b8" },
  { name: "Wolf Pack", hex: "#78776f" },
  { name: "Wolfram", hex: "#b5b6b7" },
  { name: "Wonder Wine", hex: "#635d63" },
  { name: "Wondrous Wisteria", hex: "#a3b1f2" },
  { name: "Wood Bark", hex: "#302621" },
  { name: "Woodgrain", hex: "#996633" },
  { name: "Woodhaven", hex: "#9e7b6c" },
  { name: "Woodland Grass", hex: "#004400" },
  { name: "Woodland Night", hex: "#475c5d" },
  { name: "Woodland Soul", hex: "#127a49" },
  { name: "Worcestershire Sauce", hex: "#572b26" },
  { name: "Worn Silver", hex: "#c9c0bb" },
  { name: "Wrapped in Twilight", hex: "#5f6d6e" },
  { name: "Wreath", hex: "#76856a" },
  { name: "Wu-Tang Gold", hex: "#f8d106" },
  { name: "X Marks the Spot", hex: "#e6474a" },
  { name: "Xanthic", hex: "#f4e216" },
  { name: "Xmas Candy", hex: "#990020" },
  { name: "Xoxo", hex: "#f08497" },
  { name: "Yacht Club", hex: "#566062" },
  { name: "Yakitori", hex: "#ecab3f" },
  { name: "Yang Mist", hex: "#ede8dd" },
  { name: "Yearning Desire", hex: "#ca135e" },
  { name: "Yell Yellow", hex: "#ffffbf" },
  { name: "Yellow", hex: "#ffff00" },
  { name: "Yellow Buzzing", hex: "#eedd11" },
  { name: "Yellow Chalk", hex: "#f5f9ad" },
  { name: "Yellow Mana", hex: "#fdfcbf" },
  { name: "Yellow Mellow", hex: "#f0d31e" },
  { name: "Yellow Submarine", hex: "#ffff14" },
  { name: "Yellowish", hex: "#faee66" },
  { name: "Yeti Footprint", hex: "#c7d7e0" },
  { name: "Yippie Ya Yellow", hex: "#f9f59f" },
  { name: "York Pink", hex: "#d7837f" },
  { name: "York Plum", hex: "#d3bfe5" },
  { name: "Yorkshire Cloud", hex: "#bac3cc" },
  { name: "Yoshi", hex: "#55aa00" },
  { name: "Young Apricot", hex: "#fcd8b5" },
  { name: "Young Crab", hex: "#f6a09d" },
  { name: "Young Night", hex: "#232323" },
  { name: "Young Salmon", hex: "#ffb6b4" },
  { name: "Your Majesty", hex: "#61496e" },
  { name: "Your Shadow", hex: "#787e93" },
  { name: "Yucca", hex: "#75978f" },
  { name: "Yuma Gold", hex: "#ffd678" },
  { name: "Yuzu Marmalade", hex: "#ffd766" },
  { name: "Yuzukoshō", hex: "#d4de49" },
  { name: "Zen", hex: "#cfd9de" },
  { name: "Zen Garden", hex: "#d1dac0" },
  { name: "Zenith", hex: "#497a9f" },
  { name: "Zeus's Bolt", hex: "#eeff00" },
  { name: "Zinc", hex: "#92898a" },
  { name: "Zodiac Constellation", hex: "#ee8844" },
  { name: "Zombie", hex: "#6a755a" },
  { name: "Zucchini", hex: "#17462e" },
  { name: "Zucchini Noodles", hex: "#c8d07f" },
  { name: "Zunda Green", hex: "#6bc026" },
];