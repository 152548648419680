import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  CardMedia,
  Grid,
  Paper,
  Typography,
  Button,
  Badge,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VariableClapTower from "../../resources/variableClapTower.png";
import VariableTower from "../../resources/variableTower.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import { secondsToHHMMSS } from "../../utils/secToHHMMSS";
import AlarmIcon from "@mui/icons-material/Alarm";
import GroupIcon from "@mui/icons-material/Group";
import { theme } from "../../utils/theme";
import { CosmeticPuzzleInfo } from "../../shared/Cosmetics";
import { useSecondsPassed } from "../../utils/useSecondsPassed";
import emptyRoundImage from "../../resources/emptyRound.png";
import waypoint from "../../resources/waypoint.png";
import LoopIcon from "@mui/icons-material/Loop";
import { lt } from "../../utils/lt";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Points } from "./Emoji";

interface ResourceCounterProps {
  amount: number;
  slow?: boolean;
  thumbnail?: boolean;
  size: number;
}

const thumbnailModifier = 1.25;

export const puzzlePrizePool = (
  participants: number,
  sponsorPoints: number
) => {
  let minParticipants = Math.max(participants, 3);
  let firstPrize =
    (Math.min(minParticipants, 100) + Math.ceil(sponsorPoints / 300)) * 100;
  let place = 0;
  let pool = 0;
  let givenPrize = lt(firstPrize * Math.pow(2 / 3.0, place), (it) =>
    it > 250 ? Math.floor(it / 50) * 50 : 0
  );
  while (givenPrize > 0 && minParticipants > 0) {
    minParticipants -= 1;
    pool += givenPrize;
    place += 1;
    givenPrize = lt(firstPrize * Math.pow(2 / 3.0, place), (it) =>
      it > 250 ? Math.floor(it / 50) * 50 : 0
    );
  }
  return pool;
};

export const thousandsToK = (value: number) => {
  if (value > 1000) {
    return Math.floor(value / 1000) + "k";
  }
  return "" + value;
};

const ResourceCounter = (props: ResourceCounterProps) => {
  const { amount, slow, size, thumbnail } = props;

  const sizePostMod = thumbnail ? size * thumbnailModifier : size;

  return (
    <Grid container justifyContent="center" flexShrink={1} flexBasis={0}>
      <Paper
        sx={{
          p: thumbnail ? 0.25 : 0.5,
          bgcolor: "white",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <CardMedia
          sx={{
            width: sizePostMod,
            height: sizePostMod,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            bgcolor: "white",
          }}
          image={slow ? VariableClapTower : VariableTower}
        >
          <Grid
            height={1}
            sx={{
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            container
            justifyContent="center"
            direction="column"
            bgcolor={"#16161645"}
          >
            <Typography sx={{ pt: "10%" }} fontSize={(sizePostMod * 5) / 8}>
              <b>{amount}</b>
            </Typography>
          </Grid>
        </CardMedia>
      </Paper>
    </Grid>
  );
};

interface PuzzlePreviewProps {
  info: CosmeticPuzzleInfo;
  title?: string;
  thumbnail?: boolean;
  roundImage?: string;
  onClick: () => void;
  onInfoClick?: () => void;
  onTimeout?: () => void;
}

export const PuzzlePreview = (props: PuzzlePreviewProps) => {
  const {
    info,
    thumbnail = false,
    onClick,
    onInfoClick,
    onTimeout = () => {},
    title,
  } = props;
  const { currentPlayers, solution, round } = info;
  const width = useMemo(
    () =>
      thumbnail
        ? Math.min(
            window.innerWidth / 2 - +theme.spacing(3.25).slice(0, -2),
            258
          )
        : Math.min(window.innerWidth - +theme.spacing(5).slice(0, -2), 442),
    [thumbnail]
  );

  const timeleft = useMemo(() => Math.round(info.deadline / 1000), [info]);

  const height = width * (19.5 / 17);

  const [hovering, setHovering] = useState(false);

  const { secondsPassed, resetSecondsPassed } = useSecondsPassed();

  useEffect(() => {
    if (info) {
      resetSecondsPassed();
    }
  }, [info, resetSecondsPassed]);

  const hasFiredOnTimeout = useRef(false);

  useEffect(() => {
    if (timeleft - secondsPassed < -6) {
      if (!hasFiredOnTimeout.current) {
        hasFiredOnTimeout.current = true;
        onTimeout();
      }
    } else if (hasFiredOnTimeout.current) {
      hasFiredOnTimeout.current = false;
    }
  }, [secondsPassed, timeleft, onTimeout]);

  const wideText = width > theme.breakpoints.values.sm / 3;

  return (
    <Paper sx={{ m: 0.5 }}>
      <Box width={width} height={width * (19.5 / 17)}>
        <Grid
          zIndex={0}
          width={width}
          height={width * (19.5 / 17)}
          sx={{ position: "absolute" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            {solution === undefined && (
              <PlayCircleOutlineIcon
                color="action"
                sx={{ fontSize: width / 3 }}
              />
            )}
          </Grid>
        </Grid>
        <Button
          disableRipple={solution !== undefined && !thumbnail}
          sx={{
            height: width * (19.5 / 17),
            width: width,
            bgcolor: hovering
              ? "#00000000"
              : solution
              ? "#38663a77"
              : "#597c9977",
            ":hover": { bgcolor: "#00000000" },
            position: "absolute",
            transform: "translate(-50%, 0%)",
            color: "white",
            cursor: thumbnail ? undefined : solution ? "default" : undefined,
          }}
          onClick={thumbnail ? onClick : solution ? undefined : onClick}
          onMouseLeave={() => setHovering(false)}
          onMouseEnter={() => setHovering(true)}
        >
          <Grid
            zIndex={0}
            width={width}
            height={width * (19.5 / 17)}
            sx={{ position: "absolute" }}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              {timeleft < secondsPassed ? (
                <LoopIcon
                  color="action"
                  sx={{
                    fontSize: width / 3,
                    animation: "spin 1s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(360deg)",
                      },
                      "100%": {
                        transform: "rotate(0deg)",
                      },
                    },
                  }}
                />
              ) : solution ? (
                !hovering && (
                  <Fragment>
                    <CheckCircleOutlineIcon
                      color="action"
                      sx={{ fontSize: width / 3 }}
                    />
                  </Fragment>
                )
              ) : (
                <PlayCircleOutlineIcon
                  color="action"
                  sx={{ fontSize: width / 3 }}
                />
              )}
            </Grid>
          </Grid>
          {!hovering && (
            <Grid container direction="column" justifyContent="space-between">
              <Grid item xs={4}>
                <Typography sx={{ pt: 1 }}>
                  {thumbnail && title && <b>{title}</b>}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction={"row"} justifyContent="space-around" alignItems="center">
                  {info.round.board.waypoint && <Grid item />}
                  <ResourceCounter
                    size={width / 7}
                    thumbnail={thumbnail}
                    amount={round.towers}
                  />
                  {info.round.board.waypoint && (
                    <img
                      src={waypoint}
                      alt="waypoint"
                      width={width / 6}
                      height={width / 6}
                    />
                  )}
                  <ResourceCounter
                    size={width / 7}
                    thumbnail={thumbnail}
                    slow
                    amount={round.claps}
                  />
                  {info.round.board.waypoint && <Grid item />}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Box height={height / 2.5} />
              </Grid>
              <Grid item xs={4}>
                <Grid container alignItems="center">
                  <Grid item xs={6} alignItems={"flex-end"}>
                    <Badge
                      badgeContent={
                        currentPlayers > 0 && (
                          <Typography
                            fontSize={
                              thumbnail
                                ? (width / 18) * thumbnailModifier
                                : width / 18
                            }
                          >
                            <b>{currentPlayers}</b>
                          </Typography>
                        )
                      }
                    >
                      <GroupIcon color="action" sx={{ fontSize: width / 6 }} />
                    </Badge>
                    <Typography
                      sx={{
                        textTransform: "none",
                        minHeight: "1em",
                        overflow: "visible",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                      noWrap
                    >
                      <EmojiEventsIcon sx={{ pb: 0.5, mr: -0.5 }} />
                      <b>
                        {": " +
                          thousandsToK(
                            puzzlePrizePool(
                              currentPlayers,
                              info.sponsors.amount
                            )
                          ) +
                          " "}
                        <Points />
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <AlarmIcon color="action" sx={{ fontSize: width / 6 }} />
                    <Typography
                      sx={{ textTransform: "none", mt: -1, minHeight: "1em" }}
                      noWrap
                    >
                      <b>
                        {lt(timeleft - secondsPassed, (seconds) =>
                          seconds > 86400
                            ? Math.floor(seconds / 86400) +
                              (wideText ? " Days" : "d")
                            : seconds > 3600
                            ? Math.floor(seconds / 3600) +
                              (wideText ? " Hours" : "h")
                            : secondsToHHMMSS(seconds)
                        )}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Button>
        {thumbnail && (
          <IconButton
            onClick={onInfoClick}
            sx={{
              position: "absolute",
              ml: { xs: width / 2 - 35 + "px", sm: width / 2 - 40 + "px" },
              mt: { xs: "-5px", sm: 0 },
            }}
          >
            <InfoIcon />
          </IconButton>
        )}
        <Box>
          <img
            alt=""
            src={info.roundImage || emptyRoundImage}
            width={width}
            height={width * (19.5 / 17)}
          />
        </Box>
      </Box>
    </Paper>
  );
};
