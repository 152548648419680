export type RGB = {
  r: number;
  g: number;
  b: number;
};

export type CMYK = {
  c: number;
  m: number;
  y: number;
  k: number;
};

export const hexToRGB = (hex: string): RGB => {
  if (hex.length < 6) return { r: 0, g: 0, b: 0 };

  return {
    r: parseInt(hex.slice(-6, -4), 16),
    g: parseInt(hex.slice(-4, -2), 16),
    b: parseInt(hex.slice(-2), 16),
  };
};

export const rgbToCMYK = (rgb: RGB): CMYK => {
  let { r, g, b } = rgb;

  let _r = r / 255;
  let _g = g / 255;
  let _b = b / 255;

  let k = 1 - Math.max(_r, _g, _b);
  let c = (1 - _r - k) / (1 - k);
  let m = (1 - _g - k) / (1 - k);
  let y = (1 - _b - k) / (1 - k);

  return { c, m, y, k };
};

export const rgbToInt = (rgb: RGB) => {
  let { r, g, b } = rgb;
  return r * 65536 + g * 256 + b;
};

export const cmykToRGB = (cmyk: CMYK): RGB => {
  let { c, m, y, k } = cmyk;
  return {
    r: Math.floor(255 * (1 - c) * (1 - k)),
    g: Math.floor(255 * (1 - m) * (1 - k)),
    b: Math.floor(255 * (1 - y) * (1 - k)),
  };
};

export const rgbDist = (a: RGB, b: RGB): number => {
  let rd = a.r - b.r;
  let gd = a.g - b.g;
  let bd = a.b - b.b;

  return Math.sqrt(rd * rd + gd * gd + bd * bd);
};

export const tintToRGB = (tint: number): RGB => {
  let r = Math.floor(tint / 65536);
  let g = Math.floor((tint - r * 65536) / 256);
  let b = tint - r * 65536 - g * 256;

  return { r, g, b };
};

export const tintDist = (a: number, b: number) => {
  return rgbDist(tintToRGB(a), tintToRGB(b));
};

export type HSV = {
  h: number;
  s: number;
  v: number;
};

export const rgbToHSV = (rgb: RGB) => {
  const { r, g, b } = rgb;

  let _r = r / 255;
  let _g = g / 255;
  let _b = b / 255;

  let cMax = Math.max(_r, _g, _b);
  let cMin = Math.min(_r, _g, _b);
  let delta = cMax - cMin;

  return {
    h:
      delta === 0
        ? 0
        : (cMax = _r
            ? 60 * (((_g - _b) / delta) % 6)
            : (cMax = _g
                ? 60 * ((_b - _r) / delta + 2)
                : 60 * ((_r - _g) / delta + 4))),
    s: cMax === 0 ? 0 : delta / cMax,
    v: cMax,
  };
};
