import { SmoothGraphics } from "@pixi/graphics-smooth";
import { get } from "scriptjs";
import * as PIXI from "pixi.js";
import { Container, Rectangle, Renderer, Sprite } from "pixi.js";
import { generateHook } from "../../pages/ToggleTest";
import { cst } from "../../utils/constants";
import { dateInteger, mulberry32 } from "./useStaticPattern";
import { Board } from "../../shared/Board";
declare var pattern: CustomPattern;

export interface CustomPattern {
  name: string;
  author: string;
  make: (
    tileSize: number, // Length of the sides of a single square Tile in pixels.
    width: number, // Width of the playable Board in Tiles (Small: 9, Normal: 16, Large: 23)
    height: number, // Height of the playable Board in Tiles (Small: 10, Normal: 18, Large: 26)
    pixi: typeof PIXI, // A reference to pixi.js:6.2.0
    smoothGraphics: () => SmoothGraphics, // Creates a new SmoothGraphics() from @pixi/graphics-smooth:0.0.30
    random: () => number // Returns pseudorandom number between 0 and 1. Seed changes daily.
  ) => PIXI.DisplayObject; // The following region of the DisplayObject will be rendered for the pattern:
  // {x: tileSize, y: tileSize, width: width * tileSize, height: height * tileSize}
  // Notice: The final render will strip tileSize from the left and top.
}

export const importCustomPattern = (
  githubRepository: string,
  callback: (c: CustomPattern) => void
) => {
  get(
    "https://cdn.jsdelivr.net/gh/"+
      githubRepository +
      "/pattern.js",
    () => {
      callback(pattern);
    }
  );
};

export const renderCustomPattern = (
  renderer: Renderer,
  customPattern: CustomPattern,
  board: Board
) => {
  let dailyRandom = mulberry32(dateInteger);

  let container = new Container();

  container.addChild(
    customPattern.make(
      cst.tileSize,
      board.width,
      board.height,
      PIXI,
      () => new SmoothGraphics(),
      () => dailyRandom() 
    )
  );

  return new Sprite(
    generateHook(renderer, container, {
      region: new Rectangle(
        cst.tileSize,
        cst.tileSize,
        board.width * cst.tileSize,
        board.height * cst.tileSize
      ),
    })
  );
};
