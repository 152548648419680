import { Box, Button, Grid, Link, Typography, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPlayerService } from "../network/PlayerService";
import { getShopService } from "../network/ShopService";
import { isOfferList, Offer, productImage } from "../shared/Product";
import { cst } from "../utils/constants";
import { Points } from "./elements/Emoji";
import { MazePaper } from "./elements/MazePaper";
import { PlayerProfileLink } from "./elements/PlayerProfileLink";

interface CoinShopProps {}

export const CoinShop = (props: CoinShopProps) => {
  const [offers, setOffers] = useState<Offer[]>([]);

  useEffect(() => {
    getShopService()
      .offers()
      .then((response) => {
        if (isOfferList(response)) {
          setOffers(response);
        }
      });
  }, [setOffers]);

  const [guiltTrip, setGuiltTrip] = useState<number | undefined>(undefined);

  useEffect(() => {
    getPlayerService()
      .guiltTrip()
      .then((response) => {
        if (response === true) {
          setGuiltTrip(0);
        }
      });
  }, [setGuiltTrip]);

  return (
    <Box sx={{ mt: 1 }}>
      <MazePaper title="Buy Coins">
        {guiltTrip !== undefined && guiltTrip < 3 && (
          <Alert severity="info" variant="outlined" sx={{ mb: 2 }}>
            {guiltTrip === 0 && (
              <Typography width={1}>
                <b>maze.game</b> is an independent solo project developed,
                designed and maintained by me,{" "}
                <b>
                  <PlayerProfileLink playerName="boubou" />
                </b>
                .
                <br />
                <br />
                If you enjoy the game, and would like to see more of it,
                consider supporting the project by becoming a{" "}
                <Link
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  href="https://patreon.com/mazegame"
                >
                  Patron
                </Link>
                , or by buying <Points /> here in the <b>Store</b>.
              </Typography>
            )}
            {guiltTrip === 1 && (
              <Typography width={1}>
                I know it sucks with these sort of messages, and I understand if
                you are not able to help out right now.
                <br />
                <br />
                But <b>maze.game</b> does not, and will never: run ads, sell
                your data or any other predatory stuff.
                <br />
                <br />
                Its funding relies entirely on mazers like you stepping up and
                contributing, allowing everyone to enjoy the game.
              </Typography>
            )}
            {guiltTrip === 2 && (
              <Typography width={1}>
                You can hide these messages for a week by clicking below, or you
                can dismiss them entirely by buying any amount of <Points /> or
                by becoming a{" "}
                <Link
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  href="https://patreon.com/mazegame"
                >
                  Patron
                </Link>
                .
              </Typography>
            )}
            <Button
              onClick={() => {
                setGuiltTrip((t) => {
                  if (t !== undefined) {
                    if (t === 2) {
                      getPlayerService().postponeGuiltTrip()
                      return undefined
                    }
                    return t + 1
                  }
                  return undefined
                });
              }}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              {guiltTrip === 0
                ? "Stop guilt tripping me, please"
                : guiltTrip === 1
                ? "alright, fair enough"
                : "fine, hide them for a week"}
            </Button>
          </Alert>
        )}
        <Grid container>
          {offers.map((offer, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <Button
                color="warning"
                href={
                  cst.backendUrl + "/shop/checkout/" + offer.productCode
                }
              >
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      src={productImage(offer.productCode)}
                      height="100"
                      alt={offer.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="white">
                      <b>${offer.priceUSDCent / 100}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ pt: 2 }}>
          <i>
            Any purchase of <Points /> will give <b>all</b> mazers a free play
            in the <b>Arena</b>, and approximately the same amount of <Points />{" "}
            will be divided among the prize pools of all upcoming <b>Puzzles</b>
          </i>
        </Typography>
      </MazePaper>
    </Box>
  );
};
