import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  BlockPattern,
  blockPatternName,
  patternName,
} from "../../shared/Cosmetics";

interface BlockPatternNameProps {
  pattern: BlockPattern;
}

export const BlockPatternName = (props: BlockPatternNameProps) => {
  const { pattern } = props;
  return (
    <Grid
      minHeight={"3.5em"}
      container
      alignItems="center"
      justifyContent="center"
    >
      {pattern.secondColor ? (
        <Box>
          <Typography sx={{ mb: -0.9 }} lineHeight={1}>
            <b>{pattern.firstColor.color.name}</b>
          </Typography>
          <Typography
            sx={{ fontVariant: "small-caps", color: "lightgray" }}
            variant="caption"
          >
            <b>{patternName(pattern.pattern).toLowerCase()}</b>
          </Typography>
          <Typography sx={{ mt: -0.5 }} lineHeight={1}>
            <b>{pattern.secondColor.color.name}</b>
          </Typography>
        </Box>
      ) : (
        <Typography lineHeight={1}>
          <b>{blockPatternName(pattern)}</b>
        </Typography>
      )}
    </Grid>
  );
};
