import { Renderer } from "@pixi/core";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Sprite } from "@pixi/sprite";
import { Board } from "../../shared/Board";
import { Cosmetics } from "../../shared/Cosmetics";
import { Tower } from "../../shared/Tower";
import { cst } from "../../utils/constants";
import { Textures } from "../sprites/texture";
import { CustomPattern, renderCustomPattern } from "./CustomPattern";
import { generateBlockTextures } from "./useBlocks";
import {
  borderBottomTilingSpriteProps,
  borderTopTilingSpriteProps,
} from "./useBorders";
import {
  entryZoneBottomTilingProps,
  entryZoneTopTilingProps,
  TilingSpriteProps,
} from "./useEntryZone";
import { generatePatternSprite } from "./usePattern";
import { scaledStaticPattern } from "./useStaticPattern";
import { tilingSpriteRenderTexture } from "./useTilingSpriteRenderTexture";

export const generateBoardImage = (
  renderer: Renderer,
  cosmetics: Cosmetics,
  textures: Textures,
  playerTowers: Tower[],
  board: Board,
  tileSize: number,
  customPattern?: CustomPattern
) => {
  const container = new Container();
  container.scale.x = tileSize / cst.tileSize;
  container.scale.y = tileSize / cst.tileSize;

  const bottomFrameTilingSpriteProps = (
    [
      {
        texture: "tile.png",
        y: 1,
        x: 1 / 2,
        width: board.width,
        height: board.height,
      },
    ] as TilingSpriteProps[]
  )
    .concat(borderBottomTilingSpriteProps(board))
    .concat(entryZoneBottomTilingProps(board, false))
    .concat(entryZoneBottomTilingProps(board, true));

  const bottomFrameTexture = tilingSpriteRenderTexture(
    renderer,
    textures,
    bottomFrameTilingSpriteProps
  );
  const bottomFrameSprite = new Sprite();
  bottomFrameSprite.texture = bottomFrameTexture;
  container.addChild(bottomFrameSprite);

  const { blockBottomTexture, playerBlockTopTexture, staticBlockTopTexture } =
    generateBlockTextures(board, playerTowers, textures, renderer);
  const blockBottomSprite = new Sprite();
  blockBottomSprite.texture = blockBottomTexture;
  blockBottomSprite.x = (cst.tileSize * 1) / 4;
  blockBottomSprite.y = (cst.tileSize * 3) / 4;
  container.addChild(blockBottomSprite);

  if (board.waypoint) {
    const waypoint = new Sprite();
    waypoint.texture = textures["waypoint.png"]!!;
    waypoint.x = (board.waypoint.x + 9 / 16) * cst.tileSize;
    waypoint.y = (board.waypoint.y + 17 / 16) * cst.tileSize;
    waypoint.width = (cst.tileSize * 7) / 8;
    waypoint.height = (cst.tileSize * 7) / 8;
    waypoint.tint = 0xff0000;
    waypoint.alpha = 0.4;
    container.addChild(waypoint);
  }

  const playerBlockTopSprite = new Sprite();
  playerBlockTopSprite.texture = playerBlockTopTexture;
  playerBlockTopSprite.x = (1 / 2) * cst.tileSize;
  playerBlockTopSprite.y = cst.tileSize;
  playerBlockTopSprite.tint = cosmetics.block.pattern.firstColor.color.tint;
  container.addChild(playerBlockTopSprite);

  const playerPattern = customPattern
    ? renderCustomPattern(renderer, customPattern, board)
    : generatePatternSprite(
        cst.tileSize,
        renderer,
        cosmetics.block.pattern.pattern,
        board
      );

  if (cosmetics.block.pattern.secondColor) {
    const secondPlayerBlockTopSprite = new Sprite();
    secondPlayerBlockTopSprite.texture = playerBlockTopTexture;
    secondPlayerBlockTopSprite.x = (1 / 2) * cst.tileSize;
    secondPlayerBlockTopSprite.y = cst.tileSize;
    secondPlayerBlockTopSprite.tint =
      cosmetics.block.pattern.secondColor.color.tint;
    secondPlayerBlockTopSprite.mask = playerPattern;
    secondPlayerBlockTopSprite.addChild(playerPattern);
    container.addChild(secondPlayerBlockTopSprite);
  }

  const staticBlockTopSprite = new Sprite();
  staticBlockTopSprite.texture = staticBlockTopTexture;
  staticBlockTopSprite.x = (1 / 2) * cst.tileSize;
  staticBlockTopSprite.y = cst.tileSize;
  staticBlockTopSprite.tint = cst.firstStaticTint;
  container.addChild(staticBlockTopSprite);

  const staticPattern = scaledStaticPattern(
    renderer,
    board.height,
    board.width
  );

  const secondStaticBlockTopSprite = new Sprite();
  secondStaticBlockTopSprite.texture = staticBlockTopTexture;
  secondStaticBlockTopSprite.x = (1 / 2) * cst.tileSize;
  secondStaticBlockTopSprite.y = cst.tileSize;
  secondStaticBlockTopSprite.tint = cst.secondStaticTint;
  secondStaticBlockTopSprite.mask = staticPattern;
  secondStaticBlockTopSprite.addChild(staticPattern);
  container.addChild(secondStaticBlockTopSprite);

  board.staticTowers
    .concat(playerTowers)
    .filter((it) => it.clap)
    .map((it) => it.coord)
    .forEach(({ x, y }) => {
      const frostSprite = new Sprite();
      frostSprite.texture = textures["frostCooldownIndicator0300.png"]!;
      frostSprite.x = (x + 3 / 4) * cst.tileSize;
      frostSprite.y = (y + 5 / 4) * cst.tileSize;
      frostSprite.width = (3 / 2) * cst.tileSize;
      frostSprite.height = (3 / 2) * cst.tileSize;
      container.addChild(frostSprite);
    });

  const topFrameTilingSpriteProps = entryZoneTopTilingProps(board, false)
    .concat(entryZoneTopTilingProps(board, true))
    .concat(borderTopTilingSpriteProps(board, 0xffffff));

  const topFrameTexture = tilingSpriteRenderTexture(
    renderer,
    textures,
    topFrameTilingSpriteProps
  );
  const topFrameSprite = new Sprite();
  topFrameSprite.texture = topFrameTexture;
  container.addChild(topFrameSprite);

  const mask = new Graphics();
  mask.beginFill(0xff0000);
  mask.drawRect(
    0,
    0,
    (board.width + 1) * tileSize,
    (board.height + 1.5) * tileSize
  );
  mask.endFill();
  container.mask = mask;

  const actualContainer = new Container();
  actualContainer.addChild(container);
  actualContainer.width = mask.width;
  actualContainer.height = mask.height;

  const base64Image = renderer.plugins.extract.base64(actualContainer);
  playerPattern.destroy(true);
  staticPattern.destroy(true);
  bottomFrameTexture.destroy(true);
  topFrameTexture.destroy(true);
  staticBlockTopSprite.destroy(true);
  playerBlockTopSprite.destroy(true);
  blockBottomTexture.destroy(true);
  mask.destroy();
  actualContainer.destroy({ children: true });

  return base64Image;
};
