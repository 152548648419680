import { Avatar, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { Player } from "../../shared/Player";
import Emoji from "./Emoji";
import { Fragment, useState } from "react";
import { SlowInterval } from "../../shared/Solution";
import { formatScore } from "../../utils/formatScore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Path } from "../../shared/Path";
import { PathStats } from "./PathStats";
import { useNavigate } from "react-router";
export type RunnerIndicatorState = "completed" | "slowed" | "running";

export interface RunnerIndicatorProps {
  slowIntervals?: SlowInterval[];
  player: Player;
  result: number;
  score?: number;
  place?: number;
  completed: boolean;
  isPlayer: boolean;
  state?: RunnerIndicatorState;
  clickable?: boolean;
  isWatched?: boolean;
  offsetIcons?: boolean;
  isTitle?: boolean;
  path?: Path;
  prioritize?: (playerId: number) => void;
}

const goldColor = "#ccad00";
const silverColor = "#8c8c8c";
const bronzeColor = "#cd7f32";
const restColor = "#565656";

export const RunnerIndicator = (props: RunnerIndicatorProps) => {
  const {
    slowIntervals = [],
    isPlayer,
    player,
    score = 0,
    result,
    place,
    completed,
    state,
    isTitle = false,
    isWatched = false,
    prioritize = () => {},
    clickable = false,
    path,
  } = props;

  const isCompleted = state === "completed" || completed || score >= result;

  const navigate = useNavigate();

  const isSlowed =
    state === "slowed" ||
    (slowIntervals &&
      slowIntervals.findIndex(
        (slowInterval) => score >= slowInterval.from && score <= slowInterval.to
      ) !== -1);

  const [showStats, setShowStats] = useState(false);

  const [statsHaveBeenShown, setStatsHaveBeenShown] = useState(false);

  return (
    <Chip
      sx={{
        backgroundColor:
          !isWatched && !isTitle
            ? undefined
            : !isCompleted
            ? isSlowed
              ? "rgb(41, 182, 246)"
              : "#66bb6a"
            : place === 1
            ? goldColor
            : place === 2
            ? silverColor
            : place === 3
            ? bronzeColor
            : restColor,
        borderColor:
          isWatched && isTitle
            ? undefined
            : !isCompleted
            ? undefined
            : place === 1
            ? goldColor
            : place === 2
            ? silverColor
            : place === 3
            ? bronzeColor
            : undefined,
        borderBottomLeftRadius: isTitle ? 0 : undefined,
        borderBottomRightRadius: isTitle ? 0 : undefined,
        borderWidth:
          !isWatched && !isTitle && isCompleted && place && place < 4 ? 2 : 1,
        display: "flex",
        boxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
        WebkitBoxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
        MozBoxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
        mx: isTitle ? 0.5 : 0.5,
        "& .MuiChip-label": {
          width: "100%",
          color:
            (isCompleted || !isWatched) && (!isTitle || isCompleted)
              ? (isWatched || isTitle) && place && place < 4
                ? "#121212"
                : isCompleted
                ? place === 1
                  ? goldColor
                  : place === 2
                  ? "lightGray"
                  : place === 3
                  ? bronzeColor
                  : "lightGray"
                : "lightGray"
              : "#121212",
        },
        "& .MuiChip-deleteIcon": {
          color: "#121212",
        },
        ":hover": {
          backgroundColor:
            !isWatched && !isTitle
              ? undefined
              : !isCompleted
              ? isSlowed
                ? "rgb(41, 182, 246)"
                : "#66bb6a"
              : place === 1
              ? goldColor
              : place === 2
              ? silverColor
              : place === 3
              ? bronzeColor
              : restColor,
          filter: isWatched ? undefined : "brightness(130%)",
        },
        ":focus": {
          filter: "brightness(130%)",
          boxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
          WebkitBoxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
          MozBoxShadow: isTitle ? "inset 0 0 4px #000000" : undefined,
        },
      }}
      key={"indicator" + player.id}
      variant={isWatched || isTitle ? "filled" : "outlined"}
      clickable={(clickable && !isPlayer) || (isTitle && !player.guest)}
      onClick={() => {
        if (clickable && !isWatched) {
          prioritize(player.id);
        }
        if (isTitle && !player.guest) {
          navigate("/profile/" + player.name);
        }
      }}
      deleteIcon={
        path && (
          <Tooltip
            title={<PathStats path={path} />}
            disableHoverListener
            disableFocusListener
            disableTouchListener
            open={showStats}
          >
            {showStats ? <HighlightOffIcon /> : <InfoOutlinedIcon />}
          </Tooltip>
        )
      }
      onDelete={
        path &&
        (isCompleted || statsHaveBeenShown) &&
        isTitle &&
        path.sections.some((it) => it.clapCoord)
          ? (event) => {
              event.stopPropagation();
              setShowStats((v) => !v);
              setStatsHaveBeenShown(true);
            }
          : undefined
      }
      label={
        <Grid
          container
          justifyContent="space-between"
          width="100%"
          display="flex"
          sx={{ pt: 0.2 }}
        >
          {player.guest ? (
            <Fragment>
              <Grid item xs={isCompleted ? 6 : 12} sm={isCompleted ? 9 : 12}>
                <Typography align="left" variant="body2" noWrap>
                  {isPlayer ? (
                    <b>
                      <i>{player.name}</i>
                    </b>
                  ) : (
                    <i>{player.name}</i>
                  )}
                </Typography>
              </Grid>
              {isCompleted && (
                <Grid item xs={6} sm={3}>
                  <Typography align="right" variant="body2">
                    <b>{isCompleted ? formatScore(result) : ""}</b>
                  </Typography>
                </Grid>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={isCompleted ? 6 : 12} sm={isCompleted ? 9 : 12}>
                <Typography align="left" variant="body2" noWrap>
                  {isPlayer ? <b>{player.name}</b> : player.name}
                </Typography>
              </Grid>
              {isCompleted && (
                <Grid item xs={6} sm={3}>
                  <Typography align="right" variant="body2">
                    <b>{isCompleted ? formatScore(result) : ""}</b>
                  </Typography>
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      }
      icon={
        <Avatar
          sx={{
            width: 24,
            height: 24,
            border: 1,
            borderColor: "black",
            color: "lightgray",
            backgroundColor: isCompleted
              ? "#565656"
              : isSlowed
              ? "#005362"
              : "#00B062",
          }}
        >
          <Typography
            sx={{
              pt:
                (place !== undefined &&
                  (place < 4 ? 0.1 : place < 100 ? 0.4 : 0.245)) ||
                0,
              fontSize:
                (place !== undefined &&
                  (place < 4 ? 20 : place < 100 ? 15 : 10)) ||
                16,
            }}
          >
            <b>
              {isCompleted &&
                (place === 1 ? (
                  <Emoji symbol={"🥇"} />
                ) : place === 2 ? (
                  <Emoji symbol={"🥈"} />
                ) : place === 3 ? (
                  <Emoji symbol={"🥉"} />
                ) : (
                  place
                ))}
            </b>
          </Typography>
        </Avatar>
      }
    />
  );
};
