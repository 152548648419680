import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { GoogleSigninButton } from "./elements/GoogleSigninButton";
import { useLocation, useNavigate } from "react-router";
import { MazePaper } from "./elements/MazePaper";
import { Box, Link } from "@mui/material";
import { getMetricsService } from "../network/MetricsService";

export const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasLogged = useRef(false);

  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      getMetricsService().logSignIn()
    }
  }, [hasLogged])

  return (
      <Container component="main" maxWidth="xs" sx={{pt:5}}>
        <MazePaper title="Sign in">
          <Typography>
            By signing in you agree to to let
            maze.game store a cookie about your login session.
          </Typography>
          <br />
          <GoogleSigninButton returnRoute={location.pathname} />
        </MazePaper>
        <Box sx={{pt:3}}>
        <Typography variant="caption">
          <Link
            underline="none"
            href="#"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </Link>{" "}
          —{" "}
          <Link
            underline="none"
            href="#"
            onClick={() => navigate("/terms-of-service")}
          >
            Terms of Service
          </Link>
        </Typography>
        </Box>
      </Container>
  );
};
