import axios from "axios";
import { Offer } from "../shared/Product";
import { ShopDisplay, ShopItem } from "../shared/Shop";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface ShopService {
  display: () => Promise<ShopDisplay | ErrorMessage>;
  buy: (id: number) => Promise<ShopItem | ErrorMessage>;
  offers: () => Promise<Offer[] | ErrorMessage>;
  canceledOrder: () => Promise<void | ErrorMessage>;
  sponsorPublic: () => Promise<void | ErrorMessage>;
  sponsorAnonymous: () => Promise<void | ErrorMessage>;
}

export const getShopService = (): ShopService => ({
  display: () =>
    axios
      .get<ShopDisplay>(cst.backendUrl + "/shop/display", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  buy: (id: number) =>
    axios
      .post<ShopItem>(cst.backendUrl + "/shop/buy", id, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  offers: () =>
    axios
      .get<Offer[]>(cst.backendUrl + "/shop/offers", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  canceledOrder: () =>
    axios
      .post<void>(cst.backendUrl + "/shop/checkout/cancel", {}, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  sponsorPublic: () =>
    axios
      .post<void>(cst.backendUrl + "/shop/sponsor/public", {}, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  sponsorAnonymous: () =>
    axios
      .post<void>(cst.backendUrl + "/shop/sponsor/anonymous", {}, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
