import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  Grid,
  Button,
  Tooltip,
  Link,
} from "@mui/material";
import Emoji, { Points } from "./Emoji";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { theme } from "../../utils/theme";
import { PlayerProfileLink } from "./PlayerProfileLink";
import { CosmeticPuzzleSnapshot } from "../../shared/Cosmetics";
import { useNavigate } from "react-router";
import { RoundResultsListElement } from "./RoundResultsListElement";
import { getPuzzleService } from "../../network/PuzzleService";
import { lt } from "../../utils/lt";
import { SponsorText } from "./SponsorText";
import { puzzlePrizePool } from "./PuzzlePreview";

interface PuzzleSnapshotElementProps {
  snapshot: CosmeticPuzzleSnapshot;
  playerId: number;
  replay: () => void;
}

const selectedColor = theme.palette.info.light + "15";

const noBorders = {
  "&:before": {
    display: "none",
  },
  boxShadow: "none",
};

const roundedBottoms = {
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
};
const roundedTops = { borderTopRightRadius: 4, borderTopLeftRadius: 4 };

export const PuzzleSnapshotElement = (props: PuzzleSnapshotElementProps) => {
  const { snapshot, playerId, replay } = props;

  const navigate = useNavigate();

  const [sponsorOpen, setSponsorOpen] = useState(false);

  return (
    <Box sx={{ m: -2 }}>
      {snapshot.currentPuzzle.info.sponsors.amount > 0 && (
        <Tooltip
          title={
            <Box>
              <Typography sx={{ pb: 1 }} align="center" width={1}>
                This <b>Puzzle</b> is sponsored by:
                <br />
                <br />
              </Typography>
              <Box
                display="inline-block"
                maxHeight={200}
                overflow="auto"
                width={1}
                sx={{ mx: -1, px: 1, my: -0.7 }}
              >
                <Typography align={"center"} width={1}>
                  <SponsorText
                    anonymous={snapshot.currentPuzzle.info.sponsors.anonymous}
                    names={snapshot.currentPuzzle.info.sponsors.named}
                  />
                </Typography>
              </Box>
              <Typography sx={{ pb: 1, mt: -2 }} align="center" width={1}>
                <br />
                <br />
                <i>
                  When mazers support <b>maze.game</b> by buying <Points /> in
                  the{" "}
                  <Link
                    underline="none"
                    href="#"
                    onClick={() => navigate("/store")}
                  >
                    Store
                  </Link>
                  , approx. the same amount of <Points /> will be divided among
                  the prize pools of all upcoming <b>Puzzles</b>.
                </i>
              </Typography>
            </Box>
          }
          open={sponsorOpen}
        >
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              borderColor: "white",
            }}
            onClick={() => setSponsorOpen((s) => !s)}
            color={"warning"}
            variant={"outlined"}
            endIcon={sponsorOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          >
            <Typography>
              <b>
                Sponsored:{" "}
                {lt(
                  snapshot.currentPuzzle.info,
                  (info) =>
                    puzzlePrizePool(info.currentPlayers, info.sponsors.amount) -
                    puzzlePrizePool(info.currentPlayers, 0)
                )}{" "}
                <Points />
              </b>
            </Typography>
          </Button>
        </Tooltip>
      )}
      {snapshot.currentPuzzle.results.findIndex(
        (playerResult) =>
          playerResult.player.id === playerId && playerResult.result
      ) !== -1 && (
        <Fragment>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Current Results
          </Typography>
          <Accordion
            disableGutters
            defaultExpanded
            elevation={2}
            sx={{ ...roundedTops, ...roundedBottoms, ...noBorders }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
                width={1}
              >
                <Grid item xs={0} sm={1} />
                <Grid item xs={4}>
                  <Typography align="right">
                    {snapshot.currentPuzzle.results[0].player.guest ? (
                      <b>
                        <i>{snapshot.currentPuzzle.results[0].player.name}</i>
                      </b>
                    ) : (
                      <b>{snapshot.currentPuzzle.results[0].player.name}</b>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align="center">—</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="left">
                    <b>
                      {(
                        snapshot.currentPuzzle.results[0].result!! * 1000
                      ).toFixed(0)}
                    </b>
                  </Typography>
                </Grid>
                <IconButton sx={{ py: 1, my: -1 }} onClick={replay}>
                  <VisibilityIcon />
                </IconButton>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, m: 0 }}>
              {snapshot.currentPuzzle.results.map(
                ({ player, result }, index) => (
                  <Grid
                    container
                    width={1}
                    height={40}
                    justifyContent="space-between"
                    key={"row" + player.id + "current" + index}
                    sx={{
                      pr: { xs: 2, sm: 0 },
                      ...(player.id === playerId
                        ? { bgcolor: selectedColor }
                        : {}),
                    }}
                  >
                    <Grid container width="12%" alignItems="center">
                      <Typography variant="body2" textAlign="center" width={1}>
                        {index === 0 ? (
                          <Emoji symbol="🥇" label="1st" />
                        ) : index === 1 ? (
                          <Emoji symbol="🥈" label="2nd" />
                        ) : index === 2 ? (
                          <Emoji symbol="🥉" label="3rd" />
                        ) : (
                          index + 1
                        )}
                      </Typography>
                    </Grid>
                    <Grid container width="58%" alignItems="center">
                      <Typography variant="body2" textAlign="left" noWrap>
                        {player.guest ? (
                          <i>{player.name}</i>
                        ) : (
                          <PlayerProfileLink playerName={player.name} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid container width="22%" alignItems="center">
                      <Typography
                        variant="body2"
                        textAlign="right"
                        width={1}
                        sx={{ mr: 1 }}
                      >
                        {(result!! * 1000).toFixed(0)}
                      </Typography>
                    </Grid>
                    <Grid container width="8%"></Grid>
                  </Grid>
                )
              )}
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
      <Typography variant="h6" sx={{ my: 1 }}>
        Past Puzzles
      </Typography>
      <RoundResultsListElement
        highlightPlayedRounds
        playerId={playerId}
        results={snapshot.pastPuzzles}
        replay={(id: number) => navigate("/puzzle/past/" + id)}
        getPlayerSolution={getPuzzleService().pastSolution}
      />
    </Box>
  );
};
