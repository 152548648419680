import {
  BlockColor,
  Pattern,
  Rarity,
  UniqueBlockPattern,
} from "../shared/Cosmetics";

export const legendaryTops = [
  {
    color: {
      name: "Cyan",
      tint: 1044734,
    },
    indentTint: 907236,
  },
  {
    color: {
      name: "Ultraberry",
      tint: 7798920,
    },
    indentTint: 6291566,
  },
  {
    color: {
      name: "Green Mana",
      tint: 2536551,
    },
    indentTint: 2136664,
  },
  {
    color: {
      name: "Too Blue to be True",
      tint: 35071,
    },
    indentTint: 31461,
  },
  {
    color: {
      name: "Hot Pink",
      tint: 16712333,
    },
    indentTint: 15008126,
  },
  {
    color: {
      name: "So Sour",
      tint: 65297,
    },
    indentTint: 58639,
  },
  {
    color: {
      name: "Minty Paradise",
      tint: 65467,
    },
    indentTint: 58792,
  },
  {
    color: {
      name: "Lapis Neptune",
      tint: 2040530,
    },
    indentTint: 1777080,
  },
];

export const rareTops = [
  {
    color: {
      name: "Dream Vapor",
      tint: 13408750,
    },
    indentTint: 11962580,
  },
  {
    color: {
      name: "Ice Temple",
      tint: 1179630,
    },
    indentTint: 1041878,
  },
  {
    color: {
      name: "Laguna",
      tint: 3581385,
    },
    indentTint: 3117231,
  },
  {
    color: {
      name: "Hello Spring",
      tint: 4513126,
    },
    indentTint: 3982170,
  },
  {
    color: {
      name: "Herbalist's Garden",
      tint: 1153280,
    },
    indentTint: 950016,
  },
  {
    color: {
      name: "Space Opera",
      tint: 5575133,
    },
    indentTint: 4919235,
  },
  {
    color: {
      name: "High Blue",
      tint: 5023968,
    },
    indentTint: 4428998,
  },
  {
    color: {
      name: "Aquamarine",
      tint: 3074235,
    },
    indentTint: 2674342,
  },
  {
    color: {
      name: "Berry",
      tint: 10030923,
    },
    indentTint: 8326206,
  },
  {
    color: {
      name: "Party Pig",
      tint: 15636991,
    },
    indentTint: 14060005,
  },
  {
    color: {
      name: "Magentleman",
      tint: 11150011,
    },
    indentTint: 9575841,
  },
  {
    color: {
      name: "Vivid Violet",
      tint: 10420479,
    },
    indentTint: 9371877,
  },
  {
    color: {
      name: "Verminal",
      tint: 5622801,
    },
    indentTint: 4895246,
  },
  {
    color: {
      name: "Blue Blue",
      tint: 3342540,
    },
    indentTint: 2883762,
  },
  {
    color: {
      name: "Pool Table",
      tint: 234872,
    },
    indentTint: 162659,
  },
  {
    color: {
      name: "Green Priestess",
      tint: 1170773,
    },
    indentTint: 1033035,
  },
  {
    color: {
      name: "King Triton",
      tint: 3966398,
    },
    indentTint: 3371940,
  },
  {
    color: {
      name: "Luxurious Lime",
      tint: 8973858,
    },
    indentTint: 7984158,
  },
  {
    color: {
      name: "Dandelion",
      tint: 16703240,
    },
    indentTint: 14993415,
  },
  {
    color: {
      name: "Tropical Rainforest",
      tint: 30046,
    },
    indentTint: 23369,
  },
  {
    color: {
      name: "Nebula",
      tint: 10552515,
    },
    indentTint: 9110441,
  },
];

export const uncommonTops = [
  {
    color: {
      name: "Fresh Air",
      tint: 10938367,
    },
    indentTint: 9818085,
  },
  {
    color: {
      name: "Ivory",
      tint: 16777200,
    },
    indentTint: 15066584,
  },
  {
    color: {
      name: "Pristine Oceanic",
      tint: 52411,
    },
    indentTint: 45731,
  },
  {
    color: {
      name: "Blister Pearl",
      tint: 11206638,
    },
    indentTint: 10085846,
  },
  {
    color: {
      name: "High Seas",
      tint: 8235992,
    },
    indentTint: 7247550,
  },
  {
    color: {
      name: "Toxic Sludge",
      tint: 47923,
    },
    indentTint: 41260,
  },
  {
    color: {
      name: "Walk in the Park",
      tint: 8960785,
    },
    indentTint: 7708942,
  },
  {
    color: {
      name: "Flood",
      tint: 6715323,
    },
    indentTint: 5793441,
  },
  {
    color: {
      name: "Brown Sugar",
      tint: 10580553,
    },
    indentTint: 8871741,
  },
  {
    color: {
      name: "Royal Lavender",
      tint: 7885225,
    },
    indentTint: 6636687,
  },
  {
    color: {
      name: "Fennel Flower",
      tint: 7842559,
    },
    indentTint: 7051749,
  },
  {
    color: {
      name: "Candy Grape Fizz",
      tint: 7820782,
    },
    indentTint: 6966228,
  },
  {
    color: {
      name: "Fairy Tale Green",
      tint: 8965205,
    },
    indentTint: 7844426,
  },
  {
    color: {
      name: "Mintastic",
      tint: 11534293,
    },
    indentTint: 10347967,
  },
  {
    color: {
      name: "Pacific Pleasure",
      tint: 1473943,
    },
    indentTint: 1206141,
  },
  {
    color: {
      name: "Marsupilami",
      tint: 16642560,
    },
    indentTint: 14932224,
  },
  {
    color: {
      name: "Baby Pink",
      tint: 16758734,
    },
    indentTint: 15049913,
  },
  {
    color: {
      name: "Soft Boiled",
      tint: 16758583,
    },
    indentTint: 15049777,
  },
  {
    color: {
      name: "Soft Cashmere",
      tint: 15709912,
    },
    indentTint: 14000832,
  },
  {
    color: {
      name: "Calypso",
      tint: 4026760,
    },
    indentTint: 3234670,
  },
  {
    color: {
      name: "Lemon",
      tint: 15662882,
    },
    indentTint: 14083358,
  },
  {
    color: {
      name: "Golden Relic",
      tint: 15257161,
    },
    indentTint: 13547328,
  },
  {
    color: {
      name: "Cherry Blossom",
      tint: 16240352,
    },
    indentTint: 14530760,
  },
  {
    color: {
      name: "Hole In One",
      tint: 4894359,
    },
    indentTint: 4166784,
  },
  {
    color: {
      name: "Lavender Night",
      tint: 6702216,
    },
    indentTint: 5388142,
  },
  {
    color: {
      name: "Victorian Garden",
      tint: 5606988,
    },
    indentTint: 4551742,
  },
  {
    color: {
      name: "Eternal Winter",
      tint: 10287871,
    },
    indentTint: 9232869,
  },
  {
    color: {
      name: "Bitter Lemon",
      tint: 13818674,
    },
    indentTint: 12173612,
  },
  {
    color: {
      name: "Kaiser Cheese",
      tint: 15651972,
    },
    indentTint: 13942133,
  },
  {
    color: {
      name: "Frozen Wave",
      tint: 5680330,
    },
    indentTint: 4953776,
  },
  {
    color: {
      name: "Pastel Violet",
      tint: 13343177,
    },
    indentTint: 11634095,
  },
  {
    color: {
      name: "Ultra Moss",
      tint: 13759320,
    },
    indentTint: 12310862,
  },
  {
    color: {
      name: "Berry Jam",
      tint: 6641795,
    },
    indentTint: 5326441,
  },
  {
    color: {
      name: "Gunmetal",
      tint: 5464679,
    },
    indentTint: 4081997,
  },
];

export const commonTops = [
  {
    color: {
      name: "Exotic Escape",
      tint: 9886175,
    },
    indentTint: 8700101,
  },
  {
    color: {
      name: "Fallen Petal",
      tint: 15917274,
    },
    indentTint: 14207171,
  },
  {
    color: {
      name: "Fizz",
      tint: 11656106,
    },
    indentTint: 10273174,
  },
  {
    color: {
      name: "Droplet",
      tint: 11197951,
    },
    indentTint: 10077925,
  },
  {
    color: {
      name: "Kashmir",
      tint: 7310698,
    },
    indentTint: 5927766,
  },
  {
    color: {
      name: "Icey",
      tint: 11527126,
    },
    indentTint: 10209725,
  },
  {
    color: {
      name: "Cream and Sugar",
      tint: 14536633,
    },
    indentTint: 12826531,
  },
  {
    color: {
      name: "Crystal Palace",
      tint: 13881259,
    },
    indentTint: 12170646,
  },
  {
    color: {
      name: "Cameo",
      tint: 15916732,
    },
    indentTint: 14206632,
  },
  {
    color: {
      name: "Organic",
      tint: 7631457,
    },
    indentTint: 5920843,
  },
  {
    color: {
      name: "Verde",
      tint: 8369027,
    },
    indentTint: 7117168,
  },
  {
    color: {
      name: "Pistachio",
      tint: 9684338,
    },
    indentTint: 8366947,
  },
  {
    color: {
      name: "Swimming",
      tint: 12772837,
    },
    indentTint: 11324363,
  },
  {
    color: {
      name: "Green Tea",
      tint: 11908751,
    },
    indentTint: 10198138,
  },
  {
    color: {
      name: "Mimosa",
      tint: 16116181,
    },
    indentTint: 14405822,
  },
  {
    color: {
      name: "Endive",
      tint: 13558216,
    },
    indentTint: 11978673,
  },
  {
    color: {
      name: "Bangalore",
      tint: 12298888,
    },
    indentTint: 10588789,
  },
  {
    color: {
      name: "Mecha Kitty",
      tint: 13681875,
    },
    indentTint: 11971769,
  },
  {
    color: {
      name: "Mascarpone",
      tint: 15525588,
    },
    indentTint: 13815229,
  },
  {
    color: {
      name: "Neptune Green",
      tint: 8371102,
    },
    indentTint: 7184776,
  },
  {
    color: {
      name: "Moonraker",
      tint: 12628695,
    },
    indentTint: 11115709,
  },
  {
    color: {
      name: "Succulent Lime",
      tint: 14474597,
    },
    indentTint: 12763993,
  },
  {
    color: {
      name: "Kale",
      tint: 5927495,
    },
    indentTint: 4544567,
  },
  {
    color: {
      name: "Scampi",
      tint: 7300000,
    },
    indentTint: 6116230,
  },
  {
    color: {
      name: "Treetop",
      tint: 9549484,
    },
    indentTint: 8166547,
  },
  {
    color: {
      name: "Basil Smash",
      tint: 12050849,
    },
    indentTint: 10667918,
  },
  {
    color: {
      name: "Flame Seal",
      tint: 16048730,
    },
    indentTint: 14338640,
  },
  {
    color: {
      name: "Rosemarried",
      tint: 8493903,
    },
    indentTint: 7045442,
  },
  {
    color: {
      name: "Partial Pink",
      tint: 16772600,
    },
    indentTint: 15062495,
  },
  {
    color: {
      name: "Gateway Grey",
      tint: 10526876,
    },
    indentTint: 8816259,
  },
  {
    color: {
      name: "Goldilocks",
      tint: 16774042,
    },
    indentTint: 15063690,
  },
  {
    color: {
      name: "Shattered Ice",
      tint: 14348006,
    },
    indentTint: 12768461,
  },
  {
    color: {
      name: "Willow Leaf",
      tint: 10593389,
    },
    indentTint: 8882780,
  },
  {
    color: {
      name: "Sailor Boy",
      tint: 11451344,
    },
    indentTint: 10003638,
  },
  {
    color: {
      name: "Dolly",
      tint: 16118129,
    },
    indentTint: 14407525,
  },
  {
    color: {
      name: "Open Seas",
      tint: 8630204,
    },
    indentTint: 7444386,
  },
  {
    color: {
      name: "Canadian Maple",
      tint: 13283942,
    },
    indentTint: 11574105,
  },
  {
    color: {
      name: "Bakery Brown",
      tint: 11243640,
    },
    indentTint: 9534054,
  },
  {
    color: {
      name: "Butter Honey",
      tint: 16115115,
    },
    indentTint: 14405017,
  },
  {
    color: {
      name: "Pearly",
      tint: 16049119,
    },
    indentTint: 14339015,
  },
  {
    color: {
      name: "Incense",
      tint: 11508350,
    },
    indentTint: 9798507,
  },
  {
    color: {
      name: "Honeydew Sand",
      tint: 15650445,
    },
    indentTint: 13940605,
  },
  {
    color: {
      name: "Whirlpool",
      tint: 10868941,
    },
    indentTint: 9551540,
  },
  {
    color: {
      name: "Moon Rock",
      tint: 9800580,
    },
    indentTint: 8090477,
  },
  {
    color: {
      name: "Igniting",
      tint: 16045722,
    },
    indentTint: 14335881,
  },
  {
    color: {
      name: "Shipyard",
      tint: 5205893,
    },
    indentTint: 4151659,
  },
  {
    color: {
      name: "Purple Haze",
      tint: 8418198,
    },
    indentTint: 6971260,
  },
  {
    color: {
      name: "Caramel Finish",
      tint: 16766362,
    },
    indentTint: 15056778,
  },
  {
    color: {
      name: "Lingering Storm",
      tint: 8749953,
    },
    indentTint: 7039336,
  },
  {
    color: {
      name: "Moon Base",
      tint: 8224119,
    },
    indentTint: 6513502,
  },
  {
    color: {
      name: "Sleepless Blue",
      tint: 12245997,
    },
    indentTint: 10863571,
  },
  {
    color: {
      name: "Grey",
      tint: 8421504,
    },
    indentTint: 6710886,
  },
  {
    color: {
      name: "Eiffel Tower",
      tint: 10063491,
    },
    indentTint: 8353389,
  },
  {
    color: {
      name: "Mamba",
      tint: 7761276,
    },
    indentTint: 6116962,
  },
  {
    color: {
      name: "Petrified",
      tint: 9143936,
    },
    indentTint: 7433576,
  },
  {
    color: {
      name: "Powdered",
      tint: 16380647,
    },
    indentTint: 14670287,
  },
  {
    color: {
      name: "Statuary",
      tint: 10396837,
    },
    indentTint: 8751755,
  },
  {
    color: {
      name: "Yorkshire Cloud",
      tint: 12239820,
    },
    indentTint: 10660530,
  },
];

export const newColors: BlockColor[] = [
  {
    color: {
      name: "Coquelicot",
      tint: 16726016,
    },
    indentTint: 18365696,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Grenadine Pink",
      tint: 16736619,
    },
    indentTint: 18377333,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Pirate's Cape",
      tint: 12255232,
    },
    indentTint: 13893632,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Red Ribbon",
      tint: 15534655,
    },
    indentTint: 17173317,
    rarity: Rarity.LEGENDARY,
  },
  {
    color: {
      name: "Fire Ant",
      tint: 12477440,
    },
    indentTint: 14119168,
    rarity: Rarity.COMMON,
  },
  {
    color: {
      name: "Orange Juice",
      tint: 16744192,
    },
    indentTint: 18385664,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Caponata",
      tint: 8530448,
    },
    indentTint: 10170899,
    rarity: Rarity.COMMON,
  },
  {
    color: {
      name: "Beetroot Purple",
      tint: 13577585,
    },
    indentTint: 15217278,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Lilacquered",
      tint: 12802969,
    },
    indentTint: 14444205,
    rarity: Rarity.UNCOMMON,
  },
  {
    color: {
      name: "Marinara",
      tint: 16711688,
    },
    indentTint: 18350088,
    rarity: Rarity.LEGENDARY,
  },
  {
    color: {
      name: "Atomic Tangerine",
      tint: 16750950,
    },
    indentTint: 18393200,
    rarity: Rarity.UNCOMMON,
  },
  {
    color: {
      name: "Sly Fox",
      tint: 8406849,
    },
    indentTint: 10048845,
    rarity: Rarity.COMMON,
  },
  {
    color: {
      name: "Funki Porcini",
      tint: 15636889,
    },
    indentTint: 17279401,
    rarity: Rarity.COMMON,
  },
  {
    color: {
      name: "Dragon Fire",
      tint: 16540717,
    },
    indentTint: 18181681,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Capri",
      tint: 49151,
    },
    indentTint: 54040,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Liquid Neon",
      tint: 13172480,
    },
    indentTint: 14489600,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Lionheart",
      tint: 13378082,
    },
    indentTint: 15017510,
    rarity: Rarity.RARE,
  },
  {
    color: {
      name: "Firebug",
      tint: 13458513,
    },
    indentTint: 15099739,
    rarity: Rarity.UNCOMMON,
  },
  {
    color: {
      name: "Lima",
      tint: 11139441,
    },
    indentTint: 12259964,
    rarity: Rarity.UNCOMMON,
  },
  {
    color: {
      name: "Lush Bamboo",
      tint: 11516723,
    },
    indentTint: 13030457,
    rarity: Rarity.COMMON,
  },
  {
    color: {
      name: "Sweetcorn",
      tint: 16165888,
    },
    indentTint: 17808640,
    rarity: Rarity.UNCOMMON,
  },
  {
    color: {
      name: "Rose Laffy Taffy",
      tint: 10896987,
    },
    indentTint: 12537960,
    rarity: Rarity.COMMON,
  },
  {
    color: { name: "Gilded Sun", tint: 16768256 }, // 16768564 },
    indentTint: 18412601,
    rarity: Rarity.LEGENDARY,
  },
];

export const tops: BlockColor[] = [
  ...legendaryTops.map((color) => ({ rarity: Rarity.LEGENDARY, ...color })),
  ...rareTops.map((color) => ({ rarity: Rarity.RARE, ...color })),
  ...uncommonTops.map((color) => ({ rarity: Rarity.UNCOMMON, ...color })),
  ...commonTops.map((color) => ({ rarity: Rarity.COMMON, ...color })),
  ...newColors,
];

export const allUniqueSolidBlockPatterns: UniqueBlockPattern[] = tops.map(
  (color, index) => ({
    id: index,
    blockPattern: {
      pattern: Pattern.SOLID,
      firstColor: color,
    },
  })
);
