import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { FixedSizeList } from "react-window";
import { CellProps } from "./ScoreCard";
import { lt } from "../../utils/lt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useResizeObserver from "@react-hook/resize-observer";
import { SortButton } from "./SortButton";
import { SeedInfo } from "../../shared/Admin";
import { formatScore } from "../../utils/formatScore";

interface SeedInfosElementProps {
  seedInfos: SeedInfo[];
  getSeedSolutions: (seed: number) => void;
}

type OrderedSeedInfo = {
  order: number;
  id: number;
  status: SeedInfo;
};

type SortType = "SEED" | "PLAYERS" | "AVG RESULT";

export const SeedInfosElement = (props: SeedInfosElementProps) => {
  const { seedInfos, getSeedSolutions } = props;

  const [sortType, setSortType] = useState<SortType>("PLAYERS");

  const [ascending, setAscending] = useState(false);

  const sortOrder = useCallback(
    (a: OrderedSeedInfo, b: OrderedSeedInfo) => {
      if (sortType === "SEED") {
        return ascending
          ? a.status.seed - b.status.seed
          : b.status.seed - a.status.seed;
      } else if (sortType === "PLAYERS") {
        return ascending
          ? a.status.solutions - b.status.solutions
          : b.status.solutions - a.status.solutions;
      } else if (sortType === "AVG RESULT") {
        return ascending
          ? a.status.averageScore - b.status.averageScore
          : b.status.averageScore - a.status.averageScore;
      } else {
        return ascending
          ? a.status.seed - b.status.seed
          : b.status.seed - a.status.seed;
      }
    },
    [sortType, ascending]
  );

  const orderedServeStatuses = useMemo<OrderedSeedInfo[]>(
    () =>
      seedInfos.map((seedInfo, index) => ({
        order: index + 1,
        id: +seedInfo.seed,
        status: seedInfo,
      })),
    [seedInfos]
  );

  const sortedServeStatuses = useMemo<OrderedSeedInfo[]>(
    () => orderedServeStatuses.sort(sortOrder),
    [orderedServeStatuses, sortOrder]
  );

  const target = useRef<any>();

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  useResizeObserver(target, (entry) => {
    setWidth(entry.contentRect.width);
    setHeight(entry.contentRect.height);
  });

  return (
    <Box sx={{ p: 1 }}>
      <Paper>
        <Grid container sx={{ py: 1, pr: 1 }}>
          <Grid item xs={3}>
            <SortButton
              active={sortType === "SEED"}
              onClick={(asc) => {
                setSortType("SEED");
                setAscending(asc);
              }}
              text={"Seed"}
            />
          </Grid>
          <Grid item xs={3}>
            <SortButton
              active={sortType === "PLAYERS"}
              onClick={(asc) => {
                setSortType("PLAYERS");
                setAscending(asc);
              }}
              text={"Solutions"}
            />
          </Grid>
          <Grid item xs={3}>
            <SortButton
              active={sortType === "AVG RESULT"}
              onClick={(asc) => {
                setSortType("AVG RESULT");
                setAscending(asc);
              }}
              text={"Avg. Result"}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Box
          width={1}
          height={400}
          ref={target}
          sx={{
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
            overflow: "hidden",
          }}
        >
          <FixedSizeList
            height={height}
            itemCount={Object.entries(seedInfos).length}
            itemSize={40}
            width={width}
            style={{
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              overflow: "auto",
            }}
          >
            {({ index, style }: CellProps) => (
              <div
                style={{
                  ...style,
                  backgroundColor: index % 2 === 1 ? "#414141" : "#484848",
                }}
              >
                {lt(sortedServeStatuses[index], ({ status, id, order }) => (
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={{ py: 1 }} align="right">
                        {status.seed}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ py: 1 }} align="right">
                        {status.solutions}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ py: 1 }} align="right">
                        {formatScore(status.averageScore)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => getSeedSolutions(status.seed)}>
                        <VisibilityIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </FixedSizeList>
        </Box>
      </Paper>
    </Box>
  );
};
