import { useCallback, useState } from "react";
import { lt } from "./lt";

const fromString = (string: string) => string.split(",").map(Number);

export const useStoredPrioritizedList: (
  key: string
) => [number[], (a: number) => void] = (key: string) => {
  const [prioritizedList, setPrioritizedList] = useState(
    lt(localStorage.getItem(key), (stringOrNull) =>
      stringOrNull === null ? [] : fromString(stringOrNull)
    )
  );

  const prioritize = useCallback(
    (a: number) => {
      setPrioritizedList((oldList) => {
        const newList = [
          a,
          ...oldList.filter((it) => it !== a),
        ];
        localStorage.setItem(key, newList.toString());
        return newList
      });
    },
    [setPrioritizedList, key]
  );

  return [prioritizedList, prioritize];
};
