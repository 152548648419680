import React, { useMemo } from "react";
import { Container, Text } from "@inlet/react-pixi";
import { tutorialTextStyle } from "./ui/textStyle";

interface TutorialTextProps {
  tutorialStep: number;
  boardHeight: number;
  boardWidth: number;
  tileSize: number;
  isTouch: boolean;
}

interface TextProp {
  x: number;
  y: number;
  text: string;
  size: number;
  wrapWidth: number;
}

const step4 = (width: number, height: number, isTouch: boolean) => ({
  x: width / 2,
  y: height / 2,
  text:
    "You can " +
    (isTouch ? "<Tap>" : "<Left Click>") +
    " to build a Block                         Try building three Blocks!",
  size: 1.5,
  wrapWidth: width * 0.6,
});

const step7 = (width: number, height: number, isTouch: boolean) => ({
  x: width / 2,
  y: height / 2,
  text:
    "You can " +
    (isTouch ? "<Tap>" : "<Left Click>") +
    " a Block to remove it                                 Try to remove all the Blocks!",
  size: 1.5,
  wrapWidth: width * 0.7,
});

const step13 = (width: number, height: number) => ({
  x: width / 2,
  y: height / 2,
  text: "Try to build a Maze using all 10 Blocks you have available                      You cannot block the Ball from reaching the end",
  size: 1.5,
  wrapWidth: width * 0.9,
});

const step23 = (width: number, height: number, isTouch: boolean) => ({
  x: width / 2,
  y: height / 2,
  text:
    "You can " +
    (isTouch ? "<Long Press>" : "<Right Click>") +
    " a Block to Frost it                                                      Try to Frost one of the Blocks you have placed!",
  size: 1.5,
  wrapWidth: width * 0.9,
});

const step30 = (width: number, height: number, tileSize: number) => ({
  x: width - 4 * tileSize,
  y: height,
  text: "Once you are ready to submit your solution, press the Submit button",
  size: 1.5,
  wrapWidth: width * 0.4,
});

export const TutorialText = (props: TutorialTextProps) => {
  const { tutorialStep, boardHeight, boardWidth, tileSize, isTouch } = props;

  const height = boardHeight * tileSize;
  const width = boardWidth * tileSize;
  const textPropsPerStep = useMemo<{ [key in number]: TextProp }>(
    () => ({
      0: {
        x: width / 2,
        y: height / 2,
        text: "Welcome to the Tutorial, <Left Click> or <Tap> anywhere to proceed!",
        size: 1.5,
        wrapWidth: width * 0.8,
      },
      1: {
        x: width / 2,
        y: height / 2,
        text: "This game is about creating Mazes!",
        size: 1.5,
        wrapWidth: width * 0.8,
      },
      2: {
        x: width / 6,
        y: height / 8,
        text: "To build a Maze you have some Blocks, they are shown up here!",
        size: 1.5,
        wrapWidth: width * 0.3,
      },
      3: {
        x: width / 4,
        y: height / 10,
        text: "In this case you have three Blocks!",
        size: 1.5,
        wrapWidth: width * 0.5,
      },
      4: step4(width, height, isTouch),
      5: step4(width, height, isTouch),
      7: step7(width, height, isTouch),
      8: step7(width, height, isTouch),
      9: {
        x: width / 2,
        y: height / 2,
        text: "When you have created a Maze, the Ball will roll through the shortest path",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      10: {
        x: width / 2,
        y: height,
        text: "The Ball will start from one of the two blue tiles",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      11: {
        x: width / 2,
        y: height / 11,
        text: "And it will roll towards the checkered line                                          on one of the orange tiles",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      12: {
        x: width / 2,
        y: height / 2,
        text: "Each round has some Blocks that cannot be removed                                 You will have to build your Maze around those",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      13: step13(width, height),
      14: step13(width, height),
      16: {
        x: width / 2,
        y: height / 2,
        text: "Alright! Let us see how the Ball fares going through your Maze",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      19: {
        x: width / 2,
        y: height / 2,
        text: "Your Maze is Scored based on how long it takes the Ball to roll through it",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      20: {
        x: width - 3 * tileSize,
        y: height / 10,
        text: "Here you can see the Score of your Maze",
        size: 1.5,
        wrapWidth: width * 0.35,
      },
      21: {
        x: width / 2,
        y: height / 2,
        text: "Alright, let us look at Frost Blocks!",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      22: {
        x: width / 4,
        y: height / 8,
        text: "The Snowflakes shows how many Frost Blocks you have",
        size: 1.5,
        wrapWidth: width * 0.5,
      },
      23: step23(width, height, isTouch),
      24: step23(width, height, isTouch),
      25: {
        x: width / 2,
        y: height / 2,
        text: "Once the Ball comes within range of a Frost Block, the Ball will be slowed down for a while",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      26: {
        x: width / 2,
        y: height / 2,
        text:
          "You can check the range of a Frost Block by " +
          (isTouch
            ? "holding your finger on it"
            : "hovering the cursor over it"),
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      27: {
        x: width / 2,
        y: height / 2,
        text:
          "You can " +
          (isTouch ? "<Tap>" : "<Left Click>") +
          " to remove a Frost Block                                           " +
          "You can " +
          (isTouch ? "<Long Press>" : "<Right Click>") +
          " to Downgrade it to a regular Block",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      28: {
        x: width / 2,
        y: height / 2,
        text: "A Frost Block can hit the Ball multiple times, but it needs time to charge up.",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      29: {
        x: width / 2,
        y: height / 2,
        text: "Give it a try, see if you can place a Frost Block such that the Ball will come within its range",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
      30: step30(width, height, tileSize),
      31: step30(width, height, tileSize),
      32: step30(width, height, tileSize),
      33: {
        x: (width * 3) / 4,
        y: tileSize * 2,
        text: "Some game modes have a time constraint, up here you can see how much time you have left!",
        size: 1.5,
        wrapWidth: width * 0.5,
      },
      34: {
        x: width / 2,
        y: height / 2,
        text: "That is all for this tutorial, go try out your new skills in the Arena, try a Puzzle, or Challenge your friends to see who can build the best Maze!",
        size: 1.5,
        wrapWidth: width * 0.9,
      },
    }),
    [width, height, isTouch, tileSize]
  );

  const textProp = useMemo(
    () => textPropsPerStep[tutorialStep],
    [tutorialStep, textPropsPerStep]
  );

  return (
    <Container>
      {textProp ? (
        <Text
          anchor={{ x: 0.5, y: 0.54 }}
          x={textProp.x}
          y={textProp.y}
          text={textProp.text}
          style={tutorialTextStyle(
            tileSize * textProp.size,
            textProp.wrapWidth
          )}
          alpha={1}
        />
      ) : (
        <Container />
      )}
    </Container>
  );
};
