import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, Alert } from "@mui/material";
import { Player } from "../shared/Player";
import { MazePaper } from "./elements/MazePaper";
import { Points } from "./elements/Emoji";
import { isSaves, Save } from "../shared/Save";
import { getSaveService } from "../network/SaveService";
import { useNavigate } from "react-router";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { lt } from "../utils/lt";

interface SavesProps {
  player: Player;
}

export const Saves = (props: SavesProps) => {
  const { player } = props;

  const [saves, setSaves] = useState<Save[] | undefined>();

  useEffect(() => {
    getSaveService()
      .getSaves()
      .then((response) => {
        if (isSaves(response)) {
          setSaves(response);
        } else {
          console.error(response);
        }
      });
  }, [player, setSaves]);

  const navigate = useNavigate();

  const content = useMemo(
    () => (
      <Box maxWidth="sm" mx="auto" sx={{ p: 1 }}>
        {player && (
          <MazePaper title="Saved Rounds" goBack={() => navigate(-1)}>
            <Typography sx={{ p: 2, px: 4 }}>
              When playing in the <b>Arena</b> you can spend <Points /> to save
              the rounds you have played, the rounds will show up here, allowing
              you to replay them.
            </Typography>
            <Fragment>
              {saves &&
                saves.map((save) => (
                  <Button
                    key={save.seed}
                    fullWidth
                    variant="contained"
                    color="info"
                    sx={{ mt: 1, mb: 1, height: 50 }}
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      localStorage.setItem("returnRoute", "/saves");
                      navigate("/save/" + save.seed);
                    }}
                  >
                    <Box
                      sx={{
                        pt: 1,
                        pb: 1,
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: 1,
                      }}
                    >
                      <Typography sx={{ pt: 1, pb: 1 }}>
                        <b>{save.origin === "ARENA" ? "Arena" : "Challenge"}</b>
                      </Typography>
                      <Typography sx={{ pt: 1, pb: 1 }}>
                        {lt(
                          new Date(save.savedAt),
                          (date) =>
                            date.toLocaleDateString() +
                            " - " +
                            date.toLocaleTimeString()
                        )}
                      </Typography>
                      <PlayCircleOutlineIcon />
                    </Box>
                  </Button>
                ))}
              {saves && saves.length === 0 && (
                <Alert severity="info" variant="outlined">
                  You have no saved rounds
                </Alert>
              )}
            </Fragment>
          </MazePaper>
        )}
      </Box>
    ),
    [saves, player, navigate]
  );

  return content;
};
