import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  TableHead,
  Tooltip,
  Paper,
  Grid,
  Avatar,
} from "@mui/material";
import { MazePaper } from "./elements/MazePaper";
import { isPlayerProfile, Player, PlayerProfile } from "../shared/Player";
import { getPlayerService } from "../network/PlayerService";
import { useNavigate, useParams } from "react-router-dom";
import { lt } from "../utils/lt";
import Emoji from "./elements/Emoji";
import { cst } from "../utils/constants";
import { AchievementStatusElement } from "./elements/AchievementStatusElement";
import { useCheckAchievements } from "../utils/useCheckAchievements";
import { periodicalName } from "../utils/periodicalName";
import { BlockPatternName } from "./elements/BlockPatternName";
import { InspectablePattern } from "./elements/InspectablePattern";
import { RarityBadge } from "./elements/RarityBadge";
import { ballCosmeticImage } from "../utils/imageGenerators";
import { useTextures } from "../game/sprites/TextureDaemon";
import { theme } from "../utils/theme";
import { SingleRoundElement } from "./elements/SingleRoundElement";

const noBottomBorder = { borderBottom: "none" };

interface ProfileCellProps {
  content: string | JSX.Element;
}

const ProfileCellLeft = (props: ProfileCellProps) => (
  <TableCell width="20%" sx={noBottomBorder} align="right">
    <Typography>{props.content}</Typography>
  </TableCell>
);
const ProfileCellCenter = (props: ProfileCellProps) => (
  <TableCell width="20%" sx={noBottomBorder} align="center">
    <Typography>{props.content}</Typography>
  </TableCell>
);
const ProfileCell = (props: ProfileCellProps) => (
  <TableCell width="20%" sx={noBottomBorder}>
    <Typography>{props.content}</Typography>
  </TableCell>
);

interface ProfileProps {
  player: Player | undefined | null;
}

export const Profile = (props: ProfileProps) => {
  const { player } = props;
  const { playerName } = useParams();

  const [playerProfile, setPlayerProfile] = useState<
    PlayerProfile | undefined
  >();

  const checkAchievements = useCheckAchievements();

  useEffect(() => {
    getPlayerService()
      .profile(playerName)
      .then((response) => {
        if (isPlayerProfile(response)) {
          setPlayerProfile(response);
          checkAchievements();
        }
      });
    // eslint-disable-next-line
  }, [playerName, setPlayerProfile]);

  const isOwnProfile = playerName === undefined;

  const [hasBeenReported, setHasBeenReported] = useState(false);

  const navigate = useNavigate();
  const textures = useTextures();

  return (
    <Box
      sx={{
        px: 0,
        pt: 5,
      }}
      mx="auto"
      maxWidth="sm"
      height="90vh"
    >
      {playerProfile && (
        <MazePaper
          title={playerProfile.name}
          topRight={
            <Fragment>
              {!hasBeenReported &&
                playerName &&
                player &&
                player.name !== playerProfile.name && (
                  <Tooltip
                    title={"Report offensive player name"}
                    enterTouchDelay={0}
                  >
                    <Button
                      sx={{ textTransform: "none", mt: 2, p: 0.5, pb: 0 }}
                      color="warning"
                      onClick={() => {
                        setHasBeenReported(true);
                        getPlayerService().reportPlayerName(playerName);
                      }}
                    >
                      ⚠
                    </Button>
                  </Tooltip>
                )}
            </Fragment>
          }
        >
          <Table>
            <TableBody>
              <TableRow>
                <ProfileCellLeft content={"Playing Since"} />
                <ProfileCell
                  content={new Date(playerProfile.createdOn).toDateString()}
                />
              </TableRow>
            </TableBody>
          </Table>
          {playerProfile.bestRound && (
            <Box sx={{ pb: 2 }}>
              <Divider sx={{ mb: 2 }} />
              <Typography sx={{ pb: 2 }}>
                <b>Best Round</b>
              </Typography>
              <SingleRoundElement
                round={playerProfile.bestRound.round}
                playerSolution={{
                  player: { id: 0, name: playerProfile.name, guest: false },
                  solution: playerProfile.bestRound.solution,
                  cosmetics: playerProfile.cosmetics,
                }}
                place={1}
                width={Math.min(
                  theme.breakpoints.values.sm - 64,
                  window.innerWidth - 32
                )}
              />
            </Box>
          )}
          <Divider sx={{ mb: 2 }} />
          <Typography sx={{ pb: 2 }}>
            <b>Current Aesthetics</b>
          </Typography>
          <Grid container spacing={2} justifyContent="center" sx={{ pb: 2 }}>
            <Grid item xs={3}>
              <InspectablePattern
                inspectDisabled
                blockPattern={playerProfile.cosmetics.block.pattern}
              />
              <BlockPatternName
                pattern={playerProfile.cosmetics.block.pattern}
              />
            </Grid>
            <Grid item xs={3}>
              <RarityBadge
                firstRarity={playerProfile.cosmetics.ball.color.rarity}
              >
                <Avatar
                  src={
                    ballCosmeticImage(
                      [playerProfile.cosmetics.ball.color],
                      textures
                    )[0]
                  }
                  sx={{
                    width: cst.tileSize,
                    height: cst.tileSize,
                  }}
                />
              </RarityBadge>
              <Typography lineHeight={3.4}>
                <b>{playerProfile.cosmetics.ball.color.color.name}</b>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Fragment>
            <Typography sx={{ p: 2 }}>
              <b>Puzzles</b>
            </Typography>
            {playerProfile.currentPuzzles.length > 0 && (
              <Fragment>
                <Typography>Currently active in</Typography>
                {playerProfile.currentPuzzles
                  .sort((a, b) => b - a)
                  .map((periodicalId) => (
                    <Button
                      sx={{ textTransform: "none", m: 1 }}
                      color="primary"
                      variant="contained"
                      onClick={() => navigate("/puzzle/" + periodicalId)}
                    >
                      <Typography>
                        <b>{periodicalName(periodicalId)}</b>
                      </Typography>
                    </Button>
                  ))}
              </Fragment>
            )}
            {playerProfile.puzzleRating > 0 && (
              <Table>
                <TableBody>
                  <TableRow>
                    <ProfileCellLeft content="Rating" />
                    <ProfileCell
                      content={"" + Math.max(playerProfile.puzzleRating, 0)}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Fragment>
          <Fragment>
            {Object.entries(playerProfile.puzzleResults).length > 0 && (
              <Paper elevation={4} sx={{ mt: 0, overflow: "auto" }}>
                {lt(
                  Object.entries(playerProfile.puzzleResults),
                  (entries) =>
                    entries.length > 0 && (
                      <Typography sx={{ pt: 2 }}>
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                borderBottom: 1,
                                borderBottomColor: "#505050",
                              }}
                            >
                              <ProfileCell content="" />
                              <ProfileCellCenter
                                content={<Emoji symbol="🥇" label="1st" />}
                              />
                              <ProfileCellCenter
                                content={<Emoji symbol="🥈" label="2nd" />}
                              />
                              <ProfileCellCenter
                                content={<Emoji symbol="🥉" label="3rd" />}
                              />
                              <ProfileCell content="Total" />
                            </TableRow>
                            {entries
                              .sort((a, b) => +b[0] - +a[0])
                              .map(([periodicalId, puzzleResult], index) => (
                                <TableRow
                                  sx={{
                                    borderBottom:
                                      index === entries.length - 1 ? 0 : 1,
                                    borderBottomColor: "#505050",
                                  }}
                                >
                                  <ProfileCellLeft
                                    content={
                                      <b>{periodicalName(+periodicalId)}</b>
                                    }
                                  />
                                  <ProfileCellCenter
                                    content={
                                      puzzleResult.gold > 0
                                        ? "" + puzzleResult.gold
                                        : ""
                                    }
                                  />
                                  <ProfileCellCenter
                                    content={
                                      puzzleResult.silver > 0
                                        ? "" + puzzleResult.silver
                                        : ""
                                    }
                                  />
                                  <ProfileCellCenter
                                    content={
                                      puzzleResult.bronze > 0
                                        ? "" + puzzleResult.bronze
                                        : ""
                                    }
                                  />
                                  <ProfileCell
                                    content={"" + puzzleResult.total}
                                  />
                                </TableRow>
                              ))}
                          </TableHead>
                        </Table>
                      </Typography>
                    )
                )}
              </Paper>
            )}
          </Fragment>
          <Fragment>
            {(Object.entries(playerProfile.puzzleResults).length > 0 ||
              playerProfile.currentPuzzles.length > 0) && (
              <Divider sx={{ my: 2 }} />
            )}
          </Fragment>
          <Fragment>
            {playerProfile.arenaRounds > 0 && (
              <Fragment>
                <Typography sx={{ pb: 2 }}>
                  <b>Arena</b>
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <ProfileCellLeft content="Rounds Played" />
                      <ProfileCell
                        content={"" + Math.max(playerProfile.arenaRounds, 0)}
                      />
                    </TableRow>
                    <TableRow>
                      <ProfileCellLeft content="Best Classic" />
                      <ProfileCell
                        content={
                          "" +
                          Math.min(
                            playerProfile.bestArenaResult,
                            cst.arena.maxWins
                          ) +
                          " - " +
                          (playerProfile.bestArenaResult >= cst.arena.maxWins
                            ? cst.arena.maxLosses +
                              cst.arena.maxWins -
                              playerProfile.bestArenaResult -
                              1
                            : cst.arena.maxLosses)
                        }
                      />
                    </TableRow>
                    {playerProfile.bestCompetitiveResult > 0 && (
                      <TableRow>
                        <ProfileCellLeft content="Best Competitive" />
                        <ProfileCell
                          content={
                            "" +
                            Math.min(
                              playerProfile.bestCompetitiveResult,
                              cst.arena.maxWins
                            ) +
                            " - " +
                            (playerProfile.bestCompetitiveResult >=
                            cst.arena.maxWins
                              ? cst.arena.maxLosses +
                                cst.arena.maxWins -
                                playerProfile.bestCompetitiveResult -
                                1
                              : cst.arena.maxLosses)
                          }
                        />
                      </TableRow>
                    )}
                    {playerProfile.bestPrestigeResult > 0 && (
                      <TableRow>
                        <ProfileCellLeft content="Best Prestige" />
                        <ProfileCell
                          content={
                            "" +
                            Math.min(
                              playerProfile.bestPrestigeResult,
                              cst.arena.maxWins
                            ) +
                            " - " +
                            (playerProfile.bestPrestigeResult >=
                            cst.arena.maxWins
                              ? cst.arena.maxLosses +
                                cst.arena.maxWins -
                                playerProfile.bestPrestigeResult -
                                1
                              : cst.arena.maxLosses)
                          }
                        />
                      </TableRow>
                    )}
                    {playerProfile.rating > 0 && (
                      <TableRow>
                        <ProfileCellLeft content="Rating" />
                        <ProfileCell content={"" + playerProfile.rating} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Divider />
              </Fragment>
            )}
          </Fragment>
          <Fragment>
            {(playerProfile.createdChallenges > 0 ||
              playerProfile.challengeRounds > 0) && (
              <Fragment>
                <Typography sx={{ p: 2 }}>
                  <b>Challenges</b>
                </Typography>
                <Table>
                  <TableBody>
                    {playerProfile.challengeRounds > 0 && (
                      <TableRow>
                        <ProfileCellLeft content="Rounds Played" />
                        <ProfileCell
                          content={"" + playerProfile.challengeRounds}
                        />
                      </TableRow>
                    )}
                    {playerProfile.createdChallenges > 0 && (
                      <TableRow>
                        <ProfileCellLeft content="Challenges Created" />
                        <ProfileCell
                          content={"" + playerProfile.createdChallenges}
                        />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Fragment>
            )}
          </Fragment>
          <Fragment>
            {(isOwnProfile ||
              playerProfile.achievements.findIndex(
                (status) => status.achieved
              ) !== -1) && (
              <Fragment>
                <Divider />
                <Typography sx={{ p: 2 }}>
                  <b>Achievements</b>
                </Typography>
                {playerProfile.achievements
                  .sort((a, b) =>
                    a.information.area < b.information.area ? -1 : 1
                  )
                  .map(
                    (achievementStatus) =>
                      (isOwnProfile || achievementStatus.achieved) && (
                        <AchievementStatusElement
                          achievementStatus={achievementStatus}
                        />
                      )
                  )}
              </Fragment>
            )}
          </Fragment>
        </MazePaper>
      )}
    </Box>
  );
};
