import React, { useEffect, useState } from "react";
import { Container, Sprite, Text } from "@inlet/react-pixi";
import { scoreTextStyle } from "./textStyle";
import { Textures } from "../sprites/texture";
import { secondsToHHMMSS } from "../../utils/secToHHMMSS";

interface timeCounterProps {
  seconds: number;
  x: number;
  y: number;
  height: number;
  tileSize: number;
  textures: Textures;
}

export const TimeCounter = (props: timeCounterProps) => {
  const { tileSize, seconds, x, y, height, textures } = props;

  let [tint, setTint] = useState(0xffffff);

  useEffect(() => {
    if (seconds <= 10 && tint !== 0xe67d49) {
      setTint((t) => (t === 0xe67d49 ? t : 0xe67d49));
    }
  }, [tint, setTint, seconds]);

  return (
    <Container x={x} y={y}>
      <Container>
        <Sprite
          texture={textures["sideTop1WN.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 5) / 16}
          tint={tint}
          x={(tileSize * 17) / 32}
          y={(tileSize * 1.5) / 16}
        />
        <Sprite
          texture={textures["sideTop1EN.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 63) / 32}
          y={(tileSize * 1) / 8}
        />
        <Sprite
          texture={textures["sideTop4N.png"]}
          width={(tileSize * 5) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 3) / 4}
          y={(tileSize * -1) / 8}
        />
        <Sprite
          texture={textures["sideTop4S.png"]}
          width={(tileSize * 5) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 3) / 4}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopNW.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 17) / 32}
          y={(tileSize * -4) / 32}
        />
        <Sprite
          texture={textures["outerCornerTopSW.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 17) / 32}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopNE.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 63) / 32}
          y={(tileSize * -1) / 8}
        />
        <Sprite
          texture={textures["outerCornerTopSE.png"]}
          width={(tileSize * 1) / 4}
          height={(tileSize * 1) / 4}
          tint={tint}
          x={(tileSize * 63) / 32}
          y={(tileSize * 3) / 8}
        />
        <Sprite
          texture={textures["topGap.png"]}
          width={(tileSize * 5) / 4}
          height={(tileSize * 5) / 16}
          tint={tint}
          x={(tileSize * 25) / 32}
          y={(tileSize * 1.5) / 16}
        />
      </Container>
      <Text
        anchor={{ x: 1, y: 0.62 }}
        x={(tileSize * 16.3) / 8}
        y={height / 2}
        alpha={seconds <= 10 ? 0.9 : 0.7}
        text={secondsToHHMMSS(seconds).replaceAll("0", "O")}
        style={scoreTextStyle(tileSize * 1.4)}
      />
    </Container>
  );
};
