import { Box, Divider, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router";
import {
  AcademyCategory,
  academyCategoryLabel,
  AcademyLevelProgress,
  AcademyProgress,
  medalButtonColor,
} from "../../shared/Academy";
import { BoardSize, boardSizeLabel } from "../../shared/Board";
import { lt } from "../../utils/lt";
import { unselectable } from "../../utils/unselectable";

interface AcademyProgressProps {
  academyProgress?: AcademyProgress;
}

const noBoardSizeProgress: { [key in BoardSize]: AcademyLevelProgress[] } = {
  SMALL: [] as AcademyLevelProgress[],
  NORMAL: [] as AcademyLevelProgress[],
  LARGE: [] as AcademyLevelProgress[],
};

const noProgress: AcademyProgress = {
  SPARSE: noBoardSizeProgress,
  MEDIUM: noBoardSizeProgress,
  DENSE: noBoardSizeProgress,
  WAYPOINT: noBoardSizeProgress,
};

interface AcademyLevelProgressesElementProps {
  category: AcademyCategory;
  boardSize: BoardSize;
  progress: AcademyLevelProgress[];
  numbers?: boolean;
  currentLevel?: number;
}

export const AcademyLevelProgressesElement = (
  props: AcademyLevelProgressesElementProps
) => {
  const {
    progress,
    category,
    boardSize,
    numbers = false,
    currentLevel,
  } = props;

  const navigate = useNavigate();
  return (
    <Fragment>
      {Array.from(Array(10).keys()).map((level) => {
        let levelProgress = progress[level];
        let medal = levelProgress?.medal;
        return (
          <Box
            display="flex"
            width={1}
            borderRadius={1}
            key={level}
            boxShadow={
              "inset 2px 2px 2px " + (medal ? "#ffffff22" : "#00000077")
            }
            bgcolor={
              (medal ? medalButtonColor[medal] : "#707070") +
              (levelProgress && levelProgress.stale ? "77" : "ff")
            }
          >
            <Box
              display="flex"
              width={1}
              borderRadius={1}
              key={level}
              boxShadow={
                "inset -2px -2px 2px " + (medal ? "#00000077" : "#ffffff22")
              }
              sx={{
                opacity: 0.8,
                ":hover": {
                  opacity: numbers ? 1 : 0.2,
                  cursor: "pointer",
                  bgcolor: numbers ? undefined : "white",
                },
                ...(numbers
                  ? currentLevel === level
                    ? {
                        borderWidth: 2,
                        borderColor: "white",
                        borderStyle: "solid",
                      }
                    : {}
                  : {}),
              }}
              justifyContent="center"
              alignItems="center"
              onClick={() =>
                navigate(
                  "/academy/" +
                    category.toLowerCase() +
                    "/" +
                    boardSize.toLowerCase() +
                    "/" +
                    level
                )
              }
            >
              {numbers && (
                <Box mb={0.2} sx={unselectable}>
                  <b>{level + 1}</b>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Fragment>
  );
};

export const AcademyProgressElement = (props: AcademyProgressProps) => {
  const { academyProgress = noProgress } = props;

  return (
    <Stack width={1} spacing={1}>
      {Object.values(AcademyCategory).map((category: AcademyCategory) => (
        <Box
          bgcolor="#00000022"
          p={1}
          borderRadius={2}
          sx={{ boxShadow: "3px 3px 4px #00000055;" }}
          key={category}
        >
          <Typography
            bgcolor="#00000022"
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              ...unselectable,
            }}
            mb={1}
            mx={-1}
            mt={-1}
            py={0.5}
          >
            <b>{academyCategoryLabel[category]}</b>
          </Typography>
          <Divider sx={{ mb: 1, mt: -1, mx: -1 }} />
          <Stack width={1} height={1} spacing={{ xs: 0.75, sm: 1 }}>
            {Object.values(BoardSize).map((boardSize: BoardSize) => (
              <Stack
                key={boardSize}
                width={1}
                height={1}
                spacing={{ xs: 0.75, sm: 1 }}
                direction="row"
                alignItems="stretch"
                alignContent="stretch"
              >
                <Box>
                  {lt(boardSizeLabel[boardSize].split(" "), ([label, size]) => (
                    <Fragment>
                      <Typography
                        width={125}
                        sx={{
                          ...unselectable,
                          display: { xs: "none", sm: "block" },
                        }}
                        component="div"
                      >
                        <b>{label}</b>{" "}
                        <div style={{ display: "inline", opacity: 0.7 }}>
                          {size}
                        </div>
                      </Typography>
                      <Typography
                        width={65}
                        sx={{
                          ...unselectable,
                          display: { xs: "block", sm: "none" },
                        }}
                        variant="body2"
                      >
                        <b>{label}</b>
                      </Typography>
                      <Typography
                        width={65}
                        sx={{
                          ...unselectable,
                          display: { xs: "block", sm: "none" },
                          opacity: 0.7,
                          mt: -0.9,
                        }}
                        variant="caption"
                      >
                        {size}
                      </Typography>
                    </Fragment>
                  ))}
                </Box>
                <AcademyLevelProgressesElement
                  progress={academyProgress[category][boardSize]}
                  category={category}
                  boardSize={boardSize}
                />
              </Stack>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
