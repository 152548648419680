import * as PIXI from "pixi.js";
import { ISpritesheetData } from "pixi.js";
import { isTextureKey, undefinedTextures } from "./texture";

export type SpriteSheetData = { imageUrl: string; frameData: ISpritesheetData };

export const loadTextures = (sheets: SpriteSheetData[]) => {
  const loadedTextures = { ...undefinedTextures };
  console.log("loaded textures")
  sheets.forEach(({ imageUrl, frameData }) => {
    const baseTexture = PIXI.BaseTexture.from(imageUrl);
    const spritesheet = new PIXI.Spritesheet(baseTexture, frameData);
    PIXI.utils.clearTextureCache();
    spritesheet.parse(() => {
      Object.keys(spritesheet.textures).forEach((textureKey) => {
        if (isTextureKey(textureKey)) {
          let texture = spritesheet.textures[textureKey];
          loadedTextures[textureKey] = texture;
        } else {
          console.error(
            'The texture: "' +
              textureKey +
              '" is not defined in TextureKeys type.'
          );
        }
      });
    });
  });

  return loadedTextures;
};
