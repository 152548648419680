import { Sprite } from "@inlet/react-pixi";
import { Renderer, RenderTexture, Sprite as PixiSprite } from "pixi.js";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Board } from "../../shared/Board";
import { cst } from "../../utils/constants";
import { MaskedContainer } from "../components/MaskedContainer";
import { GradientFactory } from "./GradientFactory";
import { useSpriteCleanup } from "./useSpriteCleanup";

export const useTimerFlash = (
  board: Board,
  scoreOffset: number,
  renderer: Renderer,
  staticTopTexture: RenderTexture | undefined,
  timerFlash: MutableRefObject<() => void>,
  enabled: boolean
) => {
  const mask = useMemo(() => {
    if (!enabled) return false;

    let overlay = new PixiSprite();
    let renderTexture = RenderTexture.create({
      width: ((board.width + 1) * cst.tileSize) / 4,
      height: ((board.width + 1) * cst.tileSize) / 4,
    });
    let centerX = ((board.width - 18 / 6 - scoreOffset) * cst.tileSize) / 4;
    let centerY = (1 * cst.tileSize) / 4;
    overlay.texture = renderTexture;
    GradientFactory.createRadialGradient(renderer, renderTexture, {
      x0: centerX,
      y0: centerY,
      r0: 0,
      x1: centerX,
      y1: centerY,
      r1: (10 * cst.tileSize) / 4,
      colorStops: [
        { color: 0xffffff, offset: 0 },
        { color: 0x000000, offset: 1 },
      ],
    });
    overlay.scale.x = 4;
    overlay.scale.y = 4;
    return overlay;
  }, [renderer, board, scoreOffset, enabled]);

  useSpriteCleanup(mask);

  const [timerFlashAlpha, setTimerFlashAlpha] = useState(0);

  const flashStart = useRef(0);
  const flashInterval = useRef<number | undefined>();

  useEffect(() => {
    let mounted = true
    timerFlash.current = () => {
      flashStart.current = Date.now();
      if (flashInterval.current) {
        window.clearInterval(flashInterval.current);
      }
      flashInterval.current = window.setInterval(() => {
        let delta =
          (1000 - Math.abs(Date.now() - flashStart.current - 1000)) / 1000;
        let alpha = delta * delta * delta * delta;
        if (delta < 0) {
          if (mounted) setTimerFlashAlpha(0);
          window.clearInterval(flashInterval.current);
        } else {
          if (mounted) setTimerFlashAlpha(Math.max(alpha, 0));
        }
      }, 16);
    };
    return () => {
      mounted = false
      window.clearInterval(flashInterval.current);
    };
  }, [setTimerFlashAlpha, timerFlash]);

  return (
    <MaskedContainer mask={mask ? mask : undefined}>
      {staticTopTexture && (
        <Sprite
          texture={staticTopTexture}
          tint={0xe67d49}
          alpha={timerFlashAlpha}
        />
      )}
    </MaskedContainer>
  );
};
