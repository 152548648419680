import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getPlayerService } from "../network/PlayerService";
import {
  isPlayer,
  isPlayerAccount,
  Player,
  PlayerAccount,
} from "../shared/Player";
import { StatusCodes } from "http-status-codes";
import { Cosmetics, isCosmetics } from "../shared/Cosmetics";

export interface PlayerSession {
  player: Player | undefined | null | "unregistered";
  account: PlayerAccount | undefined;
  cosmetics: Cosmetics | undefined;
  logout: () => void;
  signup: (returnRoute: string) => void;
  signin: (returnRoute: string) => void;
  refreshAccount: () => void;
  refreshPlayer: () => void;
  refreshCosmetics: () => void;
  setFreshPoints: (points: number) => void;
  freshPoints: number | undefined;
  isLoading: boolean;
}


export function usePlayerSession(): PlayerSession {
  const navigate = useNavigate();

  const [player, setPlayer] = useState<
    Player | undefined | null | "unregistered"
  >(undefined);
  const [account, setAccount] = useState<PlayerAccount | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const [freshPoints, setFreshPoints] = useState<number | undefined>();

  const clearFreshPoints = useCallback(() => {
    setFreshPoints(undefined);
  }, [setFreshPoints]);

  const logout = useCallback(() => {
    getPlayerService().logout();
    navigate("/");
    setPlayer(null);
    setAccount(undefined);
  }, [setPlayer, navigate]);

  const refreshAccount = useCallback(() => {
    getPlayerService()
      .account()
      .then((response) => {
        if (isPlayerAccount(response)) {
          setAccount((oldAccount) => {
            if (oldAccount && oldAccount.points !== response.points) {
              setFreshPoints(response.points - oldAccount.points);
              window.setTimeout(clearFreshPoints, 3000);
            }
            return response;
          });
        } else {
          console.error(response);
        }
      });
  }, [setAccount, setFreshPoints, clearFreshPoints]);

  const [cosmetics, setCosmetics] = useState<Cosmetics | undefined>();

  const refreshCosmetics = useCallback(() => {
    getPlayerService()
      .cosmetics()
      .then((response) => {
        if (isCosmetics(response)) {
          setCosmetics(response);
        }
      });
  }, [setCosmetics]);

  const signin = useCallback(
    (signup: boolean) => (returnRoute: string) => {
      localStorage.setItem("returnRoute", returnRoute);
      navigate(signup ? "/signup/" : "/signin");
    },
    [navigate]
  );

  const refreshPlayer = useCallback(() => {
    setIsLoading(true);
    getPlayerService()
      .current()
      .then((response) => {
        setIsLoading(false);
        if (isPlayer(response)) {
          setPlayer(response);
          refreshAccount();
          refreshCosmetics();
        } else if (response === StatusCodes.NOT_FOUND) {
          setPlayer("unregistered");
          navigate("/register");
        } else if (response === StatusCodes.BAD_REQUEST) {
          setPlayer(null);
        }
      });
  }, [refreshCosmetics, refreshAccount, navigate]);

  // eslint-disable-next-line
  useEffect(() => refreshPlayer(), []);

  return {
    player: player,
    account: account,
    cosmetics: cosmetics,
    logout: logout,
    signin: signin(false),
    signup: signin(true),
    refreshAccount: refreshAccount,
    refreshPlayer: refreshPlayer,
    setFreshPoints: setFreshPoints,
    refreshCosmetics,
    isLoading: isLoading,
    freshPoints: freshPoints,
  };
}
