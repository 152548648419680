import axios from "axios";
import { CosmeticServe } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { ServeRound, ServeStatus, ServeStatusMap } from "../shared/Serve";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface ServeService {
  all: () => Promise<ServeStatusMap | ErrorMessage>;
  total: () => Promise<ServeStatus | ErrorMessage>;
  getServeRound: () => Promise<ServeRound | ErrorMessage>;
  getServe: (id: number) => Promise<CosmeticServe | ErrorMessage>;
  submitServe: (layout: Layout) => Promise<Path | ErrorMessage>;
  claim: () => Promise<number | ErrorMessage>;
}

export const getServeService = (): ServeService => ({
  all: () =>
    axios
      .get<ServeStatusMap>(cst.backendUrl + "/arena/serve/all", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  total: () =>
    axios
      .get<ServeStatus>(cst.backendUrl + "/arena/serve/total", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getServeRound: () =>
    axios
      .get<ServeRound>(cst.backendUrl + "/arena/serve/round", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getServe: (id: number) =>
    axios
      .get<CosmeticServe>(cst.backendUrl + "/arena/serve/" + id, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  submitServe: (layout: Layout) =>
    axios
      .post<Path>(cst.backendUrl + "/arena/serve/submit", layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  claim: () =>
    axios
      .get<number>(cst.backendUrl + "/arena/serve/claim", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
