import axios from "axios";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";

export interface FreeplayService {
  submit: (layout: Layout) => Promise<Path | ErrorMessage>;
}

export const getFreeplayService = (): FreeplayService => ({
  submit: (layout: Layout) =>
    axios
      .post<Path>(cst.backendUrl + "/freeplay", layout)
      .then((response) => response.data)
      .catch((reason) => "" + reason)
});

