import { SnackbarKey, useSnackbar } from "notistack";
import { useCallback, useRef } from "react";
import { getPlayerService } from "../network/PlayerService";
import { AchievementInformationElement } from "../pages/elements/AchievementInformationElement";
import { isAchievementInformations } from "../shared/Achievement";

export const useCheckAchievements = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const keyRef = useRef<{ [key in number]: undefined | SnackbarKey }>({});
  const checkAchievements = useCallback(() => {
    getPlayerService()
      .achievements()
      .then((response) => {
        if (isAchievementInformations(response)) {
          response.forEach((element, index) => {
            keyRef.current[index] = enqueueSnackbar(
              <AchievementInformationElement
                achievementInformation={element}
                keyRef={keyRef}
                index={index}
                closeSnackbar={closeSnackbar}
              />
            );
          });
        }
      });
  }, [keyRef, enqueueSnackbar, closeSnackbar]);
  return checkAchievements;
};
