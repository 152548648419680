import axios from "axios";
import { AchievementInformation } from "../shared/Achievement";
import { Cosmetics, Inventory } from "../shared/Cosmetics";
import {
  Player,
  PlayerAccount,
  PlayerCounts,
  PlayerProfile,
  RegistrationResponse,
} from "../shared/Player";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface PlayerService {
  current: () => Promise<Player | Number>;
  new: (
    name: string,
    referrer: string | null
  ) => Promise<RegistrationResponse | ErrorMessage>;
  get: (playerId: number) => Promise<Player | ErrorMessage>;
  reportPlayerName: (playerName: string) => Promise<void | ErrorMessage>;
  profile: (playerName?: string) => Promise<PlayerProfile | ErrorMessage>;
  achievements: () => Promise<AchievementInformation[] | ErrorMessage>;
  guest: () => Promise<Player | ErrorMessage>;
  account: () => Promise<PlayerAccount | ErrorMessage>;
  logout: () => Promise<void | ErrorMessage>;
  counts: () => Promise<PlayerCounts | ErrorMessage>;
  cosmetics: () => Promise<Cosmetics | ErrorMessage>;
  inventory: () => Promise<Inventory | ErrorMessage>;
  setBlockPattern: (id: number) => Promise<void | ErrorMessage>;
  setBallColor: (id: number) => Promise<void | ErrorMessage>;
  reference: (reference: string) => Promise<void | ErrorMessage>;
  guiltTrip: () => Promise<boolean | ErrorMessage>;
  postponeGuiltTrip: () => Promise<void | ErrorMessage>;
}

export const getPlayerService = (): PlayerService => ({
  counts: () =>
    axios
      .get<PlayerCounts>(cst.backendUrl + "/player/counts", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  cosmetics: () =>
    axios
      .get<Cosmetics>(cst.backendUrl + "/player/cosmetics", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  inventory: () =>
    axios
      .get<Inventory>(
        cst.backendUrl + "/player/cosmetics/inventory",
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  setBlockPattern: (id: number) =>
    axios
      .post<void>(
        cst.backendUrl + "/player/cosmetics/blockPattern",
        id,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  setBallColor: (id: number) =>
    axios
      .post<void>(
        cst.backendUrl + "/player/cosmetics/ballColor",
        id,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  current: () =>
    axios
      .get<Player>(cst.backendUrl + "/player", creds)
      .then((response) => response.data)
      .catch((error) =>
        error && error.response && error.response.status
          ? error.response.status
          : -1
      ),
  new: (name: string, referrer: string | null) =>
    axios
      .post<RegistrationResponse>(
        cst.backendUrl + "/player/new",
        referrer ? { name: name, referrer: referrer } : { name: name },
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  reportPlayerName: (playerName: string) =>
    axios
      .get<void>(cst.backendUrl + "/player/report/" + playerName, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  profile: (playerName?: string) =>
    playerName !== undefined && playerName !== null
      ? axios
          .get<PlayerProfile>(
            cst.backendUrl + "/player/profile/" + playerName
          )
          .then((response) => response.data)
          .catch((reason) => "" + reason)
      : axios
          .get<PlayerProfile>(cst.backendUrl + "/player/profile", creds)
          .then((response) => response.data)
          .catch((reason) => "" + reason),
  get: (playerId: number) =>
    axios
      .get<Player>(cst.backendUrl + "/player/" + playerId)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  achievements: () =>
    axios
      .get<AchievementInformation[]>(
        cst.backendUrl + "/player/achievements",
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  guest: () =>
    axios
      .get<Player>(cst.backendUrl + "/player/guest")
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  account: () =>
    axios
      .get<PlayerAccount>(cst.backendUrl + "/player/account", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  logout: () =>
    axios
      .get<void>(cst.backendUrl + "/player/logout", creds)
      .then((_) => {})
      .catch((reason) => "" + reason),
  guiltTrip: () =>
    axios
      .get<boolean>(cst.backendUrl + "/player/guilt/trip", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  postponeGuiltTrip: () =>
    axios
      .post<void>(
        cst.backendUrl + "/player/guilt/trip/postpone",
        {},
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  reference: (reference: string) =>
    axios
      .post<void>(cst.backendUrl + "/player/reference", reference, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});

export const playerService = getPlayerService()