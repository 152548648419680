import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Player } from "./shared/Player";

interface RedirectProps {
  player: Player | undefined | null;
}

export const Redirect = (props: RedirectProps) => {
  const { player } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const returnRoute = localStorage.getItem("returnRoute");
    if (returnRoute !== null) {
      navigate(returnRoute);
      if (player) {
        localStorage.removeItem("returnRoute");
      }
    } else {
      navigate("/");
    }
  });
  return <Box />;
};
