import { useCallback, useEffect, useRef } from 'react';

export default function useClickInside(callback: () => void) {
    const ref = useRef<any>(null);

    const handleClickInside = useCallback((event: any) => {
        if (ref.current && ref.current.contains(event.target)) {
          callback()
        }
    }, [callback, ref] );

    useEffect(() => {
        document.addEventListener('click', handleClickInside, true);
        return () => {
            document.removeEventListener('click', handleClickInside, true);
        };
    }, [handleClickInside]);

    return { ref };
}
