import { BlockState, windowRadius } from "./useBlocks";
import { SpriteProps } from "./windowToBottoms";

export const windowToTops: (
  window: BlockState,
  x: number,
  y: number
) => (false | SpriteProps)[] = (
  window: BlockState,
  x: number,
  y: number,
) => {
  let blockType = window[windowRadius][windowRadius];
  return [
    {
      texture: "topPlate.png",
      width: 3 / 2,
      height: 3 / 2,
      x: x + 1 / 2 + 1 / 4,
      y: y + 1 + 1 / 4,
    },
    window[0][0] === blockType &&
      (window[0][2] === blockType && window[2][0] !== blockType
        ? {
            texture: "innerCornerTopNE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 - 1 / 4,
            y: y + 1 - 2 / 4,
          }
        : window[2][0] === blockType && window[0][2] !== blockType
        ? {
            texture: "innerCornerTopSW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 - 2 / 4,
            y: y + 1 - 1 / 4,
          }
        : window[2][0] !== blockType &&
          window[0][2] !== blockType && {
            texture: "connectorTopB.png",
            width: 1,
            height: 1,
            x: x + 1 / 2 - 1 / 2,
            y: y + 1 - 1 / 2,
          }),
    window[4][0] === blockType &&
      (window[2][0] === blockType && window[4][2] !== blockType
        ? {
            texture: "innerCornerTopSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 7 / 4,
            y: y + 1 - 1 / 4,
          }
        : window[4][2] === blockType && window[2][0] !== blockType
        ? {
            texture: "innerCornerTopNW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 6 / 4,
            y: y + 1 - 2 / 4,
          }
        : window[4][2] !== blockType && window[2][0] !== blockType
        ? {
            texture: "connectorTopA.png",
            width: 1,
            height: 1,
            x: x + 1 / 2 + 3 / 2,
            y: y + 1 - 1 / 2,
          }
        : {
            texture: "topGap.png",
            width: 1 / 2,
            height: 1 / 2,
            x: x + 1 / 2 + 7 / 4,
            y: y + 1 - 1 / 4,
          }),
    (window[0][1] === blockType || window[0][2] === blockType) && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 - 1 / 4,
      y: y + 1 + 1 / 4,
    },
    window[0][2] === blockType && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 - 1 / 4,
      y: y + 1 + 3 / 4,
    },
    window[0][2] === blockType &&
      window[0][0] !== blockType &&
      window[1][0] !== blockType &&
      window[2][0] !== blockType && {
        texture: "sideTop2N.png",
        width: 1 / 2,
        height: 1 / 4,
        x: x + 1 / 2 - 1 / 4,
        y: y + 1 + 0,
      },
    window[0][2] === blockType &&
      window[0][4] !== blockType &&
      window[1][4] !== blockType &&
      window[2][4] !== blockType && {
        texture: "sideTop2S.png",
        width: 1 / 2,
        height: 1 / 4,
        x: x + 1 / 2 - 1 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[0][2] === blockType || window[0][3] === blockType) && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 - 1 / 4,
      y: y + 1 + 5 / 4,
    },
    (window[1][0] === blockType || window[2][0] === blockType) && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 + 1 / 4,
      y: y + 1 - 1 / 4,
    },
    window[2][0] === blockType && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 + 3 / 4,
      y: y + 1 - 1 / 4,
    },
    window[2][0] === blockType &&
      window[0][0] !== blockType &&
      window[0][1] !== blockType &&
      window[0][2] !== blockType && {
        texture: "sideTop2W.png",
        width: 1 / 4,
        height: 1 / 2,
        x: x + 1 / 2 + 0,
        y: y + 1 - 1 / 4,
      },
    window[2][0] === blockType &&
      window[4][0] !== blockType &&
      window[4][1] !== blockType &&
      window[4][2] !== blockType && {
        texture: "sideTop2E.png",
        width: 1 / 4,
        height: 1 / 2,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 - 1 / 4,
      },
    (window[2][0] === blockType || window[3][0] === blockType) && {
      texture: "topGap.png",
      width: 1 / 2,
      height: 1 / 2,
      x: x + 1 / 2 + 5 / 4,
      y: y + 1 - 1 / 4,
    },
    window[0][2] !== blockType &&
      window[0][1] !== blockType &&
      window[0][0] !== blockType &&
      window[1][0] !== blockType &&
      window[2][0] !== blockType && {
        texture: "outerCornerTopNW.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 0,
        y: y + 1 + 0,
      },
    window[2][0] !== blockType &&
      window[3][0] !== blockType &&
      window[4][0] !== blockType &&
      window[4][1] !== blockType &&
      window[4][2] !== blockType && {
        texture: "outerCornerTopNE.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 0,
      },
    window[4][2] !== blockType &&
      window[4][3] !== blockType &&
      window[4][4] !== blockType &&
      window[3][4] !== blockType &&
      window[2][4] !== blockType && {
        texture: "outerCornerTopSE.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 7 / 4,
      },
    window[2][4] !== blockType &&
      window[1][4] !== blockType &&
      window[0][4] !== blockType &&
      window[0][3] !== blockType &&
      window[0][2] !== blockType && {
        texture: "outerCornerTopSW.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 0,
        y: y + 1 + 7 / 4,
      },
    window[0][0] !== blockType &&
      window[0][1] !== blockType &&
      window[0][2] !== blockType &&
      window[0][3] !== blockType &&
      window[0][4] !== blockType &&
      window[1][0] !== blockType &&
      window[1][4] !== blockType && {
        texture: "sideTop6W.png",
        width: 1 / 4,
        height: 3 / 2,
        x: x + 1 / 2 + 0,
        y: y + 1 + 1 / 4,
      },
    window[4][0] !== blockType &&
      window[4][1] !== blockType &&
      window[4][2] !== blockType &&
      window[4][3] !== blockType &&
      window[4][4] !== blockType &&
      window[3][0] !== blockType &&
      window[3][4] !== blockType && {
        texture: "sideTop6E.png",
        width: 1 / 4,
        height: 3 / 2,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 1 / 4,
      },
    window[0][0] !== blockType &&
      window[1][0] !== blockType &&
      window[2][0] !== blockType &&
      window[3][0] !== blockType &&
      window[4][0] !== blockType &&
      window[0][1] !== blockType &&
      window[4][1] !== blockType && {
        texture: "sideTop6N.png",
        width: 3 / 2,
        height: 1 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 0,
      },
    window[0][4] !== blockType &&
      window[1][4] !== blockType &&
      window[2][4] !== blockType &&
      window[3][4] !== blockType &&
      window[4][4] !== blockType &&
      window[4][3] !== blockType &&
      window[0][3] !== blockType && {
        texture: "sideTop6S.png",
        width: 3 / 2,
        height: 1 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 7 / 4,
      },
    window[1][0] !== blockType &&
      window[2][0] !== blockType &&
      window[3][0] !== blockType &&
      (window[0][0] === blockType ||
        window[0][1] === blockType ||
        window[4][0] === blockType ||
        window[4][1] === blockType) && {
        texture: "sideTop4N.png",
        width: 4 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 2 / 4,
        y: y + 1 + 0,
      },
    window[0][1] !== blockType &&
      window[0][2] !== blockType &&
      window[0][3] !== blockType &&
      (window[0][0] === blockType ||
        window[1][0] === blockType ||
        window[0][4] === blockType ||
        window[1][4] === blockType) && {
        texture: "sideTop4W.png",
        width: 1 / 4,
        height: 4 / 4,
        x: x + 1 / 2 + 0,
        y: y + 1 + 2 / 4,
      },
    window[4][1] !== blockType &&
      window[4][2] !== blockType &&
      window[4][3] !== blockType &&
      (window[4][0] === blockType ||
        window[3][0] === blockType ||
        window[4][4] === blockType ||
        window[3][4] === blockType) && {
        texture: "sideTop4E.png",
        width: 1 / 4,
        height: 4 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 2 / 4,
      },
    window[1][4] !== blockType &&
      window[2][4] !== blockType &&
      window[3][4] !== blockType &&
      (window[0][4] === blockType ||
        window[0][3] === blockType ||
        window[4][4] === blockType ||
        window[4][3] === blockType) && {
        texture: "sideTop4S.png",
        width: 4 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 2 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[3][0] === blockType ||
      window[4][0] === blockType ||
      window[4][1] === blockType) &&
      window[0][1] !== blockType &&
      window[0][0] !== blockType &&
      window[1][0] !== blockType &&
      window[2][0] !== blockType && {
        texture: "sideTop1NW.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 0,
      },
    (window[1][0] === blockType ||
      window[0][0] === blockType ||
      window[0][1] === blockType) &&
      window[4][1] !== blockType &&
      window[4][0] !== blockType &&
      window[3][0] !== blockType &&
      window[2][0] !== blockType && {
        texture: "sideTop1NE.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + 0,
      },
    (window[3][4] === blockType ||
      window[4][4] === blockType ||
      window[4][3] === blockType) &&
      window[0][3] !== blockType &&
      window[0][4] !== blockType &&
      window[1][4] !== blockType &&
      window[2][4] !== blockType && {
        texture: "sideTop1SW.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 1 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[1][4] === blockType ||
      window[0][4] === blockType ||
      window[0][3] === blockType) &&
      window[4][3] !== blockType &&
      window[4][4] !== blockType &&
      window[3][4] !== blockType &&
      window[2][4] !== blockType && {
        texture: "sideTop1SE.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 6 / 4,
        y: y + 1 + 7 / 4,
      },
    (window[0][3] === blockType ||
      window[0][4] === blockType ||
      window[1][4] === blockType) &&
      window[1][0] !== blockType &&
      window[0][0] !== blockType &&
      window[0][1] !== blockType &&
      window[0][2] !== blockType && {
        texture: "sideTop1WN.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 0,
        y: y + 1 + 1 / 4,
      },
    (window[1][0] === blockType ||
      window[0][0] === blockType ||
      window[0][1] === blockType) &&
      window[0][2] !== blockType &&
      window[0][3] !== blockType &&
      window[0][4] !== blockType &&
      window[1][4] !== blockType && {
        texture: "sideTop1WS.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 0,
        y: y + 1 + 6 / 4,
      },
    (window[4][3] === blockType ||
      window[4][4] === blockType ||
      window[3][4] === blockType) &&
      window[3][0] !== blockType &&
      window[4][0] !== blockType &&
      window[4][1] !== blockType &&
      window[4][2] !== blockType && {
        texture: "sideTop1EN.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 1 / 4,
      },
    (window[4][1] === blockType ||
      window[4][0] === blockType ||
      window[3][0] === blockType) &&
      window[4][2] !== blockType &&
      window[4][3] !== blockType &&
      window[4][4] !== blockType &&
      window[3][4] !== blockType && {
        texture: "sideTop1ES.png",
        width: 1 / 4,
        height: 1 / 4,
        x: x + 1 / 2 + 7 / 4,
        y: y + 1 + 6 / 4,
      },
    window[0][1] === blockType &&
      window[2][0] !== blockType && {
        texture: "innerCornerTopNE.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 - 1 / 4,
        y: y + 1 - 1 / 2,
      },
    window[0][1] === blockType &&
      (window[0][3] !== blockType
        ? {
            texture: "innerCornerTopSW.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 - 2 / 4,
            y: y + 1 + 3 / 4,
          }
        : {
            texture: "topGap.png",
            width: 1 / 2,
            height: 1 / 2,
            x: x + 1 / 2 - 1 / 4,
            y: y + 1 + 3 / 4,
          }),
    window[1][0] === blockType &&
      (window[3][0] !== blockType
        ? {
            texture: "innerCornerTopNE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 3 / 4,
            y: y + 1 - 1 / 2,
          }
        : {
            texture: "topGap.png",
            width: 1 / 2,
            height: 1 / 2,
            x: x + 1 / 2 + 3 / 4,
            y: y + 1 - 1 / 4,
          }),
    window[1][0] === blockType &&
      window[0][2] !== blockType && {
        texture: "innerCornerTopSW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 - 2 / 4,
        y: y + 1 - 1 / 4,
      },
    window[0][3] === blockType &&
      window[0][1] !== blockType && {
        texture: "innerCornerTopNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 - 2 / 4,
        y: y + 1 + 2 / 4,
      },
    window[0][3] === blockType &&
      (window[2][4] !== blockType
        ? {
            texture: "innerCornerTopSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 - 1 / 4,
            y: y + 1 + 7 / 4,
          }
        : {
            texture: "topGap.png",
            width: 1 / 2,
            height: 1 / 2,
            x: x + 1 / 2 - 1 / 4,
            y: y + 1 + 7 / 4,
          }),
    window[1][4] === blockType &&
      window[0][2] !== blockType && {
        texture: "innerCornerTopNW.png",
        width: 3 / 4,
        height: 3 / 4,
        x: x + 1 / 2 - 2 / 4,
        y: y + 1 + 6 / 4,
      },
    window[1][4] === blockType &&
      (window[3][4] !== blockType
        ? {
            texture: "innerCornerTopSE.png",
            width: 3 / 4,
            height: 3 / 4,
            x: x + 1 / 2 + 3 / 4,
            y: y + 1 + 7 / 4,
          }
        : {
            texture: "topGap.png",
            width: 1 / 2,
            height: 1 / 2,
            x: x + 1 / 2 + 3 / 4,
            y: y + 1 + 7 / 4,
          }),
  ];
};
