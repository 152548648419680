import axios from "axios";
import {
  ActivationResponse,
  BountyParameters,
  BountyReward,
  BountyRound,
  BountyState,
} from "../shared/Bounty";
import { CosmeticRoundResult } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface BountyService {
  submit: (layout: Layout) => Promise<CosmeticRoundResult | ErrorMessage>;
  tentative: (layout: Layout) => Promise<void | ErrorMessage>;
  result: () => Promise<CosmeticRoundResult | ErrorMessage>;
  activate: () => Promise<ActivationResponse | ErrorMessage>;
  activationResponse: () => Promise<ActivationResponse | ErrorMessage>;
  host: (parameters: BountyParameters) => Promise<BountyRound | ErrorMessage>;
  round: () => Promise<BountyRound | ErrorMessage>;
  claim: () => Promise<BountyReward | ErrorMessage>;
  state: () => Promise<BountyState | ErrorMessage>;
}

export const getBountyService = (): BountyService => ({
  submit: (layout: Layout) =>
    axios
      .post<CosmeticRoundResult>(
        cst.backendUrl + "/bounty/submit",
        layout,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  tentative: (layout: Layout) =>
    axios
      .post<void>(
        cst.backendUrl + "/bounty/tentative",
        layout,
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  result: () =>
    axios
      .get<CosmeticRoundResult>(cst.backendUrl + "/bounty/result", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  host: (parameters: BountyParameters) =>
    axios
      .post<BountyRound>(cst.backendUrl + "/bounty/host", parameters, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  activate: () =>
    axios
      .get<ActivationResponse>(cst.backendUrl + "/bounty/activate", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  activationResponse: () =>
    axios
      .get<ActivationResponse>(cst.backendUrl + "/bounty/activationResponse", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  round: () =>
    axios
      .get<BountyRound>(cst.backendUrl + "/bounty/round", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  claim: () =>
    axios
      .get<BountyReward>(cst.backendUrl + "/bounty/claim", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  state: () =>
    axios
      .get<BountyState>(cst.backendUrl + "/bounty/state", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
