import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WebsocketEvents } from "websocket-ts/lib";
import { NotificationWebSocketContext } from "../contexts/NotificationContext";
import { getNotificationService } from "../network/NotificationService";
import {
  isNotifications,
  Notification,
  toNotification,
} from "../shared/Notification";
import { isPlayer, Player } from "../shared/Player";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { lt } from "./lt";
import { Points } from "../pages/elements/Emoji";

interface UseNotificationsProps {
  player: Player | undefined | null | "unregistered";
  closeMenu: () => void;
}

export const useNotifications = (props: UseNotificationsProps) => {
  const { player } = props;
  const websocket = useContext(NotificationWebSocketContext);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const dismissNotification = useCallback(
    (id: number) => {
      setNotifications((ns) => ns.filter((it) => it.id !== id));
      getNotificationService().dismiss(id);
    },
    [setNotifications]
  );

  const dismissAllNotifications = useCallback(() => {
    setNotifications([]);
    getNotificationService().dismissAll();
  }, [setNotifications]);
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    if (token === undefined && player && isPlayer(player) && !player.guest) {
      getNotificationService()
        .token()
        .then((response) => {
          setToken(response);
        });
      getNotificationService()
        .unread()
        .then((response) => {
          if (isNotifications(response)) {
            setNotifications(response);
          }
        });
    }
  }, [setToken, token, player, setNotifications]);

  useEffect(() => {
    if (token) {
      websocket.send(token);
      websocket.addEventListener(WebsocketEvents.open, () => {
        websocket.send(token);
      });
      websocket.addEventListener(WebsocketEvents.message, (instance, event) => {
        let message = event.data as string;
        if (message === "INVALID_TOKEN") {
          setToken(undefined);
        } else {
          let split = message.split("--");
          if (split[0] === "DISMISS") {
            let dismiss = split.slice(1);
            setNotifications((ns) =>
              ns.filter((it) => !dismiss.some((d) => +d === it.id))
            );
          } else {
            setNotifications((ns) => [toNotification(message), ...ns]);
          }
        }
      });
    }
  }, [token, websocket]);

  const navigate = useNavigate();

  const notificationElement = useMemo(
    () => [
      <Divider key={"div1"} />,
      <List
        key={"notifications"}
        subheader={
          <ListItem
            secondaryAction={
              <Tooltip title={"Dismiss all notifications"}>
                <IconButton onClick={dismissAllNotifications}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            }
          >
            Notifications
          </ListItem>
        }
      >
        {notifications.map(({ id, message, destination }, index) => (
          <ListItem
            dense
            key={index}
            sx={{ bgcolor: index % 2 === 1 ? undefined : "#484848", p: 0 }}
            secondaryAction={
              <IconButton
                id="close"
                onClick={() => {
                  dismissNotification(id);
                }}
              >
                <CloseIcon id="close" />
              </IconButton>
            }
          >
            <ListItemButton
              onClick={() => {
                dismissNotification(id);
                navigate(destination);
              }}
              sx={{ px: 2 }}
            >
              <ListItemText sx={{ mr: 1, maxWidth: 250 }}>
                {lt(message.split("POINTS"), (parts) =>
                  parts.length > 1 ? (
                    <Fragment>
                      {parts[0]}
                      <Points />
                      {parts[1]}
                    </Fragment>
                  ) : (
                    message
                  )
                )}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>,
    ],
    [notifications, dismissAllNotifications, dismissNotification, navigate]
  );

  return {
    notificationElement,
    notificationCount: notifications.length,
  };
};
