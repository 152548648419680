import { lt } from "../utils/lt";

export type Notification = {
  id: number;
  message: string;
  destination: string;
};

export function isNotification(value: any): value is Notification {
  if ((value as Notification).message) {
    return true;
  }
  return false;
}

export const toNotification: (message: string) => Notification = (
  message: string
) =>
  lt(message.split("--"), ([id, message, destination]) => ({
    id: +id,
    message,
    destination,
  }));

export function isNotifications(values: any): values is Notification[] {
  return (
    Array.isArray(values) &&
    (values as any[]).every((value) => isNotification(value))
  );
}
