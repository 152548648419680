import axios from "axios";
import { UniqueBlockPattern } from "../shared/Cosmetics";
import { ToolResult, ToolBelt } from "../shared/Crafting";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface CraftingService {
  toolBelt: () => Promise<ToolBelt | ErrorMessage>;
  applyTool: (
    toolId: number,
    blockPatternIds: number[]
  ) => Promise<ToolResult | ErrorMessage>;
  craftPattern: (
    firstColorId: number,
    secondColorId: number,
    patternId: number
  ) => Promise<UniqueBlockPattern | ErrorMessage>;
}

export const craftingService = {
  toolBelt: () =>
    axios
      .get<ToolBelt>(cst.backendUrl + "/crafting/toolbelt", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  applyTool: (toolId: number, blockPatternIds: number[]) =>
    axios
      .post<ToolResult>(
        cst.backendUrl + "/crafting/apply",
        {
          toolId,
          blockPatternIds,
        },
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  craftPattern: (
    firstColorId: number,
    secondColorId: number,
    patternId: number
  ) =>
    axios
      .post<ToolResult>(
        cst.backendUrl + "/crafting/pattern",
        {
          firstColorId,
          secondColorId,
          patternId,
        },
        creds
      )
      .then((response) => response.data)
      .catch((reason) => "" + reason),
};
