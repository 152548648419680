import axios from "axios";
import { Layout } from "../shared/Layout";
import { Path } from "../shared/Path";
import { Round } from "../shared/Round";
import { Save } from "../shared/Save";
import { cst } from "../utils/constants";
import { ErrorMessage } from "./ErrorMessage";
import { creds } from "./utils";

export interface SaveService {
  saveArenaRound: (savedAt: number) => Promise<number | ErrorMessage>;
  saveChallengeRound: (savedAt: number) => Promise<number | ErrorMessage>;
  getSaves: () => Promise<Save[] | ErrorMessage>;
  getSave: (seed: number) => Promise<Round | ErrorMessage>;
  submitSavePlay: (seed: number, layout: Layout) => Promise<Path | ErrorMessage>;
}

export const getSaveService = (): SaveService => ({
  saveArenaRound: (savedAt: number) =>
    axios
      .get<number>(cst.backendUrl + "/save/arena/" + savedAt, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  saveChallengeRound: (savedAt: number) =>
    axios
      .get<number>(cst.backendUrl + "/save/challenge/" + savedAt, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSaves: () =>
    axios
      .get<Save[]>(cst.backendUrl + "/save/all", creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  getSave: (seed: number) =>
    axios
      .get<Round>(cst.backendUrl + "/save/" + seed, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
  submitSavePlay: (seed: number, layout: Layout) =>
    axios
      .post<Path>(cst.backendUrl + "/save/" + seed, layout, creds)
      .then((response) => response.data)
      .catch((reason) => "" + reason),
});
