import { Texture } from "pixi.js";

export type TextureName =
  | "ball0001.png"
  | "ball0002.png"
  | "ball0003.png"
  | "ball0004.png"
  | "ball0005.png"
  | "ball0006.png"
  | "ball0007.png"
  | "ball0008.png"
  | "ball0009.png"
  | "ball0010.png"
  | "ball0011.png"
  | "ball0012.png"
  | "ball0013.png"
  | "ball0014.png"
  | "ball0015.png"
  | "ball0016.png"
  | "ball0017.png"
  | "ball0018.png"
  | "ball0019.png"
  | "ball0020.png"
  | "ball0021.png"
  | "ball0022.png"
  | "ball0023.png"
  | "ball0024.png"
  | "ball0025.png"
  | "ball0026.png"
  | "ball0027.png"
  | "ball0028.png"
  | "ball0029.png"
  | "ball0030.png"
  | "ball0031.png"
  | "ball0032.png"
  | "ball0033.png"
  | "ball0034.png"
  | "ball0035.png"
  | "ball0036.png"
  | "ball0037.png"
  | "ball0038.png"
  | "ball0039.png"
  | "ball0040.png"
  | "ball0041.png"
  | "ball0042.png"
  | "ball0043.png"
  | "ball0044.png"
  | "ball0045.png"
  | "ball0046.png"
  | "ball0047.png"
  | "ball0048.png"
  | "ball0049.png"
  | "ball0050.png"
  | "ball0051.png"
  | "ball0052.png"
  | "ball0053.png"
  | "ball0054.png"
  | "ball0055.png"
  | "ball0056.png"
  | "ball0057.png"
  | "ball0058.png"
  | "ball0059.png"
  | "ball0060.png"
  | "ball0061.png"
  | "ball0062.png"
  | "ball0063.png"
  | "ball0064.png"
  | "ball0065.png"
  | "ball0066.png"
  | "ball0067.png"
  | "ball0068.png"
  | "ball0069.png"
  | "ball0070.png"
  | "ball0071.png"
  | "ball0072.png"
  | "ball0073.png"
  | "ball0074.png"
  | "ball0075.png"
  | "ball0076.png"
  | "ball0077.png"
  | "ball0078.png"
  | "ball0079.png"
  | "ball0080.png"
  | "ball0081.png"
  | "ball0082.png"
  | "ball0083.png"
  | "ball0084.png"
  | "ball0085.png"
  | "ball0086.png"
  | "ball0087.png"
  | "ball0088.png"
  | "ball0089.png"
  | "ball0090.png"
  | "ballFrozen0001.png"
  | "ballFrozen0002.png"
  | "ballFrozen0003.png"
  | "ballFrozen0004.png"
  | "ballFrozen0005.png"
  | "ballFrozen0006.png"
  | "ballFrozen0007.png"
  | "ballFrozen0008.png"
  | "ballFrozen0009.png"
  | "ballFrozen0010.png"
  | "ballFrozen0011.png"
  | "ballFrozen0012.png"
  | "ballFrozen0013.png"
  | "ballFrozen0014.png"
  | "ballFrozen0015.png"
  | "ballFrozen0016.png"
  | "ballFrozen0017.png"
  | "ballFrozen0018.png"
  | "ballFrozen0019.png"
  | "ballFrozen0020.png"
  | "ballFrozen0021.png"
  | "ballFrozen0022.png"
  | "ballFrozen0023.png"
  | "ballFrozen0024.png"
  | "ballFrozen0025.png"
  | "ballFrozen0026.png"
  | "ballFrozen0027.png"
  | "ballFrozen0028.png"
  | "ballFrozen0029.png"
  | "ballFrozen0030.png"
  | "ballFrozen0031.png"
  | "ballFrozen0032.png"
  | "ballFrozen0033.png"
  | "ballFrozen0034.png"
  | "ballFrozen0035.png"
  | "ballFrozen0036.png"
  | "ballFrozen0037.png"
  | "ballFrozen0038.png"
  | "ballFrozen0039.png"
  | "ballFrozen0040.png"
  | "ballFrozen0041.png"
  | "ballFrozen0042.png"
  | "ballFrozen0043.png"
  | "ballFrozen0044.png"
  | "ballFrozen0045.png"
  | "ballFrozen0046.png"
  | "ballFrozen0047.png"
  | "ballFrozen0048.png"
  | "ballFrozen0049.png"
  | "ballFrozen0050.png"
  | "ballFrozen0051.png"
  | "ballFrozen0052.png"
  | "ballFrozen0053.png"
  | "ballFrozen0054.png"
  | "ballFrozen0055.png"
  | "ballFrozen0056.png"
  | "ballFrozen0057.png"
  | "ballFrozen0058.png"
  | "ballFrozen0059.png"
  | "ballFrozen0060.png"
  | "ballFrozen0061.png"
  | "ballFrozen0062.png"
  | "ballFrozen0063.png"
  | "ballFrozen0064.png"
  | "ballFrozen0065.png"
  | "ballFrozen0066.png"
  | "ballFrozen0067.png"
  | "ballFrozen0068.png"
  | "ballFrozen0069.png"
  | "ballFrozen0070.png"
  | "ballFrozen0071.png"
  | "ballFrozen0072.png"
  | "ballFrozen0073.png"
  | "ballFrozen0074.png"
  | "ballFrozen0075.png"
  | "ballFrozen0076.png"
  | "ballFrozen0077.png"
  | "ballFrozen0078.png"
  | "ballFrozen0079.png"
  | "ballFrozen0080.png"
  | "ballFrozen0081.png"
  | "ballFrozen0082.png"
  | "ballFrozen0083.png"
  | "ballFrozen0084.png"
  | "ballFrozen0085.png"
  | "ballFrozen0086.png"
  | "ballFrozen0087.png"
  | "ballFrozen0088.png"
  | "ballFrozen0089.png"
  | "ballFrozen0090.png"
  | "ballShade.png"
  | "ballShadow.png"
  | "black.png"
  | "checker.png"
  | "clap.png"
  | "clashConnectorBottomA.png"
  | "clashConnectorBottomB.png"
  | "clashJuxtaposedBottomE.png"
  | "clashJuxtaposedBottomEN.png"
  | "clashJuxtaposedBottomES.png"
  | "clashJuxtaposedBottomN.png"
  | "clashJuxtaposedBottomNE.png"
  | "clashJuxtaposedBottomNW.png"
  | "clashJuxtaposedBottomS.png"
  | "clashJuxtaposedBottomSE.png"
  | "clashJuxtaposedBottomSW.png"
  | "clashJuxtaposedBottomW.png"
  | "clashJuxtaposedBottomWN.png"
  | "clashJuxtaposedBottomWS.png"
  | "clashPerpendicularBottomEN.png"
  | "clashPerpendicularBottomES.png"
  | "clashPerpendicularBottomNE.png"
  | "clashPerpendicularBottomNW.png"
  | "clashPerpendicularBottomSE.png"
  | "clashPerpendicularBottomSW.png"
  | "clashPerpendicularBottomWN.png"
  | "clashPerpendicularBottomWS.png"
  | "connectorBottomA.png"
  | "connectorBottomB.png"
  | "connectorTopA.png"
  | "connectorTopB.png"
  | "cornerExitNE.png"
  | "cornerExitNW.png"
  | "cornerExitSE.png"
  | "cornerExitSW.png"
  | "cornerExitZoneNE.png"
  | "cornerExitZoneNW.png"
  | "cornerExitZoneSE.png"
  | "cornerExitZoneSW.png"
  | "countdownDivider.png"
  | "countdownSectionGreen.png"
  | "countdownSectionGrey.png"
  | "countdownSectionRed.png"
  | "countdownSectionStartGreen.png"
  | "countdownSectionStartGrey.png"
  | "countdownSectionStopGreen.png"
  | "countdownSectionStopGrey.png"
  | "countdownSectionStopRed.png"
  | "countdownSectionStopYellow.png"
  | "countdownSectionYellow.png"
  | "cursorBuild.png"
  | "cursorBuildClap.png"
  | "cursorBuildError.png"
  | "cursorDowngrade.png"
  | "cursorRemove.png"
  | "cursorUpgrade.png"
  | "empty.png"
  | "exitE.png"
  | "exitN.png"
  | "exitS.png"
  | "exitSideTopN.png"
  | "exitSideTopS.png"
  | "exitTopNE.png"
  | "exitTopNW.png"
  | "exitTopSE.png"
  | "exitTopSW.png"
  | "exitW.png"
  | "exitZoneE.png"
  | "exitZoneN.png"
  | "exitZoneS.png"
  | "exitZoneW.png"
  | "frostCooldownIndicator0001.png"
  | "frostCooldownIndicator0002.png"
  | "frostCooldownIndicator0003.png"
  | "frostCooldownIndicator0004.png"
  | "frostCooldownIndicator0005.png"
  | "frostCooldownIndicator0006.png"
  | "frostCooldownIndicator0007.png"
  | "frostCooldownIndicator0008.png"
  | "frostCooldownIndicator0009.png"
  | "frostCooldownIndicator0010.png"
  | "frostCooldownIndicator0011.png"
  | "frostCooldownIndicator0012.png"
  | "frostCooldownIndicator0013.png"
  | "frostCooldownIndicator0014.png"
  | "frostCooldownIndicator0015.png"
  | "frostCooldownIndicator0016.png"
  | "frostCooldownIndicator0017.png"
  | "frostCooldownIndicator0018.png"
  | "frostCooldownIndicator0019.png"
  | "frostCooldownIndicator0020.png"
  | "frostCooldownIndicator0021.png"
  | "frostCooldownIndicator0022.png"
  | "frostCooldownIndicator0023.png"
  | "frostCooldownIndicator0024.png"
  | "frostCooldownIndicator0025.png"
  | "frostCooldownIndicator0026.png"
  | "frostCooldownIndicator0027.png"
  | "frostCooldownIndicator0028.png"
  | "frostCooldownIndicator0029.png"
  | "frostCooldownIndicator0030.png"
  | "frostCooldownIndicator0031.png"
  | "frostCooldownIndicator0032.png"
  | "frostCooldownIndicator0033.png"
  | "frostCooldownIndicator0034.png"
  | "frostCooldownIndicator0035.png"
  | "frostCooldownIndicator0036.png"
  | "frostCooldownIndicator0037.png"
  | "frostCooldownIndicator0038.png"
  | "frostCooldownIndicator0039.png"
  | "frostCooldownIndicator0040.png"
  | "frostCooldownIndicator0041.png"
  | "frostCooldownIndicator0042.png"
  | "frostCooldownIndicator0043.png"
  | "frostCooldownIndicator0044.png"
  | "frostCooldownIndicator0045.png"
  | "frostCooldownIndicator0046.png"
  | "frostCooldownIndicator0047.png"
  | "frostCooldownIndicator0048.png"
  | "frostCooldownIndicator0049.png"
  | "frostCooldownIndicator0050.png"
  | "frostCooldownIndicator0051.png"
  | "frostCooldownIndicator0052.png"
  | "frostCooldownIndicator0053.png"
  | "frostCooldownIndicator0054.png"
  | "frostCooldownIndicator0055.png"
  | "frostCooldownIndicator0056.png"
  | "frostCooldownIndicator0057.png"
  | "frostCooldownIndicator0058.png"
  | "frostCooldownIndicator0059.png"
  | "frostCooldownIndicator0060.png"
  | "frostCooldownIndicator0061.png"
  | "frostCooldownIndicator0062.png"
  | "frostCooldownIndicator0063.png"
  | "frostCooldownIndicator0064.png"
  | "frostCooldownIndicator0065.png"
  | "frostCooldownIndicator0066.png"
  | "frostCooldownIndicator0067.png"
  | "frostCooldownIndicator0068.png"
  | "frostCooldownIndicator0069.png"
  | "frostCooldownIndicator0070.png"
  | "frostCooldownIndicator0071.png"
  | "frostCooldownIndicator0072.png"
  | "frostCooldownIndicator0073.png"
  | "frostCooldownIndicator0074.png"
  | "frostCooldownIndicator0075.png"
  | "frostCooldownIndicator0076.png"
  | "frostCooldownIndicator0077.png"
  | "frostCooldownIndicator0078.png"
  | "frostCooldownIndicator0079.png"
  | "frostCooldownIndicator0080.png"
  | "frostCooldownIndicator0081.png"
  | "frostCooldownIndicator0082.png"
  | "frostCooldownIndicator0083.png"
  | "frostCooldownIndicator0084.png"
  | "frostCooldownIndicator0085.png"
  | "frostCooldownIndicator0086.png"
  | "frostCooldownIndicator0087.png"
  | "frostCooldownIndicator0088.png"
  | "frostCooldownIndicator0089.png"
  | "frostCooldownIndicator0090.png"
  | "frostCooldownIndicator0091.png"
  | "frostCooldownIndicator0092.png"
  | "frostCooldownIndicator0093.png"
  | "frostCooldownIndicator0094.png"
  | "frostCooldownIndicator0095.png"
  | "frostCooldownIndicator0096.png"
  | "frostCooldownIndicator0097.png"
  | "frostCooldownIndicator0098.png"
  | "frostCooldownIndicator0099.png"
  | "frostCooldownIndicator0100.png"
  | "frostCooldownIndicator0101.png"
  | "frostCooldownIndicator0102.png"
  | "frostCooldownIndicator0103.png"
  | "frostCooldownIndicator0104.png"
  | "frostCooldownIndicator0105.png"
  | "frostCooldownIndicator0106.png"
  | "frostCooldownIndicator0107.png"
  | "frostCooldownIndicator0108.png"
  | "frostCooldownIndicator0109.png"
  | "frostCooldownIndicator0110.png"
  | "frostCooldownIndicator0111.png"
  | "frostCooldownIndicator0112.png"
  | "frostCooldownIndicator0113.png"
  | "frostCooldownIndicator0114.png"
  | "frostCooldownIndicator0115.png"
  | "frostCooldownIndicator0116.png"
  | "frostCooldownIndicator0117.png"
  | "frostCooldownIndicator0118.png"
  | "frostCooldownIndicator0119.png"
  | "frostCooldownIndicator0120.png"
  | "frostCooldownIndicator0121.png"
  | "frostCooldownIndicator0122.png"
  | "frostCooldownIndicator0123.png"
  | "frostCooldownIndicator0124.png"
  | "frostCooldownIndicator0125.png"
  | "frostCooldownIndicator0126.png"
  | "frostCooldownIndicator0127.png"
  | "frostCooldownIndicator0128.png"
  | "frostCooldownIndicator0129.png"
  | "frostCooldownIndicator0130.png"
  | "frostCooldownIndicator0131.png"
  | "frostCooldownIndicator0132.png"
  | "frostCooldownIndicator0133.png"
  | "frostCooldownIndicator0134.png"
  | "frostCooldownIndicator0135.png"
  | "frostCooldownIndicator0136.png"
  | "frostCooldownIndicator0137.png"
  | "frostCooldownIndicator0138.png"
  | "frostCooldownIndicator0139.png"
  | "frostCooldownIndicator0140.png"
  | "frostCooldownIndicator0141.png"
  | "frostCooldownIndicator0142.png"
  | "frostCooldownIndicator0143.png"
  | "frostCooldownIndicator0144.png"
  | "frostCooldownIndicator0145.png"
  | "frostCooldownIndicator0146.png"
  | "frostCooldownIndicator0147.png"
  | "frostCooldownIndicator0148.png"
  | "frostCooldownIndicator0149.png"
  | "frostCooldownIndicator0150.png"
  | "frostCooldownIndicator0151.png"
  | "frostCooldownIndicator0152.png"
  | "frostCooldownIndicator0153.png"
  | "frostCooldownIndicator0154.png"
  | "frostCooldownIndicator0155.png"
  | "frostCooldownIndicator0156.png"
  | "frostCooldownIndicator0157.png"
  | "frostCooldownIndicator0158.png"
  | "frostCooldownIndicator0159.png"
  | "frostCooldownIndicator0160.png"
  | "frostCooldownIndicator0161.png"
  | "frostCooldownIndicator0162.png"
  | "frostCooldownIndicator0163.png"
  | "frostCooldownIndicator0164.png"
  | "frostCooldownIndicator0165.png"
  | "frostCooldownIndicator0166.png"
  | "frostCooldownIndicator0167.png"
  | "frostCooldownIndicator0168.png"
  | "frostCooldownIndicator0169.png"
  | "frostCooldownIndicator0170.png"
  | "frostCooldownIndicator0171.png"
  | "frostCooldownIndicator0172.png"
  | "frostCooldownIndicator0173.png"
  | "frostCooldownIndicator0174.png"
  | "frostCooldownIndicator0175.png"
  | "frostCooldownIndicator0176.png"
  | "frostCooldownIndicator0177.png"
  | "frostCooldownIndicator0178.png"
  | "frostCooldownIndicator0179.png"
  | "frostCooldownIndicator0180.png"
  | "frostCooldownIndicator0181.png"
  | "frostCooldownIndicator0182.png"
  | "frostCooldownIndicator0183.png"
  | "frostCooldownIndicator0184.png"
  | "frostCooldownIndicator0185.png"
  | "frostCooldownIndicator0186.png"
  | "frostCooldownIndicator0187.png"
  | "frostCooldownIndicator0188.png"
  | "frostCooldownIndicator0189.png"
  | "frostCooldownIndicator0190.png"
  | "frostCooldownIndicator0191.png"
  | "frostCooldownIndicator0192.png"
  | "frostCooldownIndicator0193.png"
  | "frostCooldownIndicator0194.png"
  | "frostCooldownIndicator0195.png"
  | "frostCooldownIndicator0196.png"
  | "frostCooldownIndicator0197.png"
  | "frostCooldownIndicator0198.png"
  | "frostCooldownIndicator0199.png"
  | "frostCooldownIndicator0200.png"
  | "frostCooldownIndicator0201.png"
  | "frostCooldownIndicator0202.png"
  | "frostCooldownIndicator0203.png"
  | "frostCooldownIndicator0204.png"
  | "frostCooldownIndicator0205.png"
  | "frostCooldownIndicator0206.png"
  | "frostCooldownIndicator0207.png"
  | "frostCooldownIndicator0208.png"
  | "frostCooldownIndicator0209.png"
  | "frostCooldownIndicator0210.png"
  | "frostCooldownIndicator0211.png"
  | "frostCooldownIndicator0212.png"
  | "frostCooldownIndicator0213.png"
  | "frostCooldownIndicator0214.png"
  | "frostCooldownIndicator0215.png"
  | "frostCooldownIndicator0216.png"
  | "frostCooldownIndicator0217.png"
  | "frostCooldownIndicator0218.png"
  | "frostCooldownIndicator0219.png"
  | "frostCooldownIndicator0220.png"
  | "frostCooldownIndicator0221.png"
  | "frostCooldownIndicator0222.png"
  | "frostCooldownIndicator0223.png"
  | "frostCooldownIndicator0224.png"
  | "frostCooldownIndicator0225.png"
  | "frostCooldownIndicator0226.png"
  | "frostCooldownIndicator0227.png"
  | "frostCooldownIndicator0228.png"
  | "frostCooldownIndicator0229.png"
  | "frostCooldownIndicator0230.png"
  | "frostCooldownIndicator0231.png"
  | "frostCooldownIndicator0232.png"
  | "frostCooldownIndicator0233.png"
  | "frostCooldownIndicator0234.png"
  | "frostCooldownIndicator0235.png"
  | "frostCooldownIndicator0236.png"
  | "frostCooldownIndicator0237.png"
  | "frostCooldownIndicator0238.png"
  | "frostCooldownIndicator0239.png"
  | "frostCooldownIndicator0240.png"
  | "frostCooldownIndicator0241.png"
  | "frostCooldownIndicator0242.png"
  | "frostCooldownIndicator0243.png"
  | "frostCooldownIndicator0244.png"
  | "frostCooldownIndicator0245.png"
  | "frostCooldownIndicator0246.png"
  | "frostCooldownIndicator0247.png"
  | "frostCooldownIndicator0248.png"
  | "frostCooldownIndicator0249.png"
  | "frostCooldownIndicator0250.png"
  | "frostCooldownIndicator0251.png"
  | "frostCooldownIndicator0252.png"
  | "frostCooldownIndicator0253.png"
  | "frostCooldownIndicator0254.png"
  | "frostCooldownIndicator0255.png"
  | "frostCooldownIndicator0256.png"
  | "frostCooldownIndicator0257.png"
  | "frostCooldownIndicator0258.png"
  | "frostCooldownIndicator0259.png"
  | "frostCooldownIndicator0260.png"
  | "frostCooldownIndicator0261.png"
  | "frostCooldownIndicator0262.png"
  | "frostCooldownIndicator0263.png"
  | "frostCooldownIndicator0264.png"
  | "frostCooldownIndicator0265.png"
  | "frostCooldownIndicator0266.png"
  | "frostCooldownIndicator0267.png"
  | "frostCooldownIndicator0268.png"
  | "frostCooldownIndicator0269.png"
  | "frostCooldownIndicator0270.png"
  | "frostCooldownIndicator0271.png"
  | "frostCooldownIndicator0272.png"
  | "frostCooldownIndicator0273.png"
  | "frostCooldownIndicator0274.png"
  | "frostCooldownIndicator0275.png"
  | "frostCooldownIndicator0276.png"
  | "frostCooldownIndicator0277.png"
  | "frostCooldownIndicator0278.png"
  | "frostCooldownIndicator0279.png"
  | "frostCooldownIndicator0280.png"
  | "frostCooldownIndicator0281.png"
  | "frostCooldownIndicator0282.png"
  | "frostCooldownIndicator0283.png"
  | "frostCooldownIndicator0284.png"
  | "frostCooldownIndicator0285.png"
  | "frostCooldownIndicator0286.png"
  | "frostCooldownIndicator0287.png"
  | "frostCooldownIndicator0288.png"
  | "frostCooldownIndicator0289.png"
  | "frostCooldownIndicator0290.png"
  | "frostCooldownIndicator0291.png"
  | "frostCooldownIndicator0292.png"
  | "frostCooldownIndicator0293.png"
  | "frostCooldownIndicator0294.png"
  | "frostCooldownIndicator0295.png"
  | "frostCooldownIndicator0296.png"
  | "frostCooldownIndicator0297.png"
  | "frostCooldownIndicator0298.png"
  | "frostCooldownIndicator0299.png"
  | "frostCooldownIndicator0300.png"
  | "frostEffect0001.png"
  | "frostEffect0002.png"
  | "frostEffect0003.png"
  | "frostEffect0004.png"
  | "frostEffect0005.png"
  | "frostEffect0006.png"
  | "frostEffect0007.png"
  | "frostEffect0008.png"
  | "frostEffect0009.png"
  | "frostEffect0010.png"
  | "frostEffect0011.png"
  | "frostEffect0012.png"
  | "frostEffect0013.png"
  | "frostEffect0014.png"
  | "frostEffect0015.png"
  | "frostEffect0016.png"
  | "frostEffect0017.png"
  | "frostEffect0018.png"
  | "frostEffect0019.png"
  | "frostEffect0020.png"
  | "frostEffect0021.png"
  | "frostEffect0022.png"
  | "frostEffect0023.png"
  | "frostEffect0024.png"
  | "frostEffect0025.png"
  | "frostInlay.png"
  | "innerCornerBottomNE.png"
  | "innerCornerBottomNW.png"
  | "innerCornerBottomSE.png"
  | "innerCornerBottomSW.png"
  | "innerCornerExitEN.png"
  | "innerCornerExitES.png"
  | "innerCornerExitNE.png"
  | "innerCornerExitNW.png"
  | "innerCornerExitSE.png"
  | "innerCornerExitSW.png"
  | "innerCornerExitWN.png"
  | "innerCornerExitWS.png"
  | "innerCornerExitZoneEN.png"
  | "innerCornerExitZoneES.png"
  | "innerCornerExitZoneNE.png"
  | "innerCornerExitZoneNW.png"
  | "innerCornerExitZoneSE.png"
  | "innerCornerExitZoneSW.png"
  | "innerCornerExitZoneWN.png"
  | "innerCornerExitZoneWS.png"
  | "innerCornerTopNE.png"
  | "innerCornerTopNW.png"
  | "innerCornerTopSE.png"
  | "innerCornerTopSW.png"
  | "outerCornerBottomNE.png"
  | "outerCornerBottomNW.png"
  | "outerCornerBottomSE.png"
  | "outerCornerBottomSW.png"
  | "outerCornerTopNE.png"
  | "outerCornerTopNW.png"
  | "outerCornerTopSE.png"
  | "outerCornerTopSW.png"
  | "resourceBlock.png"
  | "resourceFrost.png"
  | "runner.png"
  | "sideBottom1EN.png"
  | "sideBottom1ES.png"
  | "sideBottom1NE.png"
  | "sideBottom1NW.png"
  | "sideBottom1SE.png"
  | "sideBottom1SW.png"
  | "sideBottom1WN.png"
  | "sideBottom1WS.png"
  | "sideBottom2E.png"
  | "sideBottom2N.png"
  | "sideBottom2S.png"
  | "sideBottom2W.png"
  | "sideBottom4E.png"
  | "sideBottom4N.png"
  | "sideBottom4S.png"
  | "sideBottom4W.png"
  | "sideBottom6E.png"
  | "sideBottom6N.png"
  | "sideBottom6S.png"
  | "sideBottom6W.png"
  | "sideTop1EN.png"
  | "sideTop1ES.png"
  | "sideTop1NE.png"
  | "sideTop1NW.png"
  | "sideTop1SE.png"
  | "sideTop1SW.png"
  | "sideTop1WN.png"
  | "sideTop1WS.png"
  | "sideTop2E.png"
  | "sideTop2N.png"
  | "sideTop2S.png"
  | "sideTop2W.png"
  | "sideTop4E.png"
  | "sideTop4N.png"
  | "sideTop4S.png"
  | "sideTop4W.png"
  | "sideTop6E.png"
  | "sideTop6N.png"
  | "sideTop6S.png"
  | "sideTop6W.png"
  | "staticClapTower.png"
  | "staticTower.png"
  | "tile.png"
  | "topGap.png"
  | "topPlate.png"
  | "variableClapTower.png"
  | "variableTower.png"
  | "waypoint.png"
  | "white.png";

export function isTextureKey(value: any): value is TextureName {
  if ((value as TextureName).length) {
    return true;
  }
  return false;
}

export type Textures = { [key in TextureName]: Texture | undefined };

export const undefinedTextures: Textures = {
  "ball0001.png": undefined,
  "ball0002.png": undefined,
  "ball0003.png": undefined,
  "ball0004.png": undefined,
  "ball0005.png": undefined,
  "ball0006.png": undefined,
  "ball0007.png": undefined,
  "ball0008.png": undefined,
  "ball0009.png": undefined,
  "ball0010.png": undefined,
  "ball0011.png": undefined,
  "ball0012.png": undefined,
  "ball0013.png": undefined,
  "ball0014.png": undefined,
  "ball0015.png": undefined,
  "ball0016.png": undefined,
  "ball0017.png": undefined,
  "ball0018.png": undefined,
  "ball0019.png": undefined,
  "ball0020.png": undefined,
  "ball0021.png": undefined,
  "ball0022.png": undefined,
  "ball0023.png": undefined,
  "ball0024.png": undefined,
  "ball0025.png": undefined,
  "ball0026.png": undefined,
  "ball0027.png": undefined,
  "ball0028.png": undefined,
  "ball0029.png": undefined,
  "ball0030.png": undefined,
  "ball0031.png": undefined,
  "ball0032.png": undefined,
  "ball0033.png": undefined,
  "ball0034.png": undefined,
  "ball0035.png": undefined,
  "ball0036.png": undefined,
  "ball0037.png": undefined,
  "ball0038.png": undefined,
  "ball0039.png": undefined,
  "ball0040.png": undefined,
  "ball0041.png": undefined,
  "ball0042.png": undefined,
  "ball0043.png": undefined,
  "ball0044.png": undefined,
  "ball0045.png": undefined,
  "ball0046.png": undefined,
  "ball0047.png": undefined,
  "ball0048.png": undefined,
  "ball0049.png": undefined,
  "ball0050.png": undefined,
  "ball0051.png": undefined,
  "ball0052.png": undefined,
  "ball0053.png": undefined,
  "ball0054.png": undefined,
  "ball0055.png": undefined,
  "ball0056.png": undefined,
  "ball0057.png": undefined,
  "ball0058.png": undefined,
  "ball0059.png": undefined,
  "ball0060.png": undefined,
  "ball0061.png": undefined,
  "ball0062.png": undefined,
  "ball0063.png": undefined,
  "ball0064.png": undefined,
  "ball0065.png": undefined,
  "ball0066.png": undefined,
  "ball0067.png": undefined,
  "ball0068.png": undefined,
  "ball0069.png": undefined,
  "ball0070.png": undefined,
  "ball0071.png": undefined,
  "ball0072.png": undefined,
  "ball0073.png": undefined,
  "ball0074.png": undefined,
  "ball0075.png": undefined,
  "ball0076.png": undefined,
  "ball0077.png": undefined,
  "ball0078.png": undefined,
  "ball0079.png": undefined,
  "ball0080.png": undefined,
  "ball0081.png": undefined,
  "ball0082.png": undefined,
  "ball0083.png": undefined,
  "ball0084.png": undefined,
  "ball0085.png": undefined,
  "ball0086.png": undefined,
  "ball0087.png": undefined,
  "ball0088.png": undefined,
  "ball0089.png": undefined,
  "ball0090.png": undefined,
  "ballFrozen0001.png": undefined,
  "ballFrozen0002.png": undefined,
  "ballFrozen0003.png": undefined,
  "ballFrozen0004.png": undefined,
  "ballFrozen0005.png": undefined,
  "ballFrozen0006.png": undefined,
  "ballFrozen0007.png": undefined,
  "ballFrozen0008.png": undefined,
  "ballFrozen0009.png": undefined,
  "ballFrozen0010.png": undefined,
  "ballFrozen0011.png": undefined,
  "ballFrozen0012.png": undefined,
  "ballFrozen0013.png": undefined,
  "ballFrozen0014.png": undefined,
  "ballFrozen0015.png": undefined,
  "ballFrozen0016.png": undefined,
  "ballFrozen0017.png": undefined,
  "ballFrozen0018.png": undefined,
  "ballFrozen0019.png": undefined,
  "ballFrozen0020.png": undefined,
  "ballFrozen0021.png": undefined,
  "ballFrozen0022.png": undefined,
  "ballFrozen0023.png": undefined,
  "ballFrozen0024.png": undefined,
  "ballFrozen0025.png": undefined,
  "ballFrozen0026.png": undefined,
  "ballFrozen0027.png": undefined,
  "ballFrozen0028.png": undefined,
  "ballFrozen0029.png": undefined,
  "ballFrozen0030.png": undefined,
  "ballFrozen0031.png": undefined,
  "ballFrozen0032.png": undefined,
  "ballFrozen0033.png": undefined,
  "ballFrozen0034.png": undefined,
  "ballFrozen0035.png": undefined,
  "ballFrozen0036.png": undefined,
  "ballFrozen0037.png": undefined,
  "ballFrozen0038.png": undefined,
  "ballFrozen0039.png": undefined,
  "ballFrozen0040.png": undefined,
  "ballFrozen0041.png": undefined,
  "ballFrozen0042.png": undefined,
  "ballFrozen0043.png": undefined,
  "ballFrozen0044.png": undefined,
  "ballFrozen0045.png": undefined,
  "ballFrozen0046.png": undefined,
  "ballFrozen0047.png": undefined,
  "ballFrozen0048.png": undefined,
  "ballFrozen0049.png": undefined,
  "ballFrozen0050.png": undefined,
  "ballFrozen0051.png": undefined,
  "ballFrozen0052.png": undefined,
  "ballFrozen0053.png": undefined,
  "ballFrozen0054.png": undefined,
  "ballFrozen0055.png": undefined,
  "ballFrozen0056.png": undefined,
  "ballFrozen0057.png": undefined,
  "ballFrozen0058.png": undefined,
  "ballFrozen0059.png": undefined,
  "ballFrozen0060.png": undefined,
  "ballFrozen0061.png": undefined,
  "ballFrozen0062.png": undefined,
  "ballFrozen0063.png": undefined,
  "ballFrozen0064.png": undefined,
  "ballFrozen0065.png": undefined,
  "ballFrozen0066.png": undefined,
  "ballFrozen0067.png": undefined,
  "ballFrozen0068.png": undefined,
  "ballFrozen0069.png": undefined,
  "ballFrozen0070.png": undefined,
  "ballFrozen0071.png": undefined,
  "ballFrozen0072.png": undefined,
  "ballFrozen0073.png": undefined,
  "ballFrozen0074.png": undefined,
  "ballFrozen0075.png": undefined,
  "ballFrozen0076.png": undefined,
  "ballFrozen0077.png": undefined,
  "ballFrozen0078.png": undefined,
  "ballFrozen0079.png": undefined,
  "ballFrozen0080.png": undefined,
  "ballFrozen0081.png": undefined,
  "ballFrozen0082.png": undefined,
  "ballFrozen0083.png": undefined,
  "ballFrozen0084.png": undefined,
  "ballFrozen0085.png": undefined,
  "ballFrozen0086.png": undefined,
  "ballFrozen0087.png": undefined,
  "ballFrozen0088.png": undefined,
  "ballFrozen0089.png": undefined,
  "ballFrozen0090.png": undefined,
  "ballShade.png": undefined,
  "ballShadow.png": undefined,
  "black.png": undefined,
  "checker.png": undefined,
  "clap.png": undefined,
  "clashConnectorBottomA.png": undefined,
  "clashConnectorBottomB.png": undefined,
  "clashJuxtaposedBottomE.png": undefined,
  "clashJuxtaposedBottomEN.png": undefined,
  "clashJuxtaposedBottomES.png": undefined,
  "clashJuxtaposedBottomN.png": undefined,
  "clashJuxtaposedBottomNE.png": undefined,
  "clashJuxtaposedBottomNW.png": undefined,
  "clashJuxtaposedBottomS.png": undefined,
  "clashJuxtaposedBottomSE.png": undefined,
  "clashJuxtaposedBottomSW.png": undefined,
  "clashJuxtaposedBottomW.png": undefined,
  "clashJuxtaposedBottomWN.png": undefined,
  "clashJuxtaposedBottomWS.png": undefined,
  "clashPerpendicularBottomEN.png": undefined,
  "clashPerpendicularBottomES.png": undefined,
  "clashPerpendicularBottomNE.png": undefined,
  "clashPerpendicularBottomNW.png": undefined,
  "clashPerpendicularBottomSE.png": undefined,
  "clashPerpendicularBottomSW.png": undefined,
  "clashPerpendicularBottomWN.png": undefined,
  "clashPerpendicularBottomWS.png": undefined,
  "connectorBottomA.png": undefined,
  "connectorBottomB.png": undefined,
  "connectorTopA.png": undefined,
  "connectorTopB.png": undefined,
  "cornerExitNE.png": undefined,
  "cornerExitNW.png": undefined,
  "cornerExitSE.png": undefined,
  "cornerExitSW.png": undefined,
  "cornerExitZoneNE.png": undefined,
  "cornerExitZoneNW.png": undefined,
  "cornerExitZoneSE.png": undefined,
  "cornerExitZoneSW.png": undefined,
  "countdownDivider.png": undefined,
  "countdownSectionGreen.png": undefined,
  "countdownSectionGrey.png": undefined,
  "countdownSectionRed.png": undefined,
  "countdownSectionStartGreen.png": undefined,
  "countdownSectionStartGrey.png": undefined,
  "countdownSectionStopGreen.png": undefined,
  "countdownSectionStopGrey.png": undefined,
  "countdownSectionStopRed.png": undefined,
  "countdownSectionStopYellow.png": undefined,
  "countdownSectionYellow.png": undefined,
  "cursorBuild.png": undefined,
  "cursorBuildClap.png": undefined,
  "cursorBuildError.png": undefined,
  "cursorDowngrade.png": undefined,
  "cursorRemove.png": undefined,
  "cursorUpgrade.png": undefined,
  "empty.png": undefined,
  "exitE.png": undefined,
  "exitN.png": undefined,
  "exitS.png": undefined,
  "exitSideTopN.png": undefined,
  "exitSideTopS.png": undefined,
  "exitTopNE.png": undefined,
  "exitTopNW.png": undefined,
  "exitTopSE.png": undefined,
  "exitTopSW.png": undefined,
  "exitW.png": undefined,
  "exitZoneE.png": undefined,
  "exitZoneN.png": undefined,
  "exitZoneS.png": undefined,
  "exitZoneW.png": undefined,
  "frostCooldownIndicator0001.png": undefined,
  "frostCooldownIndicator0002.png": undefined,
  "frostCooldownIndicator0003.png": undefined,
  "frostCooldownIndicator0004.png": undefined,
  "frostCooldownIndicator0005.png": undefined,
  "frostCooldownIndicator0006.png": undefined,
  "frostCooldownIndicator0007.png": undefined,
  "frostCooldownIndicator0008.png": undefined,
  "frostCooldownIndicator0009.png": undefined,
  "frostCooldownIndicator0010.png": undefined,
  "frostCooldownIndicator0011.png": undefined,
  "frostCooldownIndicator0012.png": undefined,
  "frostCooldownIndicator0013.png": undefined,
  "frostCooldownIndicator0014.png": undefined,
  "frostCooldownIndicator0015.png": undefined,
  "frostCooldownIndicator0016.png": undefined,
  "frostCooldownIndicator0017.png": undefined,
  "frostCooldownIndicator0018.png": undefined,
  "frostCooldownIndicator0019.png": undefined,
  "frostCooldownIndicator0020.png": undefined,
  "frostCooldownIndicator0021.png": undefined,
  "frostCooldownIndicator0022.png": undefined,
  "frostCooldownIndicator0023.png": undefined,
  "frostCooldownIndicator0024.png": undefined,
  "frostCooldownIndicator0025.png": undefined,
  "frostCooldownIndicator0026.png": undefined,
  "frostCooldownIndicator0027.png": undefined,
  "frostCooldownIndicator0028.png": undefined,
  "frostCooldownIndicator0029.png": undefined,
  "frostCooldownIndicator0030.png": undefined,
  "frostCooldownIndicator0031.png": undefined,
  "frostCooldownIndicator0032.png": undefined,
  "frostCooldownIndicator0033.png": undefined,
  "frostCooldownIndicator0034.png": undefined,
  "frostCooldownIndicator0035.png": undefined,
  "frostCooldownIndicator0036.png": undefined,
  "frostCooldownIndicator0037.png": undefined,
  "frostCooldownIndicator0038.png": undefined,
  "frostCooldownIndicator0039.png": undefined,
  "frostCooldownIndicator0040.png": undefined,
  "frostCooldownIndicator0041.png": undefined,
  "frostCooldownIndicator0042.png": undefined,
  "frostCooldownIndicator0043.png": undefined,
  "frostCooldownIndicator0044.png": undefined,
  "frostCooldownIndicator0045.png": undefined,
  "frostCooldownIndicator0046.png": undefined,
  "frostCooldownIndicator0047.png": undefined,
  "frostCooldownIndicator0048.png": undefined,
  "frostCooldownIndicator0049.png": undefined,
  "frostCooldownIndicator0050.png": undefined,
  "frostCooldownIndicator0051.png": undefined,
  "frostCooldownIndicator0052.png": undefined,
  "frostCooldownIndicator0053.png": undefined,
  "frostCooldownIndicator0054.png": undefined,
  "frostCooldownIndicator0055.png": undefined,
  "frostCooldownIndicator0056.png": undefined,
  "frostCooldownIndicator0057.png": undefined,
  "frostCooldownIndicator0058.png": undefined,
  "frostCooldownIndicator0059.png": undefined,
  "frostCooldownIndicator0060.png": undefined,
  "frostCooldownIndicator0061.png": undefined,
  "frostCooldownIndicator0062.png": undefined,
  "frostCooldownIndicator0063.png": undefined,
  "frostCooldownIndicator0064.png": undefined,
  "frostCooldownIndicator0065.png": undefined,
  "frostCooldownIndicator0066.png": undefined,
  "frostCooldownIndicator0067.png": undefined,
  "frostCooldownIndicator0068.png": undefined,
  "frostCooldownIndicator0069.png": undefined,
  "frostCooldownIndicator0070.png": undefined,
  "frostCooldownIndicator0071.png": undefined,
  "frostCooldownIndicator0072.png": undefined,
  "frostCooldownIndicator0073.png": undefined,
  "frostCooldownIndicator0074.png": undefined,
  "frostCooldownIndicator0075.png": undefined,
  "frostCooldownIndicator0076.png": undefined,
  "frostCooldownIndicator0077.png": undefined,
  "frostCooldownIndicator0078.png": undefined,
  "frostCooldownIndicator0079.png": undefined,
  "frostCooldownIndicator0080.png": undefined,
  "frostCooldownIndicator0081.png": undefined,
  "frostCooldownIndicator0082.png": undefined,
  "frostCooldownIndicator0083.png": undefined,
  "frostCooldownIndicator0084.png": undefined,
  "frostCooldownIndicator0085.png": undefined,
  "frostCooldownIndicator0086.png": undefined,
  "frostCooldownIndicator0087.png": undefined,
  "frostCooldownIndicator0088.png": undefined,
  "frostCooldownIndicator0089.png": undefined,
  "frostCooldownIndicator0090.png": undefined,
  "frostCooldownIndicator0091.png": undefined,
  "frostCooldownIndicator0092.png": undefined,
  "frostCooldownIndicator0093.png": undefined,
  "frostCooldownIndicator0094.png": undefined,
  "frostCooldownIndicator0095.png": undefined,
  "frostCooldownIndicator0096.png": undefined,
  "frostCooldownIndicator0097.png": undefined,
  "frostCooldownIndicator0098.png": undefined,
  "frostCooldownIndicator0099.png": undefined,
  "frostCooldownIndicator0100.png": undefined,
  "frostCooldownIndicator0101.png": undefined,
  "frostCooldownIndicator0102.png": undefined,
  "frostCooldownIndicator0103.png": undefined,
  "frostCooldownIndicator0104.png": undefined,
  "frostCooldownIndicator0105.png": undefined,
  "frostCooldownIndicator0106.png": undefined,
  "frostCooldownIndicator0107.png": undefined,
  "frostCooldownIndicator0108.png": undefined,
  "frostCooldownIndicator0109.png": undefined,
  "frostCooldownIndicator0110.png": undefined,
  "frostCooldownIndicator0111.png": undefined,
  "frostCooldownIndicator0112.png": undefined,
  "frostCooldownIndicator0113.png": undefined,
  "frostCooldownIndicator0114.png": undefined,
  "frostCooldownIndicator0115.png": undefined,
  "frostCooldownIndicator0116.png": undefined,
  "frostCooldownIndicator0117.png": undefined,
  "frostCooldownIndicator0118.png": undefined,
  "frostCooldownIndicator0119.png": undefined,
  "frostCooldownIndicator0120.png": undefined,
  "frostCooldownIndicator0121.png": undefined,
  "frostCooldownIndicator0122.png": undefined,
  "frostCooldownIndicator0123.png": undefined,
  "frostCooldownIndicator0124.png": undefined,
  "frostCooldownIndicator0125.png": undefined,
  "frostCooldownIndicator0126.png": undefined,
  "frostCooldownIndicator0127.png": undefined,
  "frostCooldownIndicator0128.png": undefined,
  "frostCooldownIndicator0129.png": undefined,
  "frostCooldownIndicator0130.png": undefined,
  "frostCooldownIndicator0131.png": undefined,
  "frostCooldownIndicator0132.png": undefined,
  "frostCooldownIndicator0133.png": undefined,
  "frostCooldownIndicator0134.png": undefined,
  "frostCooldownIndicator0135.png": undefined,
  "frostCooldownIndicator0136.png": undefined,
  "frostCooldownIndicator0137.png": undefined,
  "frostCooldownIndicator0138.png": undefined,
  "frostCooldownIndicator0139.png": undefined,
  "frostCooldownIndicator0140.png": undefined,
  "frostCooldownIndicator0141.png": undefined,
  "frostCooldownIndicator0142.png": undefined,
  "frostCooldownIndicator0143.png": undefined,
  "frostCooldownIndicator0144.png": undefined,
  "frostCooldownIndicator0145.png": undefined,
  "frostCooldownIndicator0146.png": undefined,
  "frostCooldownIndicator0147.png": undefined,
  "frostCooldownIndicator0148.png": undefined,
  "frostCooldownIndicator0149.png": undefined,
  "frostCooldownIndicator0150.png": undefined,
  "frostCooldownIndicator0151.png": undefined,
  "frostCooldownIndicator0152.png": undefined,
  "frostCooldownIndicator0153.png": undefined,
  "frostCooldownIndicator0154.png": undefined,
  "frostCooldownIndicator0155.png": undefined,
  "frostCooldownIndicator0156.png": undefined,
  "frostCooldownIndicator0157.png": undefined,
  "frostCooldownIndicator0158.png": undefined,
  "frostCooldownIndicator0159.png": undefined,
  "frostCooldownIndicator0160.png": undefined,
  "frostCooldownIndicator0161.png": undefined,
  "frostCooldownIndicator0162.png": undefined,
  "frostCooldownIndicator0163.png": undefined,
  "frostCooldownIndicator0164.png": undefined,
  "frostCooldownIndicator0165.png": undefined,
  "frostCooldownIndicator0166.png": undefined,
  "frostCooldownIndicator0167.png": undefined,
  "frostCooldownIndicator0168.png": undefined,
  "frostCooldownIndicator0169.png": undefined,
  "frostCooldownIndicator0170.png": undefined,
  "frostCooldownIndicator0171.png": undefined,
  "frostCooldownIndicator0172.png": undefined,
  "frostCooldownIndicator0173.png": undefined,
  "frostCooldownIndicator0174.png": undefined,
  "frostCooldownIndicator0175.png": undefined,
  "frostCooldownIndicator0176.png": undefined,
  "frostCooldownIndicator0177.png": undefined,
  "frostCooldownIndicator0178.png": undefined,
  "frostCooldownIndicator0179.png": undefined,
  "frostCooldownIndicator0180.png": undefined,
  "frostCooldownIndicator0181.png": undefined,
  "frostCooldownIndicator0182.png": undefined,
  "frostCooldownIndicator0183.png": undefined,
  "frostCooldownIndicator0184.png": undefined,
  "frostCooldownIndicator0185.png": undefined,
  "frostCooldownIndicator0186.png": undefined,
  "frostCooldownIndicator0187.png": undefined,
  "frostCooldownIndicator0188.png": undefined,
  "frostCooldownIndicator0189.png": undefined,
  "frostCooldownIndicator0190.png": undefined,
  "frostCooldownIndicator0191.png": undefined,
  "frostCooldownIndicator0192.png": undefined,
  "frostCooldownIndicator0193.png": undefined,
  "frostCooldownIndicator0194.png": undefined,
  "frostCooldownIndicator0195.png": undefined,
  "frostCooldownIndicator0196.png": undefined,
  "frostCooldownIndicator0197.png": undefined,
  "frostCooldownIndicator0198.png": undefined,
  "frostCooldownIndicator0199.png": undefined,
  "frostCooldownIndicator0200.png": undefined,
  "frostCooldownIndicator0201.png": undefined,
  "frostCooldownIndicator0202.png": undefined,
  "frostCooldownIndicator0203.png": undefined,
  "frostCooldownIndicator0204.png": undefined,
  "frostCooldownIndicator0205.png": undefined,
  "frostCooldownIndicator0206.png": undefined,
  "frostCooldownIndicator0207.png": undefined,
  "frostCooldownIndicator0208.png": undefined,
  "frostCooldownIndicator0209.png": undefined,
  "frostCooldownIndicator0210.png": undefined,
  "frostCooldownIndicator0211.png": undefined,
  "frostCooldownIndicator0212.png": undefined,
  "frostCooldownIndicator0213.png": undefined,
  "frostCooldownIndicator0214.png": undefined,
  "frostCooldownIndicator0215.png": undefined,
  "frostCooldownIndicator0216.png": undefined,
  "frostCooldownIndicator0217.png": undefined,
  "frostCooldownIndicator0218.png": undefined,
  "frostCooldownIndicator0219.png": undefined,
  "frostCooldownIndicator0220.png": undefined,
  "frostCooldownIndicator0221.png": undefined,
  "frostCooldownIndicator0222.png": undefined,
  "frostCooldownIndicator0223.png": undefined,
  "frostCooldownIndicator0224.png": undefined,
  "frostCooldownIndicator0225.png": undefined,
  "frostCooldownIndicator0226.png": undefined,
  "frostCooldownIndicator0227.png": undefined,
  "frostCooldownIndicator0228.png": undefined,
  "frostCooldownIndicator0229.png": undefined,
  "frostCooldownIndicator0230.png": undefined,
  "frostCooldownIndicator0231.png": undefined,
  "frostCooldownIndicator0232.png": undefined,
  "frostCooldownIndicator0233.png": undefined,
  "frostCooldownIndicator0234.png": undefined,
  "frostCooldownIndicator0235.png": undefined,
  "frostCooldownIndicator0236.png": undefined,
  "frostCooldownIndicator0237.png": undefined,
  "frostCooldownIndicator0238.png": undefined,
  "frostCooldownIndicator0239.png": undefined,
  "frostCooldownIndicator0240.png": undefined,
  "frostCooldownIndicator0241.png": undefined,
  "frostCooldownIndicator0242.png": undefined,
  "frostCooldownIndicator0243.png": undefined,
  "frostCooldownIndicator0244.png": undefined,
  "frostCooldownIndicator0245.png": undefined,
  "frostCooldownIndicator0246.png": undefined,
  "frostCooldownIndicator0247.png": undefined,
  "frostCooldownIndicator0248.png": undefined,
  "frostCooldownIndicator0249.png": undefined,
  "frostCooldownIndicator0250.png": undefined,
  "frostCooldownIndicator0251.png": undefined,
  "frostCooldownIndicator0252.png": undefined,
  "frostCooldownIndicator0253.png": undefined,
  "frostCooldownIndicator0254.png": undefined,
  "frostCooldownIndicator0255.png": undefined,
  "frostCooldownIndicator0256.png": undefined,
  "frostCooldownIndicator0257.png": undefined,
  "frostCooldownIndicator0258.png": undefined,
  "frostCooldownIndicator0259.png": undefined,
  "frostCooldownIndicator0260.png": undefined,
  "frostCooldownIndicator0261.png": undefined,
  "frostCooldownIndicator0262.png": undefined,
  "frostCooldownIndicator0263.png": undefined,
  "frostCooldownIndicator0264.png": undefined,
  "frostCooldownIndicator0265.png": undefined,
  "frostCooldownIndicator0266.png": undefined,
  "frostCooldownIndicator0267.png": undefined,
  "frostCooldownIndicator0268.png": undefined,
  "frostCooldownIndicator0269.png": undefined,
  "frostCooldownIndicator0270.png": undefined,
  "frostCooldownIndicator0271.png": undefined,
  "frostCooldownIndicator0272.png": undefined,
  "frostCooldownIndicator0273.png": undefined,
  "frostCooldownIndicator0274.png": undefined,
  "frostCooldownIndicator0275.png": undefined,
  "frostCooldownIndicator0276.png": undefined,
  "frostCooldownIndicator0277.png": undefined,
  "frostCooldownIndicator0278.png": undefined,
  "frostCooldownIndicator0279.png": undefined,
  "frostCooldownIndicator0280.png": undefined,
  "frostCooldownIndicator0281.png": undefined,
  "frostCooldownIndicator0282.png": undefined,
  "frostCooldownIndicator0283.png": undefined,
  "frostCooldownIndicator0284.png": undefined,
  "frostCooldownIndicator0285.png": undefined,
  "frostCooldownIndicator0286.png": undefined,
  "frostCooldownIndicator0287.png": undefined,
  "frostCooldownIndicator0288.png": undefined,
  "frostCooldownIndicator0289.png": undefined,
  "frostCooldownIndicator0290.png": undefined,
  "frostCooldownIndicator0291.png": undefined,
  "frostCooldownIndicator0292.png": undefined,
  "frostCooldownIndicator0293.png": undefined,
  "frostCooldownIndicator0294.png": undefined,
  "frostCooldownIndicator0295.png": undefined,
  "frostCooldownIndicator0296.png": undefined,
  "frostCooldownIndicator0297.png": undefined,
  "frostCooldownIndicator0298.png": undefined,
  "frostCooldownIndicator0299.png": undefined,
  "frostCooldownIndicator0300.png": undefined,
  "frostEffect0001.png": undefined,
  "frostEffect0002.png": undefined,
  "frostEffect0003.png": undefined,
  "frostEffect0004.png": undefined,
  "frostEffect0005.png": undefined,
  "frostEffect0006.png": undefined,
  "frostEffect0007.png": undefined,
  "frostEffect0008.png": undefined,
  "frostEffect0009.png": undefined,
  "frostEffect0010.png": undefined,
  "frostEffect0011.png": undefined,
  "frostEffect0012.png": undefined,
  "frostEffect0013.png": undefined,
  "frostEffect0014.png": undefined,
  "frostEffect0015.png": undefined,
  "frostEffect0016.png": undefined,
  "frostEffect0017.png": undefined,
  "frostEffect0018.png": undefined,
  "frostEffect0019.png": undefined,
  "frostEffect0020.png": undefined,
  "frostEffect0021.png": undefined,
  "frostEffect0022.png": undefined,
  "frostEffect0023.png": undefined,
  "frostEffect0024.png": undefined,
  "frostEffect0025.png": undefined,
  "frostInlay.png": undefined,
  "innerCornerBottomNE.png": undefined,
  "innerCornerBottomNW.png": undefined,
  "innerCornerBottomSE.png": undefined,
  "innerCornerBottomSW.png": undefined,
  "innerCornerExitEN.png": undefined,
  "innerCornerExitES.png": undefined,
  "innerCornerExitNE.png": undefined,
  "innerCornerExitNW.png": undefined,
  "innerCornerExitSE.png": undefined,
  "innerCornerExitSW.png": undefined,
  "innerCornerExitWN.png": undefined,
  "innerCornerExitWS.png": undefined,
  "innerCornerExitZoneEN.png": undefined,
  "innerCornerExitZoneES.png": undefined,
  "innerCornerExitZoneNE.png": undefined,
  "innerCornerExitZoneNW.png": undefined,
  "innerCornerExitZoneSE.png": undefined,
  "innerCornerExitZoneSW.png": undefined,
  "innerCornerExitZoneWN.png": undefined,
  "innerCornerExitZoneWS.png": undefined,
  "innerCornerTopNE.png": undefined,
  "innerCornerTopNW.png": undefined,
  "innerCornerTopSE.png": undefined,
  "innerCornerTopSW.png": undefined,
  "outerCornerBottomNE.png": undefined,
  "outerCornerBottomNW.png": undefined,
  "outerCornerBottomSE.png": undefined,
  "outerCornerBottomSW.png": undefined,
  "outerCornerTopNE.png": undefined,
  "outerCornerTopNW.png": undefined,
  "outerCornerTopSE.png": undefined,
  "outerCornerTopSW.png": undefined,
  "resourceBlock.png": undefined,
  "resourceFrost.png": undefined,
  "runner.png": undefined,
  "sideBottom1EN.png": undefined,
  "sideBottom1ES.png": undefined,
  "sideBottom1NE.png": undefined,
  "sideBottom1NW.png": undefined,
  "sideBottom1SE.png": undefined,
  "sideBottom1SW.png": undefined,
  "sideBottom1WN.png": undefined,
  "sideBottom1WS.png": undefined,
  "sideBottom2E.png": undefined,
  "sideBottom2N.png": undefined,
  "sideBottom2S.png": undefined,
  "sideBottom2W.png": undefined,
  "sideBottom4E.png": undefined,
  "sideBottom4N.png": undefined,
  "sideBottom4S.png": undefined,
  "sideBottom4W.png": undefined,
  "sideBottom6E.png": undefined,
  "sideBottom6N.png": undefined,
  "sideBottom6S.png": undefined,
  "sideBottom6W.png": undefined,
  "sideTop1EN.png": undefined,
  "sideTop1ES.png": undefined,
  "sideTop1NE.png": undefined,
  "sideTop1NW.png": undefined,
  "sideTop1SE.png": undefined,
  "sideTop1SW.png": undefined,
  "sideTop1WN.png": undefined,
  "sideTop1WS.png": undefined,
  "sideTop2E.png": undefined,
  "sideTop2N.png": undefined,
  "sideTop2S.png": undefined,
  "sideTop2W.png": undefined,
  "sideTop4E.png": undefined,
  "sideTop4N.png": undefined,
  "sideTop4S.png": undefined,
  "sideTop4W.png": undefined,
  "sideTop6E.png": undefined,
  "sideTop6N.png": undefined,
  "sideTop6S.png": undefined,
  "sideTop6W.png": undefined,
  "staticClapTower.png": undefined,
  "staticTower.png": undefined,
  "tile.png": undefined,
  "topGap.png": undefined,
  "topPlate.png": undefined,
  "variableClapTower.png": undefined,
  "variableTower.png": undefined,
  "waypoint.png": undefined,
  "white.png": undefined,
};
