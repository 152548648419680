import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Button, Grid, Typography } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { isRound, Round } from "../shared/Round";
import { isPath, Path } from "../shared/Path";
import { getSaveService } from "../network/SaveService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InteractiveGame } from "./elements/InteractiveGame";
import { SingleRoundElement } from "./elements/SingleRoundElement";
import { Player } from "../shared/Player";
import { Cosmetics, defaultCosmetics } from "../shared/Cosmetics";
import { Layout } from "../shared/Layout";
import { BodyContent } from "./elements/BodyContent";

interface SaveProps {
  player: Player;
  cosmetics: Cosmetics | undefined;
}

export const Save = (props: SaveProps) => {
  const { player, cosmetics = defaultCosmetics } = props;
  let params = useParams();
  const navigate = useNavigate();

  const [seed, setSeed] = useState<number>();

  useEffect(() => {
    if (params !== undefined && params.saveId === undefined) {
      navigate("/saves");
    } else if (params.saveId !== undefined) {
      setSeed(+params.saveId);
    }
  }, [navigate, params, seed]);

  const [round, setRound] = useState<Round | undefined>(undefined);

  const [path, setPath] = useState<Path | undefined>();

  useEffect(() => {
    if (seed) {
      getSaveService()
        .getSave(seed)
        .then((response) => {
          if (response && isRound(response)) {
            setRound(response);
          } else {
            console.log(response);
          }
        });
    }
  }, [seed]);

  const [layout, setLayout] = useState<Layout>();

  const [refreshGame, setRefreshGame] = useState(false);

  useEffect(() => {
    if (refreshGame) {
      setRefreshGame(false);
    }
  }, [refreshGame, setRefreshGame]);

  const width = useMemo(
    () =>
      Math.min(
        window.innerWidth,
        Math.round(((((window.innerHeight - 56) * 5) / 6) * 17) / 19.5)
      ),
    []
  );

  return (
    <Fragment>
      {!refreshGame && seed && round && isRound(round) && (
        <React.Fragment>
          {round && path === undefined && (
            <BodyContent>
              <Grid
                container
                justifyContent="center"
                spacing={0}
                rowSpacing={0}
                width={1}
              >
                <InteractiveGame
                  round={round}
                  duration={0}
                  startingLayout={layout}
                  cosmetics={cosmetics}
                  submitLayout={(layout) => {
                    setLayout(layout);
                    getSaveService()
                      .submitSavePlay(seed, layout)
                      .then((response) =>
                        isPath(response)
                          ? setPath(response)
                          : console.log(response)
                      );
                  }}
                  origin={["Saved Round"]}
                />
              </Grid>
            </BodyContent>
          )}
          {round && path && layout && (
            <Grid
              container
              width={1}
              justifyContent="center"
              height={"calc(100vh - 56px)"}
            >
              <Grid item width={width} sx={{pt:3}}>
                <SingleRoundElement
                  round={round}
                  playerSolution={{
                    player,
                    cosmetics,
                    solution: { path, layout },
                  }}
                  place={1}
                  width={width}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  maxWidth="sm"
                  mx="auto"
                  sx={{
                    pt: 1,
                    width: 1,
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: 1,
                      }}
                    >
                      <ArrowBackIcon />
                      <Typography sx={{ pl: 1 }}>Back</Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      setRefreshGame(true);
                      setPath(undefined);
                    }}
                    endIcon={<ReplayIcon />}
                  >
                    Retry
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Fragment>
  );
};
